<template>
  <div class="component-footer">
    <div class="container">
      <div class="flex column item-center icon-wrapper">
        <img src="@/assets/icon-logo-black.png" class="logo" />
        <app-social-links class="content-center" />
      </div>
      <div class="agreement">
        <router-link
          v-for="(item, index) in pagesStore.data" :key="item.id"
          :to="{ name: 'pages', params: { slug: item.slug }}">{{item.name}}<span v-if="index !== 4"> | </span>
        </router-link>
      </div>
      <p class="copyright">Copyrights © {{ dayjs().year() }} BiddingArt.com All rights reserved</p>
    </div>
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';
  import { pagesStore } from '@/store';
  import dayjs from 'dayjs';

  @Component
  export default class AppFooter extends Vue {
    pagesStore = pagesStore;
    dayjs = dayjs
  }
</script>

<style lang="scss" scoped>
  .component-footer {
    padding: 27px 0 40px;
    border-top: 1px solid $border-light;
    color: $gray;

    .container {
      display: flex;
      flex-direction: column;
      align-items: center;

      @include media-lg-and-up {
        align-items: flex-start;

        .icon-wrapper {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          width: 100%;

          .logo {
            margin: 0;
          }
        }
      }
    }

    .logo {
      width: 88px;
      margin-bottom: 27px;
    }

    .agreement {
      margin-top: 30px;
      font-size: 11px;
      line-height: 18px;
      text-align: center;

      a {
        text-decoration: none;
        color: inherit;
      }
    }

    .copyright {
      margin-top: 15px;
      margin-bottom: 0;
      font-size: 8px;
      text-align: center;
    }
  }
</style>
