import { fly } from './fly';
import _ from 'lodash';
import { Collection } from '@/store';

export async function getCustomPage(slug) {
  const res = await fly.get(`custom_pages/${slug}`);
  const { components } = res.data.config;
  let mergeDetailData = []; // 分类列表和单个商品的数据(包含位置)

  if (res.data.fetch_detail) {
    const { data } = await fly.get(`custom_pages/${slug}/fetch_detail`);
    mergeDetailData = data;
  }

  for (let [index, row] of components.entries()) {
    const config = row.config || {};
    if (row.name === 'auction-list') {
      if (config.timeType) {
        const configStore = new Collection({
          fetch(params) {
            return fly.get('auctions', {
              time_type: config.timeType,
              ...params,
              per_page: config.timeType === 'finished' ? 4 : 12,
              page: config.timeType === 'in_progress' ? 0 : (params.page || 1)
            });
          }
        });
        await configStore.fetchData();
        configStore.timeType = config.timeType;
        row.config = configStore;
      } else {
        row.name = null;
      }
    }
    if (row.name === 'custom-product-list') {
      if (config.customProductId) {
        const { data, headers } = await fly.get('products', { custom_product_list_id_eq: config.customProductId, per_page: 6 });
        const total = _.get(headers, 'x-total');
        row.config = { ...row.config, data, total };
      } else {
        row.name = null;
      }
    }
    if (row.name === 'will-finished-product-list') {
      const { data, headers } = await fly.get('products', { will_end: true, per_page: (slug === 'home' || slug === 'mall') ? 6 : 4 });
      const total = _.get(headers, 'x-total');
      row.config = { data, total };
    }
    if (row.name === 'in-progress-product-list') {
      const { data, headers } = await fly.get('products', { in_progress: true, per_page: (slug === 'home' || slug === 'mall') ? 6 : 4 });
      const total = _.get(headers, 'x-total');
      row.config = { data, total };
    }
    if (row.name === 'product-category-list' || row.name === 'single-product') {
      const data = mergeDetailData.find(item => item.index === index);
      if (data) {
        row.config.data = data.value;
      } else {
        row.name = null;
      }
    }
    if (row.name === 'hot-category-list') {
      const { data } = await fly.get('product_categories', { parent_id_null: true, is_hot_eq: true });
      row.config.data = data;
    }
    if (row.name === 'auction-house-list') {
      const { data } = await fly.get('auction_houses', { page: 0, slug, component_index: index, simple_list: true }); // 店铺列表需要传：当前位置的index过去（可以多个店铺列表），slug页面, simple_list（只显示cover和title）
      row.config = { data };
    }
    if (row.name === 'recommend-auction-list') {
      const { data } = await fly.get('auctions', { page: 0, slug, component_index: index, id_in: row.config.auctions.map(v => v.id) });
      row.config = { data };
    }
    if (row.name === 'live-broadcast-list') {
      const { data } = await fly.get('live_broadcasts');
      row.config = { data };
    }
    // 一口价商品列表
    if (row.name === 'confirm-price-product-list') {
      const { data } = await fly.get('products', {
        per_page: 6,
        confirmed_price_not_null: true // 只返回一口价的商品
      });

      row.config = { data };
    }
    // 热门拍品列表
    if (row.name === 'hot-auction-item-list') {
      const { data } = await fly.get('auction_items', {
        sort: 'visits_count desc', // 點擊降序
        per_page: 6,
        entity: 'AllAuctionItem',
        status_in: ['pending'],
        auction_is_finished_eq: false
      });

      row.config = { data };
    }
  }
  return res;
}
