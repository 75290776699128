<i18n src="./locale.json"></i18n>

<template>
  <div class="step1-box">
    <!-- 头部 -->
    <div class="header">
      <div class="content-box">
        <div class="top-box">
          <span class="title">
            {{ $t('auctionItemsPopupTitle') }}
          </span>
          <span class="tips">
            {{ $t('auctionItemsLimit') }}
          </span>
        </div>
        <div v-if="selectedItems.length" class="bottom-box">
          <span class="subtitle">
            {{ $tc('selected', selectedItems.length) }}
          </span>
          <span class="items">
            （{{ selectedItems.map(item => `LOT${item.number}`).join('、') }}）
          </span>
        </div>
      </div>
      <div class="primary-btn" @click="$emit('next')">{{ $t('next') }}</div>
    </div>

    <!-- 排序 -->
    <div class="filter-group">
      <el-row :gutter="30">
        <el-col :xs="24" :sm="12" class="item">
          <el-select
            :value="priceFilterValue"
            :placeholder="$t('priceFilter')"
            @change="$emit('priceFilterChange', $event)"
          >
            <el-option v-if="priceFilterValue" :label="$t('all')" value />
            <el-option :label="$t('firstPriceFilter')" value="1" />
            <el-option :label="$t('secondPriceFilter')" value="2" />
            <el-option :label="$t('thirdPriceFilter')" value="3" />
          </el-select>
        </el-col>
        <el-col :xs="24" :sm="12" class="item">
          <el-input
            type="search"
            v-model="keywordValue"
            :placeholder="$t('auctionSearch')"
            @keypress.enter.native="$emit('search', keywordValue)"
          >
            <img src="@/assets/icon-search.png" slot="suffix" class="icon" @click="$emit('search', keywordValue)" />
          </el-input>
        </el-col>
      </el-row>
    </div>

    <!-- 拍品列表 -->
    <div class="list-wrapper">
      <el-row :gutter="15">
        <el-col :xs="24" :sm="12" v-for="item in store.data" :key="item.id">
          <auction-item-card
            class="item"
            :title="`LOT ${item.title}`"
            :cover="$get(item, 'cover.url')"
            :info="item.title"
            show-checkbox
            :checked="selectedItems.findIndex(selectedItem => selectedItem.id === item.id) > -1"
            :disabled="selectedItems.length >= 5 && selectedItems.findIndex(selectedItem => selectedItem.id === item.id) === -1"
            @toggle="handleToggle(item)"
          >
            <div slot="title" v-if="item.number">LOT {{ item.number }}</div>
            <div class="price">
              <div v-if="item.is_finished && item.show_items_final_price && $authStore.isLogin">
                <div v-if="['hammered', 'force_hammered'].includes(item.status)">
                  <div>
                    {{ $t('dealPrice') }}:
                    <span class="text-uppercase">{{ $get(item, 'auction_category.currency') }}</span>
                    {{ item.final_price | currencyFormat }}
                  </div>
                  <div v-if="!$options.filters.isLocaleCurrency($get(item, 'auction_category.currency'))">
                    {{ $t('dealPrice') }}: {{ $t('currency') }} {{ item.final_price | conversionFormat($get(item, 'auction_category.currency')) | currencyFormat }}
                  </div>
                </div>
                <div v-else>
                  {{ $t('aborted') }}
                </div>
              </div>
              <div v-else-if="item.starting_price <= 0">
                {{ $t('estimatePending') }}
              </div>
              <div v-else>
                <div>
                  {{ $t('startPrice') }}:
                  <span class="text-uppercase">{{ $get(item, 'auction_category.currency') }}</span>
                  {{ item.starting_price | currencyFormat }}
                </div>
                <div v-if="!$options.filters.isLocaleCurrency($get(item, 'auction_category.currency'))">
                  {{ $t('startPrice') }}: {{ $t('currency') }} {{ item.starting_price | conversionFormat($get(item, 'auction_category.currency')) | currencyFormat }}
                </div>
              </div>
            </div>
          </auction-item-card>
        </el-col>
      </el-row>
      <div class="list-empty" v-if="store.isEmpty">
        <img src="@/assets/icon-select-empty.png" />
        <div>{{ $t('auctionItemsListEmpty') }}</div>
      </div>
      <pagination class="pagination" :store="store" />
    </div>
  </div>
</template>

<script>
  import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

  @Component
  export default class PrintItemsStep1 extends Vue {
    @Prop({ type: Array, default: () => [] }) selectedItems;
    @Prop({ type: String, default: '' }) priceFilterValue;
    @Prop({ type: String, default: '' }) keyword;
    @Prop({ type: Object, default: () => ({}) }) store;

    keywordValue = '';
    @Watch('keyword', { immediate: true })
    handleKeywordChange(val) {
      this.keywordValue = val;
    }

    handleToggle(auctionItem) {
      // 判断selectedItems是否有auctionItem，无则添加，有则删除
      const index = this.selectedItems.findIndex(item => item.id === auctionItem.id);
      if (index > -1) {
        this.selectedItems.splice(index, 1);
      } else {
        this.selectedItems.push(auctionItem);
        this.$set(this.selectedItems, 'expertOpinion', '');
      }
    }
  }
</script>

<style lang="scss" scoped>
.step1-box {
  .header {
    display: flex;
    justify-content: space-between;

    .top-box {
      display: flex;
      align-items: center;

      .title {
        margin-right: 16px;
        font-weight: 600;
        font-size: 20px;
        color: #333;
      }

      .tips {
        padding: 2px 7px;
        margin-top: 0;
        border: 1px solid #e8e8e8;
        border-radius: 10px;
        font-size: 12px;
        color: #6b6b6b;
      }
    }

    .bottom-box {
      display: flex;
      align-items: center;
      margin-top: 7px;

      .subtitle {
        font-size: 14px;
      }

      .items {
        font-size: 10px;
      }

      .prev-btn {
        display: flex;
        align-items: center;
        gap: 10px;
        cursor: pointer;

        .icon {
          width: 15px;
          height: 15px;
        }
      }
    }

    .primary-btn {
      margin: initial;
      margin-left: 15px;
    }
  }

  .filter-group {
    padding: 12px 0;

    .item {
      margin-bottom: 9px;

      /deep/ .el-select {
        display: block;
        border: 1px solid #ddd;

        .el-select-dropdown__item.selected {
          color: #000;
        }
      }

      /deep/ .el-select__caret.el-input__icon.el-icon-arrow-up {
        font-weight: bold;
        font-size: 16px;
        color: #000;
      }

      /deep/ .el-input {
        display: flex;
        outline: none;

        .el-input__inner {
          flex: 1;
          border-color: #ddd !important;
        }

        .el-input__suffix {
          display: flex;
          align-items: center;
        }
      }

      .icon {
        width: 15px;
        height: 15px;
        padding: 2px 5px 0 0;
      }
    }
  }

  .list-wrapper {
    max-height: 60vh;
    padding: 10px;
    margin: 0 -10px;
    overflow: auto;

    .item {
      margin-bottom: 12px;
    }

    .pagination {
      margin: 6px 0;
    }

    /deep/ .auction-item-card .info {
      margin-top: 3px;
    }

    .categories-list-empty {
      margin: 46px auto 57px;
      text-align: center;
      color: #999;

      > img {
        width: 65px;
        height: 65px;
        margin-bottom: 36px;
      }
    }

    .expert-item {
      padding: 13px 0;
      border: 1px solid #e8e8e8;
      border-right: none;
      border-left: none;

      .opinion {
        padding: 10px;
        margin-top: 12px;
        background: #f7f7f7;
      }

      .textarea {
        width: 100%;
        border: none;
        outline: none;
        resize: none;
        background-color: transparent;
      }
    }
  }

  .list-empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 20px 0;
    color: #999;

    > img {
      width: 65px;
      height: 65px;
      margin-bottom: 18px;
    }
  }
}
</style>
