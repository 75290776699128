<template>
  <div class="skeleton" v-if="loading">
    <el-progress :percentage="percentage" :show-text="false"/>

    <div class="container">
      <div v-for="i in 3" :key="i">
        <common-title/>
        <div class="live-list">
          <el-row :gutter="30">
            <el-col :xs="24" :sm="8" v-for="i in 3" :key="i">
              <skeleton-live-card class="item"/>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { Component, Vue } from 'vue-property-decorator';
  import Skeleton from '@/components/skeleton/mixins';

  @Component({ mixins: [Skeleton] })
  export default class IndexSkeleton extends Vue {
  }
</script>

<style lang="scss" scoped>
  .container {
    @include media-sm-and-up {
      margin-top: 20px;
    }
  }

  .live-list {
    padding: 10px 0;

    .item {
      display: block;
      margin-bottom: 10px;

      @include media-xs-only {
        margin-bottom: 20px;
      }
    }
  }
</style>
