import { SimpleStore } from './simple-store';
import { fly } from '@/utils';

class FollowProductIdsStore extends SimpleStore {
  data = []

  async fetchData(config) {
    const { data } = await this.fetching(fly.get('mine/follow_product_ids', null, config));
    this.data = data;
  }
}

export const followProductIdsStore = new FollowProductIdsStore();
