<template>
  <div class="component-popup" v-show="show">
    <div class="content">{{ content }}</div>
  </div>
</template>

<script>
  import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

  @Component
  export default class Popup extends Vue {
    @Prop({ type: Boolean, default: false }) show
    @Prop({ type: String, default: '' }) content
    @Prop({ type: Number, default: 3000 }) duration

    timer = null

    @Watch('show')
    handler(val) {
      if (val) {
        this.timer && clearTimeout(this.timer);
        setTimeout(() => {
          this.$emit('update:show', false);
        }, this.duration);
      }
    }
  }
</script>

<style lang="scss" scoped>
  .component-popup {
    position: fixed;
    z-index: 1000;
    top: 50%;
    left: 50%;
    width: 289px;
    padding: 10px 60px;
    border-radius: 5px;
    color: #fff;
    background: rgba(0, 0, 0, 0.8);
    transform: translate(-50%, -50%);
  }
</style>
