<template>
  <el-dropdown @command="handleSwitch" trigger="click" @visible-change="handleRotate">
    <span class="el-dropdown-link">
      {{ localeName }}<i class="el-icon-arrow-down el-icon--right" :class="visibleChange ? 'rotate': ''"></i>
    </span>
    <el-dropdown-menu class="dropdown-menu" slot="dropdown">
      <el-dropdown-item :class="{ active: value === $i18n.locale }" v-for="(label, value) in languages" :key="label" :command="value">
        {{ label }}
        <i class="el-icon-check" v-if="value === $i18n.locale"></i>
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';
  import { languages } from '@/i18n';
  import { I18N_STORAGE_KEY } from '@/constants';

  @Component
  export default class LangSwitcher extends Vue {
    languages = languages;
    visibleChange = false;

    get localeName() {
      return this.languages[this.$i18n.locale];
    }

    handleSwitch(lang) {
      if (this.$i18n.locale !== lang) {
        this.$emit('close');
        this.$i18n.locale = lang;
        localStorage.setItem(I18N_STORAGE_KEY, lang);
        location.reload();
      }
    }

    handleRotate(e) {
      this.visibleChange = e;
    }
  }
</script>

<style lang="scss" scoped>
  .el-dropdown-link {
    outline: none;
    font-size: 16px;
    line-height: 30px;

    .rotate {
      transform: rotate(180deg);
    }
  }

  .el-dropdown-menu {
    z-index: 10000 !important;
    width: 120px;
    padding: 0;
    border-radius: 0;
    box-shadow: 0 0 11px 2px rgba(162, 162, 162, 0.26);

    .el-dropdown-menu__item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 13px;
      font-size: 16px;
      line-height: 30px;
      color: #000000;
    }

    .active {
      background: #eeeeee;
    }

    /deep/ .popper__arrow {
      display: none;
    }
  }
</style>
