<template>
  <div class="swiper-container" ref="swiper">
    <div class="swiper-wrapper">
      <slot />
    </div>
  </div>

</template>

<script>
  import { Vue, Component, Prop } from 'vue-property-decorator';
  // eslint-disable-next-line import/no-named-as-default
  import Swiper from 'swiper';

  @Component
  export default class AppSwiper extends Vue {
    @Prop(Function) getInstance;

    created() {
      window.addEventListener('load', this.initSwiper);
    }

    mounted() {
      this.initSwiper();
      // 确保swiper可以初始化成功
      setTimeout(() => {
        this.initSwiper();
      }, 500);
    }

    initSwiper() {
      if (this.swiper) {
        this.swiper.update();
        return;
      }
      this.swiper = new Swiper(this.$refs.swiper, {
        slidesPerView: 1,
        ...this.$attrs,
        on: this.$listeners,
        autoHeight: true,
      });
      if (this.getInstance) {
        this.getInstance(this.swiper);
      }
    }

    beforeDestroy() {
      window.removeEventListener('load', this.initSwiper);
      this.swiper.destroy();
    }
  }
</script>
