<i18n src="./locales.json"></i18n>

<template>
  <div>
    <v-title :value="$t('store')"/>
    <skeleton :onFetch="onFetch" v-model="loading"/>
    <template v-if="!loading">
      <div class="head-wrap">
        <common-banner
          :images="banners"
          :oss-img="$root.windowWidth >= 768 ? {
          process: 'image/quality,q_90/format,jpg/interlace,1'
        } : {
          resize: { w: 750 }
        }"
        >
          <template v-slot="{ item }"><banner-content :item="item"/></template>
        </common-banner>
        <div class="common-input-wrap">
          <common-input :categories="productCategories.data" />
        </div>
      </div>
      <div class="store-wrap">
        <custom-page slug="mall" :components="$get(page, 'config.components')" />
      </div>
    </template>
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';
  import { getCustomPage } from '@/utils';
  import { followProductIdsStore, Collection, auctionNumbersStore } from '@/store';
  import Skeleton from './components/index-skeleton';

  @Component({ components: { Skeleton } })
  export default class Store extends Vue {
    banners = [];
    page = {};
    loading = true
    slug = 'mall'
    productCategories = new Collection({
      fetch: params => this.$fly.get('product_categories', { ...params, per_page: 99, parent_id_null: true })
    })

    async onFetch() {
      const [{ data: banners }, { data: page }] = await Promise.all([
        this.$fly.get('banners', { location_eq: 'mall_page', channel_eq: 'web' }),
        getCustomPage(this.slug),
        this.productCategories.tryFetchData(),
        auctionNumbersStore.tryFetchData(),
        followProductIdsStore.tryFetchData({ checkAuth: false })
      ]);
      this.banners = banners;
      this.page = page;
    }
  }
</script>

<style lang="scss">
  .fixed-category {
    &.el-popper.el-cascader__dropdown {
      @include media-xs-only {
        position: fixed !important;
      }
    }
  }
</style>

<style lang="scss" scoped>
  .head-wrap {
    @include media-xs-only {
      position: sticky;
      z-index: 999;
      top: 45px;
      left: 0;
      background: #fff;
    }
  }

  .common-input-wrap {
    margin-bottom: -26px;

    @include media-sm-and-up {
      margin-bottom: 0px;
    }
  }

  .store-wrap {
    padding-bottom: 5px;

    @include media-sm-and-up {
      padding-bottom: 30px;
    }
  }
</style>
