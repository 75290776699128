<template>
  <div class="pages container">
    <v-title :value="aboutUsStore.name" />
    <div class="pages-wrap" v-html="aboutUsStore.content"></div>
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';
  import { fly } from '@/utils';

  @Component
  export default class Pages extends Vue {
    aboutUsStore = {};

    async created() {
      const { slug } = this.$route.params;
      const { data } = await this.$autoLoading(fly.get(`pages/${slug}`));
      this.aboutUsStore = data;
    }
  }
</script>

<style lang="scss" scoped>
  .pages-wrap {
    padding: 20px 0;
  }
</style>
