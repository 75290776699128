<template>
  <div class="component-header-wrapper">
    <div class="header">
      <router-link class="icon-message-wrapper hidden-sm-and-up" :to="{ name: 'messageChannels' }">
        <img class="icon-message" src="@/assets/icon-message.png" />
        <div class="badge" v-if="authStore.user.has_unread_message"></div>
      </router-link>
      <router-link v-if="!$auctionHouseStore.domainHouse" :to="{ name: 'home' }">
        <img src="@/assets/icon-logo.png" class="logo"/>
      </router-link>
      <component
        v-else
        :is="$auctionHouseStore.domainHouse.official_website_link ? 'a' : 'router-link'"
        class="house-name text-overflow"
        v-bind="$auctionHouseStore.domainHouse.official_website_link ? { href: $auctionHouseStore.domainHouse.official_website_link, target: '_blank' } : { to: { name: 'home' } } "
      >
        {{ $auctionHouseStore.domainHouse.name }}
      </component>
      <div class="house-link-list flex hidden-xs-only" :class="{ link: !$auctionHouseStore.domainHouse }">
        <router-link
          v-for="item in menus"
          :key="item.routeName"
          :to="{ name: item.routeName, query: item.query }"
          class="house-title"
          :class="{ title: !$auctionHouseStore.domainHouse, active: item.routeName === current }"
        >
          {{ $t(item.label) }}
        </router-link>
      </div>
      <div class="flex item-center">
        <router-link
          v-if="$route.name !== 'login'"
          :to="$authStore.isLogin ? { name: 'mine' } : { name: 'login', query: { redirect: encodeURIComponent($route.fullPath) } }"
          class="text text-overflow"
        >
          <span v-if="showNickName">{{ nickName }}</span>
          <img v-else src="@/assets/icon-login.png" class="icon-login"/>
        </router-link>
        <router-link class="icon-message-wrapper hidden-xs-only" :to="{ name: 'messageChannels' }">
          <img class="icon-message" src="@/assets/icon-message.png" />
          <div class="badge" v-if="authStore.user.has_unread_message"></div>
        </router-link>
        <img src="@/assets/icon-menu.png" class="icon-menu" @click="showSideMenu = true" />
      </div>
    </div>
    <portal to="app-side-meun">
      <side-menu v-model="showSideMenu"/>
    </portal>
  </div>
</template>

<script>
  import { Vue, Component, Watch } from 'vue-property-decorator';
  import SideMenu from './side-menu';
  import { authStore } from '@/store';

  @Component({
    components: {
      'side-menu': SideMenu,
    }
  })
  export default class Header extends Vue {
    showSideMenu = false;
    authStore = authStore;

    @Watch('$route', { immediate: true })
    handler(val) {
      this.current = val.name;
    }

    get menus() {
      const { custom_pages_count, guides_count } = this.$get(this.$auctionHouseStore, 'domainHouseDetail', {});
      const mainDomainHouseMenus = !this.$auctionHouseStore.domainHouse ? [
        { label: 'store', routeName: 'store' },
        { label: 'auctions', routeName: 'auctions' },
        { label: 'topic', routeName: 'topics' },
        { label: 'live', routeName: 'LiveBroadcasts' }
      ] : [
        custom_pages_count && { label: 'topic', routeName: 'topics' },
        guides_count && { label: 'guide', routeName: 'guides' },
      ].filter(Boolean);

      return [
        { label: 'home', routeName: 'home' },
        ...mainDomainHouseMenus,
        { label: 'mine', routeName: 'mine' }
      ];
    }

    get showNickName() {
      if (this.$auctionHouseStore.domainHouse) {
        return !this.$isMobile() && this.$authStore.isLogin;
      } else {
        return this.$authStore.isLogin;
      }
    }

    get nickName() {
      const { name, last_name, phone } =  this.$authStore.user;
      return (last_name + name) || phone;
    }
  }
</script>

<style lang="scss" scoped>
  .component-header-wrapper {
    position: sticky;
    z-index: var(--fixed-z-index);
    top: 0;
    width: 100%;
    height: var(--header-height);
    padding: 0 15px;
    background: #000;

    .header {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;

      .icon-message-wrapper {
        position: relative;

        .icon-message {
          width: 18px;
          height: 18px;
        }

        .badge {
          position: absolute;
          top: 1px;
          right: -2px;
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background: $primary;
        }

        @include media-sm-and-up {
          margin-left: 27px;
        }
      }

      .logo {
        width: 100px;

        @include media-xs-only {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 81px;
          vertical-align: middle;
          transform: translate(-50%, -50%);
        }
      }

      .text {
        font-weight: 500;
        font-size: 14px;
        color: #fff;

        @include media-xs-only {
          max-width: 70px;
          font-size: 16px;
        }
      }

      .icon-menu {
        width: 15px;
        height: 15px;
        margin-left: 10px;
        cursor: pointer;

        @include media-sm-and-up {
          margin-left: 27px;
        }
      }

      .icon-login {
        display: flex;
        width: 15px;
        height: 15px;
        margin-left: 10px;
        cursor: pointer;
      }

      .house-name {
        font-size: 20px;
        color: #fff;

        @include media-xs-only {
          position: absolute;
          top: 50%;
          left: 50%;
          width: fit-content;
          font-size: 16px;
          transform: translate(-50%, -50%);
        }
      }

      .house-link-list {
        @include media-md-and-up {
          padding-left: 0;
        }

        @include media-lg-and-up {
          padding-left: 250px;
        }

        &.link {
          justify-content: space-between;
        }

        .house-title {
          display: block;
          padding: 15px 20px;
          font-size: 14px;
          color: #fff;

          &.title {
            padding: 15px 0px;
          }

          &:hover {
            color: var(--color-link);
          }

          &.active {
            color: var(--color-link);
          }

          &:not(:last-child) {
            @include media-sm-and-up {
              margin-right: 20px;
            }

            @include media-lg-and-up {
              margin-right: 45px;
            }
          }
        }
      }
    }
  }
</style>
