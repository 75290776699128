<i18n src="../locales.json"></i18n>

<template>
  <div class="mobile-product-info flex column hidden-md-and-up">
    <div>
      <div class="tag">{{ $get(store, 'data.product_category.title') }}</div>
      <div class="name">{{ $get(store, 'data.title') }}</div>
    </div>
    <div class="divider"></div>
    <div>
      <div class="action-box">
        <div
          class="primary-btn"
          @click="handleParticipate"
          v-if="!store.isFinished && !showStartCountDown"
        >
          {{ $t(store.onlyBuyNowProduct ? 'directPurchase' : 'participateBidding') }}
        </div>
        <div class="primary-btn disabled" v-else-if="store.isFinished">{{ $t('ended') }}</div>
        <div class="primary-btn disabled" v-else-if="showStartCountDown">{{ $t('notStart') }}</div>
      </div>
      <!-- 已流拍 -->
      <div class="aborted flex item-center" v-if="aborted">
        <div>{{ $t('aborted') }}</div>
        <div class="aborted-bottom-price">
          <bottom-price :product="store.data" class="bottom-price" />
        </div>
      </div>
      <div class="price-wrapper" v-else>
        <!-- 保证金 -->
        <margin-amount-tip :store="store"/>
        <!-- 成交价格 -->
        <div v-if="$get(store, 'data.deal_price')" class="current-price">
          <div class="flex column">
              <div class="flex item-center">
              <span class="amount">{{ $get(store, 'data.deal_price') | currencyFormat }}</span>
              <span v-if="!$options.filters.isLocaleCurrency($get(store, 'data.currency'))">
                ( ≈ {{ $t('currency') }} <span class="primary">{{ $get(store, 'data.deal_price') | conversionFormat($get(store, 'data.currency')) | currencyFormat }}</span> )
              </span>
            </div>
            <span class="label">{{ $t('dealPrice') }}<span class="currency text-uppercase">({{ $get(store, 'data.currency') }})</span></span>
          </div>
          <bottom-price :product="store.data" class="bottom-price" />
        </div>
        <!-- 一口价商品价格 -->
        <div v-else-if="store.onlyBuyNowProduct" class="current-price flex column content-center">
          <div class="aborted flex item-center content-center" v-if="aborted">
            {{ $t('aborted') }}
          </div>
          <div class="flex column" v-else>
              <div class="flex item-center">
              <span class="amount">{{ $get(store, 'data.confirmed_price') | currencyFormat }}</span>
              <span v-if="!$options.filters.isLocaleCurrency($get(store, 'data.currency'))">
                ( ≈ {{ $t('currency') }} <span class="primary">{{ $get(store, 'data.confirmed_price') | conversionFormat($get(store, 'data.currency')) | currencyFormat }}</span> )
              </span>
            </div>
            <span class="label">{{ $t('buyNowPrice') }} <span class="currency text-uppercase">({{ $get(store, 'data.currency') }})</span></span>
          </div>
          <bottom-price :product="store.data" class="bottom-price" />
        </div>
        <!-- 竞价商品价格 -->
        <template v-else>
          <div :class="['current-price', { auction: !store.auctionAndBuyNowProduct }]">
            <div class="box">
              <div class="price-box flex item-center">
                <span class="currency text-uppercase">{{ $get(store, 'data.currency') }}</span>
                <span class="amount">{{ $get(store, 'currentPrice') | currencyFormat }}</span>
                <span v-if="!$options.filters.isLocaleCurrency($get(store, 'data.currency'))">
                  ( ≈ {{ $t('currency') }} <span class="primary">{{ $get(store, 'currentPrice') | conversionFormat($get(store, 'data.currency')) | currencyFormat }}</span> )
                </span>
              </div>
              <span class="label">{{ $t('currentPrice') }} <span class="currency text-uppercase">({{ $get(store, 'data.currency') }})</span></span>
            </div>
          </div>
          <div v-if="store.auctionAndBuyNowProduct" class="current-price">
            <div class="box">
              <div class="price-box flex item-center">
                <span class="currency text-uppercase">{{ $get(store, 'data.currency') }}</span>
                <span class="amount">{{ $get(store, 'data.confirmed_price') | currencyFormat }}</span>
                <span v-if="!$options.filters.isLocaleCurrency($get(store, 'data.currency'))">
                  ( ≈ {{ $t('currency') }} <span class="primary">{{ $get(store, 'data.confirmed_price') | conversionFormat($get(store, 'data.currency')) | currencyFormat }}</span> )
                </span>
              </div>
              <span class="label">{{ $t('buyNowPrice') }} <span class="currency text-uppercase">({{ $get(store, 'data.currency') }})</span></span>
            </div>
          </div>
          <div class="current-price" v-if="$get(store, 'data.current_price')">
            <div class="box">
              <div class="flex item-center">
                <span class="currency text-uppercase">{{ $get(store, 'data.currency') }}</span>
                <span class="amount" v-if="store.auctionAndBuyNowProduct">{{ (store.nextLowestPrice > store.isBuyNowProductPrice ? store.isBuyNowProductPrice : store.nextLowestPrice) | currencyFormat }}</span>
                <span class="amount" v-if="store.onlyAuctionProduct">{{ store.nextLowestPrice | currencyFormat }}</span>
                <span v-if="!$options.filters.isLocaleCurrency($get(store, 'data.currency'))">
                  ( ≈ {{ $t('currency') }}
                    <span class="primary" v-if="store.auctionAndBuyNowProduct">
                      {{ (store.nextLowestPrice > store.isBuyNowProductPrice ? store.isBuyNowProductPrice : store.nextLowestPrice) | conversionFormat($get(store, 'data.currency')) | currencyFormat }}
                    </span>
                    <span class="primary" v-if="store.onlyAuctionProduct">
                      {{ store.nextLowestPrice | conversionFormat($get(store, 'data.currency')) | currencyFormat }}
                    </span>
                  )
                </span>
              </div>
              <span class="label">{{ $t('nextMiniPrice') }} <span class="currency text-uppercase">({{ $get(store, 'data.currency') }})</span></span>
            </div>
          </div>
          <bottom-price :product="store.data" />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
  import { Vue, Component, Prop } from 'vue-property-decorator';
  import { authStore } from '@/store';
  import BottomPrice from './bottom-price.vue';
  import MarginAmountTip from './margin-amount-tip.vue';

  @Component({
    components: {
      'bottom-price': BottomPrice,
      'margin-amount-tip': MarginAmountTip
    }
  })
  export default class MobileProductInfo extends Vue {
    @Prop({ type: Object, default: () => {} }) store;

    authStore = authStore;

    get showStartCountDown() {
      return this.$get(this.store, 'data.started_bidding_time') && this.store.isNotStart;
    }

    get aborted() {
      return this.$get(this.store, 'data.bid_status') === 'aborted';
    }

    // 如果没有上传任何证件，没有填写证件有效期，提示用户填写后再进行下一步
    async handleParticipate() {
      if (!authStore.isLogin) {
        await this.$confirm(this.$t('logFirst'));
        return this.$router.push({ name: 'login', query: { redirect: encodeURIComponent(this.$route.fullPath) } });
      }
      if (this.$authStore.isLoadCredential) {
        if (this.$authStore.isLoadEffectiveDate) {
          await this.$confirm(this.$t('uploadValidityPeriod'));
          return this.$router.push({ name: 'myAddress' });
        }
        return this.$router.push({ name: 'productAuction', params: { id: this.$route.params.id }, query: { isPreview: this.isPreview } });
      } else {
        await this.$confirm(this.$t('uploadTip'));
        return this.$router.push({ name: 'myAddress' });
      }
    }
  }
</script>

<style lang="scss" scoped>
  .mobile-product-info {
    position: sticky;
    z-index: var(--fixed-z-index);
    top: var(--header-height);
    display: flex;
    padding: 10px;
    background-color: #fff;

    @include media-sm-and-up {
      top: calc(var(--header-height) + var(--auction-item-toggle-height))
    }

    .tag {
      float: left;
      padding: 1px 4px;
      margin-right: 5px;
      border: 1px solid $primary;
      border-radius: 2px;
      font-size: 9px;
      color: $primary;
    }

    .name {
      font-weight: 600;
      font-size: 16px;
      word-break: break-all;
    }

    .divider {
      margin: 5px 0;
      border-top: 1px solid #f3f3f3;
    }

    .aborted {
      padding: 10px 0;
      font-weight: 500;
    }

    .price-wrapper {
      .current-price {
        font-weight: 400;
        font-size: 12px;
        color: #c5c5c5;

        &:first-child {
          padding-bottom: 3px;
          border-bottom: 1px solid #f3f3f3;

          .currency {
            display: none;
          }

          .label .currency {
            display: inline;
          }
        }

        &:not(:first-child) {
          margin-top: 10px;

          .box {
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            align-items: center;
            font-size: 12px;

            .amount {
              font-weight: 500;
              font-size: 12px;
              color: $primary;
            }

            .label .currency {
              display: none;
            }
          }
        }

        &.margin-amount {
          .amount {
            margin-left: 5px;
            font-size: 12px;
          }
        }

        .amount {
          margin-right: 5px;
          font-weight: 500;
          font-size: 20px;
          color: $primary;
        }

        .currency {
          margin-right: 5px;
        }

        .primary {
          color: $primary;
        }
      }

      .primary-btn-o {
        width: fit-content;
        height: 30px;
        padding: 0 18px;
        margin-top: 20px;
        font-size: 13px;
        color: #330000;
      }
    }

    .action-box {
      float: right;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 5px;

      .primary-btn {
        height: 32px;
        padding: 0 15px;
        margin: 0 0 0 5px;
        font-size: 12px;
      }
    }
  }

  .aborted-bottom-price {
    margin-left: 5px;
  }
</style>
