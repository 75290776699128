<template>
  <div class="skeleton" v-if="loading">
    <el-progress :percentage="percentage" :show-text="false"/>
    <skeleton-banner/>
    <div class="container">
      <common-title/>
      <div class="scroll-wrapper">
        <div class="item">
          <skeleton-auction-card/>
        </div>
        <div class="item">
          <skeleton-auction-card/>
        </div>
        <div class="item">
          <skeleton-auction-card/>
        </div>
      </div>

      <common-title/>
      <div class="products-wrapper">
        <el-row :gutter="8" justify="space-between">
          <el-col :xs="12" :sm="6" :md="4" v-for="i in 6" :key="i">
            <skeleton-product-item/>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
  import { Component, Vue } from 'vue-property-decorator';
  import Skeleton from '@/components/skeleton/mixins';

  @Component({ mixins: [Skeleton] })
  export default class IndexSkeleton extends Vue {
  }
</script>

<style lang="scss" scoped>
  .container {
    /deep/ .component-title {
      margin-top: 20px;
      margin-bottom: 20px;

      @include media-sm-and-up {
        margin: 60px 0 40px;
      }
    }
  }

  .scroll-wrapper {
    display: flex;
    margin: 10px 0 0;

    .item {
      margin: 0 5px;
      cursor: pointer;

      .auction-card-wrapper {
        min-height: 100%;
      }
    }
  }

  .products-wrapper {
    margin-bottom: 50px;

    /deep/ .component-product-item {
      margin-bottom: 10px;
    }
  }
</style>
