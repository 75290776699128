import Vue from 'vue';
export { decodeQuery, encodeQuery } from './query';

import { fly } from './fly';
import { sleep } from './sleep';
import { setTitle } from './set-title';
import { isMobile } from './is-mobile';
import previewImage from './preview-image';
import './prompt';

Vue.prototype.$fly = fly;
Vue.prototype.$sleep = sleep;
Vue.prototype.$setTitle = setTitle;
Vue.prototype.$previewImage = previewImage;
Vue.prototype.$isMobile = isMobile;

export {
  fly, sleep, setTitle
};

export { decoder } from './decoder';
export { countdown } from './countdown';
export { randomString } from './random';
export { formVerification } from './form-verification';
export { uploadFile } from './upload';
export { getCustomPage } from './get-custom-page';
export { initSubscription, updateProduct } from './auto-refresh-products';
export { Storage, ArrayStorage } from './storage';
export { setMeta } from './set-meta';
export { download } from './download';
export { notifyCustomer } from './notify-customer';
