import { render, staticRenderFns } from "./collection.vue?vue&type=template&id=c59fa866&scoped=true&"
import script from "./collection.vue?vue&type=script&lang=js&"
export * from "./collection.vue?vue&type=script&lang=js&"
import style0 from "./collection.vue?vue&type=style&index=0&id=c59fa866&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c59fa866",
  null
  
)

/* custom blocks */
import block0 from "./locales.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2FUsers%2Fquake%2Fworkspace%2Fauction%2Ftokyo-auction-frontend%2Fsrc%2Fviews%2Fmine%2Fcollection.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports