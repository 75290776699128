<i18n>
{
  "zh_cn": {
    "inLive": "直播中",
    "pending": "即将开始",
    "playback": "直播回放"
  },
  "zh_hk": {
    "inLive": "直播中",
    "pending": "即將開始",
    "playback": "直播回放"
  },
  "en": {
    "inLive": "Live",
    "pending": "Coming soon",
    "playback": "Replay"
  },
  "ja": {
    "inLive": "実況中",
    "pending": "まもなく開始",
    "playback": "リプレイ"
  }
}
</i18n>

<template>
  <router-link class="live-card" :to="routeTo">
    <auto-height :height="autoHeight" :cover="$get(live, 'image.url') || live.image || $get(live, 'top_cover.url')" class="cover"/>
    <div class="mark"></div>
    <div v-if="$slots.footer">
      <slot name="footer"></slot>
    </div>
    <div v-else>
      <div v-if="showLable" class="label" :class="label.class">
        <div v-if="live.status === 'pending'">
          {{ live.start_at | timeFormat('MM-DD HH:mm') }} {{$t('start')}}
        </div>
        <div v-else>{{ label.text }}</div>
        <img v-if="live.status === 'in_live'" src="@/assets/icon-live-audio.png" class="icon">
      </div>
      <div class="theme-title text-overflow">{{ live.name }}</div>
    </div>
  </router-link>
</template>

<script>
  import { Vue, Component, Prop } from 'vue-property-decorator';

  @Component
  export default class LiveCard extends Vue {
    @Prop({ type: Object, default: () => ({}) }) live;
    @Prop({ type: String, default: '50%' }) autoHeight;
    @Prop({ type: Object, default: () => ({ name: '' }) }) routeTo;

    get label() {
      return {
        in_live: { class: 'live', text: this.$t('inLive') },
        pending: { class: 'pending', text: this.$t('pending') },
        finished: { class: 'finished', text: this.$t('playback') },
      }[this.live.status] || {};
    }

    get showLable() {
      return this.live.status !== 'finished' || (this.live.status === 'finished' && this.live.record_urls.length);
    }
  }
</script>

<style lang="scss" scoped>
  .live-card {
    position: relative;
    display: block;
    margin-bottom: 15px;
    cursor: pointer;

    /deep/ .auto-height-content {
      border-radius: 4px;
      overflow: hidden;
    }

    .mark {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      border-radius: 4px;
      background: rgba(0, 0, 0, 0.2);
    }

    .cover {
      width: 100%;
    }

    .label {
      position: absolute;
      bottom: 10px;
      right: 10px;
      height: 20px;
      padding: 0 8px;
      border-radius: 2px;
      font-size: 10px;
      line-height: 20px;
      color: #fff;
      background: $primary;

      &.pending {
        background: $black;
      }

      &.finished {
        background: $gray;
      }

      .icon {
        width: 10px;
        margin-left: 4px;
        vertical-align: middle;
      }
    }

    .theme-title {
      position: absolute;
      bottom: 10px;
      left: 10px;
      max-width: 220px;
      font-size: 14px;
      color: #fff;
    }
  }
</style>
