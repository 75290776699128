export const zh_cn = {
  networkError: '网络连接已断开，请刷新重试',
  auction: '拍卖',
  auctionTime: '拍卖时间',
  auctionPlace: '拍卖地点',
  lotDateAndPlace: '日期 / 地点',
  lotNumber: '拍品编号',
  lotQty: '拍品件数',
  navToAuctionLive: '前往拍卖直播间',
  liveBroadcast: '直播列表',
  guideList: '精選影片',
  productTimeLeft: '还剩',
  start: '开始',
  day: '日',
  hour: '时',
  minute: '分',
  finished: '已结束',
  willStart: '待开始',
  offline: '线下限定',
  startPrice: '起拍价',
  estimatePending: '估价待询',
  estimatedPrice: '估价',
  pieces: '{count}件',
  myWallet: '我的钱包',
  myDeal: '我的成交',
  myBidding: '竞投记录',
  myCollection: '我的收藏',
  mySettings: '我的设置',
  myAddress: '我的地址',
  myAuctionNumber: '我的牌号',
  login: '登录',
  goTologin: '去登录',
  signup: '注册',
  userInfo: '个人信息',
  homeName: '首页 - BiddingArt',
  home: '首页',
  auctions: '拍卖',
  live: '直播',
  store: '商城',
  mine: '设定',
  mineAccount: '我的账户',
  settings: '我的设置',
  deal: '我的成交',
  currency: 'CNY',
  bidTips: '*温馨提示：当前拍品仅限线下出价',
  share: '分享',
  hint: '提示',
  confirm: '确认',
  cancel: '取消',
  changeBtn: '更改',
  close: '收起',
  open: '展开',
  auctionFinished: '拍卖即将结束，请尽快出价',
  congratulations: '恭喜您获得拍品',
  loading: '加载中',
  wechatShare: '微信扫一扫：分享',
  prev: '上一件',
  next: '下一件',
  marginAmountRequire: '*保证金要求',
  dealPrice: '成交价格',
  currentPrice: '当前价格',
  myPrice: '我的出价:',
  aborted: '已结束',
  personalInfoHint: '请先去完善您的个人信息',
  paymentSuccess: '支付成功',
  paymentFailed: '支付失败',
  evaluation: '总评价：',
  emptyData: '暂无数据',
  willEnd: '即将结束',
  companyDetail: '公司简介',
  chooseCategory: '选择分类',
  productList: '限时拍拍品推荐',
  categoryList: '拍品分类列表',
  willEndproductList: '即将结束的拍品列表',
  endedProductList: '已结束的拍品列表',
  hotAuctionItemList: '热门拍品列表',
  confirmPriceProductList: '一口价商品列表',
  productSearch: '搜索商品',
  search: '搜索',
  ferfectInformation: '请先完善您的信息',
  logFirst: '请先登录',
  validateEmailTip: '不是有效的电子邮箱',
  marginTip: '为充值额度的',
  multiple: '倍',
  marginRatioTip: '充值额度与当前额度比例',
  withdrawConfirm: '确定',
  uploadTip: '请先上传您的证件信息',
  uploadValidityPeriod: '请先填写您的证件有效期',
  password: '请输入密码',
  newPassword: '新密码',
  resetPassword: '重置密码',
  confirmPassword: '密码确认',
  passwordEmpty: '密码不能为空',
  passwordError: '密码由数字和字母组成、不少于8位',
  notSame: '两次密码输入不一致',
  phoneHolder: '请输入手机号码',
  validateCode: '验证码',
  validateHolder: '请输入六位数验证码',
  getValidateCode: '获取验证码',
  countTime: '剩余 {second}s',
  phoneError: '请填写正确的手机号',
  validateCodeError: '请输入正确的验证码',
  validateCodeSent: '验证码已发送',
  resetSuccess: '设置密码成功，请重新登录',
  preview_mode: '预览模式',
  last_name: '请输入姓氏',
  receiver_name: '请输入名字',
  country_name: '请输入国家/地区',
  province_name: '请输入州/省/地区',
  city_name: '请输入城/镇',
  detail_info: '请输入地址',
  postal_code: '请输入区号',
  tel_number: '请输入电话号码',
  auctionItem: '同步拍',
  normalItem: '限时拍',
  isPendding: '最新店铺上架商品',
  inquiry: '咨询问题',
  followSucceeded: '关注成功',
  unfollowSuccessfully: '取消关注',
  more: '更多',
  participateBidding: '参与竞投',
  keyword: '关键字',
  topic: '专题',
  guide: '导览',
  bottomPriceTip: '注意：阁下此出价未达拍品底价。当竞投以此出价结束，此拍品会视为不成交。如阁下对此拍品有兴趣，可继续出价',
  auction_application: '委托拍卖',
  my_entrust: '我的委托',
  pdf_export: 'PDF导出',
  download_app: '下载APP',
  goToUpdate: '去更新',
};

export const zh_hk = {
  networkError: '網絡連接已斷開，請重繪重試',
  auction: '拍賣',
  auctionTime: '拍賣時間',
  auctionPlace: '拍賣地點',
  lotDateAndPlace: '日期 / 地點',
  lotNumber: '拍品編號',
  lotQty: '拍品件數',
  navToAuctionLive: '前往拍賣直播',
  liveBroadcast: '直播列表',
  guideList: '精選影片',
  productTimeLeft: '還剩',
  start: '開始',
  day: '日',
  hour: '時',
  minute: '分',
  finished: '已結束',
  willStart: '待開始',
  offline: '線下限定',
  startPrice: '起拍價',
  estimatePending: '估價待詢',
  estimatedPrice: '估價',
  pieces: '{count}件',
  myWallet: '我的錢包',
  myDeal: '我的成交',
  myBidding: '競投紀錄',
  myCollection: '我的收藏',
  mySettings: '我的設置',
  myAddress: '我的地址',
  myAuctionNumber: '我的牌號',
  login: '登錄',
  goTologin: '去登錄',
  signup: '註冊',
  userInfo: '個人信息',
  homeName: '首頁 - BiddingArt',
  home: '首頁',
  auctions: '拍賣',
  live: '直播',
  store: '商城',
  mine: '設定',
  mineAccount: '我的賬戶',
  settings: '我的設置',
  deal: '我的成交',
  currency: 'HKD',
  bidTips: '*溫馨提示：當前拍品僅限線下出價',
  share: '分享',
  hint: '提示',
  confirm: '確認',
  cancel: '取消',
  changeBtn: '更改',
  close: '收起',
  open: '展開',
  auctionFinished: '拍賣即將結束，請盡快出價',
  congratulations: '恭喜您獲得拍品',
  loading: '加載中',
  wechatShare: '微信掃一掃：分享',
  prev: '上一件',
  next: '下一件',
  marginAmountRequire: '*保證金要求',
  dealPrice: '成交價格',
  currentPrice: '當前價格',
  myPrice: '我的出價:',
  aborted: '已結束',
  personalInfoHint: '請先去完善您的個人信息',
  paymentSuccess: '支付成功',
  paymentFailed: '支付失敗',
  evaluation: '總評價：',
  emptyData: '暫無數據',
  willEnd: '即將結束',
  companyDetail: '公司簡介',
  chooseCategory: '選擇分類',
  productList: '限時拍拍品推薦',
  categoryList: '拍品分類列表',
  willEndproductList: '即將結束拍品列表',
  endedProductList: '已結束的拍品列表',
  hotAuctionItemList: '熱門拍品列表',
  confirmPriceProductList: '一口價商品列表',
  productSearch: '搜索商品',
  search: '搜索',
  ferfectInformation: '請先完善您的信息',
  logFirst: '請先登錄',
  validateEmailTip: '不是有效的電子郵箱',
  marginTip: '為充值額度的',
  multiple: '倍',
  marginRatioTip: '充值額度與當前額度比例',
  withdrawConfirm: '確定',
  uploadTip: '請先上傳您的證件資訊',
  uploadValidityPeriod: '請先填寫您的證件有效期',
  password: '請輸入密碼',
  newPassword: '新密碼',
  resetPassword: '重置密碼',
  confirmPassword: '密碼確認',
  passwordEmpty: '密碼不能為空',
  passwordError: '密碼由數字同字母組成、不少於8位',
  notSame: '兩次密碼輸入不一緻',
  phoneHolder: '請輸入手機號碼',
  validateCode: '驗證碼',
  validateHolder: '請輸入六位數驗證碼',
  getValidateCode: '獲取驗證碼',
  countTime: '剩餘 {second}s',
  phoneError: '請填寫正確的手機號',
  validateCodeError: '請輸入正確的驗證碼',
  validateCodeSent: '驗證碼已發送',
  resetSuccess: '設置密碼成功，請重新登錄',
  preview_mode: '預覽模式',
  last_name: '請輸入姓氏',
  receiver_name: '請輸入名字',
  country_name: '請輸入國家/地區',
  province_name: '請輸入州/省/地區',
  city_name: '請輸入城/鎮',
  detail_info: '請輸入地址',
  postal_code: '請輸入區號',
  tel_number: '請輸入電話號碼',
  auctionItem: '同步拍',
  normalItem: '限時拍',
  isPendding: '最新店鋪上架商品',
  inquiry: '諮詢問題',
  followSucceeded: '關注成功',
  unfollowSuccessfully: '取消關注',
  more: '更多',
  participateBidding: '參與競投',
  keyword: '關鍵字',
  topic: '專題',
  guide: '導覽',
  bottomPriceTip: '注意：閣下此出價未達拍品底價。當競投以此出價結束，此拍品會視為不成交。如閣下對此拍品有興趣，可繼續出價。',
  auction_application: '委託拍賣',
  my_entrust: '我的委託',
  pdf_export: 'PDF導出',
  download_app: '下載APP',
  goToUpdate: '去更新',
};

export const en = {
  networkError: 'Network connection has been disconnected, please refresh and try again',
  auction: 'Auction',
  auctionTime: 'Auction Date',
  auctionPlace: 'Auction Venue',
  lotDateAndPlace: 'Date / Venue',
  lotNumber: 'Lot',
  lotQty: 'Pieces',
  navToAuctionLive: 'Live Auction',
  liveBroadcast: 'Live broadcast list',
  guideList: 'Video Gallery',
  productTimeLeft: 'Left',
  start: 'Start',
  day: 'd',
  hour: 'hr | hrs',
  minute: 'min | mins',
  finished: 'Closed',
  willStart: 'Upcoming',
  offline: 'Offline Sale',
  startPrice: 'Starting Price',
  estimatePending: 'Estimate on request',
  estimatedPrice: 'Estimated Price',
  pieces: '{count}piece | {count}pieces',
  myWallet: 'My Wallet',
  myDeal: 'My Transaction',
  myBidding: 'Bidding history',
  myCollection: 'My Collection',
  mySettings: 'My Settings',
  myAddress: 'My Address',
  myAuctionNumber: 'My auction number',
  login: 'Login',
  goTologin: 'To login',
  signup: 'Sign Up',
  userInfo: 'Personal Information',
  homeName: 'Home - BiddingArt',
  home: 'Home',
  auctions: 'Auctions',
  live: 'Live broadcast',
  store: 'Stores',
  mine: 'Settings',
  mineAccount: 'My bids',
  settings: 'My settings',
  deal: 'My Transaction',
  currency: 'USD',
  bidTips: '*Gentle reminder: this auction is only available for offline bidding',
  share: 'Share',
  hint: 'Tips',
  confirm: 'confirm',
  cancel: 'cancel',
  changeBtn: 'Change',
  close: 'Put away',
  open: 'Unfold',
  auctionFinished: 'This lot is closing soon, please place your bid as soon as possible',
  congratulations: 'Congratulations on your bidding the lot',
  loading: 'Loading',
  wechatShare: 'WeChat Scan and Share',
  prev: 'Prev',
  next: 'Next',
  marginAmountRequire: '*Deposit required.',
  dealPrice: 'Dealt Price',
  currentPrice: 'Current Price',
  myPrice: 'My bid:',
  aborted: 'Closed',
  personalInfoHint: 'Please complete your personal information first',
  paymentSuccess: 'Payment Success',
  paymentFailed: 'Payment Failed',
  evaluation: 'Overall evaluation：',
  emptyData: 'Empty data',
  willEnd: 'Is ending soon',
  companyDetail: 'Company Profile',
  chooseCategory: 'Categories',
  productList: 'Featured Items',
  categoryList: 'Product category list',
  willEndproductList: 'The list of product about to end',
  endedProductList: 'The list of product has ended',
  hotAuctionItemList: 'List of popular lots',
  confirmPriceProductList: 'List of Buy-It-Yourself Products',
  productSearch: 'Search items',
  search: 'Search',
  ferfectInformation: 'Please perfect your information first',
  logFirst: 'please log in first',
  validateEmailTip: 'email is not a valid email',
  marginRatioTip: 'Ratio of top-up amount to current limit',
  withdrawConfirm: 'Confirm',
  uploadTip: 'Please upload your id information first',
  uploadValidityPeriod: 'Please fill in the validity period of your certificate first',
  password: 'Please enter a password',
  newPassword: 'New password',
  resetPassword: 'Reset password',
  confirmPassword: 'Password confirmation',
  passwordEmpty: 'Password cannot be empty',
  passwordError: 'The password consists of numbers and letters, not less than 8 digits.',
  notSame: 'The two password entries are inconsistent',
  phoneHolder: 'Please input your phone number',
  validateCode: 'Validation Code',
  validateHolder: 'Please Input Six Digits Code',
  getValidateCode: 'Get Code',
  countTime: '{second}s Left',
  phoneError: 'Please input your correct phone number',
  validateCodeError: 'Please input the correct verification code',
  validateCodeSent: 'The verification code has been sent',
  resetSuccess: 'Password set successfully, please login again.',
  preview_mode: 'Preview mode',
  last_name: 'Please enter last name',
  receiver_name: 'Please enter the first name',
  country_name: 'Please enter country/region',
  province_name: 'Please enter a state/province/region',
  city_name: 'Please enter the city/town',
  detail_info: 'Please enter the address',
  postal_code: 'Please enter the postal code',
  tel_number: 'Please enter your phone number',
  auctionItem: 'LOT',
  normalItem: 'ITEM',
  isPendding: 'Latest items',
  inquiry: 'Inquiry',
  followSucceeded: 'Follow Successfully',
  unfollowSuccessfully: 'Unfollow Successfully',
  more: 'More',
  participateBidding: 'Place Bid',
  keyword: 'Keyword',
  topic: 'Topic',
  guide: 'Tour',
  bottomPriceTip: 'Note：Your bid does not reach the reserve price. If bidding ends before the reserve is reached, this lot will not be sold. You could continue bidding if you are interested in this lot.',
  auction_application: 'Auction application',
  my_entrust: 'My commission',
  pdf_export: 'PDF Export',
  download_app: 'Download APP',
  goToUpdate: 'Go to update',
};

export const ja = {
  networkError: '网络连接已断开、请刷新重试',
  auction: 'オークション',
  auctionTime: '開催日',
  auctionPlace: '会場',
  lotDateAndPlace: '開催日 / 会場',
  lotNumber: 'Lot',
  lotQty: 'オークション点数',
  navToAuctionLive: 'ライブオークション',
  liveBroadcast: 'ライブ配信',
  guideList: 'ビデオ ギャラリー',
  productTimeLeft: '残り',
  start: '開始',
  day: '日',
  hour: '時',
  minute: '分',
  finished: '終了',
  willStart: '開始予定',
  offline: 'オンライン以外の入札',
  startPrice: '開始価格',
  estimatePending: '価格はお問い合わせください',
  estimatedPrice: '推定価格',
  pieces: '{count}点',
  myWallet: '購入可能額',
  myDeal: '取引中',
  myBidding: '入札中',
  myCollection: 'ウォッチリスト',
  mySettings: '設定',
  myAddress: '住所',
  myAuctionNumber: '私のオークション番号',
  login: 'ログイン',
  goTologin: 'ログインしに行く',
  signup: '新規登録',
  userInfo: '個人情報',
  homeName: 'ホーム - BiddingArt',
  home: 'ホーム',
  auctions: 'オークション',
  live: 'ライブ配信',
  store: 'ショッピング',
  mine: '設定',
  mineAccount: 'マイページ',
  settings: '設定',
  deal: '取引中',
  currency: 'JPY',
  bidTips: '*このロットは現場、書面、電話入札のみとなります ',
  share: '共有',
  hint: 'ヒント',
  confirm: '確認',
  cancel: 'キャンセル',
  changeBtn: '変更する',
  close: '戻す',
  open: '表示する',
  auctionFinished: 'オークションはまもなく終了するので，早く入札してください。',
  congratulations: '落札おめでとうございます',
  loading: '読み込み中',
  wechatShare: 'WeChatQRコードをスキャン',
  prev: '前へ',
  next: '次へ',
  marginAmountRequire: '*保証金要求',
  dealPrice: '落札価格',
  currentPrice: '現在の価格',
  myPrice: '私のオファー:',
  aborted: '終了',
  personalInfoHint: '先に個人情報を入力してください',
  paymentSuccess: '支払い完了',
  paymentFailed: '支払いに失敗しました',
  evaluation: '総合評価：',
  emptyData: 'データなし',
  willEnd: 'まもなく終わる',
  companyDetail: '会社概要',
  chooseCategory: 'カテゴリーを選ぶ',
  productList: 'オンラインオークション注目商品',
  categoryList: '商品分類リスト',
  willEndproductList: 'もうすぐ終了',
  endedProductList: '商品終了',
  hotAuctionItemList: '人気ロット一覧',
  confirmPriceProductList: 'Buy-It-Yourself製品のリスト',
  productSearch: '商品を検索する',
  search: '探す',
  ferfectInformation: 'まずは情報を補完してください',
  logFirst: '最初にログインしてください',
  validateEmailTip: '有効な電子メールではありません',
  marginTip: 'チャージ額のための',
  multiple: '倍',
  marginRatioTip: '現在の額に対するチャージ額の割合',
  withdrawConfirm: '決定する',
  uploadTip: 'まず身分情報をアップロードしてください',
  uploadValidityPeriod: 'まず証明書の有効期間を記入してください',
  password: 'パスワードを入力してください',
  newPassword: '新しいパスワード',
  resetPassword: 'パスワードのリセット',
  confirmPassword: 'パスワードの確認',
  passwordEmpty: 'パスワードは空にできません',
  passwordError: 'パスワードは数字とアルファベットで構成され、8桁以上です',
  notSame: '2回のパスワード入力が一致しません',
  phoneHolder: '電話番号を記入してください',
  validateCode: '認証番号',
  validateHolder: '6桁の認証番号を入力してください',
  getValidateCode: '認証番号を取得する',
  countTime: '残り {second}s',
  phoneError: '携帯電話番号を誤って入力してしまった場合は、管理者までご 連絡ください。',
  validateCodeError: '入力された認証番号に誤りがあります。',
  validateCodeSent: '認証番号が送信されました',
  resetSuccess: 'パスワードの設定に成功しました。再度ログインしてください',
  preview_mode: 'プレビューモード',
  last_name: '姓を入力してください',
  receiver_name: '名前を入力してください',
  country_name: '国/地域を入力してください',
  province_name: '県/地域を入力してください',
  city_name: '市/町を入力してください',
  detail_info: '住所を入力してください',
  postal_code: '郵便番号を入力してください',
  tel_number: '電話番号を入力してください',
  auctionItem: 'ライブオークション',
  normalItem: 'ネットオークション',
  isPendding: '新着商品',
  inquiry: '質問をする',
  followSucceeded: 'フォロー済み',
  unfollowSuccessfully: 'キャンセル',
  more: 'より多くの',
  participateBidding: '入札',
  keyword: 'キーワード',
  topic: 'トピック',
  guide: 'ガイド',
  bottomPriceTip: '注:お客様の入札はまだ最低希望価格に達していません。この入札でオークションが終了した場合は不落札となります。こちらの商品に興味がございましたら、引き続き入札を続けて下さい。',
  auction_application: '委託オークション',
  my_entrust: '私の手数料',
  pdf_export: 'PDFエクスポート',
  download_app: 'ダウンロード APP',
  goToUpdate: 'アップデートに行く',
};
