<i18n>
  {
    "zh_cn": {
      "antique": "基于古物营业法表示",
      "tokushoho": "基于特定商取引法表示",
      "aboutUs": "关于我们",
      "introduction": "平台介绍",
      "businessScopes": "业务范围",
      "contactUs": "联系我们",
      "sellOn": "商铺入驻"
    },
    "zh_hk": {
      "antique": "基於古物營業法表示",
      "tokushoho": "基於特定商取引法表示",
      "aboutUs": "關於我們",
      "introduction": "平台介紹",
      "businessScopes": "業務範圍",
      "contactUs": "聯繫我們",
      "sellOn": "商舖入駐"
    },
    "en": {
      "antique": "Antique",
      "tokushoho": "Specified Commercial Transaction Law",
      "aboutUs": "About Us",
      "introduction": "Introduction",
      "businessScopes": "Business Scopes",
      "contactUs": "Contact Us",
      "sellOn": "Sell on BiddingArt"
    },
    "ja": {
      "antique": "古物営業法に基づく表示",
      "tokushoho": "特定取引法の表示",
      "aboutUs": "About Us",
      "introduction": "アプリ紹介",
      "businessScopes": "業務内容",
      "contactUs": "お問い合わせ",
      "sellOn": "店舗の出店"
    }
  }
</i18n>

<template>
  <div class="menu-list">
    <div class="group hidden-xs-only">
      <div class="title">
        <app-lang-switcher @close="$emit('input', false)"/>
      </div>
    </div>
    <div class="group" v-for="item in menus" :key="item.title">
      <router-link v-if="item.to" exact @click.native="$emit('input', false)" class="title text-overflow" :to="item.to">{{ getTitle(item) }}</router-link>
      <span v-else class="title text-overflow" @click="$emit('input', false)">{{ getTitle(item) }}</span>
      <div class="small-title-wrapper" v-if="item.children">
        <router-link
          v-for="child in item.children"
          :key="child.title"
          exact
          @click.native="$emit('input', false)"
          class="small-title"
          :to="child.to"
        >{{$t(child.title)}}</router-link>
      </div>
    </div>
  </div>
</template>

<script>
  import { Vue, Component, Model } from 'vue-property-decorator';

  @Component
  export default class LinkList extends Vue {
    @Model('input', { type: Boolean, default: false }) value

    menus = [
      {
        title: 'homeName',
        to: { name: 'home' }
      },
      {
        title: 'mineAccount',
        to: { name: 'mine' },
        children: [
          {
            title: 'myWallet',
            to: { path: '/mine/wallet' },
          },
          {
            title: 'myDeal',
            to: { path: '/orders' },
          },
          {
            title: 'myBidding',
            to: { path: '/mine/bidding' },
          },
          {
            title: 'myCollection',
            to: { path: '/mine/collection' },
          },
          {
            title: 'myAuctionNumber',
            to: { path: '/mine/auction-number' },
          },
          {
            title: 'mySettings',
            to: { path: '/mine/settings' },
          },
          {
            title: 'myAddress',
            to: { path: '/mine/address' },
          }
        ]
      },
      {
        title: 'my_entrust',
        children: [
          {
            title: 'auction_application',
            to: { name: 'auction-applications.new' },
          }
        ]
      },
      !this.$auctionHouseStore.domainHouse ?
      {
        title: 'aboutUs',
        to: { name: 'pages', params: { slug: 'about_us' } },
        children: [
          {
            title: 'introduction',
            to: { name: 'pages', params: { slug: 'introduction' } },
          },
          {
            title: 'businessScopes',
            to: { name: 'pages', params: { slug: 'business_scope' } },
          },
          {
            title: 'contactUs',
            to: { name: 'pages', params: { slug: 'contact_us' } },
          },
          {
            title: 'sellOn',
            to: { name: 'pages', params: { slug: 'selling' } },
          },
        ]
      } : {
        title: 'companyDetail',
        to: { name: 'DomainAuctionHouseDetail' },
        children: [
          {
            title: 'antique',
            to: { name: 'pages', params: { slug: 'antique' } },
          },
          {
            title: 'tokushoho',
            to: { name: 'pages', params: { slug: 'tokushoho' } },
          },
        ]
      },
      {
        title: this.$t('download_app'),
        to: { name: 'pages', params: { slug: 'download_app' } }
      },
    ]

    getTitle(item) {
      if (this.$auctionHouseStore.domainHouse && item.title === 'homeName') {
        return this.$t('home');
      } else {
        return this.$t(item.title);
      }
    }
  }
</script>

<style lang="scss" scoped>
 .menu-list {
    @include media-sm-and-up {
      display: flex;
      justify-content: space-between;
    }

    .title {
      padding: 15px 0;
      overflow: hidden;
      font-size: 18px;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #fff;
    }

    .small-title-wrapper {
      @include media-xs-only {
        padding-left: 20px;
      }

      .small-title {
        padding: 10px 0;
        font-size: 15px;
        color: #ccc;
      }
    }

    a.title,
    a.small-title {
      display: block;

      &:hover {
        color: var(--color-link);
      }

      &.router-link-active {
        color: var(--color-link);
      }
    }
  }
</style>
