<i18n src="./locales.json"></i18n>

<template>
  <div class="page-product-auction">
    <v-title :value="store.data.title" />
    <div class="box-countdown" v-if="$get(store, 'data.ended_bidding_time') && !store.isFinished">
      <div class="label">{{ $t('countdown') }}</div>
      <app-countdown
        :key="$get(store, 'data.ended_bidding_time')"
        end-hide plusOneMinute target="div" :end-time="$get(store, 'data.ended_bidding_time')"
      >
        <template v-slot="{ day, hour, minute }">
          <div class="countdown">
            <span>{{ day }}</span>{{ $t('day') }}
            <span>{{ hour }}</span>{{ $tc('hour', hour) }}
            <span>{{ minute }}</span>{{ $tc('minute', minute) }}
          </div>
        </template>
      </app-countdown>
    </div>
    <div class="container">
      <div class="top flex">
        <!-- 移动端显示 -->
        <div class="product-wrapper hidden-sm-and-up">
          <div class="product-card flex">
            <div class="cover">
              <auto-height height="100%" :cover="$get(store, 'data.cover.url')" />
            </div>
            <div class="content flex-1 flex column content-between">
              <div class="name">
                {{ $get(store, 'data.title') }}
                <div class="tips" v-if="store.showTransportAreaTips">{{ $t('transportAreaTips') }}</div>
              </div>
              <div v-if="$get(store, 'data.bid_status') === 'aborted'">
                {{ $t('aborted') }}
              </div>
              <div class="footer" v-else>
                <div v-if="!store.isFinished" class="flex item-center content-between">
                  <div class="label">
                    <template v-if="!store.auctionProduct">
                      {{ $t('buyNowPrice') }}：
                    </template>
                    <template v-else>
                      {{ $get(store, 'data.current_price') ? $t('currentPrice') : $t('startedPrice') }}
                    </template>
                  </div>
                  <div class="price flex item-center">
                    <div class="currency text-uppercase">{{ $get(store, 'data.currency') }}</div>
                    <div class="amount">{{ !store.auctionProduct ? $get(store, 'data.confirmed_price') : store.currentPrice | currencyFormat }}</div>
                  </div>
                </div>
                <!-- 有竞拍价格和一口价 -->
                <div v-if="store.auctionProduct && store.isBuyNowProduct && !store.isFinished"
                     class="flex item-center content-between">
                  <div class="label">
                    <template>
                      {{ $t('buyNowPrice') }}：
                    </template>
                  </div>
                  <div class="price flex item-center">
                    <div class="currency text-uppercase">{{ $get(store, 'data.currency') }}</div>
                    <div class="amount">{{ $get(store, 'data.confirmed_price') | currencyFormat }}</div>
                  </div>
                </div>
                <bottom-price v-if="!store.isFinished" :product="store.data" mobile />
              </div>
            </div>
          </div>
          <!-- 成交价格 -->
          <div class="your-bid flex item-center content-between" v-if="$get(store, 'data.deal_price')">
            <div class="label">{{ $t('dealPrice') }}</div>
            <div class="price flex item-center">
              <div class="currency text-uppercase">{{ $get(store, 'data.currency') }}</div>
              <div class="amount">{{ $get(store, 'data.deal_price') | currencyFormat }}</div>
            </div>
          </div>
          <!-- 我的出价 -->
          <div class="your-bid flex item-center content-between" v-else-if="$get(store, 'data.current_user_bidding_price')">
            <div class="label">{{ $t('yourBidPrice') }}</div>
            <div class="price flex item-center">
              <div class="currency text-uppercase">{{ $get(store, 'data.currency') }}</div>
              <div class="amount">{{ $get(store, 'data.current_user_bidding_price') | currencyFormat }}</div>
            </div>
          </div>
        </div>
        <!-- pc端显示 -->
        <div class="pc-cover hidden-xs-only">
          <auto-height height="100%" :cover="$get(store, 'data.cover.url')" />
        </div>
        <!-- 结束时只显示成交价格 -->
        <div v-if="store.isFinished" class="pc-main-finished hidden-xs-only">
          <div class="name">{{ $get(store, 'data.title') }}</div>
          <div class="your-bid flex item-center content-between" v-if="$get(store, 'data.deal_price')">
            <div class="label">{{ $t('dealPrice') }}</div>
            <div class="price flex item-center">
              <div class="currency text-uppercase">{{ $get(store, 'data.currency') }}</div>
              <div class="amount">{{ $get(store, 'data.deal_price') | currencyFormat }}</div>
            </div>
          </div>
          <div :class="['rich-text-wrapper', { all: showAllDesc }]" ref="richText">
            <div class="desc-rich-text">
              <div v-html="$get(store, 'data.desc')"></div>
              <div v-if="$get(store, 'data.fixed_product_desc')" class="fix-desc">{{ $get(store, 'data.fixed_product_desc') }}</div>
            </div>
            <div class="mask" v-if="hasShowAllBtn && !showAllDesc"></div>
          </div>
          <div class="show-all flex item-center content-center" v-if="hasShowAllBtn" @click="showAllDesc = !showAllDesc">
            <div class="text">{{ showAllDesc ? $t('close') : $t('open') }}</div>
            <i :class="['el-icon-arrow-down', { active: showAllDesc }]" />
          </div>
        </div>
        <div v-if="!store.isFinished" class="main">
          <!-- pc端显示的商品名称和价格 -->
          <div class="pc-main-header hidden-xs-only">
            <div class="name">{{ $get(store, 'data.title') }}</div>
            <div class="tips" v-if="store.showTransportAreaTips">{{ $t('transportAreaTips') }}</div>
            <div class="aborted-hint" v-if="$get(store, 'data.bid_status') === 'aborted'">
              {{ $t('aborted') }}
            </div>
            <div class="current-price" v-else>
              <div class="flex item-center content-between">
                <div class="label">
                  <template v-if="!store.auctionProduct">
                    {{ $t('buyNowPrice') }}
                  </template>
                  <template v-else>
                    {{ $get(store, 'data.current_price') ? $t('currentPrice') : $t('startedPrice') }}
                  </template>
                </div>
                <div class="price flex item-center">
                  <bottom-price :product="store.data" />
                  <div class="currency text-uppercase">{{ $get(store, 'data.currency') }}</div>
                  <div class="amount">{{ !store.auctionProduct ? $get(store, 'data.confirmed_price') : store.currentPrice | currencyFormat }}</div>
                </div>
              </div>
              <!-- 有竞拍价格和一口价 -->
              <div v-if="store.auctionProduct && store.isBuyNowProduct"
                   class="flex item-center content-between">
                <div class="label">
                  <template>
                    {{ $t('buyNowPrice') }}
                  </template>
                </div>
                <div class="price flex item-center">
                  <div class="currency text-uppercase">{{ $get(store, 'data.currency') }}</div>
                  <div class="amount">{{ $get(store, 'data.confirmed_price') | currencyFormat }}</div>
                </div>
              </div>
            </div>
            <!-- 成交价格 -->
            <div class="your-bid flex item-center content-between" v-if="$get(store, 'data.deal_price')">
              <div class="label">{{ $t('dealPrice') }}</div>
              <div class="price flex item-center">
                <div class="currency text-uppercase">{{ $get(store, 'data.currency') }}</div>
                <div class="amount">{{ $get(store, 'data.deal_price') | currencyFormat }}</div>
              </div>
            </div>
            <!-- 我的出价 -->
            <div class="your-bid flex item-center content-between" v-else-if="$get(store, 'data.current_user_bidding_price')">
              <div class="label">{{ $t('yourBidPrice') }}</div>
              <div class="price flex item-center">
                <div class="currency text-uppercase">{{ $get(store, 'data.currency') }}</div>
                <div class="amount">{{ $get(store, 'data.current_user_bidding_price') | currencyFormat }}</div>
              </div>
            </div>
          </div>
          <div class="title flex item-center content-between">
            <div class="label">{{ $t('quickBid') }}</div>
            <div class="link" v-if="store.auctionProduct" @click="handleShowMethod">{{ $t('useAutomaticBid') }}</div>
          </div>
          <!-- 一口价商品 -->
          <div class="buy-now" v-if="!store.auctionProduct">
            <div class="price flex item-center content-center">
              <div class="currency text-uppercase">{{ $get(store, 'data.currency') }}</div>
              <div class="amount">{{ $get(store, 'data.confirmed_price') | currencyFormat }}</div>
            </div>
            <div :class="['primary-btn btn-buy-now', { disabled: !canBuyNow }]" @click="handleBuyNow">{{ $t('bid') }}</div>
          </div>
          <!-- 竞价商品 -->
          <template v-else>
            <el-tabs v-model="current">
              <el-tab-pane name="automatic" :label="$t('automaticBid')">
              </el-tab-pane>
              <el-tab-pane name="manual" :label="$t('manualBid')">
              </el-tab-pane>
            </el-tabs>
            <!-- 已设定自动出价 -->
            <div class="automatic-bid-content flex column item-center" v-if="showAutomaticContent">
              <!-- 已自动出价后修改中 -->
              <div v-if="showAutomaticOperation" class="flex column item-center content-center">
                <div class="label">{{ $t('changeAutomaticBid') }}</div>
                <div class="number-box flex item-center">
                  <div :class="['btn', 'minus', { disabled: disabledMinusAutoPrice }]" @click="handleMinusAutoPrice"><span class="el-icon-minus"/></div>
                  <div class="price-wrapper flex item-center content-center"><span class="price-text">{{ autoPrice | currencyFormat }} </span></div>
                  <div :class="['btn', 'plus', { disabled: disabledAddAutoPrice}]" @click="handlePlusAutoPrice"><span class="el-icon-plus"/></div>
                </div>
                <div class="flex">
                  <div class="btn-change cancel" @click="handleCancelAutoPrice">{{ $t('cancel') }}</div>
                  <div class="btn-change" @click="handleChangeAutoPrice">{{ $t('confirm') }}</div>
                </div>
              </div>
              <!-- 已自动出价后显示 -->
              <div v-else class="flex column item-center">
                <div class="label">{{ $t('automaticBidTitle') }}</div>
                <div class="price flex item-center">
                  <div class="currency text-uppercase">{{ $get(store, 'data.currency') }}</div>
                  <div class="amount">{{ $get(store, 'data.auto_bidding_price') | currencyFormat }}</div>
                </div>
                <div class="btn-change" @click="showAutomaticOperation = true">{{ $t('changeBtn') }}</div>
              </div>
            </div>
            <div class="operation" v-else>
              <div class="number-box-wrapper flex item-center content-center">
                <div class="number-box flex item-center">
                  <div :class="['btn', 'minus', { disabled: !canBuyNow || disabledMinusBtn }]" @click="handleMinusAmount"><span class="el-icon-minus"/></div>
                  <div class="price-wrapper flex item-center content-center"><span class="price-text">{{ amount | currencyFormat }} </span></div>
                  <div :class="['btn', 'plus', { disabled: !canBuyNow || disabledAddBtn}]" @click="handlePlusAmount"><span class="el-icon-plus"/></div>
                </div>
              </div>
              <div :class="['primary-btn', { disabled: !canBuyNow }]" @click="handleBid">{{ current === 'manual' ? $t('manualBid') : $t('automaticBid') }}</div>
            </div>
          </template>
        </div>
        <div v-else class="desc-rich-text mobile hidden-sm-and-up">
          <div v-html="$get(store, 'data.desc')"></div>
          <div v-if="$get(store, 'data.fixed_product_desc')" class="fix-desc">{{ $get(store, 'data.fixed_product_desc') }}</div>
        </div>
      </div>
      <!-- 出价记录 -->
      <div class="auction-record">
        <div class="flex item-center">
          <div class="title">{{ $t('bidRecord') }}</div>
          <bottom-price v-if="$get(store, 'data.bid_status') === 'aborted'" :product="store.data" style="margin-left: 0.83333rem" />
        </div>
        <div class="records" v-if="store.biddingPrices.length">
          <div class="record-item flex content-between" :class="[{ primary: item.user.id === $get(authStore, 'user.id') }]" v-for="item in store.biddingPrices" :key="item.id">
            <div class="name">
              <!-- 1. 不是匿名 -->
              <span v-if="!$get(store, 'data.anonymously_display_bids')">
                {{ item.user.id === $get(authStore, 'user.id') ? $t('myBidPrice') : item.user.decrypt_bidding_number }}/{{ $tp('Review') }}：{{ item.user.won_count || $tp('New') }}
              </span>
              <!-- 2. 是匿名（自己的出价依然显示，其余显示*******） -->
              <span v-else-if="item.user.id === $get(authStore, 'user.id')">
                {{ $t('myBidPrice') }}/{{ $tp('Review') }}：{{ item.user.won_count || $tp('New') }}
              </span>
              <span v-else>********</span>
            </div>
            <div class="time">{{ item.created_at | timeFormat('MM-DD HH:mm:ss') }}</div>
            <div class="price">{{ item.price | currencyFormat }}</div>
          </div>
        </div>
        <div class="empty flex column item-center" v-else>
          <img class="icon-empty" src="@/assets/icon-deal-empty.png" />
          <div class="text">{{ $t('emptyText') }}</div>
        </div>
      </div>
    </div>
    <common-popup :show.sync="showPopup" :content="$t('outrideBid')" />
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';
  import { ProductDetailStore, authStore } from '@/store';
  import BottomPrice from './components/bottom-price.vue';
  import { CURRENCY_MAP } from '@/constants';
  import dayjs from 'dayjs';
  import _ from 'lodash';

  @Component({
    components: {
      'bottom-price': BottomPrice
    }
  })
  export default class ProductAuction extends Vue {
    current = 'automatic' // manual - 手动出价  automatic - 自动出价
    amount = 0
    autoPrice = 0
    store = {}
    showPopup = false
    showAllDesc = false
    hasShowAllBtn = false
    showAutomaticOperation = false // 修改自动出价框的显示

    authStore = authStore
    dayjs = dayjs

    get disabledMinusBtn() {
      return this.store.data.current_price ? this.amount <= this.store.currentAmountPrice : this.amount <= this.store.currentPrice;
    }

    get disabledAddBtn() {
      if (this.store.auctionAndBuyNowProduct) {
        return this.amount >= this.store.isBuyNowProductPrice;
      }
      return false;
    }

    // 修改的自动出价不能低于当前自动出价
    get disabledMinusAutoPrice() {
      return this.autoPrice <= this.store.autoBiddingPrice;
    }

    // 修改的自动出价不能高于一口价价格
    get disabledAddAutoPrice() {
      if (this.store.auctionAndBuyNowProduct) {
        return this.autoPrice >= this.store.isBuyNowProductPrice;
      }
      return false;
    }

    get showAutomaticContent() {
      return this.store.autoBiddingPrice > this.store.currentPrice;
    }

    get canBuyNow() {
      return authStore.isLogin && !this.$get(this.store, 'data.deal_price') && this.$get(this.store, 'data.bid_status') === 'pending' &&
        (!this.$get(this.store, 'data.started_bidding_time') || dayjs(this.store.data.started_bidding_time).diff(dayjs()) <= 0);
    }

    /*
     * 描述: 提示用户价格被超越
     */
    get shouldShowPopup() {
      const { current_user_bidding_price = 0, auto_bidding_price = 0 } = this.$get(this.store, 'data');
      const [newValue, oldValue] = this.$get(this.store, 'biddingPrices');
      const price = Math.max(current_user_bidding_price, auto_bidding_price);
      if (price) {
        return price <= newValue.price && price > oldValue.price;
      }
      return false;
    }

    get translateCurrency() {
      const { currency } = this.$get(this.store, 'data');
      return CURRENCY_MAP.includes(currency) ? currency : 'jpy';
    }

    @Vue.autoLoading
    async created() {
      const { id } = this.$route.params;
      const { isPreview } = this.$route.query;
      this.store = new ProductDetailStore(id, isPreview, this);
      this.store.initSubscription();
      const [{ data: biddingPrices }] = await Promise.all([
        this.$fly.get('product_bidding_prices', { product_id_eq: id }),
        this.store.tryFetchData()
      ]);
      // 获取动态数据拼接到store.data
      const { data: { auto_bidding_price, current_user_bidding_price } } = await this.$fly.get(`products/${id}`, {
        entity: 'DynamicProduct',
        need_attributes: ['auto_bidding_price', 'current_user_bidding_price'],
        is_preview: isPreview ? true : undefined
      });
      this.$set(this.store.data, 'auto_bidding_price', auto_bidding_price);
      this.$set(this.store.data, 'current_user_bidding_price', current_user_bidding_price);

      this.store.resetBiddingPrices();
      this.store.biddingPrices.push(...biddingPrices);
      if (this.store.data.current_price && this.store.auctionAndBuyNowProduct) {
        this.amount = this.store.nextLowestPrice > this.store.isBuyNowProductPrice ? this.store.isBuyNowProductPrice : this.store.nextLowestPrice;
      } else if (this.store.data.current_price) {
        this.amount = this.store.currentAmountPrice;
      } else {
        this.amount = this.store.currentPrice;
      }

      this.autoPrice = this.store.autoBiddingPrice;

      // 出价已更新
      this.$on('updateBid', (data) => {
        this.autoPrice = this.store.autoBiddingPrice;
        if (this.store.auctionAndBuyNowProduct && this.amount < this.store.nextLowestPrice) {
          this.amount = this.store.nextLowestPrice > this.store.isBuyNowProductPrice ? this.store.isBuyNowProductPrice : this.store.nextLowestPrice;
        } else if (this.amount < this.store.nextLowestPrice) {
          this.amount = this.store.nextLowestPrice;
        }
        const { user: { id } } = data;
        if (id === this.$get(authStore, 'user.id')) {
          this.$message({ type: 'success', message: this.$t('bidSuccess') });
          return;
        }
        if (this.shouldShowPopup) {
          this.showPopup = true;
        } else {
          this.$message({ type: 'info', message: this.$t('bidUpdated') });
        }
      });

      this.$on('disconnected', async (result) => {
        if (_.get(result, 'willAttemptReconnect')) {
          await this.$confirm(this.$t('networkError'));
          location.reload();
        }
      });

      // 自动出价出价已更新
      this.$on('updateAutoPrice', () => {
        this.autoPrice = this.store.autoBiddingPrice;
      });

      // 商品已成交
      this.$on('dealProduct', (data) => {
        if (data.won_user_id === this.$get(authStore, 'user.id')) {
          this.$msgbox({
            title: this.$t('congratulations'),
            customClass: 'message-box-page-product-auction-bid',
            lockScroll: true,
            center: true,
            showConfirmButton: false,
            message: (
              <div class="content flex column item-center">
                <div class="price flex item-center">
                  <div class="label">{ this.$t('dealPrice') } </div>
                  <div class="currency text-uppercase">{ this.$get(this.store, 'data.currency') }</div>
                  <div class="amount">{ this.$options.filters['currencyFormat'](data.deal_price) }</div>
                </div>
                <div class="btn-confirm flex item-center content-center" onClick={ this.$msgbox.close }>{ this.$t('confirm') }</div>
              </div>
            )
          });
        }
        this.updateRichText();
      });

      this.updateRichText();
    }

    // 修改自动出价价格，只能改高，不能改低
    async handleChangeAutoPrice() {
      const { bottom_price, currency } = this.store.data;

      if (this.autoPrice === this.store.autoBiddingPrice) {
        return this.showAutomaticOperation = false;
      }
      await this.$confirm(`${this.$t('confirmChangeAutomaticBid')}${currency.toUpperCase()}${this.$options.filters.currencyFormat(this.autoPrice)}?`);
      await this.store.updateAutoBidding(this.autoPrice);
      this.$message({ type: 'success', message: this.$t('changeAutomaticBidSuccess') });
      this.autoPrice = this.store.autoBiddingPrice;
      // 商品设置了底价，用户出完价格需要弹窗显示未达底价
      if (bottom_price && this.autoPrice < bottom_price) {
        this.$alert(this.$t('bottomPriceTip'));
      }
      this.showAutomaticOperation = false;
    }

    handleCancelAutoPrice() {
      this.autoPrice = this.store.autoBiddingPrice;
      this.showAutomaticOperation = false;
    }

    updateRichText() {
      this.$nextTick(() => {
        const richTextElement = this.$refs.richText;
        if (richTextElement) {
          const { height } = richTextElement.getBoundingClientRect();
          this.hasShowAllBtn = height > 180;
        }
      });
    }

    handleMinusAmount() {
      if (this.canBuyNow || !this.disabledMinusBtn) {
        this.amount = this.store.minusPrice(this.amount) < this.store.data.started_price ? this.store.data.started_price :  this.store.minusPrice(this.amount);
      }
    }

    handlePlusAmount() {
      if (this.canBuyNow && this.store.auctionAndBuyNowProduct) {
        this.amount = this.store.addPrice(this.amount) > this.store.isBuyNowProductPrice ? this.store.isBuyNowProductPrice : this.store.addPrice(this.amount);
      } else if (this.canBuyNow) {
        this.amount = this.store.addPrice(this.amount);
      }
    }

    handleMinusAutoPrice() {
      this.autoPrice = this.store.minusPrice(this.autoPrice) < this.store.autoBiddingPrice ? this.store.autoBiddingPrice : this.store.minusPrice(this.autoPrice);
    }

    // 修改的自动出价不能高于一口价价格；如果超出了，则等于一口价价格
    handlePlusAutoPrice() {
      if (this.canBuyNow && this.store.auctionAndBuyNowProduct) {
        this.autoPrice = this.store.addPrice(this.autoPrice) > this.store.isBuyNowProductPrice ? this.store.isBuyNowProductPrice : this.store.addPrice(this.autoPrice);
      } else if (this.canBuyNow) {
        this.autoPrice = this.store.addPrice(this.autoPrice);
      }
    }

    handleShowMethod() {
      this.$msgbox({
        title: this.$t('automaticBidPanelTitle'),
        customClass: 'message-box-page-product-auction-method',
        lockScroll: true,
        center: true,
        showConfirmButton: false,
        message: (
          <div class="content">
            { this.$t('automaticBidPanelContent') }
          </div>
        )
      });
    }

    showMarginAmountPopup() {
      const { currency, margin_amount } = this.store.data;
      this.$msgbox({
        title: '',
        customClass: 'message-box-page-product-auction-method',
        lockScroll: true,
        center: true,
        showConfirmButton: false,
        message: (
          <div class="content flex column item-center">
            <i18n class="margin-amount-tip" path="marginAmountTip" tag="div">
              <span place="marginAmount">
                <span class="text-uppercase">{ currency }</span>
                <span class="amount">{ margin_amount }</span>
              </span>
              <span place="myWallet" class="link" onClick={ this.navToCharge }>
                { this.$t('myWallet') }
              </span>
            </i18n>
            <div class="btn-confirm flex item-center content-center" onClick={ this.handleContact }>{ this.$t('contactBtn') }</div>
          </div>
        )
      });
    }

    // 商品竞价 - 手动出价或自动出价; 如果没有上传任何证件，没有上传证件有效期，提示用户填写后再进行下一步
    async handleBid() {
      const { margin_amount, current_price } = this.store.data;
      const { quotas } = this.$get(this.authStore, 'user');
      const availableAmount = quotas.find(item =>
        item.currency === this.translateCurrency
      ).available_bidding_amount[1];

      if (this.$authStore.isLoadCredential) {
        if (this.$authStore.isLoadEffectiveDate) {
          await this.$confirm(this.$t('uploadValidityPeriod'));
          return this.$router.push({ name: 'myAddress' });
        }
        // 可竞价额度小于当前商品设置的保证金需要提示充值，跳转到充值页面
        if (margin_amount && availableAmount < margin_amount) {
          this.showMarginAmountPopup();
          return;
        }
        if (this.amount <= current_price) {
          this.$message({ type: 'warning', message: this.$t('bidErrorHint') });
          return;
        }
        this.current === 'manual' ? this.handleBidType('manual') : this.handleBidType('automatic');
      } else {
        await this.$confirm(this.$t('uploadTip'));
        return this.$router.push({ name: 'myAddress' });
      }
    }

    navToCharge() {
      const { margin_amount } = this.$get(this.store, 'data');
      this.$router.push({ name: 'charge', query: { selectCurrency: this.translateCurrency, marginAmount: margin_amount } });
    }

    async handleContact() {
      const { data } = await this.$autoLoading(this.$fly.post('message_channels/contact_service', {
        target_type: 'Product',
        target_id: this.$route.params.id,
        communicator_type: 'AuctionHouse',
        communicator_id: this.$get(this.store, 'data.auction_house_id')
      }));
      this.$router.push({ name: 'messageChannelDetail', params: { id: data.id } });
    }

    // 一口价商品 - 立即购买; 如果没有上传任何证件，没有上传证件有效期，提示用户填写后再进行下一步
    async handleBuyNow() {
      const { margin_amount, confirmed_price, currency } = this.store.data;
      const { quotas } = this.$get(this.authStore, 'user');
      const availableAmount = quotas.find(item =>
        item.currency === this.translateCurrency
      ).available_bidding_amount[1];
      if (!this.canBuyNow) {
        return;
      }
      if (this.$authStore.isLoadCredential) {
        if (this.$authStore.isLoadEffectiveDate) {
          await this.$confirm(this.$t('uploadValidityPeriod'));
          return this.$router.push({ name: 'myAddress' });
        }
        if (margin_amount && availableAmount < margin_amount) {
          this.showMarginAmountPopup();
          return;
        }
        this.$msgbox({
          title: this.$t('buyNowBoxTitle'),
          customClass: 'message-box-page-product-auction-bid',
          lockScroll: true,
          center: true,
          showConfirmButton: false,
          message: (
            <div class="content flex column item-center">
              <div class="price flex item-center">
                <div class="currency text-uppercase">{ currency}</div>
                <div class="amount">{ this.$options.filters['currencyFormat'](confirmed_price) }</div>
              </div>
              <div class="btn-confirm flex item-center content-center" onClick={ this.handleBuyNowConfirm }>{ this.$t('confirm') }</div>
            </div>
          )
        });
      } else {
        await this.$confirm(this.$t('uploadTip'));
        return this.$router.push({ name: 'myAddress' });
      }
    }

    handleBidType(type) {
      this.$msgbox({
        title: type === 'manual' ? this.$t('manualBidBoxTitle') : this.$t('automaticBidBoxTitle'),
        customClass: 'message-box-page-product-auction-bid',
        lockScroll: true,
        center: true,
        showConfirmButton: false,
        message: (
          <div class="content flex column item-center">
            <div class="price flex item-center">
              <div class="currency text-uppercase">{ this.$get(this.store, 'data.currency') }</div>
              <div class="amount">{ this.$options.filters['currencyFormat'](this.amount) }</div>
            </div>
            {
              type === 'automatic' ? <div class="hint">{ this.$t('automaticBidBoxHint') }</div> : ''
            }
            <div class="btn-confirm flex item-center content-center" onClick={ this.handleBidConfirm }>{ this.$t('confirm') }</div>
          </div>
        )
      });
    }

    handleBuyNowConfirm() {
      this.$msgbox.close();
      this.store.bid(this.$get(this.store, 'data.confirmed_price'), false);
    }

    handleBidConfirm() {
      const { bottom_price } = this.store.data;

      this.$msgbox.close();
      this.store.bid(this.amount, this.current === 'manual' ? false : true);

      // 商品设置了底价，用户出完价格需要弹窗显示未达底价
      if (bottom_price && this.amount < bottom_price) {
        this.$alert(this.$t('bottomPriceTip'));
      }
    }

    beforeDestroy() {
      this.store.disconnect();
    }
  }
</script>

<style lang="scss">
  .message-box-page-product-auction-bid {
    padding-bottom: 10px;
    border-radius: 15px;

    .el-message-box__header {
      padding-top: 45px;
    }

    .el-message-box__content {
      padding-top: 4px;
    }

    .el-message-box__title {
      font-weight: 500;
      font-size: 12px;
      color: #666;
    }

    .price {
      font-weight: 500;

      .currency {
        font-size: 14px;
        color: #000;
      }

      .amount {
        margin-left: 2px;
        font-size: 16px;
        color: $primary;
      }
    }

    .hint {
      margin-top: 25px;
      font-size: 10px;
      color: $primary;
    }

    .btn-confirm {
      box-sizing: border-box;
      min-width: 210px;
      height: 45px;
      padding: 0 15px;
      margin-top: 25px;
      border-radius: 23px;
      font-size: 18px;
      color: #fff;
      background: $primary;
      cursor: pointer;
    }
  }

  .message-box-page-product-auction-method {
    padding-bottom: 1.5rem !important;
    border-radius: 15px !important;

    .el-message-box__title {
      font-weight: 500;
      font-size: 14px;
      color: #000;
    }

    .el-message-box__content {
      padding-right: 24px;
      padding-left: 24px;
    }

    .content {
      font-size: 12px;
      line-height: 21px;
      text-align: left;
      color: #666;
    }

    .margin-amount-tip {
      .text-uppercase {
        text-transform: uppercase;
      }

      .amount {
        margin-left: 5px;
      }
    }

    .link {
      text-decoration: underline;
      color: $primary;
      cursor: pointer;
    }

    .btn-confirm {
      box-sizing: border-box;
      min-width: 210px;
      height: 45px;
      padding: 0 15px;
      margin-top: 25px;
      border-radius: 23px;
      font-size: 18px;
      color: #fff;
      background: $primary;
      cursor: pointer;
    }
  }

  .tips {
    margin-top: 6px;
    font-size: 11px;
    color: $primary;
  }
</style>

<style lang="scss" scoped>
  .box-countdown {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 45px;
    padding: 0 15px 0 13px;
    background: #eee;

    .label {
      font-weight: 500;
      font-size: 12px;
      color: #666;
    }

    .countdown {
      font-weight: 500;
      font-size: 12px;
      color: #666;

      span {
        font-weight: 500;
        font-size: 18px;
        color: $primary;
      }
    }

    @include media-sm-and-up {
      justify-content: center;

      .label {
        margin-right: 20px;
        font-size: 14px;
      }

      .countdown {
        font-size: 14px;

        span {
          font-size: 20px;
        }
      }
    }
  }

  .top {
    display: flex;
    flex-direction: column;

    @include media-sm-and-up {
      flex-direction: row;
      padding: 24px 0 34px;
      border-bottom: 1px solid #ddd;
    }

    .pc-cover {
      width: 396px;
    }

    .pc-main-finished {
      flex: 1;
      padding: 0 30px;
    }

    .your-bid {
      margin-top: 20px;
      font-weight: 500;

      .amount {
        margin-left: 2px;
        font-size: 16px;
        color: $primary;
      }
    }

    .rich-text-wrapper {
      position: relative;
      max-height: 330px;
      overflow: hidden;
      transition: all .3s;

      &.all {
        max-height: 5000px;
      }

      .mask {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 310px;
        background-image: linear-gradient(rgba(255, 255, 255, 0), #fff);
      }
    }

    .show-all {
      color: #666;
      cursor: pointer;

      .text {
        font-size: 12px;
      }

      .el-icon-arrow-down {
        margin-left: 6px;
        transition: all .3s;

        &.active {
          transform: rotate(-180deg);
        }
      }
    }

    .product-wrapper {
      padding: 18px 0 20px;
      border-bottom: 1px solid #ddd;

      .product-card {
        .cover {
          width: 110px;
        }

        .name {
          word-break: break-all;
        }

        .content {
          margin-left: 16px;
          font-weight: 500;

          .footer {
            margin-bottom: 2px;

            .amount {
              margin-left: 2px;
              font-size: 16px;
              color: $primary;
            }
          }
        }
      }
    }

    .desc-rich-text {
      padding-bottom: 14px;
      overflow: hidden;

      &.mobile {
        border-bottom: 1px solid #ddd;
      }
    }

    .main {
      flex: 1;
      padding: 15px 0 10px;
      border-bottom: 1px solid #ddd;

      @include media-sm-and-up {
        padding: 0 30px;
        border-bottom: none;
      }

      .pc-main-header {
        padding-bottom: 24px;

        .name {
          font-weight: 500;
        }

        .current-price {
          padding-bottom: 10px;
          margin-top: 34px;
          border-bottom: 1px solid #ddd;
          font-weight: 500;

          .amount {
            margin-left: 2px;
            font-size: 16px;
            color: $primary;
          }
        }

        .your-bid {
          margin-top: 18px;
          font-weight: 500;

          &.margin-amount {
            margin-top: 16px;
          }

          .amount {
            margin-left: 2px;
            font-size: 16px;
            color: $primary;
          }
        }

        .aborted-hint {
          margin-top: 20px;
          margin-bottom: 2px;
        }
      }

      .title {
        margin-bottom: 5px;

        .label {
          font-weight: 500;
        }

        .link {
          font-size: 11px;
          color: #666;
          cursor: pointer;
        }
      }

      .buy-now {
        .price {
          margin-top: 30px;
          font-weight: 500;

          @include media-sm-and-up {
            margin-top: 60px;
          }

          .amount {
            margin-left: 2px;
            font-size: 16px;
            color: $primary;
          }
        }
      }

      /deep/ .el-tabs {
        margin-top: 15px;

        .el-tabs__header {
          margin: 0;
          background: #eee;

          .el-tabs__active-bar {
            display: none;
          }

          .el-tabs__nav {
            display: flex;
            justify-content: space-between;
            width: 100%;
          }

          .el-tabs__nav-wrap::after {
            display: none;
          }

          .el-tabs__item {
            position: relative;
            width: 100%;
            padding: 0;
            margin: 0 auto;
            text-align: center;
            color: #999999;

            &::after {
              position: absolute;
              bottom: 0;
              left: 50%;
              width: 50%;
              height: 2px;
              border-radius: 1px;
              visibility: hidden;
              background: #000000;
              transform: translateX(-50%);
              content: '';
            }

            &.is-active {
              color: var(--color-tag-font);
              background: var(--color-tag-bg);

              &::after {
                visibility: visible;
              }
            }
          }
        }
      }

      .number-box-wrapper {
        margin-top: 32px;
      }

      .price-wrapper {
        width: 104px;
        height: 40px;
        margin: 0 16px;
        border: 1px solid #ddd;
        background: #f0f0f0;
      }

      .btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        font-size: 13px;
        color: #fff;
        background: #000;
        cursor: pointer;

        &.disabled {
          background: #ccc;
          pointer-events: none;
        }

        .el-icon-minus, .el-icon-plus {
          font-weight: bold;
        }
      }

      .primary-btn {
        margin-top: 28px;
        color: #fff;
        background: $primary;

        &.btn-buy-now {
          @include media-sm-and-up {
            margin-top: 60px;
          }
        }

        &.disabled {
          background: #ccc;
          pointer-events: none;
        }
      }

      .automatic-bid-content {
        padding: 20px 0;
        margin-top: 30px;
        background: #eee;

        @include media-xs-only {
          margin-bottom: 25px;
        }

        .label {
          margin-bottom: 8px;
          font-weight: 500;
          font-size: 12px;
          color: #666;
        }

        .btn-change {
          display: flex;
          justify-content: center;
          align-items: center;
          box-sizing: border-box;
          height: 24px;
          padding: 0 18px;
          margin: 12px 10px 0;
          border-radius: 15px;
          font-size: 10px;
          color: #fff;
          background: $primary;
          cursor: pointer;

          &.cancel {
            color: $black;
            background: #ccc;
          }
        }
      }

      .hint {
        margin-top: 12px;
        font-size: 10px;
        text-align: center;
        color: $primary;
      }
    }
  }

  .auction-record {
    padding: 16px 0 40px;

    @include media-xs-only {
      padding: 16px 0 15px;
    }

    .title {
      font-weight: 500;
    }

    .records {
      margin-top: 16px;

      .record-item {
        margin-bottom: 6px;
        font-size: 12px;

        &.primary {
          color: $primary;
        }

        .name {
          width: 130px;
        }

        .price {
          width: 100px;
          font-weight: 500;
          text-align: right;
        }
      }
    }

    .empty {
      padding: 40px 0;

      .icon-empty {
        width: 65px;
        height: 65px;
      }

      .text {
        margin-top: 30px;
        font-size: 13px;
        color: #999;
      }
    }
  }
</style>
