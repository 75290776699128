<i18n>
{
  "zh_cn": {
    "showMore": "查看更多>",
    "auctionSessions": "同步拍卖",
    "finished": "拍卖已结束"
  },
  "zh_hk": {
    "showMore": "查看更多>",
    "auctionSessions": "同步拍賣",
    "finished": "拍賣已結束"
  },
  "en": {
    "showMore": "See more>",
    "auctionSessions": "Live Auction",
    "finished": "Past auctions"
  },
  "ja": {
    "showMore": "もっと見る>",
    "auctionSessions": "ライブオークション",
    "finished": "オークションは終了しました"
  }
}
</i18n>

<template>
  <div class="home-wrap">
    <v-title :value="$auctionHouseStore.domainHouse.name"/>
    <skeleton :onFetch="onFetch" v-model="loading"/>
    <template v-if="!loading">
      <!-- 如同步拍先開始:
            1.拍賣進行中 / 拍賣預告
            2.拍品分類列表（限時拍）
            3.限時拍拍品推薦（限時拍）
            4.即將結束拍品列表（限時拍）
            5.直播列表
            8. 导览列表
            6.拍賣已結束
            7.已結束拍品列表（限時拍）

           如限時拍先開始:
            1.拍品分類列表（限時拍）
            2.限時拍拍品推薦（限時拍）
            3.即將結束拍品列表（限時拍）
            4.拍賣進行中 / 拍賣預告
            5.直播列表
            8. 导览列表
            6.拍賣已結束
            7.已結束拍品列表 （限時拍）
      -->

      <!-- 商品搜索框 -->
      <div v-if="showProducts" class="common-input-wrap">
        <common-input :categories="productCategories.data" />
      </div>

      <div class="container">
        <div v-for="module in homeModules" :key="module">
          <!-- 轮播图 -->
          <template v-if="module === 'banner_module'">
            <common-banner
              v-if="banners.length"
              :images="banners"
              :oss-img="$root.windowWidth >= 768 ? {
              process: 'image/quality,q_90/format,jpg/interlace,1'
            } : {
              resize: { w: 750 }
            }"
            >
              <template v-slot="{ item }"><banner-content :item="item"/></template>
            </common-banner>
          </template>

          <!-- 拍卖进行中 -->
          <template v-if="module === 'in_progress_auction_list'">
            <div v-if="!houseDetail.product_first && houseDetail.can_manage_auction && $get(houseDetail, 'auctions.length')" class="module-item">
              <div class="common-title-wrap">
                <common-title :title="$t('auctionSessions')"/>
              </div>
              <div class="scroll-wrapper">
                <div class="scroll-content">
                  <router-link
                    class="item"
                    v-for="item in houseDetail.auctions"
                    :key="item.id"
                    :to="{ name: 'auctionDetail', params: { id: item.id } }"
                  >
                    <auction-card :auction="item"/>
                  </router-link>
                </div>
              </div>
            </div>
          </template>

          <!-- 拍品分类列表 -->
          <template v-if="module === 'product_category_list'">
            <div v-if="storeCategories.data.length && houseDetail.can_manage_store" class="module-item">
              <div class="flex item-center content-between">
                <div class="common-title-wrap">
                  <common-title :title="$t('categoryList')"/>
                </div>
              </div>
              <el-row :gutter="10" justify="space-between">
                <el-col :xs="24" :sm="12" :md="8" v-for="category in storeCategories.data" :key="category.id">
                  <store-category-item :category="category" />
                </el-col>
              </el-row>
              <pagination style="padding-top: 0.83333rem;" :store="storeCategories" />
            </div>
          </template>

          <!-- 限时拍拍品推荐 -->
          <template v-if="module === 'in_progress_product_list'">
            <div v-if="showProducts" class="module-item">
              <div class="flex item-center content-between">
                <div class="common-title-wrap">
                  <common-title :title="$t('productList')"/>
                </div>
                <div v-if="$get(houseDetail,'products_count') > 6">
                  <router-link
                    :to="{ name: 'products', query: { id: houseDetail.id , type: houseDetail.tn, in_progress: true }}"
                    class="more flex"
                  >
                    {{ $t('showMore') }}
                  </router-link>
                </div>
              </div>
              <el-row :gutter="8" justify="space-between">
                <el-col :xs="8" :sm="6" :md="4" v-for="product in houseDetail.products" :key="product.id">
                  <product-item class="component-product-item" :product="product" :productQuery="productQuery"/>
                </el-col>
              </el-row>
            </div>
          </template>

          <!-- 即将结束拍品列表 -->
          <template v-if="module === 'will_finished_product_list'">
            <div v-if="willEndProducts.data.length && houseDetail.can_manage_store" class="module-item">
              <div class="flex item-center content-between">
                <div class="common-title-wrap">
                  <common-title :title="$t('willEndproductList')"/>
                </div>
                <div v-if="willEndProducts.meta.total > 6">
                  <router-link
                    :to="{ name: 'products', query: { id: houseDetail.id , type: houseDetail.tn, will_end: true }}"
                    class="more flex"
                  >
                    {{ $t('showMore') }}
                  </router-link>
                </div>
              </div>
              <el-row :gutter="8" justify="space-between">
                <el-col :xs="8" :sm="6" :md="4" v-for="product in willEndProducts.data" :key="product.id">
                  <product-item class="component-product-item" :product="product" :productQuery="{ ...productQuery, will_end: true }"/>
                </el-col>
              </el-row>
            </div>
          </template>

          <!-- 拍卖进行中 -->
          <template v-if="module === 'in_progress_auction_list'">
            <div v-if="houseDetail.product_first && houseDetail.can_manage_auction && $get(houseDetail, 'auctions.length')" class="module-item">
              <div class="common-title-wrap">
                <common-title :title="$t('auctionSessions')"/>
              </div>
              <div class="scroll-wrapper">
                <div class="scroll-content">
                  <router-link
                    class="item"
                    v-for="item in houseDetail.auctions"
                    :key="item.id"
                    :to="{ name: 'auctionDetail', params: { id: item.id } }"
                  >
                    <auction-card :auction="item"/>
                  </router-link>
                </div>
              </div>
            </div>
          </template>

          <!-- 直播列表 -->
          <template v-if="module === 'live_broadcast_list'">
            <div v-if="$auctionHouseStore.liveBroadcasts.length" class="module-item">
              <div class="flex item-center content-between">
                <div class="common-title-wrap">
                  <common-title :title="$t('liveBroadcast')"/>
                </div>
                <div v-if="$auctionHouseStore.liveBroadcasts.length > 3">
                  <router-link :to="{ name: 'LiveBroadcasts' }" class="more flex">
                    {{ $t('showMore') }}
                  </router-link>
                </div>
              </div>
              <el-row :gutter="20">
                <el-col :xs="24" :sm="8" v-for="item in $auctionHouseStore.liveBroadcasts.slice(0, 3)" :key="item.id">
                  <live-card :live="item" :routeTo="{ name: 'liveBroadcastDetail', params: { id: item.id } }"/>
                </el-col>
              </el-row>
            </div>
          </template>

          <!-- 导览列表 -->
          <template v-if="module === 'guide_list'">
            <div v-if="guideStore.data.length" class="module-item">
              <div class="flex item-center content-between">
                <div class="common-title-wrap">
                  <common-title :title="$t('guideList')"/>
                </div>
                <div v-if="guideStore.meta.total > 3">
                  <router-link :to="{ name: 'guides' }" class="more flex">
                    {{ $t('showMore') }}
                  </router-link>
                </div>
              </div>
              <el-row :gutter="20" class="guide-list">
                <el-col :xs="24" :sm="8" v-for="guide in guideStore.data" :key="guide.id" class="guide-item">
                  <guide-card
                    :guide="guide"
                    :routeTo="{ name: 'guideDetail', params: { id: guide.id } }"
                    type="homePage"
                  />
                </el-col>
              </el-row>
            </div>
          </template>

          <!-- 拍卖已结束 -->
          <template v-if="module === 'finished_auction_list'">
            <div v-if="houseDetail.can_manage_auction && finishedAuctionStores.data.length" class="module-item">
              <div class="common-title-wrap">
                <common-title class="title" :title="$t('finished')"/>
              </div>
              <div class="auction-list">
                <el-row :gutter="30">
                  <el-col
                    :xs="24"
                    :lg="12"
                    v-for="item in finishedAuctionStores.data"
                    :key="item.id"
                  >
                    <auction-item class="item" :auction="item"/>
                  </el-col>
                </el-row>
                <pagination style="padding: 1.66667rem 0 2.5rem;" :store="finishedAuctionStores" />
              </div>
            </div>
          </template>

          <!-- 已结束拍品列表 -->
          <template v-if="module === 'finished_product_list'">
            <div v-if="endProducts.data.length && houseDetail.can_manage_store" class="module-item">
              <div class="flex item-center content-between">
                <div class="common-title-wrap">
                  <common-title :title="$t('endedProductList')"/>
                </div>
                <div v-if="endProducts.meta.total > 6">
                  <router-link
                    :to="{ name: 'products', query: { id: houseDetail.id , type: houseDetail.tn, ended: true }}"
                    class="more flex"
                  >
                    {{ $t('showMore') }}
                  </router-link>
                </div>
              </div>
              <el-row :gutter="8" justify="space-between" class="product-list">
                <el-col :xs="8" :sm="6" :md="4" v-for="product in endProducts.data" :key="product.id">
                  <product-item class="component-product-item" :product="product" :productQuery="{ ...productQuery, ended: true }"/>
                </el-col>
              </el-row>
            </div>
          </template>
        </div>

      </div>
    </template>

  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';
  import { Collection, followProductIdsStore, auctionHouseStore, auctionNumbersStore } from '@/store';
  import { fly, initSubscription, updateProduct } from '@/utils';
  import Skeleton from './components/index-skeleton';

  @Component({ components: { Skeleton } })
  export default class AuctionHouseHome extends Vue {
    houseDetail = {};
    loading = true;
    productQuery = { ...this.$route.query, productType: 'product', auction_house_id_eq: this.$route.params.id };
    banners = [];

    homeModules = []

    auctionHouseStore = auctionHouseStore;
    // 拍卖已结束
    finishedAuctionStores = new Collection({
      fetch(params) {
        return fly.get('auctions/finished_auctions_and_store_categories', { ...params, per_page: 4 });
      }
    })

    // 即将结束拍品列表
    willEndProducts = new Collection({
      fetch: params => this.$fly.get('products', { ...params, will_end: true, per_page: 6 })
    })

    // 已结束拍品列表
    endProducts = new Collection({
      fetch: params => this.$fly.get('products', { ...params, ended: true, per_page: 6 })
    })

    // 拍品分类列表
    storeCategories = new Collection({
      fetch: params => this.$fly.get('store_categories', { ...params, per_page: 6 })
    })

    // 商品分类列表（用于选择分类的搜索框）
    productCategories = new Collection({
      fetch: params => this.$fly.get('product_categories', { ...params, per_page: 99, parent_id_null: true })
    })

    // 导览列表
    guideStore = new Collection({
      params: { per_page: 3, default_eq: false },
      fetch: params => this.$fly.get('guides', { ...params })
    })

    received = function (that, result) {
      if (that.$get(that.houseDetail, 'products', []).length > 0) {
        updateProduct(that.houseDetail.products, result);
      }
      if (that.willEndProducts.data.length > 0) {
        updateProduct(that.willEndProducts.data, result);
      }
    }

    get showProducts() {
      return this.$get(this.houseDetail, 'can_manage_store') && this.$get(this.houseDetail, 'products_count') > 0;
    }

    async onFetch() {
      const [{ data: banners }] = await Promise.all([
        this.$fly.get('banners', { location_eq: 'homepage', channel_eq: 'web' }),
        this.finishedAuctionStores.tryFetchData(),
        this.auctionHouseStore.tryFetchData(),
        this.storeCategories.tryFetchData(),
        // 使用了product-item组件的页面都需要请求
        followProductIdsStore.tryFetchData(),
        auctionNumbersStore.tryFetchData(),
        this.productCategories.tryFetchData(),
        this.guideStore.fetchData()
      ]);

      this.houseDetail = this.auctionHouseStore.domainHouseDetail;
      this.banners = banners;
      const { home_page_modules } = this.houseDetail;
      this.homeModules = home_page_modules || [];

      await Promise.all([
        this.willEndProducts.fetchData({ auction_house_id_eq: this.houseDetail.id }),
        this.endProducts.fetchData({ auction_house_id_eq: this.houseDetail.id }),
      ]);
      if (this.showProducts) {
        initSubscription(this, [], this.received);
      }
    }

    async mounted() {
      const { devise_success, devise_errors } = this.$route.query;
      if (devise_success) {
        await this.$message({ type: 'success', message: devise_success });
        this.$router.replace('/');
      } else if (devise_errors) {
        await this.$message({ type: 'error', message: devise_errors });
        this.$router.replace('/');
      }
    }

    beforeDestroy() {
      if (this.consumer) {
        this.consumer.disconnect();
      }
    }
  }
</script>

<style lang="scss" scoped>
  .home-wrap {
    padding-bottom: 40px;

    @include media-xs-only {
      padding-bottom: 15px;
    }

    .container {
      @include media-xs-only {
        overflow-x: visible;
      }

      .module-item {
        padding: 0 20px;

        @include media-xs-only {
          padding: 0;
        }
      }
    }

    .common-input-wrap {
      position: sticky;
      z-index: 2000;
      top: 45px;
      left: 0;
      width: 100%;
      margin-bottom: 0px;

      @include media-sm-and-up {
        top: 60px;
        margin-bottom: 10px;
      }

      /deep/ .input-wrap {
        padding: 20px 0;
        margin-top: 0;
        background: #fff;

        @include media-xs-only {
          width: 100%;
          padding: 1rem 0.5rem;
          margin: 0 auto;
        }
      }
    }

    .scroll-wrapper {
      margin: 10px -20px 0;
      overflow: auto;

      @include media-sm-and-up {
        margin: 30px 0 0;
      }

      .scroll-content {
        display: inline-flex;
        padding: 0 15px 15px;

        @include media-lg-and-up {
          padding: 0 3px 15px;
        }

        .item {
          margin: 0 5px;
          cursor: pointer;

          .auction-card-wrapper {
            min-height: 100%;
          }
        }
      }
    }

    .common-title-wrap {
      margin: 25px 0;

      @include media-sm-and-up {
        margin-bottom: 40px;
      }
    }

    .more {
      margin-top: -10px;
      color: #666;
    }

    .auction-list {
      .item {
        display: block;
        margin-bottom: 10px;
        cursor: pointer;
      }
    }

    .component-product-item {
      margin-bottom: 10px;
      cursor: pointer;
    }
  }

  .guide-list {
    margin-bottom: 20px;

    .guide-item {
      margin-bottom: 10px;
    }
  }

  div /deep/.component-banner-wrapper {
    margin: 20px 0;
  }
</style>
