<i18n src="../locales.json"></i18n>

<template>
  <div class="channel-item flex item-center" @click="handleNavDetail">
    <img class="cover no-shirk" :src="$get(channel, 'cover.url')" v-if="$get(channel, 'cover.url')" />
    <img class="default-cover no-shirk" src="@/assets/sysmsg-avatar-default.png" v-else />
    <div class="content flex-1">
      <div class="content-main flex column">
        <div class="name text-overflow">{{ $get(channel, 'communicator_name') }}</div>
        <div class="time">{{ $get(channel, 'last_message.updated_at') | timeFormat('YYYY-MM-DD HH:mm:ss') }}</div>
      </div>
      <div v-if="$get(channel, 'last_message.content')" class="message text-overflow">{{ $get(channel, 'last_message.content') }}</div>
      <div v-else class="message">{{ $t('image') }}</div>
    </div>
    <div class="badge" v-if="$get(channel, 'last_message_id') !== $get(channel, 'last_read_message_id')"></div>
    <div v-if="!$get(channel, 'is_system_message') && $get(channel, 'is_closed')" class="tag-wrapper">
      <el-tag type="success" size="small">{{ $t('isClosed') }}</el-tag>
    </div>
  </div>
</template>

<script>
  import { Component, Vue, Prop } from 'vue-property-decorator';

  @Component
  export default class ChannelItem extends Vue {
    @Prop({ type: Object, default: () => ({}) }) channel

    handleNavDetail() {
      this.$router.push({ name: 'messageChannelDetail', params: { id: this.channel.id } });
    }
  }
</script>

<style lang="scss" scoped>
  .channel-item {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    padding: 18px 30px 18px 15px;
    border-radius: 2px;
    background: #fff;
    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.09);

    @include media-sm-and-up {
      padding-right: 200px;
    }

    .cover {
      width: 60px;
      height: 60px;
      background: #eee;
      object-fit: cover;
    }

    .default-cover {
      box-sizing: border-box;
      width: 60px;
      height: 60px;
      border: 1px solid #eee;
      background: #eee;
      object-fit: contain;
    }

    .content {
      margin-left: 18px;
      color: #000;

      @include media-sm-and-up {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
      }

      .content-main {
        @include media-sm-and-up {
          min-width: 200px;
        }
      }

      .name {
        font-weight: 500;
        font-size: 13px;
      }

      .time {
        margin-top: 5px;
        font-size: 11px;
        color: #999;
      }

      .message {
        margin-top: 8px;
        font-size: 13px;
        color: #666;

        @include media-sm-and-up {
          width: 300px;
          margin: 0 auto;
          text-align: left;
        }
      }
    }

    .badge {
      position: absolute;
      top: 24px;
      right: 18px;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: $primary;

      @include media-sm-and-up {
        top: 14px;
      }
    }

    .tag-wrapper {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
</style>
