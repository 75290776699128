<i18n>
{
  "zh_cn": {
    "dealTime": "成交日期",
    "shipping": "运费"
  },
  "zh_hk": {
    "dealTime": "成交日期",
    "shipping": "運費"
  },
  "en": {
    "dealTime": "Transaction time",
    "shipping": "Shipping"
  },
  "ja": {
    "dealTime": "取引日",
    "shipping": "貨物"
  }
}
</i18n>

<template>
  <div class="component-order-item">
    <div class="order-item flex item-center">
      <div
        v-if="showCheckbox"
        class="checkbox"
        :class="{ checked: order.checked, disabled: !['wait_buyer_pay_logistic', 'wait_buyer_pay'].includes(order.status) }"
        @click="toggleCheckbox"
      ></div>
      <div class="info-wrap flex" :class="{ disabled: $route.name === 'orderBatchList' }" @click="handleNavDetail">
        <div class="info-box">
          <div class="cover-content">
            <div :class="['label', label.class]">{{ label.text }}</div>
            <div class="cover" :style="{ backgroundImage: `url(${imageSrc})` }"></div>
          </div>
          <div class="content-wrapper">
            <div class="title-wrap">
              <div class="title text-overflow"><span v-if="$get(order, 'orderable.number') || $get(order, 'number')">LOT {{ $get(order, 'orderable.number') || $get(order, 'number') }} </span>{{ $get(order, 'orderable.title') }}</div>
              <div class="info" v-if="$get(order,'orderable.auction_category.title') || $get(order,'auction_category.title')">{{ $get(order, 'orderable.auction_category.title') || $get(order,'auction_category.title') }}</div>
            </div>
            <div>
              <div class="time">{{ $t('dealTime') }}: {{ $get(order, 'orderable.transaction_time') || $get(order, 'transaction_time') || $get(order, 'orderable.ended_bidding_time') | timeFormat('YYYY-MM-DD') }}</div>
              <div class="price-wrapper">
                <template v-if="batchKind === 'logistic_kind'">
                  <span class="label">{{ $t('shipping' )}}:</span>
                  <div class="price-box">
                    <span>
                      <span class="currency">{{ $get(order, 'quota_currency') }} </span>
                      <span class="price">{{ $get(order, 'logistic_fee') }}</span>
                    </span>
                  </div>
                </template>
                <template v-else>
                  <span class="label">{{ $t('dealPrice' )}}:</span>
                  <div class="price-box">
                    <span>
                      <span class="currency">{{ $get(order, 'auction_category.currency') || $get(order, 'currency') }} </span>
                      <span class="price">{{ price | currencyFormat }}</span>
                    </span>
                    <span v-if="showExchangeCurrency && isExchangeCurrency"> (
                      ≈
                      <span class="currency">jpy</span>
                      <span class="price">
                        {{ price * $get(order, 'exchange_rate_to_quota_currency') | currencyFormat }})
                      </span>
                    </span>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
        <div>
          <slot name="right-content"></slot>
        </div>
      </div>
    </div>
    <div>
      <slot/>
    </div>
  </div>
</template>

<script>
  import { Vue, Component, Prop } from 'vue-property-decorator';
  import { CURRENCY_MAP } from '@/constants';
  import ProductDefaultImage from '@/assets/item_default.png';

  @Component
  export default class OrdersItem extends Vue {
    @Prop({ type: Object, default: () => ({}) }) order;
    @Prop(Boolean) showCheckbox
    @Prop(Boolean) showExchangeCurrency
    @Prop({ type: String, default: 'product_kind' }) batchKind

    resize = { w: 210 }

    get OSSProcess() {
      const keys = Object.keys(this.resize);
      return `image/resize,${keys.map(item => `${item}_${this.resize[item]}`).join(',')}`;
    }

    get imageSrc() {
      if (!this.$get(this.order, 'id')) {
        return '';
      }
      const url = this.$get(this.order, 'orderable.cover.url') || this.$get(this.order, 'cover.url');
      if (!url) {
        return ProductDefaultImage;
      }
      return `${url}?x-oss-process=${this.OSSProcess}`;
    }

    get label() {
      const { orderable_type } = this.order;
      if (!orderable_type) {
        return { class: 'auction', text: this.$t('auctionItem') };
      } else {
        return {
          AuctionItem: { class: 'auction', text: this.$t('auctionItem') },
          Product: { class: 'normal', text: this.$t('normalItem') },
        }[orderable_type] || {};
      }
    }

    get price() {
      const { orderable_type, orderable = {} } = this.order;
      if (!orderable_type) {
        return this.order.final_price;
      } else {
        return orderable_type === 'Product' ? orderable.deal_price : orderable.final_price;
      }
    }

    get isExchangeCurrency() {
      return !CURRENCY_MAP.includes(this.$get(this.order, 'currency'));
    }

    handleNavDetail() {
      const routeName = this.$route.name;
      if (!this.order.orderable_type) {
        this.$router.push({ name: 'auctionItemDetail', params: { id: this.order.id } });
        return;
      }
      switch (routeName) {
        case 'orders':
        case 'orderBatchDetail':
          this.$router.push({ name: 'orderDetail', params: { id: this.order.id } });
          return;
        case 'orderDetail':
          this.$router.push({
            name: this.$get(this.order, 'orderable_type') === 'Product' ? 'productDetail' : 'auctionItemDetail',
            params: { id: this.order.orderable_id }
          });
          return;
      }
    }

    toggleCheckbox() {
      this.order.checked = !this.order.checked;
      this.$emit('checkboxChange', this.order);
    }
  }
</script>

<style lang="scss" scoped>
  .component-order-item {
    padding: 16px 0 14px;

    .order-item {
      .checkbox {
        display: inline-block;
        box-sizing: border-box;
        width: 18px;
        height: 18px;
        margin-right: 20px;
        border: 1px solid #dcdfe6;
        border-radius: 50%;
        visibility: visible;
        background: #fff;
        cursor: pointer;

        &.disabled {
          background: #eee;
          pointer-events: none;
        }

        &.checked {
          border-color: $primary;
          font-weight: 100;
          color: #fff;
          background: $primary;

          &::after {
            display: flex;
            justify-content: center;
            align-items: center;
            content: '\2713';
          }
        }
      }

      .info-wrap {
        width: 100%;
        color: #000;
        cursor: pointer;

        &.disabled {
          pointer-events: none;
        }

        .info-box {
          display: flex;
          flex: 1;
        }
      }

      .cover-content,
      .content-wrapper {
        height: 110px;
      }

      .cover-content {
        position: relative;
        width: 110px;
        background: #eee;

        .label {
          position: absolute;
          top: 5px;
          left: 5px;
          padding: 0 4px;
          border-radius: 2px;
          font-size: 7px;
          line-height: 11px;
          color: #fff;
          background: $primary;

          &.normal {
            background: #a58b67;
          }
        }

        .cover {
          flex-shrink: 0;
          width: 110px;
          height: 100%;
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
        }
      }

      .content-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: 20px;

        .title-wrap {
          cursor: pointer;
        }

        .title {
          margin: 0 0 7px;
          font-size: 13px;
        }

        .info {
          width: fit-content;
          padding: 1px 4px;
          margin-bottom: 7px;
          border: 1px solid $primary;
          border-radius: 2px;
          font-size: 9px;
          color: $primary;
        }

        .time {
          font-size: 11px;
          color: #666;
        }

        .price-wrapper {
          display: flex;
          align-items: baseline;
          margin-top: 2px;

          .label {
            font-size: 11px;
            color: #666;
          }

          .currency {
            text-transform: uppercase;
          }

          .price-box {
            margin-left: 8px;

            @include media-xs-only {
              display: flex;
              flex-direction: column;
            }
          }

          .price {
            font-size: 14px;
            color: $primary;
          }
        }
      }
    }
  }
</style>
