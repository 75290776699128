<i18n src="./locales.json"></i18n>

<template>
  <div class="auction-wrap">
    <v-title :value="$t('auctions')"/>
    <skeleton :onFetch="onFetch" v-model="loading"/>
    <template v-if="!loading">
      <common-banner
        :images="banners"
        :oss-img="$root.windowWidth >= 768 ? {
        process: 'image/quality,q_90/format,jpg/interlace,1'
      } : {
        resize: { w: 750 }
      }"
      >
        <template v-slot="{ item }"><banner-content :item="item"/></template>
      </common-banner>
      <div class="container">
        <template v-if="inProgress.length">
          <common-title class="title" :title="$t('atAuction')"/>
          <div class="scroll-wrapper">
            <div class="scroll-content">
              <router-link
                v-for="item in inProgress"
                class="item"
                :key="item.id"
                :to="{ name: 'auctionDetail', params: { id: item.id } }"
              >
                <auction-card :auction="item" />
              </router-link>
            </div>
          </div>
        </template>
        <div v-for="(store, index) in auctionStores" :key="index">
          <common-title class="title" :title="index === 0 ? $t('auctionSessions') : $t('endAuction')" v-if="store.data.length"/>
          <div class="auction-list">
            <el-row :gutter="30">
              <el-col
                :xs="24"
                :lg="12"
                v-for="item in store.data"
                :key="item.id"
              >
                <auction-item class="item" :auction="item"/>
              </el-col>
            </el-row>
            <pagination :store="store" />
          </div>
        </div>

        <!-- 热门拍品列表 -->
        <div class="hot-auction-item-list">
          <div class="flex item-center content-between">
            <common-title v-if="hotAuctions.length" class="title" :title="$t('hotAuctionItemList')"/>

            <div class="show-more" v-if="hotAuctions.length >= 6">
              <router-link class="text" :to="{ path: '/search', query: { type: 'auction_items', sort: 'visits_count desc',  status_in: ['pending'], auction_is_finished_eq: false } }">{{ $t('showMore') }}</router-link>
            </div>
          </div>
          <el-row :gutter="20">
            <el-col :xs="24" :sm="12" :md="8" v-for="item in hotAuctions" :key="item.id">
              <router-link :to="{ name: 'auctionItemDetail', params: { id: item.id }, query: { sort: 'visits_count desc' } }">
                <auction-item-card
                  class="item"
                  :title="`LOT ${item.title}`"
                  :cover="$get(item, 'cover.url')"
                  :info="item.title"
                >
                  <div slot="title" v-if="item.number">LOT {{ item.number }}</div>
                  <div class="price">
                    <div v-if="item.is_finished && item.show_items_final_price && $authStore.isLogin">
                      <div v-if="['hammered', 'force_hammered'].includes(item.status)">
                        <div>
                          {{$t('dealPrice')}}:
                          <span class="text-uppercase">{{item.auction_category.currency}}</span>
                          {{ item.final_price | currencyFormat }}
                        </div>
                        <div v-if="!$options.filters.isLocaleCurrency(item.auction_category.currency)">
                          {{$t('dealPrice')}}: {{$t('currency')}} {{ item.final_price | conversionFormat($get(item.auction_category, 'currency')) | currencyFormat }}
                        </div>
                      </div>
                      <div v-else>
                        {{$t('aborted')}}
                      </div>
                    </div>
                    <div v-else-if="item.starting_price <= 0">
                      {{ $t('estimatePending') }}
                    </div>
                    <div v-else>
                      <div>
                        {{$t('startPrice')}}:
                        <span class="text-uppercase">{{item.auction_category.currency}}</span>
                        {{ item.starting_price | currencyFormat }}
                      </div>
                      <div v-if="!$options.filters.isLocaleCurrency(item.auction_category.currency)">
                        {{$t('startPrice')}}: {{$t('currency')}} {{ item.starting_price | conversionFormat($get(item.auction_category, 'currency')) | currencyFormat }}
                      </div>
                    </div>
                  </div>
                </auction-item-card>
              </router-link>
            </el-col>
          </el-row>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';
  import { Collection, auctionNumbersStore } from '@/store';
  import { fly } from '@/utils';
  import _ from 'lodash';
  import Skeleton from './components/index-skeleton';

  @Component({ components: { Skeleton } })
  export default class Auctions extends Vue {
    loading = true
    banners = [];
    inProgress = [];
    hotAuctions = []
    auctionStores = [
      new Collection({
        fetch(params) {
          return fly.get('auctions', { time_type: 'not_start', ...params });
        }
      }),
      new Collection({
        fetch(params) {
          return fly.get('auctions', { time_type: 'finished', ...params, per_page: 4 });
        }
      })];

    async onFetch() {
      const [{ data: banners }, { data: inProgress }, { data: hotAuctions }] = await Promise.all([
        this.$fly.get('banners', { location_eq: 'auction_page', channel_eq: 'web' }),
        this.$fly.get('auctions', { time_type: 'in_progress', page: 0 }),
        this.$fly.get('auction_items', { sort: 'visits_count desc', per_page: 6, entity: 'AllAuctionItem', status_in: ['pending'], auction_is_finished_eq: false }),
        ...this.auctionStores.map(store => {
          _.set(store, 'params', { ...this.$route.query });
          return store.fetchData();
        }),
        auctionNumbersStore.tryFetchData()
      ]);
      this.banners = banners;
      this.inProgress = inProgress;
      this.hotAuctions = hotAuctions;
    }
  }
</script>

<style lang="scss" scoped>
  .auction-wrap {
    padding-bottom: 20px;

    @include media-sm-and-up {
      padding-bottom: 40px;
    }
  }

  .banner-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .title {
    margin: 30px auto;
  }

  /deep/ .component-title {
    margin-top: 20px;

    @include media-sm-and-up {
      margin: 60px 0 40px;
    }
  }

  .scroll-wrapper {
    overflow: auto;

    .scroll-content {
      display: inline-flex;
      padding: 0 15px 15px;

      @include media-lg-and-up {
        padding: 0 3px 15px;
      }

      .item {
        margin: 0 5px;
        cursor: pointer;

        .auction-card-wrapper {
          min-height: 100%;
        }
      }
    }
  }

  .auction-list {
    .item {
      display: block;
      margin-bottom: 10px;
      cursor: pointer;
    }
  }

  .show-more {
    padding-top: 10px;

    .text {
      font-size: 10px;
      color: #666;
      cursor: pointer;

      @include media-sm-and-up {
        font-size: 12px;
      }
    }
  }
</style>
