<template>
  <div class="component-auction-house-item" @click="handleNavDetail">
    <div class="cover">
      <auto-height height="100%" :cover="$get(auctionHouse, 'logo.url')" />
    </div>
    <div class="name">{{ auctionHouse.name }}</div>
  </div>
</template>

<script>
  import { Vue, Component, Prop } from 'vue-property-decorator';

  @Component
  export default class AuctionHouseItem extends Vue {
    @Prop({ type: Object, default: () => ({}) }) auctionHouse

    handleNavDetail() {
      this.$router.push({ name: 'auctionHouseDetail', params: { id: this.auctionHouse.id } });
    }
  }
</script>

<style lang="scss" scoped>
  .component-auction-house-item {
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    user-select: none;

    @include media-xs-only {
      width: 90px;
    }
  }

  .cover {
    width: 75px;

    @include media-sm-and-up {
      width: 150px;
    }

    /deep/ .auto-height-content {
      border-radius: 50%;
      overflow: hidden;
      box-shadow: 0px 4px 18px 2px rgba(4, 0, 0, 0.1);
      transform: translateY(0);
    }
  }

  .name {
    margin-top: 20px;
    font-weight: 500;
    font-size: 18px;
    text-align: center;
    word-break: break-all;

    @include media-xs-only {
      margin-top: 10px;
      font-size: 13px;
    }
  }
</style>
