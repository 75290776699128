<template>
  <div :id="domId" style="width: 100%; height: 100%"></div>
</template>

<script>
  import { Vue, Component, Prop } from 'vue-property-decorator';
  import { randomString } from '@/utils';
  import _ from 'lodash';

  const PLAYER_SCRIPT = 'https://player.polyv.net/livesdk/polyv-live.min.js';
  // https://dev.polyv.net/2019/uncategorized/web-sdk/

  @Component
  export default class LivePlayer extends Vue {
    @Prop({ type: Object }) initOptions;
    @Prop({ type: Object }) setupOptions;

    uuid = '';

    get domId() {
      return `player-${this.uuid}`;
    }

    async mounted() {
      this.uuid = randomString();
      await this.$nextTick();
      this.loadPlayerScript(this.initSdk);
    }

    destroyed() {
      if (this.liveSdk) {
        this.liveSdk.destroy();
      }
    }

    loadPlayerScript(callback) {
      if (!window.polyvPlayer) {
        const myScript = document.createElement('script');
        myScript.setAttribute('src', PLAYER_SCRIPT);
        myScript.onload = callback;
        document.body.appendChild(myScript);
      } else {
        callback();
      }
    }

    initSdk() {
      const PolyvLiveSdk = window.PolyvLiveSdk;
      this.liveSdk = new PolyvLiveSdk(_.merge({}, {
        user: { userId: 'visitor' }
      }, this.initOptions));

      this.liveSdk.on(PolyvLiveSdk.EVENTS.CHANNEL_DATA_INIT, () => {
        this.liveSdk.setupPlayer({
          el: `#${this.domId}`,
          type: 'auto',
          autoplay: false,
          audioMode: false,
          width: '100%',
          height: '100%',
          controller: true,
          ...this.setupOptions
        });
      });
    }

  }
</script>
