<i18n>
{
  "zh_cn": {
    "transportCompanies": "运输公司简介",
    "about": "关于"
  },
  "zh_hk": {
    "transportCompanies": "運輸公司簡介",
    "about": "關於"
  },
  "en": {
    "transportCompanies": "Introduction of Transportation Company",
    "about": "about"
  },
  "ja": {
    "transportCompanies": "運送会社の紹介",
    "about": "オン"
  }
}
</i18n>

<template>
  <div class="container flex column content-center item-center">
    <v-title :value="$t('transportCompanies')"/>
    <img class="logo" :src="$get(transportDetail, 'logo.url')" />
    <div class="title"><span>{{ $t('about') }} </span>{{ transportDetail.name }}</div>
    <div class="desc">{{transportDetail.desc}}</div>
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';

  @Component
  export default class TransportCompanies extends Vue {
    transportDetail = {};

    @Vue.autoLoading
    async created() {
      const { id } = this.$route.params;
      const { data } = await this.$fly.get(`transport_companies/${id}`);
      this.transportDetail = data;
    }
  }
</script>

<style lang="scss" scoped>
  .logo {
    height: 100%;
  }

  .title {
    padding-bottom: 20px;
    font-weight: bold;
    font-size: 18px;
  }

  .desc {
    white-space: pre-wrap
  }
</style>
