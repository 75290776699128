<i18n src="./locales.json"></i18n>

<template>
  <div class="page-product-detail">
    <v-title :value="store.data.title" />
    <auction-item-toggle-control
      class="hidden-xs-only"
      sticky
      v-show="!hiddenToggleControl"
      :routeTo="{ name: 'products', query: { ...$route.query } }"
      :show-next="!!nextItem"
      :show-pre="!!preItem"
      :title="showToggleTitle ? $t('toggleTitle') : ''"
      @pre="handleNavigate('pre')"
      @next="handleNavigate('next')"
    >
      <template slot="pre">{{ $t('prev') }}</template>
      <template slot="next">{{ $t('next') }}</template>
    </auction-item-toggle-control>
    <mobile-product-info :store="store" />
    <div class="container">
      <!-- 移动端显示 -->
      <div class="mobile hidden-md-and-up">
        <auction-item-swiper :images="$get(store, 'data.images')" :video="$get(store, 'data.video')"/>
        <follow-share :detail="$get(store, 'data')" :fixed="false" targetType="Product" class="flex content-end" />
        <div class="mobile-main">
          <div class="box-countdown" v-if="showStartCountDown || showEndCountDown || !authStore.isLogin">
            <div class="label">
              <span v-if="showStartCountDown">{{ $t('startCountdown') }}</span>
              <span v-if="showEndCountDown">{{ $t('endtCountdown') }}</span>
            </div>
            <app-countdown
              :key="showTime"
              end-hide
              showDate
              plusOneMinute
              target="div"
              :end-time="showTime"
              v-if="showStartCountDown || showEndCountDown"
            >
              <template v-slot="{ day, hour, minute }">
                <div class="countdown">
                  <span>{{ day }}</span>{{ $t('day') }}
                  <span>{{ hour }}</span>{{ $tc('hour', hour) }}
                  <span>{{ minute }}</span>{{ $tc('minute', minute) }}
                </div>
              </template>
            </app-countdown>
            <div class="tips" v-html="tips" @click="handleClickTips" v-if="!authStore.isLogin"></div>
          </div>
          <div
            class="primary-btn"
            @click="handleParticipate"
            v-if="!store.isFinished && !showStartCountDown"
          >
            {{ $t(store.onlyBuyNowProduct ? 'directPurchase' : 'participateBidding') }}
          </div>
          <div class="primary-btn disabled" v-else-if="store.isFinished">{{ $t('ended') }}</div>
          <div class="primary-btn disabled" v-else-if="showStartCountDown">{{ $t('notStart') }}</div>
          <div class="flex content-center">
            <div class="contact" @click="handleContact">{{ $t('inquiry') }} &gt;</div>
          </div>
          <!-- 已流拍 -->
          <div class="aborted flex item-center content-center" v-if="aborted">
            {{ $t('aborted') }}
          </div>
          <div class="price-wrapper" v-else>
            <!-- 成交价格 -->
            <div v-if="$get(store, 'data.deal_price')" class="current-price flex column item-center content-center">
              <div>
                <span class="label">{{ $t('dealPrice') }}</span>
                <span class="currency text-uppercase">{{ $get(store, 'data.currency') }}</span>
                <span class="amount">{{ $get(store, 'data.deal_price') | currencyFormat }}</span>
                <span v-if="!$options.filters.isLocaleCurrency($get(store, 'data.currency'))">
                  ( ≈ {{ $t('currency') }} <span class="primary">{{ $get(store, 'data.deal_price') | conversionFormat($get(store, 'data.currency')) | currencyFormat }}</span> )
                </span>
              </div>
            </div>
            <!-- 一口价商品价格 -->
            <div v-else-if="store.onlyBuyNowProduct" class="current-price flex column item-center content-center">
              <div class="aborted flex item-center content-center" v-if="aborted">
                {{ $t('aborted') }}
              </div>
              <div v-else>
                <span class="label">{{ $t('buyNowPrice') }}</span>
                <span class="currency text-uppercase">{{ $get(store, 'data.currency') }}</span>
                <span class="amount">{{ $get(store, 'data.confirmed_price') | currencyFormat }}</span>
                <span v-if="!$options.filters.isLocaleCurrency($get(store, 'data.currency'))">
                  ( ≈ {{ $t('currency') }} <span class="primary">{{ $get(store, 'data.confirmed_price') | conversionFormat($get(store, 'data.currency')) | currencyFormat }}</span> )
                </span>
              </div>
            </div>
            <!-- 竞价商品价格 -->
            <template v-else>
              <div :class="['current-price', { auction: !store.auctionAndBuyNowProduct }]">
                <div>
                  <span class="label">{{ $get(store, 'data.current_price') ? $t('currentPrice') : $t('startedPrice') }}</span>
                  <span class="currency text-uppercase">{{ $get(store, 'data.currency') }}</span>
                  <span class="amount">{{ $get(store, 'currentPrice') | currencyFormat }}</span>
                  <span v-if="!$options.filters.isLocaleCurrency($get(store, 'data.currency'))">
                    ( ≈ {{ $t('currency') }} <span class="primary">{{ $get(store, 'currentPrice') | conversionFormat($get(store, 'data.currency')) | currencyFormat }}</span> )
                  </span>
                </div>
              </div>
              <div v-if="store.auctionAndBuyNowProduct" class="current-price auction">
                <div>
                  <span class="label">{{ $t('buyNowPrice') }}</span>
                  <span class="currency text-uppercase">{{ $get(store, 'data.currency') }}</span>
                  <span class="amount">{{ $get(store, 'data.confirmed_price') | currencyFormat }}</span>
                  <span v-if="!$options.filters.isLocaleCurrency($get(store, 'data.currency'))">
                    ( ≈ {{ $t('currency') }} <span class="primary">{{ $get(store, 'data.confirmed_price') | conversionFormat($get(store, 'data.currency')) | currencyFormat }}</span> )
                  </span>
                </div>
              </div>
              <div class="lowest-price" v-if="$get(store, 'data.current_price')">
                <div>
                  <span class="label">{{ $t('nextMiniPrice') }}</span>
                  <span class="currency text-uppercase">{{ $get(store, 'data.currency') }} </span>
                  <span class="amount" v-if="store.auctionAndBuyNowProduct">{{ (store.nextLowestPrice > store.isBuyNowProductPrice ? store.isBuyNowProductPrice : store.nextLowestPrice) | currencyFormat }}</span>
                  <span class="amount" v-if="store.onlyAuctionProduct">{{ store.nextLowestPrice | currencyFormat }}</span>
                  <span v-if="!$options.filters.isLocaleCurrency($get(store, 'data.currency'))">
                    ( ≈ {{ $t('currency') }}
                      <span class="primary" v-if="store.auctionAndBuyNowProduct">
                        {{ (store.nextLowestPrice > store.isBuyNowProductPrice ? store.isBuyNowProductPrice : store.nextLowestPrice) | conversionFormat($get(store, 'data.currency')) | currencyFormat }}
                      </span>
                      <span class="primary" v-if="store.onlyAuctionProduct">
                        {{ store.nextLowestPrice | conversionFormat($get(store, 'data.currency')) | currencyFormat }}
                      </span>
                    )
                  </span>
                </div>
              </div>
            </template>
            <div class="hint" v-if="!store.isFinished">{{ $t('productBidHint') }}</div>
            <div class="hint" v-if="store.showTransportAreaTips">{{ $t('transportAreaTips') }}</div>
            <router-link
              class="primary-btn-o"
              :to="{ path: `/products/${$route.params.id}/product_auction` }"
              v-if="!store.onlyBuyNowProduct && !showStartCountDown"
            >
              {{ $t('viewAllPrice') }}
            </router-link>
          </div>
        </div>
        <!-- 商品描述 -->
        <div v-html="$get(store, 'data.desc')"></div>
        <div v-if="$get(store, 'data.fixed_product_desc')" class="fix-desc">{{ $get(store, 'data.fixed_product_desc') }}</div>
      </div>
      <!-- pc端显示 -->
      <div class="pc hidden-sm-and-down">
        <div class="top flex">
          <auction-item-swiper :images="$get(store, 'data.images')" :video="$get(store, 'data.video')"/>
          <div class="pc-main">
            <pc-product-info :store="store" />
            <!-- 商品描述 -->
            <div v-html="$get(store, 'data.desc')"></div>
            <div v-if="$get(store, 'data.fixed_product_desc')" class="fix-desc">{{ $get(store, 'data.fixed_product_desc') }}</div>
          </div>
        </div>
        <div class="box-countdown" v-if="showStartCountDown || showEndCountDown || !authStore.isLogin">
          <div class="label">
            <span v-if="showStartCountDown">{{ $t('startCountdown') }}</span>
            <span v-if="showEndCountDown">{{ $t('endtCountdown') }}</span>
          </div>
          <app-countdown
            :key="showTime"
            end-hide
            showDate
            plusOneMinute
            target="div"
            :end-time="showTime"
            v-if="showStartCountDown || showEndCountDown"
          >
            <template v-slot="{ day, hour, minute }">
              <div class="countdown">
                <span>{{ day }}</span>{{ $t('day') }}
                <span>{{ hour }}</span>{{ $tc('hour', hour) }}
                <span>{{ minute }}</span>{{ $tc('minute', minute) }}
              </div>
            </template>
          </app-countdown>
          <div class="tips" v-html="tips" @click="handleClickTips" v-if="!authStore.isLogin"></div>
        </div>
        <div
          class="primary-btn"
          @click="handleParticipate"
          v-if="!store.isFinished && !showStartCountDown"
        >
          {{ $t(store.onlyBuyNowProduct ? 'directPurchase' : 'participateBidding') }}
        </div>
        <div class="primary-btn disabled" v-else-if="store.isFinished">{{ $t('ended') }}</div>
        <div class="primary-btn disabled" v-else-if="showStartCountDown">{{ $t('notStart') }}</div>
        <div class="flex content-center">
          <div class="contact" @click="handleContact">{{ $t('inquiry') }} &gt;</div>
        </div>
      </div>
      <div v-if="!$auctionHouseStore.domainHouse" class="auction-house">
        <div>
          <div class="title">{{ $t('auctionHouse') }}</div>
          <auction-house-card :auctionHouse="$get(store, 'data.auction_house')" />
        </div>
      </div>
      <div class="product-list-wrapper" v-if="productsStore.data.length">
        <common-title :title="$t('similarProducts')" />
        <div class="product-list">
          <el-row :gutter="8" justify="space-between">
            <el-col :xs="8" :sm="6" :md="4" v-for="product in productsStore.data" :key="product.id">
              <product-item class="component-product-item" :product="product" :productQuery="productQuery"/>
            </el-col>
            <el-col :xs="8" :sm="6" :md="4">
              <div class="more-product" @click="handleNavProducts">
                <img class="cover" :src="$get(store, 'data.product_category.image.url')" />
                <div class="inner-text">
                  <div>{{ $t('more') }}{{ $get(store, 'data.product_category.title') }}</div>
                  <div>{{ $t('viewProducts') }}</div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <app-fixed-bottom class="hidden-sm-and-up">
      <auction-item-toggle-control
        :routeTo="{ name: 'products', query: { ...$route.query } }"
        :show-next="!!nextItem"
        :show-pre="!!preItem"
        v-show="!hiddenToggleControl"
        :title="showToggleTitle ? $t('toggleTitle') : ''"
        @pre="handleNavigate('pre')"
        @next="handleNavigate('next')"
      >
        <template slot="pre">{{ $t('prev') }}</template>
        <template slot="next">{{ $t('next') }}</template>
      </auction-item-toggle-control>
    </app-fixed-bottom>
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';
  import dayjs from 'dayjs';
  import { authStore, ProductDetailStore, Collection, productIndexStore } from '@/store';
  import _ from 'lodash';
  import MobileProductInfo from './components/mobile-product-info';
  import PcProductInfo from './components/pc-product-info';

  @Component({
    components: {
      'mobile-product-info': MobileProductInfo,
      'pc-product-info': PcProductInfo
    }
  })
  export default class ProductDetail extends Vue {
    showAllDesc = false
    hasShowAllBtn = false
    isPreview = false
    authStore = authStore
    store = {}
    productsStore = new Collection({
      fetch: params => this.$fly.get('products', params)
    })
    dayjs = dayjs
    productIndexStore = productIndexStore;
    productQuery = { productType: 'product' }

    get tips() {
      return {
        zh_cn: '温馨提示：您必须<span class="primary" data-login="1">登录</span>或者<span class="primary" data-signup="1">注册账号</span>，方可出价竞投。',
        zh_hk: '溫馨提示：您必須<span class="primary" data-login="1">登錄</span>或者<span class="primary" data-signup="1">註冊賬號</span>，方可出價競投。',
        en: 'Tips: You must <span class="primary" data-login="1">Log In</span> or <span class="primary" data-signup="1">Register An Account</span> before you can bid.',
        ja: '<span class="primary" data-login="1">ログイン</span>または<span class="primary" data-signup="1">アカウントを登録して</span>、入札することができます。'
      }[this.$i18n.locale];
    }

    get aborted() {
      return this.$get(this.store, 'data.bid_status') === 'aborted';
    }

    get showStartCountDown() {
      return this.$get(this.store, 'data.started_bidding_time') && this.store.isNotStart;
    }

    get showEndCountDown() {
      return this.$get(this.store, 'data.ended_bidding_time') && !this.store.isFinished && !this.showStartCountDown;
    }

    get showTime() {
      if (this.showStartCountDown) {
        return this.$get(this.store, 'data.started_bidding_time');
      } else if (this.showEndCountDown) {
        return this.$get(this.store, 'data.ended_bidding_time');
      } else { return ''; }
    }

    // 从单个商品，或者输入链接进来的，用后端的传的 前后 id，否则用请求的 ids
    get preItem() {
      const { productType } = this.$route.query;
      if (productType && productIndexStore.isFulfilled) {
        return this.productIndexStore.preItem;
      }
      const productId = this.$get(this.store, 'data.prev_product_id');
      if (productId) {
        return {
          id: productId,
          target_type: 'Product'
        };
      }
      return null;
    }

    get nextItem() {
      const { productType } = this.$route.query;
      if (productType) {
        return this.productIndexStore.nextItem;
      }
      const productId = this.$get(this.store, 'data.next_product_id');
      if (productId) {
        return {
          id: productId,
          target_type: 'Product'
        };
      }
      return null;
    }

    // 不是从商品列表进来的，不要显示返回商品列表
    get showToggleTitle() {
      return !_.isEmpty(this.$route.query);
    }

    // 不是从商品列表进来的,无上下一件时，不显示
    get hiddenToggleControl() {
      return !this.showToggleTitle && !this.nextItem && !this.preItem;
    }

    @Vue.autoLoading
    async created() {
      this.isPreview = !!this.$route.query.preview;
      this.store = new ProductDetailStore(this.$route.params.id, this.isPreview);
      await this.store.fetchData();
      const { id, auction_house_id, product_category_id, title } = this.store.data;
      this.productsStore.params = {
        per_page: 5,
        id_not_eq: id,
        auction_house_id_eq: auction_house_id,
        product_category_id_eq: product_category_id,
        is_preview: this.isPreview ? true : undefined
      };
      await Promise.all([
        this.productsStore.fetchData(),
        this.$fly.post('action_stores', {
          action_type: 'visit',
          target_type: 'Product',
          target_id: id
        })
      ]);
      if (this.isPreview) {
        this.$message.warning({
          message: this.$t('preview_mode'),
          duration: 10000
        });
      }
      Object.assign(this.productQuery, this.productsStore.params, { type: 'product_category', name: title });
      productIndexStore.setRouteParams(this.$route.params, this.$route.query);
      await productIndexStore.tryFetchData();
    }

     // 咨询问题
    async handleContact() {
      const { data } = await this.$autoLoading(this.$fly.post('message_channels/contact_service', {
        target_type: 'Product',
        target_id: this.$route.params.id,
        communicator_type: 'AuctionHouse',
        communicator_id: this.$get(this.store, 'data.auction_house_id')
      }));
      this.$router.push({ name: 'messageChannelDetail', params: { id: data.id } });
    }

    handleNavigate(type) {
      const item = type === 'next' ? this.nextItem : this.preItem;
      if (item) {
        const id = _.get(item, 'id') || _.get(item, 'target_id');
        const name = {
          Product: 'productDetail',
          AuctionItem: 'auctionItemDetail'
        }[item.target_type];
        this.$router.replace({ name, params: { id }, query: { ...this.$route.query } });
      }
    }

    // 如果没有上传任何证件，没有填写证件有效期，提示用户填写后再进行下一步
    async handleParticipate() {
      if (!authStore.isLogin) {
        await this.$confirm(this.$t('logFirst'));
        return this.$router.push({ name: 'login', query: { redirect: encodeURIComponent(this.$route.fullPath) } });
      }
      if (this.$authStore.isLoadCredential) {
        if (this.$authStore.isLoadEffectiveDate) {
          await this.$confirm(this.$t('uploadValidityPeriod'));
          return this.$router.push({ name: 'myAddress' });
        }
        return this.$router.push({ name: 'productAuction', params: { id: this.$route.params.id }, query: { isPreview: this.isPreview } });
      } else {
        await this.$confirm(this.$t('uploadTip'));
        return this.$router.push({ name: 'myAddress' });
      }
    }

    handleClickTips(e) {
      const { login, signup } = e.target.dataset;
      login && this.$router.push({ name: 'login', query: { redirect: encodeURIComponent(this.$route.fullPath) } });
      signup && this.$router.push({ name: 'signup' });
    }

    handleNavProducts() {
      this.$router.push({
        name: 'products',
        query: {
          type: 'product_category',
          id: this.$get(this.store, 'data.product_category.id'),
          name: this.$get(this.store, 'data.product_category.title'),
        }
      });
    }
  }
</script>

<style lang="scss" scoped>
  .mobile {
    .mobile-main {
      border-bottom: 1px solid #ddd;
      overflow: hidden;

      .aborted {
        padding: 15px 0;
        font-weight: 500;
      }

      .price-wrapper {
        padding: 35px 0 30px;

        .current-price {
          font-weight: 500;
          text-align: center;

          &.auction {
            padding-bottom: 15px;
            border-bottom: 1px solid #ddd;
          }

          .amount {
            margin-bottom: 5px;
            margin-left: 5px;
            font-size: 25px;
            color: $primary;
          }

          .primary {
            color: $primary;
          }
        }

        .lowest-price {
          margin-top: 15px;
          font-weight: 500;
          text-align: center;

          .amount {
            font-size: 18px;
            color: $primary;
          }

          .primary {
            color: $primary;
          }
        }

        .hint {
          display: flex;
          justify-content: center;
          margin-top: 20px;
          font-size: 11px;
          line-height: 18px;
          color: $primary;
        }

        .primary-btn-o {
          width: fit-content;
          height: 30px;
          padding: 0 18px;
          margin-top: 20px;
          font-size: 13px;
          color: #330000;
        }
      }
    }

    .fix-desc {
      margin-bottom: 14px;
    }
  }

  .pc {
    .top {
      padding: 38px 0;

      /deep/ .component-lot-detail {
        width: 400px;
      }

      .pc-main {
        flex: 1;
        padding: 0 30px;
      }
    }
  }

  .auction-house {
    box-sizing: border-box;
    padding: 15px 0;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;

    @include media-sm-and-up {
      border: none;
    }

    .title {
      font-weight: bold;
    }

    .component-auction-house-card {
      margin-top: 25px;
    }
  }

  .product-list-wrapper {
    padding: 30px 0 5px;

    @include media-sm-and-up {
      padding: 30px 0;
    }

    .title-wrap {
      @include media-sm-and-up {
        padding: 0 20px;
      }
    }

    .product-list {
      margin-top: 25px;

      .component-product-item {
        margin-bottom: 10px;
      }

      .more-product {
        position: relative;
        height: 0;
        padding-bottom: 200%;
        border-radius: 2px;
        overflow: hidden;
        background: #eee;
        cursor: pointer;

        @include media-xs-only {
          padding-bottom: 190%;
        }

        .cover {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .inner-text {
          position: absolute;
          z-index: 100;
          top: 0;
          left: 0;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          padding: 0 16px;
          color: #fff;
          background: rgba(0, 0, 0, 0.4);

          div:first-of-type {
            margin-top: -20px;
          }
        }
      }
    }
  }

  .contact {
    padding-top: 16px;
    text-decoration-line: underline;
    color: $gray;
    cursor: pointer;

    &:hover {
      color: $primary;
    }
  }

  .box-countdown {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 20px;
    margin-top: 22px;
    font-size: 12px;
    color: $gray;
    background: #eee;

    .countdown {
      margin-top: 5px;

      span {
        font-size: 25px;
        color: $primary;
      }
    }

    .tips {
      font-size: 10px;
      color: #666;

      /deep/ .primary {
        text-decoration: underline;
        color: $primary
      }
    }
  }

  .primary-btn {
    margin-top: 27px;
  }
</style>
