<i18n>
{
  "zh_cn": {
    "totalPrice": "商品总额",
    "walletDeduct": "钱包抵扣",
    "maxPrice": "不可超过",
    "minPrice": "钱包抵扣金额不可低于",
    "total": "合计",
    "confirmPay": "确认并付款"
  },
  "zh_hk": {
    "totalPrice": "商品總額",
    "walletDeduct": "錢包抵扣",
    "maxPrice": "不可超過",
    "minPrice": "錢包抵扣金額不可低於",
    "total": "合計",
    "confirmPay": "確認並付款"
  },
  "en": {
    "totalPrice": "Total merchandise",
    "walletDeduct": "Wallet deduction",
    "maxPrice": "Cannot exceed",
    "minPrice": "The deduction amount of wallet cannot be less than",
    "total": "Total",
    "confirmPay": "Confirm Payment"
  },
  "ja": {
    "totalPrice": "商品総額",
    "walletDeduct": "財布を伏せる",
    "maxPrice": "最大で超えてはいけない",
    "minPrice": "財布の控除額は以下でなければならない",
    "total": "合計",
    "confirmPay": "支払いを確認する"
  }
}
</i18n>

<template>
  <div class="component-payment-deduction">
<!-- 移动端显示 -->
    <action-sheet
      v-model="showPaymentDeduction"
      @input="handleClosePanel"
      v-if="$isMobile()"
    >
      <div class="auction-sheet-content">
        <div class="info-wrapper flex item-center content-between">
          <div class="info-title">{{ $t('totalPrice') }}：</div>
          <div>
            <span class="text-uppercase">{{ currency }} </span>
            <span class="info-price">{{ amount | currencyFormat }}</span>
          </div>
        </div>
        <div class="info-wrapper flex item-center content-between">
          <div class="info-title">{{ $t('walletDeduct') }}：</div>
          <div :class="['input-wrapper', 'flex', 'item-center', 'content-end', { active: focus }]" @click="handleInputFocus">
            <span class="currency text-uppercase">{{ currency }}</span>
            <span class="placeholder-text">{{ discountAmount | currencyFormat }}</span>
            <input
              class="input"
              type="number"
              ref="input"
              v-show="focus"
              v-model="discountAmount"
              @blur="handleInputBlur"
            />
          </div>
        </div>
        <div>
          <div class="limit-price">
            <span>{{ $t('maxPrice') }} </span>
            <span class="text-uppercase" style="color: #000">JPY </span>
            <span style="color: #bb4353;">{{ detail.max_margin_amount | currencyFormat }}</span>
          </div>
        </div>
        <div class="info-wrapper flex item-center content-between" style="padding: 1.8rem 0;">
          <div class="info-title">{{ $t('total') }}：</div>
          <div>
            <span class="text-uppercase">{{ currency }} </span>
            <span class="info-price">{{ paymentAmount | currencyFormat }}</span>
          </div>
        </div>
        <div class="submit-btn primary-btn btn" @click="handleConfirm">{{ $t('confirmPay') }}</div>
      </div>
    </action-sheet>

<!-- pc端显示 -->
    <el-dialog
      :visible="showPaymentDeduction"
      @update:visible="handleClosePanel"
      width="29.33333rem"
      center
      v-else
    >
      <div class="info-wrapper flex item-center content-between">
        <div class="info-title">{{ $t('totalPrice') }}：</div>
        <div>
          <span class="text-uppercase">{{ currency }} </span>
          <span class="info-price">{{ amount | currencyFormat }}</span>
        </div>
      </div>
      <div class="info-wrapper flex item-center content-between">
        <div class="info-title">{{ $t('walletDeduct') }}：</div>
        <div :class="['input-wrapper', 'flex', 'item-center', 'content-end', { active: focus }]" @click="handleInputFocus">
          <span class="currency text-uppercase">{{ currency }}</span>
          <span class="placeholder-text">{{ discountAmount | currencyFormat }}</span>
          <input
            class="input"
            type="number"
            ref="input"
            v-show="focus"
            v-model="discountAmount"
            @blur="handleInputBlur"
          />
        </div>
      </div>
      <div>
        <div class="limit-price">
          <span>{{ $t('maxPrice') }} </span>
          <span class="text-uppercase" style="color: #000">JPY </span>
          <span style="color: #bb4353;">{{ detail.max_margin_amount | currencyFormat }}</span>
        </div>
      </div>
      <div class="info-wrapper flex item-center content-between" style="padding: 1.8rem 0;">
        <div class="info-title">{{ $t('total') }}：</div>
        <div>
          <span class="text-uppercase">{{ currency }} </span>
          <span class="info-price">{{ paymentAmount | currencyFormat }}</span>
        </div>
      </div>
      <div class="submit-btn primary-btn btn" @click="handleConfirm">{{ $t('confirmPay') }}</div>
    </el-dialog>
  </div>
</template>

<script>
  import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
  import { authStore } from '@/store';

  @Component
  export default class PaymentDeduction extends Vue {
    @Prop({ type: Boolean, default: false }) value
    @Prop({ type: Object, default: () => ({}) }) detail
    @Prop({ type: Number, default: 0 }) current // 之前有填过的钱包抵扣金额

    showPaymentDeduction = false
    focus = false
    discountAmount = 0

    authStore = authStore

    // 商品总额
    get amount() {
      if (this.$get(this.detail, 'exchange_rate_type') === 'to_jpy') {
        return this.$get(this.detail, 'payment_amount_to_quota_currency');
      } else {
        return this.$get(this.detail, 'payment_amount') || this.$get(this.detail, 'amount');
      }
    }

    get currency() {
      if (this.$get(this.detail, 'exchange_rate_type') === 'to_jpy') {
        return 'JPY';
      } else {
        return this.$get(this.detail, 'currency');
      }
    }

    get canDiscountAmount() {
      return Math.min(this.amount, this.$get(this.detail, 'max_margin_amount'));
    }

    // 合计
    get paymentAmount() {
      return Math.max(this.amount - this.discountAmount, 0);
    }

    // 自动抵扣额度
    get defaultMarginAmount() {
      return this.$get(this.detail, 'default_margin_amount');
    }

    @Watch('current')
    currentChange(val) {
      if (val && this.discountAmount !== val) {
        this.discountAmount = val;
      }
    }

    @Watch('value', { immediate: true })
    valueChange(val, old) {
      if (val !== old) {
        this.showPaymentDeduction = this.value;
      }
    }

    handleClosePanel(e) {
      this.$emit('input', e);
    }

    handleInputFocus() {
      this.focus = true;
      this.$nextTick(() => {
        this.$refs.input.focus();
      });
    }

    // 抵扣额度不能超过充值额度，不能小于自动抵扣额度
    handleInputBlur() {
      this.focus = false;
      this.discountAmount = Number(this.discountAmount);
      if (this.discountAmount > this.canDiscountAmount) {
        this.discountAmount = this.canDiscountAmount;
      }
      if (this.discountAmount < this.defaultMarginAmount) {
        this.discountAmount = this.current;
        this.$alert(`${this.$t('minPrice')} JPY ${this.$options.filters.currencyFormat(this.defaultMarginAmount)}`);
      }
    }

    async handleConfirm() {
      // 如果没有上传任何证件，没有证件有效期，提示用户填写后再支付
      if (this.$authStore.isLoadCredential) {
        if (this.$authStore.isLoadEffectiveDate) {
          await this.$confirm(this.$t('uploadValidityPeriod'));
          return this.$router.push({ name: 'myAddress' });
        }
        this.$emit('confirm', this.discountAmount);
        this.handleClosePanel(false);
      } else {
        await this.$confirm(this.$t('uploadTip'));
        return this.$router.push({ name: 'myAddress' });
      }
    }
  }
</script>

<style lang="scss" scoped>
  .auction-sheet-content {
    padding: 10px 15px 0;

    .info-wrapper {
      padding: 6px 0;
      font-size: 14px;
      color: #000;

      .info-title {
        font-weight: 800;
      }

      .input-wrapper {
        position: relative;
        width: 236px;
        height: 40px;
        padding: 0 13px;
        border: 1px solid #ddd;

        &.active {
          border: 1px solid $primary;
        }

        .currency {
          margin-right: 2px;
          line-height: 1.2;
        }

        .placeholder-text {
          font-size: 16px;
          color: $primary;
        }

        .input {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          padding: 0 13px;
          border: none;
          outline: none;
          font-size: 16px;
          text-align: right;
          color: $primary;
        }
      }
    }

    .info-price {
      font-size: 16px;
      color: $primary;
    }

    .btn {
      margin-bottom: 28px;
      font-size: 18px;
      background: $primary;
    }
  }

  .limit-price {
    padding: 5px 0 15px;
    border-bottom: 1px solid #ddd;
    font-size: 10px;
    color: #999;
  }

  /deep/ .el-dialog {
    border-radius: 19px;

    .el-dialog__body {
      padding: 30px 16px 44px;
    }

    .info-wrapper {
      padding: 6px 0;
      font-size: 13px;
      color: #000;

      .info-title {
        font-weight: 800;
      }

      .input-wrapper {
        position: relative;
        width: 218px;
        height: 37px;
        padding: 0 13px;
        border: 1px solid #ddd;

        &.active {
          border: 1px solid $primary;
        }

        .currency {
          margin-right: 2px;
          line-height: 1.2;
        }

        .placeholder-text {
          font-size: 16px;
          color: $primary;
        }

        .input {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          padding: 0 13px;
          border: none;
          outline: none;
          font-size: 16px;
          text-align: right;
          color: $primary;
        }
      }
    }

    .info-price {
      font-size: 16px;
      color: $primary;
    }

    .btn {
      margin-top: 10px;
      font-size: 18px;
      background: $primary;
    }
  }
</style>
