import Vue from 'vue';
import { SimpleStore } from './simple-store';

export class Collection extends SimpleStore {
  static defaultParams = {
    page: 1,
    per_page: 10,
  }

  meta = { total: 0, page: 1, offset: 0 }
  data = []
  $params = Object.assign({}, this.constructor.defaultParams, this.params)

  fetchData(params = {}) {
    Object.assign(this.params, params);
    return this.fetching(async () => {
      const { data, meta } = await this.fetch(this.params);
      this.meta = meta;
      this.data = data;
    });
  }

  fetchMoreData() {
    this.params.offset = this.data.length;
    return this.fetching(async () => {
      const { data, meta } = await this.fetch(this.params);
      this.meta = meta;
      this.data.push(...data);
    });
  }

  resetData() {
    this.isFulfilled = false;
    this.data.clear();
  }

  get params() {
    return this.$params;
  }

  set params(properties) {
    this.$params = Object.assign({}, this.constructor.defaultParams, properties);
  }

  get isComplete() {
    return this.isFulfilled && this.data.length >= this.meta.total;
  }

  get isEmpty() {
    return this.isFulfilled && this.data.length === 0;
  }
}

Vue.prototype.$Collection = Collection;
