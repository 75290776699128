import { render, staticRenderFns } from "./order-pdf-item.vue?vue&type=template&id=3ab60e2e&scoped=true&"
import script from "./order-pdf-item.vue?vue&type=script&lang=js&"
export * from "./order-pdf-item.vue?vue&type=script&lang=js&"
import style0 from "./order-pdf-item.vue?vue&type=style&index=0&id=3ab60e2e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ab60e2e",
  null
  
)

/* custom blocks */
import block0 from "../locales.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2FUsers%2Fquake%2Fworkspace%2Fauction%2Ftokyo-auction-frontend%2Fsrc%2Fviews%2Forders%2Fcomponents%2Forder-pdf-item.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports