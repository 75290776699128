import { render, staticRenderFns } from "./auction-number.vue?vue&type=template&id=dbdad6de&scoped=true&"
import script from "./auction-number.vue?vue&type=script&lang=js&"
export * from "./auction-number.vue?vue&type=script&lang=js&"
import style0 from "./auction-number.vue?vue&type=style&index=0&id=dbdad6de&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dbdad6de",
  null
  
)

/* custom blocks */
import block0 from "./locales.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2FUsers%2Fquake%2Fworkspace%2Fauction%2Ftokyo-auction-frontend%2Fsrc%2Fviews%2Fmine%2Fauction-number.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports