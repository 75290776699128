<template>
  <div>
    <v-title :value="houseDetail.name"/>
    <div class="detail">
      <div class="flex item-center content-center">
        <img class="house-img" :src="$get(houseDetail, 'logo.url')">
        <div class="title-contain" v-if="houseDetail.can_manage_store">
          <router-link :to="{ name: 'evaluations', query: { auction_house_id: houseDetail.id }}">
            <div class="evaluation flex item-center content-center">
              <span>{{ $t('evaluation') }}</span>
              <span class="number">{{ $get(houseDetail, 'evaluations_count') }}</span>
              <img class="icon-arrow-right" src="@/assets/icon-arrow-right-black.png" />
            </div>
          </router-link>
        </div>
      </div>
      <div class="container">
        <common-title class="component-title-wrap" :title="$t('companyDetail')"/>
      </div>
      <div class="introduction">
        <div class="content-wrapper" v-html="houseDetail.intro"></div>
        <vue-baidu-map v-if="$get(houseDetail, 'geographic_coordinates')" class="map" :ak="baiduMapAk" :center="center" :zoom="15"/>
      </div>
    </div>
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';
  import VueBaiduMap from 'vue-baidu-map/components/map/Map.vue';
  import { auctionHouseStore } from '@/store';

  @Component({
    components: {
      'vue-baidu-map': VueBaiduMap,
    }
  })
  export default class DomainAuctionHouse extends Vue {
    houseDetail = {};
    baiduMapAk = '';
    center = { lng: 0, lat: 0 };
    auctionHouseStore = auctionHouseStore;

    @Vue.autoLoading
    async created() {
      this.baiduMapAk = process.env.VUE_APP_BAIDU_MAP_AK;

      await this.auctionHouseStore.tryFetchData();
      const id = await this.auctionHouseStore.domainHouse.id;
      const { data } = await this.$fly.get(`auction_houses/${id}`);
      this.houseDetail = data;
      if (data.geographic_coordinates) {
        const geoGraphic = data.geographic_coordinates.split(',');
        this.center.lng = geoGraphic[0];
        this.center.lat = geoGraphic[1];
      }
    }
  }
</script>

<style lang="scss" scoped>
  .detail {
    margin: 39px 0 35px;

    .house-img {
      width: 75px;
      height: 75px;
      margin-right: 23px;
      border-radius: 50%;
      box-shadow: 0px 4px 18px 2px rgba(4, 0, 0, 0.1);
      object-fit: cover;

      @include media-sm-and-up {
        width: 94px;
        height: 94px;
        margin-right: 36px;
      }
    }

    .title-contain {
      .title {
        padding-bottom: 12px;
        font-weight: bold;
      }

      .evaluation {
        font-size: 12px;
        color: #999;
        cursor: pointer;

        .number {
          color: $primary;
        }

        .icon-arrow-right {
          width: 8px;
          height: 8px;
          padding-left: 8px;
        }
      }

      @include media-sm-and-up {
        .title {
          font-size: 22px;
        }

        .evaluation {
          font-size: 15px;
        }
      }
    }


    .introduction {
      .content-wrapper {
        width: 100%;
        max-width: 1200PX;
        padding: 0 20px;
        margin: 0 auto;
        font-size: 13px;
        color: #666;
      }

      .map {
        width: 100%;
        height: 200px;
        padding-top: 30px;
        overflow: hidden;
      }

      @include media-sm-and-up {
        display: flex;
        width: 100%;
        max-width: 1200PX;
        padding: 0 20px;
        margin: 0 auto;

        .content-wrapper {
          padding: 0;
        }

        .map {
          width: 900px;
          height: 230px;
          padding: 0 0 0 56px;
        }
      }
    }
  }

  .component-title-wrap {
    margin: 30px 0 33px;

    @include media-sm-and-up {
      margin-top: 46px;
    }
  }
</style>
