<template>
  <div class="skeleton" v-if="loading">
    <el-progress :percentage="percentage" :show-text="false"/>
    <div class="container">
      <el-row :gutter="8" justify="space-between" style="margin-bottom: 1.66667rem;">
        <el-col :xs="12" :md="8">
          <div class="select-skeleton"></div>
        </el-col>
        <el-col :xs="12" :md="8">
          <div class="select-skeleton"></div>
        </el-col>
      </el-row>

      <el-row :gutter="8" justify="space-between">
        <el-col :xs="24" :sm="12" :md="8" v-for="item in 12" :key="item">
          <div class="live-card-wrapper prod-skeleton-animate">
            <div class="cover-wrapper">
              <auto-height
                class="prod-skeleton-block"
                height="50%" :oss-img="{ resize: { w: 1000 } }"
              />
              <div class="live-info">
                <div class="prod-skeleton-block">1</div>
                <div class="prod-skeleton-block">1</div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
  import { Component, Vue } from 'vue-property-decorator';
  import Skeleton from '@/components/skeleton/mixins';

  @Component({ mixins: [Skeleton] })
  export default class IndexSkeleton extends Vue {
  }
</script>

<style lang="scss" scoped>
  .container {
    padding-top: 15px;
  }

  .select-skeleton {
    height: 38px;
    background: #f2f2f2;
  }

  .live-card-wrapper {
    margin-bottom: 15px;
    border-radius: 2px;
    overflow: hidden;
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.09);

    /deep/ .prod-skeleton-block {
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }

    .live-info {
      width: 100%;
      padding: 0 15px 15px;
      line-height: 1.2;
    }
  }
</style>
