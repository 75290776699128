<template>
  <div class="heart-tap" @click="handleTap">
    <div class="box-animation" :style="{ animationDelay: (item - 1) * 30 + 'ms' }" v-for="item in tapTimes" :key="item">
      <img src="@/assets/icon-heart.png" class="icon">
      <span>x{{ item }}</span>
    </div>
    <img src="@/assets/icon-heart.png" class="icon">
  </div>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class HeartTap extends Vue {
  tapTimes = 0;
  timer = 0;

  handleTap() {
    if (this.$authStore.isLogin) {
      this.tapTimes++;
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.$emit('change', this.tapTimes);
        this.tapTimes = 0;
      }, 1000);
    } else {
      return;
    }
  }

}
</script>

<style lang="scss" scoped>
  @keyframes fadeUp {
    0% {
      opacity: .7;
      transform: translate3d(0, 0, 0);
    }

    100% {
      opacity: 0;
      transform: translate3d(0, -25px, 0);
    }
  }

  .heart-tap {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 40px;
    height: 100%;
    border: 1px solid #f5f5f5;
    background-color: #fff;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    .icon {
      width: 26px;
    }
  }

  .box-animation {
    position: absolute;
    top: -20px;
    left: 0;
    display: flex;
    align-items: center;
    font-size: 14px;
    opacity: 0;
    animation-name: fadeUp;
    animation-duration: 300ms;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;

    .icon {
      margin-right: 3px;
    }
  }
</style>
