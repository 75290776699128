<template>
  <div class="skeleton" v-if="loading">
    <el-progress :percentage="percentage" :show-text="false"/>
    <div class="container">
      <el-row v-if="$auctionHouseStore.domainHouse" :gutter="8" justify="space-between" style="margin-bottom: 1.66667rem;">
        <el-col :xs="12" :md="8">
          <div class="select-skeleton"></div>
        </el-col>
        <el-col :xs="12" :md="8">
          <div class="select-skeleton"></div>
        </el-col>
      </el-row>

      <el-row :gutter="8" justify="space-between">
        <el-col :xs="24" :sm="12" :md="8" v-for="item in 12" :key="item">
          <skeleton-live-card />
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
  import { Component, Vue } from 'vue-property-decorator';
  import Skeleton from '@/components/skeleton/mixins';

  @Component({ mixins: [Skeleton] })
  export default class IndexSkeleton extends Vue {
  }
</script>

<style lang="scss" scoped>
  .container {
    padding-top: 15px;
  }

  .select-skeleton {
    height: 38px;
    background: #f2f2f2;
  }
</style>
