import SparkMD5 from 'spark-md5';
import { fly } from './fly';
import { randomFileName } from './random';

const fileSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice;

class FileHexDigest {
  static create(file, callback) {
    const instance = new FileHexDigest(file);
    instance.create(callback);
  }

  constructor(file) {
    this.file = file;
    this.chunkSize = 2097152; // 2MB
    this.chunkCount = Math.ceil(this.file.size / this.chunkSize);
    this.chunkIndex = 0;
  }

  create(callback) {
    this.callback = callback;
    this.md5Buffer = new SparkMD5.ArrayBuffer;
    this.fileReader = new FileReader;
    this.fileReader.addEventListener('load', event => this.fileReaderDidLoad(event));
    this.fileReader.addEventListener('error', event => this.fileReaderDidError(event));
    this.readNextChunk();
  }

  fileReaderDidLoad(event) {
    this.md5Buffer.append(event.target.result);

    if (!this.readNextChunk()) {
      const digest = this.md5Buffer.end();
      this.callback(null, digest);
    }
  }

  fileReaderDidError() {
    this.callback(`Error reading ${this.file.name}`);
  }

  readNextChunk() {
    if (this.chunkIndex < this.chunkCount || (!this.chunkIndex && !this.chunkCount)) {
      const start = this.chunkIndex * this.chunkSize;
      const end = Math.min(start + this.chunkSize, this.file.size);
      const bytes = fileSlice.call(this.file, start, end);
      this.fileReader.readAsArrayBuffer(bytes);
      this.chunkIndex++;
      return true;
    } else {
      return false;
    }
  }
}

function createHexDigest(file) {
  return new Promise((resolve, reject) => {
    FileHexDigest.create(file, (error, hexDigest) => {
      if (error) {
        return reject(error);
      }
      resolve(hexDigest);
    });
  });
}

export async function uploadFile(file) {
  const body = {
    filename: file.name || randomFileName(),
    content_type: file.type || 'application/octet-stream',
    byte_size: file.size,
    hex_digest: await createHexDigest(file)
  };

  const { data } = await fly.post('active_storage/direct_upload', body);
  await fly.put(data.direct_upload.url, file.slice(), { headers: data.direct_upload.headers, timeout: 1 * 60 * 60 * 1000 });

  return {
    url: data.direct_upload.url,
    signed_id: data.signed_id
  };
}
