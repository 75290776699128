<i18n src="./locales.json"></i18n>

<template>
  <div class="page-product-categories">
    <v-title :value="$t('productCategories')" />
    <div class="container">
      <div class="product-categories" v-for="category in store.data" :key="category.id">
        <common-title v-if="category.children.length" class="title" :title="category.title" />
        <div class="category-list">
          <product-category-item v-for="item in category.children" :key="item.id" :category="item" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';
  import { Collection } from '@/store';

  @Component
  export default class ProductCategories extends Vue {
    store = new Collection({
      fetch: params => this.$fly.get('product_categories', { ...params, per_page: 99, parent_id_null: true })
    })

    @Vue.autoLoading
    created() {
      return this.store.fetchData();
    }
  }
</script>

<style lang="scss" scoped>
  .page-product-categories {
    margin-bottom: 15px;

    @include media-sm-and-up {
      margin-bottom: 40px;
    }

    .title {
      margin: 40px 0px 30px -20px;
    }

      .category-list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 8px;

        @include media-sm-and-up {
          grid-template-columns: repeat(6, 1fr);
        }
      }
    }
</style>
