import _ from 'lodash';

export function setTitle(title) {
  document.title = title;
  // ios系统，电脑
  const special = /(iPhone|iPad|Mac|Windows)/i.test(_.get(window.navigator, 'userAgent', 'node'));
  if (special) {
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.setAttribute('src', 'about:blank');
    const onLoad = () => {
      setTimeout(() => {
        iframe.removeEventListener('load', onLoad);
        document.body.removeChild(iframe);
      }, 0);
    };
    iframe.addEventListener('load', onLoad);
    document.body.appendChild(iframe);
  }
}
