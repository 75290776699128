<i18n src="./locale.json"></i18n>

<template>
    <el-dialog
      :visible="dialogVisible"
      width="50%"
      center
      lock-scroll
      @update:visible="handleClose"
    >
      <div v-if="loading" class="loading-container">
        <i class="el-icon-loading" />
      </div>

      <div v-else class="body-container">
        <!-- STEP1: 选择打印的拍品 -->
        <PrintItemsStep1
          v-if="step === 1"
          :store="store"
          :selectedItems="selectedItems"
          :priceFilterValue="priceFilterValue"
          @priceFilterChange="handlePriceFilter"
          @search="handleSearch"
          @next="handleNext(true)"
        />

        <!-- STEP2: 专家意见 -->
        <PrintItemsStep2
          v-if="step === 2"
          :selectedItems="selectedItems"
          @next="handleNext"
          @prev="handlePrev"
        />

        <!-- STEP3: 预览打印 -->
        <PrintItemsStep3
          v-if="step === 3"
          :selectedItems="selectedItems"
          :auctionName="auction.title"
          :auctionHouseDetail="auctionHouseDetail"
        />

      </div>
    </el-dialog>
</template>

<script>
  import { Vue, Component, Model, Watch, Prop } from 'vue-property-decorator';
  import { AuctionItems, authStore } from '@/store';
  import _ from 'lodash';
  import PrintItemsStep1 from './print-items-step1.vue';
  import PrintItemsStep2 from './print-items-step2.vue';
  import PrintItemsStep3 from './print-items-step3.vue';
  import { fly } from '@/utils';

  @Component({
    components: {
      PrintItemsStep1,
      PrintItemsStep2,
      PrintItemsStep3
    }
  })
  export default class PrintItemsPopup extends Vue {
    @Model('update:show', { type: Boolean }) show
    @Prop({ type: Object, default: () => ({}) }) auction

    loading = false
    dialogVisible = false
    priceFilterValue = ''
    keyword = ''
    store = { data: [] };
    selectedItems = [];
    step = 1;
    auctionHouseDetail = {
      pdfFooterLogo: '',
      pdfEmailDomain: []
    };

    authStore = authStore;

    @Watch('show', { immediate: true })
    async showChange(val, oldVal) {
      this.dialogVisible = val;
      if (!val || val === oldVal) {
        return;
      }
      const [{ data }] = await Promise.all([
        fly.get(`auction_houses/${this.auction.auction_house_id}`),
        this.fetchData({ showLoading: true }),
      ]);
      Object.assign(this.auctionHouseDetail, {
        pdfFooterLogo: this.$get(data, 'pdf_footer_logo.url') || '',
        pdfEmailDomain: data.pdf_email_domains || []
      });
    }

    handleClose(val) {
      // 清空数据
      this.priceFilterValue = '';
      this.keyword = '';
      this.selectedItems.length = 0;
      this.step = 1;
      this.$emit('update:show', val);
    }

    async fetchData({ showLoading = false } = {}) {
      this.store = AuctionItems.findOrCreate();
      if (showLoading) {
        this.loading = true;
      }
      const priceQuery = {
          '1': {
            starting_price_gteq: undefined,
            starting_price_lteq: 10 * 10000
          },
          '2': {
            starting_price_gteq: 10 * 10000,
            starting_price_lteq: 50 * 10000
          },
          '3': {
            starting_price_gteq: 50 * 10000,
            starting_price_lteq: undefined
          }
        }[String(this.priceFilterValue)] || {};
      try {
        _.set(this.store, 'params', {
          entity: 'AuctionItem',
          auction_category_auction_id_eq: this.auction.id,
          keyword: this.keyword || undefined,
          ...priceQuery
        });
        if (showLoading) {
          await this.store.fetchData();
        } else {
          await this.$autoLoading(this.store.fetchData());
        }
      } finally {
        this.loading = false;
      }
    }

    handlePriceFilter(val) {
      this.priceFilterValue = val;
      this.fetchData();
    }

    handleSearch(val) {
      this.keyword = val;
      this.fetchData();
    }

    handleNext(showAlert) {
      if (!this.selectedItems.length && showAlert) {
        this.$message.error(this.$t('selectedEmpty'));
        return;
      }
      // 只有当前用户验证过的邮件是这些邮箱域名的的时候step+1，否则step+2
      const emailDomain = this.auctionHouseDetail.pdfEmailDomain;
      const { email, email_is_valid } = this.authStore.user;
      const isVerified = email_is_valid && emailDomain.some(domain => email.endsWith(domain));
      if (isVerified) {
        this.step += 1;
      } else {
        this.step += 2;
      }
    }

    handlePrev() {
      this.step -= 1;
    }
  }
</script>

<style lang="scss" scoped>
.loading-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 20px 0;
  color: #999;

  .el-icon-loading {
    font-size: 30px;
  }
}
</style>
