<i18n src="./locales.json"></i18n>

<template>
  <div>
    <v-title :value="houseDetail.name"/>
    <div class="detail">
      <div class="house-wrap flex item-center content-center">
        <img class="house-img" :src="$get(houseDetail, 'logo.url')">
        <div class="title-contain">
          <div class="name">{{ houseDetail.name }}</div>
          <router-link v-if="houseDetail.can_manage_store" :to="{ name: 'evaluations', query: { auction_house_id: houseDetail.id }}">
            <div class="evaluation flex item-center content-center">
              <span>{{ $t('evaluation') }}</span>
              <span class="number">{{ $get(houseDetail, 'evaluations_count') }}</span>
              <img class="icon-arrow-right" src="@/assets/icon-arrow-right-black.png" />
            </div>
          </router-link>
        </div>
      </div>
      <div class="container">
        <common-title class="common-title-wrap" :title="$t('companyDetail')"/>
      </div>
      <div class="introduction">
        <div class="content-wrapper" v-html="houseDetail.intro"></div>
        <vue-baidu-map v-if="$get(houseDetail, 'geographic_coordinates')" class="map" :ak="baiduMapAk" :center="center" :zoom="15"/>
      </div>
    </div>
    <div class="container">
      <div v-if="houseDetail.can_manage_auction">
        <common-title v-if="$get(houseDetail, 'auctions.length')" class="common-title-wrap" :title="$t('auctionSessions')"/>
        <div class="scroll-wrapper">
          <div class="scroll-content">
            <router-link
              class="item"
              v-for="item in houseDetail.auctions"
              :key="item.id"
              :to="{ name: 'auctionDetail', params: { id: item.id } }"
            >
              <auction-card :auction="item"/>
            </router-link>
          </div>
        </div>
      </div>

      <!-- 限时拍拍品推荐 -->
      <div v-if="showProducts" class="product-list">
        <div class="flex item-center content-between">
          <div class="common-title-wrap">
            <common-title :title="$t('productList')"/>
          </div>
          <div v-if="$get(houseDetail,'products_count') > 6">
            <router-link
              :to="{ name: 'products', query: { id: houseDetail.id , type: houseDetail.tn, in_progress: true }}"
              class="more flex"
            >
              {{ $t('more') }}
            </router-link>
          </div>
        </div>
        <el-row :gutter="8" justify="space-between">
          <el-col :xs="8" :sm="6" :md="4" v-for="product in houseDetail.products" :key="product.id">
            <product-item class="component-product-item" :product="product" :productQuery="productQuery"/>
          </el-col>
        </el-row>
      </div>

      <!-- 即将结束拍品列表 -->
      <div v-if="willEndProducts.data.length && houseDetail.can_manage_store" class="product-list">
        <div class="flex item-center content-between">
          <div class="common-title-wrap">
            <common-title :title="$t('willEndproductList')"/>
          </div>
          <div v-if="willEndProducts.meta.total > 6">
            <router-link
              :to="{ name: 'products', query: { id: houseDetail.id , type: houseDetail.tn, will_end: true }}"
              class="more flex"
            >
              {{ $t('more') }}
            </router-link>
          </div>
        </div>
        <el-row :gutter="8" justify="space-between">
          <el-col :xs="8" :sm="6" :md="4" v-for="product in willEndProducts.data" :key="product.id">
            <product-item class="component-product-item" :product="product" :productQuery="{ ...productQuery, will_end: true }"/>
          </el-col>
        </el-row>
      </div>

      <!-- 已结束的拍品列表 -->
      <div v-if="endProducts.data.length && houseDetail.can_manage_store" class="product-list">
        <div class="flex item-center content-between">
          <div class="common-title-wrap">
            <common-title :title="$t('endedProductList')"/>
          </div>
          <div v-if="endProducts.meta.total > 6">
            <router-link
              :to="{ name: 'products', query: { id: houseDetail.id , type: houseDetail.tn, ended: true }}"
              class="more flex"
            >
              {{ $t('more') }}
            </router-link>
          </div>
        </div>
        <el-row :gutter="8" justify="space-between">
          <el-col :xs="8" :sm="6" :md="4" v-for="product in endProducts.data" :key="product.id">
            <product-item class="component-product-item" :product="product" :productQuery="{ ...productQuery, ended: true }"/>
          </el-col>
        </el-row>
      </div>

      <div v-if="storeCategories.data.length && houseDetail.can_manage_store" class="product-list">
        <div class="flex item-center content-between">
          <div class="common-title-wrap">
            <common-title :title="$t('categoryList')"/>
          </div>
        </div>
        <el-row :gutter="8" justify="space-between">
          <el-col :xs="24" :sm="12" :md="8" v-for="category in  storeCategories.data" :key="category.id">
            <store-category-item :category="category" />
          </el-col>
        </el-row>
        <pagination style="padding-top: 1.66667rem;" :store="storeCategories" />
      </div>

    </div>
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';
  import { followProductIdsStore, Collection, auctionNumbersStore } from '@/store';
  import VueBaiduMap from 'vue-baidu-map/components/map/Map.vue';
  import { initSubscription, updateProduct } from '@/utils';

  @Component({
    components: {
      'vue-baidu-map': VueBaiduMap,
    }
  })
  export default class auctionHouse extends Vue {
    houseDetail = {};
    baiduMapAk = '';
    productQuery = { ...this.$route.query, productType: 'product', auction_house_id_eq: this.$route.params.id };
    center = { lng: 0, lat: 0 };
    storeCategories = new Collection({
      fetch: params => this.$fly.get('store_categories', { ...params, per_page: 6 })
    })

    // 即将结束拍品列表
    willEndProducts = new Collection({
      fetch: params => this.$fly.get('products', { ...params, will_end: true, per_page: 6 })
    })

    // 已结束拍品列表
    endProducts = new Collection({
      fetch: params => this.$fly.get('products', { ...params, ended: true, per_page: 6 })
    })

    received = function (that, result) {
      if (that.$get(that.houseDetail, 'products', []).length > 0) {
        updateProduct(that.houseDetail.products, result);
      }
      if (that.willEndProducts.data.length > 0) {
        updateProduct(that.willEndProducts.data, result);
      }
    }

    get showProducts() {
      return this.$get(this.houseDetail, 'can_manage_store') && this.$get(this.houseDetail, 'products_count') > 0;
    }

    @Vue.autoLoading
    async created() {
      this.baiduMapAk = process.env.VUE_APP_BAIDU_MAP_AK;
      const { id } = this.$route.params;
      const [{ data: houseDetail }] = await Promise.all([
        this.$fly.get(`auction_houses/${id}`, { auctions_count: 99 }),
        this.willEndProducts.fetchData({ auction_house_id_eq: id }),
        this.endProducts.fetchData({ auction_house_id_eq: id }),
        this.storeCategories.tryFetchData({ auction_house_id_eq: id })
      ]);
      // 使用了product-item组件的页面都需要请求
      await followProductIdsStore.tryFetchData();
      await auctionNumbersStore.tryFetchData(),
      this.houseDetail = houseDetail;
      if (houseDetail.geographic_coordinates) {
        const geoGraphic = houseDetail.geographic_coordinates.split(',');
        this.center.lng = geoGraphic[0];
        this.center.lat = geoGraphic[1];
      }
      if (this.showProducts) {
        initSubscription(this, [], this.received);
      }
    }

    beforeDestroy() {
      if (this.consumer) {
        this.consumer.disconnect();
      }
    }

    handleNavDetail(id) {
      this.$router.push({ path: `/auctions/${id}` });
    }
  }
</script>

<style lang="scss" scoped>
  .detail {
    margin: 39px 0 45px;

    @include media-xs-only {
      margin: 25px 0 15px;
    }

    .house-wrap {
      padding-bottom: 25px;

      @include media-xs-only {
        padding-bottom: 10px;
      }

      .house-img {
        width: 75px;
        height: 75px;
        margin-right: 23px;
        border-radius: 50%;
        box-shadow: 0px 4px 18px 2px rgba(4, 0, 0, 0.1);
        object-fit: cover;

        @include media-sm-and-up {
          width: 94px;
          height: 94px;
          margin-right: 36px;
        }
      }

      .title-contain {
        .name {
          padding-bottom: 12px;
          font-weight: bold;
        }

        .evaluation {
          font-size: 12px;
          color: #999;
          cursor: pointer;

          .number {
            color: $primary;
          }

          .icon-arrow-right {
            width: 8px;
            height: 8px;
            padding-left: 8px;
          }
        }

        @include media-sm-and-up {
          .name {
            font-size: 22px;
          }

          .evaluation {
            font-size: 15px;
          }
        }
      }
    }

    .introduction {
      .content-wrapper {
        width: 100%;
        max-width: 1200PX;
        padding: 0 20px;
        margin: 0 auto;
        font-size: 13px;
        color: #666;
      }

      .map {
        width: 100%;
        height: 160px;
        padding-top: 30px;
        overflow: hidden;
      }

      @include media-sm-and-up {
        display: flex;
        width: 100%;
        max-width: 1200PX;
        padding: 0 20px;
        margin: 0 auto;

        .content-wrapper {
          padding: 0;
        }

        .map {
          width: 900px;
          height: 230px;
          padding: 0 0 0 56px;
        }
      }
    }
  }

  .common-title-wrap {
    margin: 10px 0 23px;
  }

  .container {
    padding-bottom: 5px;

    @include media-sm-and-up {
      padding-bottom: 30px;
    }
  }

  .scroll-wrapper {
    margin: 10px -20px 0;
    overflow: auto;

    @include media-sm-and-up {
      margin: 30px 0;
    }

    .scroll-content {
      display: inline-flex;
      padding: 0 15px 15px;

      @include media-lg-and-up {
        padding: 0 3px 15px;
      }

      .item {
        margin: 0 5px;
        cursor: pointer;

        .auction-card-wrapper {
          min-height: 100%;
        }
      }
    }
  }

  .product-list {
    padding-bottom: 15px;

    @include media-sm-and-up {
      padding-bottom: 20px;
    }
  }

  .more {
    margin-top: -10px;
    color: #666;
  }

  .component-product-item {
    margin-bottom: 10px;
    cursor: pointer;
  }
</style>
