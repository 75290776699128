<i18n src="./locales.json"></i18n>

<template>
  <div>
    <div v-if="!this.$auctionHouseStore.domainHouse">
      <v-title :value="$t('homeName')"/>
      <skeleton :onFetch="onFetch" v-model="loading"/>
      <template v-if="!loading">
        <common-banner
          :images="banners"
          :oss-img="$root.windowWidth >= 768 ? {
          process: 'image/quality,q_90/format,jpg/interlace,1'
        } : {
          resize: { w: 750 }
        }"
        >
          <template v-slot="{ item }"><banner-content :item="item"/></template>
        </common-banner>
        <div class="home-wrap">
          <custom-page slug="home" :components="$get(page, 'config.components')" />
        </div>
      </template>
    </div>
    <div v-else>
      <auctionHouseHome />
    </div>
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';
  import { getCustomPage } from '@/utils';
  import { auctionNumbersStore } from '@/store';
  import Skeleton from './components/index-skeleton';
  import auctionHouseHome from '@/views/auction-house-home';

  @Component({ components: { Skeleton, auctionHouseHome } })
  export default class PageHome extends Vue {
    banners = [];
    page = {};
    loading = true
    slug = 'home'

    async onFetch() {
      const [{ data: banners }, { data: page }] = await Promise.all([
        this.$fly.get('banners', { location_eq: 'homepage', channel_eq: 'web' }),
        getCustomPage(this.slug),
        auctionNumbersStore.tryFetchData()
      ]);
      this.banners = banners;
      this.page = page;
    }

    async mounted() {
      const { devise_success, devise_errors } = this.$route.query;
      if (devise_success) {
        await this.$message({ type: 'success', message: devise_success });
        this.$router.replace('/');
      } else if (devise_errors) {
        await this.$message({ type: 'error', message: devise_errors });
        this.$router.replace('/');
      }
    }
  }
</script>

<style lang="scss" scoped>
  .home-wrap {
    padding-bottom: 40px;

    @include media-xs-only {
      padding-bottom: 15px;
    }
  }
</style>
