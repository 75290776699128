import { SimpleStore } from './simple-store';
import { fly } from '@/utils';

export class PagesStore extends SimpleStore {
  data = {}

  async fetchData() {
    const { data } = await this.fetching(fly.get('pages', { slug_in: ['tokushoho', 'antique', 'corporate', 'website_statement', 'privacy_protection'], order: { 'id': 'asc' } }));
    this.data = data;
  }
}

export const pagesStore = new PagesStore();
