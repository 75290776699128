<template>
  <div class="component-banner-wrapper">
    <swiper
      :options="swiperOptions"
      @slideChange="$emit('change')"
    >
      <swiper-slide v-for="(item, index) in images" :key="index">
        <div class="content" @click="$emit('click-item', item)">
          <component
            :is="ossImg ? 'oss-img' : 'img'"
            v-bind="ossImg"
            :src="item.url || $get(item, 'image.url')"
            class="cover"
            @click="$previewImage(images, index)"
          />
          <slot :item="item" :index="index"/>
        </div>
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
  </div>
</template>

<script>
  import { Vue, Component, Prop } from 'vue-property-decorator';

  @Component
  export default class Banner extends Vue {
    @Prop({ type: Array, default: () => [] }) images;
    @Prop(Object) ossImg;

    swiperOptions = {
      autoplay: {
        delay: 3000,
      },
      pagination: {
        el: '.swiper-pagination'
      },
    }
  }
</script>

<style lang="scss" scoped>
  .component-banner-wrapper {
    position: relative;
    max-width: 1200PX;
    padding: 0 20px;
    margin: 1px auto 0;

    @include media-xs-only {
      max-width: 100%;
      padding: 0;
      margin: 0;
    }

    .content {
      position: relative;
      height: 0;
      padding-bottom: 50%;

      .cover {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    /deep/ .swiper-container {
      height: 100%;

      .swiper-pagination {
        bottom: 9px;

        .swiper-pagination-bullet {
          width: 5PX;
          height: 5PX;
          margin: 0 5PX;
          border: 1px solid #fff;
          vertical-align: bottom;
          background: transparent;
          opacity: 1;

          &.swiper-pagination-bullet-active {
            background: #fff;
          }
        }
      }
    }
  }
</style>
