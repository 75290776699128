<i18n>
{
  "zh_cn": {
    "auctionInProgress": "拍卖中",
    "auctionIsOver": "已结束",
    "auctionIsToStart": "待开始",
    "NotReachedReservePrice": "未达底价",
    "ReservePriceReached": "已达底价",
    "NoBottomPrice": "无底价"
  },
  "zh_hk": {
    "auctionInProgress": "拍賣中",
    "auctionIsOver": "已結束",
    "auctionIsToStart": "待開始",
    "NotReachedReservePrice": "未達底價",
    "ReservePriceReached": "已達底價",
    "NoBottomPrice": "無底價"
  },
  "en": {
    "auctionInProgress": "In Progress",
    "auctionIsOver": "Ended",
    "auctionIsToStart": "To start",
    "NotReachedReservePrice": "Reserve not met",
    "ReservePriceReached": "Reserve met",
    "NoBottomPrice": "No reserve"
  },
  "ja": {
    "auctionInProgress": "競売中",
    "auctionIsOver": "終了",
    "auctionIsToStart": "開始前",
    "NotReachedReservePrice": "予約価格に達していません",
    "ReservePriceReached": "予約価格に達しました",
    "NoBottomPrice": "成り行き"
  }
}
</i18n>

<template>
  <div :class="['component-product-item', { horizontal }]" @click="handleNavDetail">
    <div class="inner">
      <div class="cover-wrapper">
        <auto-height
          :height="autoHeight"
          :cover="$get(product, 'cover.url')"
          :oss-img="{ resize: { w: 1000 } }"
          coverFit="contain"
        >
          <div class="cover-content">
            <div class="status-collecte-box flex item-center content-between">
              <div :class="['status', { disabled: isFinished }]">{{ statusText }}</div>
              <img class="icon-collect" :src="collectIconPath" @click.stop="handleCollect" />
            </div>
          </div>
        </auto-height>
      </div>
      <div class="product-info flex column content-between">
        <div>
          <div class="name text-overflow-2">{{ product.title }}</div>
          <div class="tag"><div class="text-overflow">{{ product.product_category.title }}</div></div><br>
          <div v-if="isShowBottomPrice" class="low-price-box" :class="bottomPrice.className">
            <div class="text-overflow">{{ bottomPrice.label }}</div>
          </div>
        </div>
        <div>
          <product-countdown v-if="!isFinished" :data="product"/>
          <div class="footer flex item-center content-between">
            <div v-if="aborted">
              {{ $t('aborted') }}
            </div>
            <div v-else class="price flex item-center">
              <div class="currency text-uppercase">{{ product.currency }}</div>
              <div v-if="isFinished" class="amount">
                {{ (product.deal_price || product.current_price || product.confirmed_price || product.started_price) | currencyFormat }}
              </div>
              <div v-else class="amount">
                {{ (product.current_price || product.started_price || product.confirmed_price) | currencyFormat }}
              </div>
            </div>
            <div class="sums" v-if="!aborted">{{ product.product_bidding_prices_count }}bids</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { Vue, Component, Prop } from 'vue-property-decorator';
  import { followProductIdsStore } from '@/store';
  import dayjs from 'dayjs';
  import iconCollect from '@/assets/icon-collect-red.png';
  import iconCollected from '@/assets/icon-collected-red.png';

  @Component
  export default class ProductItem extends Vue {
    @Prop({ type: String, default: '100%' }) autoHeight;
    @Prop({ type: Object, default: () => ({}) }) product;
    @Prop({ type: Object, default: () => ({}) }) productQuery; // 从某个商品列表传过来的该列表的请求参数
    @Prop({ type: Boolean, default: false }) horizontal;

    dayjs = dayjs

    get statusText() {
      if (this.isFinished) {
        return this.$t('auctionIsOver');
      } else if (this.isToStart) {
        return this.$t('auctionIsToStart');
      } else {
        return this.$t('auctionInProgress');
      }
    }

    get isFinished() {
      return this.product.bid_status !== 'pending' || this.product.deal_price || dayjs(this.product.ended_bidding_time).diff(dayjs()) < 0;
    }

    get collectIconPath() {
      return this.product.is_follow ? iconCollected : iconCollect;
    }

    get aborted() {
      return this.product.bid_status === 'aborted';
    }

    get isToStart() {
      return this.product.bid_status === 'pending' && this.product.started_bidding_time &&
        dayjs(this.product.started_bidding_time).diff(dayjs()) > 0;
    }

    get isShowBottomPrice() {
      const { bottom_price, confirmed_price, current_price, started_price, ended_bidding_time } = this.product;
      if (bottom_price === 0) { return true; }

      // 在后台有设置底价 && 未到结束时间 && 不是一口价
      const isNotEnded = ended_bidding_time ? dayjs().isBefore(ended_bidding_time) : true;
      return bottom_price && (!confirmed_price || current_price || started_price) && isNotEnded;
    }

    get bottomPrice() {
      const { bottom_price, current_price, started_price } = this.product;
      if (bottom_price === 0) { return { label: this.$t('NoBottomPrice'), className: 'no-bottom-price' }; }

      if (bottom_price <= (current_price || started_price)) {
        return { label: this.$t('ReservePriceReached'), className: 'reserve-price-reached' };
      }
      return { label: this.$t('NotReachedReservePrice'), className: 'not-reached-reserve-price' };
    }

    created() {
      this.$set(this.product, 'is_follow', followProductIdsStore.data.includes(this.product.id));
    }

    handleNavDetail() {
      this.$router.push({ path: `/products/${this.product.id}`, query: { ...this.productQuery } });
    }

    async handleCollect() {
      const { is_follow } = this.product;
      const method = is_follow ? 'delete' : 'post';
      await this.$autoLoading(this.auctionsAction(method, 'follow'));
      this.product.is_follow = !is_follow;
      // 更新已关注商品的ids
      if (this.product.is_follow) {
        followProductIdsStore.data.push(this.product.id);
      } else {
        const index = followProductIdsStore.data.indexOf(this.product.id);
        index > -1 && followProductIdsStore.data.splice(index, 1);
      }
      this.$message(
        {
          type: 'success',
          message: is_follow ? this.$t('unfollowSuccessfully') : this.$t('followSucceeded')
        }
      );
    }

    auctionsAction(methods, action_type) {
      return this.$fly[methods]('action_stores', {
        action_type: action_type,
        target_type: 'Product',
        target_id: this.product.id
      });
    }
  }
</script>

<style lang="scss" scoped>
  .component-product-item {
    position: relative;
    height: 0;
    padding-bottom: 200%;
    border-radius: 2px;
    background: #fff;
    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.09);
    cursor: pointer;
    user-select: none;

    @include media-xs-only {
      padding-bottom: 220%;
    }

    .inner {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;

      .cover-wrapper {
        position: relative;
      }

      .cover-content {
        .status-collecte-box {
          position: absolute;
          top: 0;
          width: 100%;
          padding: 8px 10px;

          @include media-xs-only {
            padding: 4px 6px;
          }
        }

        .status {
          box-sizing: border-box;
          min-width: 55px;
          padding: 2px 8px;
          border-radius: 2px;
          font-size: 10px;
          text-align: center;
          color: #fff;
          background: $primary;

          @include media-xs-only {
            padding: 2px 4px;
            font-size: 8px;
          }

          &.disabled {
            background: #999;
          }
        }

        .icon-collect {
          width: 18px;
          height: 18px;
        }
      }

      .product-info {
        flex: 1;
        padding: 10px 16px;

        @include media-xs-only {
          padding: 4px 4px 6px;
        }

        .name {
          font-weight: 500;

          @include media-xs-only {
            font-weight: unset;
            font-size: 12px;
          }
        }

        .tag {
          display: inline-block;
          padding: 1px 4px;
          margin: 5px 0 -1px;
          border: 1px solid $primary;
          border-radius: 2px;
          font-size: 9px;
          color: $primary;

          @include media-xs-only {
            padding: 0 4px;
            margin: 4px 0 -2px;
            font-size: 8px;
          }
        }

        .low-price-box {
          display: inline-block;
          margin-top: 2px;
          font-size: 12px;
          color: #666;

          &.no-bottom-price {
            padding: 2px 3px;
            border-radius: 5px;
            color: #fff;
            background: #2e815e;
          }
        }

        .footer {
          margin-top: 2px;

          .price {
            font-weight: 500;

            @include media-xs-only {
              font-weight: unset;
              font-size: 12px;
            }

            .amount {
              color: $primary;
            }
          }

          .sums {
            font-size: 11px;
            color: #666;

            @include media-xs-only {
              font-size: 8px;
            }
          }
        }
      }
    }

    &.horizontal {
      flex-direction: row;
      padding-bottom: 39.9%;

      .inner {
        flex-direction: row;

        .cover-wrapper {
          width: 40%;
        }

        .product-info {
          padding: 14px 27px 13px 18px;
        }
      }
    }
  }
</style>
