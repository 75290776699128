import { SimpleStore } from './simple-store';
import { fly, setMeta } from '@/utils';
import _ from 'lodash';

export class SettingsStore extends SimpleStore {
  settings = {}

  async fetchData() {
    const { data } = await this.fetching(fly.get('settings'));
    _.map(data, (val, key) => {
      const reg = /(.*)(_to_)(.*)(_exchange_rate)/ig;
      const target = key.replace(reg, '$3$2$1$4');
      if (reg.test(key) && !data[target]) {
        data[target] = 1 / val;
      }
    });
    this.settings = data;
    // 插入description meta
    setMeta('description', data.seo_meta_content);
    setMeta('og:description', data.seo_meta_content, 'property');
    return Promise.resolve(data);
  }
}

export const settingsStore = new SettingsStore();
