<i18n src="./locales.json"></i18n>

<template>
  <div class="flex">
    <common-sidebar class="hidden-xs-only" />
    <div class="container">
      <v-title :value="$t('myBidding')"/>
      <div class="title">{{ $t('myBidding') }}</div>
      <el-tabs class="tabs-wrapper" v-model="tab" @tab-click="handleTypeClick">
        <!-- 限时拍 -->
        <el-tab-pane name="normal" v-if="showNormal" :label="$t('normalItem')">
          <el-tabs class="tab sub-tab" v-model="proName" @tab-click="handleProClick">
            <el-tab-pane
              v-for="(list, key) in productsTabs"
              :key="key"
              :label="$t(key)"
              :name="key"
            >
              <div class="list-wrapper" v-if="list.store.isFulfilled">
                <el-row :gutter="20" justify="space-between">
                  <el-col
                    :xs="24"
                    :sm="12"
                    v-for="item in list.store.data"
                    :key="item.id"
                  >
                    <target-type-card
                      :targetType="item.cn"
                      :title="item.title"
                      :cover="$get(item, 'cover.url')"
                      :categoryName="item.product_category.title"
                      :id="item.id"
                      :product="item"
                      :productQuery="{ productType: 'myProduct', status: key }"
                    >
                      <app-countdown
                        :end-time="$get(item, 'ended_bidding_time')"
                        :key="$get(item, 'ended_bidding_time')"
                        :current-time="currentTime"
                        endHide plusOneMinute
                      >
                        <template v-slot="{ day, hour, minute }">
                          <div class="countdown text-overflow" v-if="day > -1 && !isFinishedTime(item)">
                            {{$t('productTimeLeft')}}
                            <span v-if="day > 0"><span class="primary"> {{ day }} </span>{{ $t('day') }}</span>
                            <span class="primary"> {{ hour }} </span>{{ $tc('hour', hour) }}
                            <span class="primary"> {{ minute }} </span>{{ $tc('minute', minute) }}
                          </div>
                        </template>
                      </app-countdown>
                      <div
                        v-if="isFinishedTime(item)"
                        style="padding-bottom: 0.16667rem;"
                        class="text-overflow">
                        {{ $t('dealTime') }}: {{ $get(item, 'ended_bidding_time') | timeFormat('YYYY-MM-DD') }}
                      </div>
                      <!-- 还未结束 价格显示 -->
                      <div v-if="!item.ended_bidding_time || !isFinishedTime(item)" class="price-wrap flex item-end content-between">
                        <div>
                          <div>
                            {{$t('currentPrice')}}
                            <span class="currency text-uppercase">{{$get(item, 'currency')}} </span>
                            <span class="price">{{ $get(item, 'current_price') | currencyFormat }}</span>
                          </div>
                          <div>
                            {{$t('myPrice')}}
                            <span class="currency text-uppercase">{{$get(item, 'currency')}} </span>
                            <span class="price">{{ $get(item, 'my_bidding_price') | currencyFormat }}</span>
                          </div>
                        </div>
                        <div class="sums">{{ item.product_bidding_prices_count }} bids</div>
                      </div>
                      <!-- 已结束 价格显示-->
                      <div v-else>
                        <div class="text-overflow">
                          <span>{{$t('dealPrice')}}: </span>
                          <span class="currency text-uppercase">{{$get(item, 'currency')}} </span>
                          <span class="price">{{ $get(item, 'deal_price') | currencyFormat }}</span>
                        </div>
                        <div class="text-overflow" v-if="!$options.filters.isLocaleCurrency($get(item, 'currency'))">
                          <span>{{$t('dealPrice')}}: </span>
                          <span class="currency text-uppercase">{{$t('currency')}} </span>
                          <span class="price">{{ $get(item, 'deal_price') | conversionFormat($get(item, 'currency')) | currencyFormat }}</span>
                        </div>
                      </div>
                    </target-type-card>
                  </el-col>
                </el-row>
                <pagination routeMode class="pagination" :store="list.store"/>
              </div>
              <div class="list-empty flex column item-center" v-if="list.store.isEmpty">
                <img src="@/assets/icon-deal-empty.png" class="icon-empty">
                <div>{{ $t('bidEmpty') }}</div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </el-tab-pane>

        <!-- 同步拍 -->
        <el-tab-pane name="auction" v-if="showAuction" :label="$t('auctionItem')">
          <el-tabs class="tab sub-tab" v-model="aucName" @tab-click="handleAucClick">
            <el-tab-pane
              v-for="(list, key) in auctionsTabs"
              :key="key"
              :label="$t(key)"
              :name="key"
            >
              <div class="list-wrapper" v-if="list.store.isFulfilled">
                <el-row :gutter="20" justify="space-between">
                  <el-col
                    :xs="24"
                    :sm="12"
                    v-for="item in list.store.data"
                    :key="item.id"
                  >
                    <target-type-card
                      :targetType="item.cn"
                      :title="item.title"
                      :cover="$get(item, 'cover.url')"
                      :categoryName="$get(item, 'auction_category.title')"
                      :id="item.id"
                      :number="$get(item, 'number')"
                      :product="item"
                    >
                      <app-countdown
                        :end-time="$get(item, 'transaction_time') || $get(item, 'auction_start_at')"
                        :current-time="currentTime" endHide plusOneMinute
                      >
                        <template v-slot="{ day, hour, minute }">
                          <div class="countdown text-overflow">
                            {{$t('timeLeft')}}
                            <span v-if="day > 0"><span class="primary"> {{ day }} </span>{{ $t('day') }}</span>
                            <span class="primary"> {{ hour }} </span>{{ $tc('hour', hour) }}
                            <span class="primary"> {{ minute }} </span>{{ $tc('minute', minute) }}
                          </div>
                        </template>
                      </app-countdown>
                      <div
                        class="text-overflow"
                        v-if="$get(item, 'transaction_time')" style="padding-bottom: 0.16667rem;"
                      >
                        {{ $t('dealTime') }}: {{ $get(item, 'transaction_time') | timeFormat('YYYY-MM-DD') }}
                      </div>
                      <!-- 已结束且显示最终价格 -->
                      <div v-if="item.is_finished && item.show_items_final_price">
                        <div v-if="['hammered', 'force_hammered'].includes(item.status)">
                          <div class="text-overflow">
                            <span>{{$t('dealPrice')}}: </span>
                            <span class="currency text-uppercase">{{$get(item, 'auction_category.currency')}} </span>
                            <span class="price">{{ $get(item, 'final_price') | currencyFormat }}</span>
                          </div>
                          <div class="text-overflow" v-if="!$options.filters.isLocaleCurrency($get(item, 'auction_category.currency'))">
                            <span>{{$t('dealPrice')}}: </span>
                            <span class="currency text-uppercase">{{$t('currency')}} </span>
                            <span class="price">{{ $get(item, 'final_price') | conversionFormat($get(item, 'auction_category.currency')) | currencyFormat }}</span>
                          </div>
                        </div>
                        <div v-else>
                        {{$t('aborted')}}
                        </div>
                      </div>
                      <!-- 未结束或不显示最终价格 -->
                      <div v-else>
                        <div v-if="$get(item, 'starting_price') <= 0">
                          {{ $t('estimatePending') }}
                        </div>
                        <template v-else>
                          <div class="text-overflow">
                            <span>{{$t('startPrice')}}: </span>
                            <span class="currency text-uppercase">{{$get(item, 'auction_category.currency')}} </span>
                            <span class="price">{{ $get(item, 'starting_price') | currencyFormat }}</span>
                          </div>
                          <div class="text-overflow" v-if="!$options.filters.isLocaleCurrency($get(item, 'auction_category.currency'))">
                            <span>{{$t('startPrice')}}: </span>
                            <span class="currency">{{$t('currency')}} </span>
                            <span class="price">{{ $get(item, 'starting_price') | conversionFormat($get(item, 'auction_category.currency')) | currencyFormat }}</span>
                          </div>
                        </template>
                      </div>
                      <div v-if="item.auto_bidding_price" class="text-overflow">
                        <span>{{$t('preBid')}}: </span>
                        <span class="currency text-uppercase">{{$get(item, 'auction_category.currency')}} </span>
                        <span class="price">{{ $get(item, 'auto_bidding_price') | currencyFormat }}</span>
                      </div>
                    </target-type-card>
                  </el-col>
                </el-row>
                <pagination routeMode class="pagination" :store="list.store"/>
              </div>
              <div class="list-empty flex column item-center" v-if="list.store.isEmpty">
                <img src="@/assets/icon-deal-empty.png" class="icon-empty">
                <div>{{ $t('bidEmpty') }}</div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';
  import { Collection } from '@/store';
  import dayjs from 'dayjs';
  import { initSubscription } from '@/utils';
  import _ from 'lodash';

  @Component
  export default class Bidding extends Vue {
    tab = 'normal';
    proName = 'going';
    aucName = 'pending';
    currentTime = null;

    productsTabs = {
      going: { params: { status: 'going' }, store: {} },
      ended: { params: { status: 'ended' }, store: {} },
    }
    auctionsTabs = {
      pending: { params: { status: 'pending' }, store: {} },
      ended: { params: { status: 'ended' }, store: {} },
    }

    received = function (that, result) {
      const { action, data } = result;
      that.productsTabs.going.store.data.forEach(item => {
        if (item.id === data.product_id) {
          switch (action.toLowerCase()) {
            case 'bid':
              if (data.current_price > item.current_price) {
                item.current_price = data.current_price;
              }
              return;
            case 'deal':
              item.deal_price = data.deal_price;
              return;
            case 'abort':
              item.bid_status = data.bid_status;
              return;
            case 'delay':
              if (dayjs(data.ended_bidding_time).diff(item.ended_bidding_time) > 0) {
                item.ended_bidding_time = data.ended_bidding_time;
              }
              return;
          }
        }
      });
    }

    get showNormal() {
      if (this.$auctionHouseStore.domainHouse) {
        return this.$auctionHouseStore.domainHouse.can_manage_store ? true : false;
      } else {
        return true;
      }
    }

    get showAuction() {
      if (this.$auctionHouseStore.domainHouse) {
        return this.$auctionHouseStore.domainHouse.can_manage_auction ? true : false;
      } else {
        return true;
      }
    }

    get currentProductsStore() {
      return this.productsTabs[this.proName].store;
    }

    get currentAuctionsStore() {
      return this.auctionsTabs[this.aucName].store;
    }

    async getServiceCurrentTime() {
      const { current_time } = await this.$fly.get('utils/current_time');
      this.currentTime = current_time;
    }

    @Vue.autoLoading
    async created() {
      const { type, pro_name, auc_name, page } = this.$route.query;
      if (type) {
        this.tab = type;
      } else if (this.$auctionHouseStore.domainHouse) {
          this.tab = this.$auctionHouseStore.domainHouse.can_manage_store ? 'normal' : 'auction';
      }
      pro_name && (this.proName = pro_name);
      auc_name && (this.aucName = auc_name);

      Object.keys(this.productsTabs).forEach(key => {
        const list = this.productsTabs[key];
        list.store = new Collection({
          fetch: params => this.$fly.get('mine/products', { ...list.params, ...params })
        });
        list.store.params = { page };
      });
      Object.keys(this.auctionsTabs).forEach(key => {
        const list = this.auctionsTabs[key];
        list.store = new Collection({
          fetch: params => this.$fly.get('mine/auction_items', { ...list.params, ...params })
        });
        list.store.params = { page };
      });
      await Promise.all([
        this.currentProductsStore.fetchData(),
        this.currentAuctionsStore.fetchData(),
        this.getServiceCurrentTime()
      ]);
      // 有数据初始化ws通道
      if (this.$get(this.productsTabs, 'going.store.data.length') > 0) {
        initSubscription(this, [], this.received);
      }
    }

    async handleTypeClick(val) {
      await this.$nextTick();
      const { type } = this.$route.query;
      if (val.name === type) {
        return;
      }

      this.$router.push({ query: _.merge({}, { type: val.name }) });
    }

    async handleProClick(val) {
      await this.$nextTick();
      const { pro_name } = this.$route.query;
      if (val.name === pro_name) {
        return;
      }

      this.$router.push({ query: _.merge({}, { type: this.tab }, { pro_name: val.name }) });
    }

    async handleAucClick(val) {
      await this.$nextTick();
      const { auc_name } = this.$route.query;
      if (val.name === auc_name) {
        return;
      }

      this.$router.push({ query: _.merge({}, { type: this.tab }, { auc_name: val.name }) });
    }

    isFinishedTime(item) {
      return dayjs(this.$get(item, 'ended_bidding_time')).diff(dayjs(this.currentTime)) < 0;
    }

    beforeDestroy() {
      if (this.consumer) {
        this.consumer.disconnect();
      }
    }
  }
</script>

<style lang="scss" scoped>
  .container {
    .title {
      margin: 28px 0 10px;
      font-weight: bold;
      font-size: 18px;
      color: #000;

      @include media-sm-and-up {
        margin: 33px 0 24px;
      }
    }

    .pagination {
      margin: 15px 0;

      @include media-sm-and-up {
        margin: 40px 0;
      }
    }

    .list-empty {
      padding: 97px 0;
      color: #999;

      .icon-empty {
        width: 65px;
        height: 65px;
        margin-bottom: 34px;
      }
    }

    .tabs-wrapper {
      /deep/ .el-tabs__header {
        margin: 19px 0 26px;

        @include media-xs-only {
          margin: 21px 0 16px;
        }

        .el-tabs__active-bar {
          display: none;
        }

        .el-tabs__nav {
          display: flex;
          width: 100%;
        }

        .el-tabs__nav-wrap:after {
          display: none;
        }

        .el-tabs__item {
          height: 40px;
          padding: 0;
          margin-right: 40px;
          line-height: 40px;
          color: #999;

          &.is-active {
            color: #000;

            &:after {
              position: absolute;
              bottom: 0;
              left: 50%;
              width: 90%;
              height: 2px;
              border-radius: 1px;
              background: #000000;
              transform: translateX(-50%);
              content: '';
            }
          }
        }
      }

      /deep/ .el-tabs__content {
        overflow: visible;
      }

      .sub-tab {
        /deep/ .el-tabs__item {
          width: 100%;
          margin-right: 0;
          text-align: center;

          &.is-active {
            color: var(--color-tag-font);
            background-color: var(--color-tag-bg);

            &:after {
              width: 40%;
            }
          }
        }
      }
    }

    .tab {
      /deep/ .el-tabs__header {
        background: #eee;

        .el-tabs__nav {
          justify-content: space-around;
        }
      }
    }

    .currency {
      font-size: 14px;
      color: #000;

      @include media-xs-only {
        font-size: 11px;
      }
    }

    .price {
      margin-left: 2px;
      font-size: 14px;
      color: $primary;
    }

    .countdown {
      font-size: 11px;
      color: $gray;

      .primary {
        font-size: 16px;
        color: $primary;
      }
    }

    .price-wrap {
      @include media-xs-only {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    .sums {
      font-size: 11px;
      color: $gray;

      @include media-xs-only {
        width: 100%;
        font-size: 10px;
        text-align: right;
      }
    }
  }
</style>
