import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { I18N_STORAGE_KEY } from '@/constants';
import { zh_cn, en, ja, zh_hk } from './locale';
import _ from 'lodash';
import locale from 'element-ui/lib/locale';
import zhLocale from 'element-ui/lib/locale/lang/zh-CN';
import twLocale from 'element-ui/lib/locale/lang/zh-TW';
import enLocale from 'element-ui/lib/locale/lang/en';
import jaLocale from 'element-ui/lib/locale/lang/ja';

// 载入element-ui组件语言包
const messages = { zh_cn: { ...zh_cn, ...zhLocale }, en: { ...en, ...enLocale }, ja: { ...ja, ...jaLocale }, zh_hk: { ...zh_hk, ...twLocale } };
Vue.use(VueI18n);

let defaultLocale = _.snakeCase(navigator.language);
if (defaultLocale.startsWith('en_')) {
  defaultLocale = 'en';
} else if (defaultLocale.startsWith('zh_') && defaultLocale !== 'zh_cn') {
  defaultLocale = 'zh_hk';
}
defaultLocale = messages[defaultLocale] ? defaultLocale : 'ja';

export const i18n = new VueI18n({
  silentFallbackWarn: true,
  locale: localStorage.getItem(I18N_STORAGE_KEY) || defaultLocale,
  fallbackLocale: defaultLocale,
  messages
});

locale.i18n((key, value) => i18n.t(key, value));

Vue.prototype.$tp = function (key, ...args) {
  return this.$t(`${this.$route.name}.${key}`, ...args);
};

export const languages = {
  zh_cn: '简体中文',
  en: 'English',
  zh_hk: '繁體中文',
  ja: '日本語'
};
