import { render, staticRenderFns } from "./product-countdown.vue?vue&type=template&id=461df06a&scoped=true&"
import script from "./product-countdown.vue?vue&type=script&lang=js&"
export * from "./product-countdown.vue?vue&type=script&lang=js&"
import style0 from "./product-countdown.vue?vue&type=style&index=0&id=461df06a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "461df06a",
  null
  
)

/* custom blocks */
import block0 from "./product-countdown.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports