<template>
  <div class="action-sheet-wrapper"
    v-show="value"
    @click="showContent = false">
    <transition name="slide-right" @after-leave="$emit('input', showContent)">
      <div class="action-sheet-content" v-show="showContent" @click.stop>
        <img src="@/assets/icon-close.png" alt="" class="icon-close" @click="showContent = false">
        <div class="scroll-wrapper"><slot/></div>
      </div>
    </transition>
  </div>
</template>

<script>
  import { Vue, Component, Model, Watch } from 'vue-property-decorator';

  @Component
  export default class ActionSheet extends Vue {
    @Model('input', Boolean) value

    showContent = false;

    @Watch('value', { immediate: true })
    valueChange(val, old) {
      if (val !== old) {
        this.showContent = this.value;
      }
      document.body.style.overflow = val ? 'hidden' : 'initial';
      document.body.style.height = val ? '100%' : 'initial';
    }
  }
</script>

<style lang="scss" scoped>
  .action-sheet-wrapper {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    display: flex;
    align-items: flex-end;
    width: 100%;
    height: 100%;
    background: rgba(#000, 0.5);

    @include media-xs-only {
      padding-bottom: 60px;
    }

    .action-sheet-content {
      position: relative;
      flex: 1;
      padding-top: 40px;
      background: #fff;

      @include paddingBottomSafeArea;

      .scroll-wrapper {
        max-height: 70vh;
        overflow: auto;
      }

      .icon-close {
        position: absolute;
        top: 15px;
        right: 13px;
        width: 16px;
        height: 16px;
        filter: brightness(0);
      }
    }
  }

  .slide-right-enter {
    transform: translateY(100%);
  }

  .slide-right-enter-to {
    transform: translateY(0);
    transition: all 0.3s;
  }

  .slide-right-leave-active {
    transform: translateY(100%);
    transition: all 0.3s;
  }
</style>
