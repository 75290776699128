<template>
  <div class="box-swiper-control" :style="style">
    <div class="container flex item-center content-between">
      <div class="btn btn-pre back" @click="$emit('pre')" :class="{ 'invisible': !showPre }">
        <img class="icon" src="@/assets/icon-arrow-left-black.png" />
        <div v-if="$slots.pre" style="margin-left: 0.83333rem;"><slot name="pre"/></div>
      </div>

      <router-link v-if="!$slots.title" class="item lot-title text-overflow" tag="div" :to="routeTo">{{ title }}</router-link>
      <div class="lot-title text-overflow" v-else>
        <slot name="title"></slot>
      </div>

      <div class="btn btn-next front" @click="$emit('next')" :class="{ 'invisible': !showNext }">
        <div v-if="$slots.next" style="margin-right: 0.83333rem;"><slot name="next"/></div>
        <img class="icon" src="@/assets/icon-arrow-right-black.png" />
      </div>
    </div>
  </div>
</template>

<script>
  import { Vue, Component, Prop } from 'vue-property-decorator';

  @Component
  export default class ToggleControl extends Vue {
    @Prop({ type: Boolean, default: true }) showNext;
    @Prop({ type: Boolean, default: true }) showPre;
    @Prop({ type: Object, default: () => ({ name: '' }) }) routeTo
    @Prop({ type: String, default: 'auctionCategoryDetail' }) backRouteName
    @Prop(Boolean) sticky;
    @Prop(Number) zIndex;
    @Prop(String) title;
    @Prop(Number) backId;
    @Prop({ type: [Number, String], default: 'var(--header-height)' }) top;

    get style() {
      const top = this.top;
      const zIndex = this.zIndex ? this.zIndex : 'var(--fixed-z-index)';
      return {
        position: this.sticky ? 'sticky' : 'relative',
        top: this.sticky ? top : 0,
        zIndex: this.sticky ? zIndex : '0',
      };
    }
  }
</script>

<style lang="scss" scoped>
  .box-swiper-control {
    height: var(--auction-item-toggle-height);
    line-height: 40px;
    background: #fff;
    box-shadow: 2px 6px 36px 2px rgba(129, 129, 129, 0.17);

    .container {
      display: flex;
      align-items: center;
      height: 100%;
      padding: 0 10px;

      @include media-lg-and-up {
        padding: 0;
      }
    }

    .lot-title {
      flex: 1;
      text-align: center;
      cursor: pointer;
    }

    .front {
      &::before {
        width: 1px;
        height: 26px;
        margin-right: 30px;
        background-color: $border-light;
        content: '';

        @include media-xs-only {
          margin-right: 10px;
        }
      }
    }

    .back {
      &::after {
        width: 1px;
        height: 26px;
        margin-left: 30px;
        background-color: $border-light;
        content: '';

        @include media-xs-only {
          margin-left: 10px;
        }
      }
    }

    .btn {
      display: flex;
      align-items: center;
      padding: 0 10px;
      font-size: 14px;
      cursor: pointer;

      .icon {
        width: 14px;
      }
    }
  }
</style>
