<i18n>
  {
    "zh_cn": {
      "orders": "我的成交",
      "logout": "登出",
      "logoutHint": "确定要退出登录?"
    },
    "zh_hk": {
      "orders": "我的成交",
      "logout": "登出",
      "logoutHint": "確定要退出登錄？"
    },
    "en": {
      "orders": "My Transaction",
      "logout": "Logout",
      "logoutHint": "Confirm log out?"
    },
    "ja": {
      "orders": "取引中",
      "logout": "ログアウト",
      "logoutHint": "ログアウトしてもよろしいですか?"
    }
  }
</i18n>

<template>
  <div class="component-side-bar">
    <div class="header flex column content-center item-center">
      <img class="avatar" src="@/assets/mine/avatar-black.png" />
      <div class="user flex item-center">
        <div class="name text-overflow">{{ nickName }}</div>
        <div v-if="authStore.user.is_vip" class="tag-vip">VIP</div>
      </div>
    </div>
    <router-link :class="['link-item', 'flex', 'item-center', { active: current.includes(item.path) }]"
      v-for="item in routerList"
      :key="item.name"
      :to="{ name: item.name }"
      replace
    >
      <img class="icon" :src="item.icon" />
      <div class="name">{{ $t(item.name) }}</div>
    </router-link>
    <div class="logout flex content-center">
      <div class="btn-logout" @click="handleLogout">{{ $t('logout') }}</div>
    </div>
  </div>
</template>

<script>
  import { Vue, Component, Watch } from 'vue-property-decorator';
  import { authStore } from '@/store';

  @Component
  export default class SideBar extends Vue {
    authStore = authStore
    current = ''

    // TODO: 改用name判断高亮，修改嵌套名字
    routerList = [
      { name: 'myWallet', icon: require('@/assets/mine/icon-wallet.png'), path: '/mine/wallet' },
      { name: 'orders', icon: require('@/assets/mine/icon-deal.png'), path: '/orders' },
      { name: 'myBidding', icon: require('@/assets/mine/icon-bidding.png'), path: '/mine/bidding' },
      { name: 'myCollection', icon: require('@/assets/mine/icon-collection.png'), path: '/mine/collection' },
      { name: 'myAuctionNumber', icon: require('@/assets/mine/icon-auction-number.png'), path: '/mine/auction-number' },
      { name: 'mySettings', icon: require('@/assets/mine/icon-settings.png'), path: '/mine/settings' },
      { name: 'myAddress', icon: require('@/assets/mine/icon-address.png'), path: '/mine/address' }
    ]

    @Watch('$route', { immediate: true })
    handler(val) {
      this.current = val.path;
    }

    get nickName() {
      const { name, last_name, phone } =  authStore.user;
      return (last_name + name) || phone;
    }

    async created() {
      if (!authStore.access_token) {
        this.$router.replace('/login');
        return;
      }
    }

    async handleLogout() {
      await this.$confirm(this.$t('logoutHint'));
      authStore.logout();
      this.$router.replace('/');
    }
  }
</script>

<style lang="scss" scoped>
  .component-side-bar {
    position: sticky;
    top: var(--header-height);
    min-width: 191px;
    height: 100vh;
    padding-top: 28px;
    border-right: 1px solid #ddd;

    .avatar {
      width: 50px;
      height: 50px;
    }

    .user {
      padding: 12px 0 29px;

      .name {
        max-width: 100px;
        font-size: 13px;
      }

      .tag-vip {
        padding: 2px 10px;
        margin-left: 6px;
        border-radius: 2px;
        font-size: 12px;
        color: #fff;
        background: #a58b67;
        transform: scale(0.7);
      }
    }

    .link-item {
      padding: 17px 0 17px 28px;
      border-bottom: 1px solid #ddd;
      font-size: 14px;
      color: #000;

      &:hover {
        background: #eee;
      }

      &.active {
        background: #eee;
      }

      .icon {
        width: 20px;
        height: 20px;
        padding-right: 13px;
      }
    }

    .logout {
      padding: 20px 0;

      .btn-logout {
        display: inline-block;
        padding: 10px 23px;
        border: 1px solid #000;
        border-radius: 20px;
        font-size: 12px;
        cursor: pointer;
      }
    }
  }
</style>
