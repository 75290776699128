<i18n src="./locales.json"></i18n>

<template>
  <div class="container mine">
    <v-title :value="$t('mineAccount')"/>
    <div class="header">
      <div class="card flex item-center" :style="{ backgroundImage: `url(${cardImage})` }">
        <div class="flex item-center flex-1">
          <img class="avatar" src="@/assets/mine/avatar.png" />
          <div class="user flex item-center">
            <div class="name text-overflow">{{ nickName }}</div>
            <div v-if="$authStore.user.is_vip" class="tag-vip">VIP</div>
          </div>
        </div>
        <div class="btn-logout" @click="handleLogout">{{ $t('logout') }}</div>
      </div>
    </div>
    <div>
      <el-row>
        <el-col
          :sm="8"
          v-for="item in routerList" :key="item.name"
        >
          <router-link class="link-item flex" :to="{ path: item.path }">
            <div class="icon-content">
              <img class="icon" :src="item.icon" />
              <div class="name">{{ $t(item.name) }}</div>
            </div>
            <img class="icon-arrow hidden-sm-and-up" src="@/assets/icon-arrow-right-black.png" />
          </router-link>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';
  import { authStore } from '@/store';
  import cardImage from '@/assets/mine/card.png';

  @Component
  export default class Mine extends Vue {
    cardImage = cardImage
    authStore = authStore

    routerList = [
      { name: 'myWallet', icon: require('@/assets/mine/icon-wallet.png'), path: '/mine/wallet' },
      { name: 'myDeal', icon: require('@/assets/mine/icon-deal.png'), path: '/orders' },
      { name: 'myBidding', icon: require('@/assets/mine/icon-bidding.png'), path: '/mine/bidding' },
      { name: 'myCollection', icon: require('@/assets/mine/icon-collection.png'), path: '/mine/collection' },
      { name: 'myAuctionNumber', icon: require('@/assets/mine/icon-auction-number.png'), path: '/mine/auction-number' },
      { name: 'mySettings', icon: require('@/assets/mine/icon-settings.png'), path: '/mine/settings' },
      { name: 'myAddress', icon: require('@/assets/mine/icon-address.png'), path: '/mine/address' }
    ]

    get nickName() {
      const { name, last_name, phone } =  this.$authStore.user;
      return (last_name + name) || phone;
    }

    async created() {
      if (!this.$authStore.access_token) {
        this.$router.replace('/login');
        return;
      }
      return this.fetchData();
    }

    @Vue.autoLoading
    async fetchData() {
      return this.$authStore.tryFetchData();
    }

    async handleLogout() {
      await this.$confirm(this.$t('logoutHint'));
      this.$authStore.logout();
      this.$router.replace('/');
    }
  }
</script>

<style lang="scss" scoped>
  .container {
    @include media-xs-only {
      padding: 0;
    }

    .header {
      padding: 23px 0 31px;

      @include media-xs-only {
        padding: 16px 16px 18px;
        background: #eee;
      }

      .card {
        padding: 25px 17px 25px 19px;
        border-radius: 10px;
        color: #fff;
        background-position: center;
        background-size: 120% 120%;
        box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.09);

        .avatar {
          width: 50px;
          height: 50px;
        }

        .user {
          margin-left: 16px;

          .name {
            max-width: 150px;
            font-size: 16px;

             @include media-sm-and-up {
               max-width: 300px;
             }
          }

          .tag-vip {
            padding: 1px 10px;
            margin-left: 2px;
            border-radius: 2px;
            font-size: 12px;
            background: #a58b67;
            transform: scale(0.6);
          }
        }

        .btn-logout {
          padding: 5px 12px;
          border: 1px solid #ddd;
          border-radius: 15px;
          font-size: 13px;
        }
      }

      @include media-sm-and-up {
        .card {
          padding: 25px;
          border-radius: 17px;

          .avatar {
            width: 54px;
            height: 54px;
          }

          .user {
            margin-left: 22px;

            .name {
              font-size: 19px;
            }

            .tag-vip {
              margin-left: 5px;
              transform: scale(0.8);
            }
          }

          .btn-logout {
            border-radius: 20px;
            font-size: 16px;
            cursor: pointer;
          }
        }
      }
    }


    /deep/ .el-row {
      .el-col {
        &:hover {
          background: #eee;
        }
      }
    }

    .link-item {
      justify-content: center;

      .icon-content {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;

         @include media-sm-and-up {
           flex-direction: column;
           padding: 29px 0 31px;
         }


        .icon {
          width: 24px;
          height: 24px;
          padding-bottom: 24px;
        }

        .name {
          font-size: 18px;
          color: #000;
        }
      }
    }

    @include media-xs-only {
      .link-item {
        justify-content: space-between;
        align-items: center;
        padding: 14px 0;
        margin: 0 15px;
        border-bottom: 1px solid #ddd;

        .icon-content {
          .icon {
            width: 25px;
            height: 25px;
            padding: 0;
          }

          .name {
            margin-left: 15px;
            font-size: 14px;
          }
        }

        .icon-arrow {
          width: 11px;
          height: 11px;
        }
      }
    }
  }
</style>
