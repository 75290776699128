import _ from 'lodash';
import qs from 'qs';
import { decoder } from '@/utils/decoder';
import { i18n } from '@/i18n';
import { authStore } from '@/store';
import router from '@/router';
import { MessageBox } from 'element-ui';
import { CAPTCHA_RCODE_SESSION_KEY } from '@/constants';

export function onSend(request) {
  if (request.method === 'GET' && request.body) {
    request.params = qs.stringify(request.body, { arrayFormat: 'brackets' });
    delete request.body;
  }
  if (!request.headers['Authorization']) {
    request.headers['Authorization'] = authStore.access_token;
  }
  const locale = i18n.locale;
  if (!request.headers.Locale) {
    if (locale) {
      request.headers.Locale = locale;
    }
  }
  if (window[CAPTCHA_RCODE_SESSION_KEY]) {
    request.headers['Captcha-Rcode'] = window[CAPTCHA_RCODE_SESSION_KEY];
  }

  return request;
}

export function onSucceed(res) {
  res['isResponse'] = true;
  res.meta = {};
  _.forEach(res.headers, (v, k) => {
    v = res.headers[k] = [].concat(v)[0];
    if (/^x-/.test(k)) {
      const key = _.snakeCase(k.replace(/^x-/, ''));
      res.meta[key] = decoder(v);
    }
  });

  // 如果用户有活跃登录，就自动延长登录时长,和更新token
  const refreshToken = _.get(res, 'headers.refresh-token');
  const tokenExpiredTime = _.get(res, 'headers.token-expired-time-stamp');
  if (refreshToken) {
    authStore.access_token = refreshToken;
  }
  if (tokenExpiredTime) {
    authStore.switchDuration(tokenExpiredTime);
  }
  return res;
}

export async function onError(err) {
  const { error_message, message, messages, error, code } = _.get(err, 'response.data', {});
  err.message = error_message || message || messages || error || err.message;
  err.api_code = code;
  const checkAuth = _.get(err, 'request.checkAuth', true);

  if (checkAuth && err.status === 401) {
    authStore.logout();
    const { fullPath = '' } = router.app.$route;
    router.replace({ name: 'login', query: { redirect: encodeURIComponent(fullPath) } });
  }

  // 如果用户账号被禁用, 则自动退出登录
  if (checkAuth && code === 40301) {
    try {
      await MessageBox.close();
    // eslint-disable-next-line no-empty
    } catch (e) {}
    await MessageBox.alert(error_message, { showClose: false });
    authStore.logout();
    window.location.reload();
    throw new Error('ignore');
  }
  throw err;
}
