<template>
  <transition name="el-fade-in-linear">
    <div class="component-notification" v-show="show && text">
      <i class="el-icon-info"></i>
      <div class="message">{{ text }}</div>
    </div>
  </transition>
</template>

<script>
  import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

  @Component
  export default class Notification extends Vue {
    @Prop([String, Object]) message

    show = false

    get text() {
      return typeof this.message === 'object' ? this.message.message : this.message;
    }

    @Watch('message', { immediate: true })
    messageChange(val) {
      this.clearTimer();
      this.show = true;
      if (val.duration) {
        this.timer = setTimeout(() => {
          this.show = false;
        }, val.duration);
      }
    }

    clearTimer() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
    }

    beforeDestroy() {
      this.clearTimer();
    }
  }
</script>

<style lang="scss" scoped>
  .component-notification {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    margin: 10px 0;
    border-radius: 4px;
    color: $gray;
    background: #edf2fc;

    .el-icon-info {
      flex-shrink: 0;
      margin-right: 10px;
      font-size: 18px;
      color: $gray;
    }
  }
</style>
