<template>
  <div @click="handleShare">
    <slot />
  </div>
</template>

<script>
  import { Vue, Component, Prop } from 'vue-property-decorator';
  import QRCode from 'qrcode';

  @Component
  export default class SocialShare extends Vue {
    @Prop(String) url;
    @Prop({ type: String, default: document.title }) title;

    get shareUrl() {
      return this.url || location.origin + this.$route.fullPath;
    }

    get links() {
      return [
        {
          img: require('@/assets/icon-facebook-black.png'),
          name: 'facebook',
          url: `http://www.facebook.com/sharer.php?u=${this.shareUrl}`
        },
        {
          img: require('@/assets/icon-line-black.png'),
          name: 'line.me',
          url: `https://lineit.line.me/share/ui?url=${this.shareUrl}&text=${this.title}`
        },
        {
          img: require('@/assets/icon-weixin-black.png'),
          name: 'wechat',
          url: ''
        }
      ];
    }

    handleShowShareDialog() {
      this.$msgbox({
        title: this.$t('share'),
        lockScroll: true,
        center: true,
        showConfirmButton: false,
        message: (
          <div class="flex content-center share-content">
            {
              this.links.map((item, index) => (
                <a
                  key={index}
                  href={item.url}
                  target="_blank"
                  class="flex column item-center content-center item"
                  key={item.name}
                  onClick={e => this.handleItemShare(item.name, e)}
                >
                  <img src={item.img} />
                  <p class="name">{item.name}</p>
                </a>
              ))
            }
          </div>
        )
      });
    }

    handleShare() {
      if (typeof navigator.share === 'function') {
        // props里面会获取不到document.title
        const title = this.title || document.title;
        navigator.share({
          title: title,
          text: title,
          url: this.shareUrl
        }).catch(this.handleShowShareDialog);
        return;
      }
      this.handleShowShareDialog();
    }

    handleItemShare(name, e) {
      if (name === 'wechat') {
        e.preventDefault();
        QRCode.toDataURL(this.shareUrl, { width: 300, height: 300 }, (err, url) => {
          this.$msgbox.close();
          this.$msgbox({
            title: this.$t('wechatShare'),
            lockScroll: true,
            center: true,
            showConfirmButton: false,
            message: <img src={url} />
          });
        });
      }
    }
  }
</script>

<style lang="scss" scoped>
  .share-content {
    .item {
      color: inherit;

      img {
        width: 32px;
        height: 32px;
      }

      &:not(:last-child) {
        margin-right: 20px;
      }
    }
  }
</style>
