import Vue from 'vue';
import { fly } from '@/utils';
import { createConsumer } from '@/utils/actioncable';
import { MessageBox } from 'element-ui';
import _ from 'lodash';
import { authStore } from '@/store';
import { i18n } from '@/i18n';
import qs from 'qs';
import dayjs from 'dayjs';

export default class {
  auction = {};
  auctionItems = Vue.observable([]);
  dynamicAuctionItems = Vue.observable([]);
  biddingPricesRecord = Vue.observable({});
  liveBroadcast = {};
  number = '';
  quotas = [];
  auctionItem = null; // 当前拍品
  autoBiddings = [];
  viewCount = 0; // 看直播人数

  get isFinished() {
    return this.auction.is_finished;
  }

  get isStarted() {
    const { start_at, is_finished } = this.auction;
    return !is_finished && dayjs().diff(dayjs(start_at)) > 0;
  }

  // 正在进行中预展直播
  get isPreviewInLive() {
    return _.get(this.auction, 'current_normal_live_broadcast.status') === 'in_live';
  }

  get liveBroadcastId() {
    return _.get(this.auction, 'current_normal_live_broadcast.id');
  }

  get raiseRules() {
    return _.get(this.auctionItem, 'auction_category.current_raise_rule_set.raise_rules') || [];
  }

  // online_and_offline, offline
  get isOfflineLot() {
    return _.get(this.auctionItem, 'auction_way') === 'offline';
  }

  // 可出价
  get canBid() {
    return !this.isOfflineLot && this.number && this.status === 'pending';
  }

  // pending: 等待拍卖 aborted: 流拍(无人出价) force_aborted: 强制流拍 hammered: 落槌 force_hammered：强制落槌
  get status() {
    return _.get(this.auctionItem, 'status');
  }

  get auctionItemIndex() {
    return this.auctionItems.findIndex(item => item.id === this.auction_item_id);
  }

  get lastPrice() {
    const currentBiddingPrices = (_.get(this.biddingPricesRecord, this.auction_item_id) || [])
      .filter(item => ['effective', 'adjusted'].includes(item.status));
    return _.first(currentBiddingPrices);
  }

  get lastPriceUserNumber() {
    return _.get(this.lastPrice, 'auction_number.number');
  }

  get auction_item_id() {
    return _.get(this.auctionItem, 'id');
  }

  get preBidPrice() {
    return _.get(this.auctionItem, 'auto_bidding_price');
  }

  constructor(id, options, isPreview, vm) {
    this.id = id;
    this.options = options || {};
    this.isPreview = isPreview;
    this.vm = vm;
  }

  disconnect() {
    if (this.consumer) {
      this.consumer.disconnect();
    }
  }

  async getAutoBiddings() {
    if (authStore.isLogin) {
      const { data } = await fly.get('auto_biddings', {
        targetable_type_cont: 'AuctionItem',
        auction_id_eq: this.id,
        is_preview: this.isPreview ? true : undefined
      });
      this.autoBiddings = _.map(data, (ele) => {
        return {
          id: ele.targetable_id,
          auto_bidding_price: ele.highest_price
        };
      });
    }
  }

  async fetchLiveBroadcast() {
    const { data: liveBroadcast } = await fly.get(`/auctions/${this.id}/live_broadcast`, { is_preview: this.isPreview ? true : undefined });
    this.liveBroadcast = liveBroadcast;
  }

  async fetchData() {
    const [{ data }] = await Promise.all([
      fly.get(`/auctions/${this.id}`, { entity: 'LivePageAuction', is_preview: this.isPreview ? true : undefined }),
      this.getAuctionItems(),
      this.getDynamicAuctionItems(),
      this.getAutoBiddings(),
      this.fetchAuctionNumber()
    ]);
    // 合并拍品静态数据和动态数据列表
    const mergeList = this.auctionItems.concat(this.dynamicAuctionItems).concat(this.autoBiddings);
    this.auctionItems = _.orderBy(_.map(_.groupBy(mergeList, 'id'), (array, id) => ({ id: id, ...array[0], ...array[1], ...array[2] })),
      ['auction_category_position', 'auction_category_id', 'number'], ['asc', 'desc', 'asc']);
    // 预防undefined
    if (!data.global_notification) {
      data.global_notification = '';
    }
    this.auction = data;
    if (this.isFinished) {
      this.toastFinishedMessage();
    }
    if (this.auctionItem) {
      // 把websocket拿到的最新数据塞到auctionItems里面
      await this.handleGetAuctionItem(this.auctionItem);
    }
  }

  async getAuctionItems() {
    const { data } = await fly.get('/auction_items', { auction_category_auction_id_eq: this.id, page: 0, entity: 'LivePageAuctionItem', is_preview: this.isPreview ? true : undefined });
    this.auctionItems = data;
  }

  async getDynamicAuctionItems() {
    const { data } = await fly.get('/auction_items/dynamic_data', { auction_category_auction_id_eq: this.id, page: 0, is_preview: this.isPreview ? true : undefined });
    this.dynamicAuctionItems = data;
  }

  async fetchAuctionNumber() {
    if (authStore.isLogin) {
      const { data: { number, quotas } } = await fly.get('/mine/auction_number', { auction_id: this.id, is_preview: this.isPreview ? true : undefined });
      this.number = number;
      this.quotas = quotas;
    }
  }

  initSubscription() {
    const params = { Locale: i18n.locale };
    if (authStore.access_token) {
      params.Authorization = authStore.access_token;
    }
    const query = qs.stringify(params);
    const consumer = this.consumer = createConsumer(`/cable?${query}`);
    this.subscription = consumer.subscriptions.create({ channel: 'AuctionChannel', id: this.id, uid: this.liveBroadcast.channel_uid }, {
      received: (result) => this.received(result),
      disconnected: (result) => {
        this.vm && this.vm.$emit('disconnected', result);
      },
    });
  }

  toastFinishedMessage() {
    this.vm.$emit('info', {
      message: this.options.finishedTip,
      duration: 5000
    });
  }

  handleGetBidingPrices(data = [], itemId) {
    const auctionItemId = itemId || _.get(data, '0.auction_item_id');
    const recordList = data.sort((a, b) => b.id - a.id);
    Vue.set(this.biddingPricesRecord, auctionItemId, recordList);
  }

  async handleGetAuctionItem(data) {
    const auctionItem = _.merge({}, this.auctionItem, data);
    data && (auctionItem.images = data.images);
    const index = this.auctionItems.findIndex(item => String(item.id) === String(_.get(data, 'id')));
    this.auctionItem = auctionItem;
    this.auctionItem.currency = _.get(this.auctionItem, 'auction_category.currency') || _.get(this.auctionItems[index], 'currency');
    this.auctionItem.auto_bidding_price = _.get(this.auctionItems[index], 'auto_bidding_price');
    if (index !== -1) {
      Vue.set(this.auctionItems, index, auctionItem);
    }
  }

  // ## AuctionChannel Code
  // | Code | Reason        |
  // | ---- |:--------------|
  // | E001 | 找不到拍品 |
  // | E002 | 用户已经是最高价的出价者，不能再出价 |
  // | E003 | auction_item_id 不是当前拍品 ID |
  // | E004 | 出价不是有效出价 |
  // | E005 | 已经有相同的有效出价 |
  // | E011 | 用户被禁用/禁止出价 |
  async received(result) {
    const { success, action, data, message, origin_payload } = result;
    if (success) {
      switch (action.toLowerCase()) {
        case 'bidding_prices':
          this.handleGetBidingPrices(data, _.get(origin_payload, 'auction_item_id'));
          return;
        case 'auction_item':
          await this.handleGetAuctionItem(data);
          return;
        case 'announce':
          this.vm.$emit('info', { message: data.content, duration: 15000 });
          return;
        case 'next':
          await this.handleGetAuctionItem(data);
          return;
        case 'bid': {
          // 如果有收到noneffective，并且是当前用户的出价
          if (data.status === 'noneffective' && this.number && _.get(data, 'auction_number.number') === this.number) {
            this.options.onAdjustedRemind && this.options.onAdjustedRemind(data);
          }
          const auctionItemId = _.get(origin_payload, 'auction_item_id', data.auction_item_id);
          this.handleGetBidingPrices(
            _.unionBy([data, ..._.get(this.biddingPricesRecord, auctionItemId, [])], 'id')
          , auctionItemId);
          if (data.status !== 'noneffective') {
            this.options.onRemoveAdjustedRemind && this.options.onRemoveAdjustedRemind();
          }
          return;
        }
        case 'abort':
        case 'force_abort':
          await this.handleGetAuctionItem(data);
          return;
        case 'hammer':
        case 'force_hammer':
          this.hammer(data);
          this.biddingPrices();
          return;
        case 'update':
          if (!this.isFinished && data.is_finished) {
            this.toastFinishedMessage();
          }
          this.auction = _.merge({}, this.auction, data);
          return;
        case 'global_notify':
          this.auction.global_notification = data.global_notification;
          return;
        case 'retake':
          // 重拍后重置当前拍品的出价列表
          await this.fetchAuctionNumber();
          this.handleGetBidingPrices([], this.auction_item_id);
          await this.handleGetAuctionItem(data);
          return;
        case 'judge_price':
          this.handleGetBidingPrices(data);
          this.judgePriceSuccess(data);
          return;
        case 'update_view_count':
          this.viewCount = data.view_count;
          return;
      }
    } else {
      await MessageBox.alert(message, { showClose: false });
    }
  }

  // 判定成功
  judgePriceSuccess(data) {
    const number = _.get(data, '0.auction_number.number');
    const currency = (_.get(this.auctionItem, 'currency') || '').toUpperCase();
    const price = currency + this.vm.$options.filters.currencyFormat(_.get(data, '0.price'));
    const params = {
      'zh_cn': `网络客人${number}号叫价${price}为有效价格`,
      'zh_hk': `網絡客人${number}號叫價${price}為有效價格`,
      'en': `Auctioneer’s discretion: bidding ${number} by client’s paddle ${price} is accepted.`,
      'ja': `インターネット${number}番のお客様の${price}の金額が現在の最高入札額です。`
    };
    this.vm.$emit('info', { message: params[i18n.locale], duration: 15000 });
  }

  // 落槌
  async hammer(data) {
    await this.handleGetAuctionItem(data.auction_item);
    this.subscription.perform('bidding_prices', { auction_item_id: this.auction_item_id });
    const hammerAuctionNumber = _.get(data, 'auction_number.number');
    // 如果落槌number是用户number
    if (this.number && (hammerAuctionNumber === this.number)) {
      await this.fetchAuctionNumber();
      MessageBox.alert(this.options.congratulations, '');
    }
  }

  biddingPrices(id = this.auction_item_id) {
    if (id) {
      if (!this.biddingPricesRecord[id]) {
        this.subscription.perform('bidding_prices', { auction_item_id: id });
      }
    }
  }

  // 出价或者调整出价
  bid(price) {
    this.subscription.perform('bid', { price, auction_item_id: this.auction_item_id });
  }

  /**
   * @param {Number} price - 当前价
   * @param {Number} basePrice - 基数
   * @param {Array} range - 价格临界点 [100, 200]
   * @param {Boolean} reverse - 减法
   */
  calcStepPrice(price, basePrice, range, reverse) {
    const [min, max] = range;
    const steps = [0, 2, 5, 8].map(item => item * (basePrice / 10));
    const remainder = price % basePrice;
    const stepPrice = reverse ? steps.reverse().find(step => step < remainder) : steps.find(step => step > remainder);
    const intPirce = Math.floor(price / basePrice) * basePrice;
    if (!_.isUndefined(stepPrice)) {
      return Math.min(intPirce + stepPrice, max);
    }
    // 跳到下一个阶段
    if (reverse) {
      return Math.max(intPirce - basePrice + steps[0], min);
    }
    return Math.min(intPirce + basePrice + steps[0], max);
  }

  addPrice(price) {
    const rules = _.cloneDeep(this.raiseRules).sort((a, b) => b.start_at - a.start_at);
    let rule = rules.find(item => price >= item.start_at && price < item.end_at);
    // 没有匹配的规则取第一个规则
    if (!rule && rules.length) {
      rule = {
        ...rules[0],
        end_at: Number.MAX_SAFE_INTEGER
      };
    }
    if (!rule) {
      return price + 500;
    }
    if (rule.kind === 'two_five_eight') {
      return this.calcStepPrice(price, Math.pow(10, String(price).length - 1), [rule.start_at, rule.end_at]);
    }
    if (rule.kind === 'normal') {
      return Math.min(Math.floor(price / rule.minimum) * rule.minimum + rule.minimum, rule.end_at);
    }
  }

  minusPrice(price) {
    const rules = _.cloneDeep(this.raiseRules).sort((a, b) => a.start_at - b.start_at);
    let rule = rules.find(item => price > item.start_at && price <= item.end_at);
    // 没有匹配的规则取第一个规则
    if (!rule && rules.length) {
      rule = {
        ...rules[0],
        start_at: 0
      };
    }
    if (!rule) {
      return Math.max(0, price - 500);
    }
    if (rule.kind === 'two_five_eight') {
      return this.calcStepPrice(price, Math.pow(10, String(price).length - 1), [rule.start_at, rule.end_at], true);
    }
    if (rule.kind === 'normal') {
      return Math.max(Math.floor(price / rule.minimum) * rule.minimum - rule.minimum, rule.start_at);
    }
  }
}
