import { render, staticRenderFns } from "./index.vue?vue&type=template&id=6e9aa7e7&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=6e9aa7e7&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e9aa7e7",
  null
  
)

/* custom blocks */
import block0 from "./locales.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2FUsers%2Fquake%2Fworkspace%2Fauction%2Ftokyo-auction-frontend%2Fsrc%2Fviews%2Fproduct-categories%2Findex.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports