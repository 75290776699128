<i18n src="./locales.json"></i18n>

<template>
  <div class="page container">
    <v-title :value="auction_house.name"/>
    <div class="header flex item-center">
      <img :src="auction_house.logo.url" class="logo"/>
      <div>
        <div class="name">{{ auction_house.name }}</div>
        <div class="flex item-center">
          <div class="flex column item-center">
            <span class="info-label">{{ $tp('overview') }}</span>
            <span class="num">{{ auction_house.evaluations_count }}</span>
          </div>
          <div class="line"/>
          <div class="flex column item-center">
            <span class="info-label">{{ $tp('praiseRatio') }}</span>
            <span class="num">
              {{ (auction_house.good_rate_count / auction_house.evaluations_count).toFixed(2) * 100 || 0 }}%
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="row-title">{{ $tp('evaluationList') }}</div>
    <div class="filter-group">
      <div class="item">
        <el-select clearable :placeholder="$tp('all')" v-model="store.params.rate_eq">
          <el-option :label="$t('rate.good')" value="good"></el-option>
          <el-option :label="$t('rate.general')" value="general"></el-option>
          <el-option :label="$t('rate.poor')" value="poor"></el-option>
        </el-select>
      </div>
      <div class="item">
        <el-input
          v-model="store.params.keyword"
          type="search"
          ref="input"
          :placeholder="$tp('searchEvaluation')"
          @keyup.enter.native="handleSearch"
          @blur="store.params.keyword = $event.target.value.trim()"
        >
          <img src="@/assets/icon-search.png" slot="suffix" class="icon" @click="handleSearch"/>
        </el-input>
      </div>
    </div>

    <div class="evaluation-item" v-for="item in store.data" :key="item.id">
      <div class="name">{{ $tp('item.userName') }}：{{ item.valuator.decrypt_bidding_number }}</div>
      <div class="flex rate">
        <span>{{ $tp('item.rate') }}：</span>
        <span class="rate-text">{{ $t(`rate.${item.rate}`) }}</span>
      </div>
      <div class="content">{{ item.content }}</div>
    </div>

    <div class="empty flex column item-center" v-if="store.isEmpty">
      <img class="empty-img" src="@/assets/empty.png"/>
      <div class="text">{{ $t('emptyData') }}</div>
    </div>
    <pagination class="pagination" :store="store"/>
  </div>
</template>

<script>
  import { Component, Vue } from 'vue-property-decorator';
  import _ from 'lodash';
  import { Collection } from '@/store';

  @Component
  export default class List extends Vue {
    auction_house = { logo: {} }
    store = new Collection({
      params: {
        evaluable_type_eq: 'AuctionHouse',
        evaluable_id_eq: void 0,
        rate_eq: '', keyword: '',
      },
      fetch: params => this.$fly.get('evaluations', _.pickBy(params, v => v))
    })

    @Vue.autoLoading
    async created() {
      this.store.params.evaluable_id_eq = this.$route.query.auction_house_id;
      const res = await this.$fly.get(`auction_houses/${this.$route.query.auction_house_id}`);
      this.auction_house = res.data;
      await this.store.fetchData();
    }

    handleSearch() {
      if (this.store.params.keyword === this.$route.query.keyword) {
        return;
      }
      this.$refs.input.blur();
      this.$autoLoading(this.store.fetchData());
    }
  }
</script>

<style lang="scss" scoped>
  .page {
    line-height: 1;
  }

  .header {
    padding-top: 28px;
    padding-bottom: 30px;
    border-bottom: 1px solid #dddddd;

    .logo {
      width: 75px;
      height: 75px;
      margin-right: 37px;
      border-radius: 50%;
      background: #ffffff;
      box-shadow: 0 4px 18px 2px rgba(4, 0, 0, 0.1);
      object-fit: contain;
    }

    .name {
      margin-bottom: 17px;
      font-weight: bold;
    }

    .line {
      width: 1px;
      height: 40px;
      margin-right: 32px;
      margin-left: 36px;
      background: #dddddd;
    }

    .info-label {
      margin-bottom: 12px;
      font-weight: 500;
      font-size: 12px;
      color: #666666;
    }

    .num {
      font-weight: 500;
      font-size: 20px;
      color: #cc3366;
    }

    @include media-sm-and-up {
      justify-content: center;

      .logo {
        width: 94px;
        height: 94px;
      }

      .name {
        font-size: 22px;
      }
    }
  }

  .row-title {
    margin-top: 28px;
    margin-bottom: 24px;
    font-weight: 800;

    @include media-sm-and-up {
      margin-top: 38px;
      margin-bottom: 39px;
      font-size: 18px;
    }
  }

  .filter-group {
    display: flex;
    flex-direction: column;

    .item {
      margin-bottom: 9px;

      /deep/ .el-select {
        display: block;

        .el-select-dropdown__item.selected {
          color: #000;
        }
      }

      /deep/ .el-select__caret.el-input__icon.el-icon-arrow-up {
        font-weight: bold;
        font-size: 16px;
        color: #000;
      }

      /deep/ .el-input {
        outline: none;

        .el-input__inner {
          border-color: #ddd !important;
        }

        .el-input__suffix {
          display: flex;
          align-items: center;
        }
      }

      .icon {
        width: 15px;
        height: 15px;
        padding: 2px 5px 0 0;
      }
    }

    @include media-sm-and-up {
      flex-direction: row;
      justify-content: center;
      margin-bottom: 44px;

      .item {
        width: 281px;
        margin-bottom: 0;
      }

      .item:last-child {
        margin-left: 17px;
      }
    }
  }

  .evaluation-item {
    margin-top: 12px;
    margin-bottom: 28px;

    .name {
      margin-bottom: 18px;
      font-weight: 800;
      font-size: 13px;
    }

    .content {
      line-height: 20px;
    }

    .content, .rate {
      font-weight: 500;
      font-size: 12px;
      white-space: pre-wrap;
      color: #666666;
    }

    .rate {
      margin-bottom: 10px;
    }

    .rate-text {
      color: $primary;
    }
  }

  .empty {
    margin-top: 100px;
    color: #979797;

    @include media-xs-only {
      margin-top: 50px;
    }

    .empty-img {
      width: 80px;
      margin-bottom: 15px;
    }
  }

  .pagination {
    margin: 15px 0;
  }
</style>
