<i18n src="./locales.json"></i18n>

<template>
  <div class="page-auction-live-preview">
    <v-title :value="auction.title"></v-title>
    <skeleton :onFetch="onFetch" v-model="loading"/>
    <div v-if="!loading" class="container">
      <el-row :gutter="30" v-show="!loading">
        <el-col :xs="24" :sm="12" >
          <div class="cover-wrapper">
            <common-banner :images="auction.images" :oss-img="{ resize: { w: 1000 } }" />
          </div>
        </el-col>
        <el-col :xs="24" :sm="12" >
          <div class="box-info">
            <div class="auction-title">{{ auction.title }}</div>
            <p>{{ $t('auctionTime') }}：{{ auction.start_at | timeFormat }}</p>
            <p>{{ $t('auctionPlace') }}：{{ auction.place }}</p>
            <p>{{ $t('marginRatioTip') }}：1：{{ auction.margin_ratio }}</p>
            <div class="flex item-center content-between" style="margin-top: 0.83333rem;">
              <div class="auction-commission" :class="{ 'invisible': !auction.commission_percent }">{{ $t('auctionCommission') }}：{{ auction.commission_percent }}</div>
              <div v-if="auction.instruction" class="primary-btn-o hidden-sm-and-up detail-btn" @click="showDesc = true">{{$t('detailBtn')}}</div>
            </div>
          </div>
        </el-col>
      </el-row>
      <collapsible-rich-text v-if="auction.instruction" :content="auction.instruction" class="hidden-xs-only"/>

      <app-auction-preview-status
        :isPreview="isPreview"
        :auction="auction"
        :server-time="serverTime"
        :number="number"
        :quotas="quotas"
        @finished="handleFinished"
        @update-number="number = $event"
      />

      <common-title :title="$t('catalog')" style="margin: 3.33333rem 0 1.66667rem -1.66667rem;" v-show="!loading"/>

      <div class="box-lot" v-show="!loading">
        <el-row :gutter="30">
          <el-col
            :xs="24"
            :sm="12"
            :md="8"
            v-for="item in categories"
            :key="item.id"
          >
            <router-link :to="{ name: 'auctionCategoryDetail', params: { id: item.id } }">
              <card
                class="auction-category-card"
                :cover="$get(item, 'image.url')"
                :title="item.title"
              >
                <div class="info">
                  <div class="text-overflow"> {{ $t('lotDateAndPlace') }}： {{ item.place }}</div>
                  <div class="text-overflow">{{ $t('lotNumber') }}：{{ item.auction_items_number_range }}</div>
                  <div class="text-overflow">{{ $t('lotQty') }}：{{ $tc('pieces', item.auction_items_count, { count: item.auction_items_count }) }}</div>
                </div>
              </card>
            </router-link>
          </el-col>
        </el-row>
        <div v-if="!categories.length" class="box-lot-empty" v-html="$t('categoriesEmpty')"></div>
      </div>
    </div>
    <action-sheet v-model="showDesc">
      <action-sheet-content :auction="auction"/>
    </action-sheet>
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';
  import dayjs from 'dayjs';
  import { authStore, settingsStore } from '@/store';
  import Card from './components/card';
  import ActionSheetContent from './components/action-sheet-content';
  import Skeleton from './components/show-skeleton';

  @Component({
    components: {
      card: Card, Skeleton,
      'action-sheet-content': ActionSheetContent,
    }
  })
  export default class AuctionDetail extends Vue {
    auction = {};
    categories = [];
    serverTime = '';
    number = ''; // 牌号
    showDesc = false;
    authStore = authStore;
    settingsStore = settingsStore;
    isPreview = false;
    detailHeight = 0;
    loading = true;
    quotas = []

    get isFinished() {
      return this.auction.is_finished;
    }

    get isStarted() {
      const { start_at, is_finished } = this.auction;
      return !is_finished && dayjs(this.serverTime).diff(dayjs(start_at)) > 0;
    }

    get diffStart() {
      return dayjs(this.auction.start_at).diff(dayjs(this.serverTime), 'second');
    }

    async onFetch() {
      this.isPreview = !!this.$route.query.preview;
      const id = this.$route.params.id;
      await Promise.all([
        this.fetchAuction(id),
        this.fetchAuctionCategory(id),
        this.fetchMyNumber(id)
      ]);
      if (this.isPreview) {
        this.$message.warning({
          message: this.$t('preview_mode'),
          duration: 10000
        });
      }
    }

    async fetchAuction(id) {
      // 延迟1s取数据，防止取到后台更新前的数据。
      await this.$sleep(1000);
      const { data, headers } = await this.$fly.get(`/auctions/${id}`, { is_preview: this.isPreview ? true : undefined });
      this.auction = data;
      this.serverTime = headers.date;
    }

    async fetchAuctionCategory(id) {
      const { data } = await this.$fly.get('/auction_categories', { auction_id_eq: id, page: 0, is_preview: this.isPreview ? true : undefined });
      this.categories = data;
    }

    async fetchMyNumber(id) {
      if (authStore.isLogin) {
        const { data: { number, quotas } } = await this.$fly.get('mine/auction_number', { auction_id: id, is_preview: this.isPreview ? true : undefined });
        this.number = number;
        this.quotas = quotas;
      }
    }

    async handleFinished() {
      this.isPreview = !!this.$route.query.preview;
      const id = this.$route.params.id;
      await this.$autoLoading(Promise.all([
        this.fetchAuction(id),
        this.fetchAuctionCategory(id),
        this.fetchMyNumber(id)
      ]));
    }
  }
</script>

<style lang="scss" scoped>
  .page-auction-live-preview .container {
    @include media-sm-and-up {
      padding-top: 50px;
    }
  }

  .cover-wrapper {
    margin-bottom: 20px;

    @include media-xs-only {
      margin: 0 -20px 20px;
    }
  }

  .player {
    position: fixed;
    z-index: var(--fixed-z-index);
    top: var(--header-height) !important;
    width: 100%;

    @include media-sm-and-up {
      position: initial;
    }
  }

  .box-info {
    @include media-xs-only {
      margin-bottom: 10px;
    }

    .auction-title {
      margin-bottom: 22px;
      font-weight: bold;
      font-size: 20px;
      color: #000;
    }

    p {
      margin: 0;
      font-size: 14px;
      line-height: 24px;
      color: $gray;
    }

    .auction-commission {
      font-weight: bold;
      font-size: 14px;
      color: $primary;
    }

    .detail-btn {
      height: 30px;
      margin: 0;
    }
  }

  .box-lot {
    padding-bottom: 10px;

    @include media-xs-only {
      padding-bottom: 0;
    }

    .box-lot-empty {
      font-size: 12px;
      text-align: center;
      color: #9d9d9d;
    }

    a {
      color: #000;
    }

    .card {
      margin-bottom: 8px;
    }

    .component-card {
      margin-bottom: 30px;

      @include media-xs-only {
        margin-bottom: 15px;
      }
    }

    .info {
      font-size: 14px;
      line-height: 24px;
      color: $gray;
    }
  }
</style>
