import { SimpleStore } from './simple-store';
import { fly } from '@/utils';

class RegionsStore extends SimpleStore {
  data = {}

  async fetchData() {
    const { data } = await this.fetching(fly.get('regions'));
    this.data = data;
  }
}

export const regionsStore = new RegionsStore();
