<i18n src="./locales.json"></i18n>

<template>
  <div class="upload-image" :class="{ border: value && value.url }">
    <template v-if="value && value.url">
      <img class="image" :src="value.url">
      <el-button class="btn-delete" size="mini" @click="$emit('delete')">{{ $t('delete') }}</el-button>
    </template>
    <slot v-else />
    <input
      type="file"
      accept="image/png, image/jpg, image/jpeg, image/webp"
      @change="handleUpload"
    />
  </div>
</template>

<script>
import { Vue, Component, Model } from 'vue-property-decorator';
import { uploadFile } from '@/utils';
import _ from 'lodash';

@Component
export default class UploadImage extends Vue {
  @Model('change', { type: Object }) value;

  sizeLimit = 5;

  async handleUpload(e) {
    if (e.target.files.length) {
      const file = e.target.files[0];
      const sizeMB = _.get(file, 'size') / 1024 / 1024;
      if (sizeMB > this.sizeLimit) {
        this.$message({ type: 'error', message: `${this.$t('notExceed')}${this.sizeLimit}MB` });
        return;
      }
      const { url, signed_id } = await this.$autoLoading(uploadFile(file));
      this.$emit('change', {
        url,
        signed_id
      });
    }
  }
}
</script>

<style lang="scss" scoped>
  .upload-image {
    position: relative;
    margin-bottom: 15px;

    &.border {
      border: 1px dashed #dcdfe6;
    }

    .btn-delete {
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      padding: 5px;
      font-size: 10px;
      color: #fff;
      background: $black;
    }

    .image {
      width: 100%;
      height: 200px;
      object-fit: contain;
    }

    input {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
    }
  }
</style>
