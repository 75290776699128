<template>
  <div class="component-product-category-item" @click="handleNavDetail">
    <auto-height
      height="175%"
      class="image"
      :cover="category.image.url"
    >
      <div class="cover-content">
        <div class="name">{{ category.title }}</div>
      </div>
    </auto-height>
  </div>
</template>

<script>
  import { Vue, Component, Prop } from 'vue-property-decorator';

  @Component
  export default class ProductCategoryItem extends Vue {
    @Prop({ type: Object, default: () => ({}) }) category

    handleNavDetail() {
      this.$router.push({
        name: 'products',
        query: {
          type: 'product_category',
          id: this.category.id,
        }
      });
    }
  }
</script>

<style lang="scss" scoped>
  .image {
    border-radius: 2px;
    overflow: hidden;

    .cover-content {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      border-radius: 2px;
      background: rgba(99, 71, 71, 0.2);
      cursor: pointer;
      user-select: none;

      .name {
        font-weight: 500;
        text-align: center;
        color: #fff;
      }
    }
  }
</style>
