<i18n src="./locales.json"></i18n>

<template>
  <div class="page-order-detail flex">
    <v-title :value="$t('pdfPreview')"/>
    <common-sidebar class="hidden-xs-only" />
    <div class="container">
      <div class="title-wrap flex item-center content-between">
        <span class="title">{{ $t('pdfPreview') }}</span>
        <div class="btn" @click="handleExportPdf">{{ $t('exportBtn') }}</div>
      </div>
      <div ref="orders" v-if="orders.length">
        <div v-for="order in orders" :key="order.id" class="pdf-order-item">
          <PdfItem :order="order" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';
  import { authStore } from '@/store';
  import { getPdf } from '@/utils/get-pdf.js';
  import PdfItem from './components/order-pdf-item.vue';

  @Component({ components: { PdfItem } })
  export default class OrderPdfPreview extends Vue {
    orders = []

    @Vue.autoLoading
    async created() {
      await authStore.tryFetchData();
      if (!authStore.user.region) {
        await this.$alert(this.$t('personalInfoHint'), { showClose: false });
        this.$router.push({ name: 'mySettings' });
        return;
      }
      const ids = (this.$route.query.ids || '').split(',');
      const { data } = await this.$fly.get('orders/order_detail_list', { ids });
      this.orders = data;
    }

    @Vue.autoLoading
    async handleExportPdf() {
      if (!this.$refs.orders) {
        return;
      }
      await getPdf(
        this.$refs.orders.querySelectorAll('.pdf-order-item'),
        `${this.$t('detail')}`
      );
    }
  }
</script>

<style lang="scss" scoped>
  .title-wrap {
    margin: 30px 0 24px;

    .title {
      font-weight: bolder;
      font-size: 18px;
      color: #000;
    }

    .btn {
      width: 140px;
      height: 40px;
      border-radius: 3px;
      font-size: 15px;
      line-height: 40px;
      text-align: center;
      color: #fff;
      background-color: #bc4353;
      cursor: pointer;
    }
  }

  .pdf-order-item {
    padding-bottom: 100px;
  }
</style>
