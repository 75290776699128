<i18n src="../locales.json"></i18n>
<!-- 本文件copy于show.vue，改动了store的构建用于预览和生产pdf -->
<template>
  <div class="order-pdf-item">
    <div class="item title-wrapper flex content-between item-center">
      <div class="title">{{ $t("detail") }}</div>
      <div class="status flex">
        <img class="icon-status" :src="status.image" />
        <div>{{ status.text }}</div>
      </div>
    </div>
    <div class="item flex content-between">
      <div class="info-title">{{ $t("orderNumber") }}</div>
      <div class="info-content">{{ order.no }}</div>
    </div>

    <!-- 商品信息 -->
    <div class="item">
      <div class="info-title">{{ $t("productInfo") }}</div>
      <div class="component-order-item">
        <order-item :order="order" />
      </div>
    </div>

    <!-- 订单详情 -->
    <div v-if="auctionOrder && isWaitPayLogistic" class="item">
      <div class="info-title">{{ $t("orderPaidDetail") }}</div>
      <div class="price-detail-wrap">
        <cost-info
          :store="store"
          :showSubtotal="$get(store, 'data.exchange_rate_type') === 'to_jpy'"
          :showDeductionPrice="!!$get(store, 'data.payment_amount') && !!$get(store, 'data.margin_amount')"
        />
      </div>
    </div>

    <!-- 费用信息 -->
    <div class="item" v-if="!isWaitBuyerConfirmLogisticInfo">
      <cost-info
        :store="store"
        :showAuctionPrice="!isWaitPayLogistic && !isWaitBuyerConfirmLogisticInfo"
        :showCommissionAmount="!isWaitPayLogistic"
        :showTaxAmount="!isWaitPayLogistic"
        :showLogisticFee="showLogisticFee"
        :showSubtotal="!isWaitPayLogistic && $get(store, 'data.exchange_rate_type') === 'to_jpy'"
        :showDeductionPrice="!isWaitPayLogistic && !!$get(store, 'data.payment_amount') && !!$get(store, 'data.margin_amount')"
        :showTotal="showTotal"
      >
        <template v-slot:other-price-item>
          <!-- 已支付过运费 -->
          <div v-if="hadPayLogisticFee" class="price-item">
            <div class="info-title">{{ $t("shipping") }}：</div>
            <div>
              <span class="currency">{{ paymentCurrency }}</span>
              <span class="price">{{
                $get(store, "data.logistic_fee") | currencyFormat
              }}</span>
            </div>
          </div>
          <div v-if="showClosePaymentTip" class="closed-tip flex content-end">
            {{ $t("closedTip") }}
          </div>
        </template>
      </cost-info>
    </div>

    <!-- 批量结算订单 -->
    <div class="item" v-if="$get(order, 'valid_batch_settlements.length')">
      <div class="info-title">{{ $t("batchList") }}</div>
      <div
        v-for="(item, index) in $get(order, 'valid_batch_settlements', [])"
        :key="item.id"
        class="order-batch-box flex item-center content-between"
      >
        <div>
          {{ `${$t("number")}${ $get(order, "valid_batch_settlements.length") > 1 ? index + 1 : "" }` }}: {{ item.no }}
        </div>
        <router-link
          :to="{ name: 'orderBatchDetail', params: { id: item.id } }"
          class="order-batch-action"
          :class="{ pay: item.status === 'pending' }"
        >
          {{ item.status === "pending" ? $t("pay") : $t("viewOrderBatch") }}
        </router-link>
      </div>
    </div>

    <!-- 物流信息 -->
    <div class="item" v-if="$get(order, 'transport_company')">
      <div class="info-title">{{ $t("logisticsCompany") }}</div>
      <router-link
        :to="{
          name: 'transportCompanies',
          params: { id: $get(order, 'transport_company.id') }
        }"
      >
        <div class="logist">
          <div class="flex item-center content-between">
            <div class="flex item-center">
              <img
                class="logist-cover"
                :src="$get(order, 'transport_company.logo.url')"
              />
              <div class="logist-content">
                <div class="logist-name">
                  {{ $get(order, "transport_company.name") }}
                </div>
                <div class="logist-desc">
                  {{ $get(order, "transport_company.desc") }}
                </div>
              </div>
            </div>
            <img
              class="icon-arrow-right"
              src="@/assets/icon-arrow-right-black.png"
            />
          </div>
        </div>
      </router-link>
    </div>

    <!-- 收货信息 -->
    <div class="item">
      <div v-if="!selfPick">
        <div class="info-title">{{ $t("deliveryInfo") }}</div>
        <div :class="{ point: canChangeAddress }">
          <div class="flex item-center content-between">
            <div class="info-title name">{{ receiverInfo }}</div>
            <div class="payment-method" v-if="canChangeAddress">
              <img class="icon" src="@/assets/icon-arrow-right-black.png" />
            </div>
          </div>
          <div class="address">
            {{
              address.trim() ? $t("deliveryInfo") : $t("fillInDeliveryAddress")
            }}
            {{ address }}
          </div>
        </div>
      </div>
      <div v-else class="flex content-between">
        <div class="info-title">{{ $t("deliveryInfo") }}</div>
        {{ $t("self") }}
      </div>
    </div>

    <div class="item" v-if="dataStatus === 'trade_closed'">
      <!-- 订单取消理由 -->
      <div class="flex content-between">
        <div class="info-title">{{ $t("closedReason") }}：</div>
        <div class="prompt">{{ $get(order, "remark") }}</div>
      </div>
    </div>
  </div>
</template>

<script>
  import { Vue, Component, Prop } from 'vue-property-decorator';
  import _ from 'lodash';
  import { CURRENCY_MAP } from '@/constants';
  import CostInfo from './cost-info.vue';
  import { OrderDetailStore } from '@/store';

  @Component({ components: { CostInfo } })
  export default class OrderPdfPreview extends Vue {
    @Prop({ type: Object, default: () => ({}) }) order

    store = {}
    CURRENCY_MAP = CURRENCY_MAP

    get address() {
      const { country_name = '', province_name = '', city_name = '', detail_info = '', additional_detail_info = '' } = this.$get(this.order, 'address_info', {});
      return [country_name, province_name, city_name, detail_info, additional_detail_info].join(' ');
    }

    get receiverInfo() {
      const { last_name = '', receiver_name = '', tel_number = '', postal_code = '' } = this.$get(this.order, 'address_info', {});
      return last_name + [receiver_name, postal_code, tel_number].join(' ');
    }

    get addressDialogWidth() {
      return this.$isMobile() ? '96%' : '552px';
    }

    get status() {
      return {
        wait_buyer_pay: { image: require('@/assets/icon-order-wait-pay.png'), text: this.disabledConfirmPay ? this.$t('waitConfirm') : this.$t('waitPay') },
        wait_buyer_confirm_logistic_info: { image: require('@/assets/icon-order-wait-pay.png'), text: this.$t('waitPay') },
        wait_seller_send_goods: { image: require('@/assets/icon-order-wait-send.png'), text: this.$t('waitDeliver') },
        wait_buyer_confirmed_goods: { image: require('@/assets/icon-order-wait-receive.png'), text: this.$t('waitReceive') },
        wait_buyer_pay_logistic: { image: require('@/assets/icon-order-wait-pay.png'), text: this.$t('waitPayLogistic') },
        wait_buyer_self_pick: { image: require('@/assets/icon-order-wait-receive.png'), text: this.$t('waitSelfPick') },
        trade_finished: { image: require('@/assets/icon-order-completed.png'), text: this.$t('finished') },
        trade_closed: { image: require('@/assets/icon-order-cancelled.png'), text: this.$t('closed') },
        no_paid: { image: require('@/assets/icon-order-cancelled.png'), text: this.$t('noPaid') }
      }[this.dataStatus] || {};
    }

    // 拍品订单
    get auctionOrder() {
      return this.$get(this.order, 'orderable_type') === 'AuctionItem';
    }

    // null表示无选择任何运输方式；true表示选中自提；false表示选中了其他运输方式
    get selfPick() {
      return this.$get(this.order, 'is_self_pick');
    }

    // 是否显示选择物流公司
    get isShowSelectShipping() {
      if (this.auctionOrder) {
        // 待支付状态且管理员还未设置运输信息（自提/运输公司和运费）
        return this.isWaitBuyerPay && this.$get(this.order, 'payment_amount') &&
          !this.$get(this.order, 'is_self_pick') &&
          !this.$get(this.order, 'transport_company') &&
          this.$get(this.order, 'logistic_fee') === null;
      } else {
        return this.isWaitBuyerConfirmLogisticInfo;
      }
    }

    get dataStatus() {
      return this.$get(this.order, 'status');
    }

    get logisticFee() {
      return Number(this.$get(this.order, 'logistic_fee'));
    }

    get isWaitBuyerPay() {
      return this.dataStatus === 'wait_buyer_pay';
    }

    get isWaitPayLogistic() {
      return this.dataStatus === 'wait_buyer_pay_logistic';
    }

    get isWaitBuyerConfirmLogisticInfo() {
      return this.dataStatus === 'wait_buyer_confirm_logistic_info';
    }

    // 店铺是否关闭自提，仅影响商品订单
    get isCloseSelfPick() {
      return this.$get(this.order, 'auction_house.close_self_pick');
    }

    get canChangeAddress() {
      if (this.auctionOrder && this.isWaitBuyerPay) {
        return true;
      } else if (this.isWaitBuyerConfirmLogisticInfo) {
        return true;
      } else {
        return false;
      }
    }

    // 已支付过运费
    get hadPayLogisticFee() {
      return this.auctionOrder && this.logisticFee && !this.isWaitPayLogistic && !this.isWaitBuyerPay;
    }

    get showLogisticFee() {
      return !(this.auctionOrder && this.isWaitBuyerPay) && !this.isWaitBuyerConfirmLogisticInfo && !this.hadPayLogisticFee;
    }

    get waitBuyerPay() {
      return ['wait_buyer_pay', 'wait_buyer_pay_logistic'].includes(this.dataStatus) &&
        !(this.logisticFee === 0 && this.isWaitPayLogistic);
    }

    // 商品订单在店铺不关闭自提时，才显示自提选项
    get isShowSelfPick () {
      return this.auctionOrder || !this.isCloseSelfPick;
    }

    // 还未确认费用
    get disabledConfirmPay() {
      return !this.$get(this.order, 'payment_amount') || this.isWaitPayLogistic && this.logisticFee === 0;
    }

    get currency() {
      if (this.$get(this.order, 'exchange_rate_type') === 'to_jpy') {
        return 'JPY';
      } else {
        return this.$get(this.order, 'currency');
      }
    }

    // 拍品订单和已评价了是不能显示评价的
    get canEvaluate() {
      return this.dataStatus === 'trade_finished' && !this.order.was_evalued && this.order.orderable_type === 'Product';
    }

    // 只有支付过订单才提示用户金额返回钱包
    get showClosePaymentTip() {
      return this.dataStatus === 'trade_closed' && this.order.has_paid_payments;
    }

    // 待支付运费订单状态，取消订单状态且未支付过任何费用,确认收货信息状态时不显示，
    get showTotal() {
      if (!this.isWaitPayLogistic && this.dataStatus !== 'trade_closed' && !this.isWaitBuyerConfirmLogisticInfo) {
        return true;
      } else {
        return this.showClosePaymentTip;
      }
    }

    get paymentCurrency() {
      return _.toUpper(CURRENCY_MAP.includes(this.currency) ? this.currency : 'jpy');
    }

    created() {
      this.store = new OrderDetailStore(this.order.id);
      this.store.data = this.order;
    }
  }
</script>

<style lang="scss" scoped>
  .order-pdf-item {
    line-height: 21px;

    .title-wrapper {
      margin-top: 12px;

      @include media-sm-and-up {
        padding-bottom: 30px !important;
      }

      .title {
        font-weight: bold;
        font-size: 18px;
      }

      .status {
        color: #999;

        .icon-status {
          width: 20px;
          height: 20px;
          padding-right: 10px;
        }
      }
    }

    .item {
      padding: 16px 0;
      border-bottom: 1px solid #ddd;

      &:nth-last-child(1),
      &:nth-last-child(2) {
        border-bottom: none;
      }

      &.hint-wrap {
        display: flex;
        justify-content: center;

        .hint-box {
          padding: 10px 20px;
          color: #000;
          background: rgba(192, 192, 192, 0.2);
        }
      }

      .info-title {
        min-width: 120px;
        font-weight: 800;

        &.name {
          padding: 12px 0 8px 1px;
        }
      }

      .info-content {
        color: #666;

        .icon-payment {
          width: 20px;
          height: 20px;
          padding-right: 10px;
        }
      }

      .component-order-item {
        cursor: pointer;
      }

      .point {
        cursor: pointer;
      }

      .address {
        padding-left: 1px;
        font-size: 12px;
        color: $gray;
      }

      .logist {
        margin-top: 14px;

        .logist-cover {
          width: 75px;
          height: 75px;
          border-radius: 50%;
          box-shadow: 0px 4px 18px 2px rgba(4, 0, 0, 0.1);
          object-fit: cover;
        }

        .logist-content {
          margin: 0 37px 0 19px;

          .logist-name {
            color: #000;
          }

          .logist-desc {
            margin-top: 6px;
            font-size: 12px;
            line-height: 16px;
            color: #999;

            @include text-overflow(3);
          }
        }

        .icon-arrow-right {
          width: 12px;
          height: 12px;
        }
      }

      .no-shipping {
        font-size: 12px;
      }

      .submit-btn {
        margin: 17px auto;

        &.disabled {
          color: #fff;
          background: #ccc;
          pointer-events: none;
        }
      }

      .prompt {
        color: #999;
      }

      .icon {
        width: 12px;
        height: 12px;
        margin-left: 6px;
      }

      .order-batch-box {
        margin-top: 10px;
        color: #666;

        .order-batch-action {
          padding: 4px 12px;
          margin-left: 32px;
          border: 1px solid #666;
          color: #666;

          @include media-xs-only {
            margin-left: 12px;
          }

          &.pay {
            border-color: $primary;
            color: $primary;
          }
        }
      }
    }

    .price-detail-wrap {
      margin-top: 10px;

      /deep/.subtotal {
        padding: 0;
        margin-top: 0.2rem;
        border: 0;
      }

      /deep/ .info-title {
        font-size: 12px;
      }
    }

    .pay-tip {
      margin-top: 10px;
      font-size: 12px;
      color: #bb4353;
    }
  }
</style>
