<i18n src="./locales.json"></i18n>

<template>
  <div class="flex">
    <common-sidebar class="hidden-xs-only"/>
    <v-title :value="$t('batchOrderDetail')"/>

    <div class="container">
      <div class="title-wrap flex item-center content-between">
        <div class="flex item-center">
          <div class="title">{{ $t('batchOrderDetail') }}</div>
          <div class="count">{{ $get(store, 'data.orders.length') }} {{ $t('count') }}</div>
        </div>
        <div>{{ $t(statusMap[status]) }}</div>
      </div>

      <div class="delivery-wrap">
        <div class="info-title">{{ $t('deliveryInfo') }}</div>
        <div class="content-box">
          <component
            :is="status === 'pending' && kind === 'product_kind' && !isProductOrders ? 'router-link' : 'div'"
            :to="{ name: 'myAddressList', query: { order_id: $get(store, 'data.id'), routerName: 'orderBatchDetail' } }"
            class="info-wrap flex item-center content-between"
          >
            <div class="flex-1">
              <div class="contact-box">{{ uniformAddress.contactInfo }}</div>
              <div class="address-box">{{ $t('deliveryInfo') }} {{ uniformAddress.deliveryInfo }}</div>
            </div>
            <img v-if="status === 'pending' && kind === 'product_kind' && !isProductOrders" class="icon" src="@/assets/icon-arrow-right-black.png" />
          </component>
          <div class="tip-box">{{ $t('batchDetailTip') }}</div>
        </div>
      </div>

      <div class="orders-wrap">
        <div v-for="order in $get(store, 'data.orders', [])" :key="order.id" class="batch-order-item">
          <batchOrder-item
            :order="order"
            :uniformAddress="uniformAddress"
            :kind="kind"
            :status="status"
            @openAddressPopup="handleOpenAddressPopup"
          />
        </div>
      </div>

      <div class="total-wrap flex content-between">
        <div class="count">{{ $get(store, 'data.orders.length') }} {{ $t('count') }}</div>
        <div class="total">
          <div v-if="kind === 'product_kind'" class="price-box flex content-between">
            {{ $t('deductionPrice') }}：
            <div>
              <span class="currency">- {{ $get(store, 'data.currency') }}</span>
              <span class="price">{{ $get(store, 'data.margin_amount') | currencyFormat }}</span>
            </div>
          </div>
          <div class="price-box flex content-between">
            {{ $t('batchTotalPrice') }}：
            <div>
              <span class="currency">{{ $get(store, 'data.currency') }}</span>
              <span class="price">{{ kind === 'product_kind' ? $get(store, 'data.real_amount') : $get(store, 'data.amount') | currencyFormat }}</span>
            </div>
          </div>
        </div>
      </div>

      <div v-if="status === 'pending'" class="paid-wrap">
        <div class="paid-box" @click="handleShowPaymentMethods">
          <div class="info-title">{{ $t('paymentMethod') }}</div>
          <div class="info-content flex item-center">
            <div class="payment-method flex item-center">
              {{ $t($get(store, 'params.payment_method')) }}
              <img class="icon" src="@/assets/icon-arrow-right-black.png" />
            </div>
          </div>
        </div>
        <div class="pay-tip">{{ $t('payTip') }}</div>
      </div>

      <div class="action-wrap">
        <div v-if="status === 'pending'" class="primary-btn" @click="handlePayment">{{ $t('confirmPay') }}</div>
        <div class="inquiry-btn flex content-center" @click="handleInquiry">{{ $t('inquiry') }} &gt;</div>
        <div v-if="status === 'pending'" class="hint-wrap">
          <div v-if="$get(settingsStore, 'settings.large_payment_hint')" class="hint-box">
            <span v-html="$get(settingsStore, 'settings.large_payment_hint')"></span>
          </div>
        </div>
      </div>
    </div>

    <!-- 地址弹窗 -->
    <el-dialog
      class="dialog-change-address"
      :visible.sync="showChangeAddress"
      :width="$isMobile() ? '96%' : '46rem'"
      center
    >
      <div slot="title">{{ $t('changeAddress') }}</div>
      <div class="change-address">
        <div class="input-content">
          <address-group :address="currentEditOrderAddress" :errorList="errorList" requireName/>
        </div>
      </div>
      <div class="btn-wrapper">
        <div @click="handleEditAddress" class="btn-confirm primary-btn">{{ $t('withdrawConfirm') }}</div>
      </div>
    </el-dialog>

    <!-- 选择非在线支付弹窗 -->
    <el-dialog
      class="dialog-page-order-detail"
      :visible.sync="dialogVisible"
      width="24.16667rem"
      center
    >
      <div class="contact-info" v-html="customPopContent.content"></div>
      <div slot="footer">
        <el-button class="primary-btn" style="font-size: 1.5rem" @click="handleContact">{{ $t('contactBtn') }}</el-button>
      </div>
    </el-dialog>

    <!-- 货币是日元，货币转换类型为日币，等待支付时，显示线上支付 -->
    <payment-methods
      v-model="showPaymentMethods"
      :current="$get(store, 'params.payment_method')"
      :showOfflinePaymentItems="kind !== 'logistic_kind'"
      :showOnlinePaymentItems="showOnlinePaymentItems"
      :isPayDollar="isPayDollar"
      @confirm="handleConfirmMethod"
      :directPay="directPay"
    />

    <!-- 选择是否抵扣金额弹窗 -->
    <el-dialog
      class="dialog-page-order-detail"
      :visible.sync="showConfirmDeduction"
      width="24.16667rem"
      center
    >
      <div>{{ $get(store, 'data.margin_amount') ? $t('deductMore') : $t('deductWallet') }}</div>
      <div slot="footer">
        <div class="flex">
          <el-button class="submit-btn primary-btn primary" @click="handleShowPaymentDeduction">{{ $t('yes') }}</el-button>
          <el-button class="submit-btn primary-btn primary" @click="handleDirectPay">{{ $t('no') }}</el-button>
        </div>
      </div>
    </el-dialog>

    <!-- 抵扣价格弹窗 -->
    <payment-deduction
      v-model="showPaymentDeduction"
      :detail="$get(store, 'data')"
      :current="$get(store, 'params.margin_amount')"
      @confirm="handleConfirmDiscount"
    />

  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';
  import { authStore, OrderDetailStore, paymentMethodsStore, transportCompaniesStore, settingsStore } from '@/store';
  import AddressGroup from '@/components/address-group.vue';
  import BatchOrderItem from './components/batch-order-item.vue';
  import _ from 'lodash';

  const statusMap = {
    pending: 'waitPay',
    paid: 'paid',
    cancelled: 'closed'
  };

  @Component({ components: { AddressGroup, BatchOrderItem } })
  export default class BatchDetail extends Vue {
    store = {}
    uniformAddress = {}
    currentEditOrderAddress = {}
    customPopContent = {}
    statusMap = statusMap

    showChangeAddress = false
    dialogVisible = false
    showPaymentMethods = false
    directPay = false
    showConfirmDeduction = false
    showPaymentDeduction = false
    errorList = {
      last_name: '',
      receiver_name: '',
      country_name: '',
      detail_info: '',
      province_name: '',
      postal_code: '',
      city_name: '',
      tel_number: ''
    }

    transportCompaniesStore = transportCompaniesStore
    settingsStore = settingsStore

    get status() {
      return this.$get(this.store, 'data.status');
    }

    get kind() {
      return this.$get(this.store, 'data.kind');
    }

    // 列表里的订单是否都为限时拍
    get isProductOrders() {
      return this.$get(this.store, 'data.orders', []).every(order => order.orderable_type === 'Product');
    }

    // 是否显示线上支付方式
    get showOnlinePaymentItems() {
      const { currency, orders = [] } = this.store.data || {};

      // 港币商品订单才能使用 PayDollar 支付平台
      if (this.isPayDollar) {
        return true;
      }

      // 其他照旧
      return currency === 'jpy' || orders.every(order => order.exchange_rate_type === 'to_jpy');
    }

    // 港币订单才能使用 PayDollar 支付平台
    get isPayDollar() {
      const { currency, orders = [] } = this.store.data || {};

      return currency === 'hkd' && orders.every(order => order.exchange_rate_type !== 'to_jpy');
    }

    @Vue.autoLoading
    async created() {
      // 没完善个人信息的直接跳去设置页
      await authStore.tryFetchData();
      if (!authStore.user.region) {
        await this.$alert(this.$t('personalInfoHint'), { showClose: false });
        this.$router.push({ name: 'mySettings' });
        return;
      }

      const { addressId, status } = this.$route.query;

      await Promise.all([
        this.fetchData(),
        transportCompaniesStore.tryFetchData(),
        settingsStore.tryFetchData()
      ]);

      const { address_info } = this.$get(this.store, 'data');
      if (addressId) {
        const { data: address } = await this.$fly.get(`addresses/${addressId}`);
        this.uniformAddress = this.getAddressFormat(address);
      } else if (_.isEmpty(address_info)) {
        const { data: address } = await this.$fly.get('addresses', { is_default_eq: true });
        if (address.length !== 0) {
          this.uniformAddress = this.getAddressFormat(address[0]);
        } else {
          await this.$alert(this.$t('noDefaultAddressTip'));
          this.$router.push({ name: 'myAddressList' });
          return;
        }
      } else {
        this.uniformAddress = this.getAddressFormat(address_info);
      }

      await paymentMethodsStore.fetchData({
        currency: this.$get(this.store, 'data.currency'),
        region: authStore.user.region,
        type: 'batch_settlement',
        id: this.$route.params.id
      });

      // 判断支付状态
      switch (status) {
        case 'captured':
          this.$message({ type: 'success', message: this.$t('paySuccess') });
          break;
        case 'cancelled':
          this.$message({ type: 'error', message: this.$t('payFailure') });
          break;
      }
      if (status) {
        return this.$router.replace({ query: {} }).catch(err => err);
      }
    }

    async fetchData() {
      const { id } = this.$route.params;
      const { sleep } = this.$route.query;
      if (sleep) {
        await this.$sleep(1000);
      }
      this.store = new OrderDetailStore(id, 'batch_settlements');
      return this.store.fetchData();
    }

    getAddressFormat(address) {
      const { country_name = '', province_name = '', city_name = '', detail_info = '', additional_detail_info = '', last_name = '', receiver_name = '', postal_code = '', tel_number = '' } = address;
      return {
        full: address,
        contactInfo: [last_name, receiver_name, postal_code, tel_number].join(' '),
        deliveryInfo: [country_name, province_name, city_name, detail_info, additional_detail_info].join(' ')
      };
    }

    handleOpenAddressPopup(order) {
      this.currentEditOrderAddress = { ..._.cloneDeep(order.address_info), order_id: order.id };
      this.showChangeAddress = true;
    }

    async handleEditAddress() {
      let errorNames = Object.keys(this.errorList);
      await new Promise((resolve, reject) => {
        for (let errorName of errorNames) {
          if (!this.currentEditOrderAddress[errorName]) {
            this.errorList[errorName] = this.$t(errorName);
            reject();
          }
        }
        resolve();
      });

      this.$get(this.store, 'data.orders').forEach(order => {
        if (order.id === this.currentEditOrderAddress.order_id) {
          order.address_info = this.currentEditOrderAddress;
        }
      });
      this.showChangeAddress = false;
    }

    async handlePayment() {
      // 如果没有上传任何证件，没有证件有效期，提示用户填写后再支付
      const { id_card_no, passport_no, driving_license_no } = this.$authStore.user;
      if (!id_card_no && !passport_no && !driving_license_no) {
        await this.$confirm(this.$t('uploadTip'));
        return this.$router.push({ name: 'myAddress' });
      }
      if (this.$authStore.isLoadEffectiveDate) {
        await this.$confirm(this.$t('uploadValidityPeriod'));
        return this.$router.push({ name: 'myAddress' });
      }

      // 校验每个子订单是否在运输下选择了运输公司
      this.$get(this.store, 'data.orders')
        .filter(order => order.orderable_type !== 'Product').every(order => {
        if (order.isSelfPick === false && !(order.transport_company_id || order.logistic_fee)) {
          this.$message.error(this.$t('noSelectAllTransport'));
          throw new Error(this.$t('noSelectAllTransport'));
        }
      });

      this.store.updateParams({
        address_info: this.uniformAddress.full,
        orders: this.$get(this.store, 'data.orders').map(order => ({ ...order, ...{ is_self_pick: order.isSelfPick } }))
      });
      if (this.$get(this.store, 'params.payment_method')) {
        await this.$autoLoading(this.store.updateOrder());
      }

      // 支付订单
      switch (true) {
        case !this.$get(this.store, 'params.payment_method'): {
          this.directPay = true;
          this.showPaymentMethods = true;
          return;
        }
        case !paymentMethodsStore.data.includes(this.$get(this.store, 'params.payment_method')): {
          this.$message({ type: 'warning', message: this.$t('reSelectPay') });
          return;
        }
        case this.$get(this.store, 'params.payment_method') === 'OfflinePayment': {
          const { data } = await this.$fly.get('pages/offline_payment_info');
          this.customPopContent = data;
          this.dialogVisible = true;
          return;
        }
        case this.$get(this.store, 'params.payment_method') === 'RemittancePayment': {
          const { data } = await this.$fly.get('pages/remittance_payment_info');
          this.customPopContent = data;
          this.dialogVisible = true;
          return;
        }
        case this.store.isOnlinePayment && this.$get(this.store, 'data.max_margin_amount') > 0 && this.kind === 'product_kind': {
          this.showConfirmDeduction = true;
          return;
        }
        case this.store.isOnlinePayment: {
          return this.$autoLoading(this.store.paymentOrder());
        }
      }
    }

    handleConfirmMethod(method, directPay) {
      this.store.updateParams({
        payment_method: method
      });
      if (directPay) {
        this.handlePayment();
      }
    }

    handleShowPaymentDeduction() {
      this.showPaymentDeduction = true;
      this.showConfirmDeduction = false;
    }

    handleDirectPay() {
      this.$autoLoading(this.store.paymentOrder());
      this.showConfirmDeduction = false;
    }

    // 确认抵扣更多
    async handleConfirmDiscount(amount) {
      this.showPaymentDeduction = false;
      this.store.updateParams({
        margin_amount: amount
      });
      this.$autoLoading(async () => {
        await this.store.updateOrder();
        await this.store.paymentOrder();
      });
    }

    async handleShowPaymentMethods() {
      if (!authStore.user.region) {
        await this.$alert(this.$t('fillInUserRegion'));
        this.$router.push({ name: 'mySettings' });
      } else if (this.status === 'pending') {
        this.showPaymentMethods = true;
      }
    }

    @Vue.autoLoading
    async handleInquiry() {
      const { kind, transport_company } = this.store.data;
      let chanelData = {};

      // logistic_kind -> 联系物流(订单可能没有关联运输公司, 此时订单应该联系平台管理员)
      // product_kind -> 联系平台管理员
      if (kind === 'logistic_kind' && transport_company) {
        const { data } = await this.$fly.post('message_channels/contact_service', {
          target_type: 'BatchSettlement',
          target_id: this.$get(this.store, 'data.id'),
          communicator_type: 'TransportCompany',
          communicator_id: this.$get(this.store, 'data.transport_company.id')
        });
        chanelData = data;
      } else {
        const { data } = await this.$fly.post('message_channels/get_by_id_or_target', {
          target_type: 'BatchSettlement',
          target_id: this.$get(this.store, 'data.id')
        });
        chanelData = data;
      }
      this.$router.push({ name: 'messageChannelDetail', params: { id: chanelData.id } });
    }

    @Vue.autoLoading
    async handleContact() {
      const { data } = await this.$fly.post('message_channels/get_by_id_or_target', {
        target_type: 'BatchSettlement',
        target_id: this.$get(this.store, 'data.id')
      });
      this.$router.push({ name: 'messageChannelDetail', params: { id: data.id } });
    }
  }
</script>

<style lang="scss" scoped>
  .title-wrap {
    margin: 30px 0 24px;

    @include media-xs-only {
      padding: 0 15px;
      margin: 28px 0 20px;
    }

    .title {
      font-weight: bolder;
      font-size: 18px;
      color: #000;
    }

    .count {
      margin-left: 16px;
      color: #717171;
    }
  }

  .info-title {
    font-weight: bold;
    font-size: 16px;
  }

  .delivery-wrap {
    padding: 20px 0;
    border: 1px solid #ddd;
    border-right: none;
    border-left: none;

    .content-box {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include media-xs-only {
        flex-direction: column;
        align-items: flex-start;

        .tip-box {
          margin-top: 5px;
        }
      }
    }

    .tip-box {
      padding: 14px 15px;
      border: 1px solid #dddddd;
    }
  }

  .info-wrap {
    margin-top: 15px;

    .contact-box {
      margin-bottom: 10px;
      font-weight: bold;
      font-size: 16px;
      color: #000;
    }

    .address-box {
      font-size: 14px;
      color: #666;
    }

    .icon {
      width: 15px;
      height: 15px;
      margin-left: 20px;
    }
  }

  .orders-wrap {
    padding: 25px 0;

    /deep/.batch-order-item {
      & + .batch-order-item {
        margin-top: 20px;
      }
    }
  }

  .total-wrap {
    padding: 20px 0;
    border: 1px solid #ddd;
    border-right: none;
    border-left: none;

    .price-box {
      & + .price-box {
        margin-top: 10px;
      }
    }
  }

  .paid-wrap {
    padding: 20px 0;

    .paid-box {
      display: flex;
      justify-content: space-between;
      align-content: center;
      cursor: pointer;
    }

    .info-content {
      color: #666;

      .icon-payment {
        width: 20px;
        height: 20px;
        padding-right: 10px;
      }

      .icon {
        width: 12px;
        height: 12px;
        margin-left: 6px;
      }
    }

    .pay-tip {
      margin-top: 10px;
      font-size: 12px;
      color: $primary;
    }
  }

  .action-wrap {
    padding: 20px 0;

    .primary-btn {
      background: $primary;
    }

    .inquiry-btn {
      margin-top: 17px;
      color: #666;
      cursor: pointer;
    }

    .hint-wrap {
      display: flex;
      justify-content: center;
      margin-top: 20px;

      .hint-box {
        padding: 10px 20px;
        color: #000;
        background: rgba(192, 192, 192, 0.2);
      }
    }
  }

  .currency {
    text-transform: uppercase;
  }

  .price {
    margin-left: 5px;
    color: $primary;
  }

  /deep/ .dialog-change-address {
    border-radius: 19px;

    .el-dialog {
      border-radius: 19px;
    }

    .el-dialog__header {
      padding: 25px 0 15px;
      font-weight: 500;
      font-size: 16px;
    }

    .el-dialog__body {
      padding: 0 40px 20px;

      @include media-xs-only {
        padding: 0 10px 10px;
      }

      .set-default {
        margin-top: 20px;
        cursor: pointer;
      }

      .btn-wrapper {
        margin: 16px;

        @include media-xs-only {
          margin: 20px 12px 10px;
        }

        .btn-confirm {
          height: 38px;
          font-size: 15px;
          background: $primary;
        }
      }

      .remove {
        margin-bottom: 10px;
        cursor: pointer;
      }
    }
  }

    /deep/ .dialog-page-order-detail {
      display: flex;
      justify-content: center;
      align-items: center;

      .el-dialog {
        border-radius: 19px;

        @include media-sm-and-up {
          width: 352px !important;
          margin-top: 15vh !important;
        }

        .el-dialog__body {
          padding-bottom: 16px;
          text-align: center;
        }

        .el-dialog__footer {
          padding-bottom: 37px;

          .primary-btn {
            height: 45px;
            font-size: 16px !important;

            @include media-sm-and-up {
              height: 38px;
              font-size: 15px !important;
            }
          }
        }
      }
    }
</style>
