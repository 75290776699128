import { Collection } from './collection';
import { fly } from '@/utils';
import _ from 'lodash';
import router from '@/router';

class AuctionHouseStore extends Collection {
  domainHouse = {}
  domainHouseDetail = {}
  liveBroadcasts = []

  fetch(params) {
    return fly.get('auction_houses', { simple_list: true, ...params, per_page: 999 });
  }

  async check(routeTo = {}) {
    await this.tryFetchData();
    const { fullPath, meta } = _.get(router.resolve(routeTo), 'resolved') || {};
    this.domainHouse = this.data.find(item => {
      const domain = _.get(item, 'domain') && `${location.origin}${fullPath}`.includes(_.get(item, 'domain'));
      const wechatMpDomain = _.get(item, 'wechat_mp_domain') && `${location.origin}${fullPath}`.includes(_.get(item, 'wechat_mp_domain'));
      return domain || wechatMpDomain;
    });
    if (_.get(this.domainHouse, 'id')) {
      document.querySelector('meta[name="apple-mobile-web-app-title"]').content = this.domainHouse.name;
      const domainHouseAbilities = _.get(meta, 'domainHouseAbilities', []);
      // 判断拍卖行子域名是否能查看当前页面
      const canShowCurrentPage = this.domainHouse.can_manage_auction && domainHouseAbilities.includes('auction') ||
      this.domainHouse.can_manage_store && domainHouseAbilities.includes('store');
      if (!canShowCurrentPage) {
        return false;
      }
      // 不需要重复请求
      if (!_.get(this.domainHouseDetail, 'id')) {
        const { id } = this.domainHouse;
        const [{ data: detail }, { data: liveList }] = await Promise.all([
          fly.get(`auction_houses/${id}`, { auctions_count: 99 }),
          fly.get('live_broadcasts'),
        ]);
        this.domainHouseDetail = detail;
        this.liveBroadcasts = liveList;
      }
    }
    return true;
  }
}

export const auctionHouseStore = new AuctionHouseStore();
