import { SimpleStore } from './simple-store';
import { fly } from '@/utils';

class PaymentMethodsStore extends SimpleStore {
  data = []

  get onlinePaymentMethods() {
    return this.data.filter(item => item !== 'OfflinePayment' && item !== 'RemittancePayment');
  }

  // 是否有线下支付
  get hasOfflinePayment() {
    return this.data.includes('OfflinePayment');
  }

  // 是否有汇款支付
  get hasRemittancePayment() {
    return this.data.includes('RemittancePayment');
  }

  async fetchData({ currency = 'jpy', region, type, id }) {
    if (region) {
      const { data } = await this.fetching(fly.get('settings/payment_methods', { currency, region, type, id }));
      this.data = data;
    }
  }
}

export const paymentMethodsStore = new PaymentMethodsStore();
