import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

function getImage(html) {
  return new Promise((resolve, reject) => {
    html2canvas(html, {
      allowTaint: true,
      useCORS: true,
      scale: 2
    }).then(function (canvas) {
      const url = canvas.toDataURL('image/jpeg');
      const image = new Image();
      image.src = url;
      resolve(image);
    }).catch(reject);
  });
}

export function getPdf(eles, fileName) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const imgs = [];
    let imgWidth = 0;
    for (let i = 0; i < eles.length; i++) {
      const ele = eles[i];
      const img = await getImage(ele);
      imgs.push(img);
      imgWidth = img.width;
    }
    const meta = document.querySelector('meta[name="viewport"]');
    const metaContent = meta.content;
    meta.setAttribute('content', 'width=1920px,initial-scale=1.0,user-scalable=no');
    const pdfContainer = document.createElement('div');
    const doc = new jsPDF();
    const pWidth = doc.internal.pageSize.width;
    const pHeight = doc.internal.pageSize.height;
    pdfContainer.style.width = '1920px';
    const margin = 5;
    imgs.forEach((img, index) => {
      const div = document.createElement('div');
      div.appendChild(img);
      if (index !== imgs.length - 1) {
        const h = img.width / ((pWidth - margin * 2) / pHeight);
        div.style.height = `${h}px`;
      }
      pdfContainer.appendChild(div);
    });
    const scale = (pWidth - margin * 2) / imgWidth;
    doc.html(pdfContainer, {
      image: { type: 'jpeg', quality: 1 },
      html2canvas: {
        scale,
        windowWidth: 1920,
      },
      callback: (doc) => {
        meta.setAttribute('content', metaContent);
        doc.save(`${fileName}.pdf`, { returnPromise: true })
          .then(resolve)
          .catch(reject);
      },
      x: margin,
      y: margin
    });
  });
}
