<i18n src="../locales.json"></i18n>

<template>
  <div class="batch-order-item">
    <div class="header">
      <div v-if="order.orderable_type === 'AuctionItem' && kind === 'product_kind' && status === 'pending' && order.is_self_pick === null && !order.logistic_fee" class="logistics-way-box">
        <div
          v-for="way in ['self', 'transport']"
          :key="way"
          class="way"
          :class="{ active: way === 'self' ? order.isSelfPick : !order.isSelfPick }"
          @click="handleToggleDeliveryWay(way)"
        >
            {{ $t(way) }}
        </div>
      </div>
      <div v-else style="color: #bc4353;">{{ order.is_self_pick ? $t('self') : $t('transport') }}</div>

      <div class="address-box flex item-center">
        <template v-if="order.isSelfPick">
          <div class="item">{{ $t('self') }}</div>
        </template>
        <template v-else>
          <template v-if="isChangeToOtherAddress">
            <div class="item">{{ $t('unifiedAddress') }}</div>
            <div
              v-if="kind === 'product_kind' && status === 'pending' && order.orderable_type === 'AuctionItem'"
              class="item red-btn"
              @click="$emit('openAddressPopup', order)"
            >
              {{ $t('change') }}
            </div>
          </template>
          <template v-else>
            <div class="item">{{ $t('otherAddress') }}</div>
            <div
              v-if="kind === 'product_kind' && status === 'pending' && order.orderable_type === 'AuctionItem'"
              class="item gray-btn"
              @click="order.address_info = uniformAddress.full"
            >
              {{ $t('setUnifiedAddress') }}
            </div>
          </template>
        </template>
      </div>
    </div>
    <div class="body">
      <order-item v-if="order" :order="order">
        <!-- 费用信息 -->
        <template v-slot:right-content>
          <div class="price-wrap">
            <template v-if="kind === 'product_kind'">
              <div class="prices-box">
                <!-- 拍品总额 -->
                <div class="price-box">
                  <span>{{ $t('totalPrice') }}：</span>
                  <div>
                    <span class="currency">{{ order.currency }}</span> <span class="price">{{ order.amount | currencyFormat }}</span>
                  </div>
                </div>
                <!-- 佣金 -->
                <div v-if="auctionOrder && order.commission_amount" class="price-box">
                  <span>{{ $t('commissionAmount') }}：</span>
                  <div>
                    <span class="currency">{{ order.currency }}</span> <span class="price">{{ order.commission_amount | currencyFormat }}</span></div>
                </div>
                <!-- 税费 -->
                <div v-if="order.tax_amount" class="price-box">
                  <span>{{ $t('taxAmount') }}：</span>
                  <div>
                    <span class="currency">{{ order.currency }}</span> <span class="price">{{ order.tax_amount | currencyFormat }}</span>
                  </div>
                </div>
                <!-- 运费 -->
                <div v-if="order.logistic_fee && order.orderable_type === 'Product'" class="price-box">
                  <span>{{ auctionOrder ? $t('shipping') : $t('productShipping') }}：</span>
                  <div>
                    <span class="currency">{{ $get(order, 'orderable_type') === 'Product' ? order.currency : paymentCurrency }}</span>
                    <span class="price">{{ order.logistic_fee | currencyFormat }}</span>
                  </div>
                </div>
                <!-- 抵扣额度 -->
                <div v-if="order.payment_amount && order.margin_amount" class="price-box">
                  <span>{{ $t('deductionPrice') }}:</span>
                  <div>
                    <span class="currency">- {{ paymentCurrency }}</span>
                    <span class="price">{{ order.margin_amount | currencyFormat }}</span>
                    <span v-if="order.currency !== 'jpy' && order.exchange_rate_type === 'to_current_currency'">
                      (≈ -
                        <span class="currency">{{ order.currency }} </span>
                        <span class="price">{{ order.real_margin_amount | currencyFormat }}</span>
                      )
                    </span>
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <!-- 运费 -->
              <div class="price-box">
                <span>{{ auctionOrder ? $t('shipping') : $t('productShipping') }}：</span>
                <div>
                  <span class="currency">{{ $get(order, 'orderable_type') === 'Product' ? order.currency : paymentCurrency }}</span>
                  <span class="price">{{ order.logistic_fee | currencyFormat }}</span>
                </div>
              </div>
            </template>
          </div>
        </template>
      </order-item>
    </div>
    <div class="footer">
      <!-- 选择物流公司 -->
      <template v-if="order.isSelfPick === false && !(order.logistic_fee || $get(order, 'transport_company.id'))">
        <div v-if="order.orderable_type !== 'Product'" class="transport-wrap">
          <template v-if="kind === 'product_kind' && status === 'pending'">
            <div class="transport-title">{{ $t('selectShipping') }}</div>
            <div class="transport-selectors-box">
              <div
                class="select-item flex item-center"
                :class="{ disabled: status !== 'pending' || kind !== 'product_kind' }"
                v-for="transport in transportCompaniesStore.data"
                :key="transport.id"
                @click="order.transport_company_id = transport.id"
              >
                <div :class="['selector', { selected: transport.id === order.transport_company_id }]"></div>
                <label class="content">{{ transport.name }}</label>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="transport-title">{{ $t('logisticsCompany') }}</div>
            <router-link :to="{ name: 'transportCompanies', params: { id: $get(order, 'transport_company.id') } }">
              <div class="transport-detail">
                <div class="flex item-center content-between">
                  <div class="flex item-center">
                    <img class="transport-detail-cover" :src="$get(order, 'transport_company.logo.url')" />
                    <div class="transport-detail-content">
                      <div class="transport-detail-name">{{ $get(order, 'transport_company.name') }}</div>
                      <div class="transport-detail-desc">{{ $get(order, 'transport_company.desc') }}</div>
                    </div>
                  </div>
                  <img class="icon-arrow-right" src="@/assets/icon-arrow-right-black.png" />
                </div>
              </div>
            </router-link>
          </template>
        </div>
      </template>

      <!-- 地址 -->
      <div
        v-if="!isChangeToOtherAddress && order.isSelfPick === false"
        @click="$emit('openAddressPopup', order)"
        class="info-wrap flex item-center content-between"
        :class="{ disabled: kind !== 'product_kind' || order.orderable_type !== 'AuctionItem' || status !== 'pending' }"
      >
        <div class="flex-1">
          <div class="contact-box">{{ $get(addressFormat, 'contactInfo') }}</div>
          <div class="address-box">{{ $t('deliveryInfo') }} {{ $get(addressFormat, 'deliveryInfo') }}</div>
        </div>
        <img v-if="kind === 'product_kind' && order.orderable_type === 'AuctionItem' && status === 'pending'" class="icon" src="@/assets/icon-arrow-right-black.png" />
      </div>

      <div class="total-price-box">
        <div>
          {{ $t('orderItemTotalPrice') }}：
          <template v-if="kind === 'product_kind'">
            <span class="currency"><span class="currency">{{ order.currency }}</span> <span class="price">{{ order.real_payment_amount | currencyFormat }}</span></span>
            <span v-if="isExchangeCurrency"> (≈ <span class="currency">{{ order.quota_currency }}</span><span class="price">{{ order.payment_amount_to_quota_currency | currencyFormat }}</span>)</span>
          </template>
          <template v-else>
            <span class="currency">{{ order.quota_currency.toUpperCase() }}</span>
            <span class="price">{{ order.logistic_fee | currencyFormat }}</span>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { Vue, Component, Prop } from 'vue-property-decorator';
  import _ from 'lodash';
  import { transportCompaniesStore } from '@/store';
  import { CURRENCY_MAP } from '@/constants';

  @Component
  export default class BatchOrderItem extends Vue {
    @Prop({ type: Object, default: () => ({}) }) order
    @Prop({ type: Object, default: () => ({}) }) uniformAddress
    @Prop(String) kind
    @Prop(String) status

    transportCompaniesStore = transportCompaniesStore

    // 拍品订单
    get auctionOrder() {
      return this.$get(this.order, 'orderable_type') === 'AuctionItem';
    }

    get isChangeToOtherAddress() {
      const address = this.$get(this.order, 'address_info');
      return _.isEqual(_.pick(this.uniformAddress.full, Object.keys(address)), address);
    }

    get addressFormat() {
      const address = this.$get(this.order, 'address_info');
      const { country_name = '', province_name = '', city_name = '', detail_info = '', additional_detail_info = '', last_name = '', receiver_name = '', postal_code = '', tel_number = '' } = address;
      return {
        full: address,
        contactInfo: [last_name, receiver_name, postal_code, tel_number].join(' '),
        deliveryInfo: [country_name, province_name, city_name, detail_info, additional_detail_info].join(' ')
      };
    }

    get currency() {
      if (this.$get(this.order, 'exchange_rate_type') === 'to_jpy') {
        return 'JPY';
      } else {
        return this.$get(this.order, 'currency');
      }
    }

    get paymentCurrency() {
      return CURRENCY_MAP.includes(this.currency) ? this.currency : 'jpy';
    }

    get isExchangeCurrency() {
      return !CURRENCY_MAP.includes(this.$get(this.order, 'currency'));
    }

    created() {
      this.$set(this.order, 'isSelfPick', this.order.is_self_pick === null ? false : this.order.is_self_pick);
    }

    handleToggleDeliveryWay(way) {
      way === 'self' ? this.order.isSelfPick = true : this.order.isSelfPick = false;
    }
  }
</script>

<style lang="scss" scoped>
  .batch-order-item {
    padding: 0 33px 0 16px;
    background: #ffffff;
    box-shadow: 0px 4px 18px 2px rgba(4, 0, 0, 0.1);

    @include media-xs-only {
      padding: 0 15px;
    }

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px 0;
      border-bottom: 1px solid #d8d8d8;
      font-size: 14px;

      .logistics-way-box {
        display: flex;
        align-items: center;
        padding: 8px 18px;
        border: 1px solid #dddddd;
        border-radius: 17px;

        .way {
          color: #666;
          cursor: pointer;

          &.active {
            color: $primary;
            pointer-events: none;
          }

          & + .way::before {
            padding: 0 9px;
            color: #666;
            content: '|';
          }
        }
      }

      .address-box {
      @include media-xs-only {
        flex-direction: column;

        .red-btn,
        .gray-btn {
          margin-top: 5px;
        }
      }

        .item {
          & + .item {
            margin-left: 15px;
          }
        }

        .red-btn,
        .gray-btn {
          padding: 5px 15px;
          border-radius: 15px;
          cursor: pointer;
        }

        .red-btn {
          border: 1px solid $primary;
          color: $primary;
        }

        .gray-btn {
          border: 1px solid #848388;
          color: #848388;
        }
      }
    }

    .footer {
      margin-left: calc(110px + 20px); // 110px: cover-content宽；20px：content-wrapper的margin-left
      border-top: 1px solid #d8d8d8;

      @include media-xs-only {
        margin-left: 0;
      }

      .transport-wrap {
        padding: 10px 0;
        border-bottom: 1px solid #d8d8d8;

        .transport-title {
          margin-bottom: 10px;
          font-weight: bold;
          font-size: 16px;
          color: #000;
        }

        .no-transport-tip {
          color: #666;
        }

        .select-item {
          cursor: pointer;

          &.disabled {
            pointer-events: none;
          }

          & + .select-item {
            margin-top: 10px;
          }

          .selector {
            position: relative;
            width: 16px;
            height: 16px;
            border: 1px solid #ccc;
            border-radius: 50%;

            &.selected::after {
              position: absolute;
              top: 50%;
              left: 50%;
              display: block;
              width: 8px;
              height: 8px;
              border-radius: 50%;
              background: #000;
              transform: translate(-50%, -50%);
              content: '';
            }
          }

          .content {
            margin-left: 17px;
            font-size: 13px;
          }
        }

        .transport-detail {
          margin-top: 14px;

          .transport-detail-cover {
            width: 75px;
            height: 75px;
            border-radius: 50%;
            box-shadow: 0px 4px 18px 2px rgba(4, 0, 0, 0.1);
            object-fit: cover;
          }

          .transport-detail-content {
            margin: 0 37px 0 19px;

            .transport-detail-name {
              color: #000;
            }

            .transport-detail-desc {
              margin-top: 6px;
              font-size: 12px;
              line-height: 16px;
              color: #999;

              @include text-overflow(3);
            }
          }

          .icon-arrow-right {
            width: 12px;
            height: 12px;
          }
        }
      }

      .info-wrap {
        padding: 10px 0;
        margin-top: 0;
        border-bottom: 1px solid #d8d8d8;
        cursor: pointer;
      }

      .total-price-box {
        display: flex;
        justify-content: flex-end;
        padding: 10px 0;
      }
    }

    .prices-box {
      padding-bottom: 10px;

      & + .prices-box {
        border-top: 1px solid #ccc;
      }

      &:not(:first-child) {
        padding-top: 10px;
      }
    }

    .price-box {
      display: flex;
      justify-content: space-between;

      & + .price-box {
        margin-top: 10px;
      }
    }

    /deep/.component-order-item {
      @include media-xs-only {
        .info-wrap {
          flex-direction: column;
        }

        .prices-box {
          padding: 10px 0;
        }
      }
    }
  }

  .info-wrap {
    margin-top: 15px;

    .contact-box {
      margin-bottom: 10px;
      font-weight: bold;
      font-size: 16px;
      color: #000;
    }

    .address-box {
      font-size: 14px;
      color: #666;
    }

    .icon {
      width: 15px;
      height: 15px;
      margin-left: 20px;
    }
  }

  .currency {
    text-transform: uppercase;
  }

  .price {
    margin-left: 5px;
    color: $primary;
  }

  .disabled {
    pointer-events: none;
  }
</style>
