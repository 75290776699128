<template>
  <div class="component-auction-house-card" @click="handleNavDetail">
    <div class="main">
      <div class="cover">
        <auto-height height="100%" :cover="$get(auctionHouse, 'logo.url')" />
      </div>
      <div class="content">
        <div class="name">{{ auctionHouse.name }}</div>
        <div class="desc" v-html="auctionHouse.intro"></div>
      </div>
    </div>
    <img class="icon-arrow-right" src="@/assets/icon-arrow-right-black.png" />
  </div>
</template>

<script>
  import { Vue, Component, Prop } from 'vue-property-decorator';

  @Component
  export default class AuctionHouseCard extends Vue {
    @Prop({ type: Object, default: () => ({}) }) auctionHouse

    handleNavDetail() {
      this.$router.push({ path: `/shop_list/${this.auctionHouse.id}` });
    }
  }
</script>

<style lang="scss" scoped>
  .component-auction-house-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    user-select: none;
    cursor: pointer;

    .main {
      display: flex;
      align-items: center;
      margin-right: 35px;

      .cover {
        flex-shrink: 0;
        width: 75px;

        @include media-sm-and-up {
          width: 94px;
        }

        /deep/ .auto-height-content {
          border-radius: 50%;

          .auto-height-cover {
            border-radius: 50%;
            box-shadow: 0px 4px 18px 2px rgba(4, 0, 0, 0.1);
          }
        }
      }

      .content {
        margin-left: 18px;

        @include media-sm-and-up {
          margin: 0 24px;
        }

        .name {
          font-weight: 500;

          @include media-sm-and-up {
            font-size: 18px;
          }
        }

        .desc {
          margin-top: 10px;
          font-size: 12px;
          color: #999;

          @include text-overflow(3);

          @include media-sm-and-up {
            margin-top: 16px;
            font-size: 15px;

            @include text-overflow(2);
          }
        }
      }
    }

    .icon-arrow-right {
      width: 12px;
      height: 12px;
    }
  }
</style>
