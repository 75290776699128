export function setMeta(name, content, nameKey = 'name') {
  const meta = document.querySelector(`meta[${nameKey}="${name}"]`);
  if (meta) {
    meta.content = content;
  } else {
    const newM = document.createElement('meta');
    newM.setAttribute(nameKey, name);
    newM.setAttribute('content', content);
    document.head.appendChild(newM);
  }
}
