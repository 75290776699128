<template>
  <div class="collapsible-rich-text-component">
    <div  class="rich-text-wrapper" :style="{ maxHeight }" ref="richTextWrap">
      <div class="desc-rich-text" v-html="content" ref="richText"></div>
      <div class="mask" v-show="hasShowAllBtn && !isSpread"></div>
    </div>
    <div class="flex content-center">
      <div class="show-all flex item-center"
        v-show="hasShowAllBtn"
        @click="toggleMaxHeight"
      >
        <span class="text">{{ isSpread ? $t('close') : $t('open') }}</span>
        <i :class="['el-icon-arrow-down', { active: isSpread }]" />
      </div>
    </div>

  </div>
</template>

<script>
  import { Vue, Component, Prop } from 'vue-property-decorator';

  @Component
  export default class collapsibleRichText extends Vue {
    @Prop(String) content
    @Prop({ type: String, default: '160' }) richHeight

    maxHeight = ''
    hasShowAllBtn = false

    // 是否展开
    get isSpread() {
      return this.maxHeight === 'initial';
    }

    created() {
      this.maxHeight = this.richHeight + 'px';
    }

    mounted() {
      this.$nextTick(async () => {
        const { richTextWrap, richText } = this.$refs;
        if (richTextWrap && richText) {
          // 延迟1s获取富文本高度，避免有图片加载慢导致判断高度错误
          await this.$sleep(1000);

          const { height: richTextWrapHeight } = richTextWrap.getBoundingClientRect();
          const { height: richTextHeight } = richText.getBoundingClientRect();

          this.hasShowAllBtn = richTextHeight > richTextWrapHeight;
        }
      });
    }

    toggleMaxHeight() {
      this.maxHeight = (this.maxHeight === 'initial' ?  `${this.richHeight}px` : 'initial');
    }
  }
</script>

<style lang="scss" scoped>
  .rich-text-wrapper {
    position: relative;
    padding: 10px 0;
    overflow: hidden;
    transition: all .3s;

    .mask {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 120px;
      background-image: linear-gradient(rgba(255, 255, 255, 0), #fff);
    }
  }

  .show-all {
    display: inline-block;
    color: #666;
    cursor: pointer;

    .text {
      font-size: 12px;
    }

    .el-icon-arrow-down {
      margin-left: 6px;
      transition: all .3s;

      &.active {
        transform: rotate(-180deg);
      }
    }
  }
</style>
