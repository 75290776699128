<i18n src="./locales.json"></i18n>

<template>
  <div class="page-products">
    <router-link :to="{ name: 'productCategories' }">
      <div class="view-all-category header hidden-xs-only" v-if="type === 'product_category'">{{ $t('viewAllCategory') }}</div>
    </router-link>
    <div class="container">
      <v-title :value="titleText" />
      <common-title class="title" :title="titleText" />
      <div class="input-group flex item-center content-between">
        <el-input
          class="flex-1 search-input flex" type="search"
          v-model="keyword"
          :placeholder="$t('productSearch')"
          @keypress.enter.native="handleSearch"
        >
          <img src="@/assets/icon-search.png" slot="suffix" class="icon" @click="handleSearch" />
        </el-input>
        <div @click="showSortDialog = true" class="sort flex item-center">
          <img class="filter-icon" src="@/assets/icon-filter.png" alt="">
          <div class="text-overflow">{{ $t(currentSort) }}</div>
        </div>
      </div>
      <div class="list-wrapper">
        <el-row :gutter="8" justify="space-between">
          <el-col :xs="8" :sm="6" :md="4" v-for="item in productsStore.data" :key="item.id">
            <product-item class="component-product-item" :product="item" :productQuery="productQuery"/>
          </el-col>
        </el-row>
        <div class="product-list-empty" v-if="productsStore.isEmpty">
          <img src="@/assets/icon-select-empty.png" />
          <div>{{ $t('productListEmpty') }}</div>
        </div>
        <pagination routeMode class="pagination" :store="productsStore" />
      </div>
    </div>
    <el-dialog
      class="dialog-sort"
      :visible.sync="showSortDialog"
      :width="dialogWidth"
      :show-close="false"
      center
    >
      <div>
        <div class="header">{{ $t('sorting') }}</div>
        <div
          class="sort-item"
          :class="{ selected: currentSort === item.label }"
          v-for="item in sortItems"
          :key="item.value"
          @click="handleSelectedSort(item)"
        >
          {{ $t(item.label) }}
        </div>
      </div>
      <div class="btn-wrapper">
        <div class="btn-confirm primary-btn" @click="handleCancelSort">{{ $t('cancel') }}</div>
      </div>
    </el-dialog>
    <app-fixed-bottom class="hidden-sm-and-up" v-if="type === 'product_category'">
      <router-link :to="{ name: 'productCategories' }">
        <div class="view-all-category footer">{{ $t('viewAllCategory') }}</div>
      </router-link>
    </app-fixed-bottom>
  </div>
</template>

<script>
  import { Vue, Component, Watch } from 'vue-property-decorator';
  import { Collection, followProductIdsStore } from '@/store';
  import { initSubscription } from '@/utils';
  import _ from 'lodash';

  @Component
  export default class Products extends Vue {
    title = '';
    type = '';
    keyword = '';
    showSortDialog = false;
    currentSort = ''; // 当前排序
    followProductIdsStore = followProductIdsStore
    productQuery = { productType: 'product' }
    dialogWidth = ''
    screenWidth = document.body.clientWidth // 在小屏下弹出后，拉大浏览器弹窗宽度太大

    productsStore = new Collection({
      fetch: params => this.$fly.get('products', params)
    });

    sortItems = [
      { label: 'shortTime', value: 'remaining_time asc' },
      { label: 'longTime', value: 'remaining_time desc' },
      { label: 'priceAscending', value: 'price asc' },
      { label: 'priceDescending', value: 'price desc' },
      { label: 'bidAscending', value: 'product_bidding_prices_count asc' },
      { label: 'bidDescending', value: 'product_bidding_prices_count desc' },
      { label: 'onePriceAscending', value: 'confirmed_price asc' },
      { label: 'onePriceDescending', value: 'confirmed_price desc' },
    ];

    @Watch('screenWidth', { immediate: true })
    screenWidthChange() {
      this.$isMobile() ? this.dialogWidth = '90%' : this.dialogWidth = '360px';
    }

    get titleText() {
      const { will_end, in_progress, type, ended, title } = this.$route.query;
      switch (true) {
        case Boolean(will_end):
          return this.$t('willEndproductList');
        case Boolean(in_progress) && type === 'auction_houses':  // 单独的店铺里面最新的商品：限时拍拍品推荐
          return this.$t('productList');
        case Boolean(in_progress):  // 所有店铺里面最新商品： 新店铺上架商品
          return this.$t('isPendding');
        case Boolean(ended):
          return this.$t('endedProductList');
        case Boolean(title):
          return title;
        default:
          return this.title || this.$t('allProducts');
      }
    }

    @Vue.autoLoading
    async created() {
      const {
        page,
        type,
        id,
        custom_product_list_id_eq,
        product_category_id_eq,
        store_category_id_eq,
        auction_house_id_eq,
        will_end,
        in_progress,
        keyword,
        ended,
        sort,
        confirmed_price_not_null
      } = this.$route.query;
      const sortItem = this.sortItems.find(item => item.value === sort); // 找出sortItems中当前排序的对象
      this.currentSort = _.get(sortItem, 'label') || 'sorting';
      this.type = type;
      this.keyword = keyword;
      this.productsStore.params = {
        per_page: 24,
        page,
        will_end,
        in_progress,
        ended,
        sort,
        keyword: this.keyword,
        confirmed_price_not_null
      };
      if (type === 'auction_houses') {
        this.productsStore.params.auction_house_id_eq = id || auction_house_id_eq;
      } else if (type === 'product_category') {
        this.productsStore.params.product_category_id_eq = id || product_category_id_eq;
      } else if (type === 'store_category') {
        this.productsStore.params.store_category_id_eq = id || store_category_id_eq;
      } else {
        this.productsStore.params.custom_product_list_id_eq = id || custom_product_list_id_eq;
      }

      await Promise.all([
        followProductIdsStore.tryFetchData(),
        this.productsStore.fetchData()
      ]);
      if (type === 'product_category' && this.productsStore.params.product_category_id_eq) {
        const { data: { title } } = await this.$fly.get(`product_categories/${this.productsStore.params.product_category_id_eq}`);
        this.title = title;
      }
      if (type === 'store_category' && this.productsStore.params.store_category_id_eq) {
        const { data: { title } } = await this.$fly.get(`store_categories/${this.productsStore.params.store_category_id_eq}`);
        this.title = title;
      }

      if (this.productsStore.data.length > 0) {
        initSubscription(this, this.productsStore.data);
      }
      Object.assign(this.productQuery, this.productsStore.params, { type });
    }

    mounted () {
      const that = this;
      window.onresize = () => {
        return (() => {
            window.screenWidth = document.body.clientWidth;
            that.screenWidth = window.screenWidth;
        })();
      };
    }

    beforeDestroy() {
      if (this.consumer) {
        this.consumer.disconnect();
      }
    }

    handleSelectedSort(item) {
      // 选中的为当前的排序时，直接关闭弹窗
      if (item.value === this.$route.query.sort) {
        return this.showSortDialog = false;
      }
      this.$router.replace({
        query: _.merge({}, this.$route.query, { sort: item.value, page: 1 })
      });
    }

    handleCancelSort() {
      // 当前无选择任何排序时，直接关闭弹窗
      if (this.$route.query.sort === '') {
        return this.showSortDialog = false;
      } else {
        this.$router.replace({
          query: _.merge({}, this.$route.query, { sort: '', page: 1 })
        });
      }
    }

    handleSearch() {
      if (this.keyword === this.$route.query.keyword) {
        return;
      }
      this.$router.replace({
        query: _.merge({}, this.$route.query, { keyword: this.keyword, page: 1 })
      });
    }
  }
</script>

<style lang="scss" scoped>
  .view-all-category {
    height: 40px;
    line-height: 40px;
    text-align: center;
    color: #000;
    background: #fff;
    box-shadow: 2px 6px 36px 2px rgba(129, 129, 129, 0.17);
    cursor: pointer;

    &.header {
      position: sticky;
      z-index: var(--fixed-z-index);
      top: var(--header-height);
    }
  }

  .title {
    margin: 29px 0 20px;

    @include media-sm-and-up {
      margin: none;
    }
  }

  /deep/ .el-input {
    outline: none;

    .el-input__inner {
      border-color: #ddd !important;
    }

    .el-input__suffix {
      display: flex;
      align-items: center;
    }
  }

  .input-group {
    width: 50%;
    margin: 0 auto 35px;

    @include media-xs-only {
      width: 100%;
      margin: 0 auto 10px;
    }

    .search-input {
      min-width: 170px;
    }
  }

  .sort {
    margin-left: 10px;
    border-radius: 4px;
    font-size: 12px;
    line-height: 17px;
    text-decoration: underline;
    color: $primary;
    cursor: pointer;

    .filter-icon {
      width: 15px;
      height: 15px;
      margin-right: 5px;
    }
  }

  .icon {
    width: 15px;
    height: 15px;
    padding: 2px 5px 0 0;
    cursor: pointer;
  }

  .list-wrapper {
    margin-bottom: 5px;

    @include media-sm-and-up {
      margin-bottom: 35px;
    }
  }

  .component-product-item {
    flex-shrink: 0;
    margin-bottom: 12px;

    @include media-xs-only {
      margin-bottom: 10px;
    }
  }

  .product-list-empty {
    margin: 46px auto 57px;
    text-align: center;
    color: #999;

    > img {
      width: 65px;
      height: 65px;
      margin-bottom: 36px;
    }
  }

  .pagination {
    padding: 10px 0 30px;
  }

  /deep/ .dialog-sort {
    border-radius: 19px;

    .el-dialog {
      border-radius: 19px;
    }

    .el-dialog__header {
      padding: 0;
    }

    .header {
      width: 100%;
      padding-bottom: 16px;
      border-bottom: 2px solid #ddd;
      font-weight: bold;
      font-size: 16px;
      text-align: center;
    }

    .sort-item {
      width: 100%;
      border-bottom: 1px solid #ddd;
      line-height: 40px;
      text-align: center;

      &:last-of-type {
        border-bottom: 2px solid #ddd;
      }

      &.selected {
        font-weight: bold;
        color: $primary;
      }

      &:hover {
        font-weight: bold;
        color: $primary;
        cursor: pointer;
      }
    }

    .btn-wrapper {
      margin-top: 30px;

      .btn-confirm {
        height: 38px;
        font-size: 15px;
        background: $primary;
      }
    }
  }
</style>
