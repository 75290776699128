<i18n>
{
  "zh_cn": {
    "showMore": "点击查看更多 >",
    "inProgress": "拍卖进行中",
    "shopsList": "店铺列表",
    "notStart": "拍卖预告",
    "finished": "拍卖已结束",
    "thematic": "专题系列",
    "hotCategory": "热门分类",
    "recommendAuction": "拍卖会推荐"
  },
  "zh_hk": {
    "showMore": "點擊查看更多 >",
    "inProgress": "拍賣進行中",
    "shopsList": "店鋪列表",
    "notStart": "拍賣預告",
    "finished": "拍賣已結束",
    "thematic": "專題系列",
    "hotCategory": "熱門分類",
    "recommendAuction": "拍賣會推薦"
  },
  "en": {
    "showMore": "more >",
    "inProgress": "Auction in progress",
    "shopsList": "Shop List",
    "notStart": "Upcoming Auctions",
    "finished": "Past auctions",
    "thematic": "Thematic series",
    "hotCategory": "Hot Categories",
    "recommendAuction": "Recommended"
  },
  "ja": {
    "showMore": "もっと見る >",
    "inProgress": "開催中のオークション",
    "shopsList": "店舗リスト",
    "notStart": "オークションプレビュー",
    "finished": "オークションは終了しました",
    "thematic": "特集シリーズ",
    "hotCategory": "人気カテゴリ",
    "recommendAuction": "お薦め"
  }
}
</i18n>

<template>
  <div class="component-custom-page">
    <template v-for="row in components">
      <!-- 图片 -->
      <div class="custom-image" :key="row.key" v-if="row.name === 'image' && $get(row, 'config.image')">
        <img class="image" :src="row.config.image"/>
      </div>

      <!-- 视频 -->
      <div class="custom-video" :key="row.key" v-if="row.name === 'video' && $get(row, 'config.video')">
        <video
          class="video"
          :src="row.config.video"
          :controls="row.config.controls"
          :muted="row.config.muted"
          :autoplay="row.config.autoplay"
          :loop="row.config.loop"
          objectFit="contain"
        />
      </div>

      <!-- 文本 -->
      <div class="custom-text" :key="row.key" v-if="row.name === 'text' && $get(row, 'config.text')">
        <div class="text" v-html="row.config.text"></div>
      </div>

      <!-- 标题 -->
      <div :class="['custom-title', { 'top-title': isTopTitle(row.key) }]" :key="row.key" v-if="row.name === 'title' && $get(row, 'config.text')">
        <div class="title">{{ row.config.text }}</div>
      </div>

      <!-- 拍卖会列表 -->
      <div
        class="component-item"
        :class="{ bg: needAddBgColor }"
        v-if="row.name === 'auction-list' && $get(row, 'config.data.length')"
        :key="row.key"
      >
        <div class="container custom-auction-list">
          <common-title :title="$t(`${$camelCase(row.config.timeType)}`)" />
          <div class="scroll-wrapper" v-if="$get(row, 'config.timeType') === 'in_progress'">
            <div class="scroll-content">
              <router-link
                class="item"
                v-for="item in row.config.data"
                :key="item.id"
                :to="{ name: 'auctionDetail', params: { id: item.id } }"
              >
                <auction-card :auction="item"/>
              </router-link>
            </div>
          </div>
          <div v-else>
            <div class="auction-list">
              <el-row :gutter="30">
                <el-col :xs="24" :lg="12" v-for="item in row.config.data" :key="item.id">
                  <auction-item class="item" :auction="item"/>
                </el-col>
              </el-row>
              <pagination style="padding-top: 1.66667rem;" :store="row.config" />
            </div>
          </div>
        </div>
      </div>

      <!-- 推荐拍卖会列表 -->
      <div
        class="component-item"
        :class="{ bg: needAddBgColor }"
        v-if="row.name === 'recommend-auction-list' && row.config.data.length"
        :key="row.key"
      >
        <div class="container custom-auction-list">
          <common-title :title="$t('recommendAuction')" />
          <div class="auction-list">
            <el-row :gutter="30">
              <el-col :xs="24" :lg="12" v-for="item in row.config.data" :key="item.id">
                <auction-item class="item" :auction="item"/>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>

      <!-- 专题模块 -->
      <!-- 数据是 custom_page 接口直接返回的, 无需另外请求 -->
      <div class="component-item" :class="{ bg: needAddBgColor }" :key="row.key" v-if="row.name === 'theme-page'">
        <div class="container custom-theme-page">
          <div class="flex item-center content-between">
            <common-title v-if="$get(row, 'config.themes.length')" class="common-title" :title="$t('thematic')"/>

            <div class="show-more"  v-if="$get(row, 'config.themes.length') > 6">
              <router-link class="text" :to="{ name: 'topics' }">{{ $t('showMore') }}</router-link>
            </div>
          </div>

          <el-row :gutter="20" class="theme-page-list">
            <el-col :xs="24" :sm="8" v-for="item in ($get(row, 'config.themes').slice(0, 6))" :key="item.id">
              <live-card :live="item" :routeTo="{ name: 'topicDetail', params: { slug: item.slug } }">
                <template slot="footer">
                  <img src="@/assets/icon-arrow-right.png" class="footer icon">
                  <div class="footer theme-title">{{ item.title }}</div>
                </template>
              </live-card>
            </el-col>
          </el-row>
        </div>
      </div>

      <!-- 单个商品 -->
      <div class="component-item" :class="{ bg: needAddBgColor }" :key="row.key" v-if="row.name === 'single-product'">
        <div class="container custom-single-product">
          <product-item :product="$get(row, 'config.data')" horizontal/>
        </div>
      </div>

      <!-- 自定义商品列表 -->
      <div
        class="component-item"
        :class="{ bg: needAddBgColor }"
        :key="row.key"
        v-if="row.name === 'custom-product-list' && $get(row, 'config.data.length')"
      >
        <div class="container custom-product-list">
          <div class="flex item-center content-between">
            <common-title class="common-title" :title="row.config.title" v-if="row.config.data.length"/>
            <div class="show-more" v-if="row.config.total > (topic ? 4 : 6)">
              <router-link
                class="text"
                :to="{ name: 'products', query: { id: row.config.customProductId, title: row.config.title } }"
              >
                {{ $t('showMore') }}
              </router-link>
            </div>
          </div>
          <div class="products-wrapper">
            <el-row :gutter="8" justify="space-between">
              <el-col :xs="8" :sm="6" :md="topic ? 6 : 4" v-for="item in row.config.data" :key="item.id">
                <product-item :product="item" :productQuery="row.productQuery"/>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>

      <!-- 拍卖即将结束商品列表 -->
      <div
        class="component-item"
        :class="{ bg: needAddBgColor }"
        :key="row.key"
        v-if="row.name === 'will-finished-product-list' && $get(row, 'config.data.length')"
      >
        <div class="container custom-will-finished-product-list">
          <div class="flex item-center content-between">
            <common-title class="common-title" :title="$t('willEndproductList')" v-if="row.config.data.length"/>
            <div class="show-more" v-if="row.config.total > (topic ? 4 : 6)">
              <router-link class="text" :to="{ name: 'products', query: { will_end: true } }">{{ $t('showMore') }}
              </router-link>
            </div>
          </div>
          <div class="products-wrapper">
            <el-row :gutter="8" justify="space-between">
              <el-col :xs="8" :sm="6" :md="topic ? 6 : 4" v-for="item in row.config.data" :key="item.id">
                <product-item :product="item" :productQuery="row.productQuery"/>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>

      <!-- 拍卖进行中商品列表 -->
      <div
        class="component-item"
        :class="{ bg: needAddBgColor }"
        :key="row.key"
        v-if="row.name === 'in-progress-product-list' && $get(row, 'config.data.length')"
      >
        <div class="container custom-in-progress-product-list">
          <div class="flex item-center content-between">
            <common-title class="common-title" :title="$t('isPendding')" v-if="row.config.data.length"/>
            <div class="show-more" v-if="row.config.total > (topic ? 4 : 6)">
              <router-link class="text" :to="{ name: 'products', query: { in_progress: true } }">{{ $t('showMore') }}
              </router-link>
            </div>
          </div>
          <div class="products-wrapper">
            <el-row :gutter="8" justify="space-between">
              <el-col :xs="8" :sm="6" :md="topic ? 6 : 4" v-for="item in row.config.data" :key="item.id">
                <product-item :product="item" :productQuery="row.productQuery"/>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>

      <!-- 商品分类 -->
      <div class="component-item" :class="{ bg: needAddBgColor }" :key="row.key" v-if="row.name === 'product-category-list'">
        <div class="container custom-product-category">
          <div class="flex item-center content-between">
            <common-title v-if="$get(row, 'config.data.children.length')" class="common-title" :title="$get(row, 'config.title')"/>
            <div class="show-more">
              <router-link class="text" :to="{ name: 'productCategories' }">{{ $t('showMore') }}</router-link>
            </div>
          </div>
          <div class="product-category-wrapper">
            <product-category-item v-for="item in $get(row, 'config.data.children')" :key="item.id" :category="item"/>
          </div>
        </div>
      </div>

      <!-- 热门分类 -->
      <div class="component-item" :class="{ bg: needAddBgColor }" :key="row.key" v-if="row.name === 'hot-category-list'">
        <div class="container custom-hot-category">
          <div class="flex item-center content-between">
            <common-title v-if="$get(row,'config.data.length')" class="common-title" :title="$t('hotCategory')"/>
            <div class="show-more">
              <router-link class="text" :to="{ name: 'productCategories' }">{{ $t('showMore') }}</router-link>
            </div>
          </div>
          <div class="product-category-wrapper">
            <product-category-item v-for="item in $get(row, 'config.data')" :key="item.id" :category="item"/>
          </div>
        </div>
      </div>

      <!-- 店铺列表 -->
      <div class="component-item" :class="{ bg: needAddBgColor }" :key="row.key" v-if="row.name === 'auction-house-list'">
        <div class="container custom-auction-house-list">
          <div class="flex item-center content-between">
            <common-title class="common-title" :title="$t('shopsList')" v-if="$get(row, 'config.data.length')"/>
            <div class="show-more">
              <router-link class="text" :to="{ name: 'auctionHouse' }">{{ $t('showMore') }}</router-link>
            </div>
          </div>
          <div class="flex auction-house-wrapper">
            <div v-for="item in $get(row, 'config.data')" :key="item.id" class="auction-house">
              <auction-house-item :auctionHouse="item" />
            </div>
          </div>
        </div>
      </div>

      <!-- 直播列表 -->
      <div class="component-item" :class="{ bg: needAddBgColor }" :key="row.key" v-if="row.name === 'live-broadcast-list'">
        <div class="container custom-live-page">
          <div class="flex item-center content-between">
            <common-title v-if="$get(row, 'config.data.length')" class="common-title" :title="$t('liveBroadcast')"/>
            <div class="show-more"  v-if="row.config.data.length > 3">
              <router-link class="text" :to="{ name: 'LiveBroadcasts' }">{{ $t('showMore') }}</router-link>
            </div>
          </div>
          <el-row :gutter="20" class="theme-live-list">
            <el-col :xs="24" :sm="8" v-for="item in $get(row, 'config.data').slice(0, 3)" :key="item.id">
              <live-card :live="item" :routeTo="{ name: 'liveBroadcastDetail', params: { id: item.id } }"/>
            </el-col>
          </el-row>
        </div>
      </div>

      <!-- 热门拍品列表 -->
      <div class="component-item" :class="{ bg: needAddBgColor }" :key="row.key" v-if="row.name === 'hot-auction-item-list'">
        <div class="container custom-hot-auction-item-list">
          <div class="flex item-center content-between">
            <common-title v-if="$get(row, 'config.data.length')" class="common-title" :title="$t('hotAuctionItemList')"/>

            <div class="show-more" v-if="$get(row, 'config.data.length') === 6">
              <router-link class="text" :to="{ path: '/search', query: { type: 'auction_items', status_in: ['pending'], auction_is_finished_eq: false } }">{{ $t('showMore') }}</router-link>
            </div>
          </div>
          <el-row :gutter="20">
            <el-col :xs="24" :sm="12" :md="8" v-for="item in $get(row, 'config.data')" :key="item.id">
              <router-link :to="{ name: 'auctionItemDetail', params: { id: item.id }, query: { sort: 'visits_count desc' } }">
                <auction-item-card
                  class="item"
                  :title="`LOT ${item.title}`"
                  :cover="$get(item, 'cover.url')"
                  :info="item.title"
                >
                  <div slot="title" v-if="item.number">LOT {{ item.number }}</div>
                  <div class="price">
                    <div v-if="item.is_finished && item.show_items_final_price && $authStore.isLogin">
                      <div v-if="['hammered', 'force_hammered'].includes(item.status)">
                        <div>
                          {{$t('dealPrice')}}:
                          <span class="text-uppercase">{{item.auction_category.currency}}</span>
                          {{ item.final_price | currencyFormat }}
                        </div>
                        <div v-if="!$options.filters.isLocaleCurrency(item.auction_category.currency)">
                          {{$t('dealPrice')}}: {{$t('currency')}} {{ item.final_price | conversionFormat($get(item.auction_category, 'currency')) | currencyFormat }}
                        </div>
                      </div>
                      <div v-else>
                        {{$t('aborted')}}
                      </div>
                    </div>
                    <div v-else-if="item.starting_price <= 0">
                      {{ $t('estimatePending') }}
                    </div>
                    <div v-else>
                      <div class="text-overflow">
                        {{$t('startPrice')}}:
                        <span class="text-uppercase">{{item.auction_category.currency}}</span>
                        {{ item.starting_price | currencyFormat }}
                      </div>
                      <div v-if="!$options.filters.isLocaleCurrency(item.auction_category.currency)">
                        {{$t('startPrice')}}: {{$t('currency')}} {{ item.starting_price | conversionFormat($get(item.auction_category, 'currency')) | currencyFormat }}
                      </div>
                    </div>
                  </div>
                </auction-item-card>
              </router-link>
            </el-col>
          </el-row>
        </div>
      </div>

      <!-- 一口价商品列表 -->
      <div class="component-item" :class="{ bg: needAddBgColor }" :key="row.key" v-if="row.name === 'confirm-price-product-list'">
        <div class="container custom-confirm-price-product-list">
          <div class="flex item-center content-between">
            <common-title v-if="$get(row, 'config.data.length')" class="common-title" :title="$t('confirmPriceProductList')"/>

            <div class="show-more" v-if="$get(row, 'config.data.length') === 6">
              <router-link class="text" :to="{ path: '/products', query: { confirmed_price_not_null: true } }">{{ $t('showMore') }}</router-link>
            </div>
          </div>
          <el-row :gutter="8" justify="space-between">
            <el-col :xs="8" :sm="6" :md="4" v-for="item in $get(row, 'config.data')" :key="item.id">
              <product-item class="component-product-item" :product="item" :productQuery="row.productQuery" />
            </el-col>
          </el-row>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
  import { Vue, Component, Prop } from 'vue-property-decorator';
  import { initSubscription } from '@/utils';
  import dayjs from 'dayjs';
  import _ from 'lodash';

  @Component
  export default class CustomPage extends Vue {
    @Prop({ type: Array, default: () => ([]) }) components
    @Prop({ type: Boolean, default: false }) topic
    @Prop({ type: String, default: '' }) slug

    received = function (that, result) {
      const newData = result.data;
      that.components.forEach(row => {
        switch (row.name) {
          case 'single-product':
            if (row.config.data.id === newData.product_id) {
              that.updateProduct(row.config.data, result);
            }
            return;
          case 'custom-product-list':
          case 'will-finished-product-list':
          case 'in-progress-product-list':
            _.forEach(row.config.data, product => {
              if (product.id === newData.product_id) {
                that.updateProduct(product, result);
              }
            });
            return;
        }
      });
    }

    get shouldSubscribeWS() {
      return this.components.filter(row => {
        return row.name === 'single-product' ||
          (['custom-product-list', 'will-finished-product-list', 'in-progress-product-list'].includes(row.name) && this.$get(row, 'config.data.length') > 0);
      }).length > 0;
    }

    // 首页、商城页面模块 -	调整背景色或其他方式，以区分出不同模快
    get needAddBgColor() {
      return ['home', 'mall'].includes(this.slug);
    }

    // 判断标题是否在页面的顶部
    isTopTitle(key) {
      const firstComponent = this.components[0] || {};
      return firstComponent.name === 'title' && firstComponent.key === key;
    }

    async created() {
      if (this.shouldSubscribeWS) {
        initSubscription(this, [], this.received);
      }
      // 每个商品列表都传请求参数到query
      this.components.forEach(row => {
        switch (row.name) {
          case 'custom-product-list':
            row.productQuery = { productType: 'product', custom_product_list_id_eq: row.config.customProductId };
            return;
          case 'will-finished-product-list':
            row.productQuery = { productType: 'product', will_end: true };
            return;
          case 'in-progress-product-list':
            row.productQuery = { productType: 'product', in_progress: true };
            return;
          case 'confirm-price-product-list':
            row.productQuery = { productType: 'product', confirmed_price_not_null: true };
            return;
        }
      });
    }

    beforeDestroy() {
      if (this.consumer) {
        this.consumer.disconnect();
      }
    }

    updateProduct(product, result) {
      const { action, data } = result;
      switch (action.toLowerCase()) {
        case 'bid':
          if (data.current_price > product.current_price) {
            product.current_price = data.current_price;
          }
          return;
        case 'deal':
          product.deal_price = data.deal_price;
          return;
        case 'abort':
          product.bid_status = data.bid_status;
          return;
        case 'delay':
          if (dayjs(data.ended_bidding_time).diff(product.ended_bidding_time) > 0) {
            product.ended_bidding_time = data.ended_bidding_time;
          }
          return;
      }
    }
  }
</script>

<style lang="scss" scoped>
  .component-custom-page {
    /deep/ .component-title {
      margin: 30px 0 20px;

      @include media-sm-and-up {
        margin: 40px 0 30px;
      }
    }

    // 首页、商城页面模块 -	调整背景色或其他方式，以区分出不同模快
    & > .component-item {
      &.bg {
        &:nth-child(even) {
          background: #fafafa;

          .custom-live-page,
          .custom-theme-page {
            /deep/ .component-title {
              background: linear-gradient(-60deg, transparent 20px, #fff 0);
            }
          }
        }

        &:nth-child(odd) {
          background: #fff;
        }
      }
    }
  }

  .show-more {
    padding-top: 10px;

    .text {
      font-size: 10px;
      color: #666;
      cursor: pointer;

      @include media-sm-and-up {
        font-size: 12px;
      }
    }
  }

  .custom-image {
    display: flex;
    justify-content: center;
    margin-top: 33px;

    .image {
      width: 100%;
      height: 100%;

      @include media-sm-and-up {
        width: 800px;
      }
    }
  }

  .custom-video {
    margin-top: 33px;

    .video {
      width: 100%;

      @include media-sm-and-up {
        width: 800px;
      }
    }
  }

  .custom-text {
    margin-top: 28px;
    font-size: 13px;
    white-space: pre-wrap;
    color: #666;

    @include media-sm-and-up {
      margin-top: 37px;
    }
  }

  .custom-title {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 38px;
    font-weight: 500;
    font-size: 18px;
    background: #fff;

    @include media-sm-and-up {
      font-size: 32PX;
    }
  }

  .top-title {
    margin: 0 -15px;
    margin-top: -20px;
    border-radius: 25px 25px 0 0;

    @include media-sm-and-up {
      padding-top: 30px;
      margin: 0;
    }
  }

  .custom-auction-list {
    .scroll-wrapper {
      margin: 10px -20px 0;
      overflow: auto;

      @include media-sm-and-up {
        margin: 30px 0;
      }

      .scroll-content {
        display: inline-flex;
        padding: 0 15px 15px;

        @include media-lg-and-up {
          padding: 0 3px 15px;
        }

        .item {
          margin: 0 5px;
          cursor: pointer;

          .auction-card-wrapper {
            min-height: 100%;
          }
        }
      }
    }

    .auction-list {
      padding: 10px 0;

      .item {
        display: block;
        margin-bottom: 10px;
      }
    }
  }

  .custom-single-product {
    margin: 28px auto 0;

    @include media-sm-and-up {
      width: 400px;
    }
  }

  .products-wrapper {
    /deep/ .component-product-item {
      margin-bottom: 10px;
    }
  }

  .product-category-wrapper {
    display: grid;
    gap: 8px;
    grid-template-columns: repeat(3, 1fr);

    @include media-sm-and-up {
      grid-template-columns: repeat(6, 1fr);
    }
  }

  .auction-house-wrapper {
    padding: 10px 0 0 20px;
    overflow: auto;

    @include media-xs-only {
      padding-left: 2px;
    }

    .auction-house {
      max-width: 180px;

      @include media-xs-only {
        max-width: 90px;
      }

      /deep/.component-auction-house-item {
        margin-right: 26px;
      }
    }
  }

  .footer {
    position: absolute;
    bottom: 15px;
    font-size: 16px;
    color: #fff;
  }

  .icon {
    right: 15px;
    width: 30px;
    height: 30px;
  }

  .theme-title {
    left: 20px;
    max-width: 200px;
  }

  .custom-confirm-price-product-list {
    .component-product-item {
      margin-bottom: 10px;
    }
  }
</style>
