<template>
  <div
    id="app"
    class="flex column"
    :class="`page-name-${$route.name}`"
    :style="{ '--color-btn-bg': $get($auctionHouseStore, 'domainHouseDetail.color_config.button_bg') || '#000',
              '--color-btn-font': $get($auctionHouseStore, 'domainHouseDetail.color_config.button_font') || '#fff',
              '--color-tag-bg': $get($auctionHouseStore, 'domainHouseDetail.color_config.tab_bg') || '#eee',
              '--color-tag-font': $get($auctionHouseStore, 'domainHouseDetail.color_config.tab_font') || '#000',
              '--color-link': linkColor || '#bb4353'}">
    <common-header v-if="showHeader"/>
    <div class="page-content-wrapper flex item-stretch flex-1">
      <div style="width: 100%">
        <router-view class="route-view" :key="`${$i18n.locale}_${routerViewKey}`" />
      </div>
    </div>
    <app-footer v-if="showFooter" :class="{ 'hidden-xs-only': !showFooterOnMobile }"/>
    <common-tab-bar v-if="showTabBar" class="hidden-sm-and-up" />
    <portal-target name="app-bottom"></portal-target>
    <portal-target name="app-side-meun"></portal-target>
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';
  import qs from 'qs';
  import _ from 'lodash';
  import { authStore, settingsStore, pagesStore } from '@/store';
  import { notifyCustomer } from '@/utils';

  @Component
  export default class App extends Vue {
    settingsStore = settingsStore;

    async created() {
      await Promise.all([authStore.checkFetchData(), settingsStore.tryFetchData()]);

      if (!this.isWXEnvironment || !this.isAppEnvironment) {
        this.injectCrisp(settingsStore.settings);
      }
      pagesStore.tryFetchData();
      await notifyCustomer(async () => {
        await this.$confirm(this.$get(authStore, 'user.user_extended_info.credential_will_expire_notify_message', ''), {
          confirmButtonText: this.$t('goToUpdate')
        });
        this.$router.push({ name: 'myAddress' });
      });
    }

    // 是否是微信小程序环境
    get isWXEnvironment() {
      return window.__wxjs_environment === 'miniprogram';
    }

    // 是否是APP环境
    get isAppEnvironment() {
      return navigator.userAgent.includes('tokyoAuctionApp');
    }

    // 是否显示footer
    get showFooter() {
      return _.get(this.$route.meta, 'showFooter', true)
        && !this.$auctionHouseStore.domainHouse
        && _.get(this.$route.query, 'show_footer', true)
        && !this.isWXEnvironment
        && !this.isAppEnvironment;
    }

    // 移动端是否显示footer
    get showFooterOnMobile() {
      return this.showFooter && _.get(this.$route.meta, 'showFooterOnMobile', true);
    }

    get routerViewKey() {
      const { path, query } = this.$route;
      return `${path}?${qs.stringify(query)}`;
    }

    get showTabBar() {
      return _.get(this.$route.meta, 'showTabBar', true)
        && _.get(this.$route.query, 'show_tab_bar', true)
        && !this.isWXEnvironment
        && !this.isAppEnvironment;
    }

    get showHeader() {
      return _.get(this.$route.query, 'show_header', true) && !this.isWXEnvironment && !this.isAppEnvironment;
    }

    get linkColor() {
      return this.$get(this.$auctionHouseStore, 'domainHouse.id') ? this.$get(this.$auctionHouseStore, 'domainHouseDetail.color_config.link') : this.$get(settingsStore, 'settings.link_color');
    }

    /**
     * 注入 crisp
     *
     * 备注:
     *   1. 主域名用 setting 中的 crisp_website_id ,子域名用 auction_house 返回的 crisp_website_id, 如果 auction_house 返回的 crisp_website_id 为 null，则用 setting 中的 crisp_website_id
     *   2. 暂时只集成聊天框(内置人工客服聊天功能, 智能回复功能因为 trigger 插件收费暂时不需要集成)
     *
     * @param {object} settingData // setting 接口返回的数据
     *
     */
    async injectCrisp(settingData) {
      const globalWebsiteId = settingData.crisp_website_id;
      const auctionHouseWebsiteId = await (async() => {
        const auctionHouseId = this.$get(this.$auctionHouseStore, 'domainHouse.id');

        if (!auctionHouseId) {
          return Promise.resolve();
        }

        const { data } = await this.$fly.get(`auction_houses/${auctionHouseId}`);

        return Promise.resolve(data.crisp_website_id);
      })();
      const crispWebsiteId = auctionHouseWebsiteId || globalWebsiteId;

      if (!crispWebsiteId) {
        return;
      }

      // wiki的信息没有及时更新, 语言查询用: https://www.andiamo.co.uk/resources/iso-language-codes/
      const localeCode = {
        zh_cn: 'zh',
        zh_hk: 'zh-hk',
        en: 'en',
        ja: 'ja'
      }[this.$i18n.locale];

      const crispScriptEle = document.createElement('script');
      const headEle = document.getElementsByTagName('head')[0];

      crispScriptEle.innerHTML = `
        // 根据当前语言环境设置对应的语言
        CRISP_RUNTIME_CONFIG = { locale : '${localeCode}' };

        window.$crisp=[];window.CRISP_WEBSITE_ID="${crispWebsiteId}";(function(){d=document;s=d.createElement("script");s.src="https://client.crisp.chat/l.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();
      `;
      headEle.appendChild(crispScriptEle);

      // 注册会话加载事件（触发callback函数，session_id作为第一个参数）
      window.$crisp.push(['on', 'session:loaded', this.crispSessionLoadCallback]);
    }

    crispSessionLoadCallback() {
      // auctionLive 页面初次加载 crisp
      if (this.$route.name === 'auctionLive') {
        // 隐藏 crisp, 避免遮挡出价
        window.$crisp.push(['config', 'hide:on:mobile', [true]]);
      }
      // 设置 chatbox 昵称
      authStore.setCrispNickName();
    }
  }
</script>

<style lang="scss">
  html, body {
    width: 100%;
    padding: 0;
    margin: 0;

    @include media-xs-only {
      ::-webkit-scrollbar {
        width: 0;
        height: 0;
      }
    }
  }

  body {
    @include paddingBottomSafeArea;
    font-size: 14px;
    font-family: 'Helvetica Neue', -apple-system, BlinkMacSystemFont, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', Helvetica, Arial, sans-serif;
    line-height: 1.4;
    color: #000000;
  }

  .el-scrollbar {
    > .el-scrollbar__bar {
      opacity: 1 !important;
    }
  }

  // messageBox遮罩层opacity会有时候出现失效，使用background的$alpha避免
  .v-modal {
    background: rgba($color: #000000, $alpha: 0.5);
    opacity: 1;
  }

  #app {
    min-height: 100vh;
    overflow-x: hidden;
  }

  .route-view {
    min-height: 100%;
  }

  .container {
    width: 100%;
    max-width: 1200PX;
    padding: 0 20px;
    margin: 0 auto;

    @include media-xs-only {
      max-width: initial;
      padding: 0 15px;
    }
  }

  div {
    box-sizing: border-box;
  }

  input,
  textarea {
    font-family: inherit;
  }

  img {
    transform: translate3d(0, 0, 0);
  }

  img,
  video {
    max-width: 100%;
    vertical-align: top;
  }

  a {
    text-decoration: none;
  }

  input {
    box-sizing: border-box;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      appearance: none;
    }

    &::placeholder {
      color: #ccc;
    }
  }

  /*
    用 css 修改定位避免遮挡 tabbar
    修改 crisp 聊天框定位需要安装 Customization 插件, 但插件是付费功能, 所以用硬编码强制修改定位, 但有可能因为 crisp 更新导致失效
    plugin doc: https://help.crisp.chat/en/article/how-to-customize-my-chatbox-1hj537j/

    给 body 加 id 提高权重, 覆盖原有的 bottom 值

    10px: 避免紧贴 tabbar
    60px: tabbar高度
    40px: 切换 上/下一件 操作栏高度 (商品详情同时存在 tabbar 和 操作栏)
    safe-area-inset-bottom: iOS 安全区域
  */
  #body .crisp-client > [data-full-view=true] a[data-maximized=false] {
    bottom: calc(10px + 60px + 40px + #{$safe-area-inset-bottom}) !important;
  }

  #body .crisp-client a[data-maximized=false] {
    bottom: 60px !important;
  }
</style>
