<i18n src="./locale.json"></i18n>

<template>
  <div class="step2-box">
    <!-- 头部 -->
    <div class="header flex column">
      <div class="top-box">
        <div class="flex-1 flex item-center content-between">
          <span class="title">
            {{ $t('expertOpinion') }}
          </span>
          <div class="selected">
            {{ $tc('selected', selectedItems.length) }}
          </div>
        </div>
        <div class="primary-btn" @click="$emit('next')">{{ $t('next') }}</div>
      </div>
      <div class="bottom-box">
        <div class="flex items-center">
          <div class="prev-btn" @click="$emit('prev')">
            <img src="@/assets/icon-arrow-left-black.png" class="icon" />
            <span>{{ $t('prev') }}</span>
          </div>
          <span style="margin-left: 0.83333rem;">{{ $t('auctionItemsPopupTitle') }}</span>
        </div>
      </div>
    </div>

    <!-- 拍品专家意见列表 -->
    <div class="list-wrapper">
      <div v-for="item in selectedItems" :key="item.id" class="expert-item">
        <div class="name">{{ `LOT${item.number}` }}</div>
        <div class="opinion">
          <el-input
            type="textarea"
            v-model="item.expertOpinion"
            :placeholder="$t('inputExpertOpinion')"
            rows="6"
            maxlength="200"
            show-word-limit
            resize="none"
          />
          <auction-item-card
            class="item"
            :title="`LOT ${item.title}`"
            :cover="$get(item, 'cover.url')"
            :info="item.title"
          >
            <div slot="title" v-if="item.number">LOT {{ item.number }}</div>
            <div class="price">
              <div v-if="item.is_finished && item.show_items_final_price && $authStore.isLogin">
                <div v-if="['hammered', 'force_hammered'].includes(item.status)">
                  <div>
                    {{ $t('dealPrice') }}:
                    <span class="text-uppercase">{{ $get(item, 'auction_category.currency') }}</span>
                    {{ item.final_price | currencyFormat }}
                  </div>
                  <div v-if="!$options.filters.isLocaleCurrency($get(item, 'auction_category.currency'))">
                    {{ $t('dealPrice') }}: {{ $t('currency') }} {{ item.final_price | conversionFormat($get(item, 'auction_category.currency')) | currencyFormat }}
                  </div>
                </div>
                <div v-else>
                  {{ $t('aborted') }}
                </div>
              </div>
              <div v-else-if="item.starting_price <= 0">
                {{ $t('estimatePending') }}
              </div>
              <div v-else>
                <div>
                  {{ $t('startPrice') }}:
                  <span class="text-uppercase">{{ $get(item, 'auction_category.currency') }}</span>
                  {{ item.starting_price | currencyFormat }}
                </div>
                <div v-if="!$options.filters.isLocaleCurrency($get(item, 'auction_category.currency'))">
                  {{ $t('startPrice') }}: {{ $t('currency') }} {{ item.starting_price | conversionFormat($get(item, 'auction_category.currency')) | currencyFormat }}
                </div>
              </div>
            </div>
          </auction-item-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { Vue, Component, Prop } from 'vue-property-decorator';

  @Component
  export default class PrintItemsStep2 extends Vue {
    @Prop({ type: Array, default: () => [] }) selectedItems;
  }
</script>

<style lang="scss" scoped>
.step2-box {
  .header {
    display: flex;
    justify-content: space-between;

    .top-box {
      display: flex;
      align-items: center;

      .title {
        margin-right: 16px;
        font-weight: 600;
        font-size: 20px;
        color: #333;
      }

      .tips {
        padding: 2px 7px;
        margin-top: 0;
        border: 1px solid #e8e8e8;
        border-radius: 10px;
        font-size: 12px;
        color: #6b6b6b;
      }
    }

    .bottom-box {
      display: flex;
      align-items: center;
      margin-top: 7px;

      .subtitle {
        font-size: 14px;
      }

      .items {
        font-size: 10px;
      }

      .prev-btn {
        display: flex;
        align-items: center;
        gap: 10px;
        cursor: pointer;

        .icon {
          width: 15px;
          height: 15px;
        }
      }
    }

    .primary-btn {
      margin: initial;
      margin-left: 15px;
    }
  }

  .list-wrapper {
    max-height: 60vh;
    margin-top: 15px;
    overflow: auto;

    .expert-item {
      padding: 13px 0;
      border: 1px solid #e8e8e8;
      border-right: none;
      border-left: none;

      .opinion {
        padding: 10px;
        margin-top: 12px;
        background: #f7f7f7;
      }

      /deep/.el-textarea {
        width: 100%;
        border: none;
        outline: none;
        resize: none;

        .el-textarea__inner,
        .el-input__count {
          padding: 0;
          border: none;
          background-color: transparent;
        }
      }
    }
  }
}
</style>
