import { i18n } from '@/i18n';

/**
 * 判断传入的货币符号是否跟当前系统语言货币符号相等，
 *
 * @param  {String} currency 传入的货币符号
 * @param  {Boolean} ignoreCase 是否忽略大小写，默认 true
 *
 * @return {Boolean}          返回是否相等的布尔值
 */
export default function currencyToI18n(currency = '', key) {
  const locale = {
    cny: 'zh_cn',
    usd: 'en',
    jpy: 'ja'
  }[currency.toLowerCase()];

  return i18n.t(key, locale);
}
