import { render, staticRenderFns } from "./print-items-popup.vue?vue&type=template&id=8be75012&scoped=true&"
import script from "./print-items-popup.vue?vue&type=script&lang=js&"
export * from "./print-items-popup.vue?vue&type=script&lang=js&"
import style0 from "./print-items-popup.vue?vue&type=style&index=0&id=8be75012&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8be75012",
  null
  
)

/* custom blocks */
import block0 from "./locale.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2FUsers%2Fquake%2Fworkspace%2Fauction%2Ftokyo-auction-frontend%2Fsrc%2Fcomponents%2Fauction%2Fprint-items-popup.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports