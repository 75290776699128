<template>
  <div class="hint">
    <template v-if="selectCurrency === 'jpy'">
      <div
        v-if="$get(settingsStore, 'settings.recharge_hint') && !$auctionHouseStore.domainHouse"
        v-html="$get(settingsStore, 'settings.recharge_hint')"
      ></div>
      <div
        v-else-if="$get($auctionHouseStore, 'domainHouseDetail.recharge_hint')"
        v-html="$get($auctionHouseStore, 'domainHouseDetail.recharge_hint')"
      ></div>
    </template>
    <template v-else>
      <div
        v-if="$get(settingsStore, 'settings.pay_dollar_recharge_hint') && !$auctionHouseStore.domainHouse"
        v-html="$get(settingsStore, 'settings.pay_dollar_recharge_hint')"
      ></div>
      <div
        v-else-if="$get($auctionHouseStore, 'domainHouseDetail.pay_dollar_recharge_hint')"
        v-html="$get($auctionHouseStore, 'domainHouseDetail.pay_dollar_recharge_hint')"
      ></div>
    </template>
  </div>
</template>

<script>
  import { Vue, Component, Prop } from 'vue-property-decorator';
  import { settingsStore } from '@/store';

  @Component
  export default class HintTip extends Vue {
    @Prop({ type: String, default: 'jpy' }) selectCurrency

    settingsStore = settingsStore
  }
</script>

<style lang="scss" scoped>
  .hint {
    font-size: 12px;
    line-height: 21px;
    white-space: pre-wrap;
    color: #666;
  }
</style>
