<i18n src="./locales.json"></i18n>

<template>
  <div class="wrapper flex">
    <v-title :value="$t('charge')"/>
    <common-sidebar class="hidden-xs-only"/>
    <div class="container">
      <div class="title">{{ $t('charge') }}</div>
      <div class="main">
        <div class="label">{{ $t('chargeAmount') }}</div>
        <div class="input-box flex item-center">
          <el-select v-model="selectCurrency" class="no-shirk" :popper-append-to-body="false">
            <el-option
              v-for="currency in ['jpy', 'hkd']"
              :key="currency"
              :value="currency"
              :label="currency"
            ></el-option>
          </el-select>
          <input v-if="inputFocus" class="input" type="number" v-model.trim="amount" @blur="inputFocus = false" ref="input"/>
          <div v-else class="input" @click="showInput">{{ amount | currencyFormat }}</div>
        </div>
      </div>
      <div class="primary-btn" @click="handleCharge">{{ $t('confirmCharge') }}</div>
      <hint-tip :selectCurrency="selectCurrency" />
    </div>

    <payment-methods
      v-model="showPaymentMethods"
      :isPayDollar="selectCurrency === 'hkd'"
      :current="paymentMethod"
      @confirm="handleConfirmMethod"
      charge
    />

    <!-- 选择非在线支付弹窗 -->
    <el-dialog
      class="dialog-page-order-detail"
      :visible.sync="dialogVisible"
      width="24.16667rem"
      center
    >
      <div class="contact-info" v-html="customPopContent.content"></div>
      <div slot="footer">
        <el-button class="primary-btn" style="font-size: 1.5rem" @click="handleContact">{{ $t('contactBtn') }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';
  import { authStore, settingsStore, paymentMethodsStore } from '@/store';
  import HintTip from './components/hint-tip.vue';
  import { loadElepay } from 'elepay-js-sdk';
  import urlJoin from 'url-join';
  import _ from 'lodash';

  @Component({ components: { 'hint-tip': HintTip } })
  export default class Charge extends Vue {
    amount = 0
    showPaymentMethods = false
    paymentMethod = null
    dialogVisible = false
    inputFocus = false
    customPopContent = {}

    authStore = authStore
    settingsStore = settingsStore
    paymentMethodsStore = paymentMethodsStore

    get selectCurrency() {
      return this.$route.query.selectCurrency || 'jpy';
    }

    set selectCurrency(value) {
      this.$router.replace({ query: { ...this.$route.query, selectCurrency: value } });
    }

    @Vue.autoLoading
    async created() {
      const { marginAmount } = this.$route.query;
      await authStore.tryFetchData();

      if (!authStore.user.region) {
        await this.$alert(this.$t('personalInfoHint'), { showClose: false });
        this.$router.push({ name: 'mySettings' });
        return;
      }

      await Promise.all([
        settingsStore.tryFetchData(),
        paymentMethodsStore.fetchData({ currency: this.selectCurrency, region: authStore.user.region, type: 'charge' })
      ]);
      const defaultAmount = settingsStore.settings[this.selectCurrency === 'jpy' ? 'least_charge_margin' : 'pay_dollar_least_charge_margin'];

      if (marginAmount > defaultAmount) {
        this.amount = marginAmount;
      } else {
        this.amount = defaultAmount;
      }
      paymentMethodsStore.onlinePaymentMethods.length && (this.paymentMethod = paymentMethodsStore.onlinePaymentMethods[0]);
    }

   async showInput() {
      this.inputFocus = true;
      await this.$nextTick();
      this.$refs.input.focus();
    }

    // 如果没有上传任何证件，没有证件有效期，提示用户填写后再支付
    async handleCharge() {
      const leastChargeMargin = settingsStore.settings[this.selectCurrency === 'jpy' ? 'least_charge_margin' : 'pay_dollar_least_charge_margin'];

      if (this.$authStore.isLoadCredential) {
        if (this.$authStore.isLoadEffectiveDate) {
          await this.$confirm(this.$t('uploadValidityPeriod'));
          return this.$router.push({ name: 'myAddress' });
        }
        if (this.amount < leastChargeMargin) {
          this.$message({
            type: 'warning',
            message: this.$t(`chargeLimit.${this.selectCurrency}`, { leastChargeMargin: this.$options.filters.currencyFormat(leastChargeMargin) })
          });
          return;
        }
        this.showPaymentMethods = true;
      } else {
        await this.$confirm(this.$t('uploadTip'));
        return this.$router.push({ name: 'myAddress' });
      }
    }

    async handleConfirmMethod(method) {
      if (['OfflinePayment', 'RemittancePayment'].includes(method)) {
        let slug = method === 'OfflinePayment' ? 'offline_payment_info' : 'remittance_payment_info';
        const { data } = await this.$fly.get(`pages/${slug}`);
        this.customPopContent = data;
        this.dialogVisible = true;
        return;
      }

      // 如果从 myWallet 跳过来，会带上 callback 参数，跳回去的时候，需要带上
      const myWallet = { name: 'myWallet' };
      if (this.$router.prevRoute.name === 'myWallet') {
        const { callback } = this.$route.query;
        // prevRoute.query剔除掉支付成功后返回来的参数，避免重复key
        myWallet.query = {
          ..._.omit(this.$router.prevRoute.query, ['status', 'orderNo', 'currency', 'chargeId', 'amount']),
          callback,
          selectCurrency: this.selectCurrency
        };
      } else {
        myWallet.query = { ...this.$route.query, callback: this.$router.prevRoute.fullPath };
      }

      const { data: { charge_obj, elepay_auth_pk, redirect_uri } } = await this.$autoLoading(this.$fly.post('mine/charge_margin', {
        currency: this.selectCurrency,
        amount: this.amount,
        payment_method: method,
        redirect_url: urlJoin(
          location.origin,
          this.$router.resolve(myWallet).route.fullPath,
        )
      }));

      // 信用卡支付的时候 如果没有上传任何证件，没有证件有效期，提示用户填写后再支付
      const { id_card_no, passport_no, driving_license_no } = this.$authStore.user;
      if (method === 'CreditcardPayment') {
        if (!id_card_no && !passport_no && !driving_license_no) {
          await this.$confirm(this.$t('uploadTip'));
          return this.$router.push({ name: 'myAddress' });
        }
        if (this.$authStore.isLoadEffectiveDate) {
          await this.$confirm(this.$t('uploadValidityPeriod'));
          return this.$router.push({ name: 'myAddress' });
        }
      }

      // 选择了港币充值需要跳去redirect_uri
      if (this.selectCurrency === 'hkd') {
        window.location.href = redirect_uri;
        return;
      }

      const elepay = await loadElepay(elepay_auth_pk);
      if (method === 'EasyCheckoutPayment') {
        return this.$autoLoading(elepay.checkout(charge_obj.id));
      }
      await elepay.handleCharge(charge_obj)
        .then(async result => {
          if (result.type === 'cancel') {
            this.$message({ type: 'error', message: this.$t('paymentFailed') });
            this.$router.replace({ name: 'myWallet', query: { callback: myWallet.query.callback } });
          } else if (result.type === 'success') {
            await authStore.fetchData();
            this.$message({ type: 'success', message: this.$t('paymentSuccess') });
            this.$router.replace({ name: 'myWallet', query: { callback: myWallet.query.callback } });
          }
        })
        .catch((result) => {
          this.$message({ type: 'error', message: result.message });
        });
    }

    // 联系客服
    async handleContact() {
      const { data } = await this.$autoLoading(this.$fly.post('message_channels/get_by_id_or_target', {
        target_type: 'User',
        target_id: this.authStore.user.id
      }));
      this.$router.push({ name: 'messageChannelDetail', params: { id: data.id } });
    }
  }
</script>

<style lang="scss" scoped>
  .title {
    margin: 28px 0 25px;
    font-weight: 500;
    font-size: 18px;
  }

  .main {
    @include media-sm-and-up {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 230px;
      margin: 0 auto;
    }

    .label {
      font-weight: 500;
      color: #999;
    }

    .input-box {
      box-sizing: border-box;
      height: 45px;
      padding: 8px 8px 12px;
      margin-top: 14px;
      border-bottom: 1px solid #ddd;

      .currency {
        margin-top: 5px;
        font-weight: 500;
        font-size: 20px;
      }

      .input {
        width: 180px;
        margin: 0 10px;
        border: none;
        outline: none;
        font-weight: 500;
        font-size: 35px;
        color: $primary;
        background: none;

        @include media-sm-and-up {
          padding-right: 30px;
          text-align: center;
        }
      }
    }
  }

  .primary-btn {
    margin: 170px auto 60px;

    @include media-sm-and-up {
      max-width: 300px;
      margin: 30px auto 80px;
    }
  }

  .hint {
    margin: 40px 0 20px;
    font-size: 12px;
    line-height: 21px;
    white-space: pre-wrap;
    color: #666;
  }

  /deep/ .dialog-page-order-detail {
    .el-dialog {
      margin-top: 40% !important;
      border-radius: 19px;

      @include media-sm-and-up {
        width: 352px !important;
        margin-top: 15vh !important;
      }

      .el-dialog__body {
        padding-bottom: 0;
      }

      .el-dialog__footer {
        padding-bottom: 37px;

        .primary-btn {
          height: 45px;
          margin: 0 auto;

          @include media-sm-and-up {
            height: 38px;
            font-size: 15px !important;
          }
        }
      }
    }
  }

  /deep/.el-select {
    .el-input__inner {
      width: 100px;
      border: none;
      text-transform: uppercase;
    }

    .el-select-dropdown__item {
      text-transform: uppercase;
    }
  }
</style>
