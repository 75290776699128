import { Vue } from 'vue-property-decorator';

import {
  Row,
  Col,
  Loading,
  Message,
  MessageBox,
  Notification,
  DropdownItem,
  DropdownMenu,
  Dropdown,
  Carousel,
  CarouselItem,
  Tabs,
  TabPane,
  Select,
  Option,
  Input,
  Pagination as ElPagination,
  Popover,
  Dialog,
  Button,
  Autocomplete,
  Breadcrumb,
  BreadcrumbItem,
  Progress,
  Cascader,
  CascaderPanel,
  DatePicker,
  Checkbox,
  Form,
  FormItem,
  Tag,
} from 'element-ui';

Vue.use(ElPagination)
  .use(Input)
  .use(Select)
  .use(Option)
  .use(Carousel)
  .use(CarouselItem)
  .use(Dropdown)
  .use(DropdownItem)
  .use(DropdownMenu)
  .use(Tabs)
  .use(TabPane)
  .use(Row)
  .use(Col)
  .use(Dialog)
  .use(Button)
  .use(Popover)
  .use(Autocomplete)
  .use(Breadcrumb)
  .use(BreadcrumbItem)
  .use(Progress)
  .use(CascaderPanel)
  .use(Cascader)
  .use(Checkbox)
  .use(DatePicker)
  .use(Form)
  .use(FormItem)
  .use(Tag);

Vue.prototype.$loading = Loading.service;
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$notify = Notification;
Vue.prototype.$message = Message;
