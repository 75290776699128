<template>
  <div class="skeleton" v-if="loading">
    <el-progress :percentage="percentage" :show-text="false"/>
    <skeleton-banner/>
    <div class="common-input-wrap">
      <common-input />
    </div>
    <div class="container">
      <common-title/>
      <div class="products-wrapper">
        <el-row :gutter="8" justify="space-between">
          <el-col :xs="8" :sm="6" :md="4" v-for="i in 6" :key="i">
            <skeleton-product-item/>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
  import { Component, Vue } from 'vue-property-decorator';
  import Skeleton from '@/components/skeleton/mixins';

  @Component({ mixins: [Skeleton] })
  export default class IndexSkeleton extends Vue {
  }
</script>

<style lang="scss" scoped>
  .common-input-wrap {
    margin-bottom: 0px;

    @include media-sm-and-up {
      margin-bottom: 50px;
    }
  }

  .container {
    /deep/ .component-title {
      margin-top: 20px;
      margin-bottom: 20px;

      @include media-sm-and-up {
        margin: 60px 0 40px;
      }
    }
  }

  .products-wrapper {
    margin-bottom: 50px;

    /deep/ .component-product-item {
      margin-bottom: 10px;
    }
  }
</style>
