<i18n src="./locales.json"></i18n>

<template>
  <div class="page-auction-applications-new">
    <div class="container">
      <v-title :value="$t('auction_application')"/>
      <h2 class="title">{{ $t('auction_application') }}</h2>
      <el-form
        @submit.prevent.native="handleSubmit"
        ref="form"
        :model="form"
        label-position="left"
        label-width="auto"
        :rules="rules"
      >
        <el-form-item :label="$t('name')" prop="name">
          <el-input v-model="form.name" :placeholder="$t('name')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('email')" prop="email">
          <el-input v-model="form.email" :placeholder="$t('email')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('phone')" prop="phone">
          <el-input v-model="form.phone" :placeholder="$t('phone')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('category')" prop="category">
          <el-select v-model="form.category" :placeholder="$t('category')">
            <el-option
              v-for="item in categories"
              :key="item"
              :value="item"
              :label="$t(item)"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('artist')" prop="artist">
          <el-input v-model="form.artist" :placeholder="$t('artist')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('artwork_name')" prop="artwork_name">
          <el-input v-model="form.artwork_name" :placeholder="$t('artwork_name')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('artwork_year')" prop="artwork_year">
          <el-date-picker
            v-model="form.artwork_year"
            type="year"
            :placeholder="$t('artwork_year')"
          ></el-date-picker>
        </el-form-item>
        <el-form-item :label="$t('artwork_region')" prop="artwork_region">
          <el-select v-model="form.artwork_region" :placeholder="$t('artwork_region')">
            <el-option
              v-for="item in countries"
              :key="item.code"
              :label="item.name"
              :value="item.name"
            />
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('size')" prop="size">
          <el-input v-model="form.size" :placeholder="$t('size')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('description')" prop="description">
          <el-input v-model="form.description" type="textarea" :placeholder="$t('description')"></el-input>
        </el-form-item>

        <el-form-item :label="$t('images')" class="form-item-image" prop="images">
          <UploadImage @change="handleAddImage">
            <el-button>+ {{ $t('upload_image') }}</el-button>
          </UploadImage>
          <div class="box-images" v-if="form.images.length">
            <UploadImage
              v-for="(item, index) in form.images"
              :key="item.signed_id"
              v-model="form.images[index]"
              @delete="handleDeleteImage(index)"
            />
          </div>
        </el-form-item>
        <button type="submit" class="submit-btn primary-btn">{{ $t('submit') }}</button>
      </el-form>

    </div>
  </div>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator';
import { fly } from '@/utils';
import UploadImage from './upload.vue';
import countries from '@/components/country-selector/countries';

@Component({
  components: {
    UploadImage
  }
})
export default class AuctionApplicationsNew extends Vue {
  countries = countries;

  form = {
    email: '',
    name: '',
    phone: '',
    category: '',
    artist: '',
    artwork_name: '',
    artwork_region: '',
    artwork_year: '',
    size: '',
    description: '',
    images: []
  }

  categories = [
    'chinese_calligraphy_and_painting',
    'chinese_ceramic_and_artwork',
    'stationery',
    'tea_set',
    'modern_art',
    'wine_and_spirit',
    'jewelery',
    'famous_watch'
  ]

  get rules() {
    return {
      email: [
        { required: true, message: this.$t('input_required', { name: this.$t('email') }) }
      ],
      name: [
        { required: true, message: this.$t('input_required', { name: this.$t('name') }) }
      ],
      phone: [
        { required: true, message: this.$t('input_required', { name: this.$t('phone') }) }
      ],
      category: [
        { required: true, message: this.$t('input_required', { name: this.$t('category') }) }
      ]
    };
  }

  async handleSubmit() {
    await this.$refs.form.validate();
    const body = {
      ...this.form,
      images: this.form.images.map(item => item.signed_id)
    };
    await this.$autoLoading(fly.post('auction_applications', body));
    try {
      await this.$alert(this.$t('submit_success'));
    } finally {
      // app会嵌入这个页面
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(JSON.stringify({
          event: 'goBack',
          data: {},
          id: Date.now()
        }));
      }
    }
  }

  handleDeleteImage(index) {
    this.form.images.splice(index, 1);
  }

  handleAddImage(imgObj) {
    this.form.images.push(imgObj);
  }

}
</script>

<style lang="scss" scoped>
  .page-auction-applications-new {
    font-size: 12px;

    .container {
      padding-bottom: 24px;
    }

    @include media-sm-and-up {
      font-size: 13px;
    }

    /deep/ .el-form-item__label {
      font-size: inherit;
    }

    /deep/ .el-form-item {
      .el-form-item__label {
        padding-right: 17px;
      }
    }

    .el-form {
      max-width: 420px;
      margin: 0 auto;
    }

    .el-select,
    .el-date-editor {
      width: 100%;
    }

    .form-item-image {
      padding: 20px 0;
      margin: 20px 0;
      border-top: 1px solid $border-light;
      border-bottom: 1px solid $border-light;

      .el-button {
        width: 100%;
        border-color: $black;
        color: $black;
      }
    }

    .title {
      padding-bottom: 24px;
      margin: 34px 0 20px;
      border-bottom: 1px solid $border-light;
      font-weight: bold;
      font-size: 18px;
      color: $black;
    }

    .submit-btn {
      width: 100%;
      margin: 30px auto;
      font-size: 18px;
    }
  }
</style>
