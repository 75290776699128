<template>
  <div class="component-lot-detail">
    <div class="swiper-container gallery-top" ref="galleryTop">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="(item, index) in medias" :key="index">
          <div class="auto-height-wrapper">
            <video :src="item.url" :poster="item.preview" v-if="item.type === 'video'" controls/>
            <oss-img
              v-else :resize="{ w: 750 }" :src="item.url" :process="item.process"
              @click="previewImage(index)"
            />
          </div>
        </div>
      </div>
      <img
        src="@/assets/icon-switch-right.png"
        class="swiper-button-next swiper-button-white"
        @click="handleSwiperSlide('next')"
      />
      <img
        src="@/assets/icon-switch-left.png"
        class="swiper-button-prev swiper-button-white"
        @click="handleSwiperSlide('prev')"
      />
    </div>
    <div class="swiper-container gallery-thumbs" ref="galleryThumbs">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="(item, index) in medias" :key="index">
          <oss-img :src="item.url" :resize="{ w: 88 }" :process="item.process"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
  // eslint-disable-next-line import/no-named-as-default
  import Swiper from 'swiper';
  import _ from 'lodash';

  @Component
  export default class LotDetail extends Vue {
    @Prop({ type: Array, default: () => [] }) images
    @Prop({ type: Object, default: () => null }) video

    created() {
      window.addEventListener('load', this.initGallery);
    }

    mounted() {
      this.initGallery();
      // 确保swiper可以初始化成功
      setTimeout(() => {
        this.initGallery();
      }, 500);
    }

    beforeDestroy() {
      window.removeEventListener('load', this.initGallery);
      this.galleryThumbs.destroy();
      this.galleryTop.destroy();
    }

    initGallery() {
      if (this.galleryThumbs && this.galleryTop) {
        this.galleryThumbs.update();
        this.galleryTop.update();

        // 切换拍品时重置到第一个
        this.galleryThumbs.slideTo(0);
        this.galleryTop.slideTo(0);
        return;
      }

      this.galleryThumbs = new Swiper(this.$refs.galleryThumbs, {
        spaceBetween: 8,
        slidesPerView: 'auto',
        freeMode: true,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
      });
      this.galleryTop = new Swiper(this.$refs.galleryTop, {
        thumbs: {
          swiper: this.galleryThumbs
        }
      });
    }

    handleSwiperSlide(direction) {
      if (!this.galleryTop) {
        return;
      }
      if (this.galleryTop.isBeginning && direction === 'prev') {
        // 滑动到最后一个
        this.galleryTop.slideTo(this.medias.length);
        return;
      }
      if (this.galleryTop.isEnd && direction === 'next') {
        // 滑动到第一个
        this.galleryTop.slideTo(0);
        return;
      }

      direction === 'next' ? this.galleryTop.slideNext() : this.galleryTop.slidePrev();
    }

    @Watch('medias')
    async onImageChange() {
      await this.$nextTick();
      this.initGallery();
    }

    previewImage(index) {
      this.$previewImage(_.map(this.medias, 'preview'), index);
    }

    get medias() {
      const arr = [];
      if (this.video) {
        arr.push({
          url: this.video.url, preview: this.video.url + '?x-oss-process=video/snapshot,t_1000,f_jpg,m_fast',
          type: 'video', process: 'video/snapshot,t_1000,f_jpg,m_fast'
        });
      }
      this.images.forEach(img => {
        arr.push({
          url: img.url, preview: img.url, type: 'image'
        });
      });
      return arr;
    }
  }
</script>

<style lang="scss" scoped>
  .swiper-button-white {
    width: 20px;
    height: 20px;
  }

  .swiper-container {
    width: 100%;

    .swiper-wrapper {
      width: 100%;
    }

    .swiper-slide {
      width: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .auto-height-wrapper {
      position: relative;
      width: 100%;
      height: 0;
      padding-bottom: 100%;
      background: rgb(250, 250, 250);

      > img, video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  .gallery-thumbs {
    height: 48px;
    margin: 10px 0;

    .swiper-slide {
      box-sizing: border-box;
      width: 48px;
      border: 2px solid transparent;
    }

    .swiper-slide-thumb-active {
      border: 2px solid $border-light;
    }
  }
</style>
