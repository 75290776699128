<i18n src="./locales.json"></i18n>

<template>
  <div class="page container">
    <v-title :value="auction_house.name"/>

    <div class="header flex item-center">
      <img :src="auction_house.logo.url" class="logo"/>
      <span class="name">{{ auction_house.name }}</span>
    </div>

    <div class="rate-list flex content-around">
      <div class="rate-item" @click="form.rate = 'poor'">
        <img src="@/assets/rate/poor-active.png" class="icon" v-show="form.rate === 'poor'"/>
        <img src="@/assets/rate/poor.png" class="icon" v-show="form.rate !== 'poor'"/>
        <span class="desc">{{ $t('rate.poor') }}</span>
      </div>
      <div class="rate-item" @click="form.rate = 'general'">
        <img src="@/assets/rate/general-active.png" class="icon" v-show="form.rate === 'general'"/>
        <img src="@/assets/rate/general.png" class="icon" v-show="form.rate !== 'general'"/>
        <span class="desc">{{ $t('rate.general') }}</span>
      </div>
      <div class="rate-item" @click="form.rate = 'good'">
        <img src="@/assets/rate/good-active.png" class="icon" v-show="form.rate === 'good'"/>
        <img src="@/assets/rate/good.png" class="icon" v-show="form.rate !== 'good'"/>
        <span class="desc">{{ $t('rate.good') }}</span>
      </div>
    </div>

    <textarea class="content-input" :placeholder="$tp('placeholder')" v-model.trim="form.content"/>

    <div class="submit-btn flex item-center content-center" @click="submit">
      {{ $tp('submit') }}
    </div>
  </div>
</template>

<script>
  import { Component, Vue } from 'vue-property-decorator';
  import Schema from 'async-validator';

  @Component
  export default class New extends Vue {
    form = {
      content: '',
      rate: '',
    }
    auction_house = { logo: {} }

    @Vue.autoLoading
    async created() {
      this.form.order_id = this.$route.query.order_id;
      const res = await this.$fly.get(`auction_houses/${this.$route.query.auction_house_id}`);
      this.auction_house = res.data;
    }

    @Vue.autoLoading
    async submit() {
      const descriptor = {
        rate: { required: true, message: this.$tp('rateNotEmpty') },
      };

      const validator = new Schema(descriptor);
      await new Promise((resolve, reject) => {
        validator.validate(this.form, errors => {
          if (errors) {
            reject(new Error(errors.map(err => err.message).join('\n')));
          } else {
            resolve();
          }
        });
      });
      await this.$fly.post('evaluations', this.form);
      await this.$router.replace({ path: '/evaluations', query: { auction_house_id: this.$route.query.auction_house_id } });
    }
  }
</script>

<style lang="scss" scoped>
  .page {
    padding-top: 27px;
    padding-bottom: 57px;
  }

  .header {
    padding-bottom: 30px;
    border-bottom: 1px solid #dddddd;

    .logo {
      width: 75px;
      height: 75px;
      border-radius: 50%;
      box-shadow: 0 4px 18px 2px rgba(4, 0, 0, 0.1);
      object-fit: contain;
    }

    .name {
      margin-left: 40px;
      font-weight: bold;
      color: #000000;
    }

    @include media-sm-and-up {
      justify-content: center;

      .name {
        font-size: 18px;
      }
    }
  }

  .rate-list {
    .rate-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 24px;
      cursor: pointer;

      .icon {
        width: 30px;
        margin-bottom: 18px;
      }

      .desc {
        font-weight: 500;
        font-size: 12px;
        line-height: 24px;
        color: #666666;
      }
    }

    @include media-sm-and-up {
      justify-content: center;

      .rate-item:nth-child(2) {
        margin: 0 45px;
      }
    }
  }

  .content-input {
    width: 100%;
    height: 260px;
    margin-top: 56px;
    border: none;
    resize: none;
    font-weight: 500;
    font-size: 13px;
    line-height: 30px;

    &::v-deep::-webkit-input-placeholder {
      color: #cccccc;
    }

    @include media-sm-and-up {
      box-sizing: border-box;
      padding: 15px 20px;
      border: 1px solid #dddddd;
    }
  }

  .submit-btn {
    width: 100%;
    height: 45px;
    margin-top: 10px;
    border-radius: 23px;
    font-weight: bold;
    font-size: 18px;
    color: #ffffff;
    background: #000000;
    cursor: pointer;

    @include media-sm-and-up {
      width: 180px;
      height: 30px;
      margin: 50px auto 0;
      border-radius: 15px;
      font-size: 12px;
    }
  }
</style>
