import { createConsumer } from '@/utils/actioncable';
import { authStore } from '@/store';
import { i18n } from '@/i18n';
import qs from 'qs';
import dayjs from 'dayjs';

export function initSubscription(that, products = [], customReceivedHandler = null) {
  const params = { Locale: i18n.locale };
  if (authStore.access_token) {
    params.Authorization = authStore.access_token;
  }
  const query = qs.stringify(params);
  const consumer = that.consumer = createConsumer(`/cable?${query}`);
  that.subscription = consumer.subscriptions.create({ channel: 'ProductChannel', subscribe_list: true }, {
    received: (result) => (customReceivedHandler ? customReceivedHandler(that, result) : updateProduct(products, result))
  });
}

export function updateProduct(products, result) {
  const { action, data } = result;
  products.forEach(product => {
    if (product.id === data.product_id) {
      switch (action.toLowerCase()) {
        case 'bid':
          if (data.current_price > product.current_price) {
            product.current_price = data.current_price;
          }
          return;
        case 'deal':
          product.deal_price = data.deal_price;
          return;
        case 'abort':
          product.bid_status = data.bid_status;
          return;
        case 'delay':
          if (dayjs(data.ended_bidding_time).diff(product.ended_bidding_time) > 0) {
            product.ended_bidding_time = data.ended_bidding_time;
          }
          return;
      }
    }
  });
}
