<i18n>
{
  "zh_cn": {
    "timeLeft": "距离拍卖",
    "auction": "拍卖中"
  },
  "zh_hk": {
    "timeLeft": "距離拍賣",
    "auction": "拍賣中"
  },
  "en": {
    "timeLeft": "Left",
    "auction": "Pending"
  },
  "ja": {
    "timeLeft": "オークションまで",
    "auction": "オークションに参加"
  }
}
</i18n>

<template>
  <div class="auction-card-wrapper">
    <div class="cover-wrapper">
      <auto-height
        :height="autoHeight"
        :cover="$get(auction, 'cover.url') || auction.cover"
        :oss-img="{ resize: { w: 1000 } }"
      >
        <div v-if="label" class="cover-content">
          <div class="label" :class="label.class">{{ label.text }}</div>
          <template v-if="auction.time_type === 'in_auction'">
            <img src="@/assets/icon-play-shadow.png" class="icon-play">
            <div class="div">{{ $t('auction') }}</div>
          </template>
        </div>
      </auto-height>
    </div>
    <div class="auction-wrapper">
      <div class="auction-title overflow two-row">{{ auction.title }}</div>
      <div class="auction-info">
        <template v-if="$get(auction, 'type') === 'StoreCategory'">
          <div v-if="auction.start_at" class="overflow">{{$t('auctionTime')}}: {{ auction.start_at | timeFormat }}</div>
          <div v-if="auction.lot_count" class="overflow">
            {{$t('lotQty')}}: {{ $tc('pieces', auction.lot_count, {count: auction.lot_count})}}
          </div>
        </template>

        <template v-else>
          <div class="overflow">{{$t('auctionTime')}}: {{ auction.start_at | timeFormat }}</div>
          <div class="overflow" v-if="!['not_start', 'will_start_auction'].includes(timeType)">
            {{$t('lotNumber')}}: {{ auction.auction_items_number_range }}
          </div>
          <div class="overflow" v-if="!['not_start', 'will_start_auction'].includes(timeType) || auction.lot_count">
            {{$t('lotQty')}}: {{ $tc('pieces', auction.auction_items_count || auction.lot_count, {count: auction.auction_items_count || auction.lot_count})}}
          </div>
          <div class="overflow" v-if="auction.place && timeType !== 'finished'">
            {{$t('auctionPlace')}}: {{ auction.place  }}
          </div>
        </template>
      </div>
      <!-- 可能出现还没开始就结束的情况 -->
      <app-countdown v-if="!auction.is_finished" target="div" end-hide plusOneMinute class="box-countdown" :end-time="auction.start_at">
        <template v-slot="{ day, hour, minute }">
          <span>{{ $t('timeLeft') }}</span>
          <span class="strong">{{ day }}</span>
          <span>{{ $t('day') }}</span>
          <span class="strong">{{ hour }}</span>
          <span>{{ $tc('hour', hour) }}</span>
          <span class="strong">{{ minute }}</span>
          <span>{{ $tc('minute', minute) }}</span>
        </template>
      </app-countdown>
    </div>
    <div v-if="['in_auction', 'not_start', 'will_start_auction'].includes(timeType)" class="primary-btn-o participate-btn">{{ btnText }}</div>
  </div>
</template>

<script>
  import { Vue, Component, Prop } from 'vue-property-decorator';
  import { auctionNumbersStore } from '@/store';

  @Component
  export default class AuctionCard extends Vue {
    @Prop({ type: Object, default: () => ({}) }) auction;
    @Prop({ type: String, default: '50%' }) autoHeight;

    get timeType() {
      return this.auction.time_type;
    }

    get label() {
      const { time_type } = this.auction;
      return {
        finished: { class: 'finished', text: this.$t('finished') },
        will_start_auction: { class: 'will-start', text: this.$t('willStart') },
        in_auction: { class: 'in-progress', text: 'LIVE' }
      }[time_type];
    }

    get btnText() {
      const index = auctionNumbersStore.data.findIndex(item => item.auction_id === this.auction.id);
      return index === -1 ? this.$t('participateBidding') : auctionNumbersStore.data[index].number;
    }
  }
</script>

<style lang="scss" scoped>
  .auction-card-wrapper {
    position: relative;
    width: 202px;
    border-radius: 2px;
    overflow: hidden;
    background: #ffffff;
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.09);

    @include media-sm-and-up {
      width: 350px;
      min-height: 190px;
    }

    .cover-wrapper {
      .cover-content {
        position: absolute;
        z-index: var(--absolute-z-index);
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        font-size: 10px;
        color: #fff;

        .label {
          position: absolute;
          top: 6px;
          left: 4px;
          height: 17px;
          padding: 0 8px;
          border-radius: 2px;
          line-height: 17px;
          background: $primary;
        }

        .icon-play {
          width: 40px;
          height: 40px;
          margin-top: 1px;
          margin-bottom: 10px;
        }
      }
    }

    .auction-wrapper {
      padding: 20px 5px 39px 10px;
      font-size: 12px;
      color: #666;

      .overflow {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &.two-row {
          @include media-xs-only {
            display: -webkit-box;
            white-space: normal;
            word-wrap: break-word;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }
      }

      .auction-title {
        margin-bottom: 10px;
        font-weight: bold;
        font-size: 18px;
        color: #000;
      }

      @include media-sm-and-up {
        .auction-title {
          line-height: 1.4;
        }
      }

      .auction-info {
        line-height: 1.6;
      }

      .box-countdown {
        .strong {
          margin: 0 5px;
          font-weight: bold;
          font-size: 20px;
          color: $primary;

          @include media-sm-and-up {
            margin: 0 3px;
            font-size: 19px;
          }
        }
      }
    }

    .participate-btn {
      position: absolute;
      bottom: 10px;
      right: 10px;
      height: 24px;
      margin: 5px 0 0;
      border-color: $primary;
      font-size: 12px;
      color: $primary;

      &:hover {
        color: #fff !important;
        background-color: $primary !important;
      }
    }
  }
</style>
