<i18n src="./locales.json"></i18n>

<template>
  <div>
    <v-title :value="detail.title"/>

    <div class="container">
      <video
        class="video"
        :src="$get(detail, 'video.url')"
        :poster="$get(detail, 'image.url')"
        objectFit="contain"
        width="800"
        height="400"
        controls
        />
      <div class="info-wrap">
        <div class="title">{{ detail.title }}</div>
        <div class="create-time">{{ detail.created_at | timeFormat }} {{ $t('publish') }}</div>
      </div>

      <div v-html="detail.desc" ref="richText"></div>
    </div>
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';

  @Component
  export default class GuideDetail extends Vue {
    detail = {}

    @Vue.autoLoading
    async created() {
      const isPreview = !!this.$route.query.preview;

      const { data } = await this.$fly.get(`guides/${this.$route.params.id}`, { is_preview: isPreview });
      this.detail = data;

      if (isPreview) {
        this.$message.warning({
          message: this.$t('preview_mode'),
          duration: 10000
        });
      }
    }
  }
</script>

<style lang="scss" scoped>
  .container {
    max-width: 800PX;
    padding-top: 15px;
  }

  .video {
    height: 400px;

    @include media-xs-only {
      height: 300px;
    }
  }

  .info-wrap {
    padding: 10px 0;
    border-bottom: 1px solid #d1cccc;

    .title {
      font-weight: bold;
      color: #000000;
    }

    .create-time {
      margin-top: 5px;
      font-size: 12px;
      color: #827f7f;
    }
  }
</style>
