<i18n src="./locales.json"></i18n>

<template>
  <div class="container flex item-center">
    <div class="wrapper flex-1">
      <v-title :value="$t('signup')"/>
      <div class="title">{{ title }}</div>

      <div class="steps flex content-between">
        <div :class="['step-item', { active: i <= step }]" v-for="i in 3" :key="i">{{ i }}</div>
      </div>

      <template v-if="step === 1">
        <div class="input-item first-item">
          <div class="label">{{ $t('phone') }}</div>
          <div class="select-wrap flex">
            <country-selector @excountry="handleChangeCountry" :toFront="toFrontArray"></country-selector>
            <el-input type="number" :placeholder="$t('phoneHolder')" v-model.trim="phone" @input="phoneError = ''">
              <div slot="prefix">{{ currentCountry.dialCode }}-</div>
            </el-input>
          </div>
          <div class="error" v-show="phoneError">* {{ phoneError }}</div>
        </div>
        <div class="input-item">
          <div class="label">{{ $t('validateCode') }}</div>
          <div class="validate-content">
            <el-input
              type="number"
              :placeholder="$t('validateHolder')"
              maxlength="6"
              v-model.trim="validateCode"
              @input="validateCodeError = ''"
            />

            <no-captcha
              id="signup"
              :before-open="() => handleValidate()"
              :disabled="disabledCodeBtn"
              :country-code="currentCountry.dialCode"
              :phone="phone"
              event="register"
              @success="sendCodeSuccess"
            >
              <template #default="{ disabled }">
                <div
                  class="primary-btn-o"
                  :class="{'disabled': disabled}"
                >
                  <span v-if="disabled">
                    <app-countdown
                      @completed="disabledCodeBtn = false"
                      :end-time="countdownTime"
                    >
                      <template v-slot="{ second }">
                        {{ $tc('countTime', second, { second }) }}
                      </template>
                    </app-countdown>
                  </span>
                  <span v-else>{{ $t('getValidateCode') }}</span>
                </div>
              </template>
            </no-captcha>
          </div>
          <div class="error code-error" v-show="validateCodeError">* {{ validateCodeError }}</div>
        </div>
        <div class="input-item">
          <div class="label">{{ $t('password') }}</div>
          <el-input
            type="password"
            show-password
            :placeholder="$t('password')"
            v-model="password"
            @input="passwordError = ''"
          />
          <div class="error" v-show="passwordError">* {{ passwordError }}</div>
        </div>
        <div class="input-item">
          <div class="label">{{ $t('confirmPassword') }}</div>
          <el-input type="password" show-password :placeholder="$t('password')" v-model="confirmPassword" @input="passwordError = ''"/>
          <div class="error tips">* {{ $t('passwordError') }}</div>
        </div>
      </template>

      <template v-if="step === 2">
        <div class="input-item first-item">
          <div class="label"><span class="star">*</span>{{ $t('lastName') }}</div>
          <el-input
            v-model="form.last_name"
            type="text"
            :placeholder="$t('lastName')"
            @input="lastNameError = ''"
            @blur="form.last_name = $event.target.value.trim()"
          />
          <div class="error" v-show="lastNameError">* {{ lastNameError }}</div>
        </div>
        <div class="input-item">
          <div class="label"><span class="star">*</span>{{ $t('name') }}</div>
          <el-input
            v-model="form.name"
            type="text"
            :placeholder="$t('name')"
            @input="nameError = ''"
            @blur="form.name = $event.target.value.trim()"
          />
          <div class="error" v-show="nameError">* {{ nameError }}</div>
        </div>
        <div class="input-item">
          <div class="label"><span class="star">*</span>{{ $t('region') }}</div>
          <el-select v-model="form.region" :placeholder="$t('region')">
            <el-option
              v-for="item in regionsStore.data"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            />
          </el-select>
        </div>
        <div class="input-item">
          <div class="label">{{ $t('email') }}</div>
          <el-input type="email" :placeholder="$t('email')" v-model.trim="form.email" @input="validateEmailTip = ''"/>
          <div class="error" v-show="validateEmailTip">* {{ validateEmailTip }}</div>
        </div>
        <div class="hint">*{{ $t('emailHint') }}</div>
      </template>

      <template v-if="step === 3">
        <div class="icon-success">
          <svg class="svg" width="100%" height="100%" viewBox="0 0 100 100">
            <circle fill="#000" cx="50" cy="50" r="50" />
            <polyline
              fill="none"
              stroke="#fff"
              stroke-width="6"
              stroke-linecap="round"
              stroke-linejoin="round"
              points="28,50 43,65 73,35"
            />
          </svg>
        </div>
        <div class="text-success">{{ $t('successText') }}</div>
      </template>

      <div class="submit-btn primary-btn" @click="handleSubmit">{{ btnText }}</div>
    </div>
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';
  import { regionsStore } from '@/store';
  import { VALIDATE_EMAIL, VALIDATE_PASSWORD } from '@/constants';
  import countrySelector from '@/components/country-selector';
  import dayjs from 'dayjs';

  @Component({
    components: {
      countrySelector
    }
  })
  export default class Signup extends Vue {
    regionsStore = regionsStore;
    validateEmailTip = ''
    step = 1;

    // step1 start
    toFrontArray = ['ja', 'cn', 'hk', 'tw'];
    currentCountry = {};
    phone = '';
    validateCode = '';
    password = '';
    passwordError = '';
    confirmPassword = '';
    phoneError = '';
    validateCodeError = '';
    disabledCodeBtn = false;
    countdownTime = dayjs().toString();
    // step1 end

    // step2 start
    form = {
      name: '',
      last_name: '',
      email: '',
      region: 'ja'
    };
    nameError = '';
    lastNameError = '';
    // step2 end

    get title() {
      switch (this.step) {
        case 1:
          return this.$t('biddingSignUp');
        case 2:
          return this.$t('personalInfo');
        case 3:
          return this.$t('completeRegistration');
        default:
          return '';
      }
    }

    get btnText() {
      switch (this.step) {
        case 1:
          return this.$t('signUp');
        case 2:
          return this.$t('completeRegistration');
        case 3:
          return this.$t('goHome');
        default:
          return '';
      }
    }

    // step1 start
    handleChangeCountry(item) {
      this.currentCountry = item;
    }

    @Vue.autoLoading
    async created() {
      return regionsStore.tryFetchData();
    }

    handleValidate() {
      if (!this.phone) {
        const msg = this.$t('phoneError');
        this.phoneError = msg;
        throw new Error(msg);
      }
    }

    sendCodeSuccess() {
      this.countdownTime = dayjs().add(60, 'second').toString();
      this.disabledCodeBtn = true;
    }

    async handleRegister() {
      if (this.phone.length === 0) {
        this.phoneError = this.$t('phoneError');
      }
      if (this.validateCode.length !== 6) {
        this.validateCodeError = this.$t('validateCodeError');
        return;
      }
      if (!this.password) {
        this.passwordError = this.$t('passwordEmpty');
        return;
      }
      if (!VALIDATE_PASSWORD.test(this.password)) {
        this.passwordError = this.$t('passwordError');
        return;
      }
      if (this.password !== this.confirmPassword) {
        this.passwordError = this.$t('notSame');
        return;
      }

      const phoneParams = {
        country_code: this.currentCountry.dialCode,
        phone: this.phone,
        code: this.validateCode,
        password: this.password,
        password_confirmation: this.confirmPassword
      };
      await this.$autoLoading(this.$authStore.register(phoneParams));
      this.step = 2;
    }
    // step1 end

    // step2 start
    async handleUpdateMine() {
      if (!this.form.name) {
        this.nameError = this.$t('nameHolder');
      }
      if (!this.form.last_name) {
        this.lastNameError = this.$t('lastNameHolder');
        return;
      }
      if (this.form.email && !VALIDATE_EMAIL.test(this.form.email)) {
        this.$message({ type: 'error', message: this.$t('validateEmailTip') });
        this.validateEmailTip = this.$t('validateEmailTip');
        return;
      }
      await this.$autoLoading(this.$authStore.updateMine(this.form));
      this.step = 3;
    }
    // step2 end

    async handleSubmit() {
      switch (this.step) {
        case 1:
          await this.handleRegister();
          break;
        case 2:
          await this.handleUpdateMine();
          break;
        case 3:
          this.$router.push({ name: 'home' });
          break;
      }
    }
  }
</script>

<style lang="scss" scoped>
  .container {
    max-width: 500px;
    padding: 44px 20px;
    text-align: center;

    @include media-sm-and-up {
      min-height: 100%;
      margin: 0 auto;
    }

    .title {
      font-weight: 500;
      font-size: 18px;
    }

    .steps {
      position: relative;
      margin-top: 30px;

      &::before {
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        border-top: 1px dotted #ccc;
        transform: translateY(-50%);
        content: '';
      }

      .step-item {
        z-index: 100;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        line-height: 20px;
        text-align: center;
        color: #fff;
        background: #ccc;

        &.active {
          background: #000;
        }
      }
    }

    .input-item {
      margin-top: 30px;

      &.first-item {
        margin-top: 60px;
      }

      .el-select {
        width: 100%;
      }

      .label {
        margin-bottom: 10px;
        font-weight: 500;
        text-align: start;

        .star {
          margin-right: 5px;
          color: $primary;
        }
      }

      .select-wrap {
        .el-input {
          margin-left: 5px;
        }

        /deep/ .el-input__inner {
          padding-left: 3.2rem;
        }

        /deep/ .el-input__prefix {
          left: 0;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          width: 3.2rem;
          color: #000;
        }
      }

      .validate-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .primary-btn-o {
          height: 30px;
          margin: 20px 0;
          font-size: 13px;
        }

        @include media-sm-and-up {
          flex-direction: row;

          .no-captcha {
            align-self: center;
          }

          .primary-btn-o {
            margin: 0 0 0 40px;
          }
        }
      }

      .error {
        margin-top: 15px;
        font-size: 11px;
        line-height: 15px;
        text-align: start;
        color: $primary;

        &.tips {
          color: $gray;
        }

        &.code-error {
          @include media-xs-only {
            margin-top: 0;
          }
        }
      }
    }

    .hint {
      margin-top: 25px;
      font-size: 11px;
      text-align: left;
      color: $primary;
    }

    .icon-success {
      width: 65px;
      height: 65px;
      margin: 106px auto 0;
    }

    .text-success {
      margin: 36px 0 85px;
      font-size: 13px;
      color: #999;
    }

    .submit-btn {
      margin: 20px auto;
      font-size: 18px;

      @include media-sm-and-up {
        margin: 66px 80px 0;
      }
    }
  }
</style>
