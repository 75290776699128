<template>
  <component :is="target" v-show="show">
    <slot v-bind="time" />
    <div v-if="showDate" class="date">{{ endTime | timeFormat }}</div>
  </component>
</template>

<script>
  import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
  import { countdown } from '@/utils';
  import dayjs from 'dayjs';

  @Component
  export default class Countdown extends Vue {
    @Prop({ type: [String, Date, Number], default: () => new Date() }) currentTime; // 当前时间
    @Prop(String) endTime; // 倒计时结束时间
    @Prop(Boolean) plusOneMinute; // 倒计时结束时间加1分钟，业务需求
    @Prop(Boolean) endHide; // 是否倒计时结束隐藏
    @Prop(Boolean) showDate; // 是否显示倒计时的目标日期
    @Prop({ type: String, default: 'span' }) target;

    secondsLeft = 0;

    get duration() {
      let day = dayjs(this.endTime);
      if (this.plusOneMinute) {
        day = day.add(1, 'minute');
      }
      return day.diff(dayjs(this.currentTime), 'second');
    }

    get time() {
      let remainings = this.secondsLeft;
      const oneDay = 24 * 60 * 60;
      const oneHour = 60 * 60;
      const oneMinute = 60;
      const day = Math.floor(remainings / oneDay);
      remainings = remainings - day * oneDay;
      const hour = Math.floor(remainings / oneHour);
      remainings = remainings - hour * oneHour;
      const minute = Math.floor(remainings / oneMinute);
      remainings = remainings - minute * oneMinute;
      const second = Math.floor(remainings / 1);
      return {
        remainings: this.secondsLeft,
        day,
        hour,
        minute,
        second
      };
    }

    get show() {
      if (this.endHide) {
        const remainings = this.time.remainings - (this.plusOneMinute ? 60 : 0);
        return remainings > 0;
      } else {
        return true;
      }
    }

    beforeDestroy() {
      this.handleClear();
    }

    handleClear() {
      if (this.countdown && this.countdown.abort) {
        this.countdown && this.countdown.abort();
      }
    }

    @Watch('duration', { immediate: true })
    onDurationChange() {
      this.handleClear();
      const onTick = secondsLeft => {
        this.secondsLeft = this.plusOneMinute ? secondsLeft + 60 : secondsLeft;
        this.$emit('change', this.time);
      };
      const onComplete = () => {
        this.secondsLeft = 0;
        this.$emit('completed');
      };
      const duration = this.plusOneMinute ? this.duration - 60 : this.duration;
      this.countdown = countdown(duration, onTick, onComplete);
    }
  }
</script>

<style lang="scss" scoped>
  .date {
    margin-top: 10px;
    font-size: 12px;
    text-align: center;
    color: $gray;
  }
</style>
