import Vue from 'vue';
import VueRouter from 'vue-router';
import _ from 'lodash';
import { i18n } from '@/i18n';
import { decodeQuery, encodeQuery } from '@/utils';
import { authStore, auctionHouseStore } from '@/store';
import { MessageBox, Message } from 'element-ui';
import { I18N_STORAGE_KEY } from '@/constants';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: require('@/views/home').default,
    meta: {
      setName: true,
      domainHouseAbilities: ['auction', 'store'],
    }
  },
  {
    path: '/auctions',
    component: require('@/views/router-view').default,
    children: [
      {
        path: '/auctions',
        name: 'auctions',
        component: require('@/views/auctions').default,
        meta: {
          setName: true
        }
      },
      {
        path: '/auctions/:id',
        name: 'auctionDetail',
        component: require('@/views/auctions/show').default,
        meta: {
          setName: true,
          domainHouseAbilities: ['auction'],
        }
      },
      {
        path: '/auctions/:id/live',
        name: 'auctionLive',
        meta: {
          setName: true,
          showTabBar: false,
          showFooterOnMobile: false,
          domainHouseAbilities: ['auction']
        },
        component: require('@/views/auctions/live').default,
      },
    ]
  },
  {
    path: '/products',
    component: require('@/views/router-view').default,
    children: [
      {
        path: '/products',
        name: 'products',
        component: require('@/views/products/index').default,
        meta: {
          setName: true,
          domainHouseAbilities: ['store'],
        }
      },
      {
        path: ':id',
        name: 'productDetail',
        component: require('@/views/products/show').default,
        meta: {
          setName: true,
          domainHouseAbilities: ['store'],
        }
      },
      {
        path: ':id/product_auction',
        name: 'productAuction',
        component: require('@/views/products/product-auction').default,
        meta: {
          setName: true,
          domainHouseAbilities: ['store'],
        }
      }
    ]
  },
  {
    path: '/store',
    name: 'store',
    component: require('@/views/store').default,
    meta: {
      setName: true
    }
  },
  {
    path: '/auction_items/:id',
    name: 'auctionItemDetail',
    component: require('@/views/auction-items/show').default,
    meta: {
      setName: true,
      domainHouseAbilities: ['auction'],
    }
  },
  {
    path: '/live_broadcasts',
    name: 'LiveBroadcasts',
    component: require('@/views/live-broadcasts').default,
    meta: {
      setName: true,
      domainHouseAbilities: ['auction'],
    }
  },
  {
    path: '/live_broadcasts/:id',
    name: 'liveBroadcastDetail',
    component: require('@/views/live-broadcasts/detail').default,
    meta: {
      setName: true,
      showFooterOnMobile: false,
      showTabBar: false,
      domainHouseAbilities: ['auction'],
    }
  },
  {
    path: '/auction_categories/:id',
    name: 'auctionCategoryDetail',
    component: require('@/views/auction-categories/show').default,
    meta: {
      setName: true,
      domainHouseAbilities: ['auction'],
    }
  },
  {
    path: '/shop_list',
    name: 'auctionHouse',
    component: require('@/views/auction-house').default,
    meta: {
      setName: true
    }
  },
  {
    path: '/shop_list/:id',
    name: 'auctionHouseDetail',
    component: require('@/views/auction-house/show').default,
    meta: {
      setName: true
    }
  },
  {
    path: '/domain_auction_house',
    name: 'DomainAuctionHouseDetail',
    component: require('@/views/domain-auction-house/show').default,
    meta: {
      setName: true,
      domainHouseAbilities: ['auction', 'store'],
    }
  },
  {
    path: '/transport_companies/:id',
    name: 'transportCompanies',
    component: require('@/views/transport-companies/show').default,
    meta: {
      setName: true,
      domainHouseAbilities: ['auction', 'store'],
    }
  },
  {
    path: '/mine',
    component: require('@/views/router-view').default,
    children: [
      {
        path: '/mine',
        name: 'mine',
        component: require('@/views/mine').default,
        meta: {
          auth: true,
          showFooterOnMobile: false,
          domainHouseAbilities: ['auction', 'store'],
        }
      },
      {
        path: 'settings',
        name: 'mySettings',
        component: require('@/views/mine/settings').default,
        meta: {
          showFooterOnMobile: false,
          auth: true,
          domainHouseAbilities: ['auction', 'store'],
        }
      },
      {
        path: 'address',
        name: 'myAddress',
        component: require('@/views/mine/address').default,
        meta: {
          setName: true,
          showFooterOnMobile: false,
          auth: true,
          domainHouseAbilities: ['auction', 'store'],
        }
      },
      {
        path: 'address-list',
        name: 'myAddressList',
        component: require('@/views/mine/address-list').default,
        meta: {
          setName: true,
          showFooterOnMobile: false,
          auth: true,
          domainHouseAbilities: ['auction', 'store'],
        }
      },
      {
        path: 'auction-number',
        name: 'myAuctionNumber',
        component: require('@/views/mine/auction-number').default,
        meta: {
          setName: true,
          showFooterOnMobile: false,
          auth: true,
          domainHouseAbilities: ['auction', 'store'],
        }
      },
      {
        path: 'wallet',
        component: require('@/views/router-view').default,
        children: [
          {
            path: '',
            name: 'myWallet',
            component: require('@/views/mine/wallet').default,
            meta: {
              auth: true,
              setName: true,
              showFooterOnMobile: false,
              domainHouseAbilities: ['auction', 'store'],
            },
          },
          {
            path: 'charge',
            name: 'charge',
            component: require('@/views/mine/charge').default,
            meta: {
              setName: true,
              showFooterOnMobile: false,
              domainHouseAbilities: ['auction', 'store'],
            }
          },
          {
            path: 'bills',
            name: 'bills',
            component: require('@/views/mine/bills').default,
            meta: {
              setName: true,
              showFooterOnMobile: false,
              domainHouseAbilities: ['auction', 'store'],
            }
          },
          {
            path: 'refund-applications',
            name: 'myRefundApplications',
            component: require('@/views/mine/refund-applications').default,
            meta: {
              setName: true,
              showFooterOnMobile: false,
              domainHouseAbilities: ['auction', 'store'],
            }
          }
        ]
      },
      {
        path: 'bidding',
        name: 'myBidding',
        component: require('@/views/mine/bidding').default,
        meta: {
          setName: true,
          showFooterOnMobile: false,
          domainHouseAbilities: ['auction', 'store'],
        }
      },
      {
        path: 'collection',
        name: 'myCollection',
        component: require('@/views/mine/collection').default,
        meta: {
          setName: true,
          showFooterOnMobile: false,
          domainHouseAbilities: ['auction', 'store'],
        }
      }
    ]
  },
  {
    path: '/auction-applications/new',
    name: 'auction-applications.new',
    component: require('@/views/mine/auction-applications/new').default,
    meta: {
      auth: false,
      showFooterOnMobile: false,
      domainHouseAbilities: ['auction', 'store'],
    }
  },
  {
    path: '/orders',
    name: 'orders',
    component: require('@/views/orders').default,
    meta: {
      setName: true,
      showFooterOnMobile: false,
      domainHouseAbilities: ['auction', 'store'],
    }
  },
  {
    path: '/orders/batch_list',
    name: 'orderBatchList',
    component: require('@/views/orders/batch-list').default,
    meta: {
      setName: true,
      showFooterOnMobile: false,
      domainHouseAbilities: ['auction', 'store'],
    }
  },
  {
    path: '/orders/batch_list/:id',
    name: 'orderBatchDetail',
    component: require('@/views/orders/batch-detail').default,
    meta: {
      setName: true,
      showFooterOnMobile: false,
      domainHouseAbilities: ['auction', 'store'],
    }
  },
  {
    path: '/orders/:id',
    name: 'orderDetail',
    component: require('@/views/orders/show').default,
    meta: {
      setName: true,
      showFooterOnMobile: false,
      domainHouseAbilities: ['auction', 'store'],
    }
  },
  {
    path: '/orders_pdf-preview',
    name: 'orderPdfPreview',
    component: require('@/views/orders/pdf-preview').default,
    meta: {
      setName: true,
      showFooterOnMobile: false,
      domainHouseAbilities: ['auction', 'store'],
    }
  },
  {
    path: '/product_categories',
    name: 'productCategories',
    component: require('@/views/product-categories').default,
    meta: {
      setName: true,
      domainHouseAbilities: ['store'],
    }
  },
  {
    path: '/message_channels',
    name: 'messageChannels',
    component: require('@/views/message-channels').default,
    meta: {
      setName: true,
      showFooterOnMobile: false,
      domainHouseAbilities: ['auction', 'store'],
    }
  },
  {
    path: '/message_channels/:id',
    name: 'messageChannelDetail',
    component: require('@/views/message-channels/show').default,
    meta: {
      setName: true,
      showTabBar: false,
      showFooter: false,
      domainHouseAbilities: ['auction', 'store'],
    },
  },
  {
    path: '/evaluations/new',
    name: 'newEvaluation',
    component: require('@/views/evaluations/new').default,
    meta: {
      setName: true,
      showFooterOnMobile: false,
      domainHouseAbilities: [ 'store'],
    }
  },
  {
    path: '/evaluations',
    name: 'evaluations',
    component: require('@/views/evaluations/list').default,
    meta: {
      setName: true,
      showFooterOnMobile: false,
      domainHouseAbilities: ['store'],
    }
  },
  {
    path: '/search',
    name: 'search',
    component: require('@/views/search/index').default,
    meta: {
      setName: true,
      showFooterOnMobile: false,
      domainHouseAbilities: ['auction', 'store'],
    }
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      showTabBar: false,
      showFooterOnMobile: false,
      domainHouseAbilities: ['auction', 'store'],
    },
    component: require('@/views/login').default
  },
  {
    path: '/signup',
    name: 'signup',
    meta: {
      showTabBar: false,
      showFooterOnMobile: false,
      domainHouseAbilities: ['auction', 'store'],
    },
    component: require('@/views/signup').default
  },
  {
    path: '/pages/:slug',
    name: 'pages',
    meta: {
      domainHouseAbilities: ['auction', 'store'],
    },
    component: require('@/views/pages').default
  },
  {
    path: '/topics',
    name: 'topics',
    component: require('@/views/topic').default,
    meta: {
      setName: true,
      domainHouseAbilities: ['auction', 'store'],
    }
  },
  {
    path: '/topic/:slug',
    name: 'topicDetail',
    component: require('@/views/topic/detail').default,
    meta: {
      setName: true,
      domainHouseAbilities: ['auction', 'store'],
    }
  },
  {
    path: '/guides',
    name: 'guides',
    component: require('@/views/guide').default,
    meta: {
      setName: true,
      domainHouseAbilities: ['auction', 'store'],
    }
  },
  {
    path: '/guides/:id',
    name: 'guideDetail',
    component: require('@/views/guide/detail').default,
    meta: {
      setName: true,
      domainHouseAbilities: ['auction', 'store'],
    }
  },
  {
    path: '/404',
    name: '404',
    meta: {
      domainHouseAbilities: ['auction', 'store'],
    },
    component: require('@/views/404').default
  },
  {
    path: '*',
    redirect: '/404'
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_ROUTER_BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return {
        x: 0,
        y: 0
      };
    }
  },
  parseQuery(query) {
    return decodeQuery(query);
  },
  stringifyQuery(query) {
    return encodeQuery(query);
  }
});

router.beforeEach(async (to, from, next) => {
  // 记录所有页面都上一个路由,用于【我的钱包】的返回按钮
  if (to.name !== from.name) {
    router.prevRoute = from;
  }

  const isWXEnvironment = window.__wxjs_environment === 'miniprogram';
  const isAppEnvironment = navigator.userAgent.includes('tokyoAuctionApp');
  if (isWXEnvironment || isAppEnvironment) {
    const lang = to.query.lang;
    if (lang && i18n.locale !== lang) {
      i18n.locale = lang;
      localStorage.setItem(I18N_STORAGE_KEY, lang);
    }
  }

  // 页面跳转前关闭所有弹窗
  // eslint-disable-next-line
  try { MessageBox.close() } catch (e) { }

  const page404 = { name: '404' };
  // 判断一下，否则会死循环
  if (to.name !== page404.name) {
    // 判断拍卖行子域名是否能查看当前页面，不能查看则跳到404页面
    const canShowCurrentPage = await auctionHouseStore.check(to);
    if (!canShowCurrentPage) {
      next(page404);
      return;
    }
  }

  if (_.get(to, 'meta.auth') && !_.get(to, 'meta.setName')) {
    if (authStore.isLogin) {
      await authStore.tryFetchData();
      return next();
    }
    Message(i18n.t('logFirst'));
    return next({ name: 'login', query: { redirect: encodeURIComponent(to.fullPath) } });
  }

  if (_.get(to, 'meta.setName')) {
    if (authStore.isLogin) {
      try {
        await authStore.tryFetchData();
      } catch (e) {
        MessageBox({
          title: '',
          message: e.message,
          type: 'info',
          showCancelButton: false,
          showClose: true
        });
        throw e;
      }
      if (!authStore.user.last_name || !authStore.user.name) {
        Message(i18n.t('ferfectInformation'));
        return next({ name: 'mySettings', query: { redirect: encodeURIComponent(to.fullPath) } });
      }
    }
  }
  next();
});

export default router;
