<template>
  <div class="live-card-wrapper prod-skeleton-animate">
    <div class="cover-wrapper">
      <auto-height
        class="prod-skeleton-block"
        :height="autoHeight" :oss-img="{ resize: { w: 1000 } }"
      />
      <div class="live-info flex content-between">
        <div class="prod-skeleton-block">1</div>
        <div class="prod-skeleton-block">1</div>
      </div>
    </div>
  </div>
</template>

<script>
  import { Vue, Component, Prop } from 'vue-property-decorator';

  @Component
  export default class LiveCard extends Vue {
    @Prop({ type: String, default: '50%' }) autoHeight;
  }
</script>

<style lang="scss" scoped>
  .live-card-wrapper {
    margin-bottom: 15px;
    border-radius: 2px;
    overflow: hidden;
    background: #ffffff;
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.09);

    /deep/ .auto-height-content {
      background-color: #f2f3f5;
    }

    .cover-wrapper {
      position: relative;
    }

    /deep/ .prod-skeleton-block {
      color: #fff;
      background-color: #fff;
    }

    .live-info {
      position: absolute;
      bottom: 15px;
      width: 100%;
      padding: 0 15px;
      line-height: 1.2;

      .prod-skeleton-block {
        width: 50%;

        &:last-of-type {
          width: 20%;
        }
      }
    }
  }
</style>
