<i18n src="./locales.json"></i18n>

<template>
  <div class="flex">
    <common-sidebar class="hidden-xs-only" />
    <div class="container">
      <v-title :value="$t('addresslist')"/>
      <div class="title">{{ $t('addresslist') }}</div>
      <div
        v-for="item in addressList"
        :key="item.id"
        class="info-card"
        :class="{ select: orderId }"
        @click="handleNavTo(item.id)"
      >
        <div class="header item">
          <div class="info">{{ getReceiverInfo(item) }}</div>
          <div v-if="item.is_default" class="default">{{ $t('defalut') }}</div>
        </div>
        <div class="footer item">
          <div class="detail">{{ getAddressInfo(item) }}</div>
          <div class="edit" @click.stop="handleShowAddressDialog(item, 'edit')">{{ $t('edit') }} &gt;</div>
        </div>
      </div>
      <div class="add-address" @click="handleShowAddressDialog">+ {{ $t('addAddress') }}</div>
    </div>

    <!-- 编辑地址弹窗 分添加地址和修改地址 -->
    <el-dialog
      class="dialog-change-address"
      :visible.sync="showChangeAddress"
      :width="addressDialogWidth"
      center
    >
      <div slot="title">{{ isEdit ? $t('changeAddress') : $t('addAddress') }}</div>
      <div class="change-address">
        <div class="input-content">
          <address-group :address="addressInfo" requireName/>
          <div class="flex content-center">
            <el-checkbox class="set-default" v-model="setDefault" @change="handleSetDefault">{{ $t('setDefaultAddress') }}</el-checkbox>
          </div>
        </div>
      </div>
      <div class="btn-wrapper">
        <div class="btn-confirm primary-btn" @click="handleConfirm(isEdit)">{{ $t('withdrawConfirm') }}</div>
      </div>
      <div v-if="isEdit" class="remove flex content-center" @click="handleDelete">
        <div>{{ $t('removeAddress') }}</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';
  import AddressGroup from '@/components/address-group.vue';
  import _ from 'lodash';

  @Component({ components: { AddressGroup } })
  export default class AddressList extends Vue {
    showChangeAddress = false
    setDefault = false
    isEdit = false // 地址弹窗是否为编辑状态
    addressList = []
    addressInfo = {} // 选中的当前地址的信息
    orderId = '' // 从订单详情跳过来的会给orderId赋值
    routerName = ''
    errorList = {
      last_name: '',
      receiver_name: '',
      country_name: '',
      detail_info: '',
      province_name: '',
      postal_code: '',
      city_name: '',
      tel_number: ''
    }

    get addressDialogWidth() {
      return this.$isMobile() ? '96%' : '552px';
    }

    @Vue.autoLoading
    created() {
      const { order_id, routerName = 'orderDetail' } = this.$route.query;
      this.orderId = order_id;
      this.routerName = routerName;
      return this.fetchData();
    }

    async fetchData() {
      const { data } = await this.$fly.get('addresses');
      this.addressList = data;
    }

    getReceiverInfo(item) {
      const { last_name = '', receiver_name = '', tel_number = '', postal_code = '' } = item;
      return last_name + [receiver_name, postal_code, tel_number].join(' ');
    }

    getAddressInfo(item) {
      const { country_name = '', province_name = '', city_name = '', detail_info = '', additional_detail_info = '' } = item;
      return [country_name, province_name, city_name, detail_info, additional_detail_info].join(' ');
    }

    // 从订单详情页跳过来的，点击地址需再跳到订单详情页
    handleNavTo(addressId) {
      if (this.orderId) {
        this.$router.replace({ name: this.routerName, params: { id: this.orderId }, query: { addressId } });
      }
    }

    handleSetDefault(value) {
      this.setDefault = value;
    }

    // 需判断地址弹窗类型，添加or修改
    handleShowAddressDialog(item, edit) {
      if (edit) {
        this.addressInfo = _.cloneDeep(item);
        this.isEdit = true;
      } else {
        this.addressInfo = {};
        this.isEdit = false;
      }
      this.setDefault = item.is_default;
      this.showChangeAddress = true;
    }

    @Vue.autoLoading
    async handleConfirm(edit) {
      let errorNames = Object.keys(this.errorList);
      for (let errorName of errorNames) {
        if (!this.addressInfo[errorName]) {
          this.errorList[errorName] = this.$t(errorName);
          setTimeout(() => {
            this.$message({ type: 'error', message: this.$t(errorName) });
          });
          return;
        }
      }
      if (edit) {
        await this.$fly.put(`addresses/${this.addressInfo.id}`, { ...this.addressInfo, is_default: this.setDefault });
      } else {
        await this.$fly.post('addresses', { ...this.addressInfo, is_default: this.setDefault });
      }
      this.showChangeAddress = false;
      this.fetchData();
    }

    @Vue.autoLoading
    async handleDelete() {
      await this.$confirm(this.$t('confirmRemoveAddress'));
      await this.$fly.delete(`addresses/${this.addressInfo.id}`);
      this.showChangeAddress = false;
      this.fetchData();
    }
  }
</script>

<style lang="scss" scoped>
  .title {
    padding-bottom: 24px;
    margin: 34px 0 0;
    border-bottom: 1px solid $border-light;
    font-weight: bold;
    font-size: 18px;
    color: #000;
  }

  .info-card {
    max-width: 420px;
    padding: 10px 12px;
    margin: 20px auto 0;
    border: 1px solid $border-light;
    border-radius: 4px;

    &.select {
      &:hover {
        background: #eee;
        cursor: pointer;
      }
    }

    .header {
      padding-bottom: 8px;
      font-weight: bold;

      .default {
        height: 18px;
        padding: 0 6px;
        border-radius: 4px;
        font-size: 10px;
        line-height: 18px;
        color: #fff;
        background: #000;
      }
    }

    .footer {
      font-size: 12px;
      color: $gray;

      .detail {
        max-width: 300px;

        @include media-xs-only {
          max-width: 260px;
        }
      }

      .edit {
        color: #999;
        cursor: pointer;
      }
    }

    .item {
      display: flex;
      justify-content: space-between;
    }
  }

  .add-address {
    max-width: 420px;
    margin: 22px auto 0;
    font-weight: bold;
    font-size: 16px;
    cursor: pointer;
  }

  /deep/ .dialog-change-address {
    border-radius: 19px;

    .el-dialog {
      border-radius: 19px;
    }

    .el-dialog__header {
      padding: 25px 0 15px;
      font-weight: 500;
      font-size: 16px;
    }

    .el-dialog__body {
      padding: 0 40px 20px;

      @include media-xs-only {
        padding: 0 10px 10px;
      }

      .set-default {
        margin-top: 20px;
        cursor: pointer;
      }

      .btn-wrapper {
        margin: 16px;

        @include media-xs-only {
          margin: 20px 12px 10px;
        }

        .btn-confirm {
          height: 38px;
          font-size: 15px;
          background: $primary;
        }
      }

      .remove {
        margin-bottom: 10px;
        cursor: pointer;
      }
    }
  }
</style>
