import { render, staticRenderFns } from "./pc-product-info.vue?vue&type=template&id=13a5ed7a&scoped=true&"
import script from "./pc-product-info.vue?vue&type=script&lang=js&"
export * from "./pc-product-info.vue?vue&type=script&lang=js&"
import style0 from "./pc-product-info.vue?vue&type=style&index=0&id=13a5ed7a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13a5ed7a",
  null
  
)

/* custom blocks */
import block0 from "../locales.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2FUsers%2Fquake%2Fworkspace%2Fauction%2Ftokyo-auction-frontend%2Fsrc%2Fviews%2Fproducts%2Fcomponents%2Fpc-product-info.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports