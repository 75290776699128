<i18n src="./locales.json"></i18n>

<template>
  <div>
    <auction-item-toggle-control
      class="hidden-xs-only"
      sticky
      :routeTo="{name: 'auctionDetail', params: { id: category.auction_id }}"
      :show-next="!!nextItem"
      :show-pre="!!preItem"
      :title="$t('toggleTitle')"
      @pre="handleNavigate('prev')"
      @next="handleNavigate('next')"
    >
      <template slot="pre">{{ $t('prevSession') }}</template>
      <template slot="next">{{ $t('nextSession') }}</template>
    </auction-item-toggle-control>
    <div class="container">
      <v-title :value="category.title" />
      <div class="cover-wrapper">
        <el-row :gutter="30">
          <el-col :xs="24" :sm="12">
            <div class="cover-content">
              <oss-img
                :resize="{ w: 750 }"
                :src="$get(category, 'image.url')"
                class="cover"
                @click="$previewImage($get(category, 'image.url'))"
              />
            </div>
          </el-col>
          <el-col :xs="24" :sm="12">
            <div class="info-wrapper">
              <div class="title">{{ category.title }}</div>
              <div class="info">
                <div>{{$t('lotDateAndPlace')}}: {{ category.place }}</div>
                <div>{{$t('lotNumber')}}: {{ category.auction_items_number_range }}</div>
                <div>{{$t('lotQty')}}: {{$tc('pieces', category.auction_items_count, {count:category.auction_items_count})}}</div>
              </div>
            </div>

            <collapsible-rich-text v-if="category.desc" :content="category.desc" richHeight="300" />
          </el-col>
        </el-row>
      </div>

      <participate-button
        v-show="!$get(category, 'auction.is_finished') & !number"
        :number="number"
        :quotas="quotas"
        :auction="{ ...category.auction, quota_currency: category.quota_currency }"
        @update-number="number = $event"
        pageType="auctionCategories"
      />

      <common-title class="common-title-wrap" :title="$t('auctionsView')" />

      <div class="filter-group">
        <el-row :gutter="30">
          <el-col :xs="24" :sm="12" class="item">
            <el-select :value="priceFilterValue" @change="handlePriceFilter" :placeholder="$t('priceFilter')">
              <el-option :label="$t('all')" value v-if="priceFilterValue"></el-option>
              <el-option :label="$t('firstPriceFilter')" value="1"></el-option>
              <el-option :label="$t('secondPriceFilter')" value="2"></el-option>
              <el-option :label="$t('thirdPriceFilter')" value="3"></el-option>
            </el-select>
          </el-col>
          <el-col :xs="24" :sm="12" class="item">
            <el-input type="search" v-model="keyword" :placeholder="$t('auctionSearch')" @keypress.enter.native="handleSearch">
              <img src="@/assets/icon-search.png" slot="suffix" class="icon" @click="handleSearch" />
            </el-input>
          </el-col>
        </el-row>
      </div>

      <div class="list-wrapper">
        <el-row :gutter="15">
          <el-col :xs="24" :sm="12" :md="8" v-for="item in store.data" :key="item.id">
            <router-link :to="{ name: 'auctionItemDetail', params: { id: item.id }, query: { auction_category_id_eq: $route.params.id } }">
              <auction-item-card
                class="item"
                :title="`LOT ${item.title}`"
                :cover="$get(item, 'cover.url')"
                :info="item.title"
              >
                <div slot="title" v-if="item.number">LOT {{ item.number }}</div>
                <div class="price">
                  <div v-if="item.is_finished && item.show_items_final_price && authStore.isLogin">
                    <div v-if="['hammered', 'force_hammered'].includes(item.status)">
                      <div>
                        {{$t('dealPrice')}}:
                        <span class="text-uppercase">{{category.currency}}</span>
                        {{ item.final_price | currencyFormat }}
                      </div>
                      <div v-if="!$options.filters.isLocaleCurrency(category.currency)">
                        {{$t('dealPrice')}}: {{$t('currency')}} {{ item.final_price | conversionFormat($get(category, 'currency')) | currencyFormat }}
                      </div>
                    </div>
                    <div v-else>
                       {{$t('aborted')}}
                    </div>
                  </div>
                  <div v-else-if="item.starting_price <= 0">
                    {{ $t('estimatePending') }}
                  </div>
                  <div v-else>
                    <div>
                      {{$t('startPrice')}}:
                      <span class="text-uppercase">{{category.currency}}</span>
                      {{ item.starting_price | currencyFormat }}
                    </div>
                    <div v-if="!$options.filters.isLocaleCurrency(category.currency)">
                      {{$t('startPrice')}}: {{$t('currency')}} {{ item.starting_price | conversionFormat($get(category, 'currency')) | currencyFormat }}
                    </div>
                  </div>
                </div>
              </auction-item-card>
            </router-link>
          </el-col>
        </el-row>
        <div class="categories-list-empty" v-if="store.isEmpty">
          <img src="@/assets/icon-select-empty.png" />
          <div>{{ $t('categoriesListEmpty') }}</div>
        </div>
        <pagination route-mode class="pagination" :store="store" />
      </div>
    </div>

    <app-fixed-bottom class="hidden-sm-and-up">
      <auction-item-toggle-control
        :routeTo="{name: 'auctionDetail', params: { id: category.auction_id }}"
        :show-next="!!nextItem"
        :show-pre="!!preItem"
        :title="$t('toggleTitle')"
        @pre="handleNavigate('prev')"
        @next="handleNavigate('next')"
      >
        <template slot="pre">{{ $t('prevSession') }}</template>
        <template slot="next">{{ $t('nextSession') }}</template>
      </auction-item-toggle-control>
    </app-fixed-bottom>
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';
  import { AuctionItems, authStore } from '@/store';
  import ParticipateButton from '@/components/auction/participate-button.vue';
  import _ from 'lodash';

  @Component({ components: { ParticipateButton } })
  export default class CategoriesShow extends Vue {
    keyword = '';
    category = {};
    auctionCategories = [];
    currentIndex = 0;
    number = ''
    quotas = []

    authStore = authStore;

    get priceFilterValue() {
      const val = this.$route.query.price_filter;
      return val ? String(val) : '';
    }

    get query() {
      const priceQuery =
        {
          '1': {
            starting_price_gteq: undefined,
            starting_price_lteq: 10 * 10000
          },
          '2': {
            starting_price_gteq: 10 * 10000,
            starting_price_lteq: 50 * 10000
          },
          '3': {
            starting_price_gteq: 50 * 10000,
            starting_price_lteq: undefined
          }
        }[String(this.$route.query.price_filter)] || {};

      return _.merge({}, _.omit(this.$route.query, 'price_filter'), priceQuery, {
        auction_category_id_eq: this.$route.params.id
      });
    }

    get preItem() {
      return this.auctionCategories[this.currentIndex - 1];
    }

    get nextItem() {
      return this.auctionCategories[this.currentIndex + 1];
    }

    store = { data: [] };

    @Vue.autoLoading
    async created() {
      this.store = AuctionItems.findOrCreate(this.$route.params.id);
      this.keyword = this.$route.query.keyword;
      _.set(this.store, 'params', { per_page: 30, ...this.query });
      await Promise.all([
        this.fetchCategory(),
        this.store.fetchData()
      ]);
      await Promise.all([
        this.fetchAuctionCategory(),
        this.fetchMyNumber()
      ]);
    }

    async fetchCategory() {
      const { id } = this.$route.params;
      const { data } = await this.$fly.get(`auction_categories/${id}`);
      this.category = data;
    }

    async fetchAuctionCategory() {
      const { data } = await this.$fly.get('/auction_categories', {
        auction_id_eq: this.category.auction_id,
        page: 0
      });
      this.auctionCategories = data;
      this.currentIndex = data.findIndex(v => v.id === this.category.id);
    }

    async fetchMyNumber() {
      if (authStore.isLogin) {
        const { data: { number, quotas } } = await this.$fly.get('mine/auction_number', {
          auction_id: this.category.auction_id
        });
        this.number = number;
        this.quotas = quotas;
      }
    }

    handleNavigate(type) {
      const { id } = type === 'next' ? this.nextItem : this.preItem;
      this.$router.replace({ params: { id } });
    }

    handlePriceFilter(e) {
      this.$router.replace({
        query: _.merge({}, this.$route.query, { price_filter: e, page: 1 })
      });
    }

    handleSearch() {
      if (this.keyword === this.$route.query.keyword) {
        return;
      }
      this.$router.replace({
        query: _.merge({}, this.$route.query, { keyword: this.keyword, page: 1 })
      });
    }

    handleBack() {
      this.$router.go(-1);
    }
  }
</script>

<style lang="scss" scoped>
  .cover-content {
    @include media-xs-only {
      margin: 0 -20px;
    }

    .cover {
      width: 100%;
      height: auto;
    }
  }

  .cover-wrapper {
    @include media-sm-and-up {
      padding: 24px 0;
    }

    .info-wrapper {
      @include media-xs-only {
        padding: 16px 0;
      }

      .title {
        margin-bottom: 22px;
        font-weight: bold;
        font-size: 20px;
        color: #000;
      }

      .info {
        font-size: 14px;
        line-height: 24px;
        color: #666;
      }
    }
  }

  .common-title-wrap {
    margin: 30px 0 20px -20px;

    @include media-xs-only {
      margin: 10px 0 20px -20px;
    }
  }

  .filter-group {
    padding: 12px 0;

    .item {
      margin-bottom: 9px;

      /deep/ .el-select {
        display: block;

        .el-select-dropdown__item.selected {
          color: #000;
        }
      }

      /deep/ .el-select__caret.el-input__icon.el-icon-arrow-up {
        font-weight: bold;
        font-size: 16px;
        color: #000;
      }

      /deep/ .el-input {
        outline: none;

        .el-input__inner {
          border-color: #ddd !important;
        }

        .el-input__suffix {
          display: flex;
          align-items: center;
        }
      }

      .icon {
        width: 15px;
        height: 15px;
        padding: 2px 5px 0 0;
      }
    }
  }

  .list-wrapper {
    .item {
      margin-bottom: 12px;
    }

    .pagination {
      margin: 6px 0;
    }

    /deep/ .auction-item-card .info {
      margin-top: 3px;
    }

    .categories-list-empty {
      margin: 46px auto 57px;
      text-align: center;
      color: #999;

      > img {
        width: 65px;
        height: 65px;
        margin-bottom: 36px;
      }
    }
  }
</style>
