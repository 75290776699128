<i18n src="../locales.json"></i18n>

<template>
  <div v-if="isShowBottomPrice" class="low-price-box" :class="{ mobile }">{{ bottomPriceTip }}</div>
</template>

<script>
  import { Vue, Component, Prop } from 'vue-property-decorator';

  @Component
  export default class BottomPrice extends Vue {
    @Prop({ type: Object, default: () => ({}) }) product
    @Prop(Boolean) mobile

    get isShowBottomPrice() {
      const { bottom_price, confirmed_price, current_price, started_price } = this.product;
      if (bottom_price === 0) { return true; }
      // 在后台有设置底价 && 未到结束时间 && 不是一口价
      return bottom_price && (!confirmed_price || current_price || started_price);
    }

    get bottomPriceTip() {
      const { bottom_price, current_price, started_price, product_bidding_prices_count } = this.product;
      if (bottom_price === 0) { return `(${this.$t('NoBottomPrice')})`; }

      const isPlural = (this.$i18n.locale === 'en' && product_bidding_prices_count > 1) ? 's' : '';
      let isReservePriceReached = false;

      if (bottom_price <= (current_price || started_price)) {
        isReservePriceReached = true;
      }
      return `(${product_bidding_prices_count} ${this.$t('timesBid')}${isPlural}, ${isReservePriceReached ? this.$t('ReservePriceReached') : this.$t('NotReachedReservePrice')})`;
    }
  }
</script>

<style lang="scss" scoped>
  .low-price-box {
    margin-right: 5px;
    font-size: 12px;
    color: #666;

    &.mobile {
      margin-top: 5px;
      margin-right: 0;
      text-align: right;
    }
  }
</style>
