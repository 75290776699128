<i18n>
{
  "zh_cn": {
    "started": "距开始",
    "ended": "距结束",
    "hour": "时",
    "minute": "分"
  },
  "zh_hk": {
    "started": "距開始",
    "ended": "距結束",
    "hour": "時",
    "minute": "分"
  },
  "en": {
    "started": "start:",
    "ended": "end:",
    "hour": "h",
    "minute": "min left"
  },
  "ja": {
    "started": "開始まで",
    "ended": "終了まで",
    "hour": "時",
    "minute": "分"
  }
}
</i18n>

<template>
  <div class="product-countdown" v-if="showStartCountDown || showEndCountDown">
    <div class="countdown" v-if="daysLeft > 0" v-html="countdownText"></div>
    <div class="countdown" v-else-if="dayjs(data.ended_bidding_time).isAfter(dayjs()) || dayjs(data.started_bidding_time).isAfter(dayjs())">
      <app-countdown
        :key="showTime"
        end-hide plusOneMinute target="div" :end-time="showTime"
      >
        <template v-slot="{ hour, minute }">
          <div class="countdown">
            <span>{{ timeText }}</span>
            <span class="primary"> {{ hour }} </span>{{ $t('hour') }}
            <span class="primary"> {{ minute }} </span>{{ $t('minute') }}
          </div>
        </template>
      </app-countdown>
    </div>
  </div>
</template>

<script>
  import { Vue, Component, Prop } from 'vue-property-decorator';
  import dayjs from 'dayjs';

  @Component
  export default class ProductCountdown extends Vue {
    @Prop({ type: Object, default: () => ({}) }) data;

    dayjs = dayjs

    get daysLeft() {
      if (this.showStartCountDown) {
        return dayjs(this.data.started_bidding_time).diff(dayjs(), 'day');
      } else if (this.showEndCountDown) {
        return dayjs(this.data.ended_bidding_time).diff(dayjs(), 'day');
      } else {
        return '';
      }
    }

    get isFinished() {
      return dayjs(this.data.ended_bidding_time).diff(dayjs()) < 0;
    }

    get isNotStart() {
      return dayjs().diff(dayjs(this.$get(this.data, 'started_bidding_time'))) < 0;
    }

    get showStartCountDown() {
      return this.$get(this.data, 'started_bidding_time') && this.isNotStart;
    }

    get showEndCountDown() {
      return this.$get(this.data, 'ended_bidding_time') && !this.isFinished && !this.showStartCountDown;
    }

    get showTime() {
      if (this.showStartCountDown) {
        return this.$get(this.data, 'started_bidding_time');
      } else if (this.showEndCountDown) {
        return this.$get(this.data, 'ended_bidding_time');
      } else { return ''; }
    }

    get timeText() {
      if (this.showStartCountDown) {
        return this.$t('started');
      } else if (this.showEndCountDown) {
        return this.$t('ended');
      } else {
        return '';
      }
    }

    get countdownText() {
      return {
        zh_cn: `${this.timeText}还剩<span class="primary"> ${this.daysLeft} </span>天`,
        zh_hk: `${this.timeText}還剩<span class="primary"> ${this.daysLeft} </span>天`,
        en: `${this.timeText}<span class="primary"> ${this.daysLeft} </span> days left`,
        ja: `${this.timeText}<span class="primary"> ${this.daysLeft} </span>日`
      }[this.$i18n.locale];
    }
  }
</script>

<style lang="scss" scoped>
  .countdown {
    font-size: 11px;
    color: #666;

    @include media-xs-only {
      font-size: 9px;
    }

    /deep/ .primary {
      font-size: 16px;
      color: $primary;

      @include media-xs-only {
        font-size: 12px;
      }
    }
  }
</style>
