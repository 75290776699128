<i18n>
{
  "zh_cn": {
    "NotReachedReservePrice": "未达底价",
    "ReservePriceReached": "已达底价",
    "NoBottomPrice": "无底价"
  },
  "zh_hk": {
    "NotReachedReservePrice": "未達底價",
    "ReservePriceReached": "已達底價",
    "NoBottomPrice": "無底價"
  },
  "en": {
    "NotReachedReservePrice": "Reserve not met",
    "ReservePriceReached": "Reserve met",
    "NoBottomPrice": "No reserve"
  },
  "ja": {
    "NotReachedReservePrice": "予約価格に達していません",
    "ReservePriceReached": "予約価格に達しました",
    "NoBottomPrice": "成り行き"
  }
}
</i18n>

<template>
  <div class="component-target-type-card" @click="handleNavDetail">
    <div class="cover-content">
      <div :class="['label', label.class]">{{ label.text }}</div>
      <img class="cover" :src="imageSrc" alt="">
    </div>
    <div class="content-wrapper">
      <div>
        <div class="flex content-between">
          <div class="title text-overflow"><span v-if="number">LOT{{ number }}</span> {{ title }}</div>
          <img v-if="collection" class="icon" :src="collectIconPath" @click.stop="handleFollow" />
        </div>
        <div class="info text-overflow" v-if="categoryName">{{ categoryName }}</div>
      </div>
      <div>
        <div v-if="isShowBottomPrice" class="low-price-box" :class="bottomPrice.className">
          <div class="text-overflow">{{ bottomPrice.label }}</div>
        </div>
        <slot/>
      </div>
    </div>
  </div>
</template>

<script>
  import { Vue, Component, Prop } from 'vue-property-decorator';
  import { followProductIdsStore } from '@/store';
  import iconCollect from '@/assets/icon-collect-red.png';
  import iconCollected from '@/assets/icon-collected-red.png';

  @Component
  export default class TargetTypeCard extends Vue {
    @Prop({ type: String, default: 'AuctionItem' }) targetType;
    @Prop(String) title
    @Prop(String) cover
    @Prop(String) number
    @Prop(String) categoryName
    @Prop(Number) id
    @Prop(Boolean) collection // 是否显示收藏功能
    @Prop({ type: Object, default: () => ({}) }) product;
    @Prop({ type: Object, default: () => ({}) }) productQuery; // 传我的收藏和竞投记录的请求参数

    resize = { w: 210 }
    isFollow = true

    get OSSProcess() {
      const keys = Object.keys(this.resize);
      return `image/resize,${keys.map(item => `${item}_${this.resize[item]}`).join(',')}`;
    }

    get label() {
      return {
        AuctionItem: { class: 'auction', text: this.$t('auctionItem') },
        Product: { class: 'normal', text: this.$t('normalItem') },
      }[this.targetType] || {};
    }

    get collectIconPath() {
      return this.isFollow ? iconCollected : iconCollect;
    }

    get imageSrc() {
      return `${this.cover}?x-oss-process=${this.OSSProcess}`;
    }

    get isShowBottomPrice() {
      const { bottom_price, confirmed_price, current_price, started_price } = this.product;
      if (bottom_price === 0) { return true; }

      // 在后台有设置底价 && 不是一口价
      return bottom_price && (!confirmed_price || current_price || started_price);
    }

    get bottomPrice() {
      const { bottom_price, current_price, started_price } = this.product;
      if (bottom_price === 0) { return { label: this.$t('NoBottomPrice'), className: 'no-bottom-price' }; }

      if (bottom_price <= (current_price || started_price)) {
        return { label: this.$t('ReservePriceReached'), className: 'reserve-price-reached' };
      }
      return { label: this.$t('NotReachedReservePrice'), className: 'not-reached-reserve-price' };
    }

    handleNavDetail() {
      this.$router.push({ name: this.targetType === 'AuctionItem' ? 'auctionItemDetail' : 'productDetail', params: { id: this.id }, query: { ...this.productQuery } });
    }

    async handleFollow() {
      const method = this.isFollow ? 'delete' : 'post';
      await this.$autoLoading(this.auctionsAction(method, 'follow'));
      this.isFollow = !this.isFollow;
      // 更新已关注商品的ids
      if (this.targetType === 'Product') {
        if (this.isFollow) {
          followProductIdsStore.data.push(this.id);
        } else {
          const index = followProductIdsStore.data.indexOf(this.id);
          index > -1 && followProductIdsStore.data.splice(index, 1);
        }
      }
      this.$message(
        {
          type: 'success',
          message: this.isFollow ? this.$t('followSucceeded') : this.$t('unfollowSuccessfully')
        }
      );
    }

    // 拍品和商品的关注与取消
    auctionsAction(methods, action_type) {
      return this.$fly[methods]('action_stores', {
        action_type: action_type,
        target_type: this.targetType,
        target_id: this.id
      });
    }
  }
</script>

<style lang="scss" scoped>
  .component-target-type-card {
    display: flex;
    height: 150px;
    padding-right: 2px;
    margin-bottom: 9px;
    border-radius: 2px;
    background: #ffffff;
    box-shadow: 0 0 10px 2px rgba(#000, 0.09);

    @include media-xs-only {
      height: auto;
    }

    .cover-content {
      position: relative;
      width: 150px;
      height: 150px;
      background: #eee;

      .label {
        position: absolute;
        z-index: 1;
        top: 10px;
        left: 10px;
        height: 11px;
        padding: 0 4px;
        border-radius: 2px;
        font-size: 7px;
        line-height: 11px;
        color: #fff;
        background: $primary;

        &.normal {
          background: #a58b67;
        }
      }

      .cover {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }


    .content-wrapper {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: space-between;
      min-width: 0;
      padding: 8px 8px 8px 15px;
      font-size: 11px;
      color: #666;

      @include media-xs-only {
        padding: 12px 8px 8px 15px;
      }

      .title {
        margin: 0 0 7px;
        font-weight: 500;
        font-size: 13px;
        color: #000;
      }

      .icon {
        width: 18px;
        height: 18px;
      }

      .info {
        width: fit-content;
        padding: 1px 4px;
        border: 1px solid $primary;
        border-radius: 2px;
        font-size: 9px;
        color: $primary;
      }
    }
  }

  .low-price-box {
    display: inline-block;
    margin: 5px 0;
    font-size: 12px;
    color: #666;

    &.no-bottom-price {
      padding: 2px 3px;
      border-radius: 5px;
      color: #fff;
      background: #2e815e;
    }
  }
</style>
