<i18n src="./locales.json"></i18n>

<template>
  <div class="wrapper flex column">
    <div class="container main flex column">
      <div class="flex-1 flex column" ref="container" style="overflow: hidden;">
        <div class="header flex" ref="header">
          <img class="cover no-shirk" :src="$get(store, 'channel.cover.url')" v-if="$get(store, 'channel.cover.url')"/>
          <img class="default-cover no-shirk" src="@/assets/sysmsg-avatar-default.png" v-else/>
          <div class="content">
            <div class="name">{{ $get(store, 'channel.communicator_name') }}</div>
            <div class="current text-overflow">
              <span v-if="targetType === 'RefundApplication'">
                <router-link :to="{ name: 'myRefundApplications' }">{{ $t('refundResult') }} &gt;</router-link>
              </span>
              <span v-if="targetType === 'User'">
                {{ $t('contact') }}
              </span>
              <span v-if="targetType === 'BatchSettlement'">
                <router-link :to="{ name: 'orderBatchDetail', params: { id: currentId } }">{{ $t('NavToOrderBatchDetail') }} &gt;</router-link>
              </span>
              <span v-if="targetType === 'Order' && $get(store, 'channel.target.orderable_type') === 'AuctionItem'">
                {{ $t('currentLot') }}：<router-link :to="{ name: 'orderDetail', params: { id: currentId } }">{{ $get(store, 'channel.target.orderable.title') }} &gt;</router-link>
              </span>
              <span v-if="targetType === 'Order' && $get(store, 'channel.target.orderable_type') === 'Product'">
                {{ $t('currentProduct') }}：<router-link :to="{ name: 'orderDetail', params: { id: currentId } }">{{ $get(store, 'channel.target.orderable.title') }} &gt;</router-link>
              </span>
              <span v-if="targetType === 'Auction'">
                {{ $t('currentAuction') }}：<router-link :to="{ name: 'auctionDetail', params: { id: currentId } }">{{ $get(store, 'channel.target.title') }} &gt;</router-link>
              </span>
              <span v-if="targetType === 'Product'">
                {{ $t('currentProduct') }}：<router-link :to="{ name: 'productDetail', params: { id: currentId } }">{{ $get(store, 'channel.target.title') }} &gt;</router-link>
              </span>
            </div>
          </div>
        </div>

        <div class="scroll-view flex-1" ref="scrollView">
          <div class="load-more">
            <el-button class="load-more-btn" @click="handleLoadMore" v-if="store.canLoadMore">{{ $t('loadMore') }}
            </el-button>
          </div>

          <div class="item" :class="['item flex item-end', { self: item.sent_by_user }]" v-for="item in store.messages" :key="item.id">
            <div class="item-content">
              <div class="time" :class="{'time-end': !item.sent_by_user}">{{ item.updated_at | timeFormat('YYYY-MM-DD HH:mm:ss') }}</div>

              <div class="attachment-wrap flex item-center">
                <div v-if="item.attachment" class="content">
                  <!-- 图片类型 -->
                  <img v-if="getContentType(item) === 'image'"
                    class="image-file pointer" :src="item.attachment.url"
                    @click="$previewImage(item.attachment.url)"
                  />
                  <!-- pdf文件类型 -->
                  <div v-else-if="getContentType(item) === 'pdf'"
                    class="flex item-center pointer"
                    @click="download({ url: item.attachment.url, filename: item.attachment.filename, mimetype: item.attachment.content_type })"
                  >
                    <img src="@/assets/icon-pdf.png" class="pdf-icon" alt="">
                    <div class="pdf-filename text-overflow-2">{{ item.attachment.filename }}</div>
                  </div>
                </div>
                <div v-else class="content">{{ item.content }}</div>
              </div>

              <div class="readed" v-if="item.id <= store.channel.last_admin_read_message_id">{{ $t('readed') }}</div>
              </div>

          </div>
          <!-- 非系统消息显示结案提醒 -->
          <div v-if="!$get(store, 'channel.is_system_message') && $get(store, 'channel.is_closed')" class="is-closed-hint">
            <hr><span>{{ $t('isClosedHint') }}</span><hr>
          </div>
        </div>
      </div>

      <div class="box-input flex item-center">
        <input
          class="input flex-1"
          type="text"
          v-model.trim="content"
          @keyup.enter="handleSubmit"
          :disabled="disabled" />
        <div class="upload-file no-shirk">
          <img class="icon-upload" src="@/assets/icon-upload-file.png"/>
          <input
            class="input-file" id="file" type="file" accept="image/*, application/pdf" v-if="!disabled"
            @change="handleUploadImage"
          />
          <label class="file" for="file"/>
        </div>
        <div :class="['btn-send', 'no-shirk', { disabled: disabled || !content }]" @click="handleSubmit">{{ $t('sent') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
  import { Component, Vue, Watch } from 'vue-property-decorator';
  import { authStore } from '@/store';
  import { uploadFile, download } from '@/utils';
  import Store from './store';

  // 获取手机浏览器高度，使用 100vh 会包含导航栏
  function getBrowserInterfaceSize() {
    let pageWidth = window.innerWidth;
    let pageHeight = window.innerHeight;

    if (typeof pageWidth !== 'number') {
      //在标准模式下面
      if (document.compatMode === 'CSS1Compat') {
        pageWidth = document.documentElement.clientWidth;
        pageHeight = document.documentElement.clientHeight;
      } else {
        pageWidth = document.body.clientWidth;
        pageHeight = window.body.clientHeight;
      }
    }

    return {
      pageWidth: pageWidth,
      pageHeight: pageHeight
    };
  }

  @Component
  export default class messageChannelDetail extends Vue {
    content = ''
    store = {}
    scrollHeight = 0
    isLoadMore = false

    download = download

    @Watch('store.messages.length')
    handler() {
      const scrollElement = this.$refs.scrollView;
      if (scrollElement && !this.isLoadMore) {
        this.$nextTick(() => {
          scrollElement.scrollTop = scrollElement.scrollHeight;
        });
      }
      this.isLoadMore = false;
    }

    get disabled() {
      return this.$get(this.store, 'channel.is_system_message');
    }

    get currentId() {
      return this.$get(this.store, 'channel.target.id');
    }

    get targetType() {
      return this.$get(this.store, 'channel.target_type');
    }

    @Vue.autoLoading
    async created() {
      const { id } = this.$route.params;
      this.store = new Store(id);
      this.store.initSubscription();
      await this.store.fetchData();
      await authStore.fetchData();
    }

    mounted() {
      this.setPageHeight();
      window.addEventListener('resize', this.setPageHeight);
    }

    beforeDestroy() {
      document.querySelector('#app').style.removeProperty('height');
      window.removeEventListener('resize', this.setPageHeight);
      this.store.disconnect();
    }

    getContentType(item) {
      const contentType = this.$get(item, 'attachment.content_type');
      if (/image/gi.test(contentType)) {
        return 'image';
      } else if (/pdf/gi.test(contentType)) {
        return 'pdf';
      }
      return 'other';
    }

    setPageHeight() {
      document.querySelector('#app').style.height = `${getBrowserInterfaceSize().pageHeight}px`;
    }

    async handleUploadImage(e) {
      if (this.disabled) {
        return;
      }
      const file = e.target.files[0];
      const { signed_id } = await this.$autoLoading(uploadFile(file));
      this.store.createMessage({ attachment: signed_id });
    }

    async handleSubmit() {
      if (this.disabled || !this.content) {
        return;
      }
      this.store.createMessage({ content: this.content });
      this.content = '';
    }

    handleLoadMore() {
      this.isLoadMore = true;
      this.store.getMessages();
    }
  }
</script>

<style lang="scss">
  #app.page-name-messageChannelDetail {
    min-height: 10px;

    .page-content-wrapper {
      overflow: hidden;
    }
  }
</style>

<style lang="scss" scoped>
  .wrapper {
    position: relative;
    height: 100%;
  }

  .container {
    position: relative;
    height: 100%;

    @include media-sm-and-up {
      box-shadow: 0px 0px 13px 2px rgba(0, 0, 0, 0.09);
    }

    .header {
      padding: 28px 0 14px;
      border-bottom: 1px solid #ddd;

      @include media-sm-and-up {
        padding: 14px 0;
        border-bottom: none;
        box-shadow: 0px 0px 13px 2px rgba(0, 0, 0, 0.09);
      }

      .cover {
        width: 60px;
        height: 60px;
        background: #eee;
        object-fit: cover;
      }

      .default-cover {
        box-sizing: border-box;
        width: 60px;
        height: 60px;
        border: 1px solid #eee;
        background: #eee;
        object-fit: contain;
      }

      .content {
        margin-left: 14px;

        @include media-sm-and-up {
          margin-left: 20px;
        }

        .name {
          margin-top: 5px;
          font-weight: 500;
          font-size: 18px;
        }

        .current {
          margin-top: 10px;
          color: #666;

          a {
            text-decoration-line: underline;
            color: $primary;
            cursor: pointer;
          }
        }
      }
    }

    .scroll-view {
      box-sizing: border-box;
      width: 100%;
      padding: 10px 15px;
      overflow-y: auto;

      @include media-sm-and-up {
        padding: 10px 25px;
      }

      .load-more {
        width: 100%;
        text-align: center;

        .load-more-btn {
          width: 100%;
          border: 1px solid transparent;
          font-size: 12px;
        }

        .load-more-btn:hover {
          font-size: 12px;
          color: black;
          background-color: #eee;
        }

        .no-more {
          font-size: 12px;
        }
      }

      .item {
        position: relative;
        display: flex;
        justify-content: flex-start;
        margin: 22px 0;

        &.self .readed {
          display: block;
        }

        &.self {
          justify-content: flex-end;

          .content {
            border-radius: 15px 0px 15px 15px;
            color: #fff;
            background: #000;
          }

          .item-content {
            justify-content: flex-end;
          }

          .attachment-wrap {
            justify-content: flex-end;
          }
        }

        .item-content {
          display: flex;
          align-items: flex-end;
          width: 50%;

          @include media-xs-only {
            display: block;
            width: 70%;
          }
        }

        .content {
          padding: 8px 14px 10px 16px;
          border-radius: 0px 15px 15px;
          font-size: 13px;
          background: #eee;
        }

        .image-file {
          width: 150px;
          height: 100px;
          object-fit: contain;
        }

        .time {
          margin: 0 20px;
          font-size: 11px;
          text-align: right;
          white-space: nowrap;
          color: $gray;

          @include media-xs-only {
            margin: 0 10px;
          }

          &.time-end {
            order: 2;
            text-align: left;
          }
        }

        .readed {
          position: absolute;
          bottom: -2px;
          right: 4px;
          display: none;
          font-size: 12px;
          color: #ccc;
          transform: translateY(100%);
        }

        .pdf-icon {
          width: 30px;
          height: 30px;
          margin-right: 10px;
        }
      }

      .is-closed-hint {
        display: flex;
        align-items: center;
        margin-top: 30px;

        hr {
          flex-grow: 1;
          border-top: 1px solid #999;
        }

        span {
          max-width: 80%;
          padding: 0 10px;
          color: #999;
        }
      }
    }

    .box-input {
      padding: 18px 16px 15px 22px;
      margin: 0 -15px;
      background: #fff;
      box-shadow: 0px -1px 4px 0px rgba(173, 191, 207, 0.21);

      @include media-sm-and-up {
        box-shadow: none;
      }

      .input {
        height: 35px;
        padding: 0 15px;
        border: none;
        border-radius: 18px;
        outline: none;
        background: #eee;
      }

      .upload-file {
        position: relative;
        width: 24px;
        height: 24px;
        margin: 0 10px;

        @include media-sm-and-up {
          margin: 0 20px;
        }

        .icon-upload {
          width: 24px;
          height: 24px;
        }

        .input-file {
          display: none;
        }

        .file {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
        }
      }

      .btn-send {
        box-sizing: border-box;
        height: 25px;
        padding: 0 18px;
        border-radius: 13px;
        font-size: 12px;
        line-height: 25px;
        color: #fff;
        background: #000;
        cursor: pointer;

        &.disabled {
          background: #999;
        }

        @include media-sm-and-up {
          height: 30px;
          padding: 0 25px;
          border-radius: 16px;
          line-height: 30px;
        }
      }
    }
  }
</style>
