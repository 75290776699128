<template>
  <div class="banner-content" @click="handleBannerClick" />
</template>

<script>
  import { Vue, Component, Prop } from 'vue-property-decorator';

  @Component
  export default class BannerContent extends Vue {
    @Prop(Object) item;

    get target() {
      return this.$get(this.item, 'target');
    }

    handleBannerClick() {
      const { target_link, target_type } = this.item;
      if (!target_link) {
        return;
      }
      if (target_type === 'external') {
        location.href = target_link;
      } else {
        this.$router.push(target_link);
      }
    }
  }
</script>

<style lang="scss" scoped>
  .banner-content {
    --size-ratio: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: #fff;
    cursor: pointer;

    @include media-sm-and-up {
      --size-ratio: 2;
    }

    @include media-lg-and-up {
      --size-ratio: 2.5;
    }

    @function to-size($n) {
      @return calc(#{$n} * var(--size-ratio));
    }
  }
</style>
