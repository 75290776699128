import { fly } from '@/utils';
import { createConsumer } from '@/utils/actioncable';
import { MessageBox } from 'element-ui';
import { authStore } from '@/store';
import { i18n } from '@/i18n';
import qs from 'qs';

export default class {
  constructor(id) {
    this.id = id;
  }

  channel = {}
  messages = []
  perPage = 15
  canLoadMore = true

  async fetchData() {
    const [ channel, messages ] = await Promise.all([
      fly.get(`message_channels/${this.id}`),
      fly.get(`message_channels/${this.id}/messages`, { current_started_id: (this.messages[0] || {}).id, per_page: this.perPage })
    ]);
    this.channel = channel.data;
    this.messages = messages.data;
    if (this.messages.length < this.perPage) {
      this.canLoadMore = false;
    }
  }

  initSubscription() {
    const params = { Locale: i18n.locale };
    if (authStore.access_token) {
      params.Authorization = authStore.access_token;
    }
    const query = qs.stringify(params);
    const consumer = this.consumer = createConsumer(`/cable?${query}`);
    this.subscription = consumer.subscriptions.create({ channel: 'PlatformMessageChannel', id: this.id }, {
      received: (result) => this.received(result)
    });
  }

  // | Code | Reason        |
  // | ---- |:--------------|
  // | E001 | 内容、图像缺失，必须至少提供一个参数 |
  // | E011 | 用户被禁用 |
  async received(result) {
    const { success, action, data, message, code } = result;
    if (success) {
      switch (action.toLowerCase()) {
        case 'create_message':
          this.messages.push(data);
          this.readMessage(data);
          return;
        case 'update_message_channel':
          this.channel.last_admin_read_message_id = data.last_admin_read_message_id;
          return;
      }
    } else {
      await await MessageBox.alert(message, { showClose: false });
      if (code === 'E011') {
        authStore.logout();
        window.location.reload();
      }
    }
  }

  createMessage({ content, attachment }) {
    this.subscription.perform('create_message', { content, attachment });
    // 结案状态调整
    this.channel.is_closed = false;
  }

  readMessage({ id }) {
    this.subscription.perform('update_last_read', { last_read_message_id: id });
  }

  async getMessages() {
    const { data } = await fly.get(`message_channels/${this.id}/messages`, { current_started_id: (this.messages[0] || {}).id, per_page: this.perPage });
    this.messages = data.concat(this.messages);
    if (data.length < this.perPage) {
      this.canLoadMore = false;
    }
  }

  disconnect() {
    if (this.consumer) {
      this.consumer.disconnect();
    }
  }
}
