import { SimpleStore } from './simple-store';
import { fly } from '@/utils';
import _ from 'lodash';
import { authStore } from './auth-store';

export class ProductIndexStore extends SimpleStore {
  data = [];
  routeParams = {};
  routeQuery = {};

  setRouteParams(params = {}, query = {}) {
    if (JSON.stringify(query) !== JSON.stringify(this.routeQuery)) {
      this.isFulfilled = false;
    }
    this.routeQuery = _.cloneDeep(query);
    this.routeParams = _.cloneDeep(params);
  }

  get currentIndex() {
    const { productType } = this.routeQuery;
    if (productType === 'auctionProduct') {
      return this.data.findIndex(item => item.target_id === Number(this.routeParams.id));
    }
    return this.data.findIndex(item => item.id === Number(this.routeParams.id));
  }

  get preItem() {
    return this.data[this.currentIndex - 1];
  }

  get nextItem() {
    return this.data[this.currentIndex + 1];
  }

  async fetchData() {
    const { productType } = this.routeQuery;
    let params = {};
    let requestPath = '';
    let checkAuth = false;
    switch (productType) {
      // products 从商品列表进来的参数
      case 'product': {
        checkAuth = false;
        requestPath = 'products';
        params = { ...this.routeQuery, only_attrs: ['id'] };
        break;
      }
      // mine/products 我的竞拍商品进行中请求参数
      case 'myProduct': {
        checkAuth = true;
        requestPath = 'mine/products';
        params = { ...this.routeQuery, only_attrs: ['id'] };
        break;
      }
      // action_stores 我的收藏商品请求参数
      case 'auctionProduct': {
        checkAuth = true;
        requestPath = 'action_stores';
        params = { ...this.routeQuery, only_attrs: ['target_id',  'target_type'] };
        break;
      }
    }
    if (!requestPath || (checkAuth && (!authStore.isLogin))) {
      this.isFulfilled = false;
      this.data = [];
      return;
    }
    try {
      const { data } = await this.fetching(
        fly.get(requestPath, params, { checkAuth })
      );
      this.data = data;
    } catch (e) {
      // 可能有token但是失效了
      // 吃掉401
      if (e.status !== 401) {
        throw e;
      }
    }
  }
}

export const productIndexStore = new ProductIndexStore();
