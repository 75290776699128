import { SimpleStore } from './simple-store';
import { fly } from '@/utils';
import { authStore } from './auth-store';

class AuctionNumbersStore extends SimpleStore {
  data = []

  async fetchData() {
    // 如果没登录会报403
    if (!authStore.isLogin) {
      return;
    }
    const { data } = await this.fetching(fly.get('auction_numbers', { page: 0 }));
    this.data = data;
  }
}

export const auctionNumbersStore = new AuctionNumbersStore();
