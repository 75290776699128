import { render, staticRenderFns } from "./collapsible-rich-text.vue?vue&type=template&id=aca022d8&scoped=true&"
import script from "./collapsible-rich-text.vue?vue&type=script&lang=js&"
export * from "./collapsible-rich-text.vue?vue&type=script&lang=js&"
import style0 from "./collapsible-rich-text.vue?vue&type=style&index=0&id=aca022d8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aca022d8",
  null
  
)

export default component.exports