<template>
  <div class="intl-tel-input allow-dropdown">
    <!-- 点击组件外部隐藏下拉单 -->
    <div class="mask" @click="hideSubMenu = true" v-show="!hideSubMenu"></div>
    <div class="flag-container">
      <div class="selected-flag" :title="currentData.name + ': +' + currentData.dialCode" @click="hideSubMenu = !hideSubMenu">
        <div :class="'iti-flag ' + currentData.code"></div>
        <img src="@/assets/icon-arrow-down-black.png" class="arrow-icon" :class="hideSubMenu ? '' : 'rotate-arrow' " />
      </div>
      <ul class="country-list" v-show="!hideSubMenu">
        <li v-for="item in frontCountriesArray" :key="item.id" :class="'country ' + (item.code == currentCode ? 'highlight' : '')" @click="handleSelect(item)">
          <div class="flag-box">
            <div :class="'iti-flag ' + item.code"></div>
          </div>
          <span class="country-name">{{ item.name }}</span>
          <span class="dial-code">+{{ item.dialCode }}</span>
        </li>
        <li class="divider"></li>
        <li v-for="item in countriesArray" :key="item.id" :class="'country ' + (item.code == currentCode ? 'highlight' : '')" @click="handleSelect(item)">
          <div class="flag-box">
            <div :class="'iti-flag ' + item.code"></div>
          </div>
          <span class="country-name">{{ item.name }}</span>
          <span class="dial-code">+{{ item.dialCode }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
  import _ from 'lodash';
  import countries from './countries';
  import './country.css';

  @Component
  export default class CountrySelector extends Vue {
    @Prop({ type: Array, default: () => [] }) toFront;
    @Prop({ type: String, default: 'ja',
      validator(code) {
        let clearCode = String(code).toLowerCase();
        return !!countries[clearCode];
      }
    }) countryCode;

    currentCode = ''
    hideSubMenu = true
    toFrontArray = []

    get currentData() {
      return countries[this.currentCode] || {};
    }

    get frontCountriesArray() {
      return _.pick(countries, this.toFrontArray);
    }

    get countriesArray() {
      return _.omit(countries, this.toFrontArray);
    }

    mounted() {
      this.currentCode = this.countryCode;
      this.toFrontArray = this.toFront;
      this.$emit('excountry', countries[this.countryCode]);
    }

    @Watch('countryCode', { immediate: 'true' })
    handler(newCode) {
      this.setCountry(countries[newCode]);
    }

    setCountry(item) {
      this.currentCode = item.code;
      this.toFrontArray.push(String(item.code));
      this.$emit('excountry', item);
    }

    handleSelect(item) {
      this.currentCode = item.code;
      this.setCountry(item);
      this.hideSubMenu = true;
    }
  }
</script>

<style lang="scss" scoped>
  .intl-tel-input {
    width: 50px;
    font-size: 14px;
    color: #666;

    .mask {
      position: fixed;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .country-list {
      width: 335px;
      height: 400px;
      margin-top: 2px;
    }

    .arrow-icon {
      width: 12px;
    }

    .rotate-arrow {
      transform: rotate(180deg);
    }
  }
</style>
