<i18n src="./locales.json"></i18n>

<template>
  <div class="login container">
    <div class="login-wrapper">
      <v-title :value="typeContent.headTitle"/>
      <div class="head-title">{{ typeContent.headTitle }}</div>
      <div class="sub-title" v-if="showLogin">
        <span>{{ $t('useYour') }}{{ typeContent.currentLoginText }}</span>
        <span>{{ $t('toLogin') }}, </span>
        <span>{{ $t('or') }}</span>
        <router-link class="link current" :to="{ query: { ...$route.query, type: changeType } }">{{ typeContent.changeLoginText }} </router-link>
      </div>
      <!-- 手机验证码登录 -->
      <div v-if="type === 'phone'">
        <div class="input-group-wrapper">
          <div class="title">{{$t('phone')}}</div>
          <div class="select-wrap flex">
            <country-selector @excountry="handleChangeCountry" :toFront="toFrontArray"></country-selector>
            <div class="input-wrapper flex item-center flex-1" :class="{ focus: isFocus }">
              <span>{{ currentCountry.dialCode }}-</span>
              <input
                v-model.trim="phone"
                class="input flex-1"
                type="number"
                :placeholder="$t('phoneHolder')"
                @input="handleErrorEmpty()"
                @focus="isFocus = true"
                @blur="isFocus = false"
              />
            </div>
          </div>
          <div class="error" v-show="phoneError">* {{ phoneError }}</div>
        </div>
        <div class="input-group-wrapper">
          <div class="title">{{$t('validateCode')}}</div>
          <div class="validate-content">
            <input
              class="input-wrapper"
              type="number"
              :placeholder="$t('validateHolder')"
              maxlength="6"
              v-model.trim="validateCode"
              @input="handleErrorEmpty()"
            />
            <no-captcha
              :before-open="() => handleValidate()"
              :disabled="disabledCodeBtn"
              :country-code="currentCountry.dialCode"
              :phone="phone"
              event="login"
              @success="sendCodeSuccess"
            >
              <template #default="{ disabled }">
                <div
                  class="primary-btn-o"
                  :class="{'disabled': disabled}"
                >
                  <span v-if="disabled">
                    <app-countdown
                      @completed="disabledCodeBtn = false"
                      :end-time="countdownTime"
                    >
                      <template v-slot="{ second }">
                        {{ $tc('countTime', second, { second }) }}
                      </template>
                    </app-countdown>
                  </span>
                  <span v-else>{{ $t('getValidateCode') }}</span>
                </div>
              </template>
            </no-captcha>
          </div>
          <div class="error" v-show="validateCodeError">* {{ validateCodeError }}</div>
        </div>
      </div>
      <!-- 账号密码登录 -->
      <div v-if="type === 'password'">
        <div class="input-group-wrapper">
          <div class="title">{{$t('phone')}}</div>
          <div class="select-wrap flex">
            <country-selector @excountry="handleChangeCountry" :toFront="toFrontArray"></country-selector>
            <div class="input-wrapper flex item-center flex-1" :class="{ focus: isFocus }">
              <span>{{ currentCountry.dialCode }}-</span>
              <input
                v-model.trim="phone"
                class="input flex-1"
                type="number"
                :placeholder="$t('phoneHolder')"
                @input="phoneError = ''"
                @focus="isFocus = true"
                @blur="isFocus = false"
              />
            </div>
          </div>
          <div class="error" v-show="phoneError">* {{ phoneError }}</div>
        </div>
        <div class="input-group-wrapper">
          <div class="title">{{ $t('logPassword') }}</div>
          <el-input
            type="password"
            :placeholder="$t('logPasswordPlaceholder')"
            v-model="password"
            show-password
            @input="passwordError = ''"
          />
          <div class="error" v-show="passwordError">* {{ passwordError }}</div>
          <router-link class="link forget" v-if="type==='password'" :to="{ query: { ...$route.query, type: 'forgot' } }">{{ $t('forgot') }}</router-link>
        </div>
      </div>
      <!-- 忘记密码 -->
      <div v-if="type === 'forgot'">
        <div class="input-group-wrapper">
          <div class="title">{{$t('phone')}}</div>
          <div class="select-wrap flex">
            <country-selector @excountry="handleChangeCountry" :toFront="toFrontArray"></country-selector>
            <div class="input-wrapper flex item-center flex-1" :class="{ focus: isFocus }">
              <span>{{ currentCountry.dialCode }}-</span>
              <input
                v-model.trim="phone"
                class="input flex-1"
                type="number"
                :placeholder="$t('phoneHolder')"
                @input="phoneError = ''"
                @focus="isFocus = true"
                @blur="isFocus = false"
              />
            </div>
          </div>
          <div class="error" v-show="phoneError">* {{ phoneError }}</div>
        </div>
        <div class="input-group-wrapper">
          <div class="title">{{$t('validateCode')}}</div>
          <div class="validate-content">
            <input
              class="input-wrapper"
              type="number"
              :placeholder="$t('validateHolder')"
              maxlength="6"
              v-model.trim="validateCode"
              @input="validateCodeError = ''"
            />
            <no-captcha
              :before-open="() => handleValidate()"
              :disabled="disabledCodeBtn"
              :country-code="currentCountry.dialCode"
              :phone="phone"
              event="reset_password"
              @success="sendCodeSuccess"
            >
              <template #default="{ disabled }">
                <div
                  class="primary-btn-o"
                  :class="{'disabled': disabled}"
                >
                  <span v-if="disabled">
                    <app-countdown
                      @completed="disabledCodeBtn = false"
                      :end-time="countdownTime"
                    >
                      <template v-slot="{ second }">
                        {{ $tc('countTime',second, { second }) }}
                      </template>
                    </app-countdown>
                  </span>
                  <span v-else>{{ $t('getValidateCode') }}</span>
                </div>
              </template>
            </no-captcha>
          </div>
          <div class="error" v-show="validateCodeError">* {{ validateCodeError }}</div>
        </div>
        <div class="input-group-wrapper">
          <div class="title new-password">{{ $t('newPassword') }}</div>
          <el-input
            type="password"
            :placeholder="$t('password')"
            v-model="newPassword"
            show-password
            @input="passwordError = ''"
          />
          <div class="error" v-show="passwordError">* {{ passwordError }}</div>
        </div>
        <div class="input-group-wrapper">
          <div class="title">{{ $t('confirmPassword') }}</div>
          <el-input
            type="password"
            :placeholder="$t('password')"
            v-model="confirmPassword"
            show-password
            @input="passwordError = ''"
          />
          <div class="error tips">* {{ $t('passwordError') }}</div>
        </div>
      </div>
      <div class="login-tip" v-if="type === 'password'">
        <span>{{ $t('noPassword') }}</span>
        <router-link class="link current" :to="{ query: { ...$route.query, type: changeType } }">{{ typeContent.changeLoginText }}</router-link>
        <span>{{ $t('toSetting') }}</span>
      </div>
      <div class="primary-btn submit-btn" @click="handleClick">{{ typeContent.btnText }}</div>
      <router-link v-if="!showLogin" class="link to-login" :to="{ query: { ...$route.query, type: 'password' } }">{{ $t('goTologin') }}</router-link>
      <div v-if="showLogin" class="register">
        <div class="text">{{ $t('registerText') }}</div>
        <router-link class="primary-btn-o register-btn" :to="{ name: 'signup' }">{{ $t('registerBtn') }}</router-link>
      </div>
    </div>
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';
  import countrySelector from '@/components/country-selector';
  import { VALIDATE_PASSWORD } from '@/constants';
  import dayjs from 'dayjs';
  import { notifyCustomer } from '@/utils';

  @Component({
    components: {
      countrySelector
    }
  })
  export default class Login extends Vue {
    toFrontArray = ['ja', 'cn', 'hk', 'tw'];
    currentCountry = {};
    phone = '';
    password = '';
    newPassword = '';
    confirmPassword = '';
    passwordError = '';
    validateCode = '';
    phoneError = '';
    validateCodeError = '';
    isFocus = false;
    disabledCodeBtn = false;
    countdownTime = dayjs().toString();

    get type() {
      return this.$route.query.type || 'password';
    }

    get changeType() {
      return this.type === 'phone' ? 'password' : 'phone';
    }

    get showLogin() {
      return this.type === 'phone' || this.type === 'password';
    }

    get typeContent() {
      return {
        phone: {
          headTitle: this.$t('loginHeader'),
          btnText: this.$t('login'),
          currentLoginText: this.$t('phoneLogin'),
          changeLoginText: this.$t('passwordLogin')
        },
        password: {
          headTitle: this.$t('loginHeader'),
          btnText: this.$t('login'),
          currentLoginText: this.$t('passwordLogin'),
          changeLoginText: this.$t('phoneLogin')
        },
        forgot: {
          headTitle: this.$t('findPassword'),
          btnText: this.$t('resetPassword')
        }
      }[this.type];
    }

    handleClick() {
      switch (this.type) {
        case 'phone': case 'password':
          return this.handleLogin();
        case 'forgot':
          return this.handleReset();
      }
    }

    handleErrorEmpty() {
      this.phoneError = '';
      this.validateCodeError = '';
    }

    handleChangeCountry(item) {
      this.currentCountry = item;
    }

    mounted() {
      if (this.$authStore.access_token) {
        this.$router.replace({ name: 'mine' });
        return;
      }
    }

    handleValidate() {
      if (!this.phone) {
        const msg = this.$t('phoneError');
        this.phoneError = msg;
        throw new Error(msg);
      }
    }

    async sendCodeSuccess() {
      this.countdownTime = dayjs().add(60, 'second').toString();
      this.disabledCodeBtn = true;
    }

    async handleLogin() {
      if (this.phone.length === 0) {
        this.phoneError = this.$t('phoneError');
      }
      if (this.type === 'phone') {
        if (this.validateCode.length !== 6) {
          this.validateCodeError = this.$t('validateCodeError');
          return;
        }
      } else {
        if (!this.password) {
          this.passwordError = this.$t('passwordEmpty');
          return;
        }
        if (!VALIDATE_PASSWORD.test(this.password)) {
          this.passwordError = this.$t('passwordError');
          return;
        }
      }
      const phoneParams = {
        country_code: this.currentCountry.dialCode,
        phone: this.phone,
        code: this.validateCode || null,
        password: this.password
      };

      await this.$autoLoading(this.$authStore.login(phoneParams));

      const { redirect = '' } = this.$route.query;
      if (redirect.includes('signup')) {
        this.$router.replace({ name: 'home' });
      } else {
        this.$router.replace(decodeURIComponent(redirect) || '/');
      }
      await notifyCustomer(async () => {
        await this.$confirm(this.$get(this.$authStore, 'user.user_extended_info.credential_will_expire_notify_message', ''), {
          confirmButtonText: this.$t('goToUpdate')
        });
        this.$router.push({ name: 'myAddress' });
      });
    }

    // 重置密码
    async handleReset() {
      if (this.phone.length === 0) {
        this.phoneError = this.$t('phoneError');
        return;
      }
      if (this.validateCode.length !== 6) {
        this.validateCodeError = this.$t('validateCodeError');
        return;
      }
      if (!this.newPassword) {
        this.passwordError = this.$t('passwordEmpty');
        return;
      }
      if (!VALIDATE_PASSWORD.test(this.newPassword)) {
        this.passwordError = this.$t('passwordError');
        return;
      }
      if (this.newPassword !== this.confirmPassword) {
        this.passwordError = this.$t('notSame');
        return;
      }

      const passwordParams = {
        country_code: this.currentCountry.dialCode,
        phone: this.phone,
        code: this.validateCode,
        password: this.newPassword,
        password_confirmation: this.confirmPassword
      };
      await this.$autoLoading(this.$authStore.resetPassword(passwordParams));
      this.$message({ type: 'success', message: this.$t('resetSuccess') });
      this.$router.push({ name: 'login' });
    }
  }
</script>

<style lang="scss" scoped>
  .login {
    max-width: 500px;
    padding: 30px 20px;
    text-align: center;

    @include media-sm-and-up {
      min-height: 100%;
      padding: 44px 20px;
      margin: 0 auto;
    }

    .login-wrapper {
      flex: 1;
    }

    .head-title {
      padding-bottom: 30px;
      border-bottom: 1px solid #ddd;
      font-weight: 500;
      font-size: 18px;

      @include media-xs-only {
        padding-bottom: 20px;
      }
    }

    .sub-title {
      display: flex;
      padding-top: 20px;
      font-size: 12px;
      white-space: pre;
    }

    .input-group-wrapper {
      .title {
        display: block;
        margin-top: 30px;
        margin-bottom: 10px;
        font-weight: 500;
        text-align: start;

        &.new-password {
          @include media-xs-only {
            margin-top: 10px;
          }
        }
      }

      .input-wrapper {
        width: 100%;
        height: 40px;
        padding: 0 17px;
        border: 1px solid #ddd;
        outline: none;

        &:focus,
        &.focus {
          border: 1px solid #000 !important;
        }

        .input {
          align-self: stretch;
          border: none;
          outline: none;
        }
      }
    }

    /deep/ .el-input {
      &.el-input--suffix {
        .el-input__inner {
          border-radius: 0;

          &:focus {
            border: 1px solid #000;
          }
        }
      }
    }

    .validate-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .primary-btn-o {
        height: 30px;
        margin: 20px 0;
        font-size: 13px;
      }

      @include media-sm-and-up {
        flex-direction: row;

        .no-captcha {
          align-self: center;
        }

        .primary-btn-o {
          margin: 0 0 0 40px;
        }
      }
    }

    .error {
      margin-top: 15px;
      font-size: 11px;
      line-height: 15px;
      text-align: start;
      color: $primary;

      &.tips {
        color: $gray;
      }

      &.code-error {
        @include media-xs-only {
          margin-top: 0;
        }
      }
    }

    .submit-btn {
      margin: 35px auto;
      font-size: 18px;

      @include media-sm-and-up {
        max-width: none;
        margin: 66px 80px 35px;
      }
    }

    .link {
      text-decoration: underline;
      color: #000;
      cursor: pointer;

      &.current {
        color: $primary;
      }

      &.forget {
        display: block;
        width: fit-content;
        margin: 10px 0 -25px;
        text-align: start;

        @include media-xs-only {
          margin: 10px 0 30px;
        }
      }

      &.to-login {
        display: block;
        margin-top: -15px;
      }
    }

    .register {
      border-top: 1px solid #ddd;

      .text {
        margin: 22px 0;
        font-weight: 500;
        color: $gray;
      }

      .register-btn {
        font-weight: 500;
        font-size: 18px;

        @include media-sm-and-up {
          max-width: none;
          margin: 0 80px;
        }
      }
    }

    .login-tip {
      padding-top: 50px;
      font-size: 12px;
      text-align: left;

      @include media-xs-only {
        padding-top: 0px;
      }
    }
  }
</style>
