<i18n>
{
  "zh_cn": {
    "publish": "发布"
  },
  "zh_hk": {
    "publish": "發布"
  },
  "en": {
    "publish": "publish"
  },
  "ja": {
    "publish": "リリース"
  }
}
</i18n>

<template>
  <router-link class="guide-card" :to="routeTo">
    <div class="cover-wrapper">
      <auto-height
        :height="autoHeight"
        :cover="$get(guide, 'image.url')"
        :oss-img="{ resize: { w: 1000 } }"
      />
      <div class="mask"></div>
      <div v-if="type === 'listPage'" class="video-icon">
        <img src="@/assets/icon-guide-video.png" alt="">
      </div>
      <div v-else class="title-wrap">{{ guide.title }}</div>
    </div>

    <div v-if="type === 'listPage'" class="info-wrap">
      <div class="title text-overflow">{{ guide.title }}</div>
      <div class="create-time">{{ guide.created_at | timeFormat }} {{ $t('publish') }}</div>
    </div>
  </router-link>
</template>

<script>
  import { Vue, Component, Prop } from 'vue-property-decorator';

  @Component
  export default class GuideCard extends Vue {
    @Prop({ type: Object, default: () => ({}) }) guide
    @Prop({ type: String, default: '50%' }) autoHeight;
    @Prop({ type: Object, default: () => ({ name: '' }) }) routeTo;
    @Prop({ type: String, default: 'listPage' }) type // 区分子域名首页和导览列表的样式
  }
</script>

<style lang="scss" scoped>
  .cover-wrapper {
    position: relative;
    border-radius: 4px;
    overflow: hidden;

    .mask {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      border-radius: 4px;
      background: rgba(0, 0, 0, 0.2);
    }

    .video-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      width: 40px;
      height: 40px;
      padding: 10px 6px 10px 10px;
      border-radius: 50%;
      background: rgba(#000000, 0.6);
      transform: translate(-50%, -50%);

      img {
        width: 20px;
        height: 20px;
      }
    }

    .title-wrap {
      position: absolute;
      bottom: 10px;
      left: 10px;
      max-width: 220px;
      font-size: 14px;
      color: #fff;
    }
  }

  .info-wrap {
    padding: 10px 0;

    .title {
      font-weight: bold;
      color: #000000;
    }

    .create-time {
      margin-top: 5px;
      font-size: 12px;
      color: #827f7f;
    }
  }
</style>
