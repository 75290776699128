<i18n src="./locale.json"></i18n>

<template>
  <div>
    <div
      class="primary-btn btn"
      v-if="!number"
      @click="handleParticipate">
        {{ $t('participateAuction') }}
    </div>

    <!-- 选择非在线支付弹窗 -->
    <el-dialog
      class="dialog-page-order-detail"
      :visible.sync="dialogVisible"
      width="24.16667rem"
      center
    >
      <div v-if="!auction.on_site">
        {{ $t('noOnSite') }}
        <a class="link" :href="`mailto:${auctionHouseEmail}`">{{ auctionHouseEmail }}</a>
      </div>
      <div v-if="auction.on_site && !$auctionHouseStore.domainHouse">
        <div class="contact-info" v-html="settingsStore.settings.on_site_auction_hint"></div>
      </div>
      <div v-if="auction.on_site && $auctionHouseStore.domainHouse">
        <div class="contact-info" v-html="$auctionHouseStore.domainHouseDetail.on_site_auction_hint"></div>
      </div>
      <div slot="footer">
        <el-button v-if="!auction.on_site" class="primary-btn" style="font-size: 1.5rem" @click="handleContact">{{ $t('contactAuctionHouse') }}</el-button>
        <div v-else class="flex">
          <el-button class="primary-btn cancel" style="font-size: 1.5rem" @click="dialogVisible = false">{{ $t('cancel') }}</el-button>
          <el-button class="primary-btn" style="font-size: 1.5rem" @click="handleJoinAuction">{{ $t('withdrawConfirm') }}</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
  import { settingsStore } from '@/store';
  import _ from 'lodash';

  @Component
  export default class ParticipateButton extends Vue {
    @Prop({ type: Object, default: () => ({}) }) auction
    @Prop({ type: Array, default: () => [] }) quotas
    @Prop([String, Number]) number
    @Prop({ type: String, default: 'auction' }) pageType // 根据不同页面获取quota_currency或quota_currencies

    dialogVisible = false

    settingsStore = settingsStore

    // 主域名和独立域名的邮箱区分开来
    get auctionHouseEmail() {
      return this.$auctionHouseStore.domainHouse ? this.$get(this.$auctionHouseStore, 'domainHouseDetail.customer_service_email') : this.$get(this.settingsStore, 'settings.customer_service_email');
    }

    @Watch('dialogVisible', { immediate: true })
    valueChange(val) {
      document.body.style.overflow = val ? 'hidden' : 'initial';
      document.body.style.height = val ? '100%' : 'initial';
    }

    async handleParticipate() {
      // 如果没有上传任何证件
      if (!this.$authStore.isLoadCredential) {
        await this.$confirm(this.$t('uploadTip'));
        return this.$router.push({ name: 'myAddress' });
      }
      // 没有上传证件有效期
      if (this.$authStore.isLoadEffectiveDate) {
        await this.$confirm(this.$t('uploadValidityPeriod'));
        return this.$router.push({ name: 'myAddress' });
      }
      // 是现场拍卖
      if (this.auction.on_site) {
        let quotaCurrencies = [];

        switch (this.pageType) {
          case 'auction': {
            const { quota_currencies } = this.auction;
            quotaCurrencies = quota_currencies;
            break;
          }
          case 'auctionCategories':
          case 'auctionItem': {
            const { quota_currency } = this.auction;
            quotaCurrencies = [quota_currency];
            break;
          }
        }
        // 根据拍卖会的货币判断额度是否为0
        const noQuota = _.every(
          this.quotas.filter(quota => quotaCurrencies.length ? quotaCurrencies.includes(quota.currency) : true),
          (item) => !this.$get(item, `available_bidding_amount.${this.auction.margin_ratio}`)
        );
        if (this.$authStore.isLogin && noQuota) {
          await this.$alert(this.$t('participateError'));
          this.$router.push({ name: 'charge', query: { auctionId: this.auction.id } });
        }
      }

      this.dialogVisible = true;
    }

    // 联系客服
    async handleContact() {
      const { data } = await this.$autoLoading(this.$fly.post('message_channels/contact_service', {
        target_type: 'Auction',
        target_id: this.$get(this.auction, 'id'),
        communicator_type: 'AuctionHouse',
        communicator_id: this.$get(this.auction, 'auction_house_id')
      }));
      this.$router.push({ name: 'messageChannelDetail', params: { id: data.id } });
    }

    // 确定参与拍卖发放牌号
    async handleJoinAuction() {
      const { data: { number } } = await this.$autoLoading(this.$fly.post('mine/auction_number', { auction_id: this.auction.id }));
      this.dialogVisible = false;
      this.$emit('update-number', number);
      this.$authStore.fetchData();
      this.$message({ type: 'success', message: this.$t('participateSuccess') });
    }
  }
</script>

<style lang="scss" scoped>
  /deep/ .dialog-page-order-detail {
    display: flex;
    justify-content: center;
    align-items: center;

    .el-dialog {
      border-radius: 19px;

      @include media-sm-and-up {
        width: 50% !important;
      }

      .el-dialog__header {
        padding: 20px;
      }

      .el-dialog__body {
        max-height: 380px;
        padding: 0 36px;
        overflow-y: auto;

        @include media-sm-and-up {
          max-height: 360px;
        }
      }

      .el-dialog__footer {
        padding: 20px 0;

        .primary-btn {
          height: 38px;

          @include media-sm-and-up {
            height: 40px;
            padding: 0 30px;
            font-size: 18px !important;
          }

          &.cancel {
            margin-right: 10px;
            border: 1px solid #000;
            color: #000;
            background: #fff;

            @include media-sm-and-up {
              margin-right: 20px;
            }
          }
        }
      }
    }
  }
</style>
