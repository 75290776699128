<i18n src="./locale.json"></i18n>

<template>
  <div class="step3-box">
    <!-- 头部 -->
    <div class="header">
      <div class="top-box">
        <span class="title">
          {{ $t('previewPrint') }}
        </span>
        <div class="selected">
          {{ $tc('selected', selectedItems.length) }}
        </div>
      </div>
      <div class="primary-btn" @click="handleExportPdf">
        {{ $t('goToPrint') }}
      </div>
    </div>

    <!-- 预览打印拍品列表 -->
    <div ref="auctionItems" class="list-wrapper">
      <div v-for="item in selectedItems" :key="item.id" >
        <PrintPdfItem
          :item="item"
          :auctionName="auctionName"
          :pdfFooterLogo="auctionHouseDetail.pdfFooterLogo"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { Vue, Component, Prop } from 'vue-property-decorator';
  import PrintPdfItem from './print-pdf-item.vue';
  import { getPdf } from '@/utils/get-pdf.js';

  @Component({
    components: {
      PrintPdfItem,
    },
  })
  export default class PrintItemsStep3 extends Vue {
    @Prop({ type: Array, default: () => [] }) selectedItems;
    @Prop({ type: String, default: '' }) auctionName;
    @Prop({ type: Object, default: () => ({ pdfFooterLogo: '', pdfEmailDomain: [] }) }) auctionHouseDetail;

    @Vue.autoLoading
    async handleExportPdf() {
      await getPdf(
        this.$refs.auctionItems.querySelectorAll('.paper .paper-container'),
        `${this.auctionName} - ${this.$t('auctionItemsImages')}`
      );
    }
  }
</script>

<style lang="scss" scoped>
.step3-box {
  .header {
    display: flex;
    justify-content: space-between;

    .top-box {
      display: flex;
      flex-direction: column;
      gap: 7px;

      .title {
        margin-right: 16px;
        font-weight: 600;
        font-size: 20px;
        color: #333;
      }

      .tips {
        padding: 2px 7px;
        margin-top: 0;
        border: 1px solid #e8e8e8;
        border-radius: 10px;
        font-size: 12px;
        color: #6b6b6b;
      }
    }

    .primary-btn {
      margin: initial;
    }
  }

  .list-wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-height: 60vh;
    margin-top: 15px;
    overflow: auto;
  }
}
</style>
