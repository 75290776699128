<i18n src="../locales.json"></i18n>

<template>
  <div v-if="loading">
    <el-progress :percentage="percentage" :show-text="false"/>
    <div class="container flex">
      <div class="cover-wrapper prod-skeleton-animate">
        <div class="prod-skeleton-block" style="width: 100%; padding-bottom: 56%;"/>
      </div>
      <div class="box-info prod-skeleton-animate">
        <div class="auction-title prod-skeleton-block" style="width: 60%;">1</div>
        <div class="tab-block prod-skeleton-block">1</div>
      </div>
    </div>
  </div>
</template>

<script>
  import { Component, Vue } from 'vue-property-decorator';
  import Skeleton from '@/components/skeleton/mixins';

  @Component({ mixins: [Skeleton] })
  export default class IndexSkeleton extends Vue {
    speed = 5
  }
</script>

<style lang="scss" scoped>
  .container {
    @include media-sm-and-up {
      padding-top: 50px;
    }

    @include media-xs-only {
      flex-direction: column;
    }
  }

  .prod-skeleton-block {
    width: 30%;
  }

  .tab-block {
    width: 100%;
    height: 40px;
    margin-top: 20px;
  }

  .cover-wrapper {
    margin-bottom: 20px;

    @include media-sm-and-up {
      width: 52%;
    }

    @include media-xs-only {
      margin: 0 -20px 20px;
    }
  }

  .box-info {
    @include media-sm-and-up {
      flex: 1;
      padding-left: 30px;
    }
  }
</style>
