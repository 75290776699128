<i18n>
  {
    "zh_cn": {
      "securityVerificationTitle": "请完成安全验证",
      "captchaPlaceHolder": "请输入图片验证码",
      "submit": "提交",
      "rucaptchaTips": "请输入 4 位验证码, 不区分大小写, 点击图片可刷新验证码"
    },
    "zh_hk": {
      "securityVerificationTitle": "請完成安全驗證",
      "captchaPlaceHolder": "請輸入圖片驗證碼",
      "submit": "提交",
      "rucaptchaTips": "請輸入 4 位數的驗證碼，不區分大小寫，點擊圖片可刷新驗證碼"
    },
    "en": {
      "securityVerificationTitle": "Please complete the security verification",
      "captchaPlaceHolder": "Please enter the image verification code",
      "submit": "Submit",
      "rucaptchaTips": "Please enter the 4-digit verification code. It is case-insensitive. Click on the image to refresh the verification code"
    },
    "ja": {
      "securityVerificationTitle": "セキュリティ検証を完了してください",
      "captchaPlaceHolder": "画像認証コードを入力してください",
      "submit": "提出する",
      "rucaptchaTips": "4桁の認証コードを入力してください。大文字と小文字は区別されません。画像をクリックすると認証コードが更新されます"
    }
  }
  </i18n>

<template>
  <div @click="handleClick" class="no-captcha">
    <el-dialog
      :visible.sync="dialogVisible"
      append-to-body
      modal-append-to-body
      width="90%"
      custom-class="dialog-awsc"
      lock-scroll
      :close-on-click-modal="false"
      center
      :title="$t('securityVerificationTitle')"
    >
      <img :src="rucaptchaUrl" class="image" @click="resetRucaptchaUrl" />
      <p class="rucaptcha-tips">{{ $t('rucaptchaTips') }}</p>
      <el-input
        :placeholder="$t('captchaPlaceHolder')"
        v-model.trim="rucaptcha"
        @input="errorMsg = ''"
        @keyup.enter.native="sendCode"
      ></el-input>
      <div class="error" v-show="errorMsg">* {{ errorMsg }}</div>
      <button type="submit" class="submit-btn primary-btn" @click="sendCode" :disabled="!rucaptcha" :class="{ disabled: !rucaptcha }">{{ $t('submit') }}</button>
    </el-dialog>
    <slot :disabled="disabled" />
  </div>
</template>

<script>
  // https://help.aliyun.com/document_detail/193141.html?spm=a2c4g.190301.0.0.f88658ddkTYPYS
  import { Vue, Component, Prop } from 'vue-property-decorator';
  import _ from 'lodash';
  import { CAPTCHA_RCODE_SESSION_KEY } from '@/constants';

  @Component
  export default class Rucaptcha extends Vue {
    @Prop(Boolean) disabled;
    @Prop({ type: Function, default: _.noop }) beforeOpen;
    @Prop({ type: [String, Number] }) countryCode;
    @Prop({ type: String }) phone;
    @Prop({ type: String }) event;

    dialogVisible = false;
    rucaptcha = '';
    rucaptchaUrl = '';
    errorMsg = '';

    getRandom() {
      return Date.now().toString(36).slice(4) + Math.random().toString(36).replace('0.', '');
    }

    resetRucaptchaUrl() {
      const random = this.getRandom();
      this.rucaptchaUrl = `${process.env.VUE_APP_WEB_API}/app_api/v1/captcha?rcode=${random}`;
      window[CAPTCHA_RCODE_SESSION_KEY] = random;
      this.rucaptcha = '';
    }

    async handleClick() {
      if (!this.disabled) {
        await this.beforeOpen();
        this.errorMsg = '';
        this.dialogVisible = true;
        this.resetRucaptchaUrl();
      }
    }

    @Vue.autoLoading
    async sendCode() {
      if (!this.rucaptcha) {
        return;
      }

      try {
        const { countryCode, phone, event } = this;
        await this.$fly.post('verification_codes', {
          country_code: countryCode,
          phone,
          event,
          rucaptcha: this.rucaptcha
        });
        this.$emit('success');
        this.$showToast(this.$t('validateCodeSent'));
        this.rucaptcha = '';
        this.dialogVisible = false;
      } catch (e) {
        this.resetRucaptchaUrl();
        this.errorMsg = e.message;
      }
    }
  }
</script>

<style lang="scss">
.dialog-awsc {
  max-width: 400px;

  .el-dialog__header {
    .el-dialog__title {
      font-size: 14px;
    }
  }

  .el-dialog__body {
    .image {
      width: 100%;
      height: auto;
      margin-bottom: 25px;
      cursor: pointer;
    }

    .rucaptcha-tips {
      margin-top: 0;
      font-size: 12px;
      color: $gray;
    }

    .el-input {
      .el-input__inner {
        border-radius: 0;
      }
    }

    .submit-btn {
      --color-btn-font: #fff;
      --color-btn-bg: #000;
      width: 100%;
      margin-top: 25px;
    }

    .error {
      margin-top: 15px;
      font-size: 11px;
      line-height: 15px;
      text-align: start;
      color: $primary;
    }
  }
}
</style>
