<template>
  <div class="component-auction-item" @click="handleNavDetail">
    <auction-card auto-height="50%" :auction="auction"/>
  </div>
</template>

<script>
  import { Vue, Component, Prop } from 'vue-property-decorator';

  @Component
  export default class AuctionItem extends Vue {
    @Prop({ type: Object, default: () => ({}) }) auction;

    handleNavDetail() {
      if (this.$get(this.auction, 'type') === 'StoreCategory') {
        this.$router.push({ name: 'products', query: {
          type: 'store_category',
          id: this.auction.id
        } });
        return;
      }
      this.$router.push({ name: 'auctionDetail', params: { id: this.auction.id } });
    }
  }
</script>

<style lang="scss" scoped>
  .component-auction-item {
    cursor: pointer;

    /deep/ .auction-card-wrapper {
      width: 100%;

      .auction-wrapper {
        font-size: 14px;

        @include media-xs-only {
          flex: 1;
        }

        .auction-title {
          font-size: 20px;
          line-height: 30px;
        }
      }

      @include media-sm-and-up {
        display: flex;

        .auction-wrapper {
          width: 50%;
        }

        .cover-wrapper {
          display: flex;
          flex-shrink: 0;
          align-items: center;
          width: 50%;
        }

        .auction-title,
        .auction-info {
          line-height: 1.4;
        }
      }
    }
  }
</style>
