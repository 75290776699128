import { SimpleStore } from './simple-store';
import { authStore, followProductIdsStore } from '@/store';
import { fly } from '@/utils';
import { i18n } from '@/i18n';
import { createConsumer } from '@/utils/actioncable';
import { MessageBox } from 'element-ui';
import Vue from 'vue';
import _ from 'lodash';
import dayjs from 'dayjs';
import qs from 'qs';

export class ProductDetailStore extends SimpleStore {
  productId = null
  data = {}
  biddingPrices = Vue.observable([])

  constructor(productId, isPreview, vm) {
    super();
    this.productId = productId;
    this.isPreview = isPreview;
    this.vm = vm;
  }

  get raiseRules() {
    return _.get(this.data, 'product_raise_rule_set.product_raise_rules', []);
  }

  // 首次出价没有当前价格，使用起始价
  get currentPrice() {
    return _.get(this.data, 'current_price') || _.get(this.data, 'started_price');
  }

  get currentAmountPrice() {
    return this.addPrice(_.get(this.data, 'current_price')) || _.get(this.data, 'started_price');
  }

  get nextLowestPrice() {
    return this.addPrice(this.currentPrice);
  }

  // 一口价商品价格
  get isBuyNowProductPrice() {
    return _.get(this.data, 'confirmed_price');
  }

  // 自动出价价格
  get autoBiddingPrice() {
    return _.get(this.data, 'auto_bidding_price');
  }

  get isFinished() {
    return _.get(this.data, 'deal_price') || dayjs().diff(dayjs(_.get(this.data, 'ended_bidding_time'))) > 0;
  }

  get isNotStart() {
    return dayjs().diff(dayjs(_.get(this.data, 'started_bidding_time'))) < 0;
  }

  // 包含一口价商品
  get isBuyNowProduct() {
    return !!_.get(this.data, 'confirmed_price');
  }

  // 竞价商品
  get auctionProduct() {
    return !!_.get(this.data, 'started_price');
  }

  // 一口价和竞价同时的商品
  get auctionAndBuyNowProduct() {
    return this.auctionProduct && this.isBuyNowProduct;
  }

  // 仅竞价的商品
  get onlyAuctionProduct() {
    return this.auctionProduct && !this.isBuyNowProduct;
  }

  // 仅一口价的商品
  get onlyBuyNowProduct() {
    return !this.auctionProduct && this.isBuyNowProduct;
  }

  // 商品仅在日本国内发货，需提示
  get showTransportAreaTips() {
    return _.get(this.data, 'transport_area') === 'japan';
  }

  async fetchData() {
    await followProductIdsStore.tryFetchData();
    const { data } = await this.fetching(fly.get(`products/${this.productId}`, { is_preview: this.isPreview ? true : undefined }));
    data.is_follow = followProductIdsStore.data.includes(data.id);
    this.data = data;
  }

  initSubscription() {
    const params = { Locale: i18n.locale };
    if (authStore.access_token) {
      params.Authorization = authStore.access_token;
    }
    const query = qs.stringify(params);
    const consumer = this.consumer = createConsumer(`/cable?${query}`);
    this.subscription = consumer.subscriptions.create({ channel: 'ProductChannel', id: this.productId }, {
      received: (result) => this.received(result),
      disconnected: (result) => {
        this.vm && this.vm.$emit('disconnected', result);
      },
    });
  }

  disconnect() {
    if (this.consumer) {
      this.consumer.disconnect();
    }
  }

  // ## ProductChannel Code
  // | Code | Reason        |
  // | ---- |:--------------|
  // | E001 | 当前商品不能竞拍 |
  // | E002 | 出价不是有效出价 |
  // | E003 | 用户已经是最高价的出价者，不能再出价 |
  // | E004 | 当前不能修改自动出价金额 |
  // | E011 | 用户被禁用/禁止出价 |
  // | E012 | 可竞价额度小于当前商品设置的保证金禁止出价
  async received(result) {
    const { success, action, data, message } = result;
    if (success) {
      switch (action.toLowerCase()) {
        case 'bid':
          this.updateBid(data);
          return;
        case 'update_auto_bidding_price':
          this.updateAutoPrice(data);
          return;
        case 'bidding_prices':
          this.updateBiddingPrices(data);
          return;
        case 'deal':
          this.dealProduct(data);
          return;
        case 'abort':
          this.abortProduct(data);
          return;
        case 'delay':
          this.delayProduct(data);
          return;
      }
    } else {
      await MessageBox.alert(message, { showClose: false });
    }
  }

  // 自动出价或手动出价
  // auto: true - 自动出价 false - 手动出价
  bid(price, auto) {
    this.subscription.perform('bid', { price, auto });
  }

  // 修改了自动出价价格
  updateAutoBidding(price) {
    this.subscription.perform('update_auto_bidding_price', { price });
  }

  // 出价记录
  biddingPrices() {
    this.subscription.perform('bidding_prices');
  }

  resetBiddingPrices() {
    this.biddingPrices = [];
  }

  async updateBid(data) {
    const autoBiddingPrice = _.get(this.data, 'auto_bidding_price');
    await this.fetchData();
    // 暂存auto_bidding_price值，在fetchdata后重新赋值防止出现闪烁的情况
    this.vm.$set(this.data, 'auto_bidding_price', autoBiddingPrice);
    // 获取动态数据拼接到store.data
    const dynamicData = await fly.get(`products/${this.productId}`, {
      entity: 'DynamicProduct',
      need_attributes: ['auto_bidding_price', 'current_user_bidding_price'],
      is_preview: this.isPreview ? true : undefined
    });
    this.vm.$set(this.data, 'auto_bidding_price', dynamicData.data.auto_bidding_price);
    this.vm.$set(this.data, 'current_user_bidding_price', dynamicData.data.current_user_bidding_price);
    this.biddingPrices.unshift(data);
    this.vm && this.vm.$emit('updateBid', data);
  }

  async updateAutoPrice(data) {
    await this.fetchData();
    this.vm.$set(this.data, 'auto_bidding_price', data.highest_price);
    this.vm && this.vm.$emit('updateAutoPrice');
  }

  updateBiddingPrices(data) {
    this.biddingPrices = data;
  }

  dealProduct(data) {
    this.vm && this.vm.$emit('dealProduct', data);
    this.vm.$set(this.data, 'deal_price', data.deal_price);
  }

  abortProduct(data) {
    this.vm.$set(this.data, 'bid_status', data.bid_status);
  }

  delayProduct(data) {
    this.vm.$set(this.data, 'ended_bidding_time', data.ended_bidding_time);
  }

  /**
   * @param {Number} price - 当前价
   * @param {Number} basePrice - 基数
   * @param {Array} range - 价格临界点 [100, 200]
   * @param {Boolean} reverse - 减法
   */
  calcStepPrice(price, basePrice, range, reverse) {
    const [min, max] = range;
    const steps = [0, 2, 5, 8].map(item => item * (basePrice / 10));
    const remainder = price % basePrice;
    const stepPrice = reverse ? steps.reverse().find(step => step < remainder) : steps.find(step => step > remainder);
    const intPirce = Math.floor(price / basePrice) * basePrice;
    if (!_.isUndefined(stepPrice)) {
      return Math.min(intPirce + stepPrice, max);
    }
    // 跳到下一个阶段
    if (reverse) {
      return Math.max(intPirce - basePrice + steps[0], min);
    }
    return Math.min(intPirce + basePrice + steps[0], max);
  }

  addPrice(price) {
    const rules = _.cloneDeep(this.raiseRules).sort((a, b) => b.start_at - a.start_at);
    let rule = rules.find(item => price >= item.start_at && price < item.end_at);
    // 没有匹配的规则取第一个规则
    if (!rule && rules.length) {
      rule = {
        ...rules[0],
        end_at: Number.MAX_SAFE_INTEGER
      };
    }
    if (!rule) {
      return price + 500;
    }
    if (rule.kind === 'two_five_eight') {
      return this.calcStepPrice(price, Math.pow(10, String(price).length - 1), [rule.start_at, rule.end_at]);
    }
    if (rule.kind === 'normal') {
      return Math.min(Math.floor(price / rule.minimum) * rule.minimum + rule.minimum, rule.end_at);
    }
  }

  minusPrice(price) {
    const rules = _.cloneDeep(this.raiseRules).sort((a, b) => a.start_at - b.start_at);
    let rule = rules.find(item => price > item.start_at && price <= item.end_at);
    // 没有匹配的规则取第一个规则
    if (!rule && rules.length) {
      rule = {
        ...rules[0],
        start_at: 0
      };
    }
    if (!rule) {
      return Math.max(0, price - 500);
    }
    if (rule.kind === 'two_five_eight') {
      return this.calcStepPrice(price, Math.pow(10, String(price).length - 1), [rule.start_at, rule.end_at], true);
    }
    if (rule.kind === 'normal') {
      return Math.max(Math.floor(price / rule.minimum) * rule.minimum - rule.minimum, rule.start_at);
    }
  }
}
