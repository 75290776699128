<i18n src="../locales.json"></i18n>

<template>
  <div class="filter-group">
    <el-select
      :value="currency"
      :placeholder="$t('allCurrency')"
      :popper-append-to-body="false"
      filterable
      clearable
      @change="handleChange($event, 'currency_eq')"
      class="uppercase"
    >
      <el-option
        v-for="currency in ['jpy', 'hkd']"
        :key="currency"
        :value="currency"
        :label="currency"
      ></el-option>
    </el-select>
    <el-select
      v-if="showStatus"
      :value="status"
      :placeholder="$t('allRefundStatus')"
      :popper-append-to-body="false"
      filterable
      clearable
      @change="handleChange($event, 'status_eq')"
    >
      <el-option
        v-for="status in ['pending', 'approved', 'rejected']"
        :key="status"
        :value="status"
        :label="$t(`refundApplications.${[status]}`)"
      ></el-option>
    </el-select>
  </div>
</template>

<script>
  import { Vue, Component, Prop } from 'vue-property-decorator';

  @Component
  export default class withdrawCurrencyFilter extends Vue {
    @Prop(Boolean) showStatus

    get currency() {
      const { currency_eq } = this.$route.query;

      return currency_eq;
    }

    get status() {
      const { status_eq } = this.$route.query;

      return status_eq;
    }

    handleChange(e, queryKey) {
      this.$router.replace({ query: { ...this.$route.query, [queryKey]: e || undefined } });
    }
  }
</script>

<style lang="scss" scoped>
.filter-group {
  /deep/ .el-select {
    &.uppercase {
      .el-input__inner {
        text-transform: uppercase;
      }

      .el-select-dropdown__item {
        text-transform: uppercase;
      }
    }

    &:not(:last-child) {
      margin-right: 20px;
    }

    .el-input__inner {
      box-sizing: border-box;
      height: 38px;
      border: none;
      border-bottom: 2px solid #dcdddc;
      border-radius: 0;
      font-size: 16px;
      color: #000;

      @include text-overflow();

      @include media-xs-only {
        font-size: 14px;
      }

      &::placeholder {
        color: #000;
      }
    }

    .el-select-dropdown {
      width: 100%;
    }
  }
}
</style>
