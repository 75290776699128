<i18n>
{
  "zh_cn": {
    "pending": "直播加载中",
    "stop": "请点击播放",
    "empty": "当前暂无直播",
    "disconnect": "网络异常",
    "notAgora": "当前浏览器不支持观看视频，请切换浏览器观看",
    "confirmPlayVideo": "确定播放视频",
    "liveEnd": "当前没有直播，已自动退出频道，如想知道最新直播状态请刷新页面"
  },
  "zh_hk": {
    "pending": "直播加載中",
    "stop": "請點擊播放",
    "empty": "當前暫無直播",
    "disconnect": "網絡異常",
    "notAgora": "當前瀏覽器不支持觀看視頻，請切換瀏覽器觀看",
    "confirmPlayVideo": "確定播放視頻",
    "liveEnd": "當前沒有直播，已自動退出頻道，如想知道最新直播狀態請刷新頁面"
  },
  "en": {
    "pending": "Live streaming is loading",
    "stop": "Please click to play",
    "empty": "Currently no live broadcast",
    "disconnect": "Network anomaly",
    "notAgora": "The current browser does not support watching the video, please switch the browser to watch",
    "confirmPlayVideo": "Make sure to play the video",
    "liveEnd": "There is currently no live broadcast, and the channel has been automatically exited. If you want to know the latest live broadcast status, please refresh the page"
  },
  "ja": {
    "pending": "ライブストリーミングが読み込まれています",
    "stop": "クリックしてプレイしてください",
    "empty": "現在、生放送はありません",
    "disconnect": "ネットワークの異常",
    "notAgora": "現在のブラウザはビデオの視聴をサポートしていません。視聴するにはブラウザを切り替えてください",
    "confirmPlayVideo": "生放送の再生を決定します",
    "liveEnd": "現在、ライブ配信はありません。チャンネルは自動的に退出されました。最新のライブ配信状況を知りたい場合は、ページを更新してください"
  }
}
</i18n>

<template>
  <div class="live-wrapper"
    :class="['live-wrapper', { 'mobile-full-screen': $isMobile() && fullScreen }]"
    :style="fullScreenStyle"
    ref="liveWrapper"
    @mouseover="handleOperateShowChange(true)"
    @mouseout="handleOperateShowChange(false)"
    @click="handleOperateShowChange('', true)"
    @touchmove.prevent>
    <div v-for="(item) in users"
      :key="item.uid"
      :id="item.uid"
      :class="['remote-container', { small: item.streamType }]">
    </div>
    <div :class="['operate-bg flex item-center', { show: operateShow, fullScreen: fullScreen }]" v-if="hasLocalVideoTrack">
      <img class="icon" :src="videoIcon" @click.stop="handleVideoChange" />
      <img class="icon" :src="audioIcon" @click.stop="handleAudioChange" />
      <img class="icon" v-if="users.length === 2" src="@/assets/icon-live-trigger.png" @click.stop="setUserStreamType(true)" />
      <img :class="['icon', { 'is-mobile-full-screen': $isMobile() && fullScreen }]" :src="fullScreenIcon" @click.stop="handleFullScreen" />
    </div>
    <div class="status-bg flex content-center item-center" v-if="showStatus">
      <div>
        <img v-if="showAudioStopIcon" src="@/assets/icon-audio-stop.png" class="live-icon" @click.stop="handleVideoChange" />
        <img v-else src="@/assets/icon-live-player.png" class="live-icon"/>
        <div class="tips">{{ stautsText }}</div>
      </div>
    </div>
  </div>
</template>
<script>
  import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
  import AgoraRTC from 'agora-rtc-sdk-ng';
  import iconAudioOn from '@/assets/icon-audio-on.png';
  import iconAudioOff from '@/assets/icon-audio-off.png';
  import iconVideoOn from '@/assets/icon-video-on.png';
  import iconVideoOff from '@/assets/icon-video-off.png';
  import iconFullScreenOn from '@/assets/icon-full-screen-on.png';
  import iconFullScreenOff from '@/assets/icon-full-screen-off.png';
  import _ from 'lodash';

  @Component
  export default class AgoraLive extends Vue {
    @Prop(Object) agoraPreferences
    @Prop(Boolean) liveAndRateFullScreen

    // 第一次进入
    isFirst = true;
    // 用来放置本地客户端
    client = null;
    // 用来放置本地音视频频轨道对象
    // localAudioTrack = null;
    // localVideoTrack = null;
    postImage = '';
    // 声音控制
    audioStatus = false;
    // 画面控制
    videoStatus = false;
    operateShow = true;
    status = 'pending';
    fullScreen = false;
    fullScreenStyle = {};

    // 多人连麦
    // 主播
    users = []

    get isIOS() {
      return /(iPhone|iPad|iPod)/i.test(navigator.userAgent);
    }

    get stautsText() {
      return this.$t(this.status);
    }

    get audioIcon() {
      return this.audioStatus ? iconAudioOn : iconAudioOff;
    }

    get videoIcon() {
      return this.videoStatus ? iconVideoOn : iconVideoOff;
    }

    get fullScreenIcon() {
      return this.fullScreen ? iconFullScreenOff : iconFullScreenOn;
    }

    get isIOSWechat() {
      const userAgent = navigator.userAgent;
      return /iPhone|iPod|iPad/i.test(userAgent) && /MicroMessenger/i.test(userAgent);
    }

    // 是否有本地播放，多人连麦情况只要有一个存在即为true
    get hasLocalVideoTrack() {
      if (!this.users.length) {
        return false;
      }
      return Boolean(_.find(this.users, ({ videoTrack }) => videoTrack));
    }

    get showStatus() {
      if (!this.users.length) {
        return true;
      }
      return !this.hasLocalVideoTrack || !this.videoStatus || this.status === 'disconnect';
    }

    get showAudioStopIcon() {
      if (this.status === 'disconnect' || !this.users.length) {
        return false;
      }
      return !this.hasLocalVideoTrack || !this.videoStatus;
    }

    setMobileFullScreenStyle() {
      if (!this.$isMobile()) {
        return;
      }
      if (!this.fullScreen) {
        this.fullScreenStyle = {};
        return;
      }
      const { innerWidth, innerHeight } = window;
      const w = Math.max(innerWidth, innerHeight);
      const h = Math.min(innerWidth, innerHeight);
      const style = {
        top: 0,
        left: 0,
        width: `${w}px`,
        height: `${h}px`,
      };
      if (innerWidth < innerHeight) {
        const position = (w - h) / 2;

        if (!this.liveAndRateFullScreen) {
          Object.assign(style, {
            top: `${position}px`,
            left: `-${position}px`,
            transform: 'rotate(90deg)'
          });
        }
      }
      this.fullScreenStyle = style;
    }

    checkIsFullscreen() {
      return Boolean(
        document.fullscreenElement ||
        document.msFullscreenElement ||
        document.mozFullScreenElement ||
        document.webkitFullscreenElement || false
      );
    }

    setFullScreen(ele) {
      const func =
        ele.requestFullscreen ||
        ele.mozRequestFullScreen ||
        ele.webkitRequestFullscreen ||
        ele.msRequestFullscreen;
      func.call(ele);
    }

    exitFullscreen() {
      const func =
      document.exitFullScreen ||
      document.mozCancelFullScreen ||
      document.webkitExitFullscreen ||
      document.msExitFullscreen;
      func.call(document);
    }

    async openAgoraLive() {
      const check = AgoraRTC.checkSystemRequirements();
      // eslint-disable-next-line no-console
      console.log('check', check);
      // TODO: 声网有bug
      // if (!check) {
      //   this.$showToast(this.$t('notAgora'));
      //   return;
      // }
      const client = AgoraRTC.createClient({ mode: 'live', codec: 'vp8' });
      // 主播ID
      client.on('user-published', async (user) => {
        const { uid, videoTrack } = user;
        if (!_.find(this.users, (v) => v.uid === user.uid)) {
          this.users.push({ uid, videoTrack, user, subscribe: [], streamType: 1 });
        }
        this.status = 'stop';
        this.setUserStreamType();
        console.log('user-published');
        if (this.videoStatus && (this.users.length > 1 || !this.isFirst)) {
          this.subscribeVideo();

          // 断连、多主播加入，订阅音频
          this.audioStatus && this.subscribeAudio();
        }
      });
      client.on('user-unpublished', async (user1, mediaType) => {
        console.log('user-unpublished');
        if (mediaType === 'video' && this.users.length) {
          const index = _.findIndex(this.users, ({ user }) => user.uid === user1.uid);
          // 移除订阅
          this.unSubscribeVideo(user1.uid);
          this.unSubscribeAudio(user1.uid);
          this.users.splice(index, 1);
          this.setUserStreamType();
        }
        if (this.$isMobile()) {
          this.fullScreen = false;
          this.setMobileFullScreenStyle();
        }
        this.status = this.users.length ? '' : 'empty';
      });
      client.setClientRole('audience');
      const { appid, token, channel, uid } = this.agoraPreferences;
      // 状态监听
      client.on('connection-state-change', (status, reState, reson) => {
        // 断开、重连中
        if (['DISCONNECTING', 'RECONNECTING'].includes(status)) {
          this.status = 'disconnect';
          this.operateShow = false;
        }
        if (this.status === 'disconnect' && status === 'CONNECTED') {
          this.status = this.videoStatus ? 'playing' : 'stop';
          this.operateShow = true;
        }
        // 可能被踢出去了
        // https://docs.agora.io/cn/Interactive%20Broadcast/API%20Reference/web_ng/enums/connectiondisconnectedreason.html
        if (status === 'DISCONNECTED' && reson === 'UID_BANNED') {
          this.$msgbox.alert(this.$t('liveEnd'));
        }
      });
      try {
        await client.join(appid, channel, token || null, uid);
        this.client = client;
        // 3s后视频还是没收到推送的流，说明没有在直播(直播故障了)
        await this.$sleep(3000);
        if (!this.users.length) {
          this.status = 'empty';
        }
      } catch (error) {
        this.status = 'disconnect';
        this.operateShow = false;
      }
    }

    // 设置大小流
    setUserStreamType(change) {
      let bigUid = this.agoraPreferences.host_uids[0];
      if (this.users.length === 1) {
        bigUid = this.users[0].user.uid;
      }
      if (change) {
        const bigIndex = _.findIndex(this.users, (item) => item.streamType === 0);
         // 只会有两个主播进行大小流切换
        bigUid = this.users[bigIndex ? 0 : 1].user.uid;
      }
      this.users.forEach((item) => {
        let streamType = item.user.uid === bigUid ? 0 : 1;
        item.streamType = streamType;
        this.client.setRemoteVideoStreamType(item.user.uid, streamType);
      });
    }

    async subscribeVideo() {
      const subscribeList = [];
      const list = this.users.filter(({ subscribe }) => !subscribe.includes('video'));
      list.forEach(({ user }) => {
        subscribeList.push(this.client.subscribe(user, 'video'));
      });
      await Promise.all(subscribeList);
      const play = () => {
        list.forEach((item) => {
          item.user.videoTrack.play(String(item.user.uid));
          item.videoTrack = item.user.videoTrack;
        });
      };
      play();
      this.videoStatus = true;
      this.status = '';

      // 问题描述：目前点击播放之后才sub，微信浏览器认为还是没有用户点击操作，出现黑屏
      // 解决方法：弹窗让用户点击一次屏幕再播放视频
      if (this.isFirst && this.isIOSWechat) {
        try {
          await this.$alert(this.$t('confirmPlayVideo'));
          play();
        } catch (e) {
          play();
        }
      }
    }

    async subscribeAudio() {
      const subscribeList = [];
      const list = this.users.filter(({ subscribe }) => !subscribe.includes('audio'));
      list.forEach(({ user }) => {
        subscribeList.push(this.client.subscribe(user, 'audio'));
      });
      await Promise.all(subscribeList);
      list.forEach(({ user }) => {
        user.audioTrack.play();
      });
      this.audioStatus = true;
    }

    async unSubscribeVideo(uid) {
      this.users.forEach((item) => {
        if (uid && uid !== item.user.uid) {
          return;
        }
        item.videoTrack = null;
        this.client.unsubscribe(item.user, 'video');
        item.subscribe = Array.from(new Set(item.subscribe).delete('video'));
        item.user.videoTrack && item.user.videoTrack.stop();
      });
      // 远端关闭或者网络异常，不修改
      !uid && (this.videoStatus = false);
      this.status = 'stop';
    }

    async unSubscribeAudio(uid) {
      this.users.forEach((item) => {
        if (uid && uid !== item.user.uid) {
          return;
        }
        this.client.unsubscribe(item.user, 'audio');
        item.subscribe = Array.from(new Set(item.subscribe).delete('audio'));
        item.user.audioTrack && item.user.audioTrack.stop();
      });
      // 远端关闭或者网络异常，不修改
      !uid && (this.audioStatus = false);
    }

    async handleAudioChange() {
      const isPlaying = _.every(this.users, ({ user }) => user.audioTrack && user.audioTrack.isPlaying);
      if (isPlaying) {
        await this.unSubscribeAudio();
      } else {
        await this.subscribeAudio();
      }
      this.audioStatus = !isPlaying;
    }

    async handleVideoChange() {
      const isPlaying = _.every(this.users, ({ user }) => user.videoTrack && user.videoTrack.isPlaying);
      if (isPlaying) {
        await this.unSubscribeVideo();
      } else {
        await this.subscribeVideo();
      }
      // 第一次播放视频的时候，音频也一并开启
      if (this.isFirst) {
        this.isFirst = false;
        if (!this.audioStatus) {
          await this.subscribeAudio();
        }
      }
      this.videoStatus = !isPlaying;
    }

    handleFullScreen() {
      if (this.$isMobile()) {
        // 移动端全屏
        this.fullScreen = !this.fullScreen;
        this.setMobileFullScreenStyle();
      } else {
        const check = this.checkIsFullscreen();
        this.fullScreen = !check;
        this.fullScreen ? this.setFullScreen(this.$refs.liveWrapper) : this.exitFullscreen();
      }
    }

    handleOperateShowChange(show, trigger) {
      if (!this.hasLocalVideoTrack) {
        return;
      }
      if (trigger) {
        this.operateShow = !this.operateShow;
      } else {
        this.operateShow = show;
      }
    }

    init() {
      if (this.agoraPreferences.appid) {
        this.status = this.agoraPreferences.is_live ? 'playing' : 'empty';
        this.openAgoraLive();
        this.handleAudioAutoplayFailed();
      }
    }

    handleAudioAutoplayFailed() {
      // IOS safari不支持webaudio autoplay，需兼容
      AgoraRTC.onAudioAutoplayFailed = () => {
        if (this.audioStatus) {
          this.handleAudioChange();
        }
      };
    }

    async mounted() {
      AgoraRTC.enableLogUpload();
      AgoraRTC.setLogLevel(1); // AgoraRTC.Logger.INFO
      this.init();
      window.addEventListener('resize', this.handleResize);
    }

    async handleResize() {
      if (!this.$isMobile()) {
        return;
      }
      await this.$sleep(300);
      this.setMobileFullScreenStyle();
    }

    beforeDestroy() {
      window.removeEventListener('resize', this.handleResize);
      if (this.client) {
        this.client.leave();
        this.client.off();
      }
      AgoraRTC.disableLogUpload();
    }

    @Watch('agoraPreferences')
    onAgoraPreferencesChange() {
      this.init();
    }

    @Watch('fullScreen')
    onFullScreenChange(value) {
      // 由号牌的层级别和视频全屏有冲突，通过事件来控制号牌
      this.$emit('mobileFullScreen', value);
    }
  }
</script>

<style lang="scss" scoped>
  .live-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: #f2f2f2;

    .remote-container {
      width: 100%;
      height: 100%;

      &.small {
        position: absolute;
        z-index: 10;
        bottom: 30px;
        left: 10px;
        width: 30%;
        height: 28%;
      }
    }

    .operate-bg {
      position: absolute;
      z-index: 10;
      bottom: 0;
      left: 0;
      box-sizing: border-box;
      width: 100%;
      min-height: 30px;
      padding: 5px;
      background-color: rgba(#000, 0.5);
      transform: translateY(100%);
      transition: 0.5s transform;

      &.fullScreen {
        padding: 10px 5px;

        @media screen and (orientation:landscape) {
          @include paddingBottomSafeArea(10px);
        }
      }

      .icon {
        width: 20px;
        margin-right: 10px;
      }

      .is-mobile-full-screen {
        margin-right: 20px;
      }

      &.show {
        transform: translateY(0);
      }
    }

    .status-bg {
      position: absolute;
      z-index: 5;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #f2f2f2;

      .stop-icon {
        width: 64px;
      }

      .live-icon {
        display: block;
        width: 72px;
        margin: 0 auto;
      }

      .tips {
        margin-top: 10px;
        font-size: 12px;
        text-align: center;
        color: #9d9d9d;
      }
    }
  }

  .mobile-full-screen {
    position: fixed;
    z-index: var(--fixed-z-index);
    transform-origin: center center;
  }
</style>
