import { Component, Model, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Mixins extends Vue {
  @Prop({ type: Function, required: true }) onFetch
  @Model('input', Boolean) loading

  percentage = 0
  speed = 50

  async created() {
    this.percentage++;
    this.timer = setInterval(() => {
      if (this.percentage >= 90) {
        clearInterval(this.timer);
        return;
      }
      this.percentage++;
    }, this.speed);
    await this.onFetch();
    this.percentage = 100;
    clearInterval(this.timer);
    this.$emit('input', false);
  }
}
