<template>
  <div class="auto-height-wrapper" :style="{paddingBottom: height}">
    <div class="auto-height-content">
      <component
        :is="ossImg ? 'oss-img' : 'img'"
        v-bind="ossImg ? ossImg : {}"
        v-if="cover"
        :src="cover"
        class="auto-height-cover"
        :style="{ 'object-fit': coverFit }"
      />
      <slot/>
    </div>
  </div>
</template>

<script>
  import { Vue, Component, Prop } from 'vue-property-decorator';

  @Component
  export default class AutoHeight extends Vue {
    @Prop(String) cover
    @Prop({ type: String, default: '100%' }) height
    @Prop(Object) ossImg;
    @Prop({ type: String, default: 'cover' }) coverFit
  }
</script>

<style lang="scss" scoped>
  .auto-height-wrapper {
    position: relative;
    width: 100%;
    height: 0;

    .auto-height-content {
      position: absolute;
      top: 0;
      left: 0;
      background: #eee;

      &, .auto-height-cover {
        width: 100%;
        height: 100%;
      }
    }
  }
</style>
