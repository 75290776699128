import { Vue } from 'vue-property-decorator';
import './styles/global.scss';
import 'element-ui/lib/theme-chalk/display.css';
import router from './router';
import VueAnalytics from 'vue-analytics';
import { i18n } from './i18n';
import { authStore, auctionHouseStore } from '@/store';
import _ from 'lodash';
import VueClipboard from 'vue-clipboard2';

import './components';
import './filters';
import 'video.js/dist/video-js.css';

import PortalVue from 'portal-vue';
import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter';
import { Swiper as SwiperClass, Pagination, Autoplay, Thumbs, Navigation, Controller } from 'swiper/core';
SwiperClass.use([Pagination, Autoplay, Thumbs, Navigation, Controller]);
import 'swiper/swiper-bundle.css';

Vue.use(getAwesomeSwiper(SwiperClass))
  .use(PortalVue)
  .use(VueClipboard);

Vue.prototype.$get = _.get;
Vue.prototype.$camelCase = _.camelCase;

Vue.prototype.$authStore = Vue.observable(authStore);
Vue.prototype.$auctionHouseStore = Vue.observable(auctionHouseStore);

Vue.config.productionTip = false;

if (process.env.VUE_APP_ENV !== 'production') {
  const VConsole = require('vconsole');
  if (/Android|WindowsPhone|webOS|iPhone|iPod|BlackBerry|iPad/i.test(navigator.userAgent)) {
    new VConsole;
  }
}

//GA初始化
if (process.env.VUE_APP_GA) {
  Vue.use(VueAnalytics, {
    id: process.env.VUE_APP_GA, // 从配置中读取
    disableScriptLoader: true, // 必须在html中完成初始化，这里显式禁止去下载ga脚本
    router, // 确保路由切换时可以自动统计
    autoTracking: {
      pageviewOnLoad: false // 当通过网址进来时已经GA在初始化时就发起一次pageview的统计，这里不要重复统计
    }
  });
}

new Vue({
  router,
  i18n,
  data: {
    windowWidth: window.innerWidth
  },
  render: h => h(require('./app').default)
}).$mount('#app');
