<i18n src="./locales.json"></i18n>

<template>
  <div class="page-products">
    <v-title :value="$t('search')" />
    <div class="input-group flex item-center content-center">
      <el-input
        class="pc-input flex" type="search"
        v-model="keyword"
        :placeholder="$t('search')"
        @keypress.enter.native="handleSearch"
      >
        <div slot="suffix" class="flex row content-center item-center" @click="handleSearch">
          <img src="@/assets/icon-search-white.png" class="icon" />
        </div>
      </el-input>
      <div class="sort no-shirk hidden-xs-only" v-if="sortItems.length" @click="showSortDialog = true">{{ $t(currentSort) }} &gt;</div>
    </div>

    <div class="tabs-wrapper">
      <div
        :class="['tab', { active: current === index }]"
        v-for="(item, index) in tabList"
        :key="item.label"
        @click="handleSetSwiperCurrent(index)"
      >
        {{ item.label }}
      </div>

      <div class="flex flex-1 content-end hidden-sm-and-up" v-if="sortItems.length">
        <img class="icon" src="@/assets/icon-sort-grey.png" @click="showSortDialog = true" />
      </div>
    </div>

    <div class="container">
      <div class="list-wrapper">
        <el-row :gutter="8" justify="space-between" v-if="currentTab.value === 'products'">
          <template v-if="!currentTab.collection.isFulfilled">
            <el-col :xs="8" :sm="6" :md="4" v-for="item in 24" :key="item">
              <skeleton-product-item />
            </el-col>
          </template>

          <template v-else>
            <el-col :xs="8" :sm="6" :md="4" v-for="item in currentTab.collection.data" :key="item.id">
              <product-item class="component-product-item" :product="item" />
            </el-col>
          </template>
        </el-row>

        <el-row :gutter="8" justify="space-between" v-if="currentTab.value === 'auction_items'">
          <template v-if="!currentTab.collection.isFulfilled">
            <el-col :xs="24" :sm="12" :md="8" v-for="item in 15" :key="item">
              <div class="flex column">
                <skeleton-auction-item-card />
              </div>
            </el-col>
          </template>

          <template v-else>
            <el-col :xs="24" :sm="12" :md="8" v-for="item in currentTab.collection.data" :key="item.id">
              <router-link :to="{ name: 'auctionItemDetail', params: { id: item.id }, query: { keyword, sort: $route.query.sort  } }">
                <auction-item-card
                  class="item"
                  :title="`LOT ${item.title}`"
                  :cover="$get(item, 'cover.url')"
                  :info="item.title"
                >
                  <div slot="title" v-if="item.number">LOT {{ item.number }}</div>
                  <div class="price">
                    <div v-if="item.is_finished && item.show_items_final_price && $authStore.isLogin">
                      <div v-if="['hammered', 'force_hammered'].includes(item.status)">
                        <div>
                          {{ $t('dealPrice') }}:
                          <span class="text-uppercase">{{ $get(item, 'auction_category.currency') }}</span>
                          {{ item.final_price | currencyFormat }}
                        </div>
                        <div v-if="!$options.filters.isLocaleCurrency($get(item, 'auction_category.currency'))">
                          {{ $t('dealPrice') }}: {{ $t('currency') }} {{ item.final_price | conversionFormat($get(item, 'auction_category.currency')) | currencyFormat }}
                        </div>
                      </div>
                      <div v-else>
                        {{ $t('aborted') }}
                      </div>
                    </div>
                    <div v-else-if="item.starting_price <= 0">
                      {{ $t('estimatePending') }}
                    </div>
                    <div v-else>
                      <div>
                        {{ $t('startPrice') }}:
                        <span class="text-uppercase">{{ $get(item, 'auction_category.currency') }}</span>
                        {{ item.starting_price | currencyFormat }}
                      </div>
                      <div v-if="!$options.filters.isLocaleCurrency($get(item, 'auction_category.currency'))">
                        {{ $t('startPrice') }}: {{ $t('currency') }} {{ item.starting_price | conversionFormat($get(item, 'auction_category.currency')) | currencyFormat }}
                      </div>
                    </div>
                  </div>
                </auction-item-card>
              </router-link>
            </el-col>
          </template>
        </el-row>

        <el-row :gutter="8" justify="space-between" v-if="currentTab.value === 'topic'">
          <template v-if="!currentTab.collection.isFulfilled">
            <el-col :xs="24" :sm="12" :md="8" v-for="item in 16" :key="item">
              <skeleton-live-card />
            </el-col>
          </template>

          <template v-else>
            <el-col :xs="24" :sm="12" :md="8" v-for="item in currentTab.collection.data" :key="item.id">
              <live-card :live="item" :routeTo="{ name: 'topicDetail', params: { slug: item.slug } }">
                <template slot="footer">
                  <img src="@/assets/icon-arrow-right.png" class="live-card-content icon">
                  <div class="live-card-content theme-title">{{ item.title }}</div>
                </template>
              </live-card>
            </el-col>
          </template>
        </el-row>

        <div class="product-list-empty" v-if="currentTab.collection.isEmpty">
          <img src="@/assets/icon-select-empty.png" />
          <div>{{ $t('noResult') }}</div>
        </div>
        <pagination routeMode class="pagination" :store="currentTab.collection" />
      </div>
    </div>

    <el-dialog
      class="dialog-sort"
      :visible.sync="showSortDialog"
      :width="dialogWidth"
      :show-close="false"
      center
    >
      <div>
        <div class="header">{{ $t('sorting') }}</div>
        <div
          class="sort-item"
          :class="{ selected: currentSort === item.label }"
          v-for="item in sortItems"
          :key="item.value"
          @click="handleSelectedSort(item)"
        >
          {{ $t(item.label) }}
        </div>
      </div>
      <div class="btn-wrapper">
        <div class="btn-confirm primary-btn" @click="handleCancelSort">{{ $t('cancel') }}</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';
  import { Collection, followProductIdsStore, auctionNumbersStore, auctionHouseStore } from '@/store';
  import _ from 'lodash';
  import { initSubscription } from '@/utils';

  @Component
  export default class Products extends Vue {
    keyword = ''
    showSortDialog = false
    currentSort = '' // 当前排序
    followProductIdsStore = followProductIdsStore
    current = 0

    auctionHouseStore = auctionHouseStore

    tabs = [
      {
        label: this.$t('product'),
        value: 'products',
        collection: new Collection({
          params: {
            per_page: 24
          },
          fetch: params => this.$fly.get('products', params)
        })
      },
      {
        label: this.$t('auctionItem'),
        value: 'auction_items',
        collection: new Collection({
          params: {
            per_page: 15,
            entity: 'AllAuctionItem',
            sort: 'visits_count desc'
          },
          fetch: params => this.$fly.get('auction_items', params)
        })
      },
      {
        label: this.$t('topic'),
        value: 'topic',
        collection: new Collection({
          params: {
            per_page: 16,
            default_eq: false
          },
          fetch: params => this.$fly.get('custom_pages', params)
        })
      }
    ];

    get tabList() {
      return this.tabs.filter(Boolean);
    }

    get sortItems() {
      return {
        products: [
          { label: 'shortTime', value: 'remaining_time asc' },
          { label: 'longTime', value: 'remaining_time desc' },
          { label: 'priceAscending', value: 'price asc' },
          { label: 'priceDescending', value: 'price desc' },
          { label: 'bidAscending', value: 'product_bidding_prices_count asc' },
          { label: 'bidDescending', value: 'product_bidding_prices_count desc' },
          { label: 'onePriceAscending', value: 'confirmed_price asc' },
          { label: 'onePriceDescending', value: 'confirmed_price desc' },
        ],
        auction_items: [
          { label: 'visitCountAscending', value: 'visits_count asc' },
          { label: 'visitCountDescending', value: 'visits_count desc' },
        ],
        topic: []
      }[this.currentTab.value];
    }

    get currentTab() {
      return this.tabList[this.current];
    }

    get dialogWidth() {
      return this.$isMobile() ? '90%' : '360px';
    }

    async created() {
      const { type, page, keyword, status_in, auction_is_finished_eq } = this.$route.query;
      let sort = this.$route.query?.sort;

      this.keyword = keyword;
      const current = this.tabList.findIndex(item => item.value === type);
      this.current = current === -1 ? 0 : current;

      if (!sort) {
        sort = this.tabList[this.current].collection.params.sort;
      }

      // 找出sortItems中当前排序的对象
      const sortItem = this.sortItems.find(item => item.value === sort);
      this.currentSort = _.get(sortItem, 'label') || 'sorting';

      this.tabList[this.current].collection.params = {
        ...this.tabList[this.current].collection.params,
        page,
        sort,
        status_in,
        auction_is_finished_eq,
        keyword: this.keyword
      };

      await Promise.all([
        followProductIdsStore.tryFetchData(),
        this.currentTab.collection.fetchData(),
        auctionNumbersStore.tryFetchData()
      ]);

      if (this.currentTab.value === 'products' && !this.tabs[0].collection.isEmpty) {
        initSubscription(this, this.tabs[0].collection.data);
      }
    }

    beforeDestroy() {
      if (this.consumer) {
        this.consumer.disconnect();
      }
    }

    handleSelectedSort(item) {
      // 选中的为当前的排序时，直接关闭弹窗
      if (item.value === this.$route.query.sort) {
        return this.showSortDialog = false;
      }
      this.$router.replace({
        query: _.merge({}, this.$route.query, { sort: item.value, page: 1 })
      });
    }

    handleCancelSort() {
      // 当前无选择任何排序时，直接关闭弹窗
      if (this.$route.query.sort === '') {
        return this.showSortDialog = false;
      } else {
        this.$router.replace({
          query: _.merge({}, this.$route.query, { sort: '', page: 1 })
        });
      }
    }

    handleSearch() {
      if (this.keyword === this.$route.query.keyword) {
        return;
      }
      this.$router.replace({
        query: _.merge({}, this.$route.query, { keyword: this.keyword, page: 1 })
      });
    }

    handleSetSwiperCurrent(index) {
      if (this.current === index) {
        return;
      }
      this.current = index;
      this.$router.replace({
        query: { type: this.tabList[index].value, keyword: this.keyword }
      });
    }
  }
</script>

<style lang="scss" scoped>
  .title {
    margin: 29px 0 20px;

    @include media-sm-and-up {
      margin: none;
    }
  }

  /deep/ .el-input {
    outline: none;

    .el-input__inner {
      border: 2px solid #000;
      border-radius: 50px;
    }

    .el-input__suffix {
      right: 0;

      .el-input__suffix-inner {
        display: flex;
        align-items: center;
        height: 100%;
        border-radius: 50px;
        background-color: #000002;
        cursor: pointer;

        > * {
          height: 100%;
          padding: 0 25px;
        }
      }
    }
  }

  .input-group {
    width: 40%;
    margin: 35px auto;

    @include media-xs-only {
      flex-direction: column;
      align-items: flex-end;
      width: 80%;
      margin: 15px auto;
    }
  }

  .sort {
    height: 40px;
    padding: 0 6px;
    margin-left: 10px;
    border-radius: 4px;
    font-size: 12px;
    line-height: 40px;
    text-decoration: underline;
    color: $primary;
    cursor: pointer;
  }

  .icon {
    width: 15px;
    height: 15px;
    cursor: pointer;
  }

  .tabs-wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 0 auto 30px;
    border-bottom: 1px solid #e3e3e3;

    @include media-sm-and-up {
      justify-content: center;
    }

    .tab {
      display: flex;
      justify-content: center;
      padding: 0 20px 5px;
      margin: 0 60px -1px;
      color: #97989a;
      cursor: pointer;

      @include media-xs-only {
        padding-right: 10px;
        padding-left: 10px;
        margin-right: 15px;
        margin-left: 15px;
      }

      &.active {
        border-bottom: 2px solid #000;
        color: #000;
      }
    }

    .icon {
      width: 20px;
      height: 20px;
      margin-right: 15px;
    }
  }

  .list-wrapper {
    margin-bottom: 5px;

    @include media-sm-and-up {
      margin-bottom: 35px;
    }
  }

  .component-product-item {
    flex-shrink: 0;
    margin-bottom: 12px;

    @include media-xs-only {
      margin-bottom: 10px;
    }
  }

  .live-card-content {
    position: absolute;
    bottom: 15px;
    font-size: 16px;
    color: #fff;

    &.icon {
      right: 15px;
      width: 30px;
      height: 30px;
    }

    &.theme-title {
      left: 20px;
      max-width: 200px;
    }
  }

  .product-list-empty {
    margin: 46px auto 57px;
    text-align: center;
    color: #999;

    > img {
      width: 65px;
      height: 65px;
      margin-bottom: 36px;
    }
  }

  .pagination {
    padding: 10px 0 30px;
  }

  /deep/ .dialog-sort {
    border-radius: 19px;

    .el-dialog {
      border-radius: 19px;
    }

    .el-dialog__header {
      padding: 0;
    }

    .header {
      width: 100%;
      padding-bottom: 16px;
      border-bottom: 2px solid #ddd;
      font-weight: bold;
      font-size: 16px;
      text-align: center;
    }

    .sort-item {
      width: 100%;
      border-bottom: 1px solid #ddd;
      line-height: 40px;
      text-align: center;

      &:last-of-type {
        border-bottom: 2px solid #ddd;
      }

      &.selected {
        font-weight: bold;
        color: $primary;
      }

      &:hover {
        font-weight: bold;
        color: $primary;
        cursor: pointer;
      }
    }

    .btn-wrapper {
      margin-top: 30px;

      .btn-confirm {
        height: 38px;
        font-size: 15px;
        background: $primary;
      }
    }
  }
</style>
