<template>
  <el-select
    :value="value"
    :popper-append-to-body="false"
    filterable
    remote
    :loading="store.isFetching"
    :remote-method="handleRemoteMethod"
    @change="handleChange"
  >
    <el-option v-if="!isSearching" :label="allLabel" :value="undefined" />
    <el-option
      v-for="item in store.data"
      :key="item.id"
      :label="item.title"
      :value="item.id"
    />
  </el-select>
</template>

<script>
  import { Vue, Component, Prop } from 'vue-property-decorator';
  import { Collection } from '@/store';

  @Component
  export default class FilterItem extends Vue {
    @Prop(String) apiUrl
    @Prop(String) queryKey
    @Prop(String) allLabel

    isSearching = false
    store = {}

    get value() {
      if (this.store.isFulfilled) {
        return +this.$route.query[this.queryKey] || undefined;
      }
      return null;
    }

    async created() {
      this.store = new Collection({
        fetch: params => this.$fly.get(this.apiUrl, params)
      });
      await this.store.fetchData();
    }

    handleRemoteMethod(keyword) {
      this.isSearching = Boolean(keyword);
      this.store.fetchData({ keyword });
    }

    handleChange(e) {
      if (!e && e !== undefined) { return; }
      this.$router.replace({ query: { ...this.$route.query, [this.queryKey]: e || undefined } });
    }
  }
</script>

<style lang="scss" scoped>
</style>
