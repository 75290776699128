<i18n src="../locales.json"></i18n>

<template>
  <div>
    <div class="group">
      <div class="item-group">
        <div>{{ $t('auctionTime') }}</div><div>{{ auction.start_at | timeFormat }}</div>
      </div>
      <div class="item-group">
        <div>{{ $t('auctionPlace') }}</div><div>{{ auction.place }}</div>
      </div>
      <div class="item-group">
        <div>{{ $t('marginRatioTip') }}</div><div>{{ `1 : ${auction.margin_ratio}` }}</div>
      </div>
    </div>
    <div class="group" v-if="auction.commission_percent">
      <div class="item-group">
        <div>{{ $t('auctionCommission') }}</div><div>{{ auction.commission_percent }}</div>
      </div>
    </div>
    <div class="group" v-if="auction.instruction">
      <div class="item-group rich-text" style="display: block;" v-html="auction.instruction"/>
    </div>
  </div>
</template>

<script>
  import { Vue, Component, Prop } from 'vue-property-decorator';

  @Component
  export default class ActionSheetContent extends Vue {
    @Prop({ type: Object, default: () => ({}) }) auction
  }
</script>

<style lang="scss" scoped>
  .group {
    padding: 0 16px;

    &:not(:last-child) {
      border-bottom: 10px solid #f2f2f2;
    }

    .item-group {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px 0;
      color: #666;

      &:not(:last-child) {
        border-bottom: 1px solid #ddd;
      }

      &.rich-text {
        /deep/ table {
          width: 100% !important;
        }
      }
    }
  }
</style>
