<i18n src="./locales.json"></i18n>

<template>
  <div class="wrapper flex">
    <v-title :value="$t('bills')"/>
    <common-sidebar class="hidden-xs-only" />
    <div class="container">
      <div class="title">{{ $t('bills') }}</div>

      <div class="filter-wrap flex content-end">
        <withdrawCurrencyFilter />
      </div>

      <div v-if="!store.isEmpty" class="list">
        <div class="bill-item" v-for="item in store.data" :key="item.id">
          <div class="flex item-center content-between">
            <div class="content">
              <div class="name">{{ item.desc }}</div>
              <div class="time">{{ item.created_at | timeFormat }}</div>
            </div>
            <div :class="['amount text-uppercase', { primary: item.amount > 0 }]">
              {{ item.amount > 0 ? `+ ${item.currency} ${$options.filters.currencyFormat(item.amount)}` : `- ${item.currency} ${$options.filters.currencyFormat(Math.abs(item.amount))}` }}
            </div>
          </div>
        </div>
      </div>

      <div v-else class="list-empty">
        <img src="@/assets/icon-select-empty.png" />
        <div>{{ $t('listEmpty') }}</div>
      </div>

      <pagination class="pagination" :store="store"/>

      <div class="btn primary-btn-o" @click="showWithDraw = true">{{ $t('refund') }}</div>

      <div class="hint-text">
        <div
          v-if="settingsStore.settings.refund_hint && !$auctionHouseStore.domainHouse"
          v-html="$get(settingsStore, 'settings.refund_hint')"
        ></div>
        <div
          v-else-if="$auctionHouseStore.domainHouseDetail.refund_hint"
          v-html="$get($auctionHouseStore, 'domainHouseDetail.refund_hint')"
        ></div>
      </div>
    </div>

    <el-dialog :visible.sync="showWithDraw" :title="$t('canWithdrawAmount')" center>
      <div class="message-box">
        <div @click=handleNavRefundApplications class="refund-btn">{{ `${$t('myRefundApplications')}>` }}</div>
        <div class="amount">
          <el-select v-model="selectCurrency" :popper-append-to-body="false">
            <el-option
              v-for="currency in ['jpy', 'hkd']"
              :key="currency"
              :value="currency"
              :label="currency"
            ></el-option>
          </el-select>
          <span class="number">{{ $get(currentQuota, 'withdraw_margin_amount.amount') | currencyFormat }}</span>
        </div>
        <div class="hint">* {{ $t(`withdrawHint.${selectCurrency}`) }}</div>
          <div v-if="$get(currentQuota, 'withdraw_margin_amount.cost') > 0" class="hint hint-cost">
            * {{ $t('withdrawCost') }}
            <span class="cost mark text-uppercase"> {{ selectCurrency }} </span>
            <span class="mark">{{ $get(currentQuota, 'withdraw_margin_amount.cost') | currencyFormat }}</span>
          </div>
        <div class="btn-confirm" @click="handleConfirm">{{ $t('withdrawConfirm') }}</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';
  import { Collection, authStore, settingsStore } from '@/store';
  import withdrawCurrencyFilter from './components/withdraw-currency-filter.vue';

  @Component({ components: { withdrawCurrencyFilter } })
  export default class bills extends Vue {
    showWithDraw = false
    selectCurrency = 'jpy'

    authStore = authStore
    settingsStore = settingsStore

    store = new Collection({
      fetch: params => this.$fly.get('bills', params)
    })

    get currentQuota() {
      const { quotas } = this.$get(this.authStore, 'user');
      return quotas.find(item => item.currency === this.selectCurrency);
    }

    @Vue.autoLoading
    async created() {
      const { currency_eq } = this.$route.query;

      return this.store.fetchData({ currency_eq });
    }

    handleNavRefundApplications() {
      this.$router.push({ name: 'myRefundApplications' });
    }

    async handleConfirm() {
      const { amount } = this.$get(this.currentQuota, 'withdraw_margin_amount');
      if (amount <= 0) {
        this.$message({ type: 'warning', message: this.$t('noWithdrawAmount') });
        return;
      }
      this.showWithDraw = false;
      await this.$autoLoading(async () => {
        await this.$fly.post('mine/withdraw_margin', { currency: this.selectCurrency });
        await authStore.fetchData();
      });
      this.$message({ type: 'success', message: this.$t('withdrawSuccess') });
    }
  }
</script>

<style lang="scss" scoped>
  .title {
    padding: 28px 0 25px;
    font-weight: 500;
    font-size: 18px;
  }

  .list {
    min-height: 240px;

    .bill-item {
      padding: 12px 0;
      border-bottom: 1px solid #ddd;

      .content {
        flex: 1;

        @include media-sm-and-up {
          display: flex;
          align-items: center;
        }
      }

      .name {
        @include media-sm-and-up {
          min-width: 100px;
        }
      }

      .time {
        margin-top: 6px;
        font-size: 11px;
        color: #999;

        @include media-sm-and-up {
          margin: 0 auto;
          font-size: 13px;
        }
      }

      .amount {
        font-weight: 500;

        @include media-sm-and-up {
          min-width: 200px;
          text-align: right;
        }

        &.primary {
          color: $primary;
        }
      }
    }
  }

  .pagination {
    margin: 15px 0;
  }

  .btn {
    margin: 16px auto;
    font-weight: 500;
    font-size: 18px;

    &.primary-btn {
      @include media-sm-and-up {
        margin-right: 10px;
      }
    }

    @include media-sm-and-up {
      min-width: 225px;
      margin-bottom: 40px;
    }
  }

  .message-box {
    text-align: center;

    .refund-btn {
      font-size: 11px;
      text-align: right;
      color: #666;
      cursor: pointer;
    }

    .currency {
      font-weight: 500;
      font-size: 14px;
      color: #000;
    }

    .number {
      margin-left: 5px;
      font-weight: 500;
      font-size: 16px;
      color: $primary;
    }

    .hint {
      margin-top: 10px;
      font-size: 10px;
      line-height: 28px;
      color: $primary;

      &.hint-cost {
        margin-top: 0;

        .cost {
          color: #000;
        }

        .mark {
          font-weight: bold;
        }
      }
    }

    .btn-confirm {
      padding: 8px 70px;
      margin: 20px;
      border-radius: 23px;
      font-weight: 500;
      font-size: 18px;
      color: #fff;
      background: $primary;
      cursor: pointer;
    }

    /deep/.el-select {
      .el-input__inner {
        width: 80px;
        border: none;
        text-transform: uppercase;
      }

      .el-select-dropdown__item {
        text-transform: uppercase;
      }
    }
  }

  .hint-text {
    margin: 20px 0;
    font-size: 12px;
    line-height: 21px;
    white-space: pre-wrap;
    color: #666;

    @include media-sm-and-up {
      margin-bottom: 40px;
    }
  }

  /deep/.el-dialog__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    .el-dialog {
      width: 450px;
      margin: 0 !important;
      border-radius: 5px;

      @include media-xs-only {
        width: 90%;
      }
    }
  }

  .filter-wrap {
    margin-bottom: 25px;
  }

  .list-empty {
    margin: 46px auto 57px;
    text-align: center;
    color: #999;

    > img {
      width: 65px;
      height: 65px;
      margin-bottom: 36px;
    }
  }
</style>
