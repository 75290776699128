<i18n>
{
  "zh_cn": {
    "yourName": "名字",
    "lastName": "姓氏",
    "countryRegion": "国家/地区",
    "address1": "地址行1",
    "address2": "地址行2",
    "province": "州/省/地区",
    "postalCode": "区号",
    "city": "城/镇",
    "phone": "电话号码"
  },
  "zh_hk": {
    "yourName": "名字",
    "lastName": "姓氏",
    "countryRegion": "國家/地區",
    "address1": "地址行1",
    "address2": "地址行2",
    "province": "州/省/地區",
    "postalCode": "區號",
    "city": "城/鎮",
    "phone": "電話號碼"
  },
  "en": {
    "yourName": "First Name",
    "lastName": "Last Name",
    "countryRegion": "country / region",
    "address1": "Address Line 1",
    "address2": "Address Line 2",
    "province": "state / province / region",
    "postalCode": "Area code",
    "city": "city/town",
    "phone": "telephone number"
  },
  "ja": {
    "yourName": "名前",
    "lastName": "姓",
    "countryRegion": "国/地域",
    "address1": "住所1",
    "address2": "住所2",
    "province": "県/区",
    "postalCode": "郵便番号",
    "city": "市",
    "phone": "電話番号"
  }
}
</i18n>

<template>
  <div class="component-change-address">
    <div class="item flex item-center">
      <div class="label"><span v-if="requireName" class="star">*</span>{{ $t('lastName') }}</div>
      <el-input type="text" :placeholder="$t('lastName')" v-model="address.last_name" @input="errorList.last_name = ''" @blur="address.last_name = $event.target.value.trim()" />
    </div>
    <div class="error" v-show="errorList.last_name">* {{ errorList.last_name }}</div>
    <div class="item flex item-center">
      <div class="label"><span v-if="requireName" class="star">*</span>{{ $t('yourName') }}</div>
      <el-input type="text" :placeholder="$t('yourName')" v-model="address.receiver_name" @input="errorList.receiver_name = ''" @blur="address.receiver_name = $event.target.value.trim()" />
    </div>
    <div class="error" v-show="errorList.receiver_name">* {{ errorList.receiver_name }}</div>
    <div class="item flex item-center">
      <div class="label"><span class="star">*</span>{{ $t('countryRegion') }}</div>
      <el-select v-model="address.country_name" :placeholder="$t('countryRegion')" @input="errorList.country_name = ''" filterable >
        <el-option
          v-for="item in regionsList"
          :key="item.code"
          :label="item.name"
          :value="item.name"
        />
      </el-select>
    </div>
    <div class="error" v-show="errorList.country_name">* {{ errorList.country_name }}</div>
    <div class="item flex item-center">
      <div class="label"><span class="star">*</span>{{ $t('province') }}</div>
      <el-input type="text" :placeholder="$t('province')" v-model="address.province_name" @input="errorList.province_name = ''" @blur="address.province_name = $event.target.value.trim()" />
    </div>
    <div class="error" v-show="errorList.province_name">* {{ errorList.province_name }}</div>
    <div class="item flex item-center">
      <div class="label"><span class="star">*</span>{{ $t('city') }}</div>
      <el-input type="text" :placeholder="$t('city')" v-model="address.city_name" @input="errorList.city_name = ''" @blur="address.city_name = $event.target.value.trim()" />
    </div>
    <div class="error" v-show="errorList.city_name">* {{ errorList.city_name }}</div>
    <div class="item flex item-center">
      <div class="label"><span class="star">*</span>{{ $t('address1') }}</div>
      <el-input type="text" :placeholder="$t('address1')" v-model="address.detail_info" @input="errorList.detail_info = ''" @blur="address.detail_info = $event.target.value.trim()" />
    </div>
    <div class="error" v-show="errorList.detail_info">* {{ errorList.detail_info }}</div>
    <div class="item flex item-center">
      <div class="label">{{ $t('address2') }}</div>
      <el-input type="text" :placeholder="$t('address2')" v-model="address.additional_detail_info" @blur="address.additional_detail_info = $event.target.value.trim()" />
    </div>
    <div class="item flex item-center">
      <div class="label"><span class="star">*</span>{{ $t('postalCode') }}</div>
      <el-input type="text" :placeholder="$t('postalCode')" v-model="address.postal_code" @input="errorList.postal_code = ''" @blur="address.postal_code = $event.target.value.trim()" />
    </div>
    <div class="error" v-show="errorList.postal_code">* {{ errorList.postal_code }}</div>
    <div class="item flex item-center">
      <div class="label"><span class="star">*</span>{{ $t('phone') }}</div>
      <el-input type="number" :placeholder="$t('phone')" v-model="address.tel_number" @input="errorList.tel_number = ''" @blur="address.tel_number = $event.target.value.trim()" />
    </div>
    <div class="error" v-show="errorList.tel_number">* {{ $t(errorList.tel_number) }}</div>
  </div>
</template>

<script>
  import { Vue, Prop, Component } from 'vue-property-decorator';
  import countries from '@/components/country-selector/countries';

  @Component
  export default class AddressGroup extends Vue {
    @Prop({ type: Object, default: () => ({}) }) address;
    @Prop({ type: Object, default: () => ({}) }) errorList;
    @Prop({ type: Boolean, default: false }) requireName;

    get regionsList() {
      return countries;
    }
  }
</script>

<style lang="scss" scoped>
  .input-content {
    max-width: 420px;
    margin: 0 auto;

    .el-select {
      width: 100%;
    }

    .photo-tip {
      margin-top: 15px;
      font-size: 12px;
      text-align: center;
      color: #666666;
    }

    .item {
      margin-top: 9px;

      .label {
        min-width: 88px;
        padding-right: 17px;
        text-align: end;
        word-break: break-word;

        @include media-xs-only {
          font-size: 12px;
        }

        &.date-label {
          padding-top: 12px;
        }

        @include media-sm-and-up {
          min-width: 120px;
        }

        .star {
          color: $primary;
        }
      }

      .permanent {
        padding-top: 12px;
      }
    }

    .tips {
      padding: 21px 0 6px;
      font-size: 10px;
      color: $gray;
    }

    .error {
      margin-top: 15px;
      font-size: 11px;
      line-height: 15px;
      text-align: end;
      color: $primary;
    }
  }

</style>
