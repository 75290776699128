<template>
  <div to="app-bottom">
    <div
      :class="{ 'component-fixed-bottom': true }"
      :style="{ backgroundColor }" ref="content">
        <slot />
    </div>
    <portal to="app-bottom">
      <div :style="{ height: height + 'px' }"></div>
    </portal>
  </div>
</template>


<script>
  import { Vue, Component, Prop } from 'vue-property-decorator';
  @Component
  export default class FixedBottom extends Vue {
    @Prop({ type: String, default: '#fff' }) backgroundColor;

    height = 0;

    mounted() {
      this.calcContentHeight();
    }

    async updated() {
      await this.$nextTick();
      this.calcContentHeight();
    }

    calcContentHeight() {
      const content = this.$refs.content;
      this.height = content.offsetHeight;
    }
  }
</script>

<style lang="scss" scoped>
  .component-fixed-bottom {
    position: fixed;
    z-index: var(--fixed-z-index);
    bottom: 60px;
    left: 0;
    width: 100%;

    @include paddingBottomSafeArea;
  }
</style>
