<template>
  <div class="component-banner-wrapper prod-skeleton-animate">
    <div class="banner prod-skeleton-block"/>
  </div>
</template>

<script>
  export default {};
</script>

<style lang="scss" scoped>
  .component-banner-wrapper {
    max-width: 1200PX;
    padding: 0 20px;
    margin: 1px auto 0;

    @include media-xs-only {
      max-width: 100%;
      padding: 0;
      margin: 0;
    }

    .banner {
      padding-bottom: 50%;
    }
  }
</style>
