<template>
  <div class="component-follow-share">
    <div v-if="fixed" class="fixed">
      <img :src="detail.is_follow ? collectedIcon : collectIcon" class="icon" @click="handleFollow">
      <app-social-share>
        <img src="@/assets/icon-share.png" class="icon">
      </app-social-share>
    </div>

    <div v-else class="flex row">
      <div class="icon-box" @click="handleFollow">
        <img :src="detail.is_follow ? collectedIconRed : collectIconBlack" class="icon">
      </div>
      <app-social-share>
        <div class="icon-box">
          <img src="@/assets/icon-share-black.png" class="icon">
        </div>
      </app-social-share>
    </div>
  </div>
</template>

<script>
  import { Vue, Component, Prop } from 'vue-property-decorator';
  import { followProductIdsStore } from '@/store';
  import collectIcon from '@/assets/icon-collect.png';
  import collectIconBlack from '@/assets/icon-collect-black.png';
  import collectedIcon from '@/assets/icon-collected.png';
  import collectedIconRed from '@/assets/icon-collected-red.png';

  @Component
  export default class FollowShare extends Vue {
    @Prop({ type: Object, default: () => ({}) }) detail
    @Prop({ type: String, default: 'Product' }) targetType
    @Prop({ type: Boolean, default: true }) fixed

    collectIcon = collectIcon;
    collectIconBlack = collectIconBlack;
    collectedIcon = collectedIcon;
    collectedIconRed = collectedIconRed;

    async handleFollow() {
      const { id, is_follow } = this.detail;
      if (!id) {
        return;
      }
      const method = is_follow ? 'delete' : 'post';
      await this.$autoLoading(this.auctionsAction(method, 'follow'));
      this.detail.is_follow = !is_follow;
      // 更新已关注商品的ids
      if (this.detail.is_follow) {
        followProductIdsStore.data.push(this.detail.id);
      } else {
        const index = followProductIdsStore.data.indexOf(this.detail.id);
        index > -1 && followProductIdsStore.data.splice(index, 1);
      }
      this.$message(
        {
          type: 'success',
          message: is_follow ? this.$t('unfollowSuccessfully') : this.$t('followSucceeded')
        }
      );
    }

    auctionsAction(methods, action_type) {
      const { id } = this.$route.params;
      return this.$fly[methods]('action_stores', {
        action_type: action_type,
        target_type: this.targetType,
        target_id: id
      });
    }
  }
</script>

<style lang="scss" scoped>
  .icon {
    cursor: pointer;
  }

  .component-follow-share {
    .fixed {
      position: fixed;
      z-index: var(--fixed-z-index);
      bottom: 205px;
      right: 23px;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 35px;
      padding: 10px 0;
      border-radius: 18px;
      background: rgba(#000, 0.6);

      .icon {
        width: 20px;
        height: 20px;
        margin: 8px 0;
      }
    }
  }

  .icon-box {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 32px;
    height: 32px;
    border: 1px solid rgba(235, 237, 236, 1);
    border-radius: 50%;
    background: #ffffff;

    &:not(:last-child) {
      margin-right: 5px;
    }

    .icon {
      width: 16px;
      height: 16px;
    }
  }
</style>
