<template>
  <div class="component-product-item prod-skeleton-animate" :class="[{ horizontal }]">
    <div class="inner">
      <div class="cover-wrapper">
        <auto-height class="prod-skeleton-block" :height="autoHeight" :oss-img="{ resize: { w: 1000 } }"/>
      </div>
      <div class="product-info flex column content-between">
        <div>
          <div class="name prod-skeleton-block">商品B</div>
          <div class="tag prod-skeleton-block">日本艺术</div>
        </div>
        <div class="footer prod-skeleton-block">1</div>
      </div>
    </div>
  </div>
</template>

<script>
  import { Vue, Component, Prop } from 'vue-property-decorator';

  @Component
  export default class ProductItem extends Vue {
    @Prop({ type: String, default: '100%' }) autoHeight;
    @Prop({ type: Boolean, default: false }) horizontal;
  }
</script>

<style lang="scss" scoped>
  .component-product-item {
    position: relative;
    height: 0;
    padding-bottom: 190%;
    border-radius: 2px;
    overflow: hidden;
    background: #fff;
    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.09);
    cursor: pointer;
    user-select: none;

    @include media-xs-only {
      padding-bottom: 200%;
    }

    /deep/ .auto-height-content {
      background-color: #f2f3f5;
    }

    .inner {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;

      .product-info {
        flex: 1;
        padding: 10px 16px;

        .name {
          font-weight: 500;
        }

        .tag {
          display: inline-block;
          padding: 1px 4px;
          border-radius: 2px;
          font-size: 9px;
        }

        .footer {
          margin-top: 2px;
        }
      }
    }

    &.horizontal {
      flex-direction: row;
      padding-bottom: 39.9%;

      .inner {
        flex-direction: row;

        .cover-wrapper {
          width: 40%;
        }

        .product-info {
          padding: 14px 27px 13px 18px;
        }
      }
    }
  }
</style>
