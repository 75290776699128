<i18n src="../locales.json"></i18n>

<template>
  <div class="pc-product-info">
    <div>
      <div class="tag">{{ $get(store, 'data.product_category.title') }}</div>
      <div class="name">{{ $get(store, 'data.title') }}</div>
    </div>
    <div class="divider"></div>
    <div>
      <div class="action-box">
        <router-link
          class="view-all"
          :to="{ path: `/products/${$route.params.id}/product_auction` }"
          v-if="!store.onlyBuyNowProduct && !showStartCountDown"
        >
          {{ $t('viewAllPrice') }}
        </router-link>
        <follow-share :detail="$get(store, 'data')" :fixed="false" targetType="Product" />
        <div
          class="primary-btn"
          @click="handleParticipate"
          v-if="!store.isFinished && !showStartCountDown"
        >
          {{ $t(store.onlyBuyNowProduct ? 'directPurchase' : 'participateBidding') }}
        </div>
        <div class="primary-btn disabled" v-else-if="store.isFinished">{{ $t('ended') }}</div>
        <div class="primary-btn disabled" v-else-if="showStartCountDown">{{ $t('notStart') }}</div>
      </div>
      <!-- 已流拍 -->
      <div class="aborted flex item-center" v-if="aborted">
        <div>{{ $t('aborted') }}</div>
        <div class="aborted-bottom-price">
          <bottom-price :product="store.data" class="bottom-price" />
        </div>
      </div>
      <div class="price-wrapper" v-else>
        <!-- 保证金tip -->
        <margin-amount-tip :store="store"/>
        <!-- 成交价格 -->
        <div v-if="$get(store, 'data.deal_price')" class="current-price">
          <span class="label">{{ $t('dealPrice') }}:</span>
          <span class="currency text-uppercase">{{ $get(store, 'data.currency') }}</span>
          <span class="amount">{{ $get(store, 'data.deal_price') | currencyFormat }}</span>
          <span v-if="!$options.filters.isLocaleCurrency($get(store, 'data.currency'))">
            ( ≈ {{ $t('currency') }} <span class="primary">{{ $get(store, 'data.deal_price') | conversionFormat($get(store, 'data.currency')) | currencyFormat }}</span> )
          </span>
          <div class="price-wrap-bottom-price">
            <bottom-price :product="store.data" class="bottom-price" />
          </div>
        </div>
        <!-- 一口价商品价格 -->
        <div v-else-if="store.onlyBuyNowProduct" class="current-price flex column content-center">
          <div class="aborted flex item-center content-center" v-if="aborted">
            {{ $t('aborted') }}
          </div>
          <div v-else>
            <span class="label">{{ $t('buyNowPrice') }}:</span>
            <span class="currency text-uppercase">{{ $get(store, 'data.currency') }}</span>
            <span class="amount">{{ $get(store, 'data.confirmed_price') | currencyFormat }}</span>
            <span v-if="!$options.filters.isLocaleCurrency($get(store, 'data.currency'))">
              ( ≈ {{ $t('currency') }} <span class="primary">{{ $get(store, 'data.confirmed_price') | conversionFormat($get(store, 'data.currency')) | currencyFormat }}</span> )
            </span>
          </div>
          <div class="price-wrap-bottom-price">
            <bottom-price :product="store.data" class="bottom-price" />
          </div>
        </div>
        <!-- 竞价商品价格 -->
        <template v-else>
          <div :class="['current-price', { auction: !store.auctionAndBuyNowProduct }]">
            <div class="flex item-center">
              <span class="label">{{ $t('currentPrice') }}:</span>
              <span class="currency text-uppercase">{{ $get(store, 'data.currency') }}</span>
              <span class="amount">{{ $get(store, 'currentPrice') | currencyFormat }}</span>
              <span v-if="!$options.filters.isLocaleCurrency($get(store, 'data.currency'))">
                (  ≈ {{ $t('currency') }}<span class="primary">{{ $get(store, 'currentPrice') | conversionFormat($get(store, 'data.currency')) | currencyFormat }} </span>)
              </span>
            </div>
          </div>
          <bottom-price :product="store.data" class="bottom-price" />
          <div v-if="store.auctionAndBuyNowProduct" class="current-price">
            <span class="label">{{ $t('buyNowPrice') }}:</span>
            <span class="currency text-uppercase">{{ $get(store, 'data.currency') }}</span>
            <span class="amount">{{ $get(store, 'data.confirmed_price') | currencyFormat }}</span>
            <span v-if="!$options.filters.isLocaleCurrency($get(store, 'data.currency'))">
              ( ≈ {{ $t('currency') }} <span class="primary">{{ $get(store, 'data.confirmed_price') | conversionFormat($get(store, 'data.currency')) | currencyFormat }}</span> )
            </span>
          </div>
          <div class="current-price" v-if="$get(store, 'data.current_price')">
            <span class="label">{{ $t('nextMiniPrice') }}:</span>
            <span class="currency text-uppercase">{{ $get(store, 'data.currency') }}</span>
            <span class="amount" v-if="store.auctionAndBuyNowProduct">{{ (store.nextLowestPrice > store.isBuyNowProductPrice ? store.isBuyNowProductPrice : store.nextLowestPrice) | currencyFormat }}</span>
            <span class="amount" v-if="store.onlyAuctionProduct">{{ store.nextLowestPrice | currencyFormat }}</span>
            <span v-if="!$options.filters.isLocaleCurrency($get(store, 'data.currency'))">
              ( ≈ {{ $t('currency') }}
                <span class="primary" v-if="store.auctionAndBuyNowProduct">
                  {{ (store.nextLowestPrice > store.isBuyNowProductPrice ? store.isBuyNowProductPrice : store.nextLowestPrice) | conversionFormat($get(store, 'data.currency')) | currencyFormat }}
                </span>
                <span class="primary" v-if="store.onlyAuctionProduct">
                  {{ store.nextLowestPrice | conversionFormat($get(store, 'data.currency')) | currencyFormat }}
                </span>
              )
            </span>
          </div>
        </template>
        <div class="hint" v-if="!store.isFinished">{{ $t('productBidHint') }}</div>
        <div class="hint" v-if="store.showTransportAreaTips">{{ $t('transportAreaTips') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
  import { Vue, Component, Prop } from 'vue-property-decorator';
  import { authStore } from '@/store';
  import BottomPrice from './bottom-price.vue';
  import MarginAmountTip from './margin-amount-tip.vue';

  @Component({
    components: {
      'bottom-price': BottomPrice,
      'margin-amount-tip': MarginAmountTip
    }
  })
  export default class PcProductInfo extends Vue {
    @Prop({ type: Object, default: () => {} }) store;

    authStore = authStore;

    get showStartCountDown() {
      return this.$get(this.store, 'data.started_bidding_time') && this.store.isNotStart;
    }

    get aborted() {
      return this.$get(this.store, 'data.bid_status') === 'aborted';
    }

    // 如果没有上传任何证件，没有填写证件有效期，提示用户填写后再进行下一步
    async handleParticipate() {
      if (!authStore.isLogin) {
        await this.$confirm(this.$t('logFirst'));
        return this.$router.push({ name: 'login', query: { redirect: encodeURIComponent(this.$route.fullPath) } });
      }
      if (this.$authStore.isLoadCredential) {
        if (this.$authStore.isLoadEffectiveDate) {
          await this.$confirm(this.$t('uploadValidityPeriod'));
          return this.$router.push({ name: 'myAddress' });
        }
        return this.$router.push({ name: 'productAuction', params: { id: this.$route.params.id }, query: { isPreview: this.isPreview } });
      } else {
        await this.$confirm(this.$t('uploadTip'));
        return this.$router.push({ name: 'myAddress' });
      }
    }
  }
</script>

<style lang="scss" scoped>
  .pc-product-info {
    display: flex;
    flex-direction: column;
    padding: 15px 20px 5px;
    background-color: #eeeeee;

    .divider {
      margin: 15px 0 10px;
      border-top: 1px solid #cccccc;
    }

    .tag {
      float: left;
      padding: 1px 4px;
      margin-right: 5px;
      border: 1px solid $primary;
      border-radius: 2px;
      font-size: 10px;
      color: $primary;
    }

    .name {
      font-weight: 600;
      font-size: 16px;
      word-break: break-all;
    }

    .aborted {
      padding: 10px 0;
      font-weight: 500;
    }

    .price-wrapper {
      .label {
        margin-right: 10px;
      }

      .amount,
      .primary {
        margin-left: 5px;
      }

      .current-price {
        font-weight: 500;

        &:not(:last-child) {
          margin-bottom: 15px;
        }

        .amount {
          margin-right: 5px;
          font-size: 20px;
          color: $primary;
        }

        .primary {
          color: $primary;
        }
      }

      .hint {
        display: flex;
        justify-content: center;
        font-size: 11px;
        line-height: 18px;
        color: $primary;
      }
    }

    .action-box {
      float: right;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 5px;

      .view-all {
        margin-right: 10px;
        border-bottom: 1px solid #000000;
        font-size: 12px;
        color: #000000;
      }

      .primary-btn {
        height: 32px;
        padding: 0 25px;
        margin: 0 0 0 10px;
        font-size: 14px;
      }
    }

    .bottom-price {
      margin-bottom: 15px;
    }

    .aborted-bottom-price {
      .bottom-price {
        margin: 0 0 0 15px;
      }
    }

    .price-wrap-bottom-price {
      .bottom-price {
        margin: 15px 0 0;
      }
    }
  }
</style>
