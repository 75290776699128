import { SimpleStore } from './simple-store';
import { fly } from '@/utils';
import dayjs from 'dayjs';
const { Storage } = require('@/utils/storage');

const ACCESS_TOKEN_KEY = 'USER_ACCESS_TOKEN_DATA';
class AuthStore extends SimpleStore {
  constructor(...agrs) {
    super(...agrs);
    this.storage = new Storage(ACCESS_TOKEN_KEY);
  }
  user = {}

  set access_token(v) {
    this.$access_token = v;
    this.storage.set(ACCESS_TOKEN_KEY, v);
  }

  get access_token() {
    this.$access_token = this.storage.get(ACCESS_TOKEN_KEY);
    return this.$access_token;
  }

  get isLogin() {
    return !!this.access_token;
  }

  // 是否上传任何证件照
  get isLoadCredential() {
    const { id_card_no, passport_no, driving_license_no } = this.user;
    return id_card_no || passport_no || driving_license_no;
  }

  // 是否填写证件照有效期
  get isLoadEffectiveDate() {
    const { credential_expired_on } = this.user;
    return credential_expired_on === null;
  }

  get fullName() {
    const { name, last_name, phone } = this.user;

    return (last_name + name) || phone;
  }

  execFetchData() {
    // 防止并发时同时请求多次
    if (this.fetchMinePromise) {
      return this.fetchMinePromise;
    }
    return this.fetchMinePromise = this.fetching(fly.get('mine')).finally(() => this.fetchMinePromise = null);
  }

  async fetchData() {
    const { data } = await this.execFetchData();
    this.user = data;
    this.setCrispNickName();
  }

  async checkFetchData() {
    if (this.access_token) {
      const { data } = await this.execFetchData();
      return this.user = data;
    }
    return false;
  }

  // 登录
  async login(params = {}) {
    const { data: { token, user, expired_time_stamp } } = await fly.post('sessions', params);
    this.user = user;
    this.switchDuration(expired_time_stamp);
    return this.access_token = token;
  }

  // 计算token的有效期时长，单位为毫秒；应提前半天到期
  switchDuration(time) {
    const duration = (time - dayjs().add(12, 'hour').unix()) * 1000;
    this.storage.setDuration(duration);
  }

  // 重置密码
  async resetPassword(params = {}) {
    const { data: { token } } = await fly.post('passwords', params);
    return this.access_token = token;
  }

  // 注册
  async register(params = {}) {
    const { data: { token, user } } = await fly.post('registrations', params);

    this.user = user;
    return this.access_token = token;
  }

  async updateMine(params = {}) {
    const { data } = await fly.put('mine', params);

    return this.user = data;
  }

  // 设置 crisp chatbox 用户昵称
  setCrispNickName() {
    if (!window.$crisp) {
      return Promise.reject({ message: 'crisp sdk 环境未准备好' });
    }

    // sdk 方法 doc: https://docs.crisp.chat/guides/chatbox-sdks/web-sdk/dollar-crisp/
    window.$crisp.push(['set', 'user:nickname', [this.fullName]]);
  }

  logout() {
    this.isFulfilled = false; // 重置状态
    this.access_token = '';
    this.user = {};
  }
}

export const authStore = new AuthStore();
