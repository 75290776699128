<i18n src="./locales.json"></i18n>

<template>
  <div class="flex">
    <common-sidebar class="hidden-xs-only" />
    <div class="container flex item-center">
      <div class="flex-1">
        <v-title :value="$t('myAddress')"/>
        <div class="title">{{ $t('myAddress') }}</div>
        <!-- 地址管理 -->
        <div class="input-item">
          <div class="input-content">
            <div class="flex content-between">
              <div class="item-title">{{ $t('address') }}</div>
              <router-link :to="{ name: 'myAddressList'}" class="show-more">{{ $t('more') }} &gt;</router-link>
            </div>
            <address-group :address="form.addresses_attributes[0]" :errorList="errorList"/>
          </div>
        </div>
        <!-- 上传证件 -->
        <div class="input-item">
          <div class="input-content">
            <div class="item-title flex item-center">
              <div class="upload" style="white-space: nowrap;">{{ $t('upload') }}</div>
            </div>
            <div class="photo-tip">{{ $t('identificationTip') }}</div>
            <div class="item flex item-center">
              <div class="label">{{ $t('selectCredentialType') }}</div>
              <el-select v-model="form.credential_type" :disabled="isCredentialApprove" @change="handleChangeType">
                <el-option
                  v-for="item in documents"
                  :key="item.credential_type"
                  :label="item.label"
                  :value="item.credential_type"
                />
              </el-select>
            </div>
            <div class="item flex item-center">
              <div class="label"><span class="star">*</span>{{ $t('credentialName') }}</div>
              <el-input
                v-model="form.credential_name"
                type="text"
                :placeholder="$t('credentialName')"
              />
            </div>
            <div class="error" v-show="uploadError.credential_name">* {{ uploadError.credential_name }}</div>
            <!-- 上传身份证 -->
            <div v-show="form.credential_type === 'id_card'">
              <div class="item flex item-center">
                <div class="label"><span class="star">*</span>{{ $t('idNumber') }}</div>
                <el-input
                  v-model="form.id_card_no"
                  type="text"
                  :disabled="isCredentialApprove"
                  :placeholder="$t('idNumber')"
                  @input="uploadError.id_card_no = ''"
                  @blur="form.id_card_no = $event.target.value.trim()"
                />
              </div>
              <div class="error" v-show="uploadError.id_card_no">* {{ $t('id_card_no') }}</div>
              <div class="item flex item-start">
                <div class="label"><span class="star">*</span>{{ $t('idImage') }}</div>
                <div class="id-card flex flex-1 column content-between">
                  <img-upload :disabled="isCredentialApprove" class="positive" :defaultText="$t('front')" @changeImage="handleSaveFrontImg" :imgUrl="allImg.idCardFrontUrl" @deleteImage="handleDeleteImage('front')"/>
                  <img-upload :disabled="isCredentialApprove" :defaultText="$t('back')" @changeImage="handleSaveBackImg" :imgUrl="allImg.idCardBackUrl" @deleteImage="handleDeleteImage('back')"/>
                </div>
              </div>
            </div>
            <!-- 上传护照 -->
            <div v-show="form.credential_type === 'passport'">
              <div class="item flex item-center">
                <div class="label"><span class="star">*</span>{{ $t('passportNo') }}</div>
                <el-input
                  v-model="form.passport_no"
                  :disabled="isCredentialApprove"
                  type="text"
                  :placeholder="$t('passportNo')"
                  @input="uploadError.passport_no = ''"
                  @blur="form.passport_no = $event.target.value.trim()"
                />
              </div>
              <div class="error" v-show="uploadError.passport_no">* {{ $t('passport_no') }}</div>
              <div class="item flex item-start">
                <div class="label"><span class="star">*</span>{{ $t('passport') }}</div>
                <div class="id-card flex flex-1 column content-between">
                  <img-upload :disabled="isCredentialApprove" :defaultText="$t('passportImage')" @changeImage="handleSavePassportImg" :imgUrl="allImg.passportUrl" @deleteImage="handleDeleteImage('passport')"/>
                </div>
              </div>
            </div>
            <!-- 上传驾驶证照 -->
            <div v-show="form.credential_type === 'driving_license'" >
              <div class="item flex item-center">
                <div class="label"><span class="star">*</span>{{ $t('drivingNo') }}</div>
                <el-input
                  v-model="form.driving_license_no"
                  :disabled="isCredentialApprove"
                  type="text"
                  :placeholder="$t('drivingNo')"
                  @input="uploadError.driving_license_no = ''"
                  @blur="form.driving_license_no = $event.target.value.trim()"
                />
              </div>
              <div class="error" v-show="uploadError.driving_license_no">* {{ $t('driving_license_no') }}</div>
              <div class="item flex item-start">
                <div class="label"><span class="star">*</span>{{ $t('driving') }}</div>
                <div class="id-card flex flex-1 column content-between">
                  <img-upload :disabled="isCredentialApprove" :defaultText="$t('drivingImage')" @changeImage="handleSaveDrivingImg" :imgUrl="allImg.drivingUrl" @deleteImage="handleDeleteImage('driving')"/>
                </div>
              </div>
            </div>
            <div class="photo-tip">{{ $t('support') }}</div>
            <div class="item flex">
              <div class="label" :class="{'date-label': $i18n.locale !== 'en'}"><span class="star">*</span>{{ $t('effectiveDate') }}</div>
              <div>
                <el-date-picker
                  v-model="effectiveDate"
                  :disabled="isCredentialApprove"
                  :placeholder="permanent ? '' : $t('placeholderDate')"
                  value-format="yyyy-MM-dd"
                  @change="permanent=false"
                ></el-date-picker>
                <el-checkbox v-model="permanent" :disabled="isCredentialApprove" @change="handlePermanent" class="permanent">{{ $t('permanent') }}</el-checkbox>
              </div>
            </div>
            <div class="error" v-show="uploadError.credential_expired_on">* {{ uploadError.credential_expired_on }}</div>
          </div>
        </div>

        <div class="statement-box flex content-center item-center">
          <el-checkbox v-model="agreeStatement" />
          <div class="statement-label" @click="agreeStatement = !agreeStatement">
            {{ $t('readAndAgree') }}
            <router-link :to="{ name: 'pages', params: { slug: 'personal_information_collection_statement' }}">
              <span class="statement">《{{ $t('personalDataCollectionstatement') }}》</span>
            </router-link>
          </div>
        </div>
        <div class="submit-btn primary-btn" @click="handleUpdateMine">{{ $t('save') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';
  import countries from '@/components/country-selector/countries';
  import ImgUpload from './components/img-upload';
  import _ from 'lodash';
  import AddressGroup from '@/components/address-group.vue';

  @Component({
    components: {
      'img-upload': ImgUpload,
      AddressGroup
    }
  })
  export default class Address extends Vue {
    form = {
      last_name: '',
      name: '',
      credential_type: '',
      credential_name: '',
      id_card_no: '',
      id_card_images: [],
      passport_no: '',
      passport_image: null,
      driving_license_no: '',
      driving_license_image: null,
      credential_expired_on: null,
      addresses_attributes: [{
        id: '',
        last_name: '',
        receiver_name: '',
        country_name: '',
        detail_info: '',
        additional_detail_info: '',
        province_name: '',
        postal_code: '',
        city_name: '',
        tel_number: ''
      }]
    }

    allImg = {
      idCardFrontUrl: null,
      idCardBackUrl: null,
      passportUrl: null,
      drivingUrl: null,
    }

    confirmEmail = ''
    effectiveDate = null
    permanent = null
    agreeStatement = false

    errorList = {
      country_name: '',
      detail_info: '',
      province_name: '',
      postal_code: '',
      city_name: '',
      tel_number: ''
    }

    uploadError = {
      id_card_no: '',
      passport_no: '',
      driving_license_no: ''
    }

    documents = [
      { credential_type: 'id_card', label: this.$t('idImage') },
      { credential_type: 'passport', label: this.$t('passport') },
      { credential_type: 'driving_license', label: this.$t('driving') }
    ]

    get regionsList() {
      return countries;
    }

    get isCredentialApprove() {
      return this.$get(this.$authStore, 'user.credential_status') === 'approved';
    }

    @Vue.autoLoading
    async created() {
      await this.$authStore.fetchData();
      // 数据初始化
      const { last_name, name, phone, addresses, credential_type, credential_name, credential_expired_on, id_card_images, passport_image, driving_license_image } = this.$authStore.user;
      const extra = { last_name, receiver_name: name, tel_number: phone };

      this.form.credential_type = credential_type || 'id_card';
      id_card_images && (this.form.id_card_images = id_card_images.map(item => item.signed_id));
      this.form.passport_image = this.$get(passport_image, 'signed_id');
      this.form.driving_license_image = this.$get(driving_license_image, 'signed_id');
      this.form.credential_name = credential_name;
      Object.assign(this.form, _.pick(this.$authStore.user, 'last_name', 'name', 'id_card_no', 'passport_no', 'driving_license_no'));

      this.allImg.idCardFrontUrl = this.$get(id_card_images[0], 'url');
      this.allImg.idCardBackUrl = this.$get(id_card_images[1], 'url');
      this.allImg.passportUrl = this.$get(passport_image, 'url');
      this.allImg.drivingUrl = this.$get(driving_license_image, 'url');

      // credential_expired_on 值为'0'： 长期有效
      if (credential_expired_on === '0') {
        this.permanent = true;
      } else {
        this.effectiveDate = credential_expired_on;
      }

      if (addresses.length) {
        Object.keys(this.form.addresses_attributes[0]).forEach(key => {
          if (addresses[0][key]) {
            this.form.addresses_attributes[0][key] = addresses[0][key];
          }
        });
      }
      Object.keys(extra).forEach(key => {
        const value = extra[key];
        if (!this.form.addresses_attributes[0][key] && value) {
          if (key === 'country_name' && this.regionsList[value].name) {
            this.form.addresses_attributes[0][key] = this.regionsList[value].name;
          } else {
            this.form.addresses_attributes[0][key] = value;
          }
        }
      });
    }

    handlePermanent(value) {
      this.effectiveDate = value ? '' : null;
    }

    // 选择其他证件类型时，除了已提交过的，其他证件有效期应该置空
    handleChangeType(value) {
      const { credential_type, credential_expired_on } = this.$authStore.user;
      if (credential_expired_on && credential_type === value) {
        if (credential_expired_on === '0') {
          this.permanent = true;
        } else {
          this.effectiveDate = credential_expired_on;
        }
      } else {
        this.effectiveDate = null;
        this.permanent = false;
      }
    }

    handleSaveFrontImg(id) {
      if (!this.form.id_card_images[1]) {
        this.form.id_card_images[1] = null;
      }
      this.form.id_card_images[0] = id;
    }

    handleSaveBackImg(id) {
      if (!this.form.id_card_images[0]) {
        this.form.id_card_images[0] = null;
      }
      this.form.id_card_images[1] = id;
    }

    handleSavePassportImg(id) {
      this.form.passport_image = id;
    }

    handleSaveDrivingImg(id) {
      this.form.driving_license_image = id;
    }

    handleDeleteImage(type) {
      switch (type) {
        case 'front':
          this.form.id_card_images[0] = null;
          break;
        case 'back':
          this.form.id_card_images[1] = null;
          break;
        case 'passport':
          this.form.passport_image = null;
          break;
        case 'driving':
          this.form.driving_license_image = null;
          break;
      }
    }

    async handleUpdateMine() {
      let error = false;
      let errorMessage = '';
      Object.keys(this.errorList).forEach((errorName) => {
        if (!this.form.addresses_attributes[0][errorName]) {
          this.errorList[errorName] = this.$t(errorName);
          errorMessage += this.$t(errorName) + '\n';
          error = true;
        }
      });

      if (!this.form[this.form.credential_type + '_no']) {
        this.uploadError[this.form.credential_type + '_no'] = this.form.credential_type;
        errorMessage += this.$t(this.form.credential_type + '_no') + '\n';
        error = true;
      }

      if (!this.form.credential_name) {
        this.uploadError.credential_name = this.$t('credentialNameTip');
        errorMessage += this.$t('credentialNameTip') + '\n';
        error = true;
      }

      const type = this.form.credential_type;
      switch (type) {
        case 'id_card':
          if (this.form.id_card_images.includes(null) || !this.form.id_card_images.length) {
            errorMessage += this.$t('uploadImgTip') + '\n';
            error = true;
          }
          if (this.effectiveDate === null && !this.permanent) {
            errorMessage += this.$t('placeholderDate') + '\n';
            error = true;
          }
          break;
        case 'passport':
          if (!this.form.passport_image) {
            errorMessage += this.$t('uploadImgTip') + '\n';
            error = true;
          }
          if (this.effectiveDate === null && !this.permanent) {
            errorMessage += this.$t('placeholderDate') + '\n';
            error = true;
          }
          break;
        case 'driving_license':
          if (!this.form.driving_license_image) {
            errorMessage += this.$t('uploadImgTip') + '\n';
            error = true;
          }
          if (this.effectiveDate === null && !this.permanent) {
            errorMessage += this.$t('placeholderDate') + '\n';
            error = true;
          }
          break;
      }

      if (!this.agreeStatement) {
        errorMessage += this.$t('agreeStatementTips') + '\n';
        error = true;
      }

      if (errorMessage) {
        this.$message({ type: 'error', message: errorMessage });
      }
      if (error) {
        return;
      }

      // 身份证照片没有上传的时候，应该置空才不会报错
      if (this.form.credential_type !== 'id_card' && this.form.id_card_images.includes(null)) {
        this.form.id_card_images = [];
      }

      this.form.credential_expired_on = this.permanent ? 0 : this.effectiveDate;
      await this.$autoLoading(this.$authStore.updateMine(this.form));
      await this.$message({ type: 'success', message: this.$t('settingSuccess') });
      this.$authStore.fetchData();
      this.$router.go(-1);
    }
  }
</script>

<style lang="scss" scoped>
  .container {
    padding: 0 15px 50px;
    font-size: 12px;
    color: $gray;

    @include media-sm-and-up {
      min-height: 100%;
      margin: 0 auto;
      font-size: 13px;
    }

    .title {
      padding-bottom: 24px;
      margin: 34px 0 0;
      border-bottom: 1px solid $border-light;
      font-weight: bold;
      font-size: 18px;
      color: #000;
    }

    .input-item {
      padding: 18px 0;
      border-bottom: 1px solid $border-light;

      .input-content {
        max-width: 420px;
        margin: 0 auto;

        .item-title {
          margin-bottom: 18px;
          font-weight: bold;
          font-size: 14px;
          color: #000;
        }

        .show-more {
          color: $gray;
          cursor: pointer;
        }

        .el-select {
          width: 100%;
        }

        .photo-tip {
          margin-top: 15px;
          font-size: 12px;
          text-align: center;
          color: #666666;
        }

        .item {
          margin-top: 9px;

          .label {
            min-width: 107px;
            padding-right: 17px;
            text-align: end;

            &.date-label {
              padding-top: 12px;
            }

            @include media-sm-and-up {
              min-width: 120px;
            }

            .star {
              color: $primary;
            }
          }

          .permanent {
            padding-top: 12px;
          }
        }

        .tips {
          padding: 21px 0 6px;
          font-size: 10px;
          color: $gray;
        }

        .error {
          margin-top: 15px;
          font-size: 11px;
          line-height: 15px;
          text-align: end;
          color: $primary;
        }
      }
    }

    .upload {
      min-width: 120px;
      white-space: nowrap;

      @include media-xs-only {
        min-width: 106px;
      }
    }

    .id-card {
      @include media-sm-and-up {
        flex-direction: row;
      }

      .positive {
        @include media-xs-only {
          margin-bottom: 10px;
        }
      }
    }

    .checked-rules {
      padding-top: 20px;

      .rules {
        text-decoration: underline;
        color: $primary;
        cursor: pointer;
      }
    }

    .el-date-editor.el-input, .el-date-editor.el-input__inner {
      width: 100%;
    }

    .el-checkbox__input.is-checked + .el-checkbox__label {
      font-size: 12px;
    }

    .statement-box {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-top: 20px;

      .el-checkbox {
        margin-right: 10px;
      }

      .statement-label {
        cursor: pointer;
      }

      .statement {
        color: #bb4353;
      }
    }

    .submit-btn {
      margin: 10px auto;
      font-size: 18px;

      @include media-sm-and-up {
        margin-top: 46px;
      }
    }
  }
</style>
