<i18n src="./locales.json"></i18n>

<template>
  <div>
    <v-title :value="$get(store, 'auction.title')"/>
    <skeleton :onFetch="onFetch" v-model="loading"/>
    <auction-item-toggle-control
      class="hidden-xs-only"
      :show-next="activeSlide + 1 < store.auctionItems.length"
      :show-pre="activeSlide > 0"
      @pre="handleSlidePre"
      @next="handleSlideNext" v-show="!loading"
    >
      <template slot="pre">{{ $t('prev') }}</template>
      <template slot="next">{{ $t('next') }}</template>
      <span slot="title" v-if="store.auction_item_id && (activeSlide !== store.auctionItemIndex)" @click="handleSlideRestore">{{ $t('toggleTitle') }}</span>
    </auction-item-toggle-control>
    <div class="page-auction-live" v-if="store.auction && store.auction.id" v-show="!loading">
      <div class="container">
        <div class="box-content">
          <div class="screen-left">
            <auto-height height="56%" v-if="store.isFinished">
              <div class="box-finished">
                <img src="@/assets/icon-live.png" />
                <p>{{ $t('finished_tip') }}</p>
              </div>
            </auto-height>
            <template v-else>
              <div
                :class="[{ full: liveAndRateFullScreen }, $isMobile() ? 'mobile' : 'pc']"
                :style="{'--full-screen-height': fullScreenHeight}"
              >
                <div class="live-info-wrap">
                  <div class="live-rate-wrap flex">
                    <div class="rate-box">
                      <div class="current-price-box">
                        <div>{{ $t('currentPrice') }}</div>
                        <div class="price">
                          <span class="currency">{{ $get(auctionItem, 'currency') }}</span>
                          <span>{{ currentPrice | currencyFormat }}</span>
                        </div>
                      </div>
                      <div class="translate-price-box">
                        <div v-for="(currency, index) in ['jpy', 'cny', 'usd', 'hkd', 'twd'].filter(item => item !== $get(auctionItem, 'currency'))" :key="index" class="price">
                          <span class="currency">{{ currency }}</span>
                          <span>{{ currentPrice | conversionFormat($get(auctionItem, 'currency'), currency) | currencyFormat }}</span>
                        </div>
                      </div>
                      <div v-if="$get(auctionItem, 'images.0.url') && showFullScreenAuctionImage" class="auction-item-box" :class="{ 'hidden-xs-only': !liveAndRateFullScreen }">
                        <img :src="$get(auctionItem, 'images.0.url')" alt="">
                      </div>
                    </div>
                    <!-- 如果没有当前拍品的时候不要显示顶部相关的提示 -->
                    <div class="box-live">
                      <div class="indicator-watch">
                        <div class="flex item-center">
                          <div class="indicator" v-if="store.auctionItemIndex > -1">
                            <span>{{ $t('current_lot') }}：</span>
                            <span>{{ store.auctionItemIndex + 1 }}/{{ store.auctionItems.length }}</span>
                          </div>
                          <div class="watch-user"><img class="icon" src="@/assets/icon-watch.png"/>{{ store.viewCount }}</div>
                        </div>
                        <div class="live-tips-wrapper flex item-center" v-if="globalNotification && store.auctionItemIndex > -1" ref="liveTipsWrapper">
                          <img class="icon" src="@/assets/icon-live-notice.png"/>
                          <div class="live-tips-box flex-1" ref="liveTipsBox">
                            <div class="live-tips" ref="liveTips">{{ globalNotification }}</div>
                          </div>
                        </div>
                      </div>
                      <div class="player">
                        <agora-live-player :agoraPreferences="agoraPreferences" :liveAndRateFullScreen="liveAndRateFullScreen" @mobileFullScreen="handleMobileFullScreen"/>
                      </div>
                    </div>
                  </div>
                  <div class="info-wrap" :class="{ 'hidden-xs-only': !liveAndRateFullScreen }">
                    <div class="title text-overflow">{{ `LOT ${auctionItem.number} ${auctionItem.title}` }}</div>
                    <div @click="handleFullScreen" class="action">
                      <span>{{ liveAndRateFullScreen ? $t('shrink') : $t('expand') }}</span>
                      <img :src="require(`@/assets/icon-${liveAndRateFullScreen ? 'shrink' : 'expand'}.png`)" alt="">
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex item-center content-center notice" v-if="nextFollowLotPosition > 0">
                <img src="@/assets/icon-notice.png" />
                {{ $t('next_lot_followed', { num: nextFollowLotPosition }) }}
              </div>
              <div class="fixed-footer hidden-xs-only" :style="{ padding: 0 }" v-if="store.number">
                <!-- 当前额度 PC端 -->
                <div class="excess-info">
                  <div class="flex content-between item-center">
                    <div>
                      <span class="excess">{{ $t('excess') }}</span>
                      <span v-if="auctionItem.status === 'pending' && $get(auctionItem, 'id') !== $get(store, 'auction_item_id')">
                        <span class="text-uppercase" style="color: #000;">{{ currentShowAuctionItem.currency }} </span>
                        <span>
                          {{ $get(currentShowAuctionItem, `availableAmount.${store.auction.margin_ratio}`) | conversionFormat(CURRENCY_MAP.includes(currentShowAuctionItem.currency) ? currentShowAuctionItem.currency : 'jpy', currentShowAuctionItem.currency) | currencyFormat }}
                        </span>
                      </span>
                      <span v-else>
                        <span class="text-uppercase" style="color: #000;">{{ currentCurrency }}</span>
                        <span>
                          {{ $get(availableAmount, `${store.auction.margin_ratio}`) | conversionFormat(quotaCurrency, currentCurrency ) | currencyFormat }}
                        </span>
                      </span>
                      <span class="margin-ratio">
                        1 : {{ store.auction.margin_ratio }}
                      </span>
                    </div>
                    <router-link
                      style="color: #bb4353;"
                      :to="{ name: 'charge', query: { auctionId: store.auction.id, selectCurrency: quotaCurrency } }"
                    >
                      <div class="recharge">{{ $t('recharge') }}</div>
                    </router-link>
                  </div>
                </div>
                <p class="info" v-if="store.isOfflineLot">{{ $t('bidTips') }}</p>
                <p class="info" v-if="store.status && (store.status !== 'pending') && (activeSlide === store.auctionItemIndex)">*{{ storeStatusHint }}</p>
                <!-- 事前出价 PC端  -->
                <div class="hidden-xs-only flex content-center item-center" style="background: #eee;" v-if="showPreBidPrice">
                  <div class="label">{{ $t('preBid') }}：</div>
                  <div class="price-wrapper flex item-center">
                    <div class="currency text-uppercase">{{ currentCurrency }}</div>
                    <div class="amount">{{ store.preBidPrice | currencyFormat }}</div>
                  </div>
                </div>
                <div
                  v-else
                  class="primary-btn bid-btn"
                  :class="{
                    disabled: !store.canBid || $get(auctionItem, 'id') !== $get(store, 'auction_item_id')
                  }"
                  @click="handlePlacards"
                >
                  {{ $t('bid') }}({{ store.number }})
                </div>
              </div>
            </template>
          </div>

          <div class="screen-right">
            <div class="swiper-and-action-box">
              <app-swiper
                :get-instance="handleGetSwiper"
                @slideChange="handleSlideChange"
                v-if="store.auctionItems.length"
              >
                <div class="swiper-slide" v-for="item in store.auctionItems" :key="item.id">
                  <div class="lot-info">
                  <h3 class="title text-overflow">
                    <div class="tag" v-if="item.auction_way === 'offline'">{{ $t('offline_limit') }}</div>
                    LOT {{item.number}} {{ item.title }}
                  </h3>
                  </div>
                </div>
              </app-swiper>
              <div @click="handleFullScreen" class="action hidden-sm-and-up">
                <!-- 真机放大旋转然后旋转回竖屏按缩小文字没有更新，所以需要换种写法 -->
                <span v-show="liveAndRateFullScreen">{{ $t('shrink') }}</span>
                <span v-show="!liveAndRateFullScreen">{{ $t('expand') }}</span>
                <img :src="require(`@/assets/icon-${liveAndRateFullScreen ? 'shrink' : 'expand'}.png`)" alt="" class="action-icon">
              </div>
            </div>
            <auction-item-toggle-control
              class="hidden-sm-and-up sm-toggle-control"
              :show-next="activeSlide + 1 < store.auctionItems.length"
              :show-pre="activeSlide > 0"
              @pre="handleSlidePre"
              @next="handleSlideNext"
            >
              <template slot="pre">{{ $t('prev') }}</template>
              <template slot="next">{{ $t('next') }}</template>
              <span slot="title" v-if="store.auction_item_id && (activeSlide !== store.auctionItemIndex)" @click="handleSlideRestore">{{ $t('toggleTitle') }}</span>
            </auction-item-toggle-control>

            <div>
              <el-tabs v-model="tab" v-if="store.isStarted">
                <el-tab-pane name="tab-1" :label="$t('bidRecord')">
                  <!-- 只有排在当前拍品后面的拍品才有可能未开始拍卖 (出现了下面链接任务这样的BUG，但是无法重现) -->
                  <!-- https://beansmile.worktile.com/mission/projects/615141fe3e614c250ccd4faa/tasks/617b57ecd957aa4a7e8b3f94 -->
                  <div class="section-empty" v-if="(activeSlide > store.auctionItemIndex) && $get(auctionItem, 'status') === 'pending'">{{$t('auctionsPending')}}</div>
                  <Record
                    :list="currentRecordList"
                    :auctionItem="auctionItem"
                    :currency="$get(auctionItem, 'currency')"
                    :number="store.number"
                    v-else
                  >
                    <!-- 这里不要用v-if，不然切换的时候会重新渲染Notification -->
                    <div v-show="store.auction_item_id && (String(store.auction_item_id) === String($get(auctionItem, 'id')))">
                      <Notification v-if="store.status && (store.status !== 'pending')" :message="`*${storeStatusHint}`" />
                      <Notification v-else :message="notification"/>
                      <Notification v-if="store.status === 'pending'" :message="bidRemind"/>
                    </div>
                  </Record>
                </el-tab-pane>
                <el-tab-pane name="tab-2" :label="$t('lotDetail')">
                  <auction-item-swiper :images="auctionItem.images" v-if="tab === 'tab-2'" />
                  <p class="desc" v-html="auctionItem.desc"></p>
                </el-tab-pane>
              </el-tabs>
              <div v-else>
                <auction-item-swiper :images="auctionItem.images" />
                <p class="desc" v-html="auctionItem.desc"></p>
              </div>
            </div>
          </div>
        </div>

        <div class="component-fixed-bottom" v-if="!store.isFinished && store.number && !mobileFullScreen">
          <!-- 当前额度 移动端 -->
          <div class="hidden-sm-and-up excess-info">
            <div class="flex content-between item-center">
              <div>
                <span class="excess">{{ $t('excess') }}</span>
                <span v-if="auctionItem.status === 'pending' && $get(auctionItem, 'id') !== $get(store, 'auction_item_id')">
                  <span class="text-uppercase" style="color: #000;">{{ currentShowAuctionItem.currency }} </span>
                  <span>
                    {{ $get(currentShowAuctionItem, `availableAmount.${store.auction.margin_ratio}`) | conversionFormat(CURRENCY_MAP.includes(currentShowAuctionItem.currency) ? currentShowAuctionItem.currency : 'jpy', currentShowAuctionItem.currency) | currencyFormat }}
                  </span>
                </span>
                <span v-else>
                  <span class="text-uppercase" style="color: #000;">{{ currentCurrency }}</span>
                  <span>
                    {{ $get(availableAmount, `${store.auction.margin_ratio}`) | conversionFormat(quotaCurrency, currentCurrency ) | currencyFormat }}
                  </span>
                </span>
                <span class="margin-ratio">
                  1 : {{ store.auction.margin_ratio }}
                </span>
              </div>
              <router-link style="color: #bb4353;" :to="{ name: 'charge', query: { auctionId: store.auction.id, selectCurrency: quotaCurrency } }">
                <div class="recharge">{{ $t('recharge') }}</div>
              </router-link>
            </div>
          </div>
          <!-- 事前出价 移动端  -->
          <div v-if="showPreBidPrice"
            class="fixed-footer pre-bid-price hidden-sm-and-up flex content-center item-center"
          >
            <div class="label">{{ $t('preBid') }}：</div>
            <div class="price-wrapper flex item-center">
              <div class="currency text-uppercase">{{ currentCurrency }}</div>
              <div class="amount">{{ store.preBidPrice | currencyFormat }}</div>
            </div>
          </div>
          <div v-else class="fixed-footer bid-number hidden-sm-and-up">
            <p class="info" v-if="store.isOfflineLot">{{ $t('bidTips') }}</p>
            <div class="primary-btn bid-btn"
              :class="{ disabled: !store.canBid || $get(auctionItem, 'id') !== $get(store, 'auction_item_id')}"
              @click="handlePlacards"
            >
              {{ $t('bid') }}({{ store.number }})
            </div>
          </div>
        </div>
      </div>
    </div>
    <PricePopup
      :show.sync="showPricePopup"
      :store="store"
      :currentRecordList="currentRecordList"
      @confirm="handleBid"
    />
  </div>
</template>

<script>
  import { Vue, Component, Watch } from 'vue-property-decorator';
  import Record from './components/record';
  import Notification from './components/notification';
  import PricePopup from './components/price-popup';
  import { authStore, settingsStore } from '@/store';
  import Store from './store';
  import _ from 'lodash';
  import Skeleton from './components/live-skeleton';
  import { CURRENCY_MAP } from '@/constants';

  @Component({
    components: {
      Record,
      PricePopup,
      Notification, Skeleton,
    }
  })
  export default class AuctionLive extends Vue {
    tab = 'tab-1';
    activeSlide = 0;
    authStore = authStore;
    settingsStore = settingsStore;
    store = {};
    notification = ''
    showPricePopup = false
    bidRemind = ''; // 即将落槌提示

    liveTipsLoopTimer = '';
    globalNotification = '';
    agoraPreferences = {};

    mobileFullScreen = false;
    loading = true
    liveAndRateFullScreen = false
    CURRENCY_MAP = CURRENCY_MAP

    fullScreenHeight = '100vh'
    showFullScreenAuctionImage = true

    // 距您关注的下一件拍品还有x件
    get nextFollowLotPosition() {
      const itemIndex = this.store.auctionItemIndex;
      const items = this.store.auctionItems.slice(itemIndex + 1);
      const nextFollowedIndex = items.findIndex(item => item.is_follow);
      return nextFollowedIndex + 1;
    }

    // 用户触发的滚动当前的item
    get auctionItem() {
      const item = this.store.auctionItems[this.activeSlide] || {};
      if (item.id) {
        this.store.biddingPrices(item.id);
      }
      return item;
    }

    get currentRecordList() {
      return _.get(this.store, `biddingPricesRecord.${this.auctionItem.id}`) || [];
    }

    get currencyUpperCase() {
      return (_.get(this.auctionItem, 'currency') || '').toUpperCase();
    }

    get storeStatusHint() {
      const { final_price: finalPrice, status } = this.store.auctionItem;
      const hasBeenHammered = ['hammered', 'force_hammered'].includes(status);
      const currentUsersBiddingPrice = this.currentRecordList.filter(item => _.get(item, 'auction_number.number') === this.store.number)[0];
      const showNoneffectiveBiddingPriceHint = hasBeenHammered && +(currentUsersBiddingPrice || {}).price > finalPrice;
      // 举牌前已落槌
      if (showNoneffectiveBiddingPriceHint) {
        return this.$t('noneffectiveHintAfterHammered', { price: `${this.currencyUpperCase}${this.$options.filters.currencyFormat(finalPrice)}` });
      }
      if (hasBeenHammered) {
        return this.$t('auctionItemHammered', { price: `${this.currencyUpperCase}${this.$options.filters.currencyFormat(finalPrice)}` });
      }
      return this.$t(this.store.status);
    }

    // 显示事前出价（线上拍品 + 事前出价大于当前出价）
    get showPreBidPrice() {
      return !this.store.isOfflineLot
        && this.store.preBidPrice > this.$get(this.store, 'lastPrice.price', 0);
    }

    // 当前正在拍卖的拍品的货币
    get currentCurrency() {
      return this.$get(this.store, 'auctionItem.currency');
    }

    // pending状态以用户当前查看的拍品为准
    get currentShowAuctionItem() {
      const { currency: showCurrency } = this.auctionItem;
      const availableAmount = this.store.quotas.find(item => item.currency === (CURRENCY_MAP.includes(showCurrency) ? showCurrency : 'jpy'));
      return {
        currency: this.$get(this.auctionItem, 'currency'),
        availableAmount: this.$get(availableAmount, 'available_bidding_amount')
      };
    }

    // 正在拍卖的拍品货币若不包含在CURRENCY_MAP，则统一用jpy
    get quotaCurrency() {
      return CURRENCY_MAP.includes(this.currentCurrency) ? this.currentCurrency : 'jpy';
    }

    get currentPrice() {
      const { status, final_price, starting_price } = this.auctionItem;
      return ['hammered', 'force_hammered'].includes(status) ? final_price : (_.get(this.currentRecordList, '0.price') || starting_price);
    }

    // 可竞价额度
    get availableAmount() {
      const currentQuota = this.store.quotas.find(item => item.currency === this.quotaCurrency);
      return this.$get(currentQuota, 'available_bidding_amount');
    }

    created() {
      this.$on('info', message => this.notification = message);
      this.$on('disconnected', async (result) => {
        if (_.get(result, 'willAttemptReconnect')) {
          await this.$confirm(this.$t('networkError'));
          location.reload();
        }
      });

      // 后端可能会推送过来很多条noneffective 状态bid price
      this.onAdjustedRemind = _.throttle(() => {
        this.notification = { message: this.$t('noneffectiveRemind') };
      }, 500, { leading: true, trailing: false });

      // 上述信息在新价出现之前一直存在
      this.onRemoveAdjustedRemind = _.throttle(() => {
        const [first, second] = this.currentRecordList;
        if (first && second && first.price !== second.price) {
          this.notification = '';
        }
      }, 500, { leading: true, trailing: false });

      this.store = new Store(this.$route.params.id, {
        finishedTip: this.$t('finished_tip'),
        congratulations: this.$t('congratulations'),
        onAdjustedRemind: this.onAdjustedRemind,
        onRemoveAdjustedRemind: this.onRemoveAdjustedRemind
      }, this.$route.query.isPreview, this);

      // 第一次进来加载初始化slider位置
      this.initSlidePositionOnce = _.once(() => this.handleSlideRestore());

      if (window.$crisp) {
        // 隐藏 crisp, 避免遮挡出价
        window.$crisp.push(['config', 'hide:on:mobile', [true]]);
      }
    }

    mounted() {
      // 监听键盘Esc按键事件：退出全屏
      this.$nextTick(() => {
        window.addEventListener('keydown', this.handleLiveAndRateFullScreen);
        window.addEventListener('resize', this.handleResize);
      });
    }

    async onFetch() {
      await this.store.fetchLiveBroadcast();
      this.store.initSubscription();
      await this.store.fetchData();
      this.getAgoraPreferences(this.store.liveBroadcast);
      // 拍卖处于预览中
      if (this.store.isPreviewInLive && !this.store.isStarted) {
        this.store.disconnect();
        await this.$alert(this.$t('liveAuctionPreviewedTips'));
        this.$router.replace({ name: 'liveBroadcastDetail', params: { id: this.store.liveBroadcastId } });
        return;
      }
      if (this.store.isFinished) {
        this.store.disconnect();
      }
      this.fullScreenHeight = window.innerHeight + 'px';
    }

    handleLiveAndRateFullScreen(e) {
      if (e.key === 'Escape' && this.liveAndRateFullScreen) {
        this.liveAndRateFullScreen = false;
        this.createLiveTipsLoop();
      }
    }

    async handleResize() {
      await this.$sleep(300);
      // 安卓横屏高度不够的话，不显示拍品图片
      this.fullScreenHeight = window.innerHeight + 'px';
      if ([90, -90].includes(window.orientation) && this.fullScreenHeight < '290') {
        this.showFullScreenAuctionImage = false;
      } else {
        this.showFullScreenAuctionImage = true;
      }
      this.createLiveTipsLoop();
    }

    handleFullScreen() {
      this.liveAndRateFullScreen = !this.liveAndRateFullScreen;
      this.createLiveTipsLoop();
    }

    // 如果没有上传任何证件，没有证件有效期，提示用户填写后再举牌
    async handlePlacards() {
      if (this.$authStore.isLoadCredential) {
        if (this.$authStore.isLoadEffectiveDate) {
          await this.$confirm(this.$t('uploadValidityPeriod'));
          return this.$router.push({ name: 'myAddress' });
        }
        return this.showPricePopup = true;
      } else {
        await this.$confirm(this.$t('uploadTip'));
        return this.$router.push({ name: 'myAddress' });
      }
    }

    getAgoraPreferences(data) {
      if (data) {
        this.agoraPreferences = {
          appid: data.appid,
          channel: data.channel,
          token: data.channel_token || null,
          uid: data.channel_uid,
          is_live: data.is_live,
          host_uids: data.host_uids
        };
      }
    }

    handleMobileFullScreen(status) {
      this.mobileFullScreen = status;
    }

    // 3秒提示一次出价
    handleBidRemind() {
      const remind = () => this.bidRemind = { message: this.$t('tips'), duration: 4000 };
      remind();
      this.handleCancelBidRemind();
      this.bidRemindTimer = setInterval(remind, 7000);
    }

    handleCancelBidRemind() {
      if (this.bidRemindTimer) {
        clearInterval(this.bidRemindTimer);
      }
    }

    async createLiveTipsLoop() {
      await this.$nextTick();
      const isPortrait = [0, 180].includes(window.orientation);
      const wrapperElement = this.$refs.liveTipsWrapper;
      const tipsElement = this.$refs.liveTips;
      const boxElement = this.$refs.liveTipsBox;
      if (!wrapperElement || !tipsElement || !boxElement) {
        return;
      }

      const { width: boxWidth, height: boxHeight } = boxElement.getBoundingClientRect();
      const { width: tipsWidth, height: tipsHeight } = tipsElement.getBoundingClientRect();

      const stopLiveTipsLoop = () => {
        this.clearLiveTipsLoop();
        this.$get(this.$refs, 'liveTipsBox.childNodes', []).forEach((element, index) => {
          if (index === 0) {
            element.style.transform = 'translateX(0px)';
          } else {
            element.remove();
          }
        });
      };

      if (isPortrait && this.liveAndRateFullScreen) {
        if (tipsHeight <= boxHeight) {
          stopLiveTipsLoop();
          return;
        }
      } else {
        if (tipsWidth <= boxWidth) {
          stopLiveTipsLoop();
          return;
        }
      }

      const space = 40;
      tipsElement.style.transform = 'translateX(0px)';

      if (this.$get(this.$refs, 'liveTipsBox.childNodes.length') <= 1) {
        const cloneElement = tipsElement.cloneNode(true);
        cloneElement.style.left = (((isPortrait && this.liveAndRateFullScreen) ? tipsHeight : tipsWidth) + space) + 'px';
        boxElement.appendChild(cloneElement);
      }

      await this.$sleep(1000);
      let count = 0;
      this.clearLiveTipsLoop();

      this.liveTipsLoopTimer = setInterval(() => {
        if (!this.globalNotification || this.store.auctionItemIndex < 0) {
          this.clearLiveTipsLoop();
          return;
        }
        count += 0.5;
        this.$get(this.$refs, 'liveTipsBox.childNodes', []).forEach((element) => {
          element.style.transform = `translateX(-${count}px)`;
          if (count >= ((isPortrait && this.liveAndRateFullScreen) ? tipsHeight : tipsWidth) + space) {
            count = 0;
            element.style.transform = 'translateX(0px)';
            return;
          }
        });
      }, 24);
    }

    clearLiveTipsLoop() {
      if (!this.liveTipsLoopTimer) {
        return;
      }
      clearInterval(this.liveTipsLoopTimer);
      this.liveTipsLoopTimer = null;
    }

    beforeDestroy() {
      this.store.disconnect();
      this.clearLiveTipsLoop();
      this.handleCancelBidRemind();
      window.removeEventListener('keydown', this.handleLiveAndRateFullScreen);
      window.removeEventListener('resize', this.handleResize);
      // 其他页面重新显示 crisp
      window.$crisp && window.$crisp.push(['config', 'hide:on:mobile', [false]]);
    }

    async handleGetSwiper(swiper) {
      this.swiper = swiper;
      await this.$nextTick();
      this.handleSlideRestore();
    }

    async handleBid(price) {
      this.store.bid(price);
      this.showPricePopup = false;
    }

    handleSlideChange(e) {
      this.activeSlide = e.activeIndex;
    }

    handleSlidePre() {
      this.swiper.slidePrev();
    }

    handleSlideNext() {
      this.swiper.slideNext();
    }

    handleSlideRestore() {
      if (this.swiper) {
        this.swiper.slideTo(this.store.auctionItemIndex);
      }
    }

    @Watch('store.auctionItem.id')
    async onCurrentAuctionItemIndexChange(id) {
      if (id) {
        await this.$nextTick();
        this.initSlidePositionOnce();
        if (this.activeSlide === (this.store.auctionItemIndex - 1)) {
          this.handleSlideRestore();
        }
      }
    }

    @Watch('store.auction.global_notification')
    async onGlobalNotificationChange(str) {
      this.globalNotification = '';
      await this.$sleep(1000);
      this.globalNotification = str || this.$t('liveTips');
      this.createLiveTipsLoop();
    }

    @Watch('store.auctionItemIndex')
    async onAuctionItemIndexChange(newValue, oldValue) {
      if (newValue > -1 && oldValue !== undefined && oldValue === -1) {
        await this.$sleep(1000);
        this.createLiveTipsLoop();
      }
    }

    @Watch('store.number')
    @Watch('store.canBid')
    @Watch('store.lastPriceUserNumber')
    @Watch('store.biddingPricesRecord')
    onPriceChange() {
      const { auction_item_id, number, canBid, lastPriceUserNumber, biddingPricesRecord } = this.store;
      const priceList = _.get(biddingPricesRecord, auction_item_id);
      if (priceList && canBid && number && number !== lastPriceUserNumber) {
        this.handleBidRemind();
      } else {
        this.handleCancelBidRemind();
      }
    }

    @Watch('store.auctionItem.number')
    onNumberChange(number) {
      // 拍品改变重置消息
      if (number) {
        this.notification = '';
      }
    }
  }
</script>

<style lang="scss">
  @include media-sm-and-up {
    .page-name-auctionLive {
      height: 100vh;

      & > .page-content-wrapper {
        overflow: hidden;
      }

      .route-view {
        display: flex;
        flex-direction: column;
        height: 100%;

        .page-auction-live {
          flex: 1;
          padding-top: 30px;
          padding-bottom: 30px;
          overflow: auto;
        }
      }
    }
  }
</style>

<style lang="scss" scoped>
  /deep/ .box-swiper-control {
    .front, .back {
      &::before {
        display: none;
      }
    }
  }

  /deep/ .section-empty {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    text-align: center;
    color: $gray;
  }

  .box-finished {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    font-size: 16px;
    color: #888888;
    background: #f6f9fa;

    img {
      width: 60px;
    }
  }

  .container {
    overflow: initial;
  }

  .box-content {
    @include clearfix;

    .screen-left {
      @include media-xs-only {
        position: sticky;
        z-index: var(--fixed-z-index);
        top: var(--header-height);
        margin: 0 -15px;
      }
    }

    .sm-toggle-control {
      @include media-xs-only {
        margin: 0 -15px;
      }
    }

    @include media-sm-and-up {
      .screen-left {
        top: 0;
        float: left;
        flex-shrink: 0;
        width: 52%;
      }

      .screen-right {
        float: right;
        width: 48%;
        padding-left: 30px;

        .lot-info {
          padding-top: 0;
        }
      }
    }
  }

  .desc {
    line-height: 1.4;
    color: #666666;
  }

  .full {
    position: fixed;
    z-index: 2000;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    background: #ebebeb;

    &.mobile {
      left: 100%;
      width: var(--full-screen-height);
      height: 100vw;
      padding-right: env(safe-area-inset-bottom);
      transform: rotate(90deg);
      transform-origin: 0 0;

      @media screen and (orientation:landscape) {
        left: 0;
        width: 100vw;
        height: var(--full-screen-height);
        padding-right: 0;
        padding-left: env(safe-area-inset-left);
        transform: initial;
        transform-origin: initial;
      }
    }

    &.pc {
      width: 100vw;
      height: var(--full-screen-height);
      padding-left: env(safe-area-inset-left);
    }

    .live-info-wrap {
      display: flex;
      flex: 1;
      flex-direction: column;
      height: auto;
    }

    .live-rate-wrap {
      flex: 1;
      height: auto;

      .rate-box {
        @include media-sm-and-up {
          width: 180px;

          .auction-item-box {
            max-height: 180px;
          }
        }
      }
    }

    div .live-tips-wrapper {
      width: 35%;

      @include media-lg-and-up {
        width: 20%;
      }
    }

    div .box-live .indicator-watch .indicator {
      flex-direction: row;
    }
  }

  .live-rate-wrap {
    height: 270px;
    overflow: hidden;

    @include media-xs-only {
      height: 190px;
    }
  }

  .info-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 11px;
    font-size: 14px;
    color: #000;
    background: #eeeeee;
    cursor: pointer;

    @media screen and (orientation:landscape) {
      @include paddingBottomSafeArea(11px);
    }

    .action {
      display: flex;
      flex-shrink: 0;
      align-items: center;

      img {
        width: 20px;
        height: 20px;
        margin-left: 10px;
      }
    }
  }

  .rate-box {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 105px;
    font-size: 11px;
    white-space: nowrap;
    background: #eee;

    .current-price-box {
      padding: 8px 5px;

      .price {
        margin-top: 8px;
        font-size: 12px;
      }
    }

    .translate-price-box {
      flex: 1;
      padding: 12px 5px;
      border-top: 1px solid #d5d5d5;

      .price {
        & + .price {
          margin-top: 8px;
        }
      }
    }

    .auction-item-box {
      display: flex;
      justify-content: center;
      align-content: flex-end;
      max-height: 100px;
      overflow: hidden;

      @media screen and (orientation:landscape) {
        max-height: 80px;
      }

      img {
        height: 100%;
        object-fit: contain;
      }
    }

    .price {
      color: #bc4353;

      .currency {
        margin-right: 5px;
        text-transform: uppercase;
        color: #000;
      }
    }
  }

  .box-live {
    position: relative;
    width: 100%;
    height: 100%;

    .player {
      width: 100%;
      height: 100%;
    }

    .indicator-watch {
      position: absolute;
      z-index: 9;
      top: 10px;
      left: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 17px;
      padding: 0 5px;

      .indicator,
      .watch-user {
        display: flex;
        align-items: center;
        padding: 0 6px;
        border-radius: 2px;
        font-size: 10px;
        background: rgba(#fff, 0.4);

        .icon {
          width: 8px;
          margin-right: 4px;
        }
      }

      .indicator {
        margin-right: 6px;

        @include media-xs-only {
          @media screen and (orientation:portrait) {
            flex-direction: column;
            align-items: flex-start;
          }
        }
      }
    }

    .live-tips-wrapper {
      width: 50%;
      height: 17px;
      border-radius: 2px;
      overflow: hidden;
      font-size: 10px;
      white-space: nowrap;
      background-color: rgba(#fff, 0.4);

      @include media-xs-only {
        width: 40%;
      }

      .icon {
        width: 8px;
        margin: 4px;
      }

      .live-tips-box {
        position: relative;
        height: 100%;
        overflow: hidden;
        white-space: nowrap;

        .live-tips {
          position: absolute;
          left: 2px;
          box-sizing: border-box;
          padding-top: 2px;
          padding-right: 4px;
          white-space: nowrap;
        }
      }
    }
  }

  .excess-info {
    width: 100%;
    padding: 12px 20px;
    font-size: 15px;
    color: $primary;
    background: #fff;

    @include media-xs-only {
      padding: 8px 20px 6px;
    }

    .excess {
      font-size: 12px;
      vertical-align: text-top;
      color: #666;
    }

    .recharge {
      font-size: 13px;

      @include media-xs-only {
        font-size: 12px;
      }
    }

    .margin-ratio {
      display: inline-block;
      padding: 0 10px;
      margin-left: 12px;
      border: 1px solid #ccc;
      border-radius: 12px;
      font-size: 12px;
      vertical-align: text-top;
      color: #666;

      @include media-xs-only {
        padding: 0 6px;
        margin-left: 6px;
      }
    }
  }

  .fixed-footer {
    width: 100%;
    padding: 8px 20px;
    background: #fff;

    &.bid-number {
      padding: 0px 20px 8px;
    }

    .info {
      margin: 0 0 12px;
      text-align: center;
      color: $primary;
    }

    @include media-sm-and-up {
      margin-top: 15px;
      box-shadow: none;

      .primary-btn {
        width: 360px;
      }
    }

    .bid-btn {
      color: #fff;
      background-color: $primary;

      &:hover {
        color: #fff !important;
        background-color: $primary !important;
      }

      &:active {
        color: #fff !important;
        background-color: $primary !important;
      }

      &.disabled {
        background: #ccc;
      }
    }

    .label {
      margin-top: 2px;
      font-weight: 500;
      font-size: 12px;
      color: $gray;
    }

    .price-wrapper {
      padding: 8px 0;

      @include media-xs-only {
        padding: 0;
      }
    }

    .currency {
      font-weight: 500;
      font-size: 18px;
      color: #000;
    }

    .amount {
      margin-left: 5px;
      font-weight: 500;
      font-size: 21px;
      color: $primary;
    }
  }

  .pre-bid-price {
    border-top: 1px solid #eee;
  }

  .notice {
    height: 30px;
    font-size: 14px;
    color: #fff;
    background: $primary;

    img {
      width: 14px;
      margin-right: 6px;
    }
  }

  .swiper-and-action-box {
    position: relative;

    .action {
      position: absolute;
      z-index: 2;
      top: 50%;
      right: 0;
      display: flex;
      align-items: center;
      background: #fff;
      transform: translateY(-50%);

      .action-icon {
        width: 15px;
        height: 15px;
        margin-left: 5px;
      }
    }
  }

  .lot-info {
    padding: 22px 0;

    @include media-xs-only {
      padding: 5px 0;
    }

    .title {
      margin: 0;
      font-weight: 800;
      font-size: 14px;
      line-height: 24px;
      color: #000000;

      @include media-xs-only {
        padding-right: calc(55px + 15px);
      }

      .tag {
        display: inline-flex;
        align-items: center;
        height: 14px;
        padding: 0 7px;
        border-radius: 2px;
        font-size: 9px;
        vertical-align: middle;
        color: #fff;
        background: $primary;
      }
    }
  }

  .box-tip {
    .tip {
      margin: 10px 0;
      font-size: 11px;
      color: $primary;
    }
  }

  /deep/ .el-tabs__header {
    background: #eeeeee;

    .el-tabs__active-bar {
      display: none;
    }

    .el-tabs__nav {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    .el-tabs__nav-wrap::after {
      display: none;
    }

    .el-tabs__item {
      position: relative;
      flex: 1;
      padding: 0;
      text-align: center;
      color: #999999;
      -webkit-tap-highlight-color: transparent;
      -webkit-touch-callout: none;
      user-select: none;

      &.is-active {
        color: var(--color-tag-font);
        background-color: var(--color-tag-bg);

        &:after {
          position: absolute;
          bottom: 0;
          left: 50%;
          width: 55px;
          height: 2px;
          border-radius: 1px;
          background: #000000;
          transform: translateX(-50%);
          content: '';
        }
      }
    }
  }

  .component-fixed-bottom {
    position: fixed;
    z-index: 99;
    bottom: 0;
    left: 0;
    width: 100%;
    box-shadow: 0 4px 18px 2px rgba(4, 0, 0, 0.1);

    @include paddingBottomSafeArea;
  }
</style>
