import { Collection } from './collection';
import { fly } from '@/utils';

const categoryMaps = new Map();

export class AuctionItems extends Collection {
  static findOrCreate(categoryId) {
    categoryId = String(categoryId || '');
    if (categoryMaps.has(categoryId)) {
      return categoryMaps.get(categoryId);
    } else {
      const instance = new this;
      if (categoryId) {
        instance.params.auction_category_id_eq = categoryId;
        instance.params.page = 0;
        categoryMaps.set(categoryId, instance);
      }
      return instance;
    }
  }

  fetch(params) {
    return fly.get('auction_items', { entity: 'CategoryPageAuctionItem', ...params });
  }
}
