<i18n src="./locales.json"></i18n>

<template>
  <div class="page-order-detail flex">
    <v-title :value="$t('detail')"/>
    <common-sidebar class="hidden-xs-only" />
    <div class="container">
      <div ref="pdfBox" :class="['pdf-box', { loading: pdfLoading }]">
        <div class="item title-wrapper flex content-between item-center">
          <div class="title">{{ $t('detail') }}</div>
          <div class="status flex">
            <img class="icon-status" :src="status.image" />
            <div>{{ status.text }}</div>
          </div>
        </div>
        <div class="item flex content-between">
          <div class="info-title">{{ $t('orderNumber') }}</div>
          <div class="info-content">{{ $get(store, 'data.no') }}</div>
        </div>

        <!-- 商品信息 -->
        <div class="item">
          <div class="info-title">{{ $t('productInfo') }}</div>
          <div class="component-order-item">
            <order-item :order="$get(store, 'data')" />
          </div>
        </div>

        <!-- 订单详情 -->
        <div v-if="auctionOrder && isWaitPayLogistic" class="item">
          <div class="info-title">{{ $t('orderPaidDetail') }}</div>
          <div class="price-detail-wrap">
            <cost-info
              :store="store"
              :showSubtotal="$get(store, 'data.exchange_rate_type') === 'to_jpy'"
              :showDeductionPrice="!!$get(store, 'data.payment_amount') && !!$get(store, 'data.margin_amount')"
            />
          </div>
        </div>

        <!-- 费用信息 -->
        <div class="item" v-if="!isWaitBuyerConfirmLogisticInfo">
          <cost-info
            :store="store"
            :showAuctionPrice="!isWaitPayLogistic && !isWaitBuyerConfirmLogisticInfo"
            :showCommissionAmount="!isWaitPayLogistic"
            :showTaxAmount="!isWaitPayLogistic"
            :showLogisticFee="showLogisticFee"
            :showSubtotal="!isWaitPayLogistic && $get(store, 'data.exchange_rate_type') === 'to_jpy'"
            :showDeductionPrice="!isWaitPayLogistic && !!$get(store, 'data.payment_amount') && !!$get(store, 'data.margin_amount')"
            :showTotal="showTotal"
          >
            <template v-slot:other-price-item>
              <!-- 已支付过运费 -->
              <div v-if="hadPayLogisticFee" class="price-item">
                <div class="info-title">{{ $t('shipping') }}：</div>
                <div>
                  <span class="currency">{{ paymentCurrency }}</span>
                  <span class="price">{{ $get(store, 'data.logistic_fee') | currencyFormat }}</span>
                </div>
              </div>
              <div v-if="showClosePaymentTip" class="closed-tip flex content-end">{{ $t('closedTip') }}</div>
            </template>
          </cost-info>
        </div>

        <!-- 批量结算订单 -->
        <div class="item" v-if="$get(store, 'data.valid_batch_settlements.length')">
          <div class="info-title">{{ $t('batchList') }}</div>
          <div v-for="(item, index) in $get(store, 'data.valid_batch_settlements', [])" :key="item.id" class="order-batch-box flex item-center content-between">
            <div>{{ `${$t('number')}${$get(store, 'data.valid_batch_settlements.length') > 1 ? index + 1 : ''}` }}: {{ item.no }}</div>
            <router-link
              :to="{ name: 'orderBatchDetail', params: { id: item.id } }"
              class="order-batch-action"
              :class="{ pay: item.status === 'pending' }"
            >
              {{ item.status === 'pending' ? $t('pay') : $t('viewOrderBatch') }}
            </router-link>
          </div>
        </div>

        <!-- 物流信息 -->
        <div class="item" v-if="$get(store, 'data.transport_company')">
          <div class="info-title">{{ $t('logisticsCompany') }}</div>
          <router-link :to="{ name: 'transportCompanies', params: { id: $get(store, 'data.transport_company.id') } }">
            <div class="logist">
              <div class="flex item-center content-between">
                <div class="flex item-center">
                  <img class="logist-cover" :src="$get(store, 'data.transport_company.logo.url')" />
                  <div class="logist-content">
                    <div class="logist-name">{{ $get(store, 'data.transport_company.name') }}</div>
                    <div class="logist-desc">{{ $get(store, 'data.transport_company.desc') }}</div>
                  </div>
                </div>
                <img class="icon-arrow-right" src="@/assets/icon-arrow-right-black.png" />
              </div>
            </div>
          </router-link>
        </div>

        <!-- 选择物流公司 -->
        <div class="item" v-else-if="isShowSelectShipping">
          <div class="info-title">{{ $t('selectShipping') }}</div>
          <div class="select flex column" style="cursor: pointer;">
            <!-- 商品订单在店铺不关闭自提时，才显示自提选项 -->
            <div class="select-item flex item-center" v-if="isShowSelfPick" @click="selectLogist(false)">
              <div class="flex item-center">
                <div :class="['selector', { selected: selfPick === true}]"></div>
                <label class="content" for="self">{{ $t('self') }}</label>
              </div>
            </div>
            <div v-if="auctionOrder">
              <div
                class="select-item flex content-between item-center"
                v-for="item in transportCompaniesStore.data"
                :key="item.id"
                @click="selectLogist(item.id)"
              >
                <div class="flex item-center">
                  <div :class="['selector', { selected: $get(store, 'params.transport_company_id') === item.id }]"></div>
                  <label class="content" :for="item.name">{{ item.name }}</label>
                </div>
                <router-link
                  :to="{ name: 'transportCompanies', params: { id: item.id } }"
                  class="flex content-center"
                >
                  <img class="icon" src="@/assets/icon-arrow-right-black.png" />
                </router-link>
              </div>
            </div>
            <div v-else class="select-item flex item-center" @click="selectLogist(true)">
              <div class="flex item-center">
                <div :class="['selector', { selected: selfPick === false }]"></div>
                <label class="content">{{ $t('transport') }}</label>
              </div>
            </div>
          </div>
        </div>

        <!-- 收货信息 -->
        <div class="item">
          <div v-if="showReceiverInfo" @click="handleShowChangeAddress">
            <div class="info-title">{{ $t('deliveryInfo') }}</div>
            <div :class="{ point: canChangeAddress }">
              <div class="flex item-center content-between">
                <div class="info-title name">{{ receiverInfo }}</div>
                <div class="payment-method" v-if="canChangeAddress">
                  <img class="icon" src="@/assets/icon-arrow-right-black.png" />
                </div>
              </div>
              <div class="address">{{ address.trim() ? $t('deliveryInfo') : $t('fillInDeliveryAddress') }} {{ address }}</div>
            </div>
          </div>
          <div v-else class="flex content-between">
            <div class="info-title">{{ $t('deliveryInfo') }}</div>
            {{ $t('self') }}
          </div>
        </div>

        <!-- 支付方式 -->
      <div class="item" v-if="waitBuyerPay && this.$get(this.store, 'data.payment_amount') && !$get(store, 'data.has_pending_batch_settlement')">
        <div class="flex item-center content-between" @click="handleShowPaymentMethods" style="cursor: pointer;">
          <div class="info-title">{{ $t('paymentMethod') }}</div>
          <div class="info-content flex item-center">
            <img class="icon-payment" v-if="paymentIconPath" :src="paymentIconPath" />
            <div class="payment-method flex item-center">
              {{ $t($get(store, 'params.payment_method')) }}
              <img class="icon" src="@/assets/icon-arrow-right-black.png" />
            </div>
          </div>
        </div>
        <div v-if="dataStatus === 'wait_buyer_pay'" class="pay-tip">{{ $t('payTip') }}</div>
      </div>
      <div class="item" v-if="dataStatus === 'trade_closed'">
        <!-- 订单取消理由 -->
        <div class="flex content-between">
          <div class="info-title">{{ $t('closedReason') }}：</div>
          <div class="prompt">{{ $get(store, 'data.remark') }}</div>
        </div>
      </div>
      </div>

      <div class="item">
        <div v-if="waitBuyerPay" @click="handlePayment"
          :class="['submit-btn', 'primary-btn', 'primary', { disabled: disabledConfirmPay || $get(store, 'data.has_pending_batch_settlement') }]">
          {{ disabledConfirmPay ? $t('waitConfirm') : $t('confirmPay') }}
        </div>
        <div v-if="isWaitBuyerConfirmLogisticInfo" @click="handleConfirmReceiverInfo"
          :class="['submit-btn', 'primary-btn', 'primary']">
            {{ $t('confirmReceiverInfo') }}
        </div>
        <div class="submit-btn primary-btn" v-if="['wait_buyer_confirmed_goods', 'wait_buyer_self_pick'].includes(dataStatus)" @click="handleConfirmGoods">{{ $t('confirmBtn') }}</div>
        <div class="submit-btn primary-btn" v-else-if="canEvaluate" @click="newEvaluation">
          {{ $t('toEvaluate') }}
        </div>
        <div class="flex content-center">
          <div class="contact link" @click="auctionHandleContact">
            {{ $t('inquiry') }} &gt;
          </div>
          <div class="contact link hidden-xs-only" @click="handleDownPdf">{{ $t('pdf_export') }}</div>
        </div>
      </div>

      <div class="item hint-wrap">
        <div v-if="$get(settingsStore, 'settings.large_payment_hint')" class="hint-box">
          <span v-html="$get(settingsStore, 'settings.large_payment_hint')"></span>
        </div>
      </div>
    </div>

    <!-- 选择非在线支付弹窗 -->
    <el-dialog
      class="dialog-page-order-detail"
      :visible.sync="dialogVisible"
      width="24.16667rem"
      center
    >
      <div class="contact-info" v-html="customPopContent.content"></div>
      <div slot="footer">
        <el-button class="primary-btn" style="font-size: 1.5rem" @click="handleContact">{{ $t('contactBtn') }}</el-button>
      </div>
    </el-dialog>

    <!-- 货币是日元，货币转换类型为日币，等待支付时，显示线上支付 -->
    <payment-methods
      v-model="showPaymentMethods"
      :current="$get(store, 'params.payment_method')"
      :showOfflinePaymentItems="!isWaitPayLogistic"
      :showOnlinePaymentItems="showOnlinePaymentItems"
      :isPayDollar="isPayDollar"
      @confirm="handleConfirmMethod"
      :directPay = directPay
    />

    <!-- 选择是否抵扣金额弹窗 -->
    <el-dialog
      class="dialog-page-order-detail"
      :visible.sync="showConfirmDeduction"
      width="24.16667rem"
      center
    >
      <div>{{ $get(store, 'data.margin_amount') ? $t('deductMore') : $t('deductWallet') }}</div>
      <div slot="footer">
        <div class="flex">
          <el-button class="submit-btn primary-btn primary" @click="handleShowPaymentDeduction">{{ $t('yes') }}</el-button>
          <el-button class="submit-btn primary-btn primary" @click="handleDirectPay">{{ $t('no') }}</el-button>
        </div>
      </div>
    </el-dialog>

    <!-- 抵扣价格弹窗 -->
    <payment-deduction
      v-model="showPaymentDeduction"
      :detail="$get(store, 'data')"
      :current="$get(store, 'params.margin_amount')"
      @confirm="handleConfirmDiscount"
    />
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';
  import { authStore, settingsStore, transportCompaniesStore, paymentMethodsStore, OrderDetailStore } from '@/store';
  import _ from 'lodash';
  import { CURRENCY_MAP } from '@/constants';
  import CostInfo from './components/cost-info.vue';
  import html2canvas from 'html2canvas';
  import jsPDF from 'jspdf';

  @Component({ components: { CostInfo } })
  export default class OrderDetail extends Vue {
    dialogVisible = false
    showPaymentMethods = false
    showPaymentDeduction = false
    showReceiverInfo = true
    showConfirmDeduction = false
    store = {}
    customPopContent = {}
    addressInfo = {}
    directPay = false
    CURRENCY_MAP = CURRENCY_MAP

    authStore = authStore
    settingsStore = settingsStore
    transportCompaniesStore = transportCompaniesStore
    paymentMethodsStore = paymentMethodsStore

    pdfLoading = false

    // 是否显示线上支付方式
    get showOnlinePaymentItems() {
      const { currency, exchange_rate_type } = this.store.data || {};

      // 港币商品订单才能使用 PayDollar 支付平台
      if (this.isPayDollar) {
        return true;
      }

      // 其他照旧
      return currency === 'jpy' || this.isWaitPayLogistic || exchange_rate_type === 'to_jpy';
    }

    // 港币订单才能使用 PayDollar 支付平台
    get isPayDollar() {
      const { quota_currency, exchange_rate_type } = this.store.data || {};

      return quota_currency === 'hkd' && exchange_rate_type !== 'to_jpy';
    }

    get address() {
      const { country_name = '', province_name = '', city_name = '', detail_info = '', additional_detail_info = '' } = this.$get(this.store, 'data.address_info', {});
      return [country_name, province_name, city_name, detail_info, additional_detail_info].join(' ');
    }

    get receiverInfo() {
      const { last_name = '', receiver_name = '', tel_number = '', postal_code = '' } = this.$get(this.store, 'data.address_info', {});
      return last_name + [receiver_name, postal_code, tel_number].join(' ');
    }

    get addressDialogWidth() {
      return this.$isMobile() ? '96%' : '552px';
    }

    get status() {
      return {
        wait_buyer_pay: { image: require('@/assets/icon-order-wait-pay.png'), text: this.disabledConfirmPay ? this.$t('waitConfirm') : this.$t('waitPay') },
        wait_buyer_confirm_logistic_info: { image: require('@/assets/icon-order-wait-pay.png'), text: this.$t('waitPay') },
        wait_seller_send_goods: { image: require('@/assets/icon-order-wait-send.png'), text: this.$t('waitDeliver') },
        wait_buyer_confirmed_goods: { image: require('@/assets/icon-order-wait-receive.png'), text: this.$t('waitReceive') },
        wait_buyer_pay_logistic: { image: require('@/assets/icon-order-wait-pay.png'), text: this.$t('waitPayLogistic') },
        wait_buyer_self_pick: { image: require('@/assets/icon-order-wait-receive.png'), text: this.$t('waitSelfPick') },
        trade_finished: { image: require('@/assets/icon-order-completed.png'), text: this.$t('finished') },
        trade_closed: { image: require('@/assets/icon-order-cancelled.png'), text: this.$t('closed') },
        no_paid: { image: require('@/assets/icon-order-cancelled.png'), text: this.$t('noPaid') }
      }[this.dataStatus] || {};
    }

    get paymentIconPath() {
      return {
        'WechatPayment': require('@/assets/payment/icon-wechat.png'),
        'AlipayPayment': require('@/assets/payment/icon-alipay.png'),
        'UnionpayPayment': require('@/assets/payment/icon-unionpay.png'),
        'CreditcardPayment': require('@/assets/payment/icon-creditcard.png'),
        'LinepayPayment': require('@/assets/payment/icon-linepay.png'),
        'PaypayPayment': require('@/assets/payment/icon-paypay.png'),
        'EasyCheckoutPayment': require('@/assets/payment/icon-scan.png')
      }[this.$get(this.store, 'params.payment_method')];
    }

    // 拍品订单
    get auctionOrder() {
      return this.$get(this.store, 'data.orderable_type') === 'AuctionItem';
    }

    // null表示无选择任何运输方式；true表示选中自提；false表示选中了其他运输方式
    get selfPick() {
      return this.$get(this.store, 'params.is_self_pick');
    }

    // 是否显示选择物流公司
    get isShowSelectShipping() {
      if (this.auctionOrder) {
        // 待支付状态且管理员还未设置运输信息（自提/运输公司和运费）
        return this.isWaitBuyerPay && this.$get(this.store, 'data.payment_amount') &&
          !this.$get(this.store, 'data.is_self_pick') &&
          !this.$get(this.store, 'data.transport_company') &&
          this.$get(this.store, 'data.logistic_fee') === null;
      } else {
        return this.isWaitBuyerConfirmLogisticInfo;
      }
    }

    // 无选择任何送货方式
    get isNoSelectLogist() {
      if (this.auctionOrder) {
        return !this.$get(this.store, 'params.transport_company_id') && this.selfPick === null &&
          this.$get(this.store, 'data.logistic_fee') === null;
      } else {
        return this.selfPick === null;
      }
    }

    get dataStatus() {
      return this.$get(this.store, 'data.status');
    }

    get logisticFee() {
      return Number(this.$get(this.store, 'data.logistic_fee'));
    }

    get isWaitBuyerPay() {
      return this.dataStatus === 'wait_buyer_pay';
    }

    get isWaitPayLogistic() {
      return this.dataStatus === 'wait_buyer_pay_logistic';
    }

    get isWaitBuyerConfirmLogisticInfo() {
      return this.dataStatus === 'wait_buyer_confirm_logistic_info';
    }

    // 店铺是否关闭自提，仅影响商品订单
    get isCloseSelfPick() {
      return this.$get(this.store, 'data.auction_house.close_self_pick');
    }

    get canChangeAddress() {
      if (this.auctionOrder && this.isWaitBuyerPay) {
        return true;
      } else if (this.isWaitBuyerConfirmLogisticInfo) {
        return true;
      } else {
        return false;
      }
    }

    // 已支付过运费
    get hadPayLogisticFee() {
      return this.auctionOrder && this.logisticFee && !this.isWaitPayLogistic && !this.isWaitBuyerPay;
    }

    get showLogisticFee() {
      return !(this.auctionOrder && this.isWaitBuyerPay) && !this.isWaitBuyerConfirmLogisticInfo && !this.hadPayLogisticFee;
    }

    get waitBuyerPay() {
      return ['wait_buyer_pay', 'wait_buyer_pay_logistic'].includes(this.dataStatus) &&
        !(this.logisticFee === 0 && this.isWaitPayLogistic);
    }

    // 商品订单在店铺不关闭自提时，才显示自提选项
    get isShowSelfPick () {
      return this.auctionOrder || !this.isCloseSelfPick;
    }

    // 还未确认费用
    get disabledConfirmPay() {
      return !this.$get(this.store, 'data.payment_amount') || this.isWaitPayLogistic && this.logisticFee === 0;
    }

    get currency() {
      if (this.$get(this.store, 'data.exchange_rate_type') === 'to_jpy') {
        return 'JPY';
      } else {
        return this.$get(this.store, 'data.currency');
      }
    }

    // 拍品订单和已评价了是不能显示评价的
    get canEvaluate() {
      return this.dataStatus === 'trade_finished' && !this.store.data.was_evalued && this.store.data.orderable_type === 'Product';
    }

    // 只有支付过订单才提示用户金额返回钱包
    get showClosePaymentTip() {
      return this.dataStatus === 'trade_closed' && this.store.data.has_paid_payments;
    }

    // 待支付运费订单状态，取消订单状态且未支付过任何费用,确认收货信息状态时不显示，
    get showTotal() {
      if (!this.isWaitPayLogistic && this.dataStatus !== 'trade_closed' && !this.isWaitBuyerConfirmLogisticInfo) {
        return true;
      } else {
        return this.showClosePaymentTip;
      }
    }

    // 以前的订单支付方式不在可选方位内
    get includesPayment() {
      return this.paymentMethodsStore.data.includes(this.$get(this.store, 'params.payment_method'));
    }

    // 商品订单勾选了所有的支付方式
    get hasSelectAll() {
      return this.$get(this.store, 'data.orderable.available_payments', '').includes('all');
    }

    get paymentCurrency() {
      return _.toUpper(CURRENCY_MAP.includes(this.currency) ? this.currency : 'jpy');
    }

    @Vue.autoLoading
    async created() {
      await authStore.tryFetchData();

      if (!authStore.user.region) {
        await this.$alert(this.$t('personalInfoHint'), { showClose: false });
        this.$router.push({ name: 'mySettings' });
        return;
      }

      await Promise.all([
        this.fetchData(),
        settingsStore.tryFetchData(),
        transportCompaniesStore.tryFetchData(),
      ]);

      // 初始化地址，如果没有收货地址，就显示用户设置的收货地址
      this.addressInfo = _.cloneDeep(this.$get(this.store, 'data.address_info', {}));

      // 订单支付方式区分拍品、商品
      const paymentMethodsType = _.snakeCase(this.$get(this.store, 'data.orderable_type'));
      await paymentMethodsStore.fetchData({
        currency: CURRENCY_MAP.includes(this.$get(this.store, 'data.currency')) ? this.$get(this.store, 'data.currency') : 'jpy',
        region: authStore.user.region,
        type: paymentMethodsType
      });

      if (!this.auctionOrder && !this.hasSelectAll) {
        const showPaymentMethods = [];
        this.paymentMethodsStore.data.forEach(item => {
          if (this.store.data.orderable.available_payments.includes(item)) {
            showPaymentMethods.push(item);
          }
        });
        this.paymentMethodsStore.data = showPaymentMethods;
      }

      // 关闭自提之后，店铺订单默认选中运输
      if (!this.isShowSelfPick) {
        this.$set(this.store, 'params.is_self_pick', false);
      }

      if (this.selfPick) {
        this.showReceiverInfo = false;
      }

      const { status, addressId } = this.$route.query;
      // 判断支付状态
      switch (status) {
        case 'captured':
          if (this.auctionOrder) {
            this.$message({ type: 'success', message: this.$t('payAuctionSuccess') });
          } else {
            this.$message({ type: 'success', message: this.$t('paySuccess') });
          }
          break;
        case 'cancelled':
          this.$message({ type: 'error', message: this.$t('payFailure') });
          break;
      }
      if (status) {
        return this.$router.replace({ query: {} }).catch(err => err);
      }
      // 修改了收货地址，更新订单信息
      if (addressId) {
        const { data } = await this.$fly.get(`addresses/${addressId}`);
        this.addressInfo = data;
        this.store.data.address_info = data;
        this.store.updateParams({ address_info: data });
        await this.$autoLoading(this.store.updateOrder());
      }
    }

    async fetchData() {
      const { id } = this.$route.params;
      const { sleep } = this.$route.query;
      if (sleep) {
        await this.$sleep(1000);
      }
      this.store = new OrderDetailStore(id);
      return this.store.fetchData();
    }

    handleShowChangeAddress() {
      if (!this.canChangeAddress) {
        return;
      }
      return this.$router.push({ name: 'myAddressList', query: { order_id: this.$get(this.store, 'data.id') } });
    }

    async handleShowPaymentMethods() {
      if (!this.authStore.user.region) {
        await this.$alert(this.$t('fillInUserRegion'));
        this.$router.push({ name: 'mySettings' });
      } else if (this.waitBuyerPay) {
        this.showPaymentMethods = true;
      }
    }

    handleConfirmMethod(method, directPay) {
      this.store.updateParams({
        payment_method: method
      });
      if (directPay) {
        this.handlePayment();
      }
    }

    // 选择物流
    selectLogist(val) {
      this.store.updateParams({
        transport_company_id: _.isBoolean(val) ? null : val,
        is_self_pick: _.isBoolean(val) ? !val : false
      });
      this.showReceiverInfo = Boolean(val);
    }

    async handleConfirmReceiverInfo() {
      const { id } = this.$route.params;
      if (this.isNoSelectLogist) {
        this.$message({ type: 'warning', message: this.$t('selectShipCompany') });
        return;
      }
      await this.$confirm(this.$t('isConfirmReceiverInfo'));
      this.$autoLoading(async () => {
        await this.store.updateOrder();
        await this.$fly.put(`orders/${id}/confirm_logistic_info`);
        await this.store.fetchData();
      });
    }

    async handlePayment() {
      // 如果没有上传任何证件，没有证件有效期，提示用户填写后再支付
      const { id_card_no, passport_no, driving_license_no } = this.$authStore.user;
      if (!id_card_no && !passport_no && !driving_license_no) {
        await this.$confirm(this.$t('uploadTip'));
        return this.$router.push({ name: 'myAddress' });
      }
      if (this.$authStore.isLoadEffectiveDate) {
        await this.$confirm(this.$t('uploadValidityPeriod'));
        return this.$router.push({ name: 'myAddress' });
      }
      if (this.isWaitBuyerPay) {
        if (!this.showReceiverInfo) {
          this.store.updateParams({ address_info: '' });
        } else {
          this.store.updateParams({ address_info: this.addressInfo });
        }
        if (this.$get(this.store, 'params.payment_method') && (!this.showReceiverInfo || this.$get(this.store, 'params.address_info'))) {
          await this.$autoLoading(this.store.updateOrder());
        }
      }
      switch (true) {
        case this.disabledConfirmPay:
          return;
        case this.showReceiverInfo && !this.address.trim():
          this.$message({ type: 'warning', message: this.$t('fillInDeliveryAddress') });
          return;
        case !this.$get(this.store, 'params.payment_method'):
          this.directPay = true;
          this.showPaymentMethods = true;
          return;
        case !this.includesPayment:
          this.$message({ type: 'warning', message: this.$t('reSelectPay') });
          return;
        case this.isNoSelectLogist:
          this.$message({ type: 'warning', message: this.$t('selectShipCompany') });
          return;
        case this.$get(this.store, 'params.payment_method') === 'OfflinePayment': {
          const { data } = await this.$fly.get('pages/offline_payment_info');
          this.customPopContent = data;
          this.dialogVisible = true;
          return;
        }
        case this.$get(this.store, 'params.payment_method') === 'RemittancePayment': {
          const { data } = await this.$fly.get('pages/remittance_payment_info');
          this.customPopContent = data;
          this.dialogVisible = true;
          return;
        }
        case this.store.isOnlinePayment && this.$get(this.store, 'data.max_margin_amount') > 0 && this.isWaitBuyerPay:
          this.showConfirmDeduction = true;
          return;
        case this.store.isOnlinePayment:
          return this.$autoLoading(this.store.paymentOrder());
      }
    }

    handleShowPaymentDeduction () {
      this.showPaymentDeduction = true;
      this.showConfirmDeduction = false;
    }

    handleDirectPay() {
      this.$autoLoading(this.store.paymentOrder());
      this.showConfirmDeduction = false;
    }

    // 抵扣余额支付
    async handleConfirmDiscount(amount) {
      this.showPaymentDeduction = false;
      this.store.updateParams({
        margin_amount: amount
      });
      this.$autoLoading(async () => {
        await this.store.updateOrder();
        await this.store.paymentOrder();
      });
    }

    // 联系客服(平台管理员)
    async handleContact() {
      const { data } = await this.$autoLoading(this.$fly.post('message_channels/get_by_id_or_target', {
        target_type: 'Order',
        target_id: this.$get(this.store, 'data.id')
      }));
      this.$router.push({ name: 'messageChannelDetail', params: { id: data.id } });
    }

    // 拍品支付前可以联系客服
    async auctionHandleContact() {
      const { transport_company_id } = this.$get(this.store, 'data');
      /**
       * 拍卖会订单：
       * 1、wait_buyer_pay_logistic、wait_seller_send_goods、wait_buyer_confirmed_goods这三个状态联系物流
       * 2、线下支付联系平台管理员
       * 3、其他的联系拍卖行管理员（2和3具体联系谁由get_by_id_or_target接口判断）
       * 店铺订单：
       * 都联系拍卖行管理员
       */

      // 订单类型优先级比订单状态高
      if ((!this.auctionOrder || ['wait_buyer_pay_logistic', 'wait_seller_send_goods', 'wait_buyer_confirmed_goods'].includes(this.dataStatus)) && transport_company_id) {
        let communicator_type = this.auctionOrder ? 'TransportCompany' : 'AuctionHouse';
        let communicator_id = this.auctionOrder ? this.$get(this.store, 'data.transport_company.id') : this.$get(this.store, 'data.auction_house_id');
        const { data } = await this.$autoLoading(this.$fly.post('message_channels/contact_service', {
          target_type: 'Order',
          target_id: this.$get(this.store, 'data.id'),
          communicator_type,
          communicator_id
        }));
        this.$router.push({ name: 'messageChannelDetail', params: { id: data.id } });
      } else {
        this.handleContact();
      }
    }

    // 确认收货
    async handleConfirmGoods() {
      const { id } = this.$route.params;
      await this.$confirm(this.$t('confirmGoods'));
      this.$autoLoading(async () => {
        await this.$fly.put(`orders/${id}/receive`);
        await this.store.fetchData();
      });
    }

    newEvaluation() {
      const { id, auction_house_id } = this.store.data;
      this.$router.push({ name: 'newEvaluation', query: { order_id: id, auction_house_id: auction_house_id } });
    }

    getImage(html) {
      return new Promise((resolve, reject) => {
        html2canvas(html, {
          allowTaint: true,
          useCORS: true,
        }).then(function(canvas) {
          const url = canvas.toDataURL('image/jpeg');
          const image = new Image();
          image.src = url;
          resolve(image);
        }).catch(reject);
      });
    }

    getPdf() {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        const img = await this.getImage(this.$refs.pdfBox);
        const meta = document.querySelector('meta[name="viewport"]');
        const metaContent = meta.content;
        meta.setAttribute('content', 'width=1920px,initial-scale=1.0,user-scalable=no');
        const pdfContainer = document.createElement('div');
        pdfContainer.appendChild(img);
        const doc = new jsPDF();
        const pWidth = doc.internal.pageSize.width;
        pdfContainer.style.width = '1920px';
        const margin = 5;
        const scale = (pWidth - margin * 2) / img.width;
        doc.html(pdfContainer, {
          image: { type: 'jpeg', quality: 1 },
          html2canvas: {
            scale,
            windowWidth: 1920,
          },
          callback: (doc) => {
            meta.setAttribute('content', metaContent);
            doc.save(`${this.$get(this.store, 'data.no')}.pdf`, { returnPromise: true })
              .then(resolve)
              .catch(reject);
          },
          x: margin,
          y: margin
        });
      });
    }

    @Vue.autoLoading
    async handleDownPdf() {
      this.pdfLoading = true;
      try {
        await this.$nextTick();
        await this.getPdf();
      } catch (e) {
        throw new Error(e);
      } finally {
        this.pdfLoading = false;
      }
    }
  }
</script>

<style lang="scss" scoped>
  /deep/ .dialog-change-address {
    border-radius: 19px;

    .el-dialog {
      border-radius: 19px;
    }

    .el-dialog__header {
      padding: 25px 0 15px;
      font-weight: 500;
      font-size: 16px;
    }

    .el-dialog__body {
      padding: 0 40px 20px;

      @include media-xs-only {
        padding: 0 10px 10px;
      }

      .btn-wrapper {
        margin: 30px;

        @include media-xs-only {
          margin: 20px 12px 10px;
        }

        .btn-confirm {
          height: 38px;
          font-size: 15px;
          background: $primary;
        }
      }
    }
  }

  .page-order-detail {
    /deep/ .dialog-page-order-detail {
      display: flex;
      justify-content: center;
      align-items: center;

      .el-dialog {
        border-radius: 19px;

        @include media-sm-and-up {
          width: 352px !important;
          margin-top: 15vh !important;
        }

        .el-dialog__body {
          padding-bottom: 16px;
          text-align: center;
        }

        .el-dialog__footer {
          padding-bottom: 37px;

          .primary-btn {
            height: 45px;
            font-size: 16px !important;

            @include media-sm-and-up {
              height: 38px;
              font-size: 15px !important;
            }
          }
        }
      }
    }
  }

  .submit-btn {
    font-size: 18px;
    color: #fff;

    &.primary {
      background: $primary;
    }
  }

  .container {
    line-height: 21px;

    .pdf-box {
      font-variant: normal;

      &.loading {
        .order-batch-box {
          .order-batch-action {
            visibility: hidden;
          }
        }
      }
    }

    .title-wrapper {
      margin-top: 12px;

      @include media-sm-and-up {
        padding-bottom: 30px !important;
      }

      .title {
        font-weight: bold;
        font-size: 18px;
      }

      .status {
        color: #999;

        .icon-status {
          width: 20px;
          height: 20px;
          padding-right: 10px;
        }
      }
    }

    .item {
      padding: 16px 0;
      border-bottom: 1px solid #ddd;

      &:nth-last-child(1),
      &:nth-last-child(2) {
        border-bottom: none;
      }

      &.hint-wrap {
        display: flex;
        justify-content: center;

        .hint-box {
          padding: 10px 20px;
          color: #000;
          background: rgba(192, 192, 192, 0.2);
        }
      }

      .info-title {
        min-width: 120px;
        font-weight: 800;

        &.name {
          padding: 12px 0 8px 1px;
        }
      }

      .info-content {
        color: #666;

        .icon-payment {
          width: 20px;
          height: 20px;
          padding-right: 10px;
        }
      }

      .component-order-item {
        cursor: pointer;
      }

      .point {
        cursor: pointer;
      }

      .address {
        padding-left: 1px;
        font-size: 12px;
        color: $gray;
      }

      .logist {
        margin-top: 14px;

        .logist-cover {
          width: 75px;
          height: 75px;
          border-radius: 50%;
          box-shadow: 0px 4px 18px 2px rgba(4, 0, 0, 0.1);
          object-fit: cover;
        }

        .logist-content {
          margin: 0 37px 0 19px;

          .logist-name {
            color: #000;
          }

          .logist-desc {
            margin-top: 6px;
            font-size: 12px;
            line-height: 16px;
            color: #999;

            @include text-overflow(3);
          }
        }

        .icon-arrow-right {
          width: 12px;
          height: 12px;
        }
      }

      .no-shipping {
        font-size: 12px;
      }

      .submit-btn {
        margin: 17px auto;

        &.disabled {
          color: #fff;
          background: #ccc;
          pointer-events: none;
        }
      }

      .prompt {
        color: #999;
      }

      .icon {
        width: 12px;
        height: 12px;
        margin-left: 6px;
      }

      .select {
        .select-item {
          padding: 14px 0;
          border-bottom: 1px solid #ddd;

          &:last-of-type {
            padding: 14px 0 0;
            border-bottom: none;
          }

          .selector {
            position: relative;
            width: 16px;
            height: 16px;
            border: 1px solid #ccc;
            border-radius: 50%;

            &.selected::after {
              position: absolute;
              top: 50%;
              left: 50%;
              display: block;
              width: 8px;
              height: 8px;
              border-radius: 50%;
              background: #000;
              transform: translate(-50%, -50%);
              content: '';
            }
          }

          .content {
            margin-left: 17px;
            font-size: 13px;
          }

          .icon {
            padding-left: 8px;
          }
        }
      }

      .closed-tip {
        padding-top: 12px;
        color: #999;
      }

      .contact {
        margin-right: 10px;
        color: $gray;
        cursor: pointer;

        &:hover {
          color: $primary;
        }

        &.link {
          text-decoration-line: underline;
        }
      }

      .order-batch-box {
        margin-top: 10px;
        color: #666;

        .order-batch-action {
          padding: 4px 12px;
          margin-left: 32px;
          border: 1px solid #666;
          color: #666;

          @include media-xs-only {
            margin-left: 12px;
          }

          &.pay {
            border-color: $primary;
            color: $primary;
          }
        }
      }
    }

    .price-detail-wrap {
      margin-top: 10px;

      /deep/.subtotal {
        padding: 0;
        margin-top: 0.2rem;
        border: 0;
      }

      /deep/ .info-title {
        font-size: 12px;
      }
    }

    .pay-tip {
      margin-top: 10px;
      font-size: 12px;
      color: #bb4353;
    }
  }
</style>
