<i18n src="./locales.json"></i18n>

<template>
  <div class="wrapper flex">
    <v-title :value="$t('wallet')"/>
    <common-sidebar class="hidden-xs-only" style="zIndex: 2;"/>
    <div class="mask" @click="showRatio = false" v-show="showRatio"></div>
    <div class="container">
      <div class="flex item-center content-between">
        <div class="title">{{ $t('wallet') }}</div>
        <router-link class="bills" :to="{ name: 'bills' }">{{ $t('bills') }}></router-link>
      </div>

      <div class="main flex column item-center">
        <div class="flex item-center content-center" style="position: relative;">
          <div class="label no-shirk">{{ $t('currentAmount') }}</div>
          <!-- 选择比例框 -->
          <div v-if="value" class="input-wrap flex column">
            <div class="input flex item-center content-center" @click="showRatio = !showRatio">
              <span type="text" class="selcet-input">{{ $get(marginTypes, `${value - 1}.label`) }}</span>
              <img class="icon-arrow" :class="{ rotate: showRatio }" src="@/assets/icon-tangle-down2.png" mode="widthFix" />
            </div>
            <div class="type-content" v-show="showRatio">
              <div
                class="type-item"
                :class="{ selected: item.value === value }"
                v-for="item in marginTypes"
                :key="item.value"
                @click="handleSelectedType(item.value)"
              >{{ item.label }}</div>
            </div>
          </div>
        </div>
        <div class="amount flex item-center">
          <el-select v-model="selectCurrency" class="no-shirk" :popper-append-to-body="false">
            <el-option
              v-for="currency in ['jpy', 'hkd']"
              :key="currency"
              :value="currency"
              :label="currency"
            ></el-option>
          </el-select>
          <div class="number">
            {{ $get(currentQuota, `available_bidding_amount[${value}]`) | currencyFormat }}
          </div>
        </div>
        <div v-if="auctionLimitTip" class="auction-limit-tip">{{ auctionLimitTip }}</div>
      </div>

      <div class="operation">
        <div class="btn primary-btn" @click="handleNavCharge">{{ $t('charge') }}</div>
        <div v-if="callback && !callback.includes('mine/wallet')" class="btn primary-btn-o" @click="handleReturn">{{ $t('return') }}</div>
      </div>

      <div v-if="$get(currentQuota, 'all_freezed_amount')" class="hint">
        <span>{{ $t('frozenAmount') }}</span>
        <span class="text-uppercase">{{ selectCurrency }}</span>
        {{ $get(currentQuota, 'all_freezed_amount') | currencyFormat}}
        <i18n v-if="$get(currentQuota, 'all_freezed_bidding_amount')" path="frozenBiddingAmount" tag="span">
          <span place="frozenAmount">
            <span class="text-uppercase">{{ selectCurrency }}</span>
            {{ $get(currentQuota, 'all_freezed_bidding_amount') | currencyFormat}}
          </span>
        </i18n>
      </div>

      <hint-tip :selectCurrency="selectCurrency" class="hint configurable" />
    </div>
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';
  import { authStore, settingsStore } from '@/store';
  import HintTip from './components/hint-tip.vue';
  import _ from 'lodash';

  @Component({ components: { 'hint-tip': HintTip } })
  export default class Wallet extends Vue {
    authStore = authStore
    settingsStore = settingsStore
    showRatio = false

    marginTypes = [
      { value: '1', label: '1 : 1' },
      { value: '2', label: '1 : 2' },
      { value: '3', label: '1 : 3' },
    ]
    value = ''
    auctionData = {}

    get selectCurrency() {
      return this.$route.query.selectCurrency || 'jpy';
    }

    set selectCurrency(value) {
      this.$router.replace({ query: { ...this.$route.query, selectCurrency: value } });
    }

    get auctionLimitTip() {
      const filter = this.$options.filters;
      const { title, margin_ratio, quotas = [] } = this.auctionData;
      if (!quotas.length) { return null; }

      const currentQuota = quotas.find(item => item.currency === this.selectCurrency);
      const biddingAmount = filter.currencyFormat(this.$get(currentQuota, `available_bidding_amount[${margin_ratio}]`));
      const selectCurrency = _.toUpper(this.selectCurrency);

      return {
        zh_cn: `您在${title}拍卖会上的额度为${selectCurrency} ${biddingAmount}`,
        zh_hk: `您在${title}拍賣會上的額度為${selectCurrency} ${biddingAmount}`,
        en: `Your quota at ${title} auction is ${selectCurrency} ${biddingAmount}`,
        ja: `${title}オークションでのあなたのクォータは${selectCurrency} ${biddingAmount}です`
      }[this.$i18n.locale];
    }

    get callback() {
      return this.$route.query.callback;
    }

    get currentQuota() {
      const { quotas } = this.$get(this.authStore, 'user');
      return quotas.find(item => item.currency === this.selectCurrency);
    }

    async created() {
      this.authStore.fetchData();

      // 目前通过URL携带的status参数判断支付结果，后期可能改为API查询
      const { status, type, auctionId, pay_result } = this.$route.query;

      // 从拍卖会详情/拍卖直播间点击【增加额度】需要显示这个拍卖会的额度
      if (auctionId) {
        const [{ data }, { data: { quotas } }] = await Promise.all([
          this.$fly.get(`/auctions/${auctionId}`),
          this.$fly.get('mine/auction_number', { auction_id: auctionId })
        ]);
        this.auctionData = data;
        _.merge(this.auctionData, { quotas });
      }

      this.value = type || this.auctionData.margin_ratio || '3';

      // 日币支付
      switch (status) {
        case ['captured', 'uncaptured']: {
          const availableAmount = this.$get(authStore, `user.available_bidding_amount.${type}`);

          if (availableAmount && availableAmount === authStore.user.available_bidding_amount[this.value]) {
            this.$message({ type: 'error', message: this.$t('amountPrompt') });
          } else {
            this.$message({ type: 'success', message: this.$t('chargeSuccess') });
          }
          break;
        }
        case 'cancelled':
          this.$message({ type: 'error', message: this.$t('chargeFailure') });
          break;
      }

      // 港币支付
      if (pay_result) {
        if (pay_result === 'success') {
          this.$message({ type: 'success', message: this.$t('chargeSuccess') });
        } else {
          this.$message({ type: 'error', message: this.$t('chargeFailure') });
        }
      }

      // 去除query上的status状态，避免重复出现message
      if (['captured', 'uncaptured', 'cancelled'].includes(status) || pay_result) {
        const replaceQuery = _.omit(this.$route.query, ['status', 'pay_result']);
        return this.$router.replace({ query: replaceQuery }).catch(err => err);
      }
    }

    handleSelectedType(value) {
      this.showRatio = false;
      if (+this.value === +value) { return; }
      this.value = value;
      this.$router.replace({ query: { ...this.$route.query, type: this.value } });
    }

    handleNavCharge() {
      // 支付完成点击返回需要跳转过去的页面
      let callback = this.$route.query.callback || this.$router.prevRoute.fullPath;

      this.$router.push({ name: 'charge', query: { selectCurrency: this.selectCurrency, callback } });
    }

    handleReturn() {
      this.$router.replace(this.callback);
    }
  }
</script>

<style lang="scss" scoped>
  .mask {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .title {
    margin: 28px 0 25px;
    font-weight: 500;
    font-size: 18px;
  }

  .bills {
    z-index: 2;
    font-size: 11px;
    color: #666;
    cursor: pointer;
  }

  .main {
    margin: 70px 0 110px;

    @include media-sm-and-up {
      margin: 50px 0;
    }

    .label {
      width: 140px;
      padding-bottom: 12px;
      font-weight: 500;
      font-size: 15px;
      text-align: center;
    }

    .input-wrap {
      position: absolute;
      z-index: 2;
      top: 0px;
      left: 160px;
      cursor: pointer;

      .input {
        width: 60px;
        padding: 2px 0 2px 8px;
        border: 1px solid #ddd;
        border-radius: 24px;
        font-size: 12px;
        text-align: center;

        .selcet-input {
          display: inline-block;
          margin-right: 4px;
        }

        .icon-arrow {
          width: 8px;

          &.rotate {
            transform: rotate(180deg);
          }
        }
      }

      .type-content {
        width: 60px;
        margin-top: 4px;
        border: 1px solid #ddd;
        border-radius: 4px;
        overflow: hidden;
        text-align: center;
        background: #fff;
        box-shadow: 0 0 11px 2px rgba(162, 162, 162, 0.20);

        .type-item {
          padding: 6px 0;

          &:hover {
            font-weight: 500;
            background: #f5f7fa;
          }

          &.selected {
            font-weight: 500;
            background: #f5f7fa;
          }
        }
      }
    }

    .amount {
      margin-top: 6px;

      .currency {
        margin-top: 5px;
        font-weight: 500;
        font-size: 20px;
      }

      .number {
        width: 150px;
        margin-left: 8px;
        font-weight: 500;
        font-size: 35px;
        text-align: center;
        color: $primary;
      }
    }

    .auction-limit-tip {
      margin-top: 10px;
      color: #bb4353;
    }
  }

  .operation {
    position: relative;
    z-index: 2;

    @include media-sm-and-up {
      display: flex;
      justify-content: center;
    }

    .btn {
      margin: 16px auto;
      font-weight: 500;
      font-size: 18px;

      &.primary-btn {
        @include media-sm-and-up {
          margin-right: 10px;
        }
      }

      @include media-sm-and-up {
        min-width: 225px;
        margin: 0;
      }
    }
  }

  .hint {
    margin-top: 40px;
    font-size: 12px;
    line-height: 21px;
    white-space: pre-wrap;
    color: #666;

    &.configurable {
      margin: 10px 0 20px;
    }
  }

  /deep/.el-select {
    .el-input__inner {
      width: 100px;
      border: none;
      text-transform: uppercase;
    }

    .el-select-dropdown__item {
      text-transform: uppercase;
    }
  }
</style>
