<template>
  <div class="component-tab-bar">
    <div class="tabbar">
      <!-- 首页 -->
      <router-link class="tab" :to="{ name: 'home' }" replace>
        <img class="icon" :src="current === 'home' ? require('@/assets/tabbar/home-selected.png') : require('@/assets/tabbar/home.png')" />
        <div :class="['text', { active: current === 'home' }]">{{ $t('home') }}</div>
      </router-link>
      <!-- 商城 -->
      <router-link v-if="isDomainHouse" class="tab" :to="{ name: 'store' }" replace>
        <img class="icon" :src="current === 'store' ? require('@/assets/tabbar/store-selected.png') : require('@/assets/tabbar/store.png')" />
        <div :class="['text', { active: current === 'store' }]">{{ $t('store') }}</div>
      </router-link>
      <!-- 拍卖 -->
      <router-link v-if="isDomainHouse" class="tab" :to="{ name: 'auctions' }" replace>
        <img class="icon" :src="current === 'auctions' ? require('@/assets/tabbar/auctions-selected.png') : require('@/assets/tabbar/auctions.png')" />
        <div :class="['text', { active: current === 'auctions' }]">{{ $t('auctions') }}</div>
      </router-link>
      <!-- 专题 -->
      <router-link v-if="isDomainHouse || (!isDomainHouse && $get($auctionHouseStore, 'domainHouseDetail.custom_pages_count'))" class="tab" :to="{ name: 'topics'}" replace>
        <img class="icon" :src="current === 'topics' ? require('@/assets/tabbar/topic-selected.png') : require('@/assets/tabbar/topic.png')" />
        <div :class="['text', { active: current === 'topics' }]">{{ $t('topic') }}</div>
      </router-link>
      <!-- 导览 -->
      <router-link v-if="!isDomainHouse && $get($auctionHouseStore, 'domainHouseDetail.guides_count')" class="tab" :to="{ name: 'guides'}" replace>
        <img class="icon" :src="current === 'guides' ? require('@/assets/tabbar/guide-selected.png') : require('@/assets/tabbar/guide.png')" />
        <div :class="['text', { active: current === 'guides' }]">{{ $t('guide') }}</div>
      </router-link>
      <!-- 直播 -->
      <router-link v-if="isDomainHouse" class="tab" :to="{ name: 'LiveBroadcasts'}" replace>
        <img class="icon" :src="current === 'LiveBroadcasts' ? require('@/assets/tabbar/live-selected.png') : require('@/assets/tabbar/live.png')" />
        <div :class="['text', { active: current === 'LiveBroadcasts' }]">{{ $t('live') }}</div>
      </router-link>
      <!-- 设定 -->
      <router-link class="tab" :to="{ name: 'mine'}" replace>
        <img class="icon" :src="current === 'mine' ? require('@/assets/tabbar/mine-selected.png') : require('@/assets/tabbar/mine.png')" />
        <div :class="['text', { active: current === 'mine' }]">{{ $t('mine') }}</div>
      </router-link>
    </div>
    <!-- 占位块 -->
    <div class="block"></div>
  </div>
</template>

<script>
  import { Vue, Component, Watch } from 'vue-property-decorator';

  @Component
  export default class TabBar extends Vue {
    current = ''

    get isDomainHouse() {
      return !this.$auctionHouseStore.domainHouse;
    }

    @Watch('$route', { immediate: true })
    handler(val) {
      this.current = val.name;
    }
  }
</script>

<style lang="scss" scoped>
  .component-tab-bar {
    z-index: --fixed-z-index;
  }

  .tabbar {
    position: fixed;
    z-index: 1000;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: space-around;
    width: 100%;
    padding: 12px 0 calc(8px + env(safe-area-inset-bottom));
    background: #fff;
    box-shadow: 0px 4px 18px 2px rgba(4, 0, 0, 0.1);

    .tab {
      display: flex;
      flex-direction: column;
      align-items: center;

      .icon {
        width: 20px;
        height: 20px;
      }

      .text {
        margin-top: 4px;
        font-size: 12px;
        color: #999;

        &.active {
          color: #000;
        }
      }
    }
  }

  .block {
    height: 60px;
  }
</style>
