<i18n src="./locales.json"></i18n>

<template>
  <div class="wrapper flex">
    <v-title :value="$t('refundApplications.title')"/>
    <common-sidebar class="hidden-xs-only"/>
    <div class="container">
      <div class="title">{{ $t('refundApplications.title') }}</div>

      <div class="filter-wrap flex content-end">
        <withdrawCurrencyFilter showStatus />
      </div>

      <div class="list">
        <div class="bill-item" v-for="item in store.data" :key="item.id">
          <div class="flex item-center content-between">
            <div class="content">
              <div class="name">{{ $t(`refundApplications.${item.status}`) }}</div>
              <div class="time">{{ item.created_at | timeFormat }}</div>
            </div>
            <div class="right">
              <div class="reason">{{ item.reason }}</div>
              <div class="amount">
                <span class="text-uppercase">{{ item.currency }}</span>
                {{ item.amount | currencyFormat }}
                ({{ $t('refundApplications.refundCost') }}：{{ item.refund_cost | currencyFormat }})
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="list-empty" v-if="store.isEmpty">
        <img src="@/assets/icon-select-empty.png" />
        <div>{{ $t('listEmpty') }}</div>
      </div>

      <pagination class="pagination" :store="store"/>
    </div>
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';
  import { Collection } from '@/store';
  import withdrawCurrencyFilter from './components/withdraw-currency-filter.vue';

  @Component({ components: { withdrawCurrencyFilter } })
  export default class RefundApplications extends Vue {
    store = new Collection({
      fetch: params => this.$fly.get('refund_applications', params)
    })

    @Vue.autoLoading
    created() {
      const { currency_eq, status_eq } = this.$route.query;

      return this.store.fetchData({ currency_eq, status_eq });
    }
  }
</script>

<style lang="scss" scoped>
  .title {
    padding: 28px 0 25px;
    font-weight: 500;
    font-size: 18px;
  }

  .bill-item {
    padding: 12px 0;
    border-bottom: 1px solid #ddd;

    .content {
      flex: 1;

      @include media-sm-and-up {
        display: flex;
        align-items: center;
      }
    }

    .name {
      @include media-sm-and-up {
        min-width: 100px;
      }
    }

    .time {
      margin-top: 6px;
      font-size: 11px;
      color: #999;

      @include media-sm-and-up {
        margin: 0 auto;
        font-size: 13px;
      }
    }

    .right {
      @include media-sm-and-up {
        display: flex;
        align-items: center;
      }
    }

    .reason {
      max-width: 160px;
      text-align: right;

      @include media-sm-and-up {
        min-width: 250px;
        text-align: left;
      }
    }

    .amount {
      font-weight: 500;
      text-align: right;
      color: $primary;

      @include media-sm-and-up {
        min-width: 150px;
      }
    }
  }

  .filter-wrap {
    margin-bottom: 25px;
  }

  .list-empty {
    margin: 46px auto 57px;
    text-align: center;
    color: #999;

    > img {
      width: 65px;
      height: 65px;
      margin-bottom: 36px;
    }
  }

  .pagination {
    margin: 15px 0;
  }
</style>
