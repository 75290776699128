<template>
  <div class="skeleton" v-if="loading">
    <el-progress :percentage="percentage" :show-text="false"/>
    <skeleton-banner/>
    <div class="container">
      <common-title/>
      <div class="scroll-wrapper prod-skeleton-animate">
        <div class="item">
          <skeleton-auction-card/>
        </div>
        <div class="item">
          <skeleton-auction-card/>
        </div>
        <div class="item">
          <skeleton-auction-card/>
        </div>
      </div>

      <common-title/>
      <div class="auction-list">
        <el-row :gutter="30">
          <el-col :xs="24" :lg="12" v-for="i in 6" :key="i">
            <skeleton-auction-item class="item"/>
          </el-col>
        </el-row>
      </div>

      <common-title/>
      <div class="auction-list">
        <el-row :gutter="30">
          <el-col :xs="24" :lg="12" v-for="i in 6" :key="i">
            <skeleton-auction-item class="item"/>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
  import { Component, Vue } from 'vue-property-decorator';
  import Skeleton from '@/components/skeleton/mixins';

  @Component({ mixins: [Skeleton] })
  export default class IndexSkeleton extends Vue {
    speed = 5
  }
</script>

<style lang="scss" scoped>
  .container {
    /deep/ .component-title {
      margin-top: 20px;

      @include media-sm-and-up {
        margin: 60px 0 40px;
      }
    }
  }

  .scroll-wrapper {
    display: flex;
    margin: 10px 0 0;

    .item {
      margin: 0 5px;
      cursor: pointer;

      .auction-card-wrapper {
        min-height: 100%;
      }
    }
  }

  .auction-list {
    padding: 10px 0 5px;

    @include media-sm-and-up {
      padding: 10px 0 30px;
    }

    .item {
      display: block;
      margin-bottom: 10px;
    }
  }
</style>
