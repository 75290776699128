<i18n>
{
  "zh_cn": {
    "count": "件"
  },
  "zh_hk": {
    "count": "件"
  },
  "en": {
    "count": "pieces"
  },
  "ja": {
    "count": "点"
  }
}
</i18n>

<template>
  <div class="component-store-category-item" @click="handleNavProducts(category)">
    <div class="inner">
      <div class="cover-wrapper">
        <auto-height
          :height="autoHeight"
          :cover="$get(category, 'image.url')"
          :oss-img="{ resize: { w: 1000 } }"
        >
          <product-countdown :data="category" class="product-countdown"/>
        </auto-height>
      </div>
      <div class="category-info">
        <div class="title text-overflow">{{ category.title }}</div>
        <div class="auction-info">
          <div v-if="category.started_bidding_time" class="text-overflow">{{$t('auctionTime')}}: {{ category.started_bidding_time | timeFormat }}</div>
          <div v-if="category.product_count" class="text-overflow">{{$t('lotQty')}}: {{ category.product_count }}{{ $t('count') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { Vue, Component, Prop } from 'vue-property-decorator';

  @Component
  export default class StoreCategoryItem extends Vue {
    @Prop({ type: String, default: '50%' }) autoHeight;
    @Prop({ type: Object, default: () => ({}) }) category;

    handleNavProducts({ id }) {
      this.$router.push({
        name: 'products',
        query: {
          type: 'store_category',
          id,
        }
      });
    }
  }
</script>

<style lang="scss" scoped>
  .component-store-category-item {
    position: relative;
    height: 0;
    padding-bottom: 80%;
    margin-bottom: 10px;
    border-radius: 4px;
    overflow: hidden;
    background: #fff;
    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.09);
    cursor: pointer;
    user-select: none;

    .inner {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;

      .cover-wrapper {
        box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.06);

        .product-countdown {
          position: absolute;
          z-index: var(--absolute-z-index);
          bottom: 0;
          left: 0;
          padding: 0 5px;
          margin: 8px 10px;
          border-radius: 5px;
          background: #ffffffc2;
        }
      }

      .category-info {
        height: inherit;
        padding: 20px 5px 39px 10px;
        font-size: 12px;
        color: #666;

        .title {
          margin-bottom: 10px;
          font-weight: bold;
          font-size: 18px;
          color: #000;
        }
      }
    }
  }
</style>
