<i18n src="./locales.json"></i18n>

<template>
  <div class="flex">
    <common-sidebar class="hidden-xs-only" />
    <div class="container">
      <v-title :value="$t('myCollection')"/>
      <div class="title">{{ $t('myCollection') }}</div>
        <div class="list-wrapper" v-if="followStore.isFulfilled">
          <el-row :gutter="20" justify="space-between">
            <el-col
              :xs="24"
              :sm="12"
              v-for="item in followStore.data"
              :key="item.id"
            >
              <target-type-card
                :categoryName="$get(item, 'target.category_name')"
                :targetType="$get(item, 'target_type')"
                :title="$get(item, 'target.title')"
                :cover="$get(item, 'target.cover.url')"
                :number="$get(item, 'target.number')"
                :id="$get(item, 'target.id')"
                :productQuery="productQuery"
                collection
              >
                <app-countdown
                  :end-time="$get(item, 'target.started_bidding_time')"
                  :current-time="$get(item, 'target.current_time')"
                  endHide plusOneMinute
                >
                  <template v-slot="{ day, hour, minute }">
                    <div class="countdown text-overflow" v-if="item.target_type === 'AuctionItem'" >
                      {{$t('timeLeft')}}
                      <span class="time">{{ day }} </span>{{ $t('day') }}
                      <span class="time">{{ hour }} </span>{{ $tc('hour', hour) }}
                      <span class="time">{{ minute }} </span>{{ $tc('minute', minute) }}
                    </div>
                  </template>
                </app-countdown>
                <!-- 商品倒计时区分方式和商品card一样,区分距开始，距结束 -->
                <product-countdown v-if="item.target_type === 'Product'" :data="item.target"/>

                <!-- 拍品价格显示 -->
                <div v-if="item.target_type === 'AuctionItem'" class="price-wrap flex item-end content-between">
                  <div>
                    <!-- 有落锤或强制落锤，显示成交日期 -->
                    <div
                      v-if="$get(item, 'target.ended_bidding_time') && ['hammered', 'force_hammered'].includes($get(item, 'target.status'))"
                      style="padding-bottom: 0.16667rem;"
                      class="text-overflow"
                    >
                      {{ $t('dealTime') }}: {{ $get(item, 'target.ended_bidding_time') | timeFormat('YYYY-MM-DD') }}
                    </div>
                    <!-- 有落锤或强制落锤，显示成交价格 -->
                    <div v-if="['hammered', 'force_hammered'].includes($get(item, 'target.status'))">
                      <div class="text-overflow">
                        <span>{{$t('dealPrice')}}: </span>
                        <span class="currency text-uppercase">{{$get(item, 'target.currency')}}</span>
                        <span class="price">{{ $get(item, 'target.price') | currencyFormat }}</span>
                      </div>
                      <div class="text-overflow" v-if="!$options.filters.isLocaleCurrency($get(item, 'target.currency'))">
                        <span>{{$t('dealPrice')}}: </span>
                        <span class="currency text-uppercase">{{$t('currency')}}</span>
                        <span class="price">{{ $get(item, 'target.price') | conversionFormat($get(item, 'target.currency')) | currencyFormat }}</span>
                      </div>
                    </div>
                    <!-- 还在拍卖中的拍品，显示当前价格 -->
                    <div v-if="['pending'].includes($get(item, 'target.status'))">
                      <div v-if="$get(item, 'target.price') <= 0">
                        {{ $t('estimatePending') }}
                      </div>
                      <div v-else>
                        {{$t('currentPrice')}}
                        <span class="currency text-uppercase">{{$get(item, 'target.currency')}}</span>
                        <span class="price">{{ $get(item, 'target.price') | currencyFormat }}</span>
                      </div>
                      <div v-if="$get(item, 'target.my_bidding_price')">
                        {{$t('myPrice')}}
                        <span class="currency text-uppercase">{{$get(item, 'currency')}} </span>
                        <span class="price">{{ $get(item, 'target.my_bidding_price') | currencyFormat }}</span>
                      </div>
                    </div>
                    <!-- 如果流拍或强制流拍，显示流拍 -->
                    <div class="aborted" v-if="['aborted', 'force_aborted'].includes($get(item, 'target.status'))">
                      {{$t('aborted')}}
                    </div>
                  </div>
                  <!-- 竞拍数量 -->
                  <div class="sums" v-if="!(['aborted', 'force_aborted'].includes($get(item, 'target.status')))">{{ $get(item, 'target.bidding_prices_count') }}bids</div>
                </div>

                <!-- 商品价格显示 -->
                <div v-else class="price-wrap flex item-end content-between">
                  <div>
                    <!-- 已结束未流拍，显示成交日期 -->
                    <div
                      v-if="isFinishedTime(item) && !$get(item, 'target.was_aborted')"
                      class="show-time text-overflow"
                    >
                      {{ $t('dealTime') }}: {{ $get(item, 'target.ended_bidding_time') | timeFormat('YYYY-MM-DD') }}
                    </div>
                    <!-- 未结束且未成交，显示当前价格 -->
                    <div v-if="!$get(item, 'target.ended_bidding_time') || !isFinishedTime(item)">
                      <div>
                        {{$t('currentPrice')}}
                        <span class="currency text-uppercase">{{$get(item, 'target.currency')}}</span>
                        <span class="price">{{ $get(item, 'target.price') | currencyFormat }}</span>
                      </div>
                      <div v-if="$get(item, 'target.my_bidding_price')">
                        {{$t('myPrice')}}
                        <span class="currency text-uppercase">{{$get(item, 'target.currency')}} </span>
                        <span class="price">{{ $get(item, 'target.my_bidding_price') | currencyFormat }}</span>
                      </div>
                    </div>
                    <!-- 已结束 -->
                    <div v-else>
                      <!-- 流拍 -->
                      <div class="aborted" v-if="$get(item, 'target.was_aborted')">
                        {{ $t('aborted') }}
                      </div>
                      <!-- 显示成交价 -->
                      <div v-else>
                        <div class="text-overflow">
                          <span>{{$t('dealPrice')}}: </span>
                          <span class="currency text-uppercase">{{$get(item, 'target.currency')}}</span>
                          <span class="price">{{ $get(item, 'target.price') | currencyFormat }}</span>
                        </div>
                        <div class="text-overflow" v-if="!$options.filters.isLocaleCurrency($get(item, 'target.currency'))">
                          <span>{{$t('dealPrice')}}: </span>
                          <span class="currency text-uppercase">{{$t('currency')}}</span>
                          <span class="price">{{ $get(item, 'target.price') | conversionFormat($get(item, 'target.currency')) | currencyFormat }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- 竞拍数量 -->
                  <div class="sums" v-if="!$get(item, 'target.was_aborted')">{{ $get(item, 'target.bidding_prices_count') }} bids</div>
                </div>
              </target-type-card>
            </el-col>
          </el-row>
          <pagination routeMode class="pagination" :store="followStore"/>
        </div>
        <div class="list-empty flex column item-center" v-if="followStore.isEmpty">
          <img src="@/assets/icon-follow-empty.png" class="icon-empty">
          <div>{{ $t('followEmpty') }}</div>
        </div>
    </div>
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';
  import { Collection } from '@/store';
  import { fly, initSubscription } from '@/utils';
  import dayjs from 'dayjs';
  import { i18n } from '@/i18n';

  @Component
  export default class myCollection extends Vue {
    followStore = new Collection({
      fetch(params) {
        return fly.get('action_stores', {
          action_type: 'follow',
          target_types: ['AuctionItem', 'Product'],
          ...params
        });
      }
    });
    productQuery = { productType: 'auctionProduct', action_type: 'follow', target_types: ['AuctionItem', 'Product'] }

    received = function (that, result) {
      const { action, data } = result;
      that.followStore.data.forEach(item => {
        if (item.target_type === 'Product' && item.target.id === data.product_id) {
          switch (action.toLowerCase()) {
            case 'bid':
              if (data.current_price > item.target.price) {
                item.target.price = data.current_price;
              }
              return;
            case 'deal':
              item.target.price = data.deal_price;
              return;
            case 'abort':
              item.target.status = data.bid_status;
              item.target.was_aborted = true;
              return;
            case 'delay':
              if (dayjs(data.ended_bidding_time).diff(item.target.ended_bidding_time) > 0) {
                item.target.ended_bidding_time = data.ended_bidding_time;
                item.target.started_bidding_time = data.started_bidding_time;
              }
              return;
          }
        }
      });
    }

    get locale() {
      return i18n.locale;
    }

    get shouldSubscribeWS() {
      return this.followStore.data.filter(item => {
        return !this.isFinishedTime(item) && !this.$get(item, 'target.was_aborted');
      }).length > 0;
    }

    @Vue.autoLoading
    async created() {
      const { page } = this.$route.query;
      this.followStore.params = {
        page
      };
      await this.followStore.fetchData();
      if (this.shouldSubscribeWS) {
        initSubscription(this, [], this.received);
      }
    }

    beforeDestroy() {
      if (this.consumer) {
        this.consumer.disconnect();
      }
    }

    isFinishedTime(item) {
      return dayjs(this.$get(item, 'target.ended_bidding_time')).diff(dayjs(this.$get(item, 'target.current_time'))) < 0;
    }
  }
</script>

<style lang="scss" scoped>
  .container {
    .title {
      margin: 30px 0 31px;
      font-weight: bold;
      font-size: 18px;
      color: #000;

      @include media-sm-and-up {
        margin: 33px 0 34px;
      }
    }

    .list-empty {
      padding: 97px 0;
      color: #999;

      .icon-empty {
        width: 65px;
        height: 65px;
        margin-bottom: 34px;
      }
    }

    .list-wrapper {
      /deep/ .product-countdown {
        @include media-xs-only {
          .countdown {
            font-size: 11px;

            .primary {
              font-size: 16px;
            }
          }
        }
      }

      .currency {
        font-size: 14px;
        color: #000;
      }

      .price {
        margin-left: 2px;
        font-size: 14px;
        color: $primary;
      }

      .countdown {
        font-size: 11px;
        color: $gray;

        span {
          font-size: 16px;
          color: $primary;
        }
      }

      .show-time {
        padding-bottom: 2px;
      }

      .aborted {
        font-size: 13px;
        color: #000;
      }

      .price-wrap {
        @include media-xs-only {
          flex-direction: column;
          align-items: flex-start;
        }
      }

      .sums {
        font-size: 11px;
        color: $gray;

        @include media-xs-only {
          width: 100%;
          font-size: 10px;
          text-align: right;
        }
      }
    }

    .pagination {
      margin: 15px 0;

      @include media-sm-and-up {
        margin: 40px 0;
      }
    }
  }
</style>
