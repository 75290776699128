<template>
  <div class="flex content-center">
    <el-pagination
      background
      layout="prev, pager, next"
      :pager-count="5"
      :page-size="pageSize"
      :total="total"
      @current-change="handlePageChange"
      hide-on-single-page
      v-bind="bindProps"
    />
  </div>
</template>

<script>
  import { Vue, Component, Prop } from 'vue-property-decorator';
  import { Collection } from '@/store';
  import _ from 'lodash';

  @Component
  export default class Pagination extends Vue {
    @Prop(Collection) store;
    @Prop(Boolean) routeMode;

    get bindProps() {
      return this.routeMode ? {
        'current-page': _.get(this.store, 'meta.page', 1)
      } : {};
    }

    get total() {
      return _.get(this.store, 'meta.total', 0);
    }

    get pageSize() {
      return _.get(this.store, 'meta.per_page', 1);
    }

    async handlePageChange(page) {
      if (this.routeMode) {
        this.$router.push({ query: _.merge({}, this.$route.query, { page }) });
        return;
      }
      await this.handleFetchData(page);
      this.$emit('paginationChange');
    }

    @Vue.autoLoading
    handleFetchData(page) {
      return this.store.fetchData({ page });
    }
  }
</script>

<style lang="scss" scoped>
  /deep/ .el-pagination {
    .btn-prev,
    .btn-next,
    .el-pager > li {
      box-sizing: border-box;
      min-width: 25px;
      height: 25px;
      font-size: 12px;
      line-height: 25px;
      background: #dfdfdf;
    }

    &.is-background .el-pager li:not(.disabled).active {
      background: #999;
    }
  }

</style>
