<i18n src="../locales.json"></i18n>

<template>
  <div class="component-auction-price-record">
    <div class="flex content-between section-stat">
      <p class="label">{{ $t(statusMap[auctionItem.status]) }}<span v-if="!isAborted"></span></p>
      <div class="price-wrapper" v-if="!isAborted">
        <span>{{ currencyUpperCase }} </span><span class="price">{{ price | currencyFormat }}</span>
        <span v-if="$t('currency') !== currencyUpperCase">(≈ <span>{{ $t('currency') }} </span><span class="price">{{ price | conversionFormat(currency) | currencyFormat }}</span>)</span>
      </div>
    </div>
    <slot/>
    <div class="section-auction-price-list">
      <template v-if="list.length">
        <div class="flex content-between box-title">
          <span class="title">{{ $t('bidder') }}</span>
          <span class="title">{{ $t('bidPrice') }}({{ currencyUpperCase }})</span>
        </div>

        <div class="price-list" ref="scrollView">
          <div
            class="item flex content-between"
            :class="[{ active: number === $get(item, 'auction_number.number') }, item.status]"
            v-for="item in list" :key="item.id"
          >
            <div class="user-item flex item-center">
              <img src="@/assets/icon-auctioneer.png" />
              <span>{{ $get(item, 'auction_number.number') || (item.status === 'adjusted' ?  $t('priceRevision') : $t('bidOffline')) }}</span>
            </div>
            <span class="price">{{ item.price | currencyFormat }}</span>
          </div>
        </div>
      </template>
      <div class="section-empty" v-else>{{ $t('no_bid_record') }}</div>
    </div>
  </div>
</template>


<script>
  import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
  import _ from 'lodash';

  @Component
  export default class Record extends Vue {
    @Prop({ type: Array, default: () => [] }) list;
    @Prop({ type: Object, default: () => {} }) auctionItem;
    @Prop(String) currency;
    @Prop([String, Number]) number;

    statusMap = {
      pending: 'currentPrice',
      hammered: 'dealPrice',
      force_hammered: 'dealPrice',
      force_aborted: 'aborted',
      aborted: 'aborted'
    }

    get price() {
      const { status, final_price, starting_price } = this.auctionItem;
      return ['hammered', 'force_hammered'].includes(status) ? final_price : (_.get(this.list, '0.price') || starting_price);
    }

    get currencyUpperCase() {
      return (this.currency || '').toUpperCase();
    }

    get isAborted() {
      return ['force_aborted', 'aborted'].includes(this.auctionItem.status);
    }

    @Watch('list.length')
    handlerScrollTop() {
      const scrollElement = this.$refs.scrollView;
      if (scrollElement) {
        this.$nextTick(() => {
          scrollElement.scrollTop = 0;
        });
      }
    }
  }
</script>

<style lang="scss" scoped>
  .section-stat {
    border-bottom: 1px solid $border-light;

    @include media-xs-only {
      padding-bottom: 4px;
    }

    .label {
      margin: 0;
    }

    .price-wrapper {
      font-size: 14px;
      text-align: right;

      .price {
        color: $primary;
      }
    }

    .unit {
      color: #000000;
    }

    small {
      font-size: 12px;
    }
  }

  .section-auction-price-list {
    .box-title {
      padding: 12px 0 15px;
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      color: $gray;

      @include media-xs-only {
        padding: 5px 0;
      }
    }

    .price-list {
      max-height: 250px;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;

      @include media-xs-only {
        padding-bottom: 90px;
      }

      &::-webkit-scrollbar {
        display: none;
      }

      .item {
        margin-bottom: 10px;

        @include media-xs-only {
          margin-bottom: 4px;
          font-size: 10px;
        }

        &.noneffective {
          text-decoration: line-through;
          color: $gray !important;
        }

        &.active {
          color: $primary;
        }
      }

      .user-item {
        img {
          width: 12px;
          height: 12px;
          margin-right: 8px;
        }
      }
    }
  }
</style>
