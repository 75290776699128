<i18n src="../locales.json"></i18n>

<template>
  <div class="item">
    <div class="header">
      <div class="tag">{{ $get(tagMap, $get(item, 'auction.time_type')) }}</div>
      <div class="number">
        {{ $t('auctionNumber') }} <span class="value">{{ item.number }}</span>
      </div>
    </div>
    <div class="content" @click="handleJump">
      <div class="cover-wrapper">
        <auto-height class="prod-skeleton-block" height="50%" :cover="$get(item, 'auction.cover.url')"
          :oss-img="{ resize: { w: 1000 } }" />
      </div>
      <div class="auction-wrapper">
        <div class="name text-overflow-2">{{ $get(item, 'auction.title') }}</div>
        <div class="date">
          <span>{{ $t('auctionTime') }}: </span>
          <span class="value">{{ $get(item, 'auction.start_at') | timeFormat }}</span>
        </div>
      </div>
    </div>
    <div class="footer">
      <span class="current">{{ $t('currentAmount') }}: </span>
      <div
        v-for="(quota, index) in quotas"
        :key="index"
        class="amount-item"
      >
        <span class="currency">{{ $get(currencyMap, quota.currency) }}</span>
        <span class="value">{{ quota.available_bidding_amount | currencyFormat }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class AuctionNumberItem extends Vue {
  @Prop({ type: Object, default: () => { } }) item

  tagMap = {
    finished: this.$t('auctionNumberTag.finished'),
    will_start_auction: this.$t('auctionNumberTag.will_start_auction'),
    in_auction: this.$t('auctionNumberTag.in_auction'),
  }
  currencyMap = {
    hkd: 'HKD',
    jpy: 'JPY',
    cny: 'CNY'
  }

  get quotas() {
    let quotas = this.item.quotas.map(item => {
      return {
        ...item,
        available_bidding_amount: this.$get(item, `available_bidding_amount.${this.item.auction.margin_ratio}`),
      };
    });
    if (!quotas.length) {
      quotas = this.$authStore.user.quotas.map(item => {
        return {
          ...item,
          available_bidding_amount: this.$get(item, `available_bidding_amount.${this.item.auction.margin_ratio}`),
        };
      });
    }
    return quotas;
  }

  handleJump() {
    this.$router.push({ name: 'auctionDetail', params: { id: this.item.auction_id } });
  }
}
</script>

<style lang="scss" scoped>
.item {
  padding: 10px;
  margin-bottom: 11px;
  font-size: 12px;
  box-shadow: 0px 4px 18px 2px rgba(4, 0, 0, 0.1);

  @include media-sm-and-up {
    padding: 15px;
  }
}

.header {
  display: flex;
  justify-content: space-between;

  .tag {
    display: flex;
    align-items: center;
    padding: 0 4px;
    border-radius: 4px;
    color: #fff;
    background-color: $primary;
  }

  .amount {
    display: flex;
    align-items: center;
    padding: 0 4px;
    margin-left: 6px;
    border: 1px solid $gray;
    border-radius: 4px;
    color: $gray;

    @include media-sm-and-up {
      margin-left: 10px;
    }

    .currency {
      margin: 0 4px;
      color: #000;
    }

    .value {
      font-weight: 500;
      font-size: 18px;
      color: #000;
    }
  }

  .number {
    display: flex;
    align-items: center;
    color: $gray;

    .value {
      margin-left: 4px;
      font-weight: 500;
      font-size: 20px;
      color: #000;
    }
  }
}

.content {
  display: flex;
  padding: 10px 0;
  margin-top: 10px;
  border-top: 1px solid $border-light;
  border-bottom: 1px solid $border-light;
  cursor: pointer;

  @include media-sm-and-up {
    padding: 15px 0;
    margin-top: 20px;
  }


  .cover-wrapper {
    width: 40%;
  }

  .auction-wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 15px;

    .name {
      font-weight: 500;
      font-size: 16px;
      color: #000;
    }

    .date {
      display: flex;
      align-items: center;
      color: $gray;

      .value {
        margin-left: 4px;
        color: #000;
      }
    }
  }
}

.footer {
  display: flex;
  margin-top: 15px;
  font-size: 14px;

  .amount-item {
    margin-left: 6px;
  }

  .current {
    color: $gray;
  }

  .currency {
    margin: 0 4px;
    color: #000;
  }

  .value {
    font-weight: 500;
    color: $primary;
  }
}
</style>
