<i18n src="./locales.json"></i18n>

<template>
  <div class="flex">
    <common-sidebar class="hidden-xs-only" />
    <div class="container flex item-center">
      <div class="flex-1">
        <v-title :value="$t('settings')"/>
        <div class="title">{{ $t('settings') }}</div>

        <!-- 个人信息 -->
        <div class="input-item">
          <div class="input-content">
            <div class="item-title">{{ $t('information') }}</div>
            <div class="item flex item-center">
              <div class="label"><span class="star">*</span>{{ $t('lastName') }}</div>
              <el-input
                v-model="form.last_name"
                type="text"
                :placeholder="$t('lastName')"
                @input="errorList.last_name = ''"
                @blur="form.last_name = $event.target.value.trim()"
              />
            </div>
            <div class="error" v-show="errorList.last_name">* {{ errorList.last_name }}</div>
            <div class="item flex item-center">
              <div class="label"><span class="star">*</span>{{ $t('yourName') }}</div>
              <el-input
                v-model="form.name"
                type="text"
                :placeholder="$t('yourName')"
                @input="errorList.name = ''"
                @blur="form.name = $event.target.value.trim()"
              />
            </div>
            <div class="error" v-show="errorList.name">* {{ errorList.name }}</div>
            <div class="item flex item-center">
              <div class="label"><span class="star">*</span>{{ $t('yourRegion') }}</div>
              <el-select v-model="form.region" :placeholder="$t('yourRegion')" @input="errorList.region = ''" >
                <el-option
                  v-for="item in regionsStore.data"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code"
                />
              </el-select>
            </div>
            <div class="error" v-show="errorList.region">* {{ errorList.region }}</div>
          </div>
        </div>

        <!-- 电子邮箱 -->
        <div class="input-item">
          <div class="input-content">
            <div class="item-title">{{ $t('email') }}</div>
            <div class="item flex item-center" v-if="authStore.user.email">
              <div class="label">{{ $t('currentEmail') }}</div>
              <div style="color: #000;">{{ authStore.user.email }}</div>
              <div class="verification">{{ $t('verification') }}</div>
            </div>
            <template v-if="authStore.user.unconfirmed_email">
              <div class="item flex item-center">
                <div class="label">{{ $t('newEmail') }}</div>
                <div style="color: #000;">{{ authStore.user.unconfirmed_email }}</div>
                <div class="verification wait">{{ $t('waitVerification') }}</div>
              </div>
              <div class="warn"><span class="el-icon-warning"></span>{{ $t('warnTips') }}</div>
            </template>
            <div class="item flex item-center">
              <div class="label">{{ authStore.user.email ? $t('newEmail') : $t('email') }}</div>
              <el-input
                v-model="form.email"
                type="email"
                :placeholder="$t('email')"
                @input="validateEmailTip = ''"
                @blur="form.email = $event.target.value.trim()"
              />
            </div>
            <div class="error" v-show="validateEmailTip">* {{ validateEmailTip }}</div>
            <div class="item flex item-center">
              <div class="label">{{ $t('confirmEmail') }}</div>
              <el-input
                v-model="confirmEmail"
                type="email"
                :placeholder="$t('confirmEmail')"
                @blur="confirmEmail = $event.target.value.trim()"
              />
            </div>
            <div class="tips">* {{ $t('emailTips') }}</div>
          </div>
        </div>

        <!-- 密码设置 -->
        <div class="input-item">
          <div class="input-content">
            <div class="item-title">{{ $t('setPassword') }}</div>
            <!-- 手机验证 -->
            <div class="item flex item-center">
              <div class="label">{{ $t('validateCode') }}</div>
              <el-input
                type="number"
                :placeholder="$t('validatePlaceholder')"
                v-model.trim="form.code"
                maxlength="6"
                @input="validateCodeError = ''"
              />
              <no-captcha
                class="hidden-xs-only"
                :disabled="disabledCodeBtn"
                :country-code="authStore.user.country_code"
                :phone="authStore.user.phone"
                event="reset_password"
                @success="sendCodeSuccess"
              >
                <template #default="{ disabled }">
                  <div
                    class="primary-btn-o"
                    :class="{'disabled': disabled}"
                  >
                    <span v-if="disabled">
                      <app-countdown
                        @completed="disabledCodeBtn = false"
                        :end-time="countdownTime"
                      >
                        <template v-slot="{ second }">
                          {{ $tc('countTime', second, { second }) }}
                        </template>
                      </app-countdown>
                    </span>
                    <span v-else>{{ $t('getValidateCode') }}</span>
                  </div>
                </template>
              </no-captcha>
            </div>
            <div class="error" v-show="validateCodeError">* {{ validateCodeError }}</div>
            <no-captcha
              class="hidden-sm-and-up"
              :disabled="disabledCodeBtn"
              :country-code="authStore.user.country_code"
              :phone="authStore.user.phone"
              event="reset_password"
              @success="sendCodeSuccess"
            >
              <template #default="{ disabled }">
                <div
                  class="primary-btn-o"
                  :class="{'disabled': disabled}"
                >
                  <span v-if="disabled">
                    <app-countdown
                      @completed="disabledCodeBtn = false"
                      :end-time="countdownTime"
                    >
                      <template v-slot="{ second }">
                        {{ $tc('countTime', second, { second }) }}
                      </template>
                    </app-countdown>
                  </span>
                  <span v-else>{{ $t('getValidateCode') }}</span>
                </div>
              </template>
            </no-captcha>
            <!-- 密码 -->
            <div class="item flex item-center">
              <div class="label">{{ $t('newPassword') }}</div>
              <el-input
                type="password"
                :placeholder="$t('password')"
                v-model="form.password"
                show-password
                autocomplete="new-password"
                @input="passwordError = ''"
              />
            </div>
            <div class="error" v-show="passwordError">* {{ passwordError }}</div>
            <div class="item flex item-center">
              <div class="label">{{ $t('confirmPassword') }}</div>
              <el-input
                type="password"
                :placeholder="$t('password')"
                v-model="form.password_confirmation"
                show-password
                @input="passwordError = ''"
              />
            </div>
            <div class="tips">* {{ $t('passwordError') }}</div>
          </div>
        </div>

        <div class="submit-btn primary-btn" @click="handleUpdateMine">{{ $t('save') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';
  import { authStore, regionsStore } from '@/store';
  import countries from '@/components/country-selector/countries';
  import countrySelector from '@/components/country-selector';
  import ImgUpload from './components/img-upload';
  import { VALIDATE_EMAIL, VALIDATE_PASSWORD } from '@/constants';
  import _ from 'lodash';
  import dayjs from 'dayjs';

  @Component({
    components: {
      'img-upload': ImgUpload,
      countrySelector
    }
  })
  export default class Settings extends Vue {
    authStore = authStore
    regionsStore = regionsStore
    confirmEmail = ''
    validateEmailTip = ''

    // 密码设置
    passwordError = ''
    validateCodeError = ''
    isFocus = false;
    disabledCodeBtn = false;
    countdownTime = dayjs().toString();

    form = {
      last_name: '',
      name: '',
      region: '',
      email: '',
      code: '',
      password: '',
      password_confirmation: ''
    }

    errorList = {
      last_name: '',
      name: '',
      region: ''
    }

    get regionsList() {
      return countries;
    }

    // 如果有填重置密码里面的内容，才开始做密码校验
    get validatePassword() {
      return this.form.code || this.form.password || this.form.password_confirmation;
    }

    @Vue.autoLoading
    async created() {
      await Promise.all([
        authStore.tryFetchData(),
        regionsStore.tryFetchData(),
      ]);
      // 数据初始化
      Object.assign(this.form, _.pick(authStore.user, 'last_name', 'name', 'region'));
    }

    sendCodeSuccess() {
      this.countdownTime = dayjs().add(60, 'second').toString();
      this.disabledCodeBtn = true;
    }

    async handleUpdateMine() {
      if (this.form.email && !VALIDATE_EMAIL.test(this.form.email)) {
        this.$message({ type: 'error', message: this.$t('validateEmailTip') });
        this.validateEmailTip = this.$t('validateEmailTip');
        return;
      }
      if (this.form.email && this.form.email !== this.confirmEmail) {
        this.$message({ type: 'error', message: this.$t('emailError') });
        return;
      }
      // 如果有填重置密码里面的内容，才开始做密码校验
      if (this.validatePassword) {
        if (this.form.code.length !== 6) {
          return this.validateCodeError = this.$t('validateCodeError');
        }
        if (!this.form.password) {
          return this.passwordError = this.$t('passwordEmpty');
        }
        if (!VALIDATE_PASSWORD.test(this.form.password)) {
          return this.passwordError = this.$t('passwordError');
        }
        if (this.form.password !== this.form.password_confirmation) {
          return this.passwordError = this.$t('notSame');
        }
      } else {
        this.form.code = null;
      }
      let error = false;
      let errorMessage = '';
      Object.keys(this.errorList).forEach((errorName) => {
        if (!this.form[errorName]) {
          this.errorList[errorName] = this.$t(errorName);
          errorMessage += this.$t(errorName) + ',\n';
          error = true;
        }
      });

      if (errorMessage) {
        this.$message({ type: 'error', message: errorMessage });
      }
      if (error) {
        return;
      }

      //防止邮箱被置空
      if (!this.form.email) {
        this.form.email = this.authStore.user.email;
      }
      await this.$autoLoading(authStore.updateMine(this.form));
      if (this.phone) {
        this.$message({ type: 'success', message: this.$t('resetSuccess') });
        this.$authStore.logout();
        this.$router.push({ name: 'login' });
      } else {
        this.$message({ type: 'success', message: this.$t('settingSuccess') });
        authStore.fetchData();
      }
      // 把密码重置相关的表单项为空
      this.form.code = '';
      this.form.password = '';
      this.form.password_confirmation = '';
    }
  }
</script>

<style lang="scss" scoped>
  .container {
    padding: 0 15px 50px;
    font-size: 12px;
    color: $gray;

    @include media-sm-and-up {
      min-height: 100%;
      margin: 0 auto;
      font-size: 13px;
    }

    .title {
      padding-bottom: 24px;
      margin: 34px 0 0;
      border-bottom: 1px solid $border-light;
      font-weight: bold;
      font-size: 18px;
      color: #000;
    }

    .input-item {
      padding: 18px 0;
      border-bottom: 1px solid $border-light;

      .input-content {
        max-width: 420px;
        margin: 0 auto;

        .item-title {
          margin-bottom: 18px;
          font-weight: bold;
          font-size: 14px;
          color: #000;
        }

        .el-select {
          width: 100%;
        }

        .photo-tip {
          margin-top: 15px;
          font-size: 12px;
          text-align: center;
          color: #666666;
        }

        .item {
          margin-top: 9px;

          .label {
            min-width: 107px;
            padding-right: 17px;
            text-align: end;

            &.date-label {
              max-width: 107px;
            }

            @include media-sm-and-up {
              min-width: 120px;
            }

            .star {
              color: $primary;
            }
          }

          .verification {
            min-width: fit-content;
            padding: 2px 6px;
            margin-left: 2px;
            border-radius: 4px;
            font-size: 12px;
            color: #fff;
            background: #4bb44d;
            transform: scale(0.7);

            &.wait {
              background: #ccc;
            }
          }
        }

        .primary-btn-o {
          width: fit-content;
          height: 30px;
          margin-top: 10px;
          margin-right: 0;
          font-size: 13px;

          @include media-sm-and-up {
            margin-top: 0;
            margin-left: 14px;
          }
        }

        .warn {
          margin: 15px 0 9px 98px;
          font-size: 10px;
          color: $primary;
        }

        .agree {
          color: green;
        }

        .tips {
          padding: 21px 0 6px;
          font-size: 10px;
          color: $gray;
        }

        .error {
          margin-top: 15px;
          font-size: 11px;
          line-height: 15px;
          text-align: end;
          color: $primary;
        }
      }
    }

    .input-wrapper {
      width: 100%;
      height: 40px;
      padding: 0 17px;
      border: 1px solid #ddd;
      border-radius: 4px;
      outline: none;

      &:focus,
      &.focus {
        border: 1px solid $primary;
      }

      .input {
        align-self: stretch;
        border: none;
        outline: none;
      }
    }

    .submit-btn {
      margin: 20px auto;
      font-size: 18px;

      @include media-sm-and-up {
        margin-top: 46px;
      }
    }
  }
</style>
