import { settingsStore } from '@/store';
import { i18n } from '@/i18n';

/**
 * 将金额根据后台设置的汇率进行转化
 * @param {Number} price 初始金额
 * @param {String} startCurrency 起始货币单位
 * @param {String} endCurrency 转化的货币单位
 * @returns {Number} 转化后的金额
 */
export default function conversionFormat(price, startCurrency = 'jpy', endCurrency) {
  !endCurrency && (endCurrency = i18n.t('currency'));
  startCurrency = startCurrency.toLowerCase();
  endCurrency = endCurrency.toLowerCase();
  if (startCurrency === endCurrency) {
    return price;
  }
  const transformKey = `${startCurrency}_to_${endCurrency}_exchange_rate`;
  const rate = settingsStore.settings[transformKey];
  if (!rate) {
    return null;
  }

  return rate * price;
}
