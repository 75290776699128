<i18n src="../locales.json"></i18n>

<template>
  <div v-if="$get(store, 'data.bid_status') !== 'deal' && $get(store, 'data.margin_amount')" class="tip-wrapper">
    <span class="label">{{ $t('marginAmountRequire') }}</span>
    <span class="link" @click="showMarginAmountPopup">{{ $t('useMarginAmount') }}</span>
  </div>
</template>

<script>
  import { Vue, Component, Prop } from 'vue-property-decorator';
  import { CURRENCY_MAP } from '@/constants';

  @Component
  export default class MarginAmountTip extends Vue {
    @Prop({ type: Object, default: () => {} }) store;

    get translateCurrency() {
      const { currency } = this.$get(this.store, 'data');
      return CURRENCY_MAP.includes(currency) ? currency : 'jpy';
    }

    showMarginAmountPopup() {
      const { currency, margin_amount } = this.store.data;
      this.$msgbox({
        title: this.$t('useMarginAmountTitle'),
        customClass: 'message-box-page-product-auction-method',
        lockScroll: true,
        center: true,
        showConfirmButton: false,
        message: (
          <div class="content flex column item-center">
            {
              <i18n class="margin-amount-tip" path="useMarginAmountTip" tag="div">
                <span place="marginAmount">
                  <span class="text-uppercase">{ currency }</span>
                  <span class="amount">{ margin_amount }</span>
                </span>
                <span place="myWallet" class="link" onClick={ this.navToCharge }>
                  { this.$t('myWallet') }
                </span>
              </i18n>
            }
            <div class="btn-confirm flex item-center content-center" onClick={ this.handleContact }>{ this.$t('contactBtn') }</div>
          </div>
        )
      });
    }

    navToCharge() {
      const { margin_amount } = this.$get(this.store, 'data');
      this.$router.push({ name: 'charge', query: { selectCurrency: this.translateCurrency, marginAmount: margin_amount } });
    }

    async handleContact() {
      const { data } = await this.$autoLoading(this.$fly.post('message_channels/contact_service', {
        target_type: 'Product',
        target_id: this.$route.params.id,
        communicator_type: 'AuctionHouse',
        communicator_id: this.$get(this.store, 'data.auction_house_id')
      }));
      this.$router.push({ name: 'messageChannelDetail', params: { id: data.id } });
    }
  }
</script>

<style lang="scss" scoped>
  .tip-wrapper {
    font-weight: 500;

    .link {
      margin-left: 20px;
      text-decoration: underline;
      color: #666;
      cursor: pointer;
    }
  }
</style>
