import { SimpleStore } from './simple-store';
import { loadElepay } from 'elepay-js-sdk';
import { fly, sleep } from '@/utils';
import { i18n } from '@/i18n';
import { Message } from 'element-ui';
import urlJoin from 'url-join';
import router from '@/router';

const ordersMaps = new Map();

export class OrderDetailStore extends SimpleStore {
  orderId = null
  params = {
    margin_amount: 0,
    address_info: {},
    transport_company_id: null,
    is_self_pick: null,
    payment_method: null
  }
  data = {}

  constructor(orderId, apiUrl = 'orders') {
    super();
    this.orderId = orderId;
    this.apiUrl = apiUrl;
  }

  get isOnlinePayment() {
    return this.params.payment_method && !['OfflinePayment', 'RemittancePayment'].includes(this.params.payment_method);
  }

  static findOrCreate(orderId) {
    orderId = String(orderId || '');
    if (ordersMaps.has(orderId)) {
      return ordersMaps.get(orderId);
    } else {
      const instance = new this(orderId);
      if (orderId) {
        ordersMaps.set(orderId, instance);
      }
      return instance;
    }
  }

  async fetchData() {
    const { data } = await this.fetching(fly.get(`${this.apiUrl}/${this.orderId}`));
    this.initParams(data);
    this.data = data;
  }

  initParams(data) {
    if (this.apiUrl === 'batch_settlements') {
      Object.assign(this.params, { orders: [], transport_company_id: undefined, is_self_pick: undefined  });
    }
    Object.keys(this.params).forEach(key => {
      if (key === 'transport_company_id') {
        data['transport_company'] && (this.params[key] = data['transport_company'].id);
      }
      if (['is_self_pick', 'payment_method'].includes(key)) {
        this.params[key] = data[key];
      }
      data[key] && (this.params[key] = data[key]);
    });
  }

  updateParams(params) {
    Object.assign(this.params, params);
  }

  async updateOrder() {
    const { data } = await fly.put(`${this.apiUrl}/${this.orderId}`, this.params);
    this.data = data;
  }

  async paymentOrder() {
    const paymentSuccess = {
      zh_cn: '支付成功',
      zh_hk: '支付成功',
      en: 'Payment Successful',
      ja: '支払い完了'
    };
    const paymentFailed = {
      zh_cn: '支付失败',
      zh_hk: '支付失敗',
      en: 'Payment Failed',
      ja: '支払いに失敗しました'
    };
    const { data: { charge_obj, elepay_auth_pk, payment_code, redirect_uri } } = await fly.post(`${this.apiUrl}/${this.orderId}/request_payment`, {
      payment_method: this.params.payment_method,
      redirect_url: urlJoin(
        location.origin,
        // 前端支付成功之后等一会，比如等1s再请求订单详情接口
        router.resolve({ name: this.apiUrl === 'orders' ? 'orderDetail' : 'orderBatchDetail', params: { id: this.orderId }, query: { sleep: true } }).route.fullPath,
      )
  });

    // 金额抵扣后需要支付的金额为0，此时不需要再调用第三方支付, 20001 => 需要支付
    if (payment_code === 20002) {
      await this.fetchData();
      Message.success(paymentSuccess[i18n.locale]);
      return;
    }

    // 如果后端判断到是用elepay平台，API就会响应原来的内容（带有elepay_auth_pk），如果判断是要用pay dollar平台，则API响应的内容不包含elepay_auth_pk，而是返回redirect_uri，前端直接跳转到redirect_uri就好了
    // 用户完成支付，取消支付或支付失败，会从第三方平台的链接跳转回订单详情页面，这时url会有一个pay_result参数（后端定义的，可以调整），为success则表示支付成功，为cancel则为取消支付，为fail则表示支付失败
    if (!elepay_auth_pk && redirect_uri) {
      location.href = redirect_uri;
      return;
    }

    const elepay = await loadElepay(elepay_auth_pk);
    if (this.params.payment_method === 'EasyCheckoutPayment') {
      await elepay.checkout(charge_obj.id);
      return;
    }
    await elepay.handleCharge(charge_obj)
      .then(async result => {
        if (result.type === 'cancel') {
          Message.error(paymentFailed[i18n.locale]);
        } else if (result.type === 'success') {
          await sleep(1000);
          await this.fetchData();
          Message.success(paymentSuccess[i18n.locale]);
        }
      });
  }
}
