<template>
  <div class="component-card">
    <oss-img class="cover" :src="cover" :resize="{ w: 1000 }" />
    <div class="content">
      <div v-if="title" class="title text-overflow-2">{{ title }}</div>
      <slot />
    </div>
  </div>
</template>

<script>
  import { Vue, Component, Prop } from 'vue-property-decorator';

  @Component
  export default class Card extends Vue {
    @Prop(String) cover;
    @Prop(String) title;
  }
</script>

<style lang="scss" scpoed>
  div {
    box-sizing: border-box;
  }

  .component-card {
    border-radius: 2px;
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.09);

    .cover {
      width: 100%;
      height: 157px;
      object-fit: cover;
    }

    .content {
      padding: 27px 22px 22px;

      .title {
        margin-bottom: 24px;
        font-size: 20px;
        line-height: 30px;

        @include media-sm-and-up {
          height: 60px;
        }
      }
    }
  }
</style>
