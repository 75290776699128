import { Collection } from './collection';
import { fly } from '@/utils';

class TransportCompaniesStore extends Collection {
  fetch(params) {
    return fly.get('transport_companies', params);
  }
}

export const transportCompaniesStore = new TransportCompaniesStore();
