<template>
  <div class="auction-item-card" :class="{ active: checked }">
    <div class="flex item-center">
      <div
        v-if="showCheckbox"
        class="checkbox"
        :class="{ checked, disabled }"
        @click="$emit('toggle')"
      />
      <div class="cover" :style="{backgroundImage: `url(${imageSrc})`}"></div>
    </div>
    <div class="content-wrapper">
      <div>
        <slot name="title"/>
        <div v-if="title && !$slots.title" class="title text-overflow">{{ title }}</div>
        <slot name="info"/>
        <div v-if="info && !$slots.info" class="info text-overflow-2">{{ info }}</div>
      </div>
      <div>
        <slot/>
      </div>
    </div>
  </div>
</template>

<script>
  import { Vue, Component, Prop } from 'vue-property-decorator';

  @Component
  export default class AuctionItemCard extends Vue {
    @Prop(String) title
    @Prop(String) info
    @Prop(String) cover
    @Prop(Boolean) showCheckbox
    @Prop(Boolean) checked
    @Prop(Boolean) disabled

    resize = { w: 210 }

    get OSSProcess() {
      const keys = Object.keys(this.resize);
      return `image/resize,${keys.map(item => `${item}_${this.resize[item]}`).join(',')}`;
    }

    get imageSrc() {
      return `${this.cover}?x-oss-process=${this.OSSProcess}`;
    }
  }
</script>

<style lang="scss" scoped>
  .auction-item-card {
    display: flex;
    box-sizing: border-box;
    margin-bottom: 9px;
    border: 1px solid transparent;
    border-radius: 2px;
    background: #ffffff;
    box-shadow: 0 0 10px 2px rgba(#000, 0.09);

    &.active {
      border: 1px solid #000;
    }

    .checkbox {
      display: inline-block;
      box-sizing: border-box;
      width: 20px;
      height: 20px;
      margin-right: 10px;
      margin-left: 9px;
      border: 1px solid #dcdfe6;
      border-radius: 50%;
      visibility: visible;
      background: #fff;
      cursor: pointer;

      &.disabled {
        background: #e8e8e8;
        cursor: not-allowed;
        pointer-events: none;
      }

      &.checked {
        font-weight: 300;
        color: #fff;
        background: #000;

        &::after {
          display: flex;
          justify-content: center;
          align-items: center;
          content: '\2713';
        }
      }
    }

    .cover {
      flex-shrink: 0;
      width: 105px;
      height: 105px;
      margin-right: 15px;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }

    .content-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 8px 0;
      overflow: hidden;
      font-size: 11px;
      color: #666;

      .title {
        margin-bottom: 5px;
        font-weight: 800;
        font-size: 14px;
        color: #000;
      }

      .info {
        font-size: 13px;
        word-break: break-all;
      }
    }
  }
</style>
