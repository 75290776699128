<i18n src="./locales.json"></i18n>

<template>
  <div class="wrapper">
    <div class="container">
      <v-title :value="$t('myMessage')"/>
      <div class="title">{{ $t('myMessage') }}</div>
      <div class="list">
        <channel-item
          v-for="item in store.data"
          :key="item.id"
          :channel="item"
        />
      </div>
      <div class="empty flex column item-center" v-if="store.isEmpty">
        <img class="empty-img" src="@/assets/empty.png"/>
        <div class="text">{{ $t('emptyData') }}</div>
      </div>
      <pagination routeMode class="pagination" :store="store"/>
    </div>
  </div>
</template>

<script>
  import { Component, Vue } from 'vue-property-decorator';
  import { Collection, authStore } from '@/store';
  import { fly } from '@/utils';
  import ChannelItem from './components/channel-item';

  @Component({
    components: {
      'channel-item': ChannelItem
    }
  })
  export default class messageChannels extends Vue {
    store = new Collection({
      fetch: params => fly.get('message_channels', params)
    })

    @Vue.autoLoading
    async created() {
      const { page } = this.$route.query;
      this.store.params = {
        page
      };
      await authStore.fetchData();
      await this.store.fetchData();
    }
  }
</script>

<style lang="scss" scoped>
  .title {
    margin: 28px 0 25px;
    font-weight: 500;
    font-size: 18px;
  }

  .channel-item {
    margin-bottom: 12px;
    cursor: pointer;
  }

  .pagination {
    margin: 15px 0;
  }

  .empty {
    margin-top: 200px;
    color: #979797;

    @include media-xs-only {
      margin-top: 100px;
    }

    .empty-img {
      width: 80px;
      margin-bottom: 15px;
    }
  }
</style>
