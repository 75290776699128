<i18n>
{
  "zh_cn": {
    "paymentMethods": "请选择付款方式",
    "onlinePayment": "线上支付",
    "OfflinePayment": "线下支付",
    "RemittancePayment": "汇款支付",
    "WechatPayment": "微信支付",
    "AlipayPayment": "支付宝",
    "UnionpayPayment": "银联支付",
    "CreditcardPayment": "信用卡",
    "LinepayPayment": "LINE Pay",
    "PaypayPayment": "PayPay",
    "EasyCheckoutPayment": "扫码支付",
    "confirm": "确认"
  },
  "zh_hk": {
    "paymentMethods": "請選擇付款方式",
    "onlinePayment": "線上支付",
    "OfflinePayment": "線下支付",
    "RemittancePayment": "匯款支付",
    "WechatPayment": "微信支付",
    "AlipayPayment": "支付寶",
    "UnionpayPayment": "銀聯支付",
    "CreditcardPayment": "信用卡",
    "LinepayPayment": "LINE Pay",
    "PaypayPayment": "PayPay",
    "EasyCheckoutPayment": "掃碼支付",
    "confirm": "確認"
  },
  "en": {
    "paymentMethods": "Please Choose Payment Method",
    "onlinePayment": "Online Payment",
    "OfflinePayment": "Offline Payment",
    "RemittancePayment": "Remittance Payment",
    "WechatPayment": "WeChat Pay",
    "AlipayPayment": "Alipay",
    "UnionpayPayment": "Unionpay Payment",
    "CreditcardPayment": "Creditcard Payment",
    "LinepayPayment": "LINE Pay",
    "PaypayPayment": "PayPay",
    "EasyCheckoutPayment": "QR Code Payment",
    "confirm": "Confirm"
  },
  "ja": {
    "paymentMethods": "お支払い方法の選択",
    "onlinePayment": "オンライン支払い",
    "OfflinePayment": "来社での決済",
    "RemittancePayment": "送金して支払う",
    "WechatPayment": "WeChat Pay",
    "AlipayPayment": "Alipay",
    "UnionpayPayment": "銀聯",
    "CreditcardPayment": "クレジットカード",
    "LinepayPayment": "LINE Pay",
    "PaypayPayment": "PayPay",
    "EasyCheckoutPayment": "QRコード",
    "confirm": "確認します"
  }
}
</i18n>

<template>
  <div class="component-payment-methods">
    <!-- 移动端显示 -->
    <action-sheet
      v-model="showPaymentMethods"
      @input="handleClosePanel"
      v-if="$isMobile()"
    >
      <div class="auction-sheet-content">
        <div class="title">{{ $t('paymentMethods') }}</div>
        <div class="payment-methods">
          <div class="payment-item flex item-center content-between"
            @click="handleSelectPayment('OfflinePayment')"
            v-if="showOfflinePaymentItems && paymentMethodsStore.hasOfflinePayment"
          >
            <div class="label">{{ $t('OfflinePayment') }}</div>
            <div :class="['selector', { selected: paymentMethod === 'OfflinePayment' }]"></div>
          </div>
          <div class="payment-item flex item-center content-between"
            @click="handleSelectPayment('RemittancePayment')"
            v-if="showOfflinePaymentItems && paymentMethodsStore.hasRemittancePayment"
          >
            <div class="label">{{ $t('RemittancePayment') }}</div>
            <div :class="['selector', { selected: paymentMethod === 'RemittancePayment' }]"></div>
          </div>
          <!-- 线上支付 -->
          <div class="payment-item" v-if="showOnlinePaymentItems && availablePaymentMethodList.length">
            <div class="flex item-center content-between" @click="handleToggleOnline">
              <div class="label">{{ $t('onlinePayment') }}</div>
              <i :class="['el-icon-arrow-down', { active: showOnlinePayment }]" />
            </div>
            <div :class="['online', { active: showOnlinePayment }]">
              <div
                class="online-item"
                v-for="(item, index) in availablePaymentMethodList"
                :key="index"
                @click="handleSelectPayment(item)"
              >
                <div class="flex item-center content-between">
                  <div class="flex item-center">
                    <img class="icon-payment" :src="getPaymentIconPath(item)" />
                    <div class="name">{{ $t(item) }}</div>
                  </div>
                  <div :class="['selector', { selected: paymentMethod === item }]"></div>
                </div>
                <div
                  class="hint"
                  v-if="item === 'CreditcardPayment' && charge"
                  v-html="$get(settingsStore, `settings.${isPayDollar ? 'pay_dollar_credit_card_charge_hint' : 'credit_card_charge_hint'}`)"
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div class="btn-wrapper">
          <div class="btn-confirm primary-btn" @click="handleConfirmMethod">{{ $t('confirm') }}</div>
        </div>
      </div>
    </action-sheet>
    <!-- pc端显示 -->
    <el-dialog
      :visible="showPaymentMethods"
      @update:visible="handleClosePanel"
      width="29.33333rem"
      center
      v-else
    >
      <div slot="title">{{ $t('paymentMethods') }}</div>
      <div class="payment-methods">
        <div class="payment-item flex item-center content-between"
          @click="handleSelectPayment('OfflinePayment')"
          v-if="showOfflinePaymentItems && paymentMethodsStore.hasOfflinePayment"
        >
          <div class="label">{{ $t('OfflinePayment') }}</div>
          <div :class="['selector', { selected: paymentMethod === 'OfflinePayment' }]"></div>
        </div>
        <div class="payment-item flex item-center content-between"
          @click="handleSelectPayment('RemittancePayment')"
          v-if="showOfflinePaymentItems && paymentMethodsStore.hasRemittancePayment"
        >
          <div class="label">{{ $t('RemittancePayment') }}</div>
          <div :class="['selector', { selected: paymentMethod === 'RemittancePayment' }]"></div>
        </div>
        <!-- 线上支付 -->
        <div class="payment-item" v-if="showOnlinePaymentItems && availablePaymentMethodList.length">
          <div class="flex item-center content-between" @click="handleToggleOnline">
            <div class="label">{{ $t('onlinePayment') }}</div>
            <i :class="['el-icon-arrow-down', { active: showOnlinePayment }]" />
          </div>
          <div :class="['online', { active: showOnlinePayment }]">
            <div
              class="online-item"
              v-for="(item, index) in availablePaymentMethodList"
              :key="index"
              @click="handleSelectPayment(item)"
            >
              <div class="flex item-center content-between">
                <div class="flex item-center">
                  <img class="icon-payment" :src="getPaymentIconPath(item)" />
                  <div class="name">{{ $t(item) }}</div>
                </div>
                <div :class="['selector', { selected: paymentMethod === item }]"></div>
              </div>
              <div
                class="hint"
                v-if="item === 'CreditcardPayment' && charge"
                v-html="$get(settingsStore, `settings.${isPayDollar ? 'pay_dollar_credit_card_charge_hint' : 'credit_card_charge_hint'}`)"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div class="btn-wrapper">
        <div class="btn-confirm primary-btn" @click="handleConfirmMethod">{{ $t('confirm') }}</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
  import { paymentMethodsStore, settingsStore } from '@/store';

  @Component
  export default class PaymentMethods extends Vue {
    @Prop({ type: Boolean, default: false }) value
    @Prop({ type: String, default: null }) current
    @Prop({ type: Boolean, default: true }) showOfflinePaymentItems
    @Prop({ type: Boolean, default: true }) showOnlinePaymentItems
    @Prop({ type: Boolean, default: false }) charge
    @Prop({ type: Boolean, default: false }) directPay // 是否选择支付方式后直接付款
    @Prop({ type: Boolean, default: false }) isPayDollar // 是否 PayDollar 支付平台

    paymentMethod = null
    showPaymentMethods = false
    showOnlinePayment = true

    paymentMethodsStore = paymentMethodsStore
    settingsStore = settingsStore

    // PayDollar 目前仅支持银联和信用卡
    get availablePaymentMethodList() {
      if (this.isPayDollar) {
        return this.paymentMethodsStore.onlinePaymentMethods.filter(v => ['UnionpayPayment', 'CreditcardPayment'].includes(v));
      }

      return this.paymentMethodsStore.onlinePaymentMethods;
    }

    @Watch('current', { immediate: true })
    currentChange(val) {
      if (val && this.paymentMethod !== val) {
        this.paymentMethod = val;
      }
    }

    @Watch('value', { immediate: true })
    valueChange(val, old) {
      if (val !== old) {
        this.showPaymentMethods = this.value;
      }
    }

    getPaymentIconPath(method) {
      return {
        'WechatPayment': require('@/assets/payment/icon-wechat.png'),
        'AlipayPayment': require('@/assets/payment/icon-alipay.png'),
        'UnionpayPayment': require('@/assets/payment/icon-unionpay.png'),
        'CreditcardPayment': require('@/assets/payment/icon-creditcard.png'),
        'LinepayPayment': require('@/assets/payment/icon-linepay.png'),
        'PaypayPayment': require('@/assets/payment/icon-paypay.png'),
        'EasyCheckoutPayment': require('@/assets/payment/icon-scan.png')
      }[method];
    }

    handleClosePanel(e) {
      this.$emit('input', e);
    }

    handleToggleOnline() {
      this.showOnlinePayment = !this.showOnlinePayment;
    }

    handleSelectPayment(method) {
      this.paymentMethod = method;
    }

    handleConfirmMethod() {
      this.$emit('confirm', this.paymentMethod, this.directPay);
      this.handleClosePanel(false);
    }
  }
</script>

<style lang="scss" scoped>
  .auction-sheet-content {
    .title {
      position: absolute;
      top: 20px;
      left: 50%;
      width: 100%;
      font-weight: 500;
      font-size: 18px;
      text-align: center;
      transform: translateX(-50%);
    }

    .payment-methods {
      padding: 0 16px;
      margin-top: 25px;

      .payment-item {
        padding: 15px 0;
        border-bottom: 1px solid #ddd;

        .selector {
          position: relative;
          width: 16px;
          height: 16px;
          border: 1px solid #ccc;
          border-radius: 50%;

          &.selected::after {
            position: absolute;
            top: 3px;
            left: 3px;
            display: block;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: #000;
            content: '';
          }
        }

        .el-icon-arrow-down {
          font-size: 16px;
          transition: all .3s;

          &.active {
            transform: rotate(-180deg);
          }
        }

        .online {
          max-height: 0;
          overflow: hidden;
          transition: all .3s;

          &.active {
            max-height: 1000px;
          }

          .online-item {
            margin-top: 26px;

            .icon-payment {
              width: 20px;
              height: 20px;
            }

            .name {
              margin-left: 15px;
            }

            .hint {
              margin-top: 5px;
              font-size: 10px;
              color: $primary;
            }
          }
        }
      }
    }

    .btn-wrapper {
      padding: 14px 16px 22px;

      .btn-confirm {
        background: $primary;
      }
    }
  }

  /deep/ .el-dialog {
    border-radius: 19px;

    .el-dialog__header {
      padding: 25px 0 30px;
      font-weight: 500;
      font-size: 16px;
    }

    .el-dialog__body {
      padding: 0 24px 39px;

      .payment-item {
        padding: 12px 0;
        border-bottom: 1px solid #ddd;

        .label {
          font-size: 13px;
          color: #000;
        }

        .selector {
          position: relative;
          width: 16px;
          height: 16px;
          border: 1px solid #ccc;
          border-radius: 50%;
          transform: scale(0.8);
          transform-origin: right;

          &.selected::after {
            position: absolute;
            top: 3px;
            left: 3px;
            display: block;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: #000;
            content: '';
          }
        }

        .el-icon-arrow-down {
          font-size: 16px;
          transition: all .3s;

          &.active {
            transform: rotate(-180deg);
          }
        }

        .online {
          max-height: 0;
          overflow: hidden;
          transition: all .3s;

          &.active {
            max-height: 1000px;
          }

          .online-item {
            margin: 22px 0;

            .icon-payment {
              width: 20px;
              height: 20px;
            }

            .name {
              margin-left: 15px;
            }

            .hint {
              margin-top: 5px;
              font-size: 10px;
              color: $primary;
            }
          }
        }
      }

      .btn-wrapper {
        margin-top: 12px;

        .btn-confirm {
          height: 38px;
          font-size: 15px;
          background: $primary;
        }
      }
    }
  }
</style>
