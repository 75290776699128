<i18n src="../locales.json"></i18n>

<template>
  <div class="upload-image no-shirk" :class="{ disabled }">
    <img class="icon-close" @click.stop="handleDelete" src="@/assets/gray-close.png"/>
    <div v-if="!getPhoto">
      <div class="icon-wrap flex column item-center">
        <div class="text">
          {{ defaultText }}
        </div>
        <img class="icon-image" src="@/assets/add.png"/>
      </div>
    </div>
    <img class="cover" :src="getPhoto" v-else/>
    <label class="file">
      <input
        class="input-file"
        type="file"
        accept="image/png, image/jpg, image/jpeg, image/webp"
        @change="handleUploadImage"
        :disabled="disabled"
      />
    </label>
  </div>
</template>

<script>
  import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
  import { uploadFile } from '@/utils';
  import _ from 'lodash';

  @Component
  export default class ImgUpload extends Vue {
    @Prop(String) defaultText
    @Prop(String) imgUrl
    @Prop(Boolean) disabled

    getPhoto = ''
    sizeLimit = 5

    @Watch('imgUrl', { immediate: true })
    showImg(val) {
      if (val !== null) {
        this.getPhoto = val;
      }
    }

    async handleUploadImage(e) {
      if (e.target.files.length) {
        const file = e.target.files[0];
        const sizeMB = _.get(file, 'size') / 1024 / 1024;
        if (sizeMB > this.sizeLimit) {
          this.$message({ type: 'error', message: `${this.$t('notExceed')}${this.sizeLimit}MB` });
          return;
        }
        const { url, signed_id } = await this.$autoLoading(uploadFile(file));
        this.getPhoto = url;
        this.$emit('changeImage', signed_id);
      } else {
        return;
      }
    }

    handleDelete() {
      this.getPhoto = '';
      this.$emit('deleteImage');
    }
  }
</script>

<style lang="scss" scoped>
  .cover {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .upload-image {
    position: relative;
    width: 180px;
    height: 134px;
    border: 1px dashed #dcdfe6;
    border-radius: 4px;
    overflow: hidden;

    @include media-sm-and-up {
      width: 146px;
      height: 100px;
    }

    &.disabled {
      background: #f5f7fa;
      cursor: not-allowed;

      * {
        cursor: not-allowed;
        pointer-events: none;
      }
    }

    .icon-close {
      position: absolute;
      z-index: 999;
      top: 6px;
      right: 6px;
      width: 16px;
      height: 16px;
      cursor: pointer;
    }

    .icon-wrap {
      position: absolute;
      top: 0;
      bottom: 14px;
      left: 0;
      right: 0;
      width: fit-content;
      height: fit-content;
      margin: auto;
      color: #bbb;

      .text {
        font-size: 14px;
        text-align: center;

        @include media-sm-and-up {
          font-size: 11px;
        }
      }

      .icon-image {
        width: 24px;
        height: 24px;
        padding-top: 6px;

        @include media-xs-only {
          width: 32px;
          height: 32px;
        }
      }
    }

    .input-file {
      display: none;
    }

    .file {
      position: absolute;
      z-index: 99;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;
    }
  }

</style>
