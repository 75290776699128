<i18n>
{
  "zh_cn": {
    "socialMedia": "社交网站"
  },
  "zh_hk": {
    "socialMedia": "社交網站"
  },
  "en": {
    "socialMedia": "Social Media"
  },
  "ja": {
    "socialMedia": "公式SNS"
  }
}
</i18n>

<template>
  <div class="mobile-menu-wrapper flex">
    <div class="icon-wrapper" @click="$emit('input', false)">
      <img src="@/assets/icon-arrow-right-double.png" alt="" class="icon-arrow">
    </div>
    <div class="menu-content">
      <div>
        <div class="flex item-center content-between" style="margin-bottom: 2.5rem;">
          <app-lang-switcher @close="$emit('input', false)"/>
        </div>
        <slot/>
      </div>
      <div v-if="!$auctionHouseStore.domainHouse" class="footer">
        <div class="title">{{$t('socialMedia')}}</div>
        <app-social-links white />
      </div>
    </div>
  </div>
</template>

<script>
  import { Vue, Component, Model } from 'vue-property-decorator';

  @Component
  export default class SideMenu extends Vue {
    @Model('input', { type: Boolean, default: false }) value;
  }
</script>

<style lang="scss" scoped>
  .mobile-menu-wrapper {
    height: 100%;
    overflow: auto;

    .icon-wrapper {
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;
      height: 100%;

      .icon-arrow {
        width: 16px;
        height: 22px;
      }
    }

    /deep/ .el-dropdown {
      color: #fff;
    }

    .menu-content {
      flex-shrink: 0;
      width: 295px;
      height: 100%;
      padding: 18px 15px 28px 24px;
      overflow: auto;
      color: #fff;
      background: #000;

      .logo {
        width: 81px;
        height: 21px;
      }
    }
  }

  .footer {
    margin-top: 25px;

    .title {
      margin-bottom: 20px;
      font-size: 12px;
      color: #666;
    }

    .icon {
      width: 20px;
      height: 20px;
      margin-right: 15px;
    }
  }
</style>
