<i18n src="./locales.json"></i18n>

<template>
  <div class="flex">
    <common-sidebar class="hidden-xs-only"/>
    <div class="container">
      <v-title :value="$t('deal')"/>
      <div class="title-wrap flex item-center content-between">
        <span class="title">{{ $t('deal') }}</span>
        <div class="flex item-center">
          <div v-if="currentListStore.data.length" class="list-link-btn hidden-xs-only" @click="hadnleBatchPdf">{{ $t('batchPdfExport') }}</div>
          <!-- 先隐藏掉批量结算入口 -->
          <!-- <router-link :to="{ name: 'orderBatchList' }" class="list-link-btn">{{ $t('bulkSettlementList') }}</router-link> -->
        </div>
      </div>
      <el-tabs v-model="name" @tab-click="handleTabClick">
        <el-tab-pane
          v-for="(list, key) in tabs"
          :key="key"
          :label="$t(key)"
          :name="key"
        >
          <template v-if="!list.store.isEmpty">
            <div v-if="key === 'waitCreateOrder'" class="top-hint flex content-center item-center">{{ $t('waitingCreateOrderHint') }}</div>
            <!-- 先隐藏掉批量结算入口 -->
            <!-- <div v-if="key === 'waitPay'" class="unpaid-top-wrap flex item-center content-between">
              <el-checkbox v-model="checkAll" @change="handleCheckAll">{{ $t('checkAll') }}</el-checkbox>
              <div class="flex item-center">
                <span v-if="waitPayOrders.length > 0">
                  ({{ $tc('selected', waitPayOrders.length) }})
                </span>
                <div class="link-btn" :class="{ disabled: waitPayOrders.length < 2 }" @click="handleOrderBatch">
                  {{ $t('bulkSettlement') }}
                </div>
              </div>
            </div> -->
          </template>
          <div v-if="list.store.isEmpty" class="list-empty flex column item-center">
            <img src="@/assets/icon-deal-empty.png" class="icon-empty">
            <div>{{ $t(list.emptyName) }}</div>
          </div>
          <div v-else-if="list.store.isFulfilled" class="list-wrapper">
            <!-- 先隐藏掉批量结算入口，开放入口需要加上：:showCheckbox="key === 'waitPay'" -->
            <order-item
              :order="Object.assign(item, { checked: getItemCheck(item) } )"
              v-for="item in list.store.data"
              :key="item.id"
              @checkboxChange="handleCheckboxChange"
            >
              <div class="flex content-end">
                <div
                  @click.stop="handleNavDetail(item.id)"
                  class="primary-btn"
                  v-if="item.status === 'wait_buyer_pay' || item.status === 'wait_buyer_confirm_logistic_info'"
                >
                  {{ showBtnText(item) }}
                </div>
                <div class="primary-btn disabled paid" v-if="item.status === 'wait_buyer_pay_logistic'">{{ $t('paid') }}</div>
                <div class="primary-btn confirm" v-if="item.status === 'wait_buyer_confirmed_goods'" @click.stop.prevent="handleConfirmGoods(item.id)">{{ $t('confirm') }}</div>
                <div class="primary-btn" v-if="canEvaluate(item)" @click.stop="$router.push({ name: 'newEvaluation', query: { order_id: item.id, auction_house_id: item.auction_house_id } })">
                  {{ $t('toEvaluate') }}
                </div>
              </div>
              <div v-if="item.status === 'wait_buyer_pay_logistic'" >
                <div
                  v-if="!item.logistic_fee"
                  class="logistic"
                  style="padding: 2rem 0 1rem;"
                >{{ item.orderable_type === 'Product' ? $t('auctionHouselogisticTip') : $t('logisticTip') }}</div>
                <div v-else class="logistic flex content-between item-center">
                  <div class="flex" style="align-items: baseline;">
                    <span>{{ $t('logistic') }}: </span>
                    <div class="price">
                      <span style="color: #000">{{ item.quota_currency.toUpperCase() }} </span>
                      <span>{{ item.logistic_fee | currencyFormat}}</span>
                    </div>
                  </div>
                  <div class="primary-btn logistic-btn" @click.stop="handleNavDetail(item.id)">{{ $t('pay') }}</div>
                </div>
              </div>
            </order-item>
            <pagination class="pagination" :store="list.store" @paginationChange="handlePaginationChange" />
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';
  import { Collection } from '@/store';
  import _ from 'lodash';

  @Component
  export default class Orders extends Vue {
    name = 'waitPay';
    tabs = {
      waitPay: {
        params: { status_eq: 'wait_buyer_pay', payment_method: 'OfflinePayment' },
        store: {},
        emptyName: 'noWaitPay'
      },
      waitConfirm: {
        params: { status_eq: 'wait_confirm_payment_amount', payment_method: 'OfflinePayment' },
        store: {},
        emptyName: 'noWaitConfirm'
      },
      waitCreateOrder: {
        params: { wait_create_order: true },
        store: {},
        emptyName: 'noWaitCreateOrder'
      },
      waitSelfPick: {
        params: { status_eq: 'wait_buyer_self_pick' },
        store: {},
        emptyName: 'noWaitSelfPick'
      },
      waitDeliver: {
        params: { status_eq: 'wait_seller_send_goods',
        payment_method: 'OfflinePayment' },
        store: {},
        emptyName: 'noWaitDeliver'
      },
      waitReceive: {
        params: { status_eq: 'wait_buyer_confirmed_goods',
        payment_method: 'OfflinePayment' },
        store: {},
        emptyName: 'noWaitReceive'
      },
      finished: {
        params: { status_eq: 'trade_finished', payment_method: 'OfflinePayment' },
        store: {},
        emptyName: 'noFinished'
      },
      closed: {
        params: { status_eq: 'trade_closed', payment_method: 'OfflinePayment' },
        store: {},
        emptyName: 'noClosed'
      }
    }
    waitPayOrders = []
    checkAll = false

    get currentListStore() {
      return this.tabs[this.name].store;
    }

    @Vue.autoLoading
    async created() {
      const { name, page } = this.$route.query;
      name && (this.name = name);
      Object.keys(this.tabs).forEach(key => {
        const list = this.tabs[key];
        if (key === 'waitCreateOrder') {
          list.store = new Collection({
            fetch: params => this.$fly.get('mine/won_auction_items', { ...params, ...list.params })
          });
        } else {
          list.store = new Collection({
            fetch: params => this.$fly.get('orders', { ...params, ...list.params })
          });
        }
        list.store.params = { page };
      });
      return this.currentListStore.fetchData();
    }

    async handleTabClick() {
      if (this.name === this.$get(this.$route, 'query.name', 'waitPay')) {
        return;
      }
      await this.$nextTick();
      this.$router.replace({ query: _.merge({}, { name: this.name }) });
    }

    // 分别为： 待支付，等待商家确认回复，去确认收货信息
    showBtnText(item) {
      if (item.status === 'wait_buyer_pay') {
        return item.payment_amount ? this.$t('pay') : this.$t('waitReply');
      } else {
        return this.$t('toConfirmReceiverInfo');
      }
    }

    // 拍品订单和已评价了是不能显示评价的
    canEvaluate(item) {
      return item.status === 'trade_finished' && !item.was_evalued && item.orderable_type === 'Product';
    }

    // 确认收货
    async handleConfirmGoods(id) {
      await this.$confirm(this.$t('confirmGoods'));
      await this.$fly.put(`orders/${id}/receive`);
      return this.currentListStore.fetchData();
    }

    // 如果没有上传任何证件，没有证件有效期，提示用户填写后再支付
    async handleNavDetail(id) {
      if (this.$authStore.isLoadCredential) {
        if (this.$authStore.isLoadEffectiveDate) {
          await this.$confirm(this.$t('uploadValidityPeriod'));
          return this.$router.push({ name: 'myAddress' });
        }
        return this.$router.push({ name: 'orderDetail', params: { id: id } });
      } else {
        await this.$confirm(this.$t('uploadTip'));
        return this.$router.push({ name: 'myAddress' });
      }
    }

    getItemCheck(orderItem) {
      const index = this.waitPayOrders.findIndex(item => item.id === orderItem.id);
      return index !== -1 ? true : false || false;
    }

    async handleCheckboxChange(order) {
      if (order.checked) {
        this.waitPayOrders.push(order);
      } else {
        const index = this.waitPayOrders.findIndex(item => item.id === order.id);
        this.waitPayOrders.splice(index, 1);
        if (this.checkAll) {
          this.checkAll = false;
        }
      }

      this.checkAll = this.currentListStore.data.every(item => item.checked);
    }

    handleCheckAll(checkAll) {
      const currentData = this.currentListStore.data;

      if (!checkAll) {
        _.remove(this.waitPayOrders, (item) => {
          return currentData.findIndex(order => order.id === item.id) !== -1;
        });
        return;
      }
      this.waitPayOrders.push(...currentData.filter((item) => !item.checked && ['wait_buyer_pay_logistic', 'wait_buyer_pay'].includes(item.status)));
    }

    handlePaginationChange() {
      this.checkAll = this.currentListStore.data.every(item => item.checked);
    }

    @Vue.autoLoading
    async handleOrderBatch() {
      const ids = this.waitPayOrders.map(item => item.id);
      const { data } = await this.$fly.post('batch_settlements', { order_ids: ids });
      this.$router.push({ name: 'orderBatchDetail', params: { id: data.id } });
    }

    async hadnleBatchPdf() {
      await this.$alert(this.$t('batchPdfExportTip'));
      const ids = this.currentListStore.data.map(item => item.id);
      this.$router.push({ name: 'orderPdfPreview', query: { ids: ids.join(',') } });
    }
  }
</script>

<style lang="scss" scoped>
  .container {
    @include media-xs-only {
      padding: 0;
    }

    .title-wrap {
      margin: 30px 0 24px;

      @include media-xs-only {
        padding: 0 15px;
        margin: 28px 0 20px;
      }

      .title {
        font-weight: bolder;
        font-size: 18px;
        color: #000;
      }
    }

    .list-link-btn {
      padding: 6px 15px;
      margin-left: 10px;
      border: 1px solid #000;
      color: #000;
      cursor: pointer;
    }

    .top-hint {
      height: 30px;
      font-size: 12px;
      color: #666;
      background: #eee;
    }

    .unpaid-top-wrap {
      font-size: 12px;

      @include media-xs-only {
        padding: 0 15px;
      }

      .link-btn {
        width: 140px;
        height: 40px;
        margin-left: 8px;
        border-radius: 3px;
        font-size: 15px;
        line-height: 40px;
        text-align: center;
        color: #fff;
        background-color: #bc4353;
        cursor: pointer;

        &.disabled {
          background-color: #ccc;
          pointer-events: none;
        }
      }
    }

    /deep/ .el-tabs__header {
      margin-bottom: 28px;

      @include media-xs-only {
        padding: 0 15px;
        margin-bottom: 19px;
      }

      .el-tabs__nav-scroll {
        overflow: auto;
      }

      .el-tabs__active-bar {
        display: none;
      }

      .el-tabs__nav {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }

      .el-tabs__nav-wrap:after {
        display: none;
      }

      .el-tabs__item {
        height: 40px;
        padding: 0;
        margin-right: 16px;
        line-height: 40px;
        color: #999;

        &:last-of-type {
          margin-right: 0;
        }

        &.is-active {
          color: #000000;

          &:after {
            position: absolute;
            bottom: 0;
            left: 50%;
            width: 90%;
            height: 2px;
            border-radius: 1px;
            background: #000000;
            transform: translateX(-50%);
            content: '';
          }
        }
      }
    }

    /deep/ .el-tabs__content {
      overflow: visible;
    }

    .list-empty {
      padding: 97px 0;
      color: #999;

      .icon-empty {
        width: 65px;
        height: 65px;
        margin-bottom: 34px;
      }
    }

    .list-wrapper {
      .component-order-item {
        padding: 15px;
        margin-top: 11px;
        box-shadow: 0px 4px 18px 2px rgba(4, 0, 0, 0.1);

        &:first-of-type {
          margin-top: 19px;
        }
      }

      .primary-btn {
        width: max-content;
        height: 30px;
        padding: 0 14px;
        margin: 21px 0 0;
        font-size: 13px;
        color: #fff;
        background: $primary;

        @include media-sm-and-up {
          margin: -72px 15px 0 0;
        }

        &.confirm {
          background: #000;
        }

        &.paid {
          background: #ccc;
        }

        &.logistic-btn {
          margin: 0;

           @include media-sm-and-up {
            margin-right: 15px;
          }
        }
      }

      .logistic {
        padding-top: 14px;
        margin-top: 14px;
        border-top: 1px solid #ddd;
        font-size: 11px;
        color: #666;

        .price {
          margin-left: 8px;
          font-size: 14px;
          color: $primary;
        }
      }

      .pagination {
        margin: 15px 0;

        @include media-sm-and-up {
          margin: 40px 0;
        }
      }
    }
  }

  /deep/ .el-checkbox {
    .el-checkbox__inner {
      width: 18px;
      height: 18px;
      border-radius: 50%;

      &::after {
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
      }
    }

    .is-disabled {
      .el-checkbox__inner {
        border-color: #ddd;
        background-color: #eee;
      }
    }
  }
</style>
