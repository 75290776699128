<template>
  <div class="auction-card-wrapper prod-skeleton-animate">
    <div class="cover-wrapper">
      <auto-height
        class="prod-skeleton-block"
        :height="autoHeight" :oss-img="{ resize: { w: 1000 } }"
      />
    </div>
    <div class="auction-wrapper">
      <div class="auction-title prod-skeleton-block">1</div>
      <div class="auction-info">
        <div class="prod-skeleton-block">1</div>
        <div class="prod-skeleton-block">1</div>
        <div class="prod-skeleton-block">1</div>
        <div class="prod-skeleton-block">1</div>
      </div>
    </div>
  </div>
</template>

<script>
  import { Vue, Component, Prop } from 'vue-property-decorator';

  @Component
  export default class AuctionCard extends Vue {
    @Prop({ type: String, default: '50%' }) autoHeight;
  }
</script>

<style lang="scss" scoped>
  .auction-card-wrapper {
    width: 202px;
    border-radius: 2px;
    overflow: hidden;
    background: #ffffff;
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.09);

    /deep/ .auto-height-content {
      background-color: #f2f3f5;
    }

    @include media-sm-and-up {
      width: 350px;
    }

    .auction-wrapper {
      padding: 20px 5px 15px 10px;
      font-size: 12px;
      color: #666;

      .auction-title {
        margin-bottom: 10px;
        font-weight: bold;
        font-size: 18px;
      }

      .auction-info .prod-skeleton-block {
        width: 30%;
        margin-bottom: 2px;
      }

      @include media-sm-and-up {
        .auction-title {
          line-height: 1.4;
        }
      }

      .auction-info {
        line-height: 1.6;
      }
    }
  }
</style>
