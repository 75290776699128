<template>
  <div class="side-menu-wrapper" v-if="value">
    <transition name="slide-right" @after-leave="$emit('input', showContent)">
      <div style="height: 100%;" v-if="showContent">
        <mobile-side-menu v-model="showContent" class="hidden-sm-and-up">
          <el-input v-if="showInput" v-model="keyword" :placeholder="$t('search')" type="search" @keyup.enter.native="handleSearch">
            <img src="@/assets/icon-search-white.png" slot="suffix" class="icon" @click="handleSearch" />
          </el-input>
          <link-list v-model="showContent"/>
        </mobile-side-menu>
        <pc-side-menu v-model="showContent" class="hidden-xs-only">
          <el-input v-if="showInput" class="pc-input" v-model="keyword" :placeholder="$t('search')" @keyup.enter.native="handleSearch">
            <img src="@/assets/icon-search-white.png" slot="suffix" class="icon" @click="handleSearch" />
          </el-input>
          <link-list v-model="showContent"/>
        </pc-side-menu>
      </div>
    </transition>
  </div>
</template>

<script>
  import { Vue, Component, Model, Watch } from 'vue-property-decorator';
  import LinkList from './link-list';
  import MobileSideMenu from './mobile-side-menu';
  import PcSideMenu from './pc-side-menu';

  @Component({
    components: {
      'pc-side-menu': PcSideMenu,
      'mobile-side-menu': MobileSideMenu,
      'link-list': LinkList,
    }
  })
  export default class SideMenu extends Vue {
    @Model('input', { type: Boolean, default: false }) value;

    showContent = false;
    keyword = '';

    @Watch('value', { immediate: true })
    valueChange(val, old) {
      if (val !== old) {
        this.showContent = this.value;
      }
      // 移动端在弹出侧边栏的时候可以滚动，但是不滚动到最底层的页面。
      this.showContent ? this.fixedBody() : this.looseBody();
    }

    get showInput() {
      if (this.$auctionHouseStore.domainHouse) {
        return this.$auctionHouseStore.domainHouse.can_manage_store;
      } else {
        return true;
      }
    }

    handleSearch() {
      this.$router.push({ name: 'search', query: { keyword: this.keyword } });
      this.showContent = false;
      this.keyword = '';
    }

    fixedBody () {
      document.body.style.overflow = 'hidden';
    }

    looseBody () {
      document.body.style.removeProperty('overflow');
    }
  }
</script>

<style lang="scss" scoped>
  .side-menu-wrapper {
    position: fixed;
    z-index: 2001;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(#000, 0.5);
  }

  .slide-right-enter {
    transform: translateX(100%);
  }

  .slide-right-enter-to {
    transform: translateX(0);
    transition: all 0.3s;
  }

  .slide-right-leave-active {
    transform: translateX(100%);
    transition: all 0.3s;
  }

  /deep/ .el-input {
    margin-bottom: 16px;
    outline: none;

    &::placeholder {
      color: #ccc;
    }

    .el-input__inner {
      color: #fff;
      background: #000;
    }

    .el-input__suffix {
      display: flex;
      align-items: center;
    }
  }

  .icon {
    width: 15px;
    height: 15px;
    padding: 2px 5px 0 0;
    cursor: pointer;
  }

  .pc-input {
    display: flex;
    width: 281px;
    margin: 0 auto 54px;

    .icon {
      width: 18px;
      height: 18px;
    }
  }
</style>
