<template>
  <div class="skeleton" v-if="loading">
    <el-progress :percentage="percentage" :show-text="false"/>

    <div class="container">
      <div v-if="!productFirst && $auctionHouseStore.domainHouse.can_manage_auction">
        <div class="common-title-wrap top">
          <common-title/>
        </div>
        <div class="scroll-wrapper">
          <el-row :gutter="10">
            <el-col :xs="24" :lg="8" v-for="i in 3" :key="i">
              <div class="item component-auction-item">
                <skeleton-auction-card/>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>

      <div v-if="showProducts">
        <div class="common-title-wrap input-top">
          <common-title/>
        </div>
        <div class="products-wrapper">
          <el-row :gutter="8" justify="space-between">
            <el-col :xs="8" :sm="6" :md="4" v-for="i in 6" :key="i">
              <skeleton-product-item/>
            </el-col>
          </el-row>
        </div>
      </div>

      <div v-if="showProducts">
        <div class="common-title-wrap input-top">
          <common-title/>
        </div>
        <div class="products-wrapper">
          <el-row :gutter="8" justify="space-between">
            <el-col :xs="8" :sm="6" :md="4" v-for="i in 6" :key="i">
              <skeleton-product-item/>
            </el-col>
          </el-row>
        </div>
      </div>

      <div v-if="productFirst && $auctionHouseStore.domainHouse.can_manage_auction">
        <div class="common-title-wrap">
          <common-title/>
        </div>
        <div class="scroll-wrapper">
          <el-row :gutter="10">
            <el-col :xs="24" :lg="8" v-for="i in 3" :key="i">
              <div class="item component-auction-item">
                <skeleton-auction-card/>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>

      <div v-if="$auctionHouseStore.liveBroadcasts.length">
        <div class="common-title-wrap" style="padding-top: 2.5rem;">
          <common-title/>
        </div>
        <div class="auction-list">
          <el-row :gutter="30">
            <el-col :xs="24" :sm="8" v-for="i in 3" :key="i">
              <skeleton-live-card class="item"/>
            </el-col>
          </el-row>
        </div>
      </div>

      <div class="common-title-wrap" style="padding-top: 2.5rem;">
        <common-title/>
      </div>
      <div class="auction-list">
        <el-row :gutter="30">
          <el-col :xs="24" :lg="12" v-for="i in 4" :key="i">
            <skeleton-auction-item class="item"/>
          </el-col>
        </el-row>
      </div>

      <div v-if="showProducts">
        <div class="common-title-wrap">
          <common-title/>
        </div>
        <div class="products-wrapper">
          <el-row :gutter="8" justify="space-between">
            <el-col :xs="8" :sm="6" :md="4" v-for="i in 6" :key="i">
              <skeleton-product-item/>
            </el-col>
          </el-row>
        </div>
      </div>

      <div v-if="$auctionHouseStore.domainHouseDetail.guides_count">
        <div class="common-title-wrap">
          <common-title/>
        </div>
        <div class="auction-list">
          <el-row :gutter="30">
            <el-col :xs="24" :sm="8" v-for="i in 3" :key="i">
              <skeleton-live-card class="item"/>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { Component, Vue } from 'vue-property-decorator';
  import Skeleton from '@/components/skeleton/mixins';

  @Component({ mixins: [Skeleton] })
  export default class IndexSkeleton extends Vue {
    get showProducts() {
      return this.$get(this.$auctionHouseStore, 'domainHouse.can_manage_store') && this.$get(this.$auctionHouseStore, 'domainHouseDetail.products_count') > 0;
    }

    get productFirst() {
      return this.$get(this.$auctionHouseStore, 'domainHouseDetail.product_first');
    }
  }
</script>

<style lang="scss" scoped>
  .common-input-wrap {
    margin-bottom: 0px;

    @include media-sm-and-up {
      margin-bottom: 50px;
    }
  }

  .container {
    .common-title-wrap {
      margin: 10px 0 26px;

      @include media-sm-and-up {
        margin-bottom: 40px;
      }

      &.input-top {
        margin: 70px 0 26px;

        @include media-sm-and-up {
          margin: 95px 0 40px;
        }
      }

      &.top {
        margin-top: 40px !important;

        @include media-xs-only {
          margin-top: 30px !important;
        }
      }
    }
  }

  .scroll-wrapper {
    margin: 10px 0 30px;

    /deep/ .auction-card-wrapper {
      width: 100%;
    }

    .item {
      margin: 0 5px;
      margin-bottom: 15px;
      cursor: pointer;

      .auction-card-wrapper {
        min-height: 100%;
      }
    }
  }

  .auction-list {
    padding: 10px 0 5px;

    @include media-sm-and-up {
      padding: 10px 0 30px;
    }

    .item {
      display: block;
      margin-bottom: 10px;
    }
  }

  .products-wrapper {
    margin-bottom: 50px;

    /deep/ .component-product-item {
      margin-bottom: 10px;
    }
  }
</style>
