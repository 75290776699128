<i18n>
  {
    "zh_cn": {
      "allContentCategory": "所有内容类别",
      "allPageCategory": "所有专题类别"
    },
    "zh_hk": {
      "allContentCategory": "所有內容類別",
      "allPageCategory": "所有專題類別"
    },
    "en": {
      "allContentCategory": "All content categories",
      "allPageCategory": "All topic categories"
    },
    "ja": {
      "allContentCategory": "すべてのコンテンツ カテゴリ",
      "allPageCategory": "すべてのトピック カテゴリ"
    }
  }
  </i18n>

<template>
  <div>
    <el-row :gutter="8" justify="space-between">
      <el-col :xs="12" :md="8">
        <FilterItem
          :allLabel="$t('allContentCategory')"
          apiUrl="content_categories"
          queryKey="content_category_id_eq"
        />
      </el-col>
      <el-col :xs="12" :md="8">
        <FilterItem
          :allLabel="$t('allPageCategory')"
          apiUrl="page_categories"
          queryKey="page_category_id_eq"
        />
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';
  import FilterItem from './filter-item.vue';

  @Component({ components: { FilterItem } })
  export default class TopicAndGuideFilterGroup extends Vue {
  }
</script>

<style lang="scss" scoped>
  /deep/ .el-select {
    width: 100%;

    .is-focus {
      .el-input__icon {
        transform: rotate(0deg);
      }
    }

    .el-input__inner {
      box-sizing: border-box;
      height: 38px;
      border: none;
      border-bottom: 2px solid #dcdddc;
      border-radius: 0;
      font-size: 18px;
      color: #000;

      @include text-overflow();

      @include media-xs-only {
        font-size: 16px;
      }
    }

    .el-input__icon {
      font-weight: bold;
      font-size: 18px;
      color: #000;

      @include media-xs-only {
        font-size: 16px;
      }

      &::before {
        content: '\e6df';
      }
    }

    .el-select-dropdown {
      width: 100%;
    }
  }
</style>
