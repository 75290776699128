<i18n src="../locales.json"></i18n>

<template>
  <div class="price-wrapper">
    <!-- 拍品总额 -->
    <div v-if="showAuctionPrice" class="price-item">
      <div class="info-title">{{ $t('totalPrice') }}：</div>
      <div>
        <span class="currency">{{ $get(store, 'data.currency') }} </span>
        <span class="price">{{ price | currencyFormat }}</span>
      </div>
    </div>
    <!-- 佣金 -->
    <div v-if="showCommissionAmount && $get(store, 'data.commission_amount')" class="price-item">
      <div class="info-title">{{ $t('commissionAmount') }}：</div>
      <div>
        <span class="currency">{{ $get(store, 'data.currency') }} </span>
        <span class="price">{{ $get(store, 'data.commission_amount') | currencyFormat }}</span>
      </div>
    </div>
    <!-- 税费 -->
    <div v-if="showTaxAmount && $get(store, 'data.tax_amount')" class="price-item">
      <div class="info-title">{{ $t('taxAmount') }}：</div>
      <div>
        <span class="currency">{{ $get(store, 'data.currency') }} </span>
        <span class="price">{{ $get(store, 'data.tax_amount') | currencyFormat }}</span>
      </div>
    </div>
    <!-- 运费(商品，且不是支付运费状态的订单，显示：运费及其它费用) -->
    <div v-if="showLogisticFee" class="price-item">
      <div class="info-title">
        {{ auctionOrder ? $t('shipping') : $t('productShipping') }}：
      </div>
      <div v-if="Number($get(store, 'data.logistic_fee')) === 0 && $get(store, 'data.status') === 'wait_buyer_pay_logistic'" class="logistic">
        {{ $t('logisticTip') }}
      </div>
      <div v-else>
        <span class="currency" v-if="$get(store, 'data.orderable_type') === 'Product'">{{ $get(store, 'data.currency') }} </span>
        <span class="currency" v-else>{{ paymentCurrency }} </span>
        <span class="price">{{ $get(store, 'data.logistic_fee') | currencyFormat }}</span>
      </div>
    </div>
    <!-- 小计（不含运费） -->
    <div v-if="showSubtotal" class="price-item subtotal">
      <div class="info-title">{{ $t('subtotal') }}：</div>
      <div>
        <span class="currency">{{ $get(store, 'data.currency') }} </span>
        <span class="price">{{ $get(store, 'data.payment_amount') | currencyFormat }}</span>
        <span v-if="!CURRENCY_MAP.includes($get(store, 'data.currency'))"> (≈ <span class="currency">{{ $get(store, 'data.quota_currency') }} </span><span class="price">{{ $get(store, 'data.payment_amount_to_quota_currency') | currencyFormat }}</span>)</span>
      </div>
    </div>
    <!-- 抵扣额度 -->
    <div v-if="showDeductionPrice" class="price-item">
      <div class="info-title">{{ $t('deductionPrice') }}：</div>
      <div>
        <span class="currency">- {{ paymentCurrency }} </span>
        <span class="price">{{ $get(store, 'data.margin_amount') | currencyFormat }}</span>
        <span v-if="$get(store, 'data.currency') !== 'jpy' && $get(store, 'data.exchange_rate_type') === 'to_current_currency'"> (≈ - <span class="currency">{{ $get(store, 'data.currency') }} </span><span class="price">{{ $get(store, 'data.real_margin_amount') | currencyFormat }}</span>)</span>
      </div>
    </div>
    <!-- 合计 -->
    <div v-if="showTotal" class="price-item">
      <div class="info-title">{{ $t('total') }}<span v-if="auctionOrder" class="no-shipping">{{ $t('noShipping') }}</span>：</div>
      <div class="prompt" v-if="!$get(store, 'data.payment_amount')">{{ $t('waitConfirmPay') }}</div>
      <div v-else>
        <span class="currency">{{ currency }} </span>
        <span class="price">{{ $get(store, 'data.real_payment_amount') | currencyFormat }}</span>
      </div>
    </div>

    <slot name="other-price-item"></slot>
  </div>
</template>

<script>
  import { Vue, Component, Prop } from 'vue-property-decorator';
  import { CURRENCY_MAP } from '@/constants';

  @Component
  export default class CostInfo extends Vue {
    @Prop({ type: Object, default: () => ({}) }) store
    @Prop({ type: Boolean, default: true }) showAuctionPrice // 显示拍品总额
    @Prop({ type: Boolean, default: true }) showCommissionAmount // 显示佣金
    @Prop({ type: Boolean, default: true }) showTaxAmount // 显示税费
    @Prop({ type: Boolean, default: false }) showLogisticFee // 显示运费
    @Prop({ type: Boolean, default: true }) showSubtotal // 显示小计
    @Prop({ type: Boolean, default: true }) showDeductionPrice // 显示抵扣额滴
    @Prop({ type: Boolean, default: true }) showTotal // 显示合计

    CURRENCY_MAP = CURRENCY_MAP

    // 拍品总额
    get price() {
      const orderable_type = this.$get(this.store, 'data.orderable_type');
      const orderable = this.$get(this.store, 'data.orderable', {});
      return orderable_type === 'Product' ? orderable.deal_price : orderable.final_price;
    }

    get auctionOrder() {
      return this.$get(this.store, 'data.orderable_type') === 'AuctionItem';
    }

    get paymentCurrency() {
      return CURRENCY_MAP.includes(this.currency) ? this.currency : 'jpy';
    }

    get currency() {
      if (this.$get(this.store, 'data.exchange_rate_type') === 'to_jpy') {
        return 'JPY';
      } else {
        return this.$get(this.store, 'data.currency');
      }
    }
  }
</script>

<style lang="scss" scoped>
  .price-wrapper {
    .info-title {
      min-width: 120px;
      font-weight: 800;

      &.name {
        padding: 12px 0 8px 1px;
      }
    }

    .price-item {
      display: flex;
      justify-content: space-between;

      &:not(:first-child) {
        margin-top: 0.2rem;
      }
    }

    .subtotal {
      padding-top: 20px;
      margin-top: 20px;
      border-top: 1px solid #ddd;
    }

    .logistic {
      font-size: 11px;
      color: #666;
    }

    .currency {
      text-transform: uppercase;
    }

    .price {
      font-size: 16px;
      color: $primary;
    }

    .prompt {
      color: #999;
    }
  }
</style>
