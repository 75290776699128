<i18n src="./locale.json"></i18n>

<template>
  <div class="print-pdf-item" ref="pdfItem">
    <!-- 拍品信息图 -->
    <div class="paper" :style="{ height: paperHeight }">
      <div class="paper-container">
        <!-- paper头部 -->
        <div class="header-box">
          <div class="title">
            {{ `${auctionName} - ${item.auction_category.title}` }}
          </div>
          <div class="message">
            {{ auctionInfoPaperMessage }}
          </div>
        </div>

        <!-- 封面图 -->
        <div class="cover-box">
          <div class="cover">
            <div class="placeholder-box">
              <img :src="$get(item, 'cover.url')" alt="" class="image" />
            </div>
          </div>
        </div>

        <!-- 拍品信息 -->
        <div class="info-box flex item-center content-between">
          <div class="flex-1">
            <div class="number">
              {{ `Lot ${item.number}` }}
            </div>
            <div class="name text-overflow">
              {{ `Lot ${item.number} ${item.title}` }}
            </div>
            <div class="price">
              <div v-if="item.is_finished && item.show_items_final_price && $authStore.isLogin">
                <div v-if="['hammered', 'force_hammered'].includes(item.status)">
                  <div>
                    {{ $t('dealPrice') }}:
                    <span class="text-uppercase">{{ $get(item, 'auction_category.currency') }}</span>
                    {{ item.final_price | currencyFormat }}
                  </div>
                  <div v-if="!$options.filters.isLocaleCurrency($get(item, 'auction_category.currency'))">
                    {{ $t('dealPrice') }}: {{ $t('currency') }} {{ item.final_price | conversionFormat($get(item, 'auction_category.currency')) | currencyFormat }}
                  </div>
                </div>
                <div v-else>
                  {{ $t('aborted') }}
                </div>
              </div>
              <div v-else>
                <div>
                  {{ $t('startPrice') }}:
                  <span class="text-uppercase">{{ $get(item, 'auction_category.currency') }}</span>
                  {{ item.starting_price | currencyFormat }}
                </div>
                <div v-if="!$options.filters.isLocaleCurrency($get(item, 'auction_category.currency'))">
                  {{ $t('startPrice') }}: {{ $t('currency') }} {{ item.starting_price | conversionFormat($get(item, 'auction_category.currency')) | currencyFormat }}
                </div>
              </div>
            </div>
          </div>
          <canvas ref="qrcode" />
        </div>

        <!-- 详情 -->
        <div class="desc-rich-text flex-1" v-html="$options.filters.htmlLineBreak(item.desc)"></div>

        <!-- 底部页标 -->
        <div class="paper-footer">
          <img :src="pdfFooterLogo" alt="" class="logo">
          <div class="page-number">
            1
          </div>
        </div>
      </div>
    </div>

    <div v-for="(paper, index) in papers" :key="index" class="paper" :style="{ height: paperHeight }">
      <div class="paper-container">
        <!-- 拍品图 & 专家意见 -->
        <div class="imgs-wrapper flex-1" :style="{'--items-per-page': itemsPerPage}">
          <div v-for="(image, imageIndex) in paper" :key="imageIndex" class="img-container">
            <img :src="image.url" alt="" class="image" />
          </div>

          <!-- 专家意见（放在最后） -->
          <div v-if="index === papers.length - 1 && item.expertOpinion" class="opinion-wrapper">
            <div class="title">
              {{ $t('expertOpinion') }}：
            </div>
            <div class="desc-opinion flex-1" v-html="item.expertOpinion" />
          </div>
        </div>

        <!-- 底部页标 -->
        <div class="paper-footer">
          <img :src="pdfFooterLogo" alt="" class="logo">
          <div class="page-number">
            {{ index + 2 }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { Vue, Component, Prop } from 'vue-property-decorator';
  import qrcode from 'qrcode';
  import dayjs from 'dayjs';
  import utcPlugin from 'dayjs/plugin/utc';
  import timezonePlugin from 'dayjs/plugin/timezone';
  import _ from 'lodash';

  // 导入插件
  dayjs.extend(utcPlugin);
  dayjs.extend(timezonePlugin);

  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  @Component
  export default class PrintPdfItem extends Vue {
    @Prop({ type: Object, default: () => ({}) }) item;
    @Prop({ type: String, default: '' }) auctionName;
    @Prop({ type: String, default: '' }) pdfFooterLogo;

    paperHeight = 'unset';
    itemsPerPage = 1;

    mounted() {
      const ele = this.$refs.qrcode;
      const { fullPath } = this.$get(this.$router.resolve({ name: 'auctionItemDetail', params: { id: this.item.id } }), 'resolved');
      qrcode.toCanvas(ele, `${window.location.origin}${fullPath}`, {
        width: 100,
        height: 100,
      });
      const { width } = this.$refs.pdfItem.getBoundingClientRect();
      this.paperHeight = `${width * 1.414}px`;
    }

    get auctionInfoPaperMessage() {
      const formatStartAt = dayjs(this.item.auction_start_at).utc().tz(userTimeZone);
      return [
        this.item.auction_category.place,
        formatStartAt.format('DD MMM YYYY'),
        formatStartAt.format('hh:mm A Z'),
      ].filter(Boolean).join(' | ');
    }

    get papers() {
      const images = this.item.images || [];
      const papers = _.chunk(images, this.itemsPerPage);

      if (images.length % this.itemsPerPage === 0 && this.item.expertOpinion) {
        papers.push([]);
      }
      return papers;
    }
  }
</script>

<style lang="scss" scoped>
.print-pdf-item {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .paper {
    box-sizing: border-box;
    padding: 24px;
    border: 3px solid #e8e8e8;

    .paper-container {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    .imgs-wrapper {
      display: grid;
      grid-template-rows: repeat(var(--items-per-page), 1fr);
      margin-bottom: 18px;
      overflow: hidden;
      gap: 18px;

      // 由于html2canvas不支持object-fit:cover，所以需要手动实现
      // https://juejin.cn/post/6906388793805242375
      .img-container {
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        overflow: hidden;
      }

      .image {
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .header-box {
    padding-bottom: 8px;
    border-bottom: 1px solid #e8e8e8;

    .title {
      font-weight: 600;
      font-size: 18px;
      color: #000;
    }

    .message {
      margin-top: 8px;
      font-size: 12px;
    }
  }

  .cover-box {
    padding: 20px;
    border: 1px solid #e8e8e8;

    .cover {
      position: relative;
      width: 100%;
      padding-bottom: calc((261 / 547) * 100%);

      .placeholder-box {
        position: absolute;
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        overflow: hidden;
        inset: 0;

        .image {
          width: 100%;
          object-fit: cover;
        }
      }
    }
  }

  .info-box {
    margin-top: 10px;

    .number,
    .name {
      font-weight: 400;
      font-size: 16px;
      color: #000;
    }

    .name,
    .price {
      margin-top: 4px;
    }

    .price {
      margin-top: 12px;
      color: #333;
    }
  }

  .desc-rich-text {
    column-count: 2;
    column-gap: 20px;
    margin-top: 20px;
    margin-bottom: 10px;
    overflow: hidden;
    font-size: 14px;
    color: #333;
  }
}

.paper-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 9px 0;
  border-top: 1px solid #e8e8e8;
  font-size: 14px;
  color: #000;

  .logo {
    width: auto;
    height: 32px;
  }
}
</style>
