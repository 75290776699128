<i18n src="./locales.json"></i18n>

<template>
  <div>
    <v-title :value="$t('guide')" />
    <skeleton :onFetch="onFetch" v-model="loading"/>

    <div v-if="!loading" class="container">
      <div class="filter-group">
        <FilterGroup/>
      </div>

      <el-row :gutter="8" justify="space-between">
        <el-col :xs="24" :sm="12" :md="8" v-for="item in guideStore.data" :key="item.id">
          <guide-card :guide="item" :routeTo="{ name: 'guideDetail', params: { id: item.id } }" />
        </el-col>
      </el-row>

      <div class="guides-list-empty" v-if="guideStore.isEmpty">
        <img src="@/assets/icon-select-empty.png" />
        <div>{{ $t('guidesListEmpty') }}</div>
      </div>

      <pagination routeMode class="pagination" :store="guideStore" />
    </div>
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';
  import { Collection } from '@/store';
  import Skeleton from './components/index-skeleton';
  import FilterGroup from '@/components/topic-and-guide/filter-group.vue';

  @Component({ components: { Skeleton, FilterGroup } })
  export default class Guide extends Vue {
    loading = true

    guideStore = new Collection({
      params: { per_page: 12, default_eq: false },
      fetch: params => this.$fly.get('guides', params)
    })

    async onFetch() {
      const { page } = this.$route.query;

      return await this.guideStore.fetchData({
        page,
        page_category_id_eq: this.$route.query.page_category_id_eq || undefined,
        content_category_id_eq: this.$route.query.content_category_id_eq || undefined,
      });
    }
  }
</script>

<style lang="scss" scoped>
  .container {
    padding-top: 15px;
  }

  .filter-group {
    margin-bottom: 20px;
  }

  .pagination {
    padding: 10px 0 30px;
  }

  .guides-list-empty {
    margin: 46px auto 57px;
    text-align: center;
    color: #999;

    > img {
      width: 65px;
      height: 65px;
      margin-bottom: 36px;
    }
  }
</style>
