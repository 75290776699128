// https://github.com/scurker/currency.js
import Currency from 'currency.js';

export default function currencyFormat(currency, options = {}) {
  if (!currency) {
    return 0;
  }
  // 所有金额都做向下取整处理
  return Currency(Math.floor(currency), { symbol: '', precision: 0, ...options }).format();
}
