<i18n src="./locales.json"></i18n>

<template>
  <div>
    <v-title :value="$get(liveBroadcast, 'name')"/>

    <div class="page-live-broadcast-detail" v-if="liveBroadcast.id">
      <div class="container">
        <div class="box-content">
          <div class="screen-left">
            <auto-height height="56%" v-if="isFinished && !showRecordVideo">
              <div class="box-finished">
                <img src="@/assets/icon-live.png" />
                <p>{{ $t('finished_tip') }}</p>
              </div>
            </auto-height>
            <template v-else>
              <div class="live-title-box hidden-xs-only">
                <div class="flex">
                  <div class="title flex-1">{{ liveBroadcast.name }}</div>
                  <div class="select-video" v-if="showRecordVideo && videoSources.length > 1">
                    <el-select v-model="videoIndex"
                      @change="handleVideoSourceChange"
                      class="record-video-select flex-1">
                      <el-option v-for="(item, index) in videoSources"
                        :key="index"
                        :value="index"
                        :label="item.label"
                      />
                    </el-select>
                  </div>
                </div>
                <div class="date-time">{{ liveBroadcast.start_at | timeFormat }}</div>
              </div>
              <div class="box-live">
                <div class="watch-user" v-if="!showRecordVideo"><img class="icon" src="@/assets/icon-watch.png"/>{{ viewCount }}</div>
                <div class="player">
                  <auto-height height="56%">
                    <video v-if="showRecordVideo"
                      id="my-video"
                      webkit-playsinline="true"
                      playsinline="true"
                      class="video-js video-js-wrapper"/>
                    <agora-live-player v-else :agoraPreferences="agoraPreferences" @mobileFullScreen="handleMobileFullScreen" />
                  </auto-height>
                </div>
              </div>
              <div class="live-title-box hidden-sm-and-up">
                <div class="title">{{ liveBroadcast.name }}</div>
                <div class="date-time">{{ liveBroadcast.start_at | timeFormat }}</div>
                <div class="select-video" v-if="showRecordVideo && videoSources.length > 1">
                  <el-select v-model="videoIndex"
                    @change="handleVideoSourceChange"
                    class="record-video-select flex-1">
                    <el-option v-for="(item, index) in videoSources"
                      :key="index"
                      :value="index"
                      :label="item.label"
                    />
                  </el-select>
                </div>
              </div>
            </template>
          </div>
          <div class="screen-right">
            <div class="comment-wrapper" :class="[{ live: liveBroadcast.status === 'in_live' }]" ref="commentWrapper" :style="commentStyle">
              <div class="comment-main" ref="commentMain">
                <template v-if="commentList.length">
                  <div class="comment" v-for="(item, index) in commentList" :key="index">
                    <span class="name">{{ item.name }}: </span>
                    <span class="content" v-if="item.type === 'text'">{{ item.content }}</span>
                    <span class="content" v-else><img src="@/assets/icon-heart.png" class="heart"/>x{{ item.content }}</span>
                  </div>
                </template>
                <div class="comment-empty" v-else>
                  <img src="@/assets/empty.png" />
                  <p>{{ $t('emptyData') }} </p>
                </div>
              </div>
              <div class="comment-footer">
                <div class="input-box flex">
                  <HeartTap @change="handleLikeChange" v-if="isInLive" @click.native="handleNoLogin"/>
                  <form class="flex flex-1" style="height: 100%;" @submit.prevent="handleComment">
                    <input :disabled="!isInLive" class="input flex-1" v-model.trim="comment" :placeholder="$t('commentPlaceholder')" @click="handleNoLogin"/>
                    <div class="btn cursor-pointer"  :class="{ disabled: !isInLive }" @click="handleComment">{{ $t('send') }}</div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';
  import { authStore } from '@/store';
  import videojs from 'video.js';
  import _ from 'lodash';
  import 'video.js/dist/video-js.css';
  import HeartTap from './components/heart-tap.vue';
  import { createConsumer } from '@/utils/actioncable';

  @Component({
    components: {
      HeartTap
    }
  })
  export default class LiveBroadcastDetail extends Vue {
    authStore = authStore;
    comment = '';

    agoraPreferences = {};
    mobileFullScreen = false;
    videoSources = [];
    videoIndex = 0;
    video = null;
    commentStyle = {}

    liveBroadcast = {}

    commentList = [];
    viewCount = 0;

    get liveBroadcastId() {
      return this.$route.params.id;
    }

    get auctionId() {
      return _.get(this.liveBroadcast, 'auction_id');
    }

    get isFinished() {
      return this.liveBroadcast.status === 'finished';
    }

    get showRecordVideo() {
      return this.liveBroadcast.status === 'finished' && this.videoSources.length > 0;
    }

    get isInLive() {
      return this.liveBroadcast.status === 'in_live';
    }

    async created() {
      const mapComment = (comment = {}) => {
        return {
          name: this.$get(comment, 'user.full_name', this.$t('visitor')),
          type: comment.like_count ? 'heart' : 'text',
          content: comment.like_count || comment.content
        };
      };
      const [{ data: comments }] = await this.$autoLoading(
        Promise.all([
          this.$fly.get(`live_broadcasts/${this.liveBroadcastId}/comments`, { per_page: 100 }),
          this.getAgoraPreferences(),
        ])
      );
      this.commentList = comments.reverse().map(mapComment);
      this.consumer = createConsumer(`/cable?Locale=${this.$i18n.locale}&Authorization=${authStore.access_token}`);
      this.consumer.subscriptions.create({ channel: 'LiveBroadcastChannel', id: this.liveBroadcastId, uid: this.agoraPreferences.uid }, {
        received: async result => {
          const { success, action, data } = result;
          if (success) {
            if (action === 'new_comment') {
              this.commentList.push(mapComment(data));
              this.handleScrollCommentWrapper();
            }
            if (action === 'update_view_count') {
              this.viewCount = data.view_count;
            }
          }
        }
      });

      // 回放相关
      this.videoSources = this.liveBroadcast.record_urls.map((url, index) => {
        return {
          src: url,
          type: 'application/x-mpegURL',
          label: `video${index + 1}`
        };
      });
      await this.$nextTick();
      this.setVideo();
      this.setCommentStyle();

      window.addEventListener('resize', this.setCommentStyle, true);

      this.handleScrollCommentWrapper();
    }

    beforeDestroy() {
      if (this.consumer) {
        this.consumer.disconnect();
      }
      if (this.video) {
        this.video.dispose();
        this.video = null;
      }
      window.removeEventListener('resize', this.setCommentStyle, true);
    }

    async handleScrollCommentWrapper() {
      await this.$nextTick();
      this.$refs.commentMain.scrollTop = 99999;
    }

    // 全屏的时候需要隐藏上/下一件
    handleMobileFullScreen(status) {
      this.mobileFullScreen = status;
    }

    setCommentStyle() {
      if (!this.$isMobile()) {
        this.commentStyle = {};
        return;
      }
      const { top } = this.$refs.commentWrapper.getBoundingClientRect();
      const h = window.innerHeight;
      this.commentStyle = {
        height: `${h - top}px`
      };
    }

    setVideo() {
      if (!this.showRecordVideo) {
        return;
      }
      const config = {
        controls: true,
        notSupportedMessage: this.$t('notSupportedMessage'),
        controlBar: {
          children: [
            'playToggle',
            'volumePanel',
            'currentTimeDisplay',
            'timeDivider',
            'durationDisplay',
            'progressControl',
            'fullscreenToggle',
          ]
        }
      };
      const video = videojs('my-video', config);
      video.src(this.videoSources[this.videoIndex]);
      video.on('ended', () => {
        if (this.videoIndex >= this.videoSources.length - 1) {
          return;
        }
        this.videoIndex += 1;
        video.src(this.videoSources[this.videoIndex]);
        video.play();
      });

      this.video = video;
    }

    handleVideoSourceChange() {
      this.video.src(this.videoSources[this.videoIndex]);
      this.video.play();
    }

    async getAgoraPreferences() {
      const { data } = await this.$fly.get(
        `/live_broadcasts/${this.$route.params.id}`,
        { is_preview: this.isPreview ? true : undefined }
      );
      if (data) {
        this.liveBroadcast = data;
        this.agoraPreferences = {
          auctionId: data.auction_id,
          appid: data.appid,
          channel: data.channel,
          token: data.channel_token || null,
          uid: data.channel_uid,
          is_live: data.is_live,
          host_uids: data.host_uids
        };
      }
    }

    handleLikeChange(count) {
      return this.$fly.post(`/live_broadcasts/${this.liveBroadcastId}/comments`, { like_count: count });
    }

    // 游客点击评论的时候，提示需要先登录
    async handleNoLogin() {
      if (!authStore.isLogin) {
        await this.$confirm(this.$t('logFirst'));
        return this.$router.push({ name: 'login', query: { redirect: encodeURIComponent(this.$route.fullPath) } });
      }
    }

    async handleComment() {
      this.handleNoLogin();
      if (this.comment && authStore.isLogin) {
        await this.$autoLoading(this.$fly.post(`/live_broadcasts/${this.liveBroadcastId}/comments`, {
          content: this.comment,
          is_preview: this.isPreview ? true : undefined
        }));
        this.$message({ type: 'success', message: this.$t('sendSuccess') });
        this.comment = '';
      }
    }
  }
</script>

<style lang="scss">
  .video-js {
    position: relative;
    width: 100%;
    height: 100%;

    .vjs-big-play-button {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 2.5em;
      height: 2.5em;
      border-width: 0.12em;
      border-radius: 2.5em;
      font-size: 2.5em;
      line-height: 2.3em;
      background-color: rgba(115, 133, 159, .5);
      transform: translate(-50%, -50%);
    }
  }
</style>

<style lang="scss" scoped>
  .cursor-pointer {
    cursor: pointer;
  }

  .box-finished {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    font-size: 16px;
    color: #888888;
    background: #f6f9fa;

    img {
      width: 60px;
    }
  }

  .container {
    overflow: initial;
  }

  .page-live-broadcast-detail {
    padding-top: 30px;
  }

  @include media-xs-only {
    .page-live-broadcast-detail {
      padding-top: 0;
    }

    .container {
      height: 100%;
      padding: 0;

      .box-content {
        height: 100%;

        .screen-right {
          height: 100%;
          overflow-y: auto;
        }
      }
    }
  }


  @include media-sm-and-up {
    .container {
      height: 100%;

      .box-content {
        display: flex;
        // height: 100%;

        .screen-right {
          // height: 100%;
          overflow-y: auto;
        }
      }
    }
  }

  .box-content {
    @include clearfix;

    @include media-sm-and-up {
      .screen-left {
        top: 0;
        flex-shrink: 0;
        width: 52%;
      }

      .screen-right {
        width: 48%;
        padding: 0 30px;

        .lot-info {
          padding-top: 0;
        }
      }
    }
  }

  .live-title-box {
    margin-bottom: 14px;

    .title {
      font-size: 24px;
      line-height: 33px;
    }

    .select-video {
      width: 150px;
    }

    /deep/.el-select {
      width: 100%;
    }

    .date-time {
      margin-top: 6px;
      color: #666666;
    }
  }

  @include media-xs-only {
    .live-title-box {
      padding: 0 30px;
      margin-top: 6px;
    }

    .title {
      width: 100%;
    }

    .select-video {
      width: 100% !important;
      margin-top: 6px;
    }
  }

  .box-live {
    position: relative;
    width: 100%;

    .watch-user {
      position: absolute;
      z-index: 9;
      top: 10px;
      left: 9px;
      display: flex;
      align-items: center;
      height: 17px;
      padding: 0 6px;
      margin-right: 6px;
      border-radius: 2px;
      font-size: 10px;
      background: rgba(#fff, 0.4);

      .icon {
        width: 8px;
        margin-right: 4px;
      }
    }

    .player {
      width: 100%;
    }

    .indicator {
      position: absolute;
      z-index: 9;
      top: 10px;
      left: 9px;
      display: flex;
      align-items: center;
      height: 17px;
      padding: 0 6px;
      border-radius: 2px;
      font-size: 10px;
      background: rgba(#fff, 0.4);
    }

    .live-tips-wrapper {
      position: absolute;
      z-index: 99;
      top: 10px;
      right: 9px;
      width: 50%;
      height: 17px;
      border-radius: 2px;
      overflow: hidden;
      font-size: 10px;
      white-space: nowrap;
      background-color: rgba(#fff, 0.4);

      .icon {
        width: 8px;
        margin: 4px;
      }

      .live-tips-box {
        position: relative;
        height: 100%;
        overflow: hidden;
        white-space: nowrap;

        .live-tips {
          position: absolute;
          left: 2px;
          box-sizing: border-box;
          padding-top: 2px;
          padding-right: 4px;
          white-space: nowrap;
        }
      }
    }
  }

  .comment-wrapper {
    position: relative;
    height: 100%;
    background-color: #f5f5f5;

    .comment-main {
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      padding: 12px 12px 70px;
      overflow-y: auto;

      @include media-sm-and-up {
        max-height: 65vh;
      }

      &.live {
        padding-bottom: calc(12px + 64px + env(safe-area-inset-bottom));
      }

      .comment-empty {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        color: #979797;

        img {
          width: 80px;
          height: auto;
        }
      }
    }

    .comment {
      margin-bottom: 12px;
      line-height: 24px;

      .name {
        color: $primary;
      }

      .heart {
        width: 26px;
        margin-right: 4px;
      }
    }

    .comment-footer {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 12px;
      padding-bottom: calc(12px + env(safe-area-inset-bottom));
      border-top: 1px solid #e7e7e7;
      background: #f5f5f5;

      @include media-xs-only {
        background: #fff;
      }

      .input-box {
        width: 100%;
        height: 40px;

        .input {
          display: flex;
          flex: 1;
          align-items: center;
          box-sizing: border-box;
          padding: 0 6px;
          border: 1px solid #f5f5f5;
          border-right: transparent;
          border-left: transparent;
          outline: none;
        }

        .btn {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 60px;
          height: 100%;
          font-weight: bold;
          color: #fff;
          background-color: $primary;

          &.disabled {
            opacity: .3;
            cursor: not-allowed !important;
          }
        }
      }
    }
  }
</style>
