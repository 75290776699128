<template>
  <div class="title-wrap"
    :style="{ '--menu-bg': $get($auctionHouseStore, 'domainHouseDetail.color_config.menu_bg') ? $get($auctionHouseStore, 'domainHouseDetail.color_config.menu_bg') : '#e8e8e8',
              '--menu-font': $get($auctionHouseStore, 'domainHouseDetail.color_config.menu_font') ? $get($auctionHouseStore, 'domainHouseDetail.color_config.menu_font') : '#000'}">
    <div class="component-title">{{ title }}</div>
  </div>
</template>

<script>
  import { Vue, Component, Prop } from 'vue-property-decorator';

  @Component
  export default class CustomTitle extends Vue {
    @Prop(String) title;
  }
</script>

<style lang="scss" scoped>
  .title-wrap {
    margin-left: -20px;

    @include media-sm-and-up {
      max-width: 1200PX;
      padding: 0 20px;
    }
  }

  .component-title {
    display: inline-block;
    min-width: 340px;
    height: 38px;
    padding: 6px 25px 6px 15px;
    overflow: hidden;
    font-weight: bold;
    font-size: 18px;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: var(--menu-font);
    background: linear-gradient(-60deg, transparent 20px, var(--menu-bg) 0);

    @include media-xs-only {
      min-width: 250px;
      height: 31px;
      padding-left: 15px;
      font-size: 14px;
    }
  }
</style>
