<i18n src="../locales.json"></i18n>

<template>
  <div>
    <el-dialog
      :visible="dialogVisible"
      @update:visible="$emit('update:show', $event)"
      width="29.16667rem"
      top="35vh"
    >
      <div class="lot-info text-overflow" slot="title">
        LOT {{ number }} {{ title }}
      </div>
      <div class="current-price">
        <span>{{ $t('currentPrice') }}</span>
        <span class="text-uppercase">{{ currency }} </span>
        <span class="primary">{{ this.currentPrice | currencyFormat }}</span>
      </div>
      <div class="input-group-wrapper">
        <div class="btn minus" :class="{ disabled: disabledMins }" @click="!disabledMins && handleChange('minusPrice')"><span class="el-icon-minus"/></div>
        <div class="input-wrapper"><span class="price-text">{{ priceText }}</span></div>
        <div class="btn plus" :class="{ disabled: disabledAdd }" @click="!disabledAdd && handleChange('addPrice')"><span class="el-icon-plus"/></div>
      </div>
      <div class="item-expired text-center" v-if="disabled">*{{ $t(store.status) }}</div>
      <div class="excess-tips" v-if="disabledAdd && $authStore.isLogin">
        <span class="">{{ $t('excessTips') }}：</span>
        <span class="text-uppercase">{{ currency }} </span>
        <span class="primary">
          {{ $get(availableAmount, `${store.auction.margin_ratio}`) | conversionFormat(quotaCurrency, currency) | currencyFormat }}
        </span>
        <router-link
          class="primary-to"
          :to="{ name: 'charge', query: { auctionId: store.auction.id, selectCurrency: quotaCurrency, marginAmount: $options.filters.conversionFormat(price, currency, quotaCurrency) } }"
        >
          {{ $t('rechargeTips') }}
        </router-link>
      </div>
      <div slot="footer">
        <el-button size='mini' @click="$emit('update:show', false)">{{ $t('cancel') }}</el-button>
        <el-button size='mini' type="primary" :disabled="disabledSubmit" @click="handleConfirm">{{ $t('confirm') }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { Vue, Component, Prop, Watch, Model } from 'vue-property-decorator';
  import Store from '../store';
  import _ from 'lodash';
  import { CURRENCY_MAP } from '@/constants';

  @Component
  export default class PricePopup extends Vue {
    @Prop(Store) store
    @Prop(Array) currentRecordList
    @Model('update:show', { type: Boolean }) show

    dialogVisible = true
    price = 0

    CURRENCY_MAP = CURRENCY_MAP

    get priceText() {
      return this.$options.filters.currencyFormat(this.price);
    }

    get currentPrice() {
      return _.get(this.currentRecordList, '0.price') || _.get(this.store, 'auctionItem.starting_price');
    }

    get currency() {
      return this.$get(this.store, 'auctionItem.currency');
    }

    get number() {
      return this.$get(this.store, 'auctionItem.number');
    }

    get title() {
      return this.$get(this.store, 'auctionItem.title');
    }

    get disabledMins() {
      if (this.disabled) {
        return this.disabled;
      }
      return this.isFirst ? this.price <= this.currentPrice : this.store.minusPrice(this.price) <= this.currentPrice;
    }

    // 出价超过限制额度禁止点击
    get disabledAdd() {
      if (this.disabled) {
        return this.disabled;
      }
      if (this.$authStore.isLogin) {
        const { margin_ratio } = this.$get(this.store, 'auction') || {};

        const currentQuota = this.store.quotas.find(item => item.currency === this.quotaCurrency);
        const amount = this.$get(currentQuota, `available_bidding_amount[${margin_ratio}]`);
        const maxAmount = this.$options.filters.conversionFormat(amount, this.quotaCurrency, this.currency);

        return this.price > maxAmount;
      } else {
        return true;
      }
    }

    // 是否为第一个出价
    get isFirst() {
      return !this.currentRecordList.length;
    }

    // 禁止所有操作
    get disabled() {
      return this.store.status !== 'pending';
    }

    get quotaCurrency() {
      return CURRENCY_MAP.includes(this.currency) ? this.currency : 'jpy';
    }

    get availableAmount() {
      const currentQuota = this.store.quotas.find(item => item.currency === this.quotaCurrency);
      return this.$get(currentQuota, 'available_bidding_amount');
    }

    // 禁止提交
    get disabledSubmit() {
      // 如果是第一个出价，需要可以出当前价。否则只能出当前价下一档价格
      const disabled = this.isFirst ? this.price < this.currentPrice : this.price <= this.currentPrice;
      return this.disabled || disabled || this.disabledAdd;
    }

    @Watch('show', { immediate: true })
    showChange(val, old) {
      this.dialogVisible = val;
      if (val &&  val !== old) {
        this.initPrice();
      }
    }

    @Watch('store.status')
    statusChange(val, old) {
      if (old && old !== val && val === 'pending') {
        // 切换拍品后，重置价格
        this.initPrice();
      }
    }

    created() {
      this.initPrice();
    }

    // 初始化出价信息
    initPrice() {
      this.price = this.currentPrice;
      // 如果不是第一个出价的，默认填入下一个出价
      !this.isFirst && this.handleChange('addPrice');
    }

    // 加价或减价
    handleChange(type, price = this.price) {
      if (type === 'addPrice') {
        this.price = price < this.currentPrice ? this.currentPrice : this.store[type](price);
      } else {
        this.price = this.store[type](price) < _.get(this.store, 'auctionItem.starting_price') ?
          _.get(this.store, 'auctionItem.starting_price') : this.store[type](price);
      }
    }

    handleConfirm() {
      !this.disabledSubmit && this.$emit('confirm', this.price);
    }
  }
</script>

<style lang="scss" scoped>
  .lot-info {
    padding-right: 20px;
    font-weight: 800;
    font-size: 14px;
    line-height: 24px;
    color: #000;
  }

  .primary {
    color: $primary;
  }

  .current-price {
    margin-bottom: 20px;
    text-align: center;
    color: #333;
  }

  .input-group-wrapper {
    display: flex;
    justify-content: center;

    .input-wrapper,
    .btn {
      display: flex;
      justify-content: center;
      width: 40px;
      height: 30px
    }

    .btn {
      align-items: center;
      border: 1px solid $border-light;
      font-weight: bold;
      color: #000;
      cursor: pointer;

      &.disabled {
        opacity: 0.5;
      }

      &.minus {
        border-bottom-left-radius: 15px;
        border-top-left-radius: 15px;
      }

      &.plus {
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;
      }
    }

    .input-wrapper {
      width: 100px;
      border-top: 1px solid $border-light;
      border-bottom: 1px solid $border-light;

      .price-text {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        font-weight: bold;
        color: $primary;
      }
    }
  }

  .item-expired {
    margin-top: 20px;
    color: #f00;
  }

  .excess-tips {
    margin-top: 20px;
    text-align: center;
    color: #333;

    .primary-to {
      display: block;
      margin-top: 10px;
      color: $primary;

      &:hover {
        color: #f00;
      }
    }
  }
</style>
