<template>
  <div class="flex item-center social-links">
    <component
      :is="item.url ? 'a' : 'el-popover'"
      v-bind="item.url ? { href: item.url, target: '_blank' } : { trigger: 'hover' }"
      class="item"
      v-for="(item, index) in socialLinks"
      :key="index"
      :style="item.style"
    >
      <img :src="item.icon" alt="" slot="reference" />
      <img :src="item.img" v-if="item.img" style="width: 16.66667rem;" />
    </component>
  </div>
</template>

<script>
  import { Vue, Component, Prop } from 'vue-property-decorator';
  import { settingsStore } from '@/store';
  import _ from 'lodash';

  @Component
  export default class SocialLinks extends Vue {
    @Prop(Boolean) white;

    settingsStore = settingsStore;

    get socialLinks() {
      return [
        {
          icon: require(`@/assets/icon-facebook${this.white ? '' : '-black'}.png`),
          url: _.get(this.settingsStore, 'settings.facebook_account')
        },
        {
          icon: require(`@/assets/icon-xiaohongshu${this.white ? '' : '-black'}.png`),
          url: _.get(this.settingsStore, 'settings.xiaohongshu_account'),
          style: {
            width: '40px'
          }
        },
        {
          icon: require(`@/assets/icon-instagram${this.white ? '' : '-black'}.png`),
          url: _.get(this.settingsStore, 'settings.instagram_account')
        },
        {
          icon: require(`@/assets/icon-weixin${this.white ? '' : '-black'}.png`),
          url: '',
          img: _.get(this.settingsStore, 'settings.wechat_account.url')
        }
      ];
    }
  }
</script>

<style lang="scss" scoped>
  .social-links {
    .item {
      display: flex;
      align-items: center;
      width: 16px;
      height: 16px;
      outline: none;

      &:not(:last-child) {
        margin-right: 15px;
      }

      img {
        width: 100%;
      }

      /deep/ .el-popover__reference-wrapper {
        display: flex;
      }
    }
  }
</style>
