import { authStore } from '@/store';
import { fly } from '@/utils';
import { get } from 'lodash';

export async function notifyCustomer(callbackFnc) {
  if (!authStore.isLogin || !authStore.user.user_extended_info || !get(authStore, 'user.user_extended_info.credential_will_expire_notify_message')) {
    return;
  }
  try {
    await callbackFnc();
  } finally {
    fly.put('user_extended_infos', { should_show_credential_will_expire: false });
  }
}
