<i18n src="./locale.json"></i18n>

<template>
  <div class="component-auction-preview-status">
    <template v-if="!isFinished">
      <participate-button
        v-show="!number"
        :auction="auction"
        :number="number"
        :quotas="quotas"
        v-on="$listeners"
      />
      <!-- 当前额度 -->
      <div class="box-limit" v-if="number">
        <div class="flex item-center content-between">
          <div class="flex item-center">
            <div class="label">{{ $t('currentLimit') }}：</div>
            <div class="flex item-center">
              <!-- 包含2种货币，才能切换显示不同货币的当前额度 -->
              <el-select
                v-if="$get(auction, 'quota_currencies.length') > 1"
                v-model="selectCurrency"
                :popper-append-to-body="false"
              >
                <el-option
                  v-for="currency in auction.quota_currencies"
                  :key="currency"
                  :value="currency"
                  :label="currency"
                ></el-option>
              </el-select>
              <div v-else class="currency text-uppercase">{{ selectCurrency }}</div>
              <div class="amount">{{ $get(currentBiddingAmount, `${[auction.margin_ratio]}`) | currencyFormat }}</div>
            </div>
          </div>
          <router-link
            class="btn-increase"
            :to="{ name: 'charge', query: { auctionId: auction.id, selectCurrency } }"
          >
            {{ $t('increaseAmount') }}
          </router-link>
        </div>
        <div class="margin-ratio" v-if="auction.margin_ratio !== 1">
          <div v-if="$i18n.locale !== 'en'">{{ $t('marginTip') }}<span>{{ auction.margin_ratio }}</span>{{ $t('multiple') }}</div>
          <div v-else>It is <span>{{ auction.margin_ratio }}</span> times the top-up amount</div>
        </div>
      </div>
    </template>
    <router-link
      v-if="showCountdown && (inLiveBroadcast || isFinishedLiveBroadcast) && auction.on_site"
      class="primary-btn-o btn"
      :to="{ name: 'liveBroadcastDetail', params: { id: auction.current_normal_live_broadcast.id } }"
    >
      {{ isFinishedLiveBroadcast ? $t('navToAuctionPreviewRecord') : $t('navToAuctionPreviewLive') }}
    </router-link>
    <template v-if="!showCountdown">
      <div class="primary-btn btn disabled" v-if="isFinished">{{ $t('finished_tip') }}</div>
      <router-link
        class="primary-btn btn"
        :to="{ name: 'auctionLive', params: { id: auction.id }, query: { isPreview } }"
        v-if="isStarted && auction.on_site"
      >
        {{ $t('navToAuctionLive') }}
      </router-link>
    </template>
    <template v-else>
      <div class="box-countdown">
        <p class="label">{{ $t('beforeStartTip') }}</p>
        <app-countdown
          :end-time="endTime" endHide plusOneMinute
          @completed="$emit('finished')"
          showDate
        >
          <template v-slot="{ day, hour, minute }">
            <div class="countdown">
              <span>{{ day }}</span>{{ $t('day') }}
              <span>{{ hour }}</span>{{ $tc('hour', hour) }}
              <span>{{ minute }}</span>{{ $tc('minute', minute) }}
            </div>
          </template>
        </app-countdown>
      </div>
    </template>
    <div v-if="!$isMobile()" class="primary-btn-o btn" @click="showPrintItemsPopup = true">
      {{ $t('printAuctionItems') }}
    </div>
    <div class="box-number" v-if="showNumber">
      <span class="label">{{ $t('yourNumber') }}：</span>
      <span>{{ number }}</span>
    </div>

    <!-- 打印拍品弹窗 -->
    <print-items-popup :show.sync="showPrintItemsPopup" :auction="auction" />

  </div>
</template>

<script>
  import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
  import { authStore, settingsStore } from '@/store';
  import ParticipateButton from './participate-button.vue';
  import dayjs from 'dayjs';
  import _ from 'lodash';
  import PrintItemsPopup from './print-items-popup.vue';

  @Component({ components: { ParticipateButton, PrintItemsPopup } })
  export default class AuctionPreviewStatus extends Vue {
    @Prop(Object) auction;
    @Prop({ type: [Number, String], default: void 0 }) serverTime;
    @Prop([String, Number]) number;
    @Prop({ type: Boolean, default: false }) isPreview;
    @Prop({ type: Array, default: () => [] }) quotas

    selectCurrency = ''
    showPrintItemsPopup = false

    authStore = authStore;
    settingsStore = settingsStore;

    get endTime() {
      if (!this.auction || !this.auction.id) {
        return '';
      }
      return this.auction.start_at;
    }

    get isFinished() {
      return this.auction.is_finished;
    }

    // 有预览直播
    get hasLiveBroadcast() {
      return !_.isEmpty(this.auction.current_normal_live_broadcast);
    }

    // 有预览直播正在进行中
    get inLiveBroadcast() {
      return this.hasLiveBroadcast && this.auction.current_normal_live_broadcast.status === 'in_live';
    }

    // 是结束状态的预览直播
    get isFinishedLiveBroadcast() {
      return this.hasLiveBroadcast && this.$get(this.auction, 'current_normal_live_broadcast.end_at');
    }

    get isStarted() {
      const { start_at, is_finished } = this.auction;
      return !is_finished && dayjs(this.serverTime).diff(dayjs(start_at)) >= 0;
    }

    get showCountdown() {
      // 可能出现还没开始就结束的情况
      if (this.isFinished) {
        return false;
      }
      return dayjs(this.auction.start_at).diff(dayjs(this.serverTime), 'second') > 0;
    }

    get showNumber() {
      return !this.isFinished && this.number;
    }

    get currentBiddingAmount() {
      const currentQuota = this.quotas.find(item => item.currency === this.selectCurrency);
      return this.$get(currentQuota, 'available_bidding_amount');
    }

    @Watch('auction.quota_currencies.length', { immediate: true })
    currenciesChange(val) {
      if (val > 1) {
        return this.selectCurrency = 'jpy';
      }
      this.selectCurrency = this.$get(this.auction, 'quota_currencies.0', 'jpy');
    }

  }

</script>

<style lang="scss" scoped>
  .component-auction-preview-status {
    .box-limit {
      width: 100%;
      padding-top: 26px;
      margin: 22px 0 6px;
      border-top: 1px solid #999;

      .label {
        margin-top: 2px;
        font-weight: 500;
        font-size: 12px;
        color: $gray;
      }

      .currency {
        font-weight: 500;
        font-size: 18px;
        color: #000;
      }

      .amount {
        margin-left: 5px;
        font-weight: 500;
        font-size: 21px;
        color: $primary;
      }

      .btn-increase {
        padding: 6px 14px;
        border-radius: 15px;
        font-size: 13px;
        color: #fff;
        background: $primary;
        cursor: pointer;
      }
    }

    .margin-ratio {
      font-weight: 500;
      font-size: 12px;
      color: $gray;

      span {
        margin: 0 2px;
        font-size: 16px;
        color: $primary;
      }
    }

    .btn {
      box-sizing: border-box;
      width: 100%;
      margin-top: 22px;
      font-size: 18px;
    }

    .box-number {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding: 20px;
      margin-top: 22px;
      font-weight: bold;
      font-size: 21px;
      background: #eee;

      .label {
        font-weight: normal;
        font-size: 12px;
        color: $gray;
      }
    }

    .box-countdown {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      padding: 20px;
      margin-top: 22px;
      background: #eee;

      > .label {
        margin: 0 0 10px;
        color: $gray;
      }

      .countdown {
        font-size: 12px;
        color: $gray;

        span {
          font-size: 25px;
          color: $primary;
        }
      }
    }
  }

  .link {
    font-weight: bold;
    text-decoration: underline;
    color: var(--color-btn-bg);
  }

  .contact-info {
    display: flex;
    justify-content: center;
    white-space: pre-wrap;
  }

  /deep/.el-select {
    .el-input__inner {
      width: 80px;
      border: none;
      text-transform: uppercase;
    }

    .el-select-dropdown__item {
      text-transform: uppercase;
    }
  }
</style>
