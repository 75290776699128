<i18n src="./locale.json"></i18n>

<template>
  <div class="component-auction-item-status" v-if="auction && auction.id">
    <div class="title">{{ auction.title }}</div>
    <div class="box-countdown" v-if="showCountdown">
      <div class="split-line"></div>
      <p class="label">{{ $t('beforeStartTip') }}</p>
      <app-countdown
        :end-time="auction.start_at" endHide plusOneMinute
        @completed="$emit('finished')"
        showDate
      >
        <template v-slot="{ day, hour, minute }">
          <div class="countdown">
            <span>{{ day }}</span>{{ $t('day') }}
            <span>{{ hour }}</span>{{ $tc('hour', hour) }}
            <span>{{ minute }}</span>{{ $tc('minute', minute) }}
          </div>
        </template>
      </app-countdown>
    </div>
    <div class="link-btn disabled" v-if="isFinished">{{ $t('finished_tip') }}</div>
    <router-link
      class="link-btn"
      :to="{ name: 'auctionLive', params: { id: auction.id } }"
      v-if="isStarted && auction.on_site"
    >
      {{ $t('navToAuctionLive') }}
    </router-link>
  </div>
</template>

<script>
  import { Vue, Component, Prop } from 'vue-property-decorator';
  import dayjs from 'dayjs';

  @Component
  export default class AuctionStatus extends Vue {
    @Prop(Object) auction;
    @Prop({ type: [Number, String], default: void 0 }) serverTime;

    get isFinished() {
      return this.auction.is_finished;
    }

    get isStarted() {
      const { start_at, is_finished } = this.auction;
      // 返回的serverTime偶尔会小于start_at，这里固定增加1秒保证稳定的判断
      return !is_finished && dayjs(this.serverTime).add(1, 's').diff(dayjs(start_at)) >= 0;
    }

    get showCountdown() {
      // 可能出现还没开始就结束的情况
      if (this.isFinished) {
        return false;
      }
      // 返回的serverTime偶尔会小于start_at，这里固定增加1秒保证稳定的判断
      return dayjs(this.auction.start_at).diff(dayjs(this.serverTime).add(1, 's'), 'second') > 0;
    }
  }

</script>

<style lang="scss" scoped>
  .component-auction-item-status {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 22px 30px;
    background: #eee;

    .title {
      font-weight: bold;
      font-size: 16px;
      text-align: center;
      color: #000;
    }

    .split-line {
      width: 175px;
      height: 1px;
      background: #999;
    }

    .box-countdown {
      display: flex;
      flex-direction: column;
      align-items: center;

      .split-line {
        margin: 15px 0;
      }

      > .label {
        margin: 0 0 10px;
        color: $gray;
      }

      .countdown {
        font-size: 12px;
        color: $gray;

        span {
          font-size: 25px;
          color: $primary;
        }
      }
    }

    .link-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      width: 100%;
      max-width: 360px;
      height: 36px;
      padding: 0 24px;
      margin-top: 20px;
      border-radius: 18px;
      font-size: 14px;
      color: var(--color-btn-font);
      background: var(--color-btn-bg);

      &.disabled {
        background: #ccc;
      }
    }
  }
</style>
