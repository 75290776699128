<template>
  <div class="container input-wrap flex">
    <div class="input-box flex item-center content-between">
      <input type="text" class="input" v-model="keyword" :placeholder="$t('productSearch')" @keyup.enter="handleSearch" />
      <div class="icon-wrap flex content-center">
        <img src="@/assets/icon-search-white.png" slot="suffix" class="icon" @click="handleSearch" />
      </div>
    </div>

    <div class="cascader-box">
      <el-cascader
        :placeholder="$t('chooseCategory') "
        :props="optionProps"
        :options="categories"
        @change="handleChange"
        ref="addClick"
        :popper-class="!$auctionHouseStore.domainHouse ? 'fixed-category' : 'choose-category' "
        ></el-cascader>
        <img class="cascader-icon" src="@/assets/icon-tangle-down.png" alt="">
    </div>
  </div>
</template>

<script>
  import { Vue, Component, Prop } from 'vue-property-decorator';

  @Component
  export default class CommonInput extends Vue {
    @Prop({ type: Array, default: () => [] }) categories;

    keyword = '';
    optionProps = {
      value: 'id',
      label: 'title',
      children: 'children',
    }

    async created() {
      this.categories.forEach(item => {
        let node = {};
        Object.assign(node, item);
        node.children = null;
        item.children.unshift(node);
      });
    }

    handleSearch() {
      this.$router.push({ name: 'products', query: { keyword: this.keyword } });
      this.showContent = false;
      this.keyword = '';
    }

    handleChange() {
      const checkedNodes = this.$refs['addClick'].getCheckedNodes();
      const { id } = checkedNodes[0].data;
      const { title } = checkedNodes[0].data;
      this.$router.push({
        name: 'products',
        query: {
          type: 'product_category',
          id,
          name: title,
          keyword: this.keyword
        }
      });
    }
  }
</script>

<style lang="scss">
  .el-popper.el-cascader__dropdown {
    .popper__arrow::after {
      display: none;
    }

    .el-cascader-node__label {
      @include media-xs-only {
        width: 62px;
      }
    }

    .popper__arrow {
      display: none;
    }

    .el-scrollbar.el-cascader-menu {
      &:last-of-type {
        .el-cascader-node {
          &:first-of-type {
            font-weight: bold;
            color: #000;
          }
        }
      }
    }
  }

  .el-cascader-node.in-active-path, .el-cascader-node.is-selectable.in-checked-path, .el-cascader-node.is-active {
    color: #000;
  }

  .choose-category {
    .el-icon-arrow-right:before {
      content: url(../assets/icon-tangle-right.png) !important;
    }
  }

  .fixed-category {
    .el-icon-arrow-right:before {
      content: url(../assets/icon-tangle-right.png) !important;
    }
  }
</style>

<style lang="scss" scoped>
  .input-wrap {
    width: 100%;
    padding: 0 15px;
    margin-top: 30px;

    @include media-xs-only {
      padding-bottom: 10px;
      margin: 10px 0 0;
    }

    .input-box {
      display: flex;
      align-items: center;
      box-sizing: border-box;
      width: 460px;
      height: 45px;
      padding-left: 10px;
      border: 1px solid #000;
      border-radius: 999px;
      background: #fff;

      @include media-xs-only {
        width: 50%;
        height: 42px;
        padding-left: 12px;
      }

      .input {
        flex: 1;
        width: 100%;
        border: none;
        outline: none;
        background: transparent;
      }

      .icon-wrap {
        display: flex;
        align-items: center;
        width: 50px;
        height: 100%;
        margin-left: 5px;
        border-radius: 999px;
        background: #000;

        @include media-sm-and-up {
          width: 60px;
        }

        .icon {
          width: 15px;
          height: 15px;
        }
      }
    }

    .cascader-box {
      display: flex;
      align-items: center;
      box-sizing: border-box;
      height: 45px;
      padding: 0 10px;
      margin-left: 20px;
      border: 1px solid black;
      border-radius: 20px;

      @include media-xs-only {
        width: 50%;
        height: 42px;
        margin-left: 10px;
      }

      .cascader-icon {
        width: 15px;
        height: 15px;
      }

      /deep/ .el-cascader {
        flex: 1;
        height: 100%;

        .el-input {
          height: 100%;
        }

        .el-input__inner {
          height: 100%;
          padding: 0;
          border: none;
          background: transparent;

          &::placeholder {
            color: #000;

            @include media-xs-only {
              font-size: 12px;
            }
          }
        }

        .el-input__suffix {
          display: none;
        }
      }
    }
  }

</style>
