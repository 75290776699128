import Vue from 'vue';
import './element';
Vue.use(require('./title').default);
Vue.component('app-lang-switcher', require('./lang-switcher').default);
Vue.component('app-social-share', require('./social-share').default);
Vue.component('app-footer', require('./footer').default);
Vue.component('app-countdown', require('./countdown').default);
Vue.component('app-live-player', require('./live-player').default);
Vue.component('app-social-links', require('./social-links').default);
Vue.component('app-auction-item-status', require('./auction/item-status').default);
Vue.component('app-auction-preview-status', require('./auction/preview-status').default);
Vue.component('app-swiper', require('./swiper').default);
Vue.component('app-fixed-bottom', require('./fixed-bottom').default);
Vue.component('common-header', require('./header').default);
Vue.component('common-banner', require('./banner').default);
Vue.component('common-title', require('./common-title').default);
Vue.component('common-input', require('./common-input').default);
Vue.component('common-tab-bar', require('./tab-bar').default);
Vue.component('common-sidebar', require('./side-bar').default);
Vue.component('common-popup', require('./popup').default);
Vue.component('custom-page', require('./custom-page').default);
Vue.component('auction-card', require('./auction/auction-card').default);
Vue.component('auction-item', require('./auction/auction-item').default);
Vue.component('banner-content', require('./banner-content').default);
Vue.component('auction-item-swiper', require('./auction-item/swiper').default);
Vue.component('auction-item-toggle-control', require('./auction-item/toggle-control').default);
Vue.component('auction-item-card', require('./auction-item/card').default);
Vue.component('target-type-card', require('./target-type-card').default);
Vue.component('order-item', require('./orders/order-item').default);
Vue.component('payment-methods', require('./orders/payment-methods').default);
Vue.component('payment-deduction', require('./orders/payment-deduction').default);
Vue.component('pagination', require('./pagination').default);
Vue.component('auto-height', require('./auto-height').default);
Vue.component('preview-image', require('./preview-image').default);
Vue.component('action-sheet', require('./action-sheet').default);
Vue.component('oss-img', require('./oss-img').default);
Vue.component('agora-live-player', require('./agora-live').default);
Vue.component('live-card', require('./live-card').default);
Vue.component('product-item', require('./products/product-item').default);
Vue.component('product-category-item', require('./products/product-category-item').default);
Vue.component('product-countdown', require('./products/product-countdown').default);
Vue.component('store-category-item', require('./stores/store-category-item').default);
Vue.component('auction-house-card', require('./auction-house/auction-house-card').default);
Vue.component('auction-house-item', require('./auction-house/auction-house-item').default);
Vue.component('follow-share', require('./follow-share').default);
Vue.component('collapsible-rich-text', require('./collapsible-rich-text').default);
Vue.component('guide-card', require('./guide-card').default);
Vue.component('no-captcha', require('./no-captcha.vue').default);

// 骨架屏
Vue.component('skeleton-auction-card', require('./skeleton/auction-card').default);
Vue.component('skeleton-auction-item', require('./skeleton/auction-item').default);
Vue.component('skeleton-banner', require('./skeleton/banner').default);
Vue.component('skeleton-product-item', require('./skeleton/product-item').default);
Vue.component('skeleton-live-card', require('./skeleton/live-card').default);
Vue.component('skeleton-auction-item-card', require('./skeleton/auction-item-card').default);
