<template>
  <div v-if="loading">
    <el-progress :percentage="percentage" :show-text="false"/>
    <div class="container">
      <el-row :gutter="30">
        <el-col :xs="24" :sm="12">
          <div class="cover-wrapper">
            <skeleton-banner/>
          </div>
        </el-col>
        <el-col :xs="24" :sm="12">
          <div class="box-info prod-skeleton-animate">
            <div class="auction-title prod-skeleton-block">1</div>
            <p class="prod-skeleton-block">1</p>
            <p class="prod-skeleton-block">1</p>
            <p class="prod-skeleton-block">1</p>
            <p class="prod-skeleton-block">1</p>
          </div>
        </el-col>
      </el-row>

      <div style="height: 4.16667rem;"/>
      <common-title style="margin: 1.66667rem 0;"/>

      <div class="box-lot">
        <el-row :gutter="30">
          <el-col
            :xs="24" :sm="12" :md="8"
            v-for="i in 3" :key="i"
          >
            <div class="auction-category-card prod-skeleton-animate">
              <div class="cover prod-skeleton-block"></div>
              <div class="content">
                <div class="title prod-skeleton-block">1</div>
                <div class="info">
                  <p class="prod-skeleton-block">1</p>
                  <p class="prod-skeleton-block">1</p>
                  <p class="prod-skeleton-block">1</p>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
  import { Component, Vue } from 'vue-property-decorator';
  import Skeleton from '@/components/skeleton/mixins';

  @Component({ mixins: [Skeleton] })
  export default class IndexSkeleton extends Vue {
    speed = 5
  }
</script>

<style lang="scss" scoped>
  .container {
    @include media-sm-and-up {
      padding-top: 50px;
    }
  }

  .prod-skeleton-block {
    width: 30%;
  }

  .auction-title {
    width: 50%;
  }

  .cover-wrapper {
    margin-bottom: 20px;

    @include media-xs-only {
      margin: 0 -20px 20px;
    }
  }

  .box-lot {
    padding-bottom: 10px;

    @include media-xs-only {
      padding-bottom: 0;
    }

    .box-lot-empty {
      font-size: 12px;
      text-align: center;
      color: #9d9d9d;
    }

    a {
      color: #000;
    }

    .card {
      margin-bottom: 8px;
    }

    .auction-category-card {
      margin-bottom: 30px;

      @include media-xs-only {
        margin-bottom: 15px;
      }
    }

    .info {
      font-size: 14px;
      line-height: 24px;
      color: $gray;
    }
  }

  .auction-category-card {
    border-radius: 2px;
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.09);

    .cover {
      width: 100%;
      height: 157px;
      object-fit: cover;
    }

    .content {
      padding: 27px 22px 22px;

      .title {
        width: 100%;
        margin-bottom: 24px;
        font-size: 20px;
        line-height: 30px;

        @include media-sm-and-up {
          height: 60px;
        }
      }
    }
  }
</style>
