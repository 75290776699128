<i18n>
  {
    "zh_cn": {
      "in_live": "直播中",
      "pending": "即将开始",
      "finished": "已结束",
      "liveBroadcastsEmpty": "当前暂无直播"
    },
    "zh_hk": {
      "in_live": "直播中",
      "pending": "即將開始",
      "finished": "已結束",
      "liveBroadcastsEmpty": "當前暫無直播"
    },
    "en": {
      "in_live": "Live",
      "pending": "Coming soon",
      "finished": "Finished",
      "liveBroadcastsEmpty": "No live broadcast now"
    },
    "ja": {
      "in_live": "実況中",
      "pending": "まもなく開始",
      "finished": "終了しました",
      "liveBroadcastsEmpty": "現在、ライブ配信はありません"
    }
  }
</i18n>

<template>
  <div class="live-broadcasts">
    <v-title :value="$t('liveBroadcast')"/>
    <skeleton :onFetch="onFetch" v-model="loading"/>
    <template v-if="!loading">
      <template v-if="!liveListStoreEmpty">
        <div v-for="list in liveListStore" :key="list.title" class="container">
          <div v-if="list.data.length">
            <common-title class="common-title" :title="$t(list.title)"/>
            <el-row :gutter="20">
              <el-col :xs="24" :sm="8" v-for="item in list.data" :key="item.id">
                <live-card :live="item" :routeTo="{ name: 'liveBroadcastDetail', params: { id: item.id } }"/>
              </el-col>
            </el-row>
            <pagination :store="list"/>
          </div>
        </div>
      </template>
      <div v-else class="empty-box">
        <img src="@/assets/empty.png" alt="">
        {{ $t('liveBroadcastsEmpty') }}
      </div>
    </template>
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';
  import { Collection, auctionNumbersStore } from '@/store';
  import Skeleton from './components/index-skeleton';

  @Component({ components: { Skeleton } })
  export default class LiveBroadcast extends Vue {
    listParams = [
      { status: 'in_live', perPage: 9 },
      { status: 'pending', perPage: 9 },
      { status: 'finished', perPage: 9 },
    ]
    liveListStore = []
    loading = true

    get liveListStoreEmpty() {
      return this.liveListStore.every(item => item.data.length === 0);
    }

    async onFetch() {
      const createStore = (status, perPage) => new Collection({
        title: status,
        params: { status, per_page: perPage },
        fetch: params => this.$fly.get('live_broadcasts', { ...params })
      });
      this.listParams.forEach(item => {
        this.liveListStore.push(createStore(item.status, item.perPage));
      });
      await Promise.all([
        ...this.liveListStore.map(store => {
          return store.fetchData();
        }),
        auctionNumbersStore.tryFetchData(),
      ]);
    }
  }
</script>

<style lang="scss" scoped>
  .live-broadcasts {
    margin-bottom: 40px;

    .container {
      &:first-of-type {
        @include media-sm-and-up {
          margin-top: 20px;
        }
      }
    }

    /deep/ .el-pagination {
      padding-top: 10px;

      @include media-sm-and-up {
        padding-top: 20px;
      }
    }

    /deep/ .component-title {
      margin: 20px 0;

      @include media-sm-and-up {
        margin: 20px 0 30px;
      }
    }
  }

  .empty-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px 0;

    img {
      width: 160px;
      margin-bottom: 20px;
    }
  }
</style>
