<template>
  <div class="page-topic">
    <v-title :value="page.title"/>
      <div class="container">
        <div class="top" v-if="$get(page, 'top_cover.url')">
          <img class="top-img" :src="$get(page, 'top_cover.url')" alt="">
          <div class="blur">
            <div class="title">{{ page.top_title }}</div>
            <div class="content text-overflow-2">{{ page.top_content }}</div>
          </div>
        </div>

        <custom-page v-if="$get(page, 'config.components', []).length > 0" :components="$get(page, 'config.components')" :topic="true"/>
        <div class="keyword-wrap" v-if="page.keywords && page.keywords.length">
          <div class="label">{{ $t('keyword') }}:</div>
          <router-link
            class="keyword"
            v-for="item in page.keywords"
            :key="item.id"
            :to="{ name: 'search', query: { type: 'topic', keyword: item.content } }"
          >{{ item.content }}</router-link>
        </div>
    </div>
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';
  import { getCustomPage } from '@/utils';
  import { followProductIdsStore, auctionNumbersStore } from '@/store';

  @Component
  export default class TopicDetail extends Vue {
    page = {}

    @Vue.autoLoading
    async created() {
      Promise.all([
        auctionNumbersStore.tryFetchData(),
        followProductIdsStore.tryFetchData()
      ]);
      const { slug } = this.$route.params;
      const { data } = await getCustomPage(slug);
      this.page = data;
      this.$fly.post('action_stores', {
        action_type: 'visit',
        target_type: 'CustomPage',
        target_id: data.id
      });
    }
  }
</script>

<style lang="scss" scoped>
  .container {
    max-width: 900PX;
    padding-bottom: 10px;

    @include media-sm-and-up {
      padding-bottom: 40px;
    }
  }

  .top {
    position: relative;
    overflow: hidden;
    color: #fff;

    @include media-xs-only {
      margin: 0 -15px;
    }

    .top-img {
      width: 100%;
    }

    // 蒙层
    .blur {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      padding: 50px 15px;
      background: rgba(0, 0, 0, 0.2);
    }

    .title {
      padding: 0 60px;
      font-weight: 500;
      font-size: 20px;
      text-align: center;

      @include media-sm-and-up {
        font-size: 32px;
      }
    }

    .content {
      margin-top: 60px;
      font-size: 15px;
      line-height: 24px;
      white-space: pre-wrap;

      @include media-xs-only {
        @include text-overflow(2);
      }

      @include media-sm-and-up {
        font-size: 17px;
      }
    }
  }

  .keyword-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 15px 0;
    margin: 30px 20px 20px;
    border-top: 1px solid #f3f3f3;

    .label {
      padding-top: 2px;
      margin-right: 10px;
      color: #989898;
    }

    .keyword {
      padding: 2px 4px;
      margin: 0 10px 10px 0;
      border-radius: 4px;
      color: #8c8c8c;
      background-color: #f1f1f1;
    }
  }
</style>
