<template>
  <div class="component-auction-item">
    <skeleton-auction-card auto-height="50%"/>
  </div>
</template>

<script>
  export default {};
</script>

<style lang="scss" scoped>
  .component-auction-item {
    /deep/ .auction-card-wrapper {
      width: 100%;

      .auction-wrapper {
        font-size: 14px;

        .auction-title {
          font-size: 20px;
          line-height: 30px;
        }
      }

      @include media-sm-and-up {
        display: flex;

        .cover-wrapper {
          display: flex;
          flex-shrink: 0;
          align-items: center;
          width: 50%;
        }

        .auction-wrapper {
          flex: 1;

          .auction-title {
            line-height: 1.4;
            -webkit-line-clamp: 1;
          }

          .auction-info {
            line-height: 1.4;
          }
        }
      }
    }
  }
</style>
