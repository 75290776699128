<template>
  <img :src="imageSrc" v-bind="$attrs" v-on="$listeners" />
</template>

<script>
  import { Vue, Component, Prop } from 'vue-property-decorator';

  @Component
  export default class OSSImage extends Vue {
    @Prop(String) src;
    @Prop({ type: Object, default: () => ({ m: 'mfit', w: 750 }) }) resize;
    @Prop(String) process;

    get OSSProcess() {
      if (this.process) {
        return this.process;
      }
      const keys = Object.keys(this.resize);
      return `image/resize,${keys.map(item => `${item}_${this.resize[item]}`).join(',')}`;
    }

    get imageSrc() {
      if (this.src) {
        return `${this.src}?x-oss-process=${this.OSSProcess}`;
      }
      return this.src;
    }

  }
</script>
