<i18n src="./locales.json"></i18n>

<template>
  <div class="flex">
    <common-sidebar class="hidden-xs-only"/>
    <v-title :value="$t('batchList')"/>

    <div class="container">
      <div class="title">{{ $t('batchList') }}</div>
      <el-tabs :value="name" @tab-click="handleTabClick">
        <el-tab-pane
          v-for="(list, key) in tabs"
          :key="key"
          :label="$t(key)"
          :name="key"
        >
          <div v-if="list.store.isEmpty" class="list-empty flex column item-center">
            <img src="@/assets/icon-deal-empty.png" class="icon-empty">
            <div>{{ $t(list.emptyName) }}</div>
          </div>
          <div v-else-if="list.store.isFulfilled" class="list-wrapper">
            <div v-for="item in list.store.data" :key="item.id" class="batch-order-item">
              <div class="header">
                <div class="info flex">
                  <div class="number">{{ $t('number') }}：{{ item.no }}</div>
                  <div class="date">{{ $t('balanceDate') }}：{{ item.created_at | timeFormat('YYYY-MM-DD') }}</div>
                </div>
                <div class="state">{{ $t(key) }}</div>
              </div>
              <router-link :to="{ name: 'orderBatchDetail', params: { id: item.id } }" class="body">
                <order-item v-for="order in item.orders" :key="order.id" :order="order" :batchKind="item.kind" showExchangeCurrency>
                  <div class="flex content-end"><router-link :to="{ name: 'orderDetail', params: { id: order.id } }" class="primary-btn">{{ $t('navToDetail') }}</router-link></div>
                </order-item>
              </router-link>
              <div class="footer">
                <div class="info flex item-center">
                  <div class="count">{{ item.orders.length }} {{ $t('count') }}</div>
                  <div class="price-box">
                    <span>{{ item.kind === 'product_kind' ? $t('totalAmount') : $t('totalLogisticFee') }}：</span>
                    <span class="currency">{{ item.currency }}</span>
                    <span class="price">{{ item.real_amount | currencyFormat }}</span>
                  </div>
                </div>
                <div class="action flex item-center">
                  <div v-if="item.status === 'pending'" @click="handleCancel(item.id)" class="cancel-btn">{{ $t('cancelOrder') }}</div>
                  <router-link :to="{ name: 'orderBatchDetail', params: { id: item.id } }" class="pay-btn">{{ item.status === 'pending' ? $t('pay') : $t('navToDetail') }}</router-link>
                </div>
              </div>
            </div>
          </div>
          <pagination routeMode class="pagination" />
        </el-tab-pane>
      </el-tabs>
    </div>

  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';
  import { Collection } from '@/store';
  import _ from 'lodash';

  @Component
  export default class BatchList extends Vue {
    tabs = {
      waitPay: {
        params: { status_eq: 'pending' },
        store: {},
        emptyName: 'noWaitPay'
      },
      paid: {
        params: { status_eq: 'paid' },
        store: {},
        emptyName: 'noHavePaid'
      },
      closed: {
        params: { status_eq: 'cancelled' },
        store: {},
        emptyName: 'noClosed'
      },
    }

    get name() {
      return this.$get(this.$route, 'query.name', 'waitPay');
    }

    get currentTab() {
      return this.tabs[this.name];
    }

    async handleTabClick(e) {
      if (this.name === e.name) {
        return;
      }
      this.$router.replace({ query: _.merge({}, { name: e.name }) });
    }

    @Vue.autoLoading
    async created() {
      const { page } = this.$route.query;

      this.currentTab.store = new Collection({
        fetch: params => this.$fly.get('batch_settlements', { ...params, ...this.currentTab.params, page })
      });
      return this.currentTab.store.fetchData();
    }

    @Vue.autoLoading
    async handleCancel(id) {
      await this.$fly.put(`batch_settlements/${id}/cancel`);
      const index = this.currentTab.store.data.findIndex(item => item.id === id);
      this.currentTab.store.data.splice(index, 1);
    }
  }
</script>

<style lang="scss" scoped>
  .title {
    margin: 30px 0 24px;
    font-weight: bolder;
    font-size: 18px;
    color: #000;

    @include media-xs-only {
      padding: 0 15px;
      margin: 28px 0 20px;
    }
  }

  .batch-order-item {
    padding: 0 33px 0 16px;
    background: #ffffff;
    box-shadow: 0px 4px 18px 2px rgba(4, 0, 0, 0.1);

    & + .batch-order-item {
      margin-top: 20px;
    }

    @include media-xs-only {
      padding: 0 15px;
    }

    .header,
    .footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .header {
      padding: 12px 0;
      border-bottom: 1px solid #d8d8d8;
      font-size: 14px;

      @include media-xs-only {
        .info {
          flex-direction: column;
        }

        div .date {
          margin-left: 0;
        }
      }

      .date {
        margin-left: 21px;
        color: #878787;
      }
    }

    .footer {
      padding: 10px 0;
      border-top: 1px solid #d8d8d8;

      @include media-xs-only {
        flex-direction: column;
        align-items: flex-start;

        .info,
        .action {
          width: 100%;
        }

        .action {
          justify-content: flex-end;
          margin-top: 10px;
        }
      }

      .info {
        margin-left: 96px;

        @include media-xs-only {
          justify-content: space-between;
          margin-left: 0;
        }
      }

      .count {
        color: #717171;
      }

      .price-box {
        margin-left: 19px;

        .currency {
          text-transform: uppercase;
        }

        .price {
          margin-left: 5px;
          color: $primary;
        }
      }

      .action {
        .cancel-btn,
        .pay-btn {
          padding: 6px 30px;
          border-radius: 16px;
          cursor: pointer;
        }

        .cancel-btn {
          margin-right: 10px;
          border: 1px solid #000;
          color: #000;
        }

        .pay-btn {
          border: 1px solid $primary;
          color: $primary;
        }
      }
    }

    .primary-btn {
      width: max-content;
      height: 30px;
      padding: 0 14px;
      margin: 21px 0 0;
      font-size: 13px;
      color: #fff;
      background: $primary;

      @include media-sm-and-up {
        margin: -72px 15px 0 0;
      }
    }
  }

  .list-empty {
    padding: 97px 0;
    color: #999;

    .icon-empty {
      width: 65px;
      height: 65px;
      margin-bottom: 34px;
    }
  }

  .pagination {
    margin: 15px 0;

    @include media-sm-and-up {
      margin: 40px 0;
    }
  }

  /deep/ .el-tabs__header {
    margin-bottom: 28px;

    @include media-xs-only {
      padding: 0 15px;
      margin-bottom: 19px;
    }

    .el-tabs__nav-scroll {
      overflow: auto;
    }

    .el-tabs__active-bar {
      display: none;
    }

    .el-tabs__nav {
      display: flex;
      width: 100%;

      @include media-xs-only {
        justify-content: space-between;
      }
    }

    .el-tabs__nav-wrap:after {
      display: none;
    }

    .el-tabs__item {
      height: 40px;
      padding: 0;
      margin-right: 109px;
      line-height: 40px;
      color: #999;

      @include media-xs-only {
        margin: 0;
      }

      &:last-of-type {
        margin-right: 0;
      }

      &.is-active {
        color: #000000;

        &:after {
          position: absolute;
          bottom: 0;
          left: 50%;
          width: 90%;
          height: 2px;
          border-radius: 1px;
          background: #000000;
          transform: translateX(-50%);
          content: '';
        }
      }
    }
  }

  /deep/ .el-tabs__content {
    overflow: visible;
  }
</style>
