<i18n>
  {
    "zh_cn": {
      "topicsListEmpty": "暂时没有符合条件的专题"
    },
    "zh_hk": {
      "topicsListEmpty": "暫時沒有符合條件的專題"
    },
    "en": {
      "topicsListEmpty": "There are currently no eligible topics"
    },
    "ja": {
      "topicsListEmpty": "現在、対象となるトピックはありません"
    }
  }
</i18n>

<template>
  <div>
    <v-title :value="$t('topic')" />
    <skeleton :onFetch="onFetch" v-model="loading"/>
    <div v-if="!loading" class="container">
      <div v-if="$auctionHouseStore.domainHouse" class="filter-group">
        <FilterGroup/>
      </div>

      <el-row :gutter="8" justify="space-between">
        <el-col :xs="24" :sm="12" :md="8" v-for="item in topicStore.data" :key="item.id">
          <live-card :live="item" :routeTo="{ name: 'topicDetail', params: { slug: item.slug } }">
            <template slot="footer">
              <img src="@/assets/icon-arrow-right.png" class="live-card-content icon">
              <div class="live-card-content theme-title">{{ item.title }}</div>
            </template>
          </live-card>
        </el-col>
      </el-row>
    </div>

    <div class="guides-list-empty" v-if="topicStore.isEmpty">
      <img src="@/assets/icon-select-empty.png" />
      <div>{{ $t('topicsListEmpty') }}</div>
    </div>

    <pagination routeMode class="pagination" :store="topicStore" />
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';
  import { Collection } from '@/store';
  import Skeleton from './components/index-skeleton';
  import FilterGroup from '@/components/topic-and-guide/filter-group.vue';

  @Component({ components: { Skeleton, FilterGroup } })
  export default class Index extends Vue {
    loading = true

    topicStore = new Collection({
      params: { per_page: 12, default_eq: false },
      fetch: params => this.$fly.get('custom_pages', params)
    })

    async onFetch() {
      const { page } = this.$route.query;

      if (this.$auctionHouseStore.domainHouse) {
        Object.assign(this.topicStore.params, {
          page_category_id_eq: this.$route.query.page_category_id_eq || undefined,
          content_category_id_eq: this.$route.query.content_category_id_eq || undefined
        });
      }
      return await this.topicStore.fetchData({ ...this.topicStore.params, page });
    }
  }
</script>

<style lang="scss" scoped>
  .container {
    padding-top: 15px;
  }

  .live-card-content {
    position: absolute;
    bottom: 15px;
    font-size: 16px;
    color: #fff;

    &.icon {
      right: 15px;
      width: 30px;
      height: 30px;
    }

    &.theme-title {
      left: 20px;
      max-width: 200px;
    }
  }

  .guides-list-empty {
    margin: 46px auto 57px;
    text-align: center;
    color: #999;

    > img {
      width: 65px;
      height: 65px;
      margin-bottom: 36px;
    }
  }

  .pagination {
    padding: 10px 0 30px;
  }

  .filter-group {
    margin-bottom: 20px;
  }
</style>
