<template>
  <div class="pc-menu-wrapper">
    <img src="@/assets/icon-close.png" alt="" class="icon-close" @click="$emit('input', false)">
    <div class="menu-content flex column" :class="{ around: !$auctionHouseStore.domainHouse, center: $auctionHouseStore.domainHouse  }">
      <component
        v-if="$auctionHouseStore.domainHouse"
        :is="$auctionHouseStore.domainHouse.official_website_link ? 'a' : 'router-link'"
        class="house-name flex content-center item-center"
        v-bind="$auctionHouseStore.domainHouse.official_website_link ? { href: $auctionHouseStore.domainHouse.official_website_link, target: '_blank' } : { to: { name: 'home' } } "
      >
        {{ $auctionHouseStore.domainHouse.name }}
      </component>
      <div v-else class="flex item-center ">
        <img src="@/assets/icon-logo.png" alt="" class="logo">
      </div>
      <div class="container" style="padding: 0 8.33333rem;">
        <slot/>
      </div>
      <div v-if="!$auctionHouseStore.domainHouse" class="footer flex column item-center">
        <app-social-links white />
        <div class="agreement"  @click="$emit('input', false)">
          <router-link
            v-for="(item, index) in pagesStore.data" :key="item.id"
            :to="{ name: 'pages', params: { slug: item.slug }}">{{item.name}}<span v-if="index !== 4"> | </span>
          </router-link>
        </div>
        <p class="copyright">Copyrights © {{ dayjs().year() }} BiddingArt.com All rights reserved</p>
      </div>
    </div>
  </div>
</template>

<script>
  import { Vue, Component, Model } from 'vue-property-decorator';
  import { pagesStore } from '@/store';
  import dayjs from 'dayjs';

  @Component
  export default class SideMenu extends Vue {
    @Model('input', { type: Boolean, default: false }) value;

    pagesStore = pagesStore;
    dayjs = dayjs;
  }
</script>

<style lang="scss" scoped>
  .pc-menu-wrapper {
    height: 100%;
    color: #cccccc;
    background: #000000;

    .icon-close {
      position: fixed;
      top: 26px;
      right: 32px;
      width: 16px;
      height: 16px;
      cursor: pointer;
    }

    /deep/ .el-dropdown {
      margin-top: -10px;
      color: #fff;
      cursor: pointer;
    }

    .menu-content {
      height: 100%;

      &.around {
        justify-content: space-around;
      }

      &.center {
        justify-content: center;
      }

      .house-name {
        margin-bottom: 60px;
        font-weight: bold;
        font-size: 24px;
        color: #fff;
      }

      .logo {
        width: 141px;
        margin: -10px auto 0;
      }

      .footer {
        margin-bottom: -10px;
        color: $gray;

        .agreement {
          margin-top: 23px;
          font-size: 12px;
          line-height: 18px;
          text-align: center;

          a {
            text-decoration: none;
            color: inherit;
          }
        }

        .copyright {
          margin-top: 15px;
          font-size: 9px;
          line-height: 9px;
        }
      }
    }
  }
</style>
