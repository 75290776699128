<i18n src="./locales.json"></i18n>

<template>
  <div class="flex">
    <common-sidebar class="hidden-xs-only" />
    <div class="container">
      <v-title :value="$t('myAuctionNumber')"/>
      <div class="title">{{ $t('myAuctionNumber') }}</div>
      <div class="list">
        <el-row :gutter="10">
          <el-col
            v-for="item in store.data"
            :key="item.id"
            :span="12"
            :xs="24"
          >
            <AuctionNumberItem :item="item" />
          </el-col>
        </el-row>
      </div>
      <pagination class="pagination" :store="store" />
    </div>
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';
  import { Collection } from '@/store';
  import AuctionNumberItem from './components/auction-number-item.vue';

  @Component({
    components: {
      AuctionNumberItem
    }
  })
  export default class AuctionNumber extends Vue {
    store = new Collection({
      fetch: params => this.$fly.get('mine/auction_numbers', { ...params })
    });

    @Vue.autoLoading
    async created() {
      await this.store.fetchData();
    }

    handleJump(item) {
      this.$router.push({ name: 'auctionDetail', params: { id: item.auction_id } });
    }
  }
</script>

<style lang="scss" scoped>
  .container {
    .title {
      margin: 28px 0 10px;
      font-weight: bold;
      font-size: 18px;
      color: #000;

      @include media-sm-and-up {
        margin: 33px 0 24px;
      }
    }

    .list {
      margin-top: 19px;
    }

    .pagination {
      margin: 15px 0;

      @include media-sm-and-up {
        margin: 40px 0;
      }
    }

    .list-empty {
      padding: 97px 0;
      color: #999;

      .icon-empty {
        width: 65px;
        height: 65px;
        margin-bottom: 34px;
      }
    }
  }
</style>
