<i18n src="./locales.json"></i18n>

<template>
  <div class="container">
    <v-title :value="$t('house')"/>
    <common-title class="common-title-wrap" :title="$t('house')"/>
    <div class="house-list" v-for="house in auctionHouseList" :key="house.id">
      <auction-house-card class="component-house-card" :auctionHouse="house"/>
      <div v-if="house.products_count > 6">
        <router-link
          :to="{ name: 'products', query: { id: house.id , type: house.tn, name: house.name }}"
          class="more flex content-end"
        >
          {{ $t('more') }}
        </router-link>
      </div>
      <el-row :gutter="8" justify="space-between">
        <el-col :xs="8" :sm="6" :md="4" v-for="product in $get(house, 'products')" :key="product.id">
          <product-item class="component-product-item" :product="product" :productQuery="{ productType: 'product', auction_house_id_eq: house.id, name: house.name, type: 'auction_houses' }"/>
        </el-col>
      </el-row>
    </div>
    <pagination class="pagination" routeMode :store="auctionHouse"/>
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator';
  import { Collection, followProductIdsStore } from '@/store';
  import { initSubscription, updateProduct } from '@/utils';

  @Component
  export default class auctionHouse extends Vue {
    auctionHouse = new Collection({
      fetch: params => this.$fly.get('auction_houses', params)
    });

    received = function (that, result) {
      that.auctionHouse.data.forEach(item => {
        if (item.can_manage_store && item.products_count > 0) {
          updateProduct(item.products, result);
        }
      });
    }

    get auctionHouseList() {
      return this.auctionHouse.data.filter(item => {
        return item.can_manage_store && item.products_count > 0;
      });
    }

    @Vue.autoLoading
    async created() {
      const { page } = this.$route.query;
      this.auctionHouse.params = {
        products_count: 6,
        has_online_product: true,
        page
      };
      // 使用了product-item组件的页面都需要请求
      await followProductIdsStore.tryFetchData();
      await this.auctionHouse.fetchData();
      if (this.auctionHouseList.length > 0) {
        initSubscription(this, [], this.received);
      }
    }

    beforeDestroy() {
      if (this.consumer) {
        this.consumer.disconnect();
      }
    }

    handleNavDetail(id) {
      this.$router.push({ path: `/auctions/${id}` });
    }
  }
</script>

<style lang="scss" scoped>
  .container {
    @include media-xs-only {
      padding: 0;
    }

    .common-title-wrap {
      margin: 30px 0 33px;

      @include media-sm-and-up {
        padding: 0;
        margin: 30px 0 33px;
      }
    }

    .house-list {
      padding-bottom: 12px;
      border-bottom: 1px solid #ddd;

      &:nth-last-of-type(2) {
        margin-bottom: 0;
        border-bottom: none;
      }

      @include media-xs-only {
        padding: 0 15px;
        margin-bottom: 12px;
        border-bottom: none;
        box-shadow: 0px 4px 18px 2px rgba(4, 0, 0, 0.1);
      }

      .more {
        padding-bottom: 8px;
        color: #666;
      }

      .component-house-card {
        padding: 31px 0;

        @include media-sm-and-up {
          padding: 20px 0 10px;
        }
      }

      .component-product-item {
        margin: 10px 0 15px;
        cursor: pointer;
      }
    }

    .pagination {
      margin: 15px 0;

      @include media-sm-and-up {
        margin: 40px 0;
      }
    }
  }
</style>
